import React, { useEffect, useState } from "react";
import { SidebarMenu } from "./styles";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";

import SubMenu from "../SubMenu";
import SideSubMenu from "../SideSubMenu";

import { Link, useLocation } from "react-router-dom";
import { useSolar } from "../../../hooks";
import { removeAllItems } from "../../../store/actions";
import { useDispatch } from "react-redux";
import SvgIcon from "../../SvgIcon";

export default function SideBar({
  item,
  openSidebar,
  openSubMenu,
  setOpenSubMenu,
  idSubMenuSelected,
}) {
  const [mouseHover, setMouseHover] = useState(0);
  const location = useLocation();
  const isSolar = useSolar();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!openSidebar) {
      setOpenSubMenu(true);
    }
  }, [openSidebar]);

  const onClickLink = () => {
    openSidebar
      ? openSubMenu.id != item.id
        ? setOpenSubMenu({ open: true, id: item.id })
        : setOpenSubMenu({ open: !openSubMenu.open, id: item.id })
      : null;
    dispatch(removeAllItems());
  };

  const validationStyleBackground = () => {
    if (!openSidebar) {
      if (idSubMenuSelected == item.id || item.link == location.pathname) {
            return !isSolar ? "#7EACC4" : "#FF9E73";
          
      } else {
        return "transparent";
      }
    } else {
      if (isSolar && openSubMenu.id !== item.id) {
        return "transparent";
      } else if (location.pathname == item.link) {
        return isSolar ? "#FF9E73" : "#7EACC4";
      } else if (openSubMenu.id == item.id && openSubMenu.open) {
        return isSolar ? "#FF9E73" : "#7EACC4";
      } else {
        return "transparent";
      }
    }
  };

  const validationStyleColor = () => {
    if (item?.subItems?.length > 0) {
      if (idSubMenuSelected == item.id) {
        if (isSolar) {
          return "#FFF";
        } else {
          return "#fff";
        }
      } else if (isSolar) {
        if (openSubMenu.id == item.id && openSubMenu.open) {
          return "#FFF";
        } else {
          return "#FFF";
        }
      } else {
        return "#fff";
      }
    } else if (location.pathname == item.link) {
      if (isSolar) {
        return "#FFF";
      } else {
        return "#2D567B";
      }
    } else if (isSolar) {
      return "#fff";
    } else {
      return "#fff";
    }
  };

  return (
    <SidebarMenu
      id={item.id}
      data={item}
      subItemsLenght={item?.subItems?.length}
      openSidebar={openSidebar}
      openSubMenu={openSubMenu}
      location={location}
      idSubMenuSelected={idSubMenuSelected}
      isSolar={isSolar}
    >
      <Link
        to={item?.subItems?.length > 0 ? null : item.link}
        onClick={onClickLink}
        onMouseEnter={() => setMouseHover(item.id)}
        onMouseLeave={() => setMouseHover(0)}
        style={{
          background: validationStyleBackground(),
        }}
      >
        <div className="icon-label-arrow">
          <div className="icon-menu">
            <div>
              {" "}
              {item["icon"] && (
                <SvgIcon
                  name={item["icon"]}
                  className={`icon icon-${openSidebar ? "xs" : "2xs"}`}
                />
              )}
            </div>
            <span
              style={{
                color: validationStyleColor(),
              }}
            >
              {item.label}
            </span>{" "}
          </div>
          {item?.subItems?.length > 0 && openSidebar && (
            <div className="arrow">
              <KeyboardArrowDownOutlinedIcon fontSize="medium" />
            </div>
          )}
        </div>
      </Link>
      {item?.subItems?.length > 0 && (
        <ul
          style={{
            flex:
              openSubMenu.id == item.id ? (openSubMenu.open ? "1" : "0") : "0",
            marginLeft: 45,
          }}
        >
          {item?.subItems.map((item, index) => (
            <SubMenu key={index} submenuData={item} />
          ))}{" "}
        </ul>
      )}{" "}
      {!openSidebar && mouseHover != 0
        ? openSubMenu.id != mouseHover && (
            <SideSubMenu
              item={item}
              setMouseHover={setMouseHover}
              openSubMenu={openSubMenu}
            />
          )
        : null}
    </SidebarMenu>
  );
}
