import { Field } from "formik";
import React from "react";
import { useSolar } from "../../hooks";
import { Input, Erros, Label } from "./style";

export const ArInput = ({
  title,
  name,
  mask,
  errors,
  touched,
  onchange,
  style,
  icon,
  maxLength,
  type,
  disabled,
  onBlur,
  value,
  autoComplete,
}) => {
  const isSolar = useSolar();
  const formatPhone = e => {
    var value = e;
    var valor = value;
    var retorno = valor.replace(/\D/g, "");
    retorno = retorno.replace(/^0/, "");

    if (retorno.length < 9) {
      retorno = retorno.replace(/^(\d{4})(\d{0,4}).*/, "$1-$2");
    } else {
      retorno = retorno.replace(/^(\d{5})(\d{4}).*/, "$1-$2");
    }
    return retorno;
  };

  const cnpjMask = v => {
    //CNPJ
    v = v.replace(/\D/g, "");

    v = v.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5");

    return v;
  };
  const cpfMask = v => {
    //Remove tudo o que não é dígito
    v = v.replace(/\D/g, "");
    //CPF

    //Coloca um ponto entre o terceiro e o quarto dígitos
    v = v.replace(/(\d{3})(\d)/, "$1.$2");

    //Coloca um ponto entre o terceiro e o quarto dígitos
    //de novo (para o segundo bloco de números)
    v = v.replace(/(\d{3})(\d)/, "$1.$2");

    //Coloca um hífen entre o terceiro e o quarto dígitos
    v = v.replace(/(\d{3})(\d{1,2})$/, "$1-$2");
    return v;
  };

  const maskRg = v => {
    if (v.length <= 12) {
      v = v.toUpperCase().replace(/[^\dX]/g, "");
      return v.replace(/^(\d{1,2})(\d{3})(\d{3})([\dX])$/, "$1.$2.$3-$4");
    }
  };
  const cep = v => {
    v = v.trim();
    if (v.length <= 9) {
      //somente numeros
      v = v.replace(/[^0-9]/g, "");
      v = v.replace(/(\d{5})(\d{3})/, "$1-$2");
      return v;
    }
  };

  const onlyNumbersAndLetters = v => {
    v = v.replace(/[^a-zA-Z0-9\s]/g, '');
    return v;
  };

  const onlyNumber = v => {
    v = v.replace(/[^0-9]/g, "");
    return v;
  };

  const onlyText = v => {
    if (!/\d/.test(v)) {
      return v;
    }
  };
  const formatValue = (fieldProps, value) => {
    onchange && onchange(fieldProps, value);

    if (!!mask) {
      switch (mask) {
        case "onlyNumber":
          return onlyNumber(value);
          break;
        case "onlyText":
          return onlyText(value);
          break;
        case "rg":
          return maskRg(value);
          break;
        case "cpf":
          return cpfMask(value);
          break;
        case "cep":
          return cep(value);
          break;
        case "onlyNumbersAndLetters":
          return onlyNumbersAndLetters(value);
          break;
        case "cnpj":
          return value.length <= 14 ? cpfMask(value) : cnpjMask(value);
          break;
        case "money":
          var v = value.replace(/\D/g, "");
          v = (v / 100).toFixed(2) + "";
          v = v.replace(".", ",");
          v = v.replace(/(\d)(\d{3})(\d{3}),/g, "$1.$2.$3,");
          v = v.replace(/(\d)(\d{3}),/g, "$1.$2,");
          return (value = v);
          break;
        case "phoneNoDDD":
          return formatPhone(value);
          break;
        case "phone":
          var valor = value;
          var retorno = valor.replace(/\D/g, "");
          retorno = retorno.replace(/^0/, "");
          if (retorno.length > 10) {
            retorno = retorno.replace(
              /^(\d\d)(\d{1})(\d{4})(\d{4}).*/,
              "($1) $2 $3-$4"
            );
          } else if (retorno.length > 5) {
            retorno = retorno.replace(
              /^(\d\d)(\d{4})(\d{0,4}).*/,
              "($1) $2-$3"
            );
          } else if (retorno.length > 2) {
            retorno = retorno.replace(/^(\d\d)(\d{0,5})/, "($1) $2");
          } else {
            if (retorno.length != 0) {
              retorno = retorno.replace(/^(\d*)/, "($1");
            }
          }
          return retorno;
          break;
        default:
          console.log("Nenhuma máscara encontrada!");
      }
    } else {
      return value;
    }
  };

  return (
    <div>
      <div className="d-flex">
        <Label htmlFor={title?.replaceAll(" ", "-")}>{title}</Label>
        <i>{icon}</i>
      </div>
      <Field name={name}>
        {fieldProps => (
          <Input
            isSolar={isSolar}
            disabled={disabled}
            type={type}
            maxLength={maxLength}
            autoComplete={autoComplete}
            style={style}
            value={value}
            className={`${errors[name] && touched[name] && "erros"}`}
            {...fieldProps.field}
            onBlur={onBlur}
            onChange={event => {
              fieldProps.field.onChange(event.target.name)(
                formatValue(fieldProps, event.target.value)
              );
            }}
          />
        )}
      </Field>
      {errors[name] && touched[name] ? <Erros>{errors[name]}</Erros> : null}
    </div>
  );
};
