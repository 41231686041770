import styled from "styled-components";

export const SidebarSubMenu = styled.li`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 40px;
  transition: all 0.2s linear;
  margin: 0px;
  padding: 0px;
  background: transparent;
  a {
    height: 40px;
  }
  a > div {
    display: flex;
    align-items: center;
    padding: 5px 7px;
  }
  a > div > span {
    font-weight: normal;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-left: 10px;
    color: ${props =>
      props.location.pathname == props.data.link
        ? props.isSolar
          ? props.theme.colors.blue
          : "#7EACC4"
        : props.isSolar
        ? props.theme.colors.white
        : props.theme.colors.white};
  }

  .icon {
    color: ${props =>
      props.location.pathname == props.data.link
        ? props.isSolar
          ? props.theme.colors.blue
          : "#7EACC4"
        : props.isSolar
        ? props.theme.colors.white
        : props.theme.colors.white};
  }
`;
