import { RegistrationsActionTypes } from "./actionTypes";

const initialState = {
  registrationDetails: [],
};

const RegistrationsReducer = (state = initialState, action) => {
  switch (action.type) {
    case RegistrationsActionTypes.API_RESPONSE_SUCCESS:
      switch (action.payload.actionType) {
        case RegistrationsActionTypes.GET_REGISTRATION_LIST:
          return {
            ...state,
            registrationDetails: action.payload.data,
            getRegistrationLoading: false,
          };
        case RegistrationsActionTypes.GET_REGISTRATION:
          return {
            ...state,
            registrationDetails: action.payload.data,
          };
        case RegistrationsActionTypes.CREATE_UPDATE_REGISTRATION:
          return {
            ...state,
          };
        case RegistrationsActionTypes.GET_SUPERIOR_REVENDA:
          return {
            ...state,
            registrationDetails: action.payload.data,
            getSuperiorLoading: false
          };
        default:
          return state;
      }

    case RegistrationsActionTypes.API_RESPONSE_ERROR:
      switch (action.payload.actionType) {
        case RegistrationsActionTypes.GET_REGISTRATION_LIST:
          return {
            ...state,
            registrationDetails: [],
            getRegistrationLoading: false,
          };
        case RegistrationsActionTypes.CREATE_UPDATE_REGISTRATION:
          return {
            ...state,
          };
        case RegistrationsActionTypes.GET_SUPERIOR_REVENDA:
          return {
            ...state,
            registrationDetails: action.payload.data,
            getSuperiorLoading: false
          };

        default:
          return { ...state };
      }

    case RegistrationsActionTypes.GET_REGISTRATION_LIST:
      return {
        ...state,
        getRegistrationLoading: true,
      };
    case RegistrationsActionTypes.GET_SUPERIOR_REVENDA:
      return {
        ...state,
        getSuperiorLoading: true
      };

    default:
      return state;
  }
};

export default RegistrationsReducer;
