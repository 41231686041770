import React, { useState, useEffect } from "react";
import { CardBody, Card, Input, InputGroup, Button, FormGroup, Label } from "reactstrap";
import { consultaCep } from "../../api"
import { useDispatch, useSelector } from "react-redux";
import { addEndValido, clearNewOrder, getFreteValue } from "../../store/actions";
import InputMask from "react-input-mask";

const Frete = ({ valTot, addFrete, cepCliente }) => {
    const dispatch = useDispatch();
    const { frete } = useSelector(state => ({
        frete: state.Order.newOrder.newOrder.frete,
    }));

    const address = frete.address;
    const [valor, setValor] = useState(0);
    const [btnFreteActive, setBtnFreteActive] = useState(false);
    const [cep, setCep] = useState("");
    const formato = { minimumFractionDigits: 2, style: 'currency', currency: 'BRL' };

    const validaCep = () => {
        consultaCep(cepCliente || cep).then((res) => {
            dispatch(addEndValido(res.address));
        }).catch(console.log);
    }

    useEffect(() => {
        dispatch(clearNewOrder());
        setValor(0);
        setBtnFreteActive(false);
        setCep("");
    }, [])

    useEffect(() => {
        if (address && valTot > 0) {
            dispatch(getFreteValue(valTot))
            addFrete({});
            setValor(0)
            let frete = document.getElementsByName('frete');
            for (let fre of frete) {
                fre.checked = false;
            }
        }
    }, [address, valTot, dispatch])

    return (
        <Card>
            <CardBody>
                <h4 style={{ fontSize: 23, fontWeight: "bold", color: "#202833", marginBottom: 35 }}>Calcular frete e prazo de entrega</h4>
                <p className="text-muted"><small>Partindo de: MPS Campo Grande / MS</small></p>
                <InputGroup style={{ width: "70%" }}>
                    <InputMask
                        mask="99999-999"
                        className="prazo-frete prazo-frete-busca form-control"
                        onFocus={() => setBtnFreteActive(true)}
                        onBlur={() => {
                            setBtnFreteActive(false)
                            validaCep()
                        }}
                        value={cepCliente || cep}
                        onChange={(e) => setCep(e.target.value)}
                        readOnly={cepCliente}
                    />
                    <Button className={btnFreteActive ? "btn-prazo-frete-busca btn-prazo-active" : "btn-prazo-frete-busca"} onClick={() => validaCep()}>
                        <i className="ph-magnifying-glass"></i>
                    </Button>
                </InputGroup>
                <p style={{ margin: "25px 0 0 0" }}>
                    {frete.address ?
                        <span>{frete.address.address} - {frete.address.city}/{frete.address.state}</span>
                        : <span />}
                </p>

                {frete.entrega ?
                    <div>
                        <hr style={{ margin: "25px 0" }} />
                        <h4 style={{ fontSize: 23, fontWeight: "bold", color: "#202833", marginBottom: 35 }}>Selecione a modalidade:</h4>
                        <div onChange={(e) => {
                            setValor(e.target.value)
                        }}>
                            {frete.entrega.map((value, index) => (
                                <FormGroup check className="d-flex mt-3" key={index}>
                                    <Input
                                        type="radio"
                                        value={value.price}
                                        name="frete"
                                        className="modalidade"
                                        onChange={() => addFrete(value)}
                                    />
                                    <Label
                                        className={valor == value.price ? "label-prazo-active" : ""}
                                        style={{ marginLeft: 10, fontWeight: 400 }}>
                                        {value.description} {value.usado_percentual > 0 ? <span style={{ margin: "0 5px" }}>%</span> : ""} - Prazo até {value.delivery_delay} dia(s) - {Number(value.price).toLocaleString("pt-br", formato)}
                                    </Label>
                                </FormGroup>
                            ))}
                        </div>
                    </div>
                    : <div />}

            </CardBody>
        </Card>
    )
}

export default Frete;