import React from "react";
import { Link } from "react-router-dom";
import { formato } from "../../../utils";

const Name = ({ row }) => {
  const data = row.original;
  return <div>{data.name ? data.name : "-"}</div>;
};

const Kwp = ({ row }) => {
  const data = row.original;
  return <div>{data.kwp ? data.kwp : "-"}</div>;
};

const Despesa = ({ row }) => {
  const data = row.original;

  const tarifa = data?.kwh_price ? data.kwh_price : 1;
  const value = data?.avg_account_price ? data.avg_account_price : 1;

  const totalWhenReal = parseFloat(value);

  const totalWhenKwh = parseFloat(value) / parseFloat(tarifa);

  return (
    <div>
      {data.avg_account_price
        ? data.avg_account_type === "real"
          ? totalWhenReal.toLocaleString("pt-br", formato)
          : totalWhenKwh
        : "-"}
    </div>
  );
};

const Email = ({ row }) => {
  const data = row.original;
  return (
    <div title={data.email}>
      {data.email ? data.email.substring(0, 20) + " ..." : "-"}
    </div>
  );
};

const Telefone = ({ row }) => {
  const data = row.original;
  return <div>{data.phone ? data.phone : "-"}</div>;
};

const Endereço = ({ row }) => {
  const data = row.original;
  return (
    <div title={data.address}>
      {data.address ? data.address.substring(0, 20) + " ..." : "-"}
    </div>
  );
};

const Action = ({ row }) => {
  return (
    <div>
      <Link
        to={`/solar/raio-solar-no-seu-endereco/${row.original.id}`}
        style={{ backgroundColor: "#7EACC4", color: "white" }}
        className="btn  btn-sm ms-1"
        size="sm"
      >
        <i className="mdi mdi-eye me-1" />
        Ver
      </Link>
      <Link
        to={`/solar/lead/${row.original.id}/editar`}
        className="btn btn-sm ms-1"
        style={{ backgroundColor: "#FFE0D3", color: "#FF601A" }}
      >
        <i className="bx bx-edit-alt me-1" />
        Editar
      </Link>
    </div>
  );
};

export { Name, Kwp, Despesa, Email, Telefone, Endereço, Action };
