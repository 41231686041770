import React, { useEffect, useState, useCallback } from "react";
import { Col, Row, Button, Spinner } from "reactstrap";
import "./club-customization.scss";
import ImageBgDefault from "../../../assets/images/image-bg-default.png";
import Cropper from "react-easy-crop";
import Slider from "@material-ui/core/Slider";
import Typography from "@material-ui/core/Typography";
import { styles } from "../../../components/crop/styles";
import getCroppedImg from "../../../components/crop/cropImage";
import { useSelector } from "react-redux";
import { apiClient2 } from "../../../api/apiCore2";
import { dataURLtoFile } from "../../../utils";
import { toast } from "react-toastify";

const ClubCustomization = () => {
  const [previewClubCustomization, setPreviewClubCustomization] = useState();
  const [image, setImage] = useState();
  const { user } = useSelector(state => state.Profile.profileDetails);
  const [colorPrimary, setColorPrimary] = useState();
  const [colorSecondary, setColorSecondary] = useState();
  const [colorTertiary, setColorTertiary] = useState();

  useEffect(() => {
    if (image) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewClubCustomization(reader.result);
      };
      reader.readAsDataURL(image);
    } else setPreviewClubCustomization(null);
  }, [image]);

  useEffect(() => {}, [user?.user_info?.img_profile]);

  useEffect(() => {
    setColorPrimary(user?.cores_layout?.primaria);
    setColorSecondary(user?.cores_layout?.secundaria);
    setColorTertiary(user?.cores_layout?.tercearia);
  }, [user]);

  const saveCroppedImageAndColors = async () => {
    saveColors();
    if (previewClubCustomization) {
      try {
        const croppedImage = await getCroppedImg(
          previewClubCustomization,
          croppedAreaPixels,
          rotation
        );

        setCroppedImage(croppedImage);
        var file = dataURLtoFile(croppedImage, "croppedImage.jpeg");
        saveImage("img-logo-parceiro", file);
        // saveImage('userImgProfile', file)
      } catch (e) {
        console.error(e);
      }
    }
  };

  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [rotation, setRotation] = useState(0);
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [, setCroppedImage] = useState(null);
  const [showLoading, setShowLoading] = useState(false);

  const config = {
    headers: {},
  };

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const saveImage = (imageEndPoint, croppedImage) => {
    var formData = new FormData();
    formData.append("file", croppedImage);
    setShowLoading(true);
    apiClient2
      .create(`/users/${imageEndPoint}`, formData, config)
      .then(() => {
        toast.success(`Dados salvos com sucesso`, { theme: "colored" });
        setShowLoading(false);
      })
      .catch(() => {
        toast.error(`Erro ao tentar salvar os dados na base de dados`, {
          theme: "colored",
        });
        setShowLoading(false);
      });
  };

  const saveColors = () => {
    var formData = new FormData();
    formData.append("primaria", colorPrimary);
    formData.append("secundaria", colorSecondary);
    formData.append("tercearia", colorTertiary);
    setShowLoading(true);
    apiClient2
      .create(`/users/cores-parceiro`, formData, config)
      .then(() => {
        toast.success(`Cores alteradas com sucesso`, { theme: "colored" });
        setShowLoading(false);
      })
      .catch(() => {
        toast.error(`Erro ao tentar salvar cores na base de dados`, {
          theme: "colored",
        });
        setShowLoading(false);
      });
  };

  return (
    <React.Fragment>
      <Row>
        <Col id="col-crop" className="position-relative" lg={6}>
          {/* <label htmlFor="input-file-logotipo" className="cursor-pointer hover-img-upload"> */}
          {/* <img src={previewClubCustomization || ImageBgDefault} style={
                {width: 375, height: 375, objectFit: "cover"}
              }/> */}
          <div>
            <div
              className={styles.cropContainer}
              style={{ minHeight: "250px" }}
            >
              <Cropper
                image={previewClubCustomization || ImageBgDefault}
                crop={crop}
                rotation={rotation}
                zoom={zoom}
                aspect={4 / 3}
                onCropChange={setCrop}
                onRotationChange={setRotation}
                onCropComplete={onCropComplete}
                onZoomChange={setZoom}
              />
            </div>

            <div
              id="cropSliders"
              className="d-flex justify-content-between"
              style={{
                width: `${document.getElementById("col-crop")?.offsetWidth}px`,
                maxWidth: "390px",
              }}
            >
              <div
                className={styles.sliderContainer}
                style={{ width: "100px" }}
              >
                <Typography
                  variant="overline"
                  classes={{ root: styles.sliderLabel }}
                >
                  Zoom
                </Typography>
                <Slider
                  value={zoom}
                  min={1}
                  max={3}
                  step={0.1}
                  aria-labelledby="Zoom"
                  classes={{ root: styles.slider }}
                  onChange={(e, zoom) => setZoom(zoom)}
                />
              </div>
              <div
                className={styles.sliderContainer}
                style={{ width: "100px" }}
              >
                <Typography
                  variant="overline"
                  classes={{ root: styles.sliderLabel }}
                >
                  Rotacionar
                </Typography>
                <Slider
                  value={rotation}
                  min={0}
                  max={360}
                  step={1}
                  aria-labelledby="Rotation"
                  classes={{ root: styles.slider }}
                  onChange={(e, rotation) => setRotation(rotation)}
                />
              </div>
              <label
                htmlFor="input-file-logotipo"
                className="btn-default d-flex j-c-center cursor-pointer hover-img-upload light-btn btn btn-success mt-2"
              >
                Imagem
              </label>
            </div>
          </div>
        </Col>
        <Col lg={6} className="h-100" style={{ minHeight: "250px" }}>
          <h3>Personalização Clube</h3>
          <p>
            Adicione seu logotipo para personalizar seu clube aos padrões de sua
            empresa.
          </p>
          <input
            type="file"
            id="input-file-logotipo"
            name="input-file-logotipo"
            hidden=""
            className="d-none input-file"
            accept="image/*"
            onChange={e => {
              const file = e.target.files[0];
              if (file && file.type.substr(0, 5) === "image") setImage(file);
              else setImage(null);
            }}
          ></input>

          <div className="mt-3">
            <p>Clique ao lado para alterar sua foto de perfil.</p>
            <h5>Selecione as cores:</h5>
            <Row>
              <Col lg={4} className="d-flex w-50">
                <div className="flex-grow-1">primária</div>
                <div>
                  <input
                    type="color"
                    className="input-colors"
                    name="secundaria"
                    value={colorPrimary}
                    onChange={e => {
                      setColorPrimary(e.target.value);
                    }}
                    required=""
                  />
                </div>
              </Col>
              <Col lg={4} className="d-flex w-50">
                <div className="flex-grow-1">secundária</div>
                <div>
                  <input
                    type="color"
                    className="input-colors"
                    name="secundaria"
                    value={colorSecondary}
                    onChange={e => {
                      setColorSecondary(e.target.value);
                    }}
                    required=""
                  />
                </div>
              </Col>
              <Col lg={4} className="d-flex w-50">
                <div className="flex-grow-1">terceária</div>
                <div>
                  <input
                    type="color"
                    className="input-colors"
                    name="secundaria"
                    value={colorTertiary}
                    onChange={e => {
                      setColorTertiary(e.target.value);
                    }}
                    required=""
                  />
                </div>
              </Col>
            </Row>
          </div>
          <div
            style={{ right: 0, bottom: 0 }}
            className="d-flex justify-content-end position-absolute"
          >
            <Button
              onClick={saveCroppedImageAndColors}
              variant="contained"
              classes={{ root: styles.cropButton }}
              className="btn-default cursor-pointer hover-img-upload j-c-center"
              disabled={showLoading}
            >
              {showLoading ? <Spinner /> : <p className="m-0">Salvar</p>}
            </Button>
          </div>
        </Col>
      </Row>
    </React.Fragment>
  );
};
export default ClubCustomization;
