import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";

import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { Provider } from "react-redux";
import { QueryClientProvider, QueryClient } from "react-query";
import store from "./store";
import { Contexts } from "./contexts";
import { ThemeProvider } from "styled-components";
import theme from "./styles/themes/colors";
const queryClient = new QueryClient();

const app = (
  <ThemeProvider theme={theme}>
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <Contexts>
          <App />
        </Contexts>
      </QueryClientProvider>
    </Provider>
  </ThemeProvider>
);

ReactDOM.render(app, document.getElementById("root"));
serviceWorker.unregister();
