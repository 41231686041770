import React, { useEffect, useState } from "react";
import { ArInput } from "../../../../components/adias-input";
import { Col, Collapse, Row } from "reactstrap";
import { TitleInputs } from "../../style";

import { getAdressesFromZipCode } from "../../../../api";

export const EnderecoEntrega = ({
  errors,
  touched,
  values,
  setFieldValue,
  isOpenEnderecoEntrega,
  setIsOpenEnderecoEntrega,
}) => {
  const [, setLoadingCep] = useState(false);

  const [dataCep, setDataCep] = useState({
    END_ENT: "",
    BAI_ENT: "",
    MunicipEnt: "",
    UF_ENT: "",
  });

  const toggle = selected => {
    setIsOpenEnderecoEntrega(selected);
  };

  useEffect(() => {
    if (!isOpenEnderecoEntrega) {
      setFieldValue("END_ENT", values.ENDERECO);
      setFieldValue("BAI_ENT", values.BAIRRO);
      setFieldValue("MunicipEnt", values.MUNICIPIO);
      setFieldValue("UF_ENT", values.UF);
      setFieldValue("CEP_ENT", values.CEP);
      setFieldValue("NumEndEnt", values.NumEndCob);
      setFieldValue("COMPL_ENT", values.COMPL);
    } else {
      setFieldValue("END_ENT", "");
      setFieldValue("BAI_ENT", "");
      setFieldValue("MunicipEnt", "");
      setFieldValue("UF_ENT", "");
      setFieldValue("CEP_ENT", "");
      setFieldValue("NumEndEnt", "");
      setFieldValue("COMPL_ENT", "");
    }
  }, [isOpenEnderecoEntrega]);

  const getCep = cep => {
    if (cep?.replace(/[^0-9]+/g, "").length == 8) {
      setLoadingCep(true);
      setFieldValue("END_ENT", "");
      setFieldValue("BAI_ENT", "");
      setFieldValue("MunicipEnt", "");
      setFieldValue("UF_ENT", "");
      getAdressesFromZipCode(cep)
        .then(res => {
          setFieldValue("END_ENT", res.data.logradouro);
          setFieldValue("BAI_ENT", res.data.bairro);
          setFieldValue("MunicipEnt", res.data.localidade);
          setFieldValue("UF_ENT", res?.data?.uf || res?.data?.UF);
          setDataCep({
            END_ENT: res.data.logradouro || "",
            BAI_ENT: res.data.bairro || "",
            MunicipEnt: res.data.localidade || "",
            UF_ENT: res?.data?.uf || res?.data?.UF || "",
          });
        })
        .catch(err => console.log(err));
      setLoadingCep(false);
    }
  };
  return (
    <>
      <TitleInputs>ENDEREÇO DE ENTREGA</TitleInputs>
      <Row style={{ display: "flex" }}>
        <input
          className="form-check-input"
          type="checkbox"
          id="autoSizingCheck"
          onChange={e => {
            setIsOpenEnderecoEntrega(state => !state);
          }}
        />
        <label
          style={{ width: "auto" }}
          className="form-check-label"
          htmlFor="autoSizingCheck"
        >
          Usar o mesmo endereço para entrega
        </label>
      </Row>
      <Collapse isOpen={isOpenEnderecoEntrega}>
        <Row>
          <Col md={3}>
            <ArInput
              title="CEP"
              errors={errors}
              name="CEP_ENT"
              touched={touched}
              onchange={(e, value) => getCep(value)}
              mask="cep"
            />
          </Col>
          <Col md={3}>
            <ArInput
              title="Estado"
              errors={errors}
              name="UF_ENT"
              touched={touched}
            />
          </Col>
          <Col md={3}>
            <ArInput
              title="Cidade"
              errors={errors}
              name="MunicipEnt"
              touched={touched}
            />
          </Col>

          <Col md={3}>
            <ArInput
              title="Bairro"
              errors={errors}
              name="BAI_ENT"
              touched={touched}
            />
          </Col>
        </Row>
        <Row>
          <Col md={4}>
            <ArInput
              title="Endereço"
              errors={errors}
              name="END_ENT"
              touched={touched}
            />
          </Col>
          <Col md={4}>
            <ArInput
              title="Número"
              errors={errors}
              name="NumEndEnt"
              touched={touched}
            />
          </Col>
          <Col md={4}>
            <ArInput
              title="Complemento"
              errors={errors}
              name="COMPL_ENT"
              touched={touched}
            />
          </Col>
        </Row>
      </Collapse>
    </>
  );
};
