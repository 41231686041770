import {
  GoogleMap,
  useJsApiLoader,
  Marker,
  memo,
  React,
} from "./helpers/importer.helper";

const MapContainer = props => {
  const configValue = process.env.GOOGLE_KEY;
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: configValue,
  });

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={{ width: "100%", height: "100%" }}
      zoom={props.zoom}
      center={{
        lat: props.mapCenter.lat,
        lng: props.mapCenter.lng,
      }}
      options={{ mapTypeId: "satellite", tilt: 47.5 }}
    >
      <Marker
        position={{
          lat: props.mapCenter.lat,
          lng: props.mapCenter.lng,
        }}
      />
    </GoogleMap>
  ) : (
    <></>
  );
};

export default memo(MapContainer);
