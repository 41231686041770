export const isShippingPromotion = applyToShipping => {
  return applyToShipping == 1;
} 

export const isProductpromotion = applyToShipping => {
  return applyToShipping == 2;
} 

export const getDiscountValue = (price, valueDiscount, typeRuleDiscount) => {
  const moneyDiscount = 0;
  return Number(typeRuleDiscount == moneyDiscount ? valueDiscount : valueDiscount * (price / 100));
}