import React, { useState } from "react";
import { Card, CardBody, Table, CardTitle } from "reactstrap";
import vendedor1 from "../../assets/images/vendedores/vendedor-1.png";
import vendedor2 from "../../assets/images/vendedores/vendedor-2.png";
import vendedor3 from "../../assets/images/vendedores/vendedor-3.png";
import vendedor4 from "../../assets/images/vendedores/vendedor-4.png";
import vendedor5 from "../../assets/images/vendedores/vendedor-5.png";
import { useColors } from "../../hooks/useColors";
import { useSelector } from "react-redux";
import Loading from "./Loading";

const TopVendors = ({ data }) => {
  const [showedOpt, setShowedOpt] = useState(0);
  const { loadingDashboard } = useSelector(state => ({
    loadingDashboard: state.Dashboard.getDashboardDetailsLoading,
  }));
  const top_vendedores_mes =
    (data?.top_vendedores && data?.top_vendedores.values) || [];
  const top_vendedores =
    (data?.top_vendedores_geral && data?.top_vendedores_geral.values) || [];
  const top_vendedores_semana =
    (data?.top_vendedores_semana && data?.top_vendedores_semana.values) || [];

  const top5Vendors = {
    0: top_vendedores_mes.slice(0, 5),
    1: top_vendedores_semana.slice(0, 5),
    2: top_vendedores.slice(0, 5),
  };
  // const top5Vendors = top_vendedores ? top_vendedores.slice(0, 5) : [];
  const icons = {
    1: vendedor1,
    2: vendedor2,
    3: vendedor3,
    4: vendedor4,
    5: vendedor5,
  };
  const { colorDefault, colorLight } = useColors();

  return (
    <div>
      <Card>
        <CardBody>
          <div className="titulo-top">
            <CardTitle className="mb-0">TOP 5 Vendedores</CardTitle>
            <div className="navegacao">
              <ul
                className="nav nav-pills nav-fill back-nav"
                style={{ backgroundColor: colorLight }}
              >
                <li className="nav-item">
                  <a
                    style={{
                      backgroundColor: showedOpt == 0 && colorDefault,
                      color: showedOpt != 0 && colorDefault,
                    }}
                    className={showedOpt == 0 ? "nav-link active" : "nav-link"}
                    onClick={() => setShowedOpt(0)}
                  >
                    Esse mês
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    style={{
                      backgroundColor: showedOpt == 1 && colorDefault,
                      color: showedOpt != 1 && colorDefault,
                    }}
                    className={showedOpt == 1 ? "nav-link active" : "nav-link"}
                    onClick={() => setShowedOpt(1)}
                  >
                    Essa semana
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    style={{
                      backgroundColor: showedOpt == 2 && colorDefault,
                      color: showedOpt != 2 && colorDefault,
                    }}
                    className={showedOpt == 2 ? "nav-link active" : "nav-link"}
                    onClick={() => setShowedOpt(2)}
                  >
                    Até agora
                  </a>
                </li>
              </ul>
            </div>
          </div>

          {loadingDashboard ? (
            <Loading />
          ) : (
            <Table borderless hover className="tabela-vendedores" responsive>
              <thead>
                <tr>
                  <th style={{ color: colorDefault }}></th>
                  <th style={{ color: colorDefault }}>Nome</th>
                  <th style={{ color: colorDefault }}>Total</th>
                  <th style={{ color: colorDefault }}>% Faturamento</th>
                  <th style={{ color: colorDefault }}>Pedidos</th>
                  <th style={{ color: colorDefault }}>Último Pedido</th>
                </tr>
              </thead>
              <tbody>
                {top5Vendors[showedOpt].map((value, index) => (
                  <tr key={index}>
                    <td>
                      {" "}
                      <img src={icons[index + 1]} className="ranking-pic" />
                    </td>
                    <td>{value.vendedor}</td>
                    <td>R$ {value.valor_total}</td>
                    <td>{value.percentual_do_faturamento}%</td>
                    <td>{value.qtd_pedidos}</td>
                    <td>{value.ultimo_pedido}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          )}
        </CardBody>
      </Card>
    </div>
  );
};

export default TopVendors;
