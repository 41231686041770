import { apiClient } from "./apiCore";
import { apiClient2 } from "./apiCore2";

const getWarrantyDetails = filters => {
  const baseUrl = "/warranty";
  return apiClient2.get(`${baseUrl}`, filters);
};

const getInvoice = invoice => {
  const baseUrl = "api/warranty/getprimarydata";
  return apiClient.create(`${baseUrl}`, invoice);
}

const getWarrantyDownloads = manufacturerId => {
  const baseUrl = `api/warranty/manufacturers/${manufacturerId}/downloads`;
  return apiClient.get(`${baseUrl}`)
}

const sendWarrantyDownload = data => {
  const baseUrl = `api/warranty/download`;
  return apiClient.createWithFile(`${baseUrl}`, data);
}

const deleteWarrantyDownload = id => {
  const baseUrl = `api/warranty/download/${id}`;
  return apiClient.delete(`${baseUrl}`);
}

const getManufacturerWarranty = manufacturer => {
  const baseUrl = `api/warranty/manufacturer`;
  return apiClient.get(`${baseUrl}`, manufacturer);
}

const createWarranty = (files) => {
  const baseUrl = "api/warranty/store";
    const formData = new FormData();
    files.file.forEach(file => {
      formData.append('files[]', file);
    });
    formData.append('receiveData', files.receiveData); 

  return apiClient.create(`${baseUrl}`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export { getWarrantyDetails, getInvoice, getWarrantyDownloads, sendWarrantyDownload, deleteWarrantyDownload, createWarranty, getManufacturerWarranty };
