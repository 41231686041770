//import images
import order1 from "../../assets/images/orders/order-1.png";

const orderItems = [
  {
    id: "01010001",
    img: order1,
    product: "INVERTER LG ARTCOOL VOICE 22000 BTUS QUENTE E FRIO 220V",
    codeDetails: "Cod. Fabricante: 01010001",
    qnt: 1,
    details: "",
    value: "R$:0,00",
  },
  {
    id: "01010001",
    img: order1,
    product: "INVERTER LG ARTCOOL VOICE 22000 BTUS QUENTE E FRIO 220V",
    codeDetails: "Cod. Fabricante: 01010001",
    qnt: 1,
    details: "",
    value: "R$:0,00",
  },
  {
    id: "01010001",
    img: order1,
    product: "INVERTER LG ARTCOOL VOICE 22000 BTUS QUENTE E FRIO 220V",
    codeDetails: "Cod. Fabricante: 01010001",
    qnt: 1,
    details: "",
    value: "R$:0,00",
  },
];

const orderItems2 = [
  {
    id: 1,
    description: "Crédito Bloqueado",
    status: "Aguardando Confirmação do Pagamento",
    input: "01/09/2021 - 15:34:41",
    exit: "01/09/2021 - 15:48:42",
  },
  {
    id: 1,
    description: "Crédito Bloqueado",
    status: "Aguardando Confirmação do Pagamento",
    input: "01/09/2021 - 15:34:41",
  },
];
const paymentReceivedDetails = [
  {
    id: 1,
    isDown: true,
    key: "01010001",
    status: "Cancelado",
    analysis: "-",
    date: "01/092021 - 14:52:42",
    parcels: "1x",
    amount: "R$ 01.101.01",
    ticket: "-",
  },
];
export { orderItems, orderItems2, paymentReceivedDetails };
