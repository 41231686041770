import React from "react";
export default function IconClipBoardText(props) {
  const colorFill = props?.colorFill?.replaceAll("#", "") || "none";
  const colorStroke = props?.colorStroke || "#000";
  const width = props?.width;
  const height = props?.height;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 42 41"
      fill={colorFill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.8828 24.2251H25.9625"
        stroke={colorStroke}
        strokeWidth="2.51353"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.8828 19.2131H25.9625"
        stroke={colorStroke}
        strokeWidth="2.51353"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M25.9675 6.68311H32.2673C32.6015 6.68311 32.922 6.81512 33.1582 7.0501C33.3945 7.28508 33.5273 7.60379 33.5273 7.93611V34.2491C33.5273 34.5815 33.3945 34.9002 33.1582 35.1351C32.922 35.3701 32.6015 35.5021 32.2673 35.5021H9.58808C9.25392 35.5021 8.93345 35.3701 8.69716 35.1351C8.46087 34.9002 8.32812 34.5815 8.32812 34.2491V7.93611C8.32812 7.60379 8.46087 7.28508 8.69716 7.0501C8.93345 6.81512 9.25392 6.68311 9.58808 6.68311H15.8879"
        stroke={colorStroke}
        strokeWidth="2.51353"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.625 11.6951V10.4421C14.625 8.78052 15.2887 7.18699 16.4702 6.01207C17.6516 4.83715 19.254 4.17709 20.9248 4.17709C22.5956 4.17709 24.198 4.83715 25.3794 6.01207C26.5608 7.18699 27.2246 8.78052 27.2246 10.4421V11.6951H14.625Z"
        stroke={colorStroke}
        strokeWidth="2.51353"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
