import {
  GroupRectangles,
  GroupRectanglesBottom,
  React,
  momentFormatDate,
} from "../../helpers/importer.helper";
import Footer from "../Footer";
import "./styles.scss";

export default function PageSix({ dataPdf, formOfPayment }) {
  const maskCnpj = v => {
    if (v?.length == 11) {
      return v
        .replace(/\D/g, "")
        .replace(/(\d{3})(\d)/, "$1.$2")
        .replace(/(\d{3})(\d)/, "$1.$2")
        .replace(/(\d{3})(\d)/, "$1-$2")
        .replace(/(-\d{2})\d+?$/, "$1");
    } else {
      return v
        .replace(/\D+/g, "")
        .replace(/(\d{2})(\d)/, "$1.$2")
        .replace(/(\d{3})(\d)/, "$1.$2")
        .replace(/(\d{3})(\d)/, "$1/$2")
        .replace(/(\d{4})(\d)/, "$1-$2")
        .replace(/(-\d{2})\d+?$/, "$1");
    }
  };
  return (
    <>
      <div id="pageSix" className="scroll" />
      <div className="container-pdf-10">
        <img className="img-background-pdf-10" src={GroupRectangles} />
        <img
          className="img-background-pdf-10-right"
          src={GroupRectanglesBottom}
        />
        <p className="p-pdf-10">
          *Possíveis adequações à rede elétrica existente no local, tais como:
          Aumento de Demanda, Padrão de entrada, Quadro Geral, Cabeamento AC,
          Troca de transformador, Proteções, SPDA, Reles, etc., poderão ser
          solicitadas pela concessionária local ou, por nosso departamento de
          engenharia, visando o correto funcionamento do sistema elétrico e
          fotovoltaico para produzir, com segurança, a energia elétrica para a
          qual foi dimensionado, esses custos, se houverem, correm por conta do
          cliente.
          <br />
          <br />
          Qualquer negócio jurídico destinado à transação envolvendo o tema da
          proposta deve ser submetido ao mútuo acordo prévio entre as partes,
          tanto quanto ao preço (que poderá eventualmente divergir do valor ora
          apresentado), bem como com relação aos produtos ofertados e ao escopo
          de trabalho e de termos gerais. A garantia legal dos serviços é de 90
          dias.
        </p>
        <span>
          De acordo com a proposta acima discriminada, autorizamos a confecção
          do contrato de compra:
        </span>
        <div className="pdf-10-info">
          <div className="pdf-10-info-content">
            <span>
              Local e Data: {dataPdf?.dataOrcamento?.cliente?.endereco} -{" "}
              {momentFormatDate(dataPdf?.dataOrcamento?.orcamento?.DtEmissao)}
            </span>{" "}
            <span> </span>
          </div>
          <div className="pdf-10-info-content">
            <span>Nome Completo: {dataPdf?.dataOrcamento?.cliente?.nome} </span>
          </div>
          <div className="pdf-10-info-content">
            <span>
              CPF:{" "}
              {dataPdf?.dataOrcamento?.cliente?.cpfcnpj &&
                maskCnpj(dataPdf?.dataOrcamento?.cliente?.cpfcnpj)}{" "}
            </span>
          </div>
          <div className="pdf-10-info-content">
            <span>Condição de Pagamento Escolhida: {formOfPayment} </span>
          </div>
        </div>
        <div className="signature-pdf-10">
          <div className="line-pdf-10" />
          Assinatura
        </div>{" "}
        <Footer dataPdf={dataPdf} />
      </div>
    </>
  );
}
