import React from "react";
export default function IconFactory(props) {
  const colorFill = props?.colorFill?.replaceAll("#", "") || "none";
  const colorStroke = props?.colorStroke || "#000";
  const width = props?.width || 21;
  const height = props?.height || 21;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill={colorFill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.76562 10.8125H6.40625"
        stroke={colorStroke}
        strokeWidth="0.9375"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.75 10.8125H10.3906"
        stroke={colorStroke}
        strokeWidth="0.9375"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.7344 13.1562V8.46875H9.92188L6.17188 5.65625V8.46875L2.42188 5.65625V13.1562"
        stroke={colorStroke}
        strokeWidth="0.9375"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.01562 13.1562H14.1406"
        stroke={colorStroke}
        strokeWidth="0.9375"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.7324 8.46875L11.8535 2.31055C11.838 2.19871 11.7826 2.09623 11.6976 2.02195C11.6125 1.94767 11.5035 1.90659 11.3906 1.90625H10.3242C10.2113 1.90659 10.1023 1.94767 10.0173 2.02195C9.93223 2.09623 9.87685 2.19871 9.86133 2.31055L9.07031 7.83594"
        stroke={colorStroke}
        strokeWidth="0.9375"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
