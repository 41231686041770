import ContainerTop from "./ContainerTop";
import ContainerBottom from "./ContainerBottom";

import {
  dataTableBar,
  GroupRectangles,
  GroupRectanglesBottom,
  React,
} from "../../helpers/importer.helper";
import "./styles.scss";

export default function PageThree({ dataPdf, dataProductAttributes }) {
  return (
    <>
      <div id="pageThree" className="scroll" />
      <div className="container-pdf-7">
        <img className="rectangle-page-three" src={GroupRectangles} />
        <img
          className="rectangle-page-three-right"
          src={GroupRectanglesBottom}
        />
        <ContainerTop
          dataTable={dataTableBar}
          dataPdf={dataPdf}
          dataProductAttributes={dataProductAttributes}
        />
        <ContainerBottom dataPdf={dataPdf} />
      </div>
    </>
  );
}
