import React from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { formateDate } from "../../../utils";

const ViewEventModal = ({ isOpen, onClose, event }) => {
  const date = event.date ? formateDate(event.date) : "-";
  const status = event.status_vagas ? event.status_vagas : "-";

  return (
    <Modal isOpen={isOpen} toggle={onClose} className="event-modal">
      <ModalHeader toggle={onClose} className="font-size-14 modal-title-custom">
        View Event
      </ModalHeader>
      <ModalBody>
        <h5 className="mb-3 mt-0">{event.title ? event.title : "-"}</h5>
        <p className="mb-2">
          <span>Date : </span>
          {date}
        </p>
        <p className="mb-2">
          <span>Status : </span>
          {status}
        </p>
      </ModalBody>
    </Modal>
  );
};

export default ViewEventModal;
