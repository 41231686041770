import React from "react";
import { Link } from "react-router-dom";
import { Row, Col, Card, CardBody, Button } from "reactstrap";

const RedemptionBenefits = ({ benefitsProducts }) => {
  return (
    <div>
      <Row>
        <Col xs={12}>
          <h4 className="fw-bold mb-4">Resgate Benefícios</h4>
        </Col>
      </Row>
      <Row className="justify-content-between redemption-benefits">
        {(benefitsProducts || []).map((product, key) => (
          <Col xs={12} lg={3} key={key} className="mb-3 mb-lg-0">
            <Card className="mb-0">
              <CardBody>
                <h5 className="mb-4 px-2">{product.name}</h5>
                <div className="text-center">
                  <div className="mb-4">
                    <img
                      src={product.image}
                      alt="product"
                      className="benefit-image"
                    />
                  </div>
                  <Button
                    className="text-dark"
                    tag={Link}
                    disabled={false}
                    to="#"
                  >
                    RESGATAR
                  </Button>
                </div>
              </CardBody>
            </Card>
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default RedemptionBenefits;
