import { apiClient } from "./apiCore";

const getManufacturersList = () => {
    const baseUrl = "/api/manufacturers";
    return apiClient.get(`${baseUrl}`);
}

const getManufacturerDetails = manufacturerId => {
    const baseUrl = `/api/manufacturers/${manufacturerId}`;
    return apiClient.get(`${baseUrl}`);
}

export {
  getManufacturersList,
  getManufacturerDetails,
};
