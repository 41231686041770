import React from "react";
import { Link } from "react-router-dom";

//utils
import { formateDate } from "../../utils/dateUtils";

const Event = ({ record }) => {
  const youtubeData = record.json_youtube
    ? JSON.parse(record.json_youtube)
    : null;
  const id = record.id;
  const defaultThumbnail = `https://img.youtube.com/vi/${id}/hqdefault.jpg`;
  const thumbnail = youtubeData?.items[0]?.snippet?.thumbnails?.standard?.url
    ? youtubeData.items[0].snippet.thumbnails.standard.url
    : defaultThumbnail;

  const title = youtubeData?.items[0]?.snippet?.title
    ? youtubeData.items[0].snippet.title
    : record.name;
  const upload_at = record.upload_at
    ? formateDate(record.upload_at, "DD/MM/Y hh:m A")
    : "-";
  return (
    <div className="mb-5">
      <Link to={`/tvadias/live/${id}`} className="text-center">
        <div>
          <img
            className="img-thumbnail mb-2"
            width={250}
            src={thumbnail}
            alt="title"
          />
        </div>
        <h5 className="">{title}</h5>
        <p className="mb-0">Enviado em : {upload_at}</p>
      </Link>
    </div>
  );
};

export default Event;
