import { ManufacturersActionTypes } from "./actionTypes"

const INIT_STATE = {
    manufacturers: [],
    loading: false
}

const Manufacturers = (state = INIT_STATE, action) => {
    switch (action.type) {
        case ManufacturersActionTypes.API_RESPONSE_SUCCESS:
            switch(action.payload.actionType) {
                case ManufacturersActionTypes.GET_MANUFACTURERS_LIST:
                return {
                    manufacturers: action.payload.data,
                    loading: false
                };
            }

        case ManufacturersActionTypes.API_RESPONSE_ERROR:
            switch(action.payload.actionType) {
                case ManufacturersActionTypes.GET_MANUFACTURERS_LIST:
                return {
                    manufacturers: [],
                    loading: false
                };
            }

        case ManufacturersActionTypes.GET_MANUFACTURERS_LIST:
            return {
                manufacturers: [],
                loading: true
            }

        default:
            return state;
    }
}

export default Manufacturers;