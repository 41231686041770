
const maskMoney = (value) => {
  console.log(`value`)
  console.log(value)
  const numericValue = value.replace(/\D/g, '');

  const integerPart = numericValue.slice(0, -2) || '0';
  const decimalPart = numericValue.slice(-2).padStart(2, '0');

  const formattedInteger = integerPart.replace(/^0+(?=\d)/, '').replace(/\B(?=(\d{3})+(?!\d))/g, '.');

  return `R$ ${formattedInteger},${decimalPart}`;
};


const maskPercentage = (inputValue) => {

  inputValue = inputValue.replace(/[^\d,.]/g, "");

  inputValue = inputValue.replace(/(,)\1+/g, ",");

  inputValue = inputValue.replace(/(\.)\1+/g, ".");

  inputValue = inputValue.replace(/\s/g, "");

  const decimalCount = inputValue.split(".").length - 1;
  if (decimalCount > 1) {
    inputValue = inputValue.replace(/\.(?=.*\.)/g, "");
  }

  const commaCount = inputValue.split(",").length - 1;
  if (commaCount > 1) {
    inputValue = inputValue.replace(/(,)(?=.*\,)/g, "");
  }

  const parts = inputValue.split(".");
  if (parts[1] && parts[1].length > 2) {
    inputValue = `${parts[0]}.${parts[1].slice(0, 2)}`;
  }
  
  const floatValue = parseFloat(inputValue.replace(",", "."));

  return `${floatValue}%`
};

export const typesRuleDiscountOptions = [
  { id: '1', description: 'Porcentagem', mask: maskPercentage },
  { id: '0', description: 'Dinheiro', mask: maskMoney }
];

export const operatorOptions = [
  { id: '=', description: 'Igual a', type: 'all', selectedDescription: 'igual a', ruleType: 'all' },
  { id: '<', description: 'Menor ou igual que', type: 'number', selectedDescription: 'menor ou igual que', ruleType: 'all' },
  { id: '>', description: 'Maior ou igual que', type: 'number', selectedDescription: 'maior ou igual que', ruleType: 'all'  },
  { id: '|', description: 'Qualquer uma das opções', type: 'string', selectedDescription: 'pode ser qualquer uma das opções', ruleType: 'all'  }
];