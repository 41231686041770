import React, { useEffect, useState } from "react";

import { Button, Col, Container, Row } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import DocumentsTable from "./DocumentsTable";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import AddWarrantyDownload from "../modalAddDocuments";
import { deleteWarrantyDownload, getWarrantyDownloads } from "../../../../store/actions";

const Documents = () => {

  const { manufacturerId } = useParams();

  const dispatch = useDispatch();

  const {
    documents,
    loadingList,
    errorList,
    loadingDelete,
    successDeleted,
    downloadAdded,
    errorDelete
  } = useSelector(state => ({
    documents: state.WarrantyDownload.list.downloads,
    loadingList: state.WarrantyDownload.list.loading,
    errorList: state.WarrantyDownload.list.error,
    loadingDelete: state.WarrantyDownload.delete.loading,
    successDeleted: state.WarrantyDownload.delete.successDeleted,
    downloadAdded: state.WarrantyDownload.send.downloadSent,
    errorDelete: state.WarrantyDownload.delete.error
  }));

  const [modalAddDocumentIsOpen, setModalAddDocumentIsOpen] = useState(false);

  const toggleAddDocument = () => {
    setModalAddDocumentIsOpen(!modalAddDocumentIsOpen);
  }

  const handleClickDelete = (selectedDocument) => {
    dispatch(deleteWarrantyDownload(selectedDocument.id));
  };

  useEffect(() => {
    if (manufacturerId) {
      dispatch(getWarrantyDownloads(manufacturerId));
    }
  }, [manufacturerId]);

  useEffect(() => {
    if (errorList) {
      toast.error('Erro ao buscar documentos');
    }
  }, [errorList]);

  useEffect(() => {
    if (successDeleted) {
      toast.success('Documento deletado');
    }

    if (errorDelete) {
      toast.error('Erro ao deletar documento');
    }
  }, [successDeleted, errorDelete]);

  useEffect(() => {
    if (successDeleted || downloadAdded) {
      dispatch(getWarrantyDownloads(manufacturerId));
    }
  }, [successDeleted, downloadAdded]);

  return (
    <React.Fragment>
      <div>
        <Container>
          <Row className="d-flex justify-content-between align-items-end">
            <Col>
              <h5>
                <i className="fa fa-list"></i> Documentos:
              </h5>
            </Col>
            <Col>
              <Button
                style={{ color: 'white' }}
                disabled={loadingList}
                className="btn btn-success float-end mb-2"
                onClick={toggleAddDocument}
              >
                Enviar novo documento
              </Button>
            </Col>
          </Row>

          <DocumentsTable
            documents={documents}
            loadingList={loadingList}
            loadingDelete={loadingDelete}
            handleClickDelete={handleClickDelete}
          />
          <AddWarrantyDownload
            isOpen={modalAddDocumentIsOpen}
            toggle={toggleAddDocument}
            manufacturerId={manufacturerId}
          />
        </Container>
      </div>
    </React.Fragment>
  );
}

export default Documents;