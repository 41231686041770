import styled from "styled-components";

export const CancelCard = styled.div`
    width: 100%;
    background-color: #FFE8E8;
    border-radius: 5px;
    padding: 20px
`;

export const CancelTitle = styled.h3`
    font-weight: 500;
    font-size: 16px;
    color: #B80000; 
`;
