import { CaretDown, CaretUp } from "phosphor-react";
import React from "react";
import { Row, Col, Card, CardBody, Spinner } from "reactstrap";
import { useColors } from "../../hooks/useColors";

// utils
import { displayCurreny } from "../../utils/index";
import { useSelector } from "react-redux";
import Loading from "./Loading";

const MiniWidget = ({ data }) => {
  const faturados = data?.faturados || {};
  const formattedAverageTicket =
    faturados && faturados.ticket_medio
      ? displayCurreny(faturados.ticket_medio)
      : "";
  const { colorDefault } = useColors();
  const { loadingDashboard } = useSelector(state => ({
    loadingDashboard: state.Dashboard.getDashboardDetailsLoading,
  }));

  return (
    <React.Fragment>
      <Row className="card-min">
        <Col md={6} xl={3}>
          <Card className="mb-0">
            <CardBody className="miniwidget">
              <div>
                <h6 className="mb-3" style={{ color: colorDefault }}>
                  Faturado neste mês
                </h6>
                <h4 className="mb-3 fw-bold">
                  {loadingDashboard ? (
                    <Loading />
                  ) : faturados && faturados.faturado_mes ? (
                    faturados.faturado_mes
                  ) : (
                    ""
                  )}
                </h4>
                <p
                  className={
                    faturados &&
                    faturados.percentual_valor &&
                    faturados.percentual_valor > 0
                      ? "mb-3 text-success texto-min"
                      : "mb-3 text-danger texto-min"
                  }
                >
                  {faturados && faturados.percentual_valor
                    ? faturados.percentual_valor
                    : ""}{" "}
                  %
                  {faturados &&
                  faturados.percentual_valor &&
                  faturados.percentual_valor > 0 ? (
                    <CaretUp className="ms-1" />
                  ) : (
                    <CaretDown className="ms-1" />
                  )}
                </p>
              </div>
            </CardBody>
          </Card>
        </Col>

        <Col md={6} xl={3}>
          <Card className="mb-0">
            <CardBody className="miniwidget">
              <div>
                <h6 className="mb-3" style={{ color: colorDefault }}>
                  Pedidos neste mês
                </h6>
                <h4 className="mb-3 fw-bold">
                  {loadingDashboard ? (
                    <Loading style={{ color: "#FF9E73"}} />
                  ) : faturados && faturados.total_pedidos ? (
                    faturados.total_pedidos
                  ) : (
                    ""
                  )}
                </h4>
                <p
                  className={
                    faturados &&
                    faturados.percentual_pedido &&
                    faturados.percentual_pedido > 0
                      ? "mb-3 text-success texto-min"
                      : "mb-3 text-danger texto-min"
                  }
                >
                  {faturados && faturados.percentual_pedido
                    ? faturados.percentual_pedido
                    : ""}{" "}
                  %
                  {faturados &&
                  faturados.percentual_pedido &&
                  faturados.percentual_pedido > 0 ? (
                    <CaretUp className="ms-1" />
                  ) : (
                    <CaretDown className="ms-1" />
                  )}
                </p>
              </div>
            </CardBody>
          </Card>
        </Col>

        <Col md={6} xl={3}>
          <Card className="mb-0">
            <CardBody className="miniwidget">
              <div>
                <h6 className="mb-3" style={{ color: colorDefault }}>
                  Ticket Médio
                </h6>
                <h4 className="mb-3 fw-bold">
                  {loadingDashboard ? <Loading /> : formattedAverageTicket}
                </h4>
                <p
                  className={
                    faturados &&
                    faturados.pedidos_em_aberto &&
                    faturados.pedidos_em_aberto > 0
                      ? "mb-3 text-success texto-min"
                      : "mb-3 text-danger texto-min"
                  }
                >
                  {faturados && faturados.percentual_ticket_medio
                    ? faturados.percentual_ticket_medio
                    : ""}{" "}
                  %
                  {faturados &&
                  faturados.pedidos_em_aberto &&
                  faturados.pedidos_em_aberto > 0 ? (
                    <CaretUp className="ms-1" />
                  ) : (
                    <CaretDown className="ms-1" />
                  )}
                </p>
              </div>
            </CardBody>
          </Card>
        </Col>

        <Col md={6} xl={3}>
          <Card className="mb-0">
            <CardBody className="miniwidget">
              <div>
                <h6 className="mb-3" style={{ color: colorDefault }}>
                  Pedidos em aberto
                </h6>
                <h4 className="mb-3 fw-bold">
                  {loadingDashboard ? (
                    <Loading />
                  ) : faturados && faturados.pedidos_em_aberto ? (
                    faturados.pedidos_em_aberto
                  ) : (
                    ""
                  )}
                </h4>
                <p className="mb-3 text-muted texto-min">
                  R${" "}
                  {faturados && faturados.vlr_pedidos_em_aberto
                    ? faturados.vlr_pedidos_em_aberto
                    : ""}
                </p>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default MiniWidget;
