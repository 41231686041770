import { CaretDown, CaretUp } from "phosphor-react";
import React from "react";
import { Col, Row } from "react-bootstrap";
import UserIcon from '../../../components/icons/user';

export const TitleAndArrow = ({ formVisible, setIsOpen, isOpen }) => {
  return (
    <Row>
      <Col md={11}>
        <p
          className="mb-0 d-flex align-items-center"
          style={{ fontWeight: "bold" }}
        >
          <UserIcon
            style={{
              marginRight: 30,
            }}
            color="#7EACC4"
            size={25}
            weight="light"
          />

          {formVisible.prospect
            ? "Prospect"
            : formVisible.client
            ? "Cliente"
            : "Parceiro"}
        </p>
      </Col>
      <Col md={1}>
        <a
          href="#"
          style={{
            width: "fit-content",
            marginLeft: "auto",
            display: "block",
          }}
          onClick={event => {
            event.preventDefault();
            setIsOpen(!isOpen);
          }}
        >
          {isOpen ? (
            <CaretUp size={18} weight="bold" />
          ) : (
            <CaretDown size={18} weight="bold" />
          )}
        </a>
      </Col>
    </Row>
  );
};
