import React from "react";
import { default as ReactSelect } from "react-select";
import { components } from "react-select";

const allOption = {
  label: "Select All",
  value: "*",
};

const Option = props => {
  return (
    <div>
      <components.Option {...props}>
        <span className="font-weight-normal">{props.label}</span>
        <span className="float-right">
          {props.isSelected ? <i className="mdi mdi-check"></i> : null}{" "}
        </span>
      </components.Option>
    </div>
  );
};

const MultiValue = props => {
  let labelToBeDisplayed = `${props.data.label}`;
  if (props.data.value === allOption.value) {
    labelToBeDisplayed = "All Selected";
  }
  return (
    <components.MultiValue {...props}>
      <span>{labelToBeDisplayed}</span>
    </components.MultiValue>
  );
};

const ValueContainer = ({ children, getValue, ...props }) => {
  let maxToShow = 2;
  var length = getValue().length;
  let shouldBadgeShow = length > maxToShow;
  let displayLength = length - maxToShow;

  const currentValues = getValue();

  let toBeRendered = [];
  let allIdx = currentValues.findIndex(val => val.value === allOption.value);

  if (allIdx !== -1) {
    toBeRendered = [];
    for (const a of React.Children.toArray(children)) {
      const val = a ? a : {};
      if (typeof val.type === "function" && !val.props.data) {
        toBeRendered.push(val);
      } else if (
        val.props &&
        val.props.data &&
        val.props.data.value === allOption.value
      ) {
        toBeRendered.push(val);
      }
    }
    shouldBadgeShow = false;
  } else {
    let idx = 1;
    for (const a of React.Children.toArray(children)) {
      const val = a || {};
      if (typeof val.type === "function" && !val.props.data) {
        toBeRendered.push(val);
      } else if (idx <= maxToShow) {
        toBeRendered.push(val);
        idx++;
      }
    }
  }
  return (
    <components.ValueContainer {...props}>
      {toBeRendered}
      {shouldBadgeShow ? (
        <div className="root">
          <span> + {displayLength}</span>
        </div>
      ) : null}
    </components.ValueContainer>
  );
};

const CustomClearText = () => {
  return (
    <>
      <i className="mdi mdi-close cursor-pointer"></i>
    </>
  );
};

const ClearIndicator = props => {
  const {
    children = <CustomClearText />,
    getStyles,
    innerProps: { ref, ...restInnerProps },
  } = props;
  return (
    <div
      {...restInnerProps}
      ref={ref}
      style={getStyles("clearIndicator", props)}
    >
      <div style={{ padding: "0px 5px" }}>{children}</div>
    </div>
  );
};

/**
 * Custom select component
 * @param {*} props
 */
const CustomSelect = props => {
  let options = props.options || [];
  if (props.allowSelectAll) {
    return (
      <ReactSelect
        {...props}
        options={[allOption, ...options]}
        onChange={(selected, event) => {
          if (selected !== null && selected.length > 0) {
            if (selected.some(val => val.value === allOption.value)) {
              return props.onChange([allOption, ...props.options]);
            }
            let result = [];

            if (selected.length === props.options.length) {
              if (selected.includes(allOption)) {
                result = selected.filter(
                  option => option.value !== allOption.value
                );
              } else if (event.action === "select-option") {
                result = [{ ...allOption }, ...props.options];
              }
              return props.onChange(result);
            }
          }

          return props.onChange(selected);
        }}
        components={{
          MultiValue,
          ValueContainer,
          Option,
          ClearIndicator,
        }}
      />
    );
  }
  const components = props.components
    ? { ClearIndicator, ...props.components }
    : { ClearIndicator };
  return <ReactSelect {...props} components={components} />;
};

export default CustomSelect;
