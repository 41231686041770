import React, { useState } from "react";
import { TitleInputs } from "../../style";
import { ArInput } from "../../../../../components/adias-input";
import { Col, Row } from "reactstrap";
import { Select, TitleSelect } from "./styles";
import { getAdressesFromZipCode } from "../../../../../api";

export const Endereco = ({
  errors,
  touched,
  setFieldValue,
  setData,
  values,
  data,
  handleBlur,
}) => {
  const [, setLoadingCep] = useState(false);

  const getCep = cep => {
    if (cep?.replace(/[^0-9]+/g, "").length == 8) {
      setLoadingCep(true);
      setFieldValue("ENDERECO_PF", "");
      setFieldValue("BAIRRO_PF", "");
      setFieldValue("MUNICIPIO_PF", "");
      setFieldValue("UF_PF", "");
      getAdressesFromZipCode(cep)
        .then(res => {
          setFieldValue("ENDERECO_PF", res.data.logradouro);
          setFieldValue("BAIRRO_PF", res.data.bairro);
          setFieldValue("MUNICIPIO_PF", res.data.localidade);
          setFieldValue("UF_PF", res.data.uf);
        })
        .catch(err => console.log(err));
      setLoadingCep(false);
    }
  };
  return (
    <>
      {" "}
      <TitleInputs>ENDEREÇO</TitleInputs>
      <Row>
        {" "}
        <Col md={3}>
          <ArInput
            title="CEP"
            errors={errors}
            name="CEP_PF"
            touched={touched}
            onchange={(e, value) => getCep(value)}
            mask="cep"
            onBlur={handleBlur}
          />
        </Col>
        <Col md={6}>
          <ArInput
            title="Rua"
            errors={errors}
            name="ENDERECO_PF"
            touched={touched}
            disabled={values.ENDERECO_PF != "" ? true : false}
            onBlur={handleBlur}
          />
        </Col>{" "}
        <Col md={3}>
          <ArInput
            title="Número"
            errors={errors}
            name="NUMEROEND_PF"
            touched={touched}
            onBlur={handleBlur}
          />
        </Col>
      </Row>
      <Row>
        {" "}
        <Col md={6}>
          <ArInput
            title="Complemento"
            errors={errors}
            name="COMPLEM_PF"
            touched={touched}
            onBlur={handleBlur}
          />
        </Col>
        <Col md={6}>
          <ArInput
            title="Bairro"
            errors={errors}
            name="BAIRRO_PF"
            touched={touched}
            disabled={values.BAIRRO_PF != "" ? true : false}
            onBlur={handleBlur}
          />
        </Col>
      </Row>
      <Row>
        {" "}
        <Col md={6}>
          <ArInput
            title="Cidade"
            errors={errors}
            name="MUNICIPIO_PF"
            touched={touched}
            disabled={true}
            onBlur={handleBlur}
          />
        </Col>
        <Col md={6}>
          <ArInput
            title="Estado"
            errors={errors}
            name="UF_PF"
            touched={touched}
            disabled={true}
            onBlur={handleBlur}
          />
        </Col>
      </Row>
      <Row>
        {" "}
        <Col md={data.rmatividades == "solar" ? 4 : 6}>
          <TitleSelect>Como conheceu a A.Dias?</TitleSelect>
          <Select
            onChange={e =>
              setData(state => {
                return { ...state, conheceu_adias: e.target.value };
              })
            }
          >
            <option value="0">Selecione uma Opção</option>
            <option value="google">Google</option>
            <option value="indicacao_revenda">Indicação revenda</option>
            <option value="indicacao_fabrica">Indicação fábrica</option>
            <option value="indicacao_cliente">Indicaçao cliente</option>
            <option value="revista">Revista</option>
            <option value="radio">Rádio</option>
            <option value="ativo_prospect">Ativo no prospect</option>
            <option value="site">Site</option>
            <option value="visita">Recebi uma visita da A.Dias</option>
            <option value="cadastro_supervisor">
              Cadastro feito pelo supervisor em visita
            </option>
            <option value="outros">Outros</option>
          </Select>
        </Col>
        <Col md={data.rmatividades == "solar" ? 4 : 6}>
          <TitleSelect>Ramo de Atividade</TitleSelect>
          <Select
            onChange={e =>
              setData(state => {
                return { ...state, rmatividades: e.target.value };
              })
            }
          >
            <option value="0">Selecione uma Opção</option>
            <option value="revenda">
              Ar Condicionado - Revenda / Instalador
            </option>
            <option value="arquiteto">
              Ar Condicionado - Arquiteto / Engenheiro
            </option>
            <option value="solar">Energia Solar</option>
          </Select>
        </Col>
        {data.rmatividades == "solar" && (
          <Col md={4}>
            <ArInput
              title="Nome do Consultor"
              errors={errors}
              name="nome_consultor"
              touched={touched}
            />
          </Col>
        )}
      </Row>
    </>
  );
};
