import React, { useState } from 'react';
import { Container, Card, CardBody, Form, FormGroup, Label, Row, Col, Input, InputGroup, Alert, Button } from 'reactstrap';
import "./eventsCreate.css";
import Breadcrumb from "../../components/Breadcrumb";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const EventsCreate = () => {
    const [showedOpt, setShowedOpt] = useState(0)
    return (
        <React.Fragment>
            <div className="page-content events-create">
                <Container>
                    <h3>Incluir <small className='subtitulo-incluir'>novo evento</small></h3>
                    <Breadcrumb
                        title="Página Inicial"
                        breadcrumbItem="incluir novo evento" />
                    <Card>
                        <CardBody>
                            <div className="navegacao">
                                <h4 className='titulo-form'>Insira os dados do evento</h4>
                                <ul className="nav nav-pills nav-fill back-nav">
                                    <li className="nav-item">
                                        <a className={showedOpt == 0 ? "nav-link active" : "nav-link"} onClick={() => setShowedOpt(0)}>Evento</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className={showedOpt == 1 ? "nav-link active" : "nav-link"} onClick={() => setShowedOpt(1)}>Turmas</a>
                                    </li>
                                </ul>
                            </div>
                            <hr className='linha-incluir' />
                            {showedOpt == 0 &&
                                <div>
                                    <Form>
                                        <FormGroup row>
                                            <Label
                                                for="exampleEmail"
                                                sm={2}>
                                                Titulo
                                            </Label>
                                            <Col sm={6}>
                                                <Input
                                                    id="tituloEvento"
                                                    name="tituloEvento"
                                                    placeholder="Titulo do evento"
                                                    type="text"/>
                                            </Col>
                                        </FormGroup>
                                    </Form>
                                    <Row>
                                        <Col>
                                            <Form>
                                                <FormGroup row>
                                                    <Label
                                                        for="exampleEmail"
                                                        sm={4} >
                                                        Região:
                                                    </Label>
                                                    <Col sm={6}>
                                                        <Input
                                                            id="exampleSelect"
                                                            name="select"
                                                            type="select">
                                                            <option>selecione um estado</option>
                                                            <option>Acre</option>
                                                            <option>Alagoas</option>
                                                            <option>Amazonas</option>
                                                            <option>Amapá</option>
                                                            <option>Bahia</option>
                                                            <option>Ceará</option>
                                                            <option>Distrito federal</option>
                                                            <option>Espírito Santo</option>
                                                            <option>Goiás</option>
                                                            <option>Maranhão</option>
                                                            <option>Minas Gerais</option>
                                                            <option>Mato Grosso do Sul</option>
                                                            <option>Pará</option>
                                                            <option>Paraíba</option>
                                                            <option>Pernambuco</option>
                                                            <option>Piauí</option>
                                                            <option>Paraná</option>
                                                            <option>Rio de Janeiro</option>
                                                            <option>Rio Grande do Norte</option>
                                                            <option>Rondônia</option>
                                                            <option>Roraima</option>
                                                            <option>Rio Grande do Sul</option>
                                                            <option>Santa Catarina</option>
                                                            <option>Sergipe</option>
                                                            <option>São Paulo</option>
                                                            <option>Tocantins</option>
                                                        </Input>
                                                    </Col>
                                                </FormGroup>
                                            </Form>
                                        </Col>
                                        <Col>
                                            <Form>
                                                <FormGroup row>
                                                    <Col sm={6}>
                                                        <Input
                                                            id="exampleSelect"
                                                            name="select"
                                                            type="select">
                                                            <option>selecione a cidade</option>
                                                            <option>Acre</option>
                                                            <option>Alagoas</option>
                                                            <option>Amazonas</option>
                                                            <option>Amapá</option>
                                                            <option>Bahia</option>
                                                            <option>Ceará</option>
                                                            <option>Distrito federal</option>
                                                            <option>Espírito Santo</option>
                                                            <option>Goiás</option>
                                                            <option>Maranhão</option>
                                                            <option>Minas Gerais</option>
                                                            <option>Mato Grosso do Sul</option>
                                                            <option>Pará</option>
                                                            <option>Paraíba</option>
                                                            <option>Pernambuco</option>
                                                            <option>Piauí</option>
                                                            <option>Paraná</option>
                                                            <option>Rio de Janeiro</option>
                                                            <option>Rio Grande do Norte</option>
                                                            <option>Rondônia</option>
                                                            <option>Roraima</option>
                                                            <option>Rio Grande do Sul</option>
                                                            <option>Santa Catarina</option>
                                                            <option>Sergipe</option>
                                                            <option>São Paulo</option>
                                                            <option>Tocantins</option>
                                                        </Input>
                                                    </Col>
                                                </FormGroup>
                                            </Form>
                                        </Col>
                                    </Row>
                                    <Form>
                                        <FormGroup row>
                                            <Label
                                                for="exampleEmail"
                                                sm={2}>
                                                Endereço:
                                            </Label>
                                            <Col sm={7}>
                                                <Input
                                                    id="endereco"
                                                    name="endereco"
                                                    placeholder="Digite um local"
                                                    type="text" />
                                            </Col>
                                        </FormGroup>
                                    </Form>
                                    <Form>
                                        <FormGroup row>
                                            <Label
                                                for="exampleEmail"
                                                sm={2}>
                                                Insira o texto aqui:
                                            </Label>
                                            <Col sm={7}>
                                                <ReactQuill theme="snow" id="texto" placeholder="Digite um local"/>
                                            </Col>
                                        </FormGroup>
                                    </Form>
                                    <h3>Dados para Envio de Email</h3>
                                    <p className='dados-p'>Estes dados serão enviados para os inscritos 2 dias antes do evento.</p>
                                    <Form>
                                        <FormGroup row>
                                            <Label
                                                for="exampleEmail"
                                                sm={2} >
                                                Template:
                                            </Label>
                                            <Col sm={4}>
                                                <Input
                                                    id="exampleSelect"
                                                    name="select"
                                                    type="select">
                                                    <option>selecione</option>
                                                    <option>Notificação lembrete de evento</option>
                                                    <option>Novo participante castrado no evento</option>
                                                    <option>Comunicação,Marketing e eventos</option>
                                                    <option>Notificação de inscrição em evento</option>
                                                    <option>Notificação agradecimento de presença em evento</option>
                                                </Input>
                                            </Col>
                                        </FormGroup>
                                    </Form>
                                    <Form>
                                        <FormGroup row>
                                            <Label
                                                for="exampleEmail"
                                                sm={2}>
                                                Pequeno texto:
                                            </Label>
                                            <Col sm={6}>
                                                <Input
                                                    id="pequenoTexto"
                                                    name="endereco"
                                                    placeholder="Digite um local"
                                                    type="textarea" />
                                            </Col>
                                        </FormGroup>
                                    </Form>
                                    <p className='dados-p'>Este Email será enviado 2 dias depois para todos os presentes.</p>
                                    <Form>
                                        <FormGroup row>
                                            <Label
                                                for="exampleEmail"
                                                sm={2} >
                                                Template Email para Presentes:
                                            </Label>
                                            <Col sm={4}>
                                                <Input
                                                    id="exampleSelect"
                                                    name="select"
                                                    type="select">
                                                    <option>selecione</option>
                                                    <option>Notificação lembrete de evento</option>
                                                    <option>Novo participante castrado no evento</option>
                                                    <option>Comunicação,Marketing e eventos</option>
                                                    <option>Notificação de inscrição em evento</option>
                                                    <option>Notificação agradecimento de presença em evento</option>
                                                </Input>
                                            </Col>
                                        </FormGroup>
                                    </Form>
                                    <Form>
                                        <FormGroup row>
                                            <Label
                                                for="exampleEmail"
                                                sm={2}>
                                                Pequeno texto:
                                            </Label>
                                            <Col sm={6}>
                                                <Input
                                                    id="pequenoTexto"
                                                    name="endereco"
                                                    placeholder="Digite um local"
                                                    type="textarea" />
                                            </Col>
                                        </FormGroup>
                                    </Form>
                                    <Alert>
                                        <p className='alert-p'>O Clube A.Dias Envia os Seguintes parâmetros e você pode usa-los como quiser no Mailer:</p>
                                        <ul><li>Nome do Participante = #nome#</li></ul>
                                        <ul><li>Nome da Turma = #turma#</li></ul>
                                        <ul><li> Nome do Evento = #evento#</li></ul>
                                        <ul><li>Data e Hora de Início = #inicio#</li></ul>
                                        <ul><li> Data e Hora do Fim = #fim#</li></ul>
                                        <ul><li>Endereço = #endereco#</li></ul>
                                        <ul><li> Pequeno Texto = #texto# (Exceto template Cadastrado no Evento)</li></ul>
                                        <p className='alert-p' >Tais parâmetros devem ser inseridos no template inserido no Mailer</p>
                                    </Alert>
                                </div>
                            }
                            {showedOpt == 1 &&
                                <div>
                                    <div>
                                        <Button className='botao-turma'>
                                            <i className="ph-plus"></i>
                                            Nova turma
                                        </Button>
                                    </div>
                                    <div className='engloba-formulario'>
                                        <Form>
                                            <FormGroup row>
                                                <Label
                                                    for="exampleEmail"
                                                    sm={3}>
                                                    Turma:
                                                </Label>
                                                <Col sm={6}>
                                                    <Input
                                                        id="tituloEvento"
                                                        name="tituloEvento"
                                                        placeholder="Turma"
                                                        type="text"
                                                    />
                                                </Col>
                                            </FormGroup>
                                        </Form>
                                        <Form>
                                            <FormGroup row>
                                                <Label
                                                    for="exampleEmail"
                                                    sm={3}>
                                                    vagas Geral:
                                                </Label>
                                                <Col sm={9} className='d-flex'>
                                                    <Input
                                                        id="tituloEvento"
                                                        name="tituloEvento"
                                                        placeholder=""
                                                        type="number"
                                                    />
                                                    <p>* Coloque - 1 para vagas ilimitadas</p>
                                                </Col>
                                            </FormGroup>
                                        </Form>
                                        <Form>
                                            <FormGroup row>
                                                <Label
                                                    for="exampleEmail"
                                                    sm={3}>
                                                    vagas por empresa:
                                                </Label>
                                                <Col sm={9} className='d-flex'>
                                                    <Input
                                                        id="tituloEvento"
                                                        name="tituloEvento"
                                                        placeholder=""
                                                        type="number"
                                                    />
                                                    <p>* Coloque - 1 para vagas ilimitadas</p>
                                                </Col>
                                            </FormGroup>
                                        </Form>
                                        <Form>
                                            <FormGroup row>
                                                <Label
                                                    for="dataInicio"
                                                    sm={3}>
                                                    Data:
                                                </Label>
                                                <Col sm={5}>
                                                    <InputGroup>
                                                        <Input placeholder="Data inicio" />
                                                        <Button className='botao-input'>
                                                            <i className="ph-calendar-blank"> </i>
                                                        </Button>
                                                    </InputGroup>
                                                </Col>
                                            </FormGroup>
                                        </Form>
                                        <Form>
                                            <FormGroup row>
                                                <Label
                                                    for="dataFinal"
                                                    sm={3}>
                                                    Até:
                                                </Label>
                                                <Col sm={5}>
                                                    <InputGroup>
                                                        <Input placeholder="Data final" />
                                                        <Button className='botao-input'>
                                                            <i className="ph-calendar-blank"> </i>
                                                        </Button>
                                                    </InputGroup>
                                                </Col>
                                            </FormGroup>
                                        </Form>

                                        <Row>
                                            <Col>
                                                <Form>
                                                    <FormGroup row>
                                                        <Label
                                                            for="horario"
                                                            sm={3} >
                                                            Horário:
                                                        </Label>
                                                        <Col sm={2}>
                                                            <Input
                                                                id="horario"
                                                                name="select"
                                                                type="number"
                                                                placeholder='Hora'>
                                                            </Input>
                                                        </Col>
                                                        <Col sm={2}>
                                                            <Input
                                                                id="horario"
                                                                name="horario"
                                                                type="number"
                                                                placeholder='Minutos'>
                                                            </Input>
                                                        </Col>
                                                    </FormGroup>
                                                </Form>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <Form>
                                                    <FormGroup row>
                                                        <Label
                                                            for="horario"
                                                            sm={3} >
                                                            Às:
                                                        </Label>
                                                        <Col sm={2}>
                                                            <Input
                                                                id="horario"
                                                                name="select"
                                                                type="number"
                                                                placeholder='Hora'>
                                                            </Input>
                                                        </Col>
                                                        <Col sm={2}>
                                                            <Input
                                                                id="horario"
                                                                name="horario"
                                                                type="number"
                                                                placeholder='Minutos'>
                                                            </Input>
                                                        </Col>
                                                    </FormGroup>
                                                </Form>
                                            </Col>
                                        </Row>
                                        <Form>
                                            <FormGroup row>
                                                <Label
                                                    for="exampleEmail"
                                                    sm={3} > Segmentos:</Label>
                                                <Col sm={4}>
                                                    <Input
                                                        id="exampleSelect"
                                                        name="select"
                                                        type="select"
                                                        multiple>
                                                        <option>Administradores</option>
                                                        <option>Diretorias</option>
                                                        <option>Gerente</option>
                                                        <option>Supervisor</option>
                                                        <option>Vendedor revenda</option>
                                                        <option>Assistente revenda</option>
                                                        <option>Funcionário clube</option>
                                                        <option>Funcionário revenda</option>
                                                        <option>Usuarios</option>
                                                        <option>Vendedor consumidor</option>
                                                        <option>Arquitetos</option>
                                                        <option>Teste multi usuario</option>
                                                        <option>Parceiro - Diamante</option>
                                                        <option>Parceiro - Ouro</option>
                                                        <option>Parceiro - Prata</option>
                                                        <option>Parceiro - Bronze</option>
                                                        <option>Parceiro - Clube</option>
                                                        <option>Parceiro - Esmeralda</option>
                                                    </Input>
                                                </Col>
                                            </FormGroup>
                                        </Form>
                                    </div>
                                </div>
                            }
                            <hr />
                            <Button className='criar-eventos'>Criar</Button>
                        </CardBody>
                    </Card>
                </Container>
            </div>
        </React.Fragment >
    );
};

export default EventsCreate;