import React from "react";
import { Link } from "react-router-dom";
import { formateDate } from "../../../utils/index";

const Qualification = ({ row }) => {
  return (
    <div>
      <span>{row.title ? row.title : "-"}</span>
    </div>
  );
};

const Date = ({ row }) => {
  const date = row.start_date ? formateDate(row.start_date, "MM/DD/YYYY") : "-";
  return <span>{date}</span>;
};

const State = ({ row }) => {
  return <span>{row.state["name"] ? row.state["name"] : "-"}</span>;
};

const CreatedDate = ({ row }) => {
  return (
    <span>
      {row.created_at ? formateDate(row.created_at, "MM/DD/YYYY") : "-"}
    </span>
  );
};

const Actions = ({ row, onClickDelete }) => {
  const url = "#"; // `/events/${row.id}/edit`
  return (
    <React.Fragment>
      <button className="btn btn-sm btn-primary me-1">
        <i className="fa fa-eye"></i>
      </button>
      <Link to={url} className="btn btn-sm btn-primary me-1">
        <i className="fa fa-edit"></i>
      </Link>
      <button className="btn btn-sm btn-primary me-1">
        <i className="fa fa-list-ol"></i>
      </button>
      <Link
        to="#"
        className="btn btn-sm btn-danger me-1"
        onClick={() => onClickDelete(row.id)}
      >
        <i className="fa fa-trash"></i>
      </Link>
    </React.Fragment>
  );
};

export { Qualification, Date, State, CreatedDate, Actions };
