import React from "react";
import DataTable from "../../../components/DataTable";
import Loader from "../../../components/Loader";
import { Actions, Qualification, Date, State, CreatedDate, } from "./EventsListTableColumns";

const EventList = ({ data, getEventDetailsLoading, onClickDelete }) => {
  const total = data.length;
  const columns = [
    {
      dataField: "title",
      text: "Título",
      sort: true,
      formatter: (cellContent, row) => <Qualification row={row} />,
    },
    {
      dataField: "start_date",
      text: "Data",
      sort: true,
      formatter: (cellContent, row) => <Date row={row} />,
    },
    {
      dataField: "state",
      text: "Estado",
      sort: true,
      formatter: (cellContent, row) => <State row={row} />,
    },
    {
      dataField: "createdDate",
      isDummyField: true,
      text: "Criado",
      sort: true,
      formatter: (cellContent, row) => <CreatedDate row={row} />,
    },
    {
      dataField: "ação",
      text: "Ação",
      sort: true,
      formatter: (cellContent, row) => <Actions row={row} onClickDelete={onClickDelete} />,
    },
  ];
  const defaultSorted = [
    {
      dataField: "title",
      order: "desc",
    },
  ];
  return (
    <React.Fragment>
      <div className="mt-3">
        {getEventDetailsLoading && <Loader />}
        <DataTable
          totalRecords={total}
          defaultSorted={defaultSorted}
          data={data}
          columns={columns}
          sizePerPageDropdown={true}
          isSerach={true}
          keyField="id"
        />
      </div>
    </React.Fragment>
  );
};

export default EventList;
