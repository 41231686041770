import React, { useEffect, useState } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner,
} from "reactstrap";
import { apiClient2 } from "../../../api/apiCore2";
import { toast } from "react-toastify";
import "./styles.css";
import { apiClient } from "../../../api/apiCore";
import { useSolar } from "../../../hooks";
import { useDataLayer } from "../../../hooks/DataLayerHooks";

export default function ModalCancel({
  openModalCancel,
  setOpenModalCancel,
  active,
  id,
  setReloadData,
  codFilial,
  totalValue
}) {
  const [formatParse, setFormatParse] = useState([]);
  const [selectedReason, setSelectedReason] = useState("43");
  const [selectedModal, setSelectedModal] = useState(1);
  const [loading, setLoading] = useState(false);
  const isSolar = useSolar();
  const { pushToDataLayer } = useDataLayer();

  useEffect(() => {
    if (active?.data?.motivosCancelamento) {
      const JsonParse = JSON.parse(active?.data?.motivosCancelamento);
      setFormatParse(JsonParse);
    }
  }, [active?.data?.motivosCancelamento]);

  const handleContinue = () => {
    setSelectedModal(2);
  };

  const handleCloseModal = () => {
    setOpenModalCancel(false);
    setSelectedModal(1);
  };

  const handleConfirmSubmit = async () => {
    setLoading(true);
    try {
      await apiClient.get(
        `/api/orcamento/cancel/${id}/${selectedReason}/${
          isSolar ? "02" : "01"
        }/${codFilial}`
      );
      setLoading(false);

      pushToDataLayer(
        'cancelar_orcamento',
        {
          id_orcamento: id,
          value: totalValue
        }
      );

      toast.success(`Orçameto #${id} cancelado com sucesso!`, {
        theme: "colored",
      });
      handleCloseModal();
      setReloadData(state => !state);
    } catch (err) {
      setLoading(false);
      toast.error(`Erro ao cancelar orçamento`, { theme: "colored" });

      console.log(err);
    }
  };
  return (
    <Modal isOpen={openModalCancel}>
      {selectedModal == 1 && (
        <>
          <ModalHeader toggle={handleCloseModal}>
            Selecione o motivo do cancelamento:
          </ModalHeader>
          <ModalBody>
            <select
              onChange={e => setSelectedReason(e.target.value)}
              className="form-select"
            >
              {formatParse.map((item, k) => (
                <option key={k} value={item.value}>
                  {item.text}
                </option>
              ))}
            </select>
          </ModalBody>
          <ModalFooter>
            <Button
              style={{ background: "#FF601A" }}
              className="cancel hover text-white"
              onClick={handleCloseModal}
            >
              Cancelar
            </Button>
            <Button
              style={{ background: "#e0e0e0" }}
              className="btn-color hover text-white"
              onClick={handleContinue}
            >
              OK
            </Button>
          </ModalFooter>
        </>
      )}
      {selectedModal == 2 && (
        <>
          <ModalHeader toggle={handleCloseModal}>
            Tem certeza de deseja cancelar esse pedido? Essa ação não poderá ser
            desfeita.
          </ModalHeader>
          <ModalFooter>
            <Button className="cancel hover" onClick={handleCloseModal}>
              Cancelar
            </Button>
            <Button
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              className="btn-color hover"
              onClick={handleConfirmSubmit}
            >
              {loading ? <Spinner className="spinner-width" size="sm" /> : "OK"}
            </Button>
          </ModalFooter>
        </>
      )}
    </Modal>
  );
}
