import React, { useEffect } from "react";

import Header from "./Header";
import Sidebar from "./Sidebar";
import SideBarMenu from "../../components/SideBar";

import Footer from "./Footer";
import { withRouter } from "react-router-dom";
import "./solar-styles.scss";

//redux
import { useSelector, useDispatch } from "react-redux";

import {
  changeLayout,
  changeSidebarTheme,
  changeSidebarType,
  changeTopbarTheme,
  changeLayoutWidth,
} from "../../store/actions";

const Layout = props => {
  const dispatch = useDispatch();

  /* const { sidebarMenuData } = useSelector(state => ({
    sidebarMenuData: state.SidebarMenu.menuItems,
  })); */

  //verifica se o usuario tem acesso a rota
  /* useEffect(() => {
    let verifyRoute = false;
    if (sidebarMenuData) {
      for (var i = 0; i <= sidebarMenuData?.length - 1; i++) {
        if (sidebarMenuData[i].link == location?.pathname) {
          verifyRoute = true;
        }
        for (var j = 0; j <= sidebarMenuData[i]?.subItems?.length; j++) {
          if (sidebarMenuData[i]?.subItems[j]?.link == location?.pathname) {
            verifyRoute = true;
          }
        }
      }
      if (!verifyRoute) {
        history.push("/dashboard");
      }
    }
  }, [location?.pathname, sidebarMenuData]); */

  const {
    leftSideBarTheme,
    leftSideBarType,
    isPreloader,
    layoutWidth,
    topbarTheme,
  } = useSelector(state => ({
    leftSideBarTheme: state.Layout.leftSideBarTheme,
    leftSideBarType: state.Layout.leftSideBarType,
    isPreloader: state.Layout.isPreloader,
    layoutWidth: state.Layout.layoutWidth,
    topbarTheme: state.Layout.topbarTheme,
  }));

  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

  useEffect(() => {
    if (isPreloader === true) {
      document.getElementById("preloader").style.display = "block";
      document.getElementById("status").style.display = "block";

      setTimeout(function () {
        document.getElementById("preloader").style.display = "none";
        document.getElementById("status").style.display = "none";
      }, 2500);
    } else {
      document.getElementById("preloader").style.display = "none";
      document.getElementById("status").style.display = "none";
    }
  }, [isPreloader]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    dispatch(changeLayout("vertical"));
  }, [dispatch]);

  useEffect(() => {
    if (leftSideBarTheme) {
      dispatch(changeSidebarTheme(leftSideBarTheme));
    }
  }, [leftSideBarTheme, dispatch]);

  useEffect(() => {
    if (layoutWidth) {
      dispatch(changeLayoutWidth(layoutWidth));
    }
  }, [layoutWidth, dispatch]);

  useEffect(() => {
    if (leftSideBarType) {
      dispatch(changeSidebarType(leftSideBarType));
    }
  }, [leftSideBarType, dispatch]);

  useEffect(() => {
    if (topbarTheme) {
      dispatch(changeTopbarTheme(topbarTheme));
    }
  }, [topbarTheme, dispatch]);

  const toggleMenuCallback = () => {
    if (props.leftSideBarType === "default") {
      props.changeSidebarType("condensed", isMobile);
    } else if (props.leftSideBarType === "condensed") {
      props.changeSidebarType("default", isMobile);
    }
  };

  return (
    <React.Fragment>
      <div id="preloader">
        <div id="status">
          <div className="spinner">
            <i className="uil-shutter-alt spin-icon"></i>
          </div>
        </div>
      </div>
      <div id="layout-wrapper">
        <Header toggleMenuCallback={toggleMenuCallback} />
        {/*    <Sidebar
          theme={leftSideBarTheme}
          type={leftSideBarType}
          isMobile={isMobile}
          solar={isSolar}
        /> */}
        <SideBarMenu />
        <div className="main-content" id="principal-container">
          {props.children}
        </div>
        { <Footer />}
      </div>
    </React.Fragment>
  );
};

export default withRouter(Layout);
