import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "reactstrap";
import { useParams } from "react-router-dom";

//redux
import { useDispatch, useSelector } from "react-redux";

//actions
import { getTVConfigGallary } from "../../../store/actions";

// components
import Breadcrumb from "../../../components/Breadcrumb";
import Loader from "../../../components/Loader";
import LatestEvents from "./LatestEvents";
import AddEditNewLive from "./AddEditNewLive";

const LiveVideoSettings = () => {
  const dispatch = useDispatch();
  const {
    tvConfigGallery,
    getTvConfigGalleryLoading,
    liveAdded,
    fileUploaded,
    isFileDeleted,
  } = useSelector(state => ({
    tvConfigGallery: state.Gallery.tvConfigGallery,
    getTvConfigGalleryLoading: state.Gallery.getTvConfigGalleryLoading,
    liveAdded: state.Gallery.liveAdded,
    fileUploaded: state.Gallery.fileUploaded,
    isFileDeleted: state.Gallery.isFileDeleted,
  }));
  const { videoId } = useParams();

  /*
  get data
  */
  useEffect(() => {
    if (videoId) {
      dispatch(getTVConfigGallary(videoId));
    } else {
      dispatch(getTVConfigGallary());
    }
  }, [dispatch, videoId]);

  useEffect(() => {
    if (liveAdded || fileUploaded || isFileDeleted) {
      if (videoId) {
        dispatch(getTVConfigGallary(videoId));
      } else {
        dispatch(getTVConfigGallary());
      }
    }
  }, [liveAdded, videoId, fileUploaded, isFileDeleted]);

  const [isEditMode, setIsEditMode] = useState(false);
  useEffect(() => {
    if (
      videoId &&
      tvConfigGallery &&
      tvConfigGallery.hasOwnProperty("video") &&
      typeof tvConfigGallery.video === "object"
    ) {
      setIsEditMode(true);
    }
  }, [videoId, tvConfigGallery]);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container>
          {/* Render Breadcrumb */}
          <Breadcrumb title="Adias" breadcrumbItem="Configurações da LIVE" />
          <Row>
            <Col xs={12}>
              <AddEditNewLive
                isEditMode={isEditMode}
                video={tvConfigGallery["video"]}
                files={tvConfigGallery["arquivos"] || []}
                getTvConfigGalleryLoading={getTvConfigGalleryLoading}
              />
            </Col>
          </Row>

          <Row>
            <Col xs={12}>
              <div className="position-relative">
                {getTvConfigGalleryLoading && <Loader />}
                <LatestEvents videos={tvConfigGallery.videos || []} />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default LiveVideoSettings;
