import React, { useEffect } from "react";

import { Container, Spinner } from "reactstrap";

import { useSelector, useDispatch } from "react-redux";
import { getSettlementsList } from "../../../store/actions";
import Breadcrumb from "../../../components/Breadcrumb";

import "./Cartao.css";
import SettlementsList from "./SettlementsList";
import SettlementsFilter from "./SettlementsFilter";

const Cartao = () => {
  const dispatch = useDispatch();

  const { loading, success, filters } = useSelector(state => {
    return {
      loading: state.Settlements.loading,
      success: state.Settlements.success,
      filters: state.Settlements.filters,
    };
  });

  useEffect(() => {
    dispatch(getSettlementsList(filters));
  }, []);

  return (
    <React.Fragment>
      <div className="page-content fechamento-cartao">
        <Container>
          {/* Seu código inicia aqui */}
          <Breadcrumb
            title="Página Inicial"
            breadcrumbItem="Fechamentos de Comissão"
          />
          <SettlementsFilter />

          <h2 className="d-flex align-items-center titulo-comissaos">
            Fechamentos de Comissão - Cartão{" "}
            <i
              className="ph-browser"
              style={{ marginLeft: 10, color: "#202833", fontSize: 22 }}
            ></i>
          </h2>

          {loading ? (
            <Spinner style={{ margin: "0 50%" }} className="mt-4" />
          ) : (
            <div />
          )}

          {success ? <SettlementsList /> : <div />}

          {/* Seu código se encerra aqui */}
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Cartao;
