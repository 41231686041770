import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Container, Card, CardBody, Spinner, Label } from "reactstrap";
import Breadcrumb from "../../components/Breadcrumb";
import "./consulta.css";
import DataTable from "react-data-table-component";
import { Link } from "react-router-dom";
import OrcamentoFilter from "./OrcamentoFilter";
import { formatInvertDate } from "../../utils";
import { useSolar } from "../../hooks";

const ConstultarTabela = () => {
  const [isOpen, setisOpen] = useState(false);
  const [totalSum, setTotalSum] = useState(0);

  const isSolar = useSolar();
  const formato = {
    minimumFractionDigits: 2,
    style: "currency",
    currency: "BRL",
  };
  const fases = {
    A: "Aberto",
    C: "Cancelado",
  };
  //  {
  //     A: "Análise do Pedido",
  //     B: "Pedido Bloqueado",
  //     C: "Análise de Crédito",
  //     D: "Crédito Bloqueado",
  //     E: "Estoque Bloqueado",
  //     F: "Aguardando Faturamento",
  //     G: "Aguardando Expedição",
  //     H: "Pedido Embarcado",
  //     I: "Pedido Cancelado",
  //     J: "Pedido Entregue",
  //     K: "Devolução Parcial",
  //     L: "Devolução Total",
  //     S: "Gestão de Serviços",
  //   };
  const columns = [
    {
      name: "Cadastro",
      selector: row => formatInvertDate(row.DataEmissao),
      sortable: true,
      reorder: true,
      width: "102px",
    },
    {
      name: "Orçamento",
      selector: row => `${row.CodFilial} - ${row.NumPedido}`,
      width: "115px",
    },
    {
      name: "Cliente",
      selector: row => (row.NomeCliente ? row.NomeCliente : "Prospect"),
      width: "190px",
    },
    {
      name: "Validade",
      selector: row => formatInvertDate(row.Validade),
      width: "102px",
    },
    {
      name: "Valor Total",
      selector: row => Number(row.total).toLocaleString("pt-br", formato),
      width: "130px",
    },
    {
      name: "Situação",
      selector: row => fases[row.Situacao],
      width: "94px",
    },
    {
      name: "Vendedor",
      selector: row => row.Vendedor1,
      width: "86px",
    },
    {
      name: "Parceiro",
      selector: row => row.Vendedor2,
      width: "86px",
    },
    {
      name: "",
      cell: row => (
        <Link
          to={`orcamento/${("000000" + row.NumPedido).slice(-6)}/${
            row.CodFilial ? row.CodFilial : "02"
          }`}
        >
          <i className="ph-eye"></i>
        </Link>
      ),
      width: "42px",
    },
  ];

  const { list } = useSelector(state => ({
    list: state.Budget.list,
  }));

  useEffect(() => {
    const sum = list.budgets.reduce((acc, budget) => {
      return acc + parseFloat(budget.total);
    }, 0);

    setTotalSum(sum.toFixed(2)); 
  }, [list.budgets]);

  const paginationComponentOptions = {
    rowsPerPageText: "Resultados por página",
    rangeSeparatorText: "de",
    selectAllRowsItem: true,
    selectAllRowsItemText: "Todos",
  };

  return (
    <React.Fragment>
      <div className="page-content consultar-orcamento">
        <Container>
          <Breadcrumb
            title="Página Inicial"
            breadcrumbItem="Consultar Orçamento"
          />
          <Card className="cardprincipal">
            <CardBody className="card-body">
              <div className="engloba-pesquisa-chev">
                <h5 className="titulo-card">
                  <i className="ph-magnifying-glass lupa"></i>Pesquisa de
                  Orçamentos
                </h5>
                <a href="#" onClick={() => setisOpen(!isOpen)}>
                  {isOpen ? (
                    <i className="ph-caret-down"></i>
                  ) : (
                    <i className="ph-caret-up"></i>
                  )}
                </a>
              </div>
              <hr className="linha-pesquisa" />

              <OrcamentoFilter isOpen={isOpen} />

              {list.loading && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: 30,
                  }}
                >
                  {" "}
                  <Spinner />
                </div>
              )}
              {list.fetch && (
                <React.Fragment>
                  <h5 className="titulo-lista">Lista de orçamentos</h5>
                  <hr />
                  <DataTable
                    columns={columns}
                    data={list.budgets}
                    pagination
                    paginationComponentOptions={paginationComponentOptions}
                  />
                  <Label className="d-flex justify-content-end text-black text-opacity-50">
                    Total de{" "}
                    <b className="mx-1 text-black text-opacity-75">
                      {" "}
                      {Number(totalSum)?.toLocaleString("pt-br", formato)}{" "}
                    </b>{" "}
                    para o filtro atual
                  </Label>
                </React.Fragment>
              )}
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  );
};
export default ConstultarTabela;
