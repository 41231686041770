import React, { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getVendedor as getVendedorApi } from "../../api/index";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import { formatInvertDate } from "../../utils/dateUtils";
import "./orders.css";

import {
  Col,
  Row,
  FormGroup,
  Label,
  Input,
  Button,
  Card,
  CardBody,
  CardTitle,
  Collapse,
} from "reactstrap";

import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";
import { Portuguese } from "flatpickr/dist/l10n/pt.js";

import { getOrdersList } from "../../store/orderList/action";
import { useParams } from "react-router-dom";
import { useColors } from "../../hooks/useColors";
import { useSolar } from "../../hooks";

const OrderFilters = ({
  idType,
  setIdType,
  clienteId,
  setClienteId,
  parceiros,
  setParceiros,
  numPedido,
  setNumPedido
}) => {
  const dispatch = useDispatch();
  let { sit } = useParams();
  const { colorDefault } = useColors();
  const { filters } = useSelector(state => ({
    filters: state.OrderList.filters,
  }));
  const isSolar = useSolar();

  const [isLoading, setIsLoading] = useState(false);
  const filter = filters.filters;
  const [isOpen, setIsOpen] = useState(false);

  const [dataInicio, setDataInicio] = useState("");
  const [dataFim, setDataFim] = useState("");
  const [fase, setFase] = useState([]);
  const [vendedores, setVendedores] = useState([]);
  const [priceTable, setPriceTable] = useState("");

  const [erro, setErro] = useState(false);
  const toggle = () => setIsOpen(!isOpen);
  const filterBy = () => true;
  const newArray = [...fase];
  const fases = {
    A: "Análise do Pedido",
    B: "Pedido Bloqueado",
    C: "Análise de Crédito",
    D: "Crédito Bloqueado",
    E: "Estoque Bloqueado",
    F: "Aguardando Faturamento",
    G: "Aguardando Expedição",
    H: "Pedido Embarcado",
    I: "Pedido Cancelado",
    J: "Pedido Entregue",
    K: "Devolução Parcial",
    L: "Devolução Total",
    S: "Gestão de Serviços",
  };

  function clearFilters() {
    setClienteId("");
    setParceiros([]);
    setIdType(0);
    setFase([]);
    setDataInicio("");
    setNumPedido("");
    setDataFim("");
    let checkbox = document.getElementsByClassName("fase-pedido");
    for (let i = 0; i < checkbox.length; i++) {
      checkbox[i].checked = false;
    }
  }

  function changeDate(dateY) {
    let data = dateY.date;
    let dataFormatada = `${data.getFullYear()}${(data.getMonth() + 1)
      .toString()
      .padStart(2, "0")}${data.getDate().toString().padStart(2, "0")}`;
    return dataFormatada;
  }

  const changeFase = e => {
    const isChecked = e.target.checked;
    if (isChecked) {
      newArray.push(e.target.value);
    } else {
      newArray.splice(fase.indexOf(e.target.value), 1);
    }
    setFase(newArray);
  };

  const changeVendedores = inputValue => {
    setIsLoading(true);

    getVendedorApi(inputValue)
      .then(res => {
        let data = [];

        res.map(value => {
          data.push({
            codigo: value.CODIGO,
            nome: value.NOME,
            label: `${value.CODIGO} - ${value.NREDUZ.trim()}`,
            nreduz: value.NREDUZ,
            cnpj: value.CPFCNPJ,
          });
        });

        setVendedores(data);
        setIsLoading(false);
      })
      .catch(console.log);
  };

  const getOrders = (e = null) => {
    if (e) e.preventDefault();

    if (Number(idType) === 0) {
      filter.cli_cpfcnpj = clienteId?.replace(/[^0-9]/g, "");
      filter.nome_cliente = "";
      filter.nota_fisca = "";
    } else if (Number(idType) === 1) {
      filter.nome_cliente = clienteId;
      filter.cli_cpfcnpj = "";
      filter.nota_fisca = "";
    } else if (Number(idType) === 2) {
      filter.nome_cliente = "";
      filter.cli_cpfcnpj = "";
      filter.nota_fisca = clienteId;
    } else if (Number(idType) === 3) {
      filter.nome_cliente = "";
      filter.cli_cpfcnpj = "";
      filter.nota_fisca = "";
      filter.num_pedido_externo = clienteId;
    }

    try {
      filter.vend_codigo = parceiros[0].codigo;
    } catch (e) {
      filter.vend_codigo = "";
    }
    filter.tabela_preco = priceTable;
    filter.fila = fase;
    filter.dateI = dataInicio;
    filter.dateF = dataFim;
    filter.num_pedido = numPedido;

    if (isOpen) setIsOpen(false);

    filters.filters = filter;
    filters.filters;
    filters.filters.empresa = isSolar ? "02" : "01";

    dispatch(getOrdersList(filters));
  };

  function removeFase(value) {
    newArray.splice(fase.indexOf(value), 1);
    setFase(newArray);
  }

  function validatePartner() {
    let el = document.getElementById("parceiro-selecionado").value.length;
    if (el > 3 && parceiros.length == 0) {
      setErro(true);
    } else {
      setErro(false);
    }
  }

  const checkKeyPress = useCallback(
    e => {
      const { key } = e;
      if (key === "Enter") {
        getOrders();
      }
    },
    [fase, dataFim, dataInicio, parceiros, numPedido, clienteId]
  );

  useEffect(() => {
    // eslint-disable-next-line no-undef
    window.addEventListener("keypress", checkKeyPress);
    return () => {
      // eslint-disable-next-line no-undef
      window.removeEventListener("keypress", checkKeyPress);
    };
  }, [checkKeyPress]);

  useEffect(() => {
    let data = filters;
    data.filters.vend_codigo = "";

    if (sit) {
      data.filters.fila = sit.split(",");
      setFase(sit.split(","));
    } else {
      /*let date = new Date();
      if (filters.filters.dateF) {
        filters.filters.dateF = filters.filters.dateF;
        setDataFim(filters.filters.dateF);
      } else {
        data.filters.dateF = `${date.getFullYear()}${(
          "00" +
          (date.getMonth() + 1)
        ).slice(-2)}${("00" + date.getDate()).slice(-2)}`;
        setDataFim(
          `${date.getFullYear()}${("00" + (date.getMonth() + 1)).slice(-2)}${(
            "00" + date.getDate()
          ).slice(-2)}`
        );
      }

      if (filters.filters.dateI) {
        data.filters.dateI = filters.filters.dateI;
        setDataInicio(filters.filters.dateI);
      } else {
        date.setDate(date.getDate() - 7);
        data.filters.dateI = `${date.getFullYear()}${(
          "00" +
          (date.getMonth() + 1)
        ).slice(-2)}${("00" + date.getDate()).slice(-2)}`;
        setDataInicio(
          `${date.getFullYear()}${("00" + (date.getMonth() + 1)).slice(-2)}${(
            "00" + date.getDate()
          ).slice(-2)}`
        );
      }*/

      if (data.filters.fila) setFase(data.filters.fila);
      setNumPedido(data.filters.num_pedido);

      if (data.filters.cli_cpfcnpj) {
        setClienteId(data.filters.cli_cpfcnpj);
      }

      if (data.filters.cli_cpfcnpj) {
        setClienteId(data.filters.nome_cliente);
        setIdType(1);
      }
    }

    if (isSolar) {
      data.filters.empresa = "02";
    } else {
      data.filters.empresa = "01";
    }

    setIsOpen(true);
    dispatch(getOrdersList(data));
  }, []);

  return (
    <React.Fragment>
      <Row className="mt-3 d-flex align-items-end">
        <Col md={3}>
          <FormGroup>
            <Label for="select-partner">Selecionar Parceiro/Vendedor</Label>

            <AsyncTypeahead
              id="parceiro"
              className="parceiro-input"
              onChange={setParceiros}
              options={vendedores}
              placeholder="Pesquisar..."
              selected={parceiros}
              minLength={3}
              filterBy={filterBy}
              isLoading={isLoading}
              onSearch={changeVendedores}
              emptyLabel="Não foi encontrado nenhum vendedor"
              promptText="Buscando vendedores"
              searchText="Buscando vendedores"
              onBlur={() => validatePartner()}
              inputProps={{
                id: "parceiro-selecionado",
              }}
              renderMenuItemChildren={option => (
                <div>
                  {option.codigo} {option.nreduz}
                  <div>
                    <small>{option.nome}</small>
                  </div>
                </div>
              )}
            />
          </FormGroup>
        </Col>
        <Col md={2}>
          <FormGroup>
            <Label for="select-partner">Numero de pedido:</Label>
            <Input
              placeholder="Digite aqui..."
              value={numPedido}
              onChange={e => setNumPedido(e.target.value)}
            />
          </FormGroup>
        </Col>
        <Col md={5}>
          <FormGroup>
            <Label for="select-partner">Buscar</Label>
            <div className="input-group">
              <div className="input-group-prepend col-6">
                <select
                  className="form-select form-control"
                  value={idType}
                  onChange={e => setIdType(e.target.value)}
                >
                  <option value={0}>CPF/CNPJ do Cliente</option>
                  <option value={1}>Nome do Cliente</option>
                  <option value={2}>Número da NF-e</option>
                  {isSolar ? <option value={3}>Número MD</option> : ""}
                </select>
              </div>
              <Input
                id="cliente"
                name="clienteId"
                placeholder="Digite aqui..."
                value={clienteId.toUpperCase()}
                onChange={e => setClienteId(e.target.value.toUpperCase())}
                type="text"
              />
            </div>
          </FormGroup>
        </Col>
        <Col md={2}>
          <Button
            color="dark-blue"
            onClick={e => getOrders(e)}
            className="px-3 btn-block"
            style={{
              backgroundColor: colorDefault,
              border: `1px solid ${colorDefault}`,
            }}
          >
            PESQUISAR
            <i className="fa fa-search" style={{ marginLeft: 10 }}></i>
          </Button>
        </Col>
      </Row>

      {erro ? (
        <Row>
          <Col>
            <p className="text-danger">
              <small>
                O parceiro indicado não foi selecionado ou é inválido, o filtro
                de parceiro será desconsiderado
              </small>
            </p>
          </Col>
        </Row>
      ) : (
        <div />
      )}
      <Row className="mt-4">
        <Col>
          <Card>
            <CardBody>
              <Row>
                <Col md={2}>
                  <CardTitle tag="h4">Filtrar por:</CardTitle>
                </Col>
                <Col md={7}>
                  <p className="d-flex align-content-start flex-wrap">
                    {dataInicio ? (
                      <span
                        className="px-3 rounded mb-2"
                        style={{
                          marginRight: 10,
                          color: "#5C9FD2",
                          backgroundColor: "#F0F9FF",
                        }}
                      >
                        de {formatInvertDate(dataInicio)}{" "}
                        <i
                          className="far fa-times-circle"
                          onClick={() => setDataInicio("")}
                          style={{ marginLeft: 5 }}
                        ></i>
                      </span>
                    ) : (
                      <span />
                    )}
                    {dataFim ? (
                      <span
                        className="px-3 rounded mb-2"
                        style={{
                          marginRight: 10,
                          color: "#5C9FD2",
                          backgroundColor: "#F0F9FF",
                        }}
                      >
                        até {formatInvertDate(dataFim)}{" "}
                        <i
                          className="far fa-times-circle"
                          onClick={() => setDataFim("")}
                          style={{ marginLeft: 5 }}
                        ></i>
                      </span>
                    ) : (
                      <span />
                    )}
                    {fase.map(value => {
                      return (
                        <span
                          className="px-3 rounded mb-2"
                          key={value}
                          style={{
                            marginRight: 10,
                            color: "#5C9FD2",
                            backgroundColor: "#F0F9FF",
                          }}
                        >
                          {fases[value]}{" "}
                          <i
                            className="far fa-times-circle"
                            onClick={() => removeFase(value)}
                            style={{ marginLeft: 5 }}
                          ></i>
                        </span>
                      );
                    })}
                  </p>
                </Col>
                <Col md={2}>
                  <Button
                    color="light"
                    onClick={clearFilters}
                    style={{
                      color: colorDefault,
                      backgroundColor: "#FFF",
                      borderColor: "#F5F5F5",
                      borderWidth: "2px",
                    }}
                  >
                    Limpar Filtros{" "}
                    <i
                      className="fa fa-times fa-md"
                      style={{ marginLeft: 5 }}
                    ></i>
                  </Button>
                </Col>
                <Col md={1}>
                  <Button color="transparent" onClick={toggle}>
                    {isOpen ? (
                      <i className="fa fa-chevron-up fa-lg"></i>
                    ) : (
                      <i className="fa fa-chevron-down fa-lg"></i>
                    )}
                  </Button>
                </Col>
              </Row>
              <Collapse isOpen={isOpen}>
                <hr />
                <Row className="d-flex justify-content-center">
                  <Col md={8}>
                    <Row className="d-flex justify-content-around">
                      <FormGroup className="d-flex flex-column align-items-center">
                        <Row>
                          {!isSolar && (
                            <Col md={!isSolar ? 4 : 6}>
                              <Label for="select-partner">
                                Tabela de Preço
                              </Label>
                              <select
                                onChange={e => setPriceTable(e.target.value)}
                                name="tabela_preco"
                                className="form-select form-control"
                              >
                                <option value="">TODOS</option>
                                <option value="002">
                                  REVENDA / PARCEIROS{" "}
                                </option>
                                <option value="003">
                                  REVENDA / PARCEIROS MS ou ES{" "}
                                </option>
                                <option value="006">
                                  TELEVENDAS / CONSUMIDOR FINAL{" "}
                                </option>
                                <option value="007">VRF </option>
                                <option value="008">BLACK FRIDAY 2021 </option>
                                <option value="009">SITE TRAY </option>
                                <option value="010">TABELA DE SERVICOS </option>
                                <option value="020">TABELA DE PECAS </option>
                                <option value="046">
                                  TABELA PROMO ATÉ10% OFF{" "}
                                </option>
                              </select>
                            </Col>
                          )}
                          <Col md={!isSolar ? 4 : 6}>
                            <Label for="exampleEmail">
                              Data de Inclusão Início:
                            </Label>
                            <Flatpickr
                              className="form-control d-block selectData"
                              placeholder="&#xf133; dd/mm/aaaa"
                              options={{
                                altInput: true,
                                locale: Portuguese,
                                altFormat: "d/m/Y",
                                dateFormat: "Ymd",
                              }}
                              value={dataInicio}
                              onChange={([date]) =>
                                setDataInicio(changeDate({ date }))
                              }
                            />
                          </Col>
                          <Col md={!isSolar ? 4 : 6}>
                            <Label for="exampleEmail">
                              Data de Inclusão Final:
                            </Label>
                            <Flatpickr
                              className="form-control d-block selectData"
                              placeholder="&#xf133; dd/mm/aaaa"
                              options={{
                                altInput: true,
                                locale: Portuguese,
                                altFormat: "d/m/Y",
                                dateFormat: "Ymd",
                              }}
                              value={dataFim}
                              onChange={([date]) =>
                                setDataFim(changeDate({ date }))
                              }
                            />
                          </Col>
                        </Row>
                      </FormGroup>
                      {/* <Col md={3}>
                                                <FormGroup>
                                                    <Label for="exampleEmail">
                                                        Tipo de Entrega
                                                    </Label>
                                                    <FormGroup check>
                                                        <Input
                                                            id="checkbox2"
                                                            type="checkbox"
                                                        />
                                                        {' '}
                                                        <Label check>
                                                            Cliente Retira
                                                        </Label>
                                                    </FormGroup>
                                                    <FormGroup check>
                                                        <Input
                                                            id="checkbox2"
                                                            type="checkbox"
                                                        />
                                                        {' '}
                                                        <Label check>
                                                            Revenda Retira
                                                        </Label>
                                                    </FormGroup>
                                                    <FormGroup check>
                                                        <Input
                                                            id="checkbox2"
                                                            type="checkbox"
                                                        />
                                                        {' '}
                                                        <Label check>
                                                            Transportadora
                                                        </Label>
                                                    </FormGroup>
                                                </FormGroup>
                                            </Col>
                                            <Col md={3}>
                                                <FormGroup>
                                                    <Label for="exampleEmail">
                                                        SLA
                                                    </Label>
                                                    <FormGroup check>
                                                        <Input
                                                            id="checkbox2"
                                                            type="checkbox"
                                                        />
                                                        {' '}
                                                        <Label check>
                                                            Expedições Atrasadas
                                                        </Label>
                                                    </FormGroup>
                                                    <FormGroup check>
                                                        <Input
                                                            id="checkbox2"
                                                            type="checkbox"
                                                        />
                                                        {' '}
                                                        <Label check>
                                                            Entregas Atrasadas
                                                        </Label>
                                                    </FormGroup>
                                                    <FormGroup check>
                                                        <Input
                                                            id="checkbox2"
                                                            type="checkbox"
                                                        />
                                                        {' '}
                                                        <Label check>
                                                            Expedir Hoje
                                                        </Label>
                                                    </FormGroup>
                                                    <FormGroup check>
                                                        <Input
                                                            id="checkbox2"
                                                            type="checkbox"
                                                        />
                                                        {' '}
                                                        <Label check>
                                                            Expedir Hoje
                                                        </Label>
                                                    </FormGroup>
                                                    <FormGroup check>
                                                        <Input
                                                            id="checkbox2"
                                                            type="checkbox"
                                                        />
                                                        {' '}
                                                        <Label check>
                                                            Expedições no Prazo
                                                        </Label>
                                                    </FormGroup>
                                                </FormGroup>
                                            </Col> */}
                    </Row>
                  </Col>
                </Row>
                <hr />
                <Row className="my-4">
                  <Col>
                    <h5>Fase do Pedido</h5>
                  </Col>
                </Row>
                <Row>
                  <Col md={4}>
                    <FormGroup check className="mb-3">
                      <Input
                        type="checkbox"
                        value="A"
                        onChange={e => changeFase(e)}
                        className="fase-pedido"
                        checked={fase.indexOf("A") != -1}
                      />{" "}
                      <Label check className="text-indigo">
                        <i
                          className="fa fa-circle"
                          style={{ marginRight: 5, color: "#EBEBEB" }}
                        ></i>
                        A - Análise do Pedido
                      </Label>
                    </FormGroup>
                    <FormGroup check className="mb-3">
                      <Input
                        type="checkbox"
                        value="B"
                        onChange={e => changeFase(e)}
                        className="fase-pedido"
                        checked={fase.indexOf("B") != -1}
                      />{" "}
                      <Label check className="text-indigo">
                        <i
                          className="fa fa-circle"
                          style={{ marginRight: 5, color: "#BEA68E" }}
                        ></i>
                        B - Pedido Bloqueado
                      </Label>
                    </FormGroup>
                    <FormGroup check className="mb-3">
                      <Input
                        type="checkbox"
                        value="C"
                        onChange={e => changeFase(e)}
                        className="fase-pedido"
                        checked={fase.indexOf("C") != -1}
                      />{" "}
                      <Label check className="text-indigo">
                        <i
                          className="fa fa-circle"
                          style={{ marginRight: 5, color: "#F8EE72" }}
                        ></i>
                        C - Análise de Crédito
                      </Label>
                    </FormGroup>
                    <FormGroup check className="mb-3">
                      <Input
                        type="checkbox"
                        value="D"
                        onChange={e => changeFase(e)}
                        className="fase-pedido"
                        checked={fase.indexOf("D") != -1}
                      />{" "}
                      <Label check className="text-indigo">
                        <i
                          className="fa fa-circle"
                          style={{ marginRight: 5, color: "#717171" }}
                        ></i>
                        D - Crédito Bloqueado
                      </Label>
                    </FormGroup>
                    <FormGroup check className="mb-3">
                      <Input
                        type="checkbox"
                        value="E"
                        onChange={e => changeFase(e)}
                        className="fase-pedido"
                        checked={fase.indexOf("E") != -1}
                      />{" "}
                      <Label check className="text-indigo">
                        <i
                          className="fa fa-circle"
                          style={{ marginRight: 5, color: "#ADADAD" }}
                        ></i>
                        E - Estoque Bloqueado
                      </Label>
                    </FormGroup>
                  </Col>
                  <Col md={4}>
                    <FormGroup check className="mb-3">
                      <Input
                        type="checkbox"
                        value="F"
                        onChange={e => changeFase(e)}
                        className="fase-pedido"
                        checked={fase.indexOf("F") != -1}
                      />{" "}
                      <Label check className="text-indigo">
                        <i
                          className="fa fa-circle"
                          style={{ marginRight: 5, color: "#97E655" }}
                        ></i>
                        F - Aguardando Faturamento
                      </Label>
                    </FormGroup>
                    <FormGroup check className="mb-3">
                      <Input
                        type="checkbox"
                        value="G"
                        onChange={e => changeFase(e)}
                        className="fase-pedido"
                        checked={fase.indexOf("G") != -1}
                      />{" "}
                      <Label check className="text-indigo">
                        <i
                          className="fa fa-circle"
                          style={{ marginRight: 5, color: "#FC63F1" }}
                        ></i>
                        G - Aguardando Expedição
                      </Label>
                    </FormGroup>
                    <FormGroup check className="mb-3">
                      <Input
                        type="checkbox"
                        value="H"
                        onChange={e => changeFase(e)}
                        className="fase-pedido"
                        checked={fase.indexOf("H") != -1}
                      />{" "}
                      <Label check className="text-indigo">
                        <i
                          className="fa fa-circle"
                          style={{ marginRight: 5, color: "#71A3D6" }}
                        ></i>
                        H - Pedido Embarcado
                      </Label>
                    </FormGroup>
                    <FormGroup check className="mb-3">
                      <Input
                        type="checkbox"
                        value="I"
                        onChange={e => changeFase(e)}
                        className="fase-pedido"
                        checked={fase.indexOf("I") != -1}
                      />{" "}
                      <Label check className="text-indigo">
                        <i
                          className="fa fa-circle"
                          style={{ marginRight: 5, color: "#DB0000" }}
                        ></i>
                        I - Pedido Cancelado
                      </Label>
                    </FormGroup>
                  </Col>
                  <Col md={4}>
                    <FormGroup check className="mb-3">
                      <Input
                        type="checkbox"
                        value="J"
                        onChange={e => changeFase(e)}
                        className="fase-pedido"
                        checked={fase.indexOf("J") != -1}
                      />{" "}
                      <Label check className="text-indigo">
                        <i
                          className="fa fa-circle"
                          style={{ marginRight: 5, color: "#DB0000" }}
                        ></i>
                        J - Pedido Entregue
                      </Label>
                    </FormGroup>
                    <FormGroup check className="mb-3">
                      <Input
                        type="checkbox"
                        value="K"
                        onChange={e => changeFase(e)}
                        className="fase-pedido"
                        checked={fase.indexOf("K") != -1}
                      />{" "}
                      <Label check className="text-indigo">
                        <i
                          className="fa fa-circle"
                          style={{ marginRight: 5, color: "#ff7f00" }}
                        ></i>
                        K - Devolução Parcial
                      </Label>
                    </FormGroup>
                    <FormGroup check className="mb-3">
                      <Input
                        type="checkbox"
                        value="L"
                        onChange={e => changeFase(e)}
                        className="fase-pedido"
                        checked={fase.indexOf("L") != -1}
                      />{" "}
                      <Label check className="text-indigo">
                        <i
                          className="fa fa-circle"
                          style={{ marginRight: 5, color: "#ff7f00" }}
                        ></i>
                        L - Devolução Total
                      </Label>
                    </FormGroup>
                    <FormGroup check className="mb-3">
                      <Input
                        type="checkbox"
                        value="S"
                        onChange={e => changeFase(e)}
                        className="fase-pedido"
                        checked={fase.indexOf("S") != -1}
                      />{" "}
                      <Label check className="text-indigo">
                        <i
                          className="fa fa-circle"
                          style={{ marginRight: 5, color: "#000" }}
                        ></i>
                        S - Gestão de Serviços
                      </Label>
                    </FormGroup>
                  </Col>
                </Row>
              </Collapse>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default OrderFilters;
