import { useEffect, useState } from "react";
import { useSolar } from "../hooks/SolarHooks";

const useColors = () => {
  const isSolar = useSolar();
  const [colorDefault, setColorDefault] = useState("#2D567B");
  const [colorLight, setColorLight] = useState("#BBCAD2");
  const [color500, setColorLight500] = useState("#7EACC4");
  const [color600, setColor600] = useState("#7EACC4");

  useEffect(() => {
    setColorDefault(isSolar ? "#FF601A" : "#2D567B" );
    setColorLight(isSolar ? "#FFE0D3" : "#BBCAD2" );
    setColorLight500(isSolar ? "#FFE0D3" : "#7EACC4");
    setColor600(isSolar ? "#FF9E73" : "#7EACC4");
  }, [isSolar]);
  return { colorDefault, colorLight, color500, color600 };
};

export { useColors };
