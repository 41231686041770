import { RulesActionTypes } from "./actionTypes";

const INIT_STATE = {
  success: false,
  loading: false,
  total_pages: null,
  rulesList: [],
  filters: {
    id: "",
    rule_name: "",
    start: "",
    end: "",
    need_coupom: "",
    company: "",
    seller: "",
    applytoshipping: "",
    active: "",
  },
};

const Rules = (state = INIT_STATE, action) => {
  switch (action.type) {
    case RulesActionTypes.API_RESPONSE_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        rulesList: action.payload.data.rules,
      };

    case RulesActionTypes.API_RESPONSE_ERROR:
      return {
        ...state,
        loading: false,
        success: false,
      };

    case RulesActionTypes.GET_RULES_LIST:
      return {
        ...state,
        loading: true,
        success: false,
        filters: action.payload.filters,
        rulesList: [],
      };
    default:
      return state;
  }
};

export default Rules;
