import React, { useEffect, useState } from "react";
import { ArInput } from "../../../../../components/adias-input";
import { Col, Row } from "reactstrap";
import { TitleInputs } from "../../style";

import { getAdressesFromZipCode } from "../../../../../api";
import { toast } from "react-toastify";

export const EnderecoFaturamento = ({
  errors,
  touched,
  values,
  setFieldValue,
  isOpenEnderecoEntrega,
}) => {
  const [, setLoadingCep] = useState(false);
  const [dataCep, setDataCep] = useState({
    ENDERECO: "",
    BAIRRO: "",
    MUNICIPIO: "",
    UF: "",
  });
  const getCep = cep => {
    if (cep?.replace(/[^0-9]+/g, "").length == 8) {
      setLoadingCep(true);
      setFieldValue("ENDERECO", "");
      setFieldValue("BAIRRO", "");
      setFieldValue("MUNICIPIO", "");
      setFieldValue("UF", "");
      getAdressesFromZipCode(cep)
        .then(res => {
          if (res.data.erro) {
            return toast.error(
              "Cep inválido! Verifique se o cep foi digitado corretamente",
              { theme: "colored" }
            );
          }
          if (!isOpenEnderecoEntrega) {
            setFieldValue("END_ENT", res.data.logradouro);
            setFieldValue("BAI_ENT", res.data.bairro);
            setFieldValue("MunicipEnt", res.data.localidade);
            setFieldValue("UF_ENT", res?.data?.uf || res?.data?.UF);
            setFieldValue("CEP_ENT", cep);
            setFieldValue("NumEndEnt", values.NumEndCob);
            setFieldValue("COMPL_ENT", values.COMPL);
          }
          setFieldValue("ENDERECO", res.data.logradouro);
          setFieldValue("BAIRRO", res.data.bairro);
          setFieldValue("MUNICIPIO", res.data.localidade);
          setFieldValue("UF", res?.data?.uf || res?.data?.UF);
          setDataCep({
            ENDERECO: res.data.logradouro || "",
            BAIRRO: res.data.bairro || "",
            MUNICIPIO: res.data.localidade || "",
            UF: res.data.uf || "",
          });
        })
        .catch(err => console.log(err));
      setLoadingCep(false);
    } else {
      setFieldValue("ENDERECO", "");
      setFieldValue("BAIRRO", "");
      setFieldValue("MUNICIPIO", "");
      setFieldValue("UF", "");
    }
  };

  useEffect(() => {
    if (!isOpenEnderecoEntrega) {
      setFieldValue("END_ENT", values.ENDERECO);
      setFieldValue("BAI_ENT", values.BAIRRO);
      setFieldValue("MunicipEnt", values.MUNICIPIO);
      setFieldValue("NumEndEnt", values.NumEndCob);
      setFieldValue("COMPL_ENT", values.COMPL);
    }
  }, [
    values.MUNICIPIO,
    values.BAIRRO,
    values.ENDERECO,
    values.NumEndCob,
    values.COMPL,
  ]);
  return (
    <>
      {" "}
      <TitleInputs>ENDEREÇO FATURAMENTO {"&"} COBRANÇA</TitleInputs>
      <Row>
        <Col md={3}>
          <ArInput
            title="CEP"
            errors={errors}
            name="CEP"
            touched={touched}
            onchange={(e, value) => getCep(value)}
            mask="cep"
            autoComplete="off"
          />
        </Col>
        <Col md={3}>
          <ArInput
            title="Estado"
            errors={errors}
            name="UF"
            touched={touched}
            mask="onlyText"
            disabled={true}
            autoComplete="off"
          />
        </Col>
        <Col md={3}>
          <ArInput
            title="Cidade"
            errors={errors}
            name="MUNICIPIO"
            touched={touched}
            mask="onlyText"
            autoComplete="off"
          />
        </Col>
        <Col md={3}>
          <ArInput
            title="Bairro"
            errors={errors}
            name="BAIRRO"
            touched={touched}
            autoComplete="off"
          />
        </Col>
      </Row>
      <Row>
        <Col md={4}>
          <ArInput
            title="Endereço"
            errors={errors}
            name="ENDERECO"
            touched={touched}
            autoComplete="off"
          />
        </Col>
        <Col md={4}>
          <ArInput
            title="Número"
            errors={errors}
            name="NumEndCob"
            touched={touched}
            mask="onlyNumbersAndLetters"
            maxLength={6}
            autoComplete="off"
          />
        </Col>
        <Col md={4}>
          <ArInput
            title="Complemento"
            errors={errors}
            name="COMPL"
            touched={touched}
            autoComplete="off"
          />
        </Col>
      </Row>
    </>
  );
};
