import { DashboardActionTypes } from "./actionTypes";

const INIT_STATE = {
  dashboardDetails: {},
};

const Dashboard = (state = INIT_STATE, action) => {
  switch (action.type) {
    case DashboardActionTypes.API_RESPONSE_SUCCESS:
      switch (action.payload.actionType) {
        case DashboardActionTypes.GET_DASHBOARD_DETAILS:
          return {
            ...state,
            dashboardDetails: action.payload.data,
            getDashboardDetailsLoading: false,
            isDashboardDetailsFetched: true,
          };

        default:
          return { ...state };
      }
    case DashboardActionTypes.API_RESPONSE_ERROR:
      switch (action.payload.actionType) {
        case DashboardActionTypes.GET_DASHBOARD_DETAILS:
          return {
            ...state,
            dashboardDetailsError: action.payload.error,
            getDashboardDetailsLoading: false,
            isDashboardDetailsFetched: true,
          };

        default:
          return { ...state };
      }

    case DashboardActionTypes.GET_DASHBOARD_DETAILS:
      return {
        ...state,
        getDashboardDetailsLoading: true,
        isDashboardDetailsFetched: false,
      };

    default:
      return state;
  }
};

export default Dashboard;
