import React from "react";
import { PlusCircle, PencilSimple, Trash, ArrowRight } from "phosphor-react";
import { useProfile } from "../../hooks";
import "./styles.css";
import { useSelector } from "react-redux";

const Status = ({
  status,
  height = 153,
  maxHeight = "none",
  minHeight = 153,
  loggedInUsername = true,
}) => {
  const { userProfile } = useProfile();
  const { user } = useSelector(state => state.Profile.profileDetails);
  const displayName = userProfile
    ? userProfile["displayName"] !== null
      ? userProfile["displayName"].split(" ")[0]
      : userProfile["email"]
    : "";
  const userCode = user?.code ? user?.code : "";

  return (
    <div className="status_container">
      <h4 className="title">Alterações feitas por você</h4>
      <div
        style={{
          width: "100%",
          height: height,
          overflowY: "scroll",
          maxHeight: maxHeight,
          minHeight: minHeight,
        }}
      >
        {status?.length > 0 ? (
          status?.map((item, k) => (
            <div
              className={`container ${
                item.statusName == "add"
                  ? "add"
                  : item.statusName == "remove"
                  ? "remove"
                  : "edit"
              }`}
              key={k}
            >
              <div className="top">
                <span className="name">
                  {loggedInUsername
                    ? displayName + " - " + userCode
                    : item?.displayName + " - " + item?.userCode}
                </span>
                <span className="date">{item.date}</span>{" "}
                <span className="date">{item.hours}</span>
              </div>
              <hr />
              {item.statusName == "add" ? (
                <p>
                  <PlusCircle
                    style={{ marginRight: 10 }}
                    size={20}
                    color="#155724"
                    weight="bold"
                  />
                  {item.name}
                </p>
              ) : item.statusName == "remove" ? (
                <p>
                  <Trash
                    style={{ marginRight: 10 }}
                    size={20}
                    color="#721c24"
                    weight="bold"
                  />
                  {item.name}{" "}
                </p>
              ) : (
                <p
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                  }}
                >
                  <span>
                    <PencilSimple
                      style={{ marginRight: 10 }}
                      size={20}
                      color="#004085"
                      weight="bold"
                    />
                    {item.name + " "}
                  </span>{" "}
                  <span>
                    ({item?.qtdAdias}
                    <ArrowRight
                      style={{ margin: "0 5px" }}
                      size={18}
                      color="#004085"
                    />
                    {item?.qtdChange})
                  </span>
                </p>
              )}
            </div>
          ))
        ) : (
          <div className="message">Nenhuma alteração feita por você</div>
        )}
      </div>
    </div>
  );
};

export default Status;
