import styled from "styled-components";

export const Select = styled.select`
  width: 100%;
  height: 35px;
  // border: none;
  border-radius: 5px;
  border: 1px solid #d2d2d2;
  padding: 0 10px;
  &.erros {
    border: 1px solid #7c0000;
  }

  &:focus {
    border: 1px solid #0067b1;
  }
`;

export const TitleSelect = styled.label`
  margin-bottom: 5px !important;
  margin-top: 0.5rem !important;
`;
