import { call, put, takeEvery, all, fork } from "redux-saga/effects";

// Crypto Redux States
import { WarrantyActionTypes } from "./actionTypes";
import { warrantyApiSuccess, warrantyApiFail } from "./action";
import { toast } from "react-toastify";

//Include Both Helper File with needed methods
import { getWarrantyDetails as getWarrantyDetailsApi, getInvoice as getInvoiceApi, createWarranty as createWarrantyApi, getManufacturerWarranty as getManufacturerWarrantyApi } from "../../api/index";

function* getWarrantyDetails({ payload: filters }) {
  try {
    const response = yield call(getWarrantyDetailsApi, filters);
    yield put(
      warrantyApiSuccess(WarrantyActionTypes.GET_WARRANTY_DETAILS, response)
    );
  } catch (error) {
    yield put(warrantyApiFail(WarrantyActionTypes.GET_WARRANTY_DETAILS, error));
  }
}

export function* watchGetWarrantyDetails() {
  yield takeEvery(WarrantyActionTypes.GET_WARRANTY_DETAILS, getWarrantyDetails);
}

function* getInvoice({ payload: params }) {
  try {
    const response = yield call(getInvoiceApi, params);
    yield put(
      warrantyApiSuccess(WarrantyActionTypes.GET_INVOICE, response)
    );

  } catch (error) {
    yield put(warrantyApiFail(WarrantyActionTypes.GET_INVOICE, error));

    toast.error(`Ocorreu um erro ao pesquisar a Nota Fiscal. Tente novamente.`, {
      theme: "colored",
    });
  }
}

export function* watchGetInvoice() {
  yield takeEvery(WarrantyActionTypes.GET_INVOICE, getInvoice);
}

function* createWarranty({ payload: data }) {
  try {
    const response = yield call(createWarrantyApi, data);

    yield put(
      warrantyApiSuccess(
        WarrantyActionTypes.CREATE_WARRANTY,
        response
      )
    );

    toast.success(`Solicitação de Garantia realizada com sucesso!`, {
      theme: "colored",
    });

  } catch (error) {
    yield put(
      warrantyApiFail(
        WarrantyActionTypes.CREATE_WARRANTY,
        error
      )
    );

    toast.error(`Ocorreu um erro ao solicitar a garantia. Confira se preencheu todos os campos corretamente e se adicionou o arquivo. `, {
      theme: "colored",
    });
  }
}

export function* watchCreateWarranty() {
  yield takeEvery(WarrantyActionTypes.CREATE_WARRANTY, createWarranty);
}

function* getManufacturerWarranty({ payload: data }) {
  try {
    const response = yield call(getManufacturerWarrantyApi, data);

    yield put(
      warrantyApiSuccess(
        WarrantyActionTypes.GET_MANUFACTURER,
        response
      )
    );

  } catch (error) {
    yield put(
      warrantyApiFail(
        WarrantyActionTypes.GET_MANUFACTURER,
        error
      )
    );
  }
}

export function* watchGetManufacturerWarranty() {
  yield takeEvery(WarrantyActionTypes.GET_MANUFACTURER, getManufacturerWarranty);
}





function* warrantySaga() {
  yield all([fork(watchGetWarrantyDetails), fork(watchGetInvoice), fork(watchCreateWarranty), fork(watchGetManufacturerWarranty)]);
}

export default warrantySaga;
