import { toast } from "react-toastify";
import { typesRuleDiscountOptions } from "./constants";

//GERAR UM CODIGO ALEATORIO

export const getRandomString = tamanho => {
  var randomString = "";
  var caracteres =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
  for (var i = 0; i < tamanho; i++) {
    randomString += caracteres.charAt(
      Math.floor(Math.random() * caracteres.length)
    );
  }
  return randomString;
};

export const formatDiscountAmount = (value, typeRuleDiscountId) => {
  const typeRuleDiscountOption = typesRuleDiscountOptions.find((item, k) => item.id == typeRuleDiscountId);
  return typeRuleDiscountOption.mask(value);
}

//REMOVER UMA CODINÇÃO DO PRODUTO
export const submitConditionRemoveProduct = (
  position,
  data,
  dataProductSelect,
  objectDataProduct,
  setObjectDataProduct,
  setDataProductSelect
) => {
  var formatKey = data.fieldname.replace("product_atribute", "").trim();
  const dataRemoveProductSelect = dataProductSelect.filter(
    (item, k) => k != position
  );
  for (var item in objectDataProduct) {
    if (objectDataProduct[item][formatKey] == data.fielddescription) {
      delete objectDataProduct[item][formatKey];
    }
  }
  var newArrayObjects = [];
  for (var arraySemObjetosVazios in objectDataProduct) {
    if (Object.keys(objectDataProduct[arraySemObjetosVazios]).length != 0) {
      newArrayObjects.push(objectDataProduct[arraySemObjetosVazios]);
    }
  }

  setObjectDataProduct(newArrayObjects);
  setDataProductSelect(dataRemoveProductSelect);
};

// REMOVER UMA CONDIÇÃO DO CARRINHO
export const submitConditionRemove = (
  position,
  id,
  arrayFields,
  dataConditions,
  setArrayFields,
  setDataConditions
) => {
  if (id) {
    const dataRemoveUpdate = arrayFields.filter((item, k) => k != position);
    setArrayFields(dataRemoveUpdate);
  } else {
    const dataRemove = dataConditions.filter((item, k) => k != position);
    setDataConditions(dataRemove);
  }
};

export const addRuleCondition = (
  cartRulesId,
  selectedRuleColumnField,
  valueToAdd,
  operator,
  selectedCartRulesConditions,
  setSelectedCartRulesConditions,
) => {
  const isExist = selectedCartRulesConditions.find((item, k) => item.column_field_id == selectedRuleColumnField.id);

  if (isExist) {
    toast.error("A condição selecionada já existe")
    return;
  }

  const updateSelectedPromotionRulesList = [...selectedCartRulesConditions];

  const newCartRulesConditions = {
    cart_rules_id: cartRulesId,
    column_field_id: selectedRuleColumnField.id,
    column_field: selectedRuleColumnField,
    operator: operator.id,
    value: valueToAdd
  };

  updateSelectedPromotionRulesList.push(newCartRulesConditions)

  setSelectedCartRulesConditions(updateSelectedPromotionRulesList);
};

export const removeCondition = (
  columnFieldId,
  selectedCartRulesConditions,
  setSelectedCartRulesConditions
) => {
  const dataRemoveUpdate = selectedCartRulesConditions.filter((item, k) => item.column_field_id != columnFieldId);
  setSelectedCartRulesConditions(dataRemoveUpdate);
};

//SALVAR UMA CONDIÇÃO = CARRINHO / PRODUTO

export const submitCondition = (
  id,
  arrayFields,
  dataConditions,
  key,
  value,
  dataProductSelect,
  objectDataProduct,
  setObjectDataProduct,
  description,
  setKey,
  setValue,
  setArrayFields,
  setDataConditions,
  setDataProductSelect
) => {
  var verifyExistsData = (id ? arrayFields : dataConditions).find(
    item => item.fieldname === key
  );

  //valida se e produto ou carrinho
  var findProduct = key.search("product_atribute");

  if (key == "Selecione" || key == "" || value == "") {
    return toast.error("Selecione um opção e preencha o campo!", {
      theme: "colored",
    });
  } else if (findProduct == -1) {
    if (verifyExistsData) {
      return toast.error("Esta condição já se econtra adicionada", {
        theme: "colored",
      });
    }
  }

  var formatKey = key.replace("product_atribute", "").trim();
  if (findProduct != -1) {
    var interruptFunction = false;
    for (var i = 0; interruptFunction == false; i++) {
      var existsInTheObject = false;
      for (var item in objectDataProduct[i]) {
        if (item == formatKey) {
          existsInTheObject = true;
        }
      }

      if (!existsInTheObject) {
        interruptFunction = true;
        const newData = objectDataProduct.map((item, k) => {
          return k == i ? { ...item, [formatKey]: value } : item;
        });

        setObjectDataProduct(newData);
      }

      if (objectDataProduct.length == i) {
        setObjectDataProduct(prevState => [
          ...prevState,
          { [formatKey]: value },
        ]);
      }
    }
  }

  const objectData = {
    fieldname: key,
    fielddescription: value,
    description,
  };

  setKey("");
  setValue("");
  if (findProduct == -1) {
    if (id) {
      setArrayFields(prevState => [...prevState, objectData]);
    } else {
      setDataConditions(prevState => [...prevState, objectData]);
    }
  } else {
    setDataProductSelect(prevState => [...prevState, objectData]);
  }
  toast.success("Condição adicionada", { theme: "colored" });
};

//VALIDA SE ABRE OU FECHA A SESSOES

export const verifyActiveSection = (id, activeSection, setAcitveSection) => {
  const newData = activeSection.map(item => {
    return item.id == id ? { ...item, active: !item.active } : item;
  });
  setAcitveSection(newData);
};

export const formatData = (data, dataProducts, setDataProductSelect) => {
  var objectData = {};
  var arrayObject = [];
  var stateFormat = [];
  var arrayFindKeys = [];
  var arrayFindValues = [];

  //DESCARTA ITENS DO OBJETO DATA.GET_PRODUCTS_RULES
  for (var i = 0; i <= data?.get_products_rules?.length; i++) {
    for (var item in data.get_products_rules[i]) {
      if (
        data.get_products_rules[i][item] == null ||
        item == "cart_rules_id" ||
        item == "created_at" ||
        item == "id" ||
        item == "updated_at" ||
        item == "get_products_sub_rules" ||
        item == "get_relationshiprules"
      ) {
        delete data.get_products_rules[i][item];
      }
    }
  }

  //PEGA AS CHAVES DO OBJETO E COLOCAR EM UM ARRAY
  for (var i = 0; i <= data?.get_products_rules?.length; i++) {
    for (var j in data?.get_products_rules[i]) {
      stateFormat.push(j);
    }
  }
  //COMPARA AS CHAVES DE DOIS OBJETOS E RETORNA OS IGUAIS
  for (var i = 0; i <= dataProducts?.fields.length; i++) {
    for (var j = 0; j <= stateFormat.length; j++) {
      if (dataProducts?.fields[i]?.fieldname.includes(stateFormat[j])) {
        //ARRAY COM AS CHAVES DO OBJETO
        arrayFindKeys.push(dataProducts?.fields[i]?.fieldname);
        //ARRAY COM OS VALORES DO OBJETO
        arrayFindValues.push(dataProducts?.fields[i]?.fielddescription);
      }
    }
  }

  //CRIA O OBJETO COM OS RESPECTIVOS DADOS
  for (var j = 0; j <= data?.get_products_rules.length; j++) {
    for (var item in data?.get_products_rules[j]) {
      //COMPARA AS CHAVES DO OBJETO CASO FOR IGUAL, RETORNA A POSIÇÃO DO VALOR NO ARRAY DE VALORES = arrayFindValues
      var findNameDescription = arrayFindKeys.map((value, k) => {
        return value == item && arrayFindValues[k];
      });
      //REMOVE OS ITENS NULOS NO ARRAY
      const filteredArray = findNameDescription.filter(function (ele, pos) {
        return findNameDescription.indexOf(ele) == pos;
      });
      objectData = {
        ...objectData,
        fieldname: item,
        fielddescription: data?.get_products_rules[j][item],
        description: filteredArray,
      };

      arrayObject.push(objectData);
    }
  }

  setDataProductSelect(arrayObject);
};

// FORMATA OS DADOS DO SELECT DE PRODUTOS
export const formatArraySelect = (data, conditionsSelect, setArrayFields) => {
  for (var item in data) {
    if (data[item] == null) {
      delete data[item];
    }
  }

  var arrayData = Object.keys(data).map(item => {
    return item;
  });

  var verifyFields = conditionsSelect?.filter(item =>
    arrayData.includes(item.fieldname)
  );

  setArrayFields(verifyFields);
};

//PREENCHE OS DADOS DA API NOS CAMPOS
export const getInfosInput = (
  data,
  setActive,
  setNeedCupom,
  setApplyonlythisrule,
  setApplytoshipping,
  setSelectedProductRules,
  formRef
) => {
  try {
    setActive(data?.active == 1);
    setNeedCupom(data?.need_coupom == 1);
    setApplyonlythisrule(data?.applyonlythisrule);
    setApplytoshipping(data?.applytoshipping);
    setSelectedProductRules(data?.get_products_rules);
    formRef.current._inputs.coupom_used.value = data?.coupom_used;
    formRef.current._inputs.end.value =
      data.end !== null ? data?.end.slice(0, 10) : null;
    formRef.current._inputs.maxproductsdiscount.value =
      data?.maxproductsdiscount;
    formRef.current._inputs.rule_description.value = data?.rule_description;
    formRef.current._inputs.rule_name.value = data?.rule_name;
    formRef.current._inputs.start.value =
      data.start !== null ? data?.start.slice(0, 10) : null;
    formRef.current._inputs.typerulediscount.value = data?.typerulediscount;
    formRef.current._inputs.valuediscount.value = data?.valuediscount;
  } catch (err) {
    toast.error(err, { theme: "colored" });
  }
};
