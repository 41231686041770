import React from "react";
import { CardBody, Card } from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { clearNewOrder } from "../../store/actions";

const PedidoEmitido = ({ type }) => {
  const dispatch = useDispatch();
  const { emissaoPedido } = useSelector(state => ({
    emissaoPedido: state.Order.newOrder.newOrder.emissaoPedido,
  }));

  return (
    <Card className="pedido-emitido" style={{ marginBottom: 200 }}>
      <CardBody
        className="d-flex flex-column align-items-center"
        style={{ padding: 50 }}
      >
        <div className="check" style={{ fontSize: 50, color: "#13b013" }}>
          <i className="ph-check-circle"></i>
        </div>
        <h3 style={{ fontSize: 18 }}>
          Seu {type == "order" ? "pedido" : "orçamento"} foi realizado com
          sucesso!
        </h3>
        <p style={{ marginBottom: 30 }}>
          Número do {type == "order" ? "pedido" : "orçamento"} : #
          {emissaoPedido.data.CODPEDIDO}
        </p>
        <div className="botoes-emitido">
          <Link
            onClick={() => dispatch(clearNewOrder())}
            to={
              type == "order"
                ? `/pedidos/${("000000" + emissaoPedido.data.CODPEDIDO).slice(
                    -6
                  )}/order`
                : ""
            }
            style={{
              fontSize: 14,
              padding: "15px 30px",
              fontWeight: "bold",
              color: "#005FA6",
              backgroundColor: "#DCF3FE",
              border: "1px solid #DCF3FE",
              margin: "20px 10px 0",
              borderRadius: 5,
            }}
          >
            Visualizar {type == "order" ? "pedido" : "orçamento"}{" "}
          </Link>
          <a
            href="/pedidos/novo"
            style={{
              fontSize: 14,
              padding: "15px 30px",
              fontWeight: "bold",
              color: "#FFFFFF",
              backgroundColor: "#005FA6",
              border: "1px solid #005FA6",
              margin: "20px 10px 0",
              borderRadius: 5,
            }}
          >
            Novo {type == "order" ? "pedido" : "orçamento"}
          </a>
        </div>
      </CardBody>
    </Card>
  );
};

export default PedidoEmitido;
