import { BudgetActionTypes } from "./actionTypes";

const INIT_STATE = {
  list: {
    loading: false,
    fetch: false,
    budgets: [],
  },
  active: {
    loading: false,
    fetch: false,
    data: {},
  },
};

const Budget = (state = INIT_STATE, action) => {
  switch (action.type) {
    case BudgetActionTypes.API_RESPONSE_SUCCESS:
      switch (action.payload.actionType) {
        case BudgetActionTypes.GET_DATA_CLIENT_ORDER_CHILDREN:
          return {
            ...state,
            active: {
              loading: false,
              fetch: true,
              data: {
                ...state.active.data,
                dataClientOrderChildren: action.payload.data,
              },
            },
          };
        case BudgetActionTypes.CLEAR_DATA_ACTIVE:
          return {
            ...state,
            active: {
              loading: false,
              fetch: false,
              data: {},
            },
          };
        case BudgetActionTypes.LIST_BUDGETS:
          return {
            ...state,
            list: {
              loading: false,
              fetch: true,
              budgets: action.payload.data,
            },
          };
        case BudgetActionTypes.GET_BUDGET:
          return {
            ...state,
            active: {
              loading: false,
              fetch: true,
              data: action.payload.data,
            },
          };
        default:
          return state;
      }
    case BudgetActionTypes.API_RESPONSE_ERROR:
      switch (action.payload.actionType) {
        case BudgetActionTypes.LIST_BUDGETS:
          return {
            ...state,
            list: {
              ...state.list,
              loading: false,
            },
          };
        case BudgetActionTypes.GET_BUDGET:
          return {
            ...state,
            active: {
              ...state.active,
              loading: false,
            },
          };
        default:
          return state;
      }

    case BudgetActionTypes.LIST_BUDGETS:
      return {
        ...state,
        list: {
          loading: true,
          fetch: false,
          budgets: [],
        },
      };

    case BudgetActionTypes.GET_BUDGET:
      return {
        ...state,
        active: {
          loading: true,
          fetch: false,
          data: [],
        },
      };

    default:
      return state;
  }
};

export default Budget;
