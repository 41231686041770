import { all, call, fork, put, takeEvery } from "redux-saga/effects";

import { 
    manufacturersApiFail, 
    manufacturersApiSuccess 
} from "./action";

import { ManufacturersActionTypes } from "./actionTypes";

import { 
    getManufacturersList as getManufacturersListApi 
} from "../../../api/index";

function* getManufacturersList() {
    try {
        const response = yield call(getManufacturersListApi);
        yield put(
            manufacturersApiSuccess(ManufacturersActionTypes.GET_MANUFACTURERS_LIST, response)
        )

    } catch (error) {
        yield put(manufacturersApiFail(ManufacturersActionTypes.GET_MANUFACTURERS_LIST, error))
    }
}

export function* watchGetManufacturersList() {
    yield takeEvery(
        ManufacturersActionTypes.GET_MANUFACTURERS_LIST,
        getManufacturersList
    );
}

function* manufacturersSaga() {
    yield all([
        fork(watchGetManufacturersList)
    ])
}

export default manufacturersSaga;