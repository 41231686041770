import React from "react";

function AmbientObject({ item, ambients }) {
  const [allAmbients, setAllAmbients] = ambients;

  const handleItemDelete = () => {
    setAllAmbients(
      allAmbients.filter(i => i.ambient_name !== item.ambient_name)
    );
  };

  return (
    <div className="rounded-bg list-type-none ps-0 py-2 font-bold d-flex justify-content-center text-center relative">
      <div className="bg-light-gray " style={{ width: "16%" }}>
        {item.grupos}
      </div>
      <div className="bg-light-gray" style={{ width: "16%" }}>
        {item.categoria}
      </div>
      <div className="bg-light-gray" style={{ width: "16%" }}>
        {item.ciclo}
      </div>
      <div className="bg-light-gray" style={{ width: "16%" }}>
        {item.capacidade}
      </div>
      <div className="bg-light-gray" style={{ width: "16%" }}>
        {item.voltagem}
      </div>
      <div className="bg-light-gray" style={{ width: "16%" }}>
        {item.ambient_name}
      </div>
      <div
        className="absolute position-ambient cursor-pointer"
        onClick={handleItemDelete}
      >
        <i className="fas fa-trash-alt"></i>
      </div>
    </div>
  );
}

export default AmbientObject;
