import React from "react";

import {
  Container,
  Card,
  CardBody,
  UncontrolledCollapse,
  Button,
} from "reactstrap";

import "./vrf.css";

import trane from "../../assets/images/vendedores/logo-trane.png";
import daikin from "../../assets/images/vendedores/logo-daikin.png";
import passo1trane from "../../assets/images/vendedores/passo1-trane.png";
import passo2trane from "../../assets/images/vendedores/passo2-trane.png";

import { FormDefault } from "../../components/forms/FormDefault";

const Vrf = () => {
  const componentPropsToForm = () => {
    return (
      <p className="texto-duvidas">
        Você tem dúvidas sobre VRF? Envie sua dúvida que um de nossos
        consultores entrará em contato.
      </p>
    );
  };

  return (
    <>
      <div className="page-content vrf">
        <Container>
          <Card className="engloba-card-fabricantes">
            <CardBody>
              <h4 className="titulo-fabricantes">
                Fabricantes (Clique para detalhes)
              </h4>
              <hr className="linha-fabricantes" />
              <div className="engloba-img-card-trane">
                <Button id="box-trane" className="botao-colapse">
                  <img src={trane} className="img-trane" />
                </Button>
                <UncontrolledCollapse toggler="#box-trane">
                  <Button id="catalogo-trane" className="botao-colapse">
                    <h5>CATALOGOS - TRANE</h5>
                  </Button>
                  <UncontrolledCollapse toggler="#catalogo-trane">
                    <Card>
                      <CardBody></CardBody>
                    </Card>
                  </UncontrolledCollapse>
                  <Button id="treinamentos-trane" className="botao-colapse">
                    <h5>TREINAMENTOS - TRANE</h5>
                  </Button>
                  <UncontrolledCollapse toggler="#treinamentos-trane">
                    <Card>
                      <CardBody>
                        <Button
                          id="passo-a-passo-trane"
                          className="botao-colapse"
                        >
                          <h5>Passo ao Passo treinamento TRANE</h5>
                        </Button>
                        <UncontrolledCollapse toggler="#passo-a-passo-trane">
                          <Card>
                            <CardBody>
                              <p>Passo a passo para acesso à plataforma TEC</p>
                              <p>1) Acesse: www.trane.com/tec</p>
                              <img
                                src={passo1trane}
                                className="imagem-treinamento"
                              />
                              <p>2) Clique em “New Customer? Register Here”</p>
                              <p>
                                3) Preencha os campos obrigatórios (marcados com
                                um asterisco)
                              </p>
                              <p>4) Crie um “Username” e uma senha de acesso</p>
                              <p>
                                5) Em “Local Trane Sales Office” digite na busca
                                “Trane do Brazil” e selecione a opção
                                correspondente
                              </p>
                              <p>6) Clique em “Submit”</p>
                              <p>
                                7) Uma vez cadastrado, ao realizar o login na
                                plataforma, você irá visualizar a tela abaixo
                              </p>
                              <img
                                src={passo2trane}
                                className="imagem-treinamento"
                              />
                              <p>
                                8) Para acessar os treinamentos de VRF, clique
                                em “Catalog” e posteriormente em “Browse”
                              </p>
                              <p>9) Em “Language”, selecione “Português”</p>
                              <p>
                                10) Mova a barra de rolagem para baixo e
                                visualize os treinamentos com as seguintes
                                nomenclaturas:
                              </p>
                              <ul>
                                <li>Treinamento Automação VRF</li>
                              </ul>
                              <ul>
                                <li>Treinamento de Instalação VRF</li>
                              </ul>
                              <ul>
                                <li>Treinamento de Manutenção VRF</li>
                              </ul>
                              <p className="formas-treinamento">
                                Outra forma de encontrar os treinamentos é:
                              </p>
                              <p>
                                1) Clique em “Catalog” e posteriormente em
                                “Browse”
                              </p>
                              <p>
                                2) Digite “VRF” na busca e clique na lupa no
                                lado superior direito
                              </p>
                              <p>
                                3)Será possível visualizar os mesmos
                                treinamentos com as nomenclaturas acima em
                                português Em caso de dúvidas, entre em contato
                                com o profissional da Trane mais próximo.Bom
                                treinamento!
                              </p>
                            </CardBody>
                          </Card>
                        </UncontrolledCollapse>
                      </CardBody>
                    </Card>
                  </UncontrolledCollapse>
                  <Button id="sofware-trane" className="botao-colapse">
                    <h5>SOFTWARE - TRANE</h5>
                  </Button>
                  <UncontrolledCollapse toggler="#sofware-trane">
                    <Card>
                      <CardBody></CardBody>
                    </Card>
                  </UncontrolledCollapse>
                </UncontrolledCollapse>
              </div>

              <div className="engloba-img-card-daikin">
                <Button id="box-daikin" className="botao-colapse">
                  <img src={daikin} className="img-daikin" />
                </Button>
                <UncontrolledCollapse toggler="#box-daikin">
                  <Button id="catalogo-daikin" className="botao-colapse">
                    <h5>CATALOGOS - DAIKIN</h5>
                  </Button>
                  <UncontrolledCollapse toggler="#catalogo-daikin">
                    <Card>
                      <CardBody></CardBody>
                    </Card>
                  </UncontrolledCollapse>
                  <Button id="treinamento-daikin" className="botao-colapse">
                    <h5>TREINAMENTOS - DAIKIN</h5>
                  </Button>
                  <UncontrolledCollapse toggler="#treinamento-daikin">
                    <Card>
                      <CardBody>
                        <Button
                          id="passo-a-passo-daikin"
                          className="botao-colapse"
                        >
                          <h5>Passo ao Passo treinamento DAIKIN</h5>
                        </Button>
                        <UncontrolledCollapse toggler="#passo-a-passo-daikin">
                          <Card>
                            <CardBody></CardBody>
                          </Card>
                        </UncontrolledCollapse>
                      </CardBody>
                    </Card>
                  </UncontrolledCollapse>
                  <Button id="software-daikin" className="botao-colapse">
                    <h5>SOFTWARE - DAIKIN</h5>
                  </Button>
                  <UncontrolledCollapse toggler="#software-daikin">
                    <Card>
                      <CardBody></CardBody>
                    </Card>
                  </UncontrolledCollapse>
                </UncontrolledCollapse>
              </div>
            </CardBody>
          </Card>

          <Card>
            <CardBody>
              <div>
                <h4>
                  <i className="ph-paper-plane-tilt icone-formulario"></i>
                  Formulário de contato
                </h4>
              </div>

              <hr />

              <FormDefault componenetProps={componentPropsToForm()} />
            </CardBody>
          </Card>
        </Container>
      </div>
    </>
  );
};

export default Vrf;
