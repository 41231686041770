import React, { useRef } from "react";
import ReactEcharts from "echarts-for-react";

const AvailableCouponsChart = ({ data }) => {
  const chartRef = useRef(null);
  const gaugeData = [
    {
      value: data,
      name: "Benefits",
      title: {
        show: false,
        offsetCenter: ["0%", "0%"],
      },
      detail: {
        valueAnimation: true,
        offsetCenter: ["0%", "0%"],
      },
    },
  ];

  const options = {
    series: [
      {
        type: "gauge",
        min: 0,
        max: 10,
        startAngle: 90,
        endAngle: -270,
        pointer: {
          show: false,
        },
        itemStyle: {
          color: "#404B5A",
        },
        progress: {
          show: true,
          overlap: false,
          roundCap: true,
          clip: false,
          itemStyle: {
            borderWidth: 0,
          },
        },
        axisLine: {
          lineStyle: {
            width: 15,
            color: [[1, "#E7E5E6"]],
          },
        },
        splitLine: {
          show: false,
          distance: 0,
          length: 10,
        },
        axisTick: {
          show: false,
        },
        axisLabel: {
          show: false,
          distance: 50,
        },
        data: gaugeData,
        title: {
          fontSize: 14,
        },
        detail: {
          rich: {
            value: {
              fontSize: 40,
              fontWeight: "bolder",
              color: "#202833",
            },
            unit: {
              fontSize: 25,
              color: "#929FB1",
              padding: [0, 0, -10, 0],
            },
          },
          formatter: function (value) {
            return "{value|" + value.toFixed(0) + "}{unit|/10}";
          },
          //formatter: '{value}%'
        },
      },
    ],
  };

  return (
    <div style={{ height: 230 }}>
      <ReactEcharts
        ref={chartRef}
        option={options}
        style={{ height: "100%", width: "100%" }}
      />
    </div>
  );
};

export default AvailableCouponsChart;
