import React from "react";
import { Button } from "reactstrap";

const Name = document => {
  const name = document.document.name ? document.document.name : "-";
  return <div> { name } </div>;
}

const File = document => {
  const link = document.document.link ? document.document.link : "-";
  const fileName = document.document.filename ? document.document.filename : "-";
  return <div> 
    <a href={ link }>{ fileName }</a> 
  </div>;
}

const Description = document => {
  const description = document.document.desc ? document.document.desc : "-"; 
  return <div> { description } </div>;
}

const Action = ({ document, handleClickDelete, loadingDelete }) => {
  return <div> 
    <Button
      color="danger"
      type="submit"
      onClick={() => handleClickDelete(document)}
      disabled={loadingDelete}>
      Excluir
    </Button>
  </div>;
}

export { Name, File, Description, Action }