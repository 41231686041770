import { ShoppingCart } from "phosphor-react";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-scroll";
import styled from "styled-components";

const ControleBtn = styled.button`
  font-size: 16px;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #FF601A;
  color: #fff;
  position: fixed;
  right: 20px;
  bottom: 20px;
  border: none;
  border-radius: 50%;
  z-index: 1000;
`;

const ControleRemoto = () => {
  const [openCtl, setOpenCtl] = useState(false);

  const { addedItems } = useSelector(state => ({
    addedItems: state.Order.newOrder.newOrder.addedItems,
  }));

  return (
    <>
      <ControleBtn onClick={() => setOpenCtl(!openCtl)} className="relative">
        <Link to="carrinho" smooth={true} duration={300} activeClass="active">
          <ShoppingCart size={22} />
        </Link>
        {addedItems?.length > 0 && (
          <div
            style={{
              position: "absolute",
              top: "-5px",
              right: "-7px",
              background: "white",
              borderRadius: "100%",
              width: "20px",
              height: "20px",
              border: "solid 2px #FF601A",
              fontSize: "12px",
              color: "#FF601A",
              fontWeight: "bold",
            }}
          >
            {addedItems?.length}
          </div>
        )}
      </ControleBtn>
    </>
  );
};

export default ControleRemoto;
