// import { apiClient } from "./apiCore";
import { apiClient2 } from "./apiCore2";

export const listSellers = (filter) => {
    const baseUrl = `/vendedores`;
    return apiClient2.get(`${baseUrl}`, filter);
};

export const getSeller = (id) => {
    const baseUrl = `/vendedores/${id}`;
    return apiClient2.get(`${baseUrl}`);
};

export const getTelephones = (id) => {
    const baseUrl = `/api/vendedor/${id}/telefone_api`;
    return apiClient2.get(`${baseUrl}`);
};

export const updateSeller = ({id, seller}) => {
    const baseUrl = `/vendedores/${id}/update`;
    return apiClient2.update(`${baseUrl}`, {seller: seller});
}

export const deleteTelephone = ({id, codigo}) => {
    const baseUrl = `/api/vendedor/${id}/telefone/${codigo}`;
    return apiClient2.delete(`${baseUrl}`);
}

export const addTelephone = ({id, tel}) => {
    const baseUrl = `/api/vendedor/${id}/telefone`;
    return apiClient2.create(`${baseUrl}`,tel);
}

export const updateTelephone = ({id, tel}) => {
    const baseUrl = `/api/vendedor/${id}/telefone`;
    return apiClient2.update(`${baseUrl}`,tel);
}