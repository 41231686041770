import { API_FAIL, API_SUCCESS, DELETE_SHARING, GET_SHARING_HISTORY, INSERT_SHARING } from "./actionTypes";

const INIT_STATE = {
  shares: [],
  searchShares: false,
  loading: false
};

const SharedResale = (state = INIT_STATE, action) => {
  switch (action.type) {
    case API_SUCCESS:
      switch (action.payload.actionType) {
        case GET_SHARING_HISTORY:
          return {
            shares: action.payload.data,
            loading: false,
            searchShares: false
          };

        case INSERT_SHARING:
        case DELETE_SHARING:
          return {
            ...state,
            loading: false,
            searchShares: true
          };

        default:
          return { ...state };
      }
    case API_FAIL:
      switch (action.payload.actionType) {
        case GET_SHARING_HISTORY:
          return {
            shares: [],
            loading: false
          };

        case INSERT_SHARING:
        case DELETE_SHARING:
          return {
            ...state,
            loading: false
          };

        default:
          return { ...state };
      }
    case GET_SHARING_HISTORY:
    case INSERT_SHARING:
    case DELETE_SHARING:
      return {
        ...state,
        loading: true
      }

    default:
      return state;
  }
};

export default SharedResale;
