import { WarrantyDownloadActionTypes } from "./actionTypes";

export const warrantyDownloadApiSuccess = (actionType, data) => ({
  type: WarrantyDownloadActionTypes.API_RESPONSE_SUCCESS,
  payload: { actionType, data },
});

export const warrantyDownloadApiFail = (actionType, error) => ({
  type: WarrantyDownloadActionTypes.API_RESPONSE_FAIL,
  payload: { actionType, error },
});

export const getWarrantyDownloads = manufacturerId => ({
  type: WarrantyDownloadActionTypes.GET_WARRANTY_DOWNLOADS,
  payload: manufacturerId
});

export const sendWarrantyDownload = ( data ) => ({
  type: WarrantyDownloadActionTypes.SEND_WARRANTY_DOWNLOAD,
  payload: data 
});

export const deleteWarrantyDownload = id => ({
  type: WarrantyDownloadActionTypes.DELETE_WARRANTY_DOWNLOAD,
  payload: id
});