import React from "react";
import SimpleBar from "simplebar-react";

const AppScrollBar = ({ children, style, className }) => {
  return (
    <SimpleBar style={style} className={className}>
      {children}
    </SimpleBar>
  );
};

export default AppScrollBar;
