import * as Yup from "yup";

const initialValuesToFormik = {
  CPFCNPJ: "",
  TP_PESSOA: "J",
  Contrib: "2",
  Inscr: "",
  Rg: "",
  N_COMPLETO: "",
  N_REDZ: "",
  EMAIL: "",
  DT_NASC: "",
  CONTATO: "",
  CARGO: "",
  DDD: "",
  TEL: "",
  DDD_CEL: "",
  CELULAR: "",
  CEP: "",
  UF: "",
  MUNICIPIO: "",
  BAIRRO: "",
  ENDERECO: "",
  NumEndCob: "",
  COMPL: "",
  CEP_ENT: "",
  UF_ENT: "",
  MunicipEnt: "",
  BAI_ENT: "",
  END_ENT: "",
  NumEndEnt: "",
  COMPL_ENT: "",
  BANCO: "",
  Agencia: "",
  Conta: "",
  CapSoc: "",
  FatMen: "",
  FuncEmp: "",
  Socio: "",
  CpfRef: "",
  EMPTRAB: "",
  CNPJTRAB: "",
  TELREFBANC: "",
  DTADMISS: "",
};

const RegisterSchema = Yup.object().shape({
  CPFCNPJ: Yup.string().required("Campo obrigatório"),
  TP_PESSOA: Yup.string().required("Campo obrigatório"),
  Contrib: Yup.string().required("Campo obrigatório"),
  N_COMPLETO: Yup.string().required("Campo obrigatório"),
  N_REDZ: Yup.string().required("Campo obrigatório"),
  EMAIL: Yup.string()
    .email("Digite um email válido")
    .required("Campo obrigatório"),
  CONTATO: Yup.string().required("Campo obrigatório"),
  CARGO: Yup.string().required("Campo obrigatório"),
  DDD: Yup.string().required("Campo obrigatório"),
  TEL: Yup.string().required("Campo obrigatório"),
  DDD_CEL: Yup.string().required("Campo obrigatório"),
  CELULAR: Yup.string().required("Campo obrigatório"),
  CEP: Yup.string().required("Campo obrigatório"),
  UF: Yup.string().required("Campo obrigatório"),
  MUNICIPIO: Yup.string().required("Campo obrigatório"),
  BAIRRO: Yup.string().required("Campo obrigatório"),
  ENDERECO: Yup.string().required("Campo obrigatório"),
  NumEndCob: Yup.string().required("Campo obrigatório"),
  COMPL: Yup.string(),
});

export { initialValuesToFormik, RegisterSchema };
