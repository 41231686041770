import React from "react";
import { Popover, PopoverHeader, PopoverBody } from "reactstrap";
import { Link } from "react-router-dom";

const tableClient = ({ dataZcm, setDataZcm, number }) => {
  const toggle = id => {
    const newState = dataZcm?.map((item, k) => {
      return k == id
        ? { ...item, active: !item.active }
        : { ...item, active: false };
    });
    setDataZcm(newState);
  };

  const getFilial = value => {
    switch (value) {
      case "1":
        return "MPS São Paulo / SP";

      case "2":
        return "MPS Campo Grande / MS";

      case "3":
        return "MPS João Pessoa / PB";

      case "4":
        return "MPS Simões Filho / BA";

      default:
        // eslint-disable-next-line no-undef
        console.log(`Sorry, we are out of ${value}.`);
    }
  };

  return (
    <div className="table-responsive">
      <table className="table mt-5">
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">Nome Cliente</th>
            <th scope="col">Pedido</th>
            <th scope="col">Filial</th>
            <th scope="col">Valor Pedido</th>
            <th scope="col">% RT Ped.</th>
            <th scope="col">% RT Pag.</th>
            <th scope="col">Valor RT</th>
          </tr>
        </thead>
        <tbody>
          {dataZcm &&
            dataZcm.map((item, k) => (
              <>
                {" "}
                <tr
                  key={k}
                  style={{
                    background: item.ZCM_VLRCOM < 0 && "#f2dede",
                    color: item.ZCM_VLRCOM < 0 && "#a94442",
                  }}
                  onClick={() => toggle(k)}
                  id={"Popover" + k}
                >
                  <th scope="row">{String(number + k).padStart(3, "0")}</th>
                  <th scope="row">{item.pedido.NomeCliente}</th>
                  <td>
                    {" "}
                    <Link to={`/pedidos/${item.ZCM_NUMPED}/order`}>
                      {item.pedido.NumPedido}
                    </Link>
                  </td>
                  <td>{getFilial(item.pedido.C5_FILIAL.substring(1, 2))}</td>
                  <td>
                    {parseFloat(item.ZCM_VLRPED)?.toLocaleString("pt-br", {
                      style: "currency",
                      currency: "BRL",
                    })}
                  </td>
                  <td>
                    {parseFloat(item.pedido.PercentualRT)?.toLocaleString(
                      "pt-br",
                      {
                        minimumFractionDigits: 2,
                      }
                    )}
                    %
                  </td>
                  <td>
                    {" "}
                    {parseFloat(item.pedido.PercentualRT)?.toLocaleString(
                      "pt-br",
                      {
                        minimumFractionDigits: 2,
                      }
                    )}
                    %
                  </td>
                  <td>
                    {" "}
                    {parseFloat(item.ZCM_VLRCOM)?.toLocaleString("pt-br", {
                      style: "currency",
                      currency: "BRL",
                    })}
                  </td>

                  <Popover
                    placement="top"
                    trigger="focus"
                    isOpen={item.active}
                    target={"Popover" + k}
                    toggle={() => toggle(k)}
                  >
                    <PopoverHeader>Status ZCM</PopoverHeader>
                    <PopoverBody>
                      {item.ZCM_STATUS.substring(3, item.ZCM_STATUS.lenght)}
                    </PopoverBody>
                  </Popover>
                </tr>
              </>
            ))}
        </tbody>
      </table>
    </div>
  );
};

export default tableClient;
