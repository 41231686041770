import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Alert,
  Spinner,
} from "reactstrap";
import { useParams } from "react-router-dom";
import * as moment from "moment";
import TableClient from "./tableClient";
import { getClosuresCard } from "../../../api";

function SettlementDetails() {
  const [data, setData] = useState([]);
  const { id } = useParams();

  useEffect(() => {
    const getData = async () => {
      const response = await getClosuresCard(id);
      setData(response);
    };
    getData();
  }, []);

  const [loadingClosures] = useState(false);
  const [dataZcm, setDataZcm] = useState([]);

  var total = data?.zcm?.reduce(
    (total, valor) => total + parseFloat(valor.ZCM_VLRCOM),
    0
  );

  var totalSoma = parseFloat(data?.somaTotal);

  var acrescimos = totalSoma - total;

  var number = 1;

  useEffect(() => {
    if (data?.zcm) {
      setDataZcm(data.zcm);
    }
  }, [data?.zcm]);

  return (
    <>
      <div className="page-content">
        {" "}
        {loadingClosures ? (
          <Spinner style={{ margin: "20% 50%" }} />
        ) : data == undefined || data?.length == 0 ? (
          <Alert color="danger">
            Fornecedor não encontrado. Entre em contato com seu consultor.
          </Alert>
        ) : (
          <Container>
            <Row>
              <Col>
                <Card>
                  <CardBody>
                    <h4>Fechamentos de RT</h4>
                    <Row>
                      {data && !data?.fechamento_files[0] ? (
                        <Alert color="danger">
                          Ainda não recebemos nenhuma nota fiscal para o
                          fechamento abaixo, fique atento com o prazo de envio
                          para evitar transtornos.
                        </Alert>
                      ) : (
                        data?.fechamento_files.map(
                          item =>
                            item.status_id == 3 && (
                              <Alert>
                                Recebemos 1 NF(s) para esse fechamento e foi
                                aceita pelo analista. Última alteração ocorreu{" "}
                                {moment(
                                  item?.updated_at.substring(0, 10)
                                ).format("L")}{" "}
                                {item?.updated_at.substring(10, 20)}
                              </Alert>
                            )
                        )
                      )}

                      <Col md={12}>
                        <div className="text-end">
                          <p>
                            Leia este{" "}
                            <Link to="/conhecimento/7"> passo a passo</Link>{" "}
                            antes de emitir sua NF.
                          </p>
                          <h4 className="mb-4">
                            #{data?.fechamento[0]?.C7_NUM} Emissão :{" "}
                            {moment(data?.fechamento[0]?.C7_EMISSAO).format(
                              "L"
                            )}
                          </h4>
                          <p className="font-size-18">
                            <span className="text-success me-2">Duvidas?</span>
                            <a
                              href={`mailto:${data?.vendedor1?.EMAIL?.toLowerCase()}`}
                              style={{
                                color: "#0067b1",
                                cursor: "pointer",
                              }}
                            >
                              {data?.vendedor1?.EMAIL?.toLowerCase()}
                            </a>
                          </p>
                        </div>
                      </Col>
                    </Row>
                    <hr />
                    <Row>
                      <Col md={4}>
                        <div>
                          <h4 className="mb-2">Parceiro :</h4>
                          <p className="mb-1">
                            <strong className="me-1">
                              #{data?.vendedor2?.CODIGO}
                            </strong>
                            {data?.vendedor2?.NREDUZ}
                          </p>
                          <p className="mb-1">
                            <strong className="me-1">Vendedor</strong>
                            {data?.vendedor2?.SUPERVISOR.substring(
                              0,
                              data?.vendedor2?.SUPERVISOR.indexOf(" ")
                            )}
                          </p>
                          <p className="mb-1">
                            {data?.vendedor2?.DDD_TEL}-
                            {data?.vendedor2?.TELEFONE}
                          </p>
                          <p className="mb-1">{data?.vendedor2?.EMAIL}</p>
                          <p className="mb-1">
                            {data?.vendedor2?.ENDERECO} ,{" "}
                            {data?.vendedor2?.ENDNUM}
                          </p>
                          <p>
                            {data?.vendedor2?.CEP} - {data?.vendedor2?.CIDADE} /
                            {data?.vendedor2?.ESTADO}
                          </p>
                        </div>
                      </Col>
                      <Col md={4}>
                        <h4 className="mb-2">Vendedor : </h4>
                        <p className="mb-1">
                          <strong className="me-1">
                            #{data?.vendedor1?.CODIGO}
                          </strong>
                          {data?.vendedor1?.NREDUZ}
                        </p>
                        <p className="mb-1">
                          <strong className="me-1">Supervisor</strong>{" "}
                          {data?.vendedor1?.SUPERVISOR?.substring(
                            0,
                            data?.vendedor1?.SUPERVISOR?.indexOf(" ")
                          )}
                        </p>
                        <p className="mb-1">
                          {data?.vendedor1?.DDD_TEL} {data?.vendedor1?.TELEFONE}
                        </p>
                        <p>{data?.vendedor1?.EMAIL}</p>
                      </Col>
                      <Col md={4}>
                        <h4 className="mb-2">Fornecedor : </h4>
                        <p className="mb-1">
                          <strong className="me-1">Codigo Fornecedor :</strong>
                          {data?.fornecedor?.CODIGO_FORN}
                        </p>
                        <p className="mb-1">
                          <strong className="me-1">Razao Social</strong>
                          {data?.fornecedor?.NOME}
                        </p>
                        <p className="mb-1">
                          <strong className="me-1">CNPJ : </strong>
                          {data?.fornecedor?.CGC}
                        </p>
                        <p className="mb-1">
                          <strong className="me-1">
                            Inscricao Estadual / Municipal :{" "}
                          </strong>
                          {data?.fornecedor?.INSCR_EST}
                        </p>
                        <p className="mb-1">
                          <strong className="me-1">Endereço : </strong>
                          {data?.fornecedor?.ENDERECO} ,{" "}
                          {data?.fornecedor?.NR_ENDERECO} -{" "}
                          {data?.fornecedor?.BAIRRO} -{" "}
                          {data?.fornecedor?.MUNICIPIO} /
                          {data?.fornecedor?.ESTADO}
                        </p>
                        <p className="mb-1">
                          <strong className="me-1">Banco : </strong>
                          {data?.fornecedor?.BANCO}
                        </p>
                        <p className="mb-1">
                          <strong className="me-1">Agencia : </strong>
                          {data?.fornecedor?.AGENCIA}
                        </p>
                        <p className="mb-1">
                          <strong className="me-1">Conta Corrente : </strong>
                          {data?.fornecedor?.NUMERO_CONTA} -
                          {data?.fornecedor?.DIGITOCONTA}
                        </p>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={12}>
                        <TableClient
                          dataZcm={dataZcm}
                          setDataZcm={setDataZcm}
                          number={number}
                        />
                      </Col>
                    </Row>
                    <Row className="align-items-center justify-content-between">
                      <Col
                        md={6}
                        xl={4}
                        className="mt-4 p-4"
                        style={{ background: "#eee" }}
                      >
                        <h6>MPS DISTRIBUIDORA MERCANTIL LTDA</h6>
                        <p className="mb-1">CNPJ 09.570.732/004-34</p>
                        <p className="mb-1">Inscricao Estadual 28.377.379-0</p>
                        <p className="mb-1">Inscricao Municipal 170075005</p>
                        <p className="mb-1">Rua Carlota de Almeida Lemos, 46</p>
                        <p className="mb-1">Campo Grande , MS 79117-004</p>
                        <p className="mb-1">Jd. Paradiso</p>
                        <p className="mb-3">T: 55 673026555855</p>
                        <h6>
                          <strong className="me-2">Duvidas?</strong>
                          <a
                            href={`mailto:${data?.vendedor1?.EMAIL?.toLowerCase()}`}
                            style={{
                              color: "#0067b1",
                              cursor: "pointer",
                            }}
                          >
                            {data?.vendedor1?.EMAIL?.toLowerCase()}
                          </a>
                        </h6>
                      </Col>
                      <Col md={6} className="text-end mt-4">
                        <p>
                          Sub Total:{" "}
                          {total?.toLocaleString("pt-br", {
                            style: "currency",
                            currency: "BRL",
                          })}
                        </p>
                        <p>
                          Acrescimos / Decrescimos : R${" "}
                          {acrescimos.toLocaleString("pt-br", {
                            minimumFractionDigits: 2,
                          })}
                        </p>
                        <p className="fw-bold">
                          Total Geral:{" "}
                          {totalSoma?.toLocaleString("pt-br", {
                            style: "currency",
                            currency: "BRL",
                          })}
                        </p>
                        <button
                          className="btn btn-primary me-2"
                          // eslint-disable-next-line no-undef
                          onClick={() => window.print()}
                        >
                          Imprimir<i className="fa fa-print ms-2"></i>
                        </button>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        )}
      </div>
    </>
  );
}

export default SettlementDetails;
