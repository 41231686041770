import React from "react";
import { Button } from "reactstrap";


function MyExportCSV(props) {
  const handleClick = () => {
    props.onExport();
  };
  return (
    <div className="mb-3 ms-3">
      <Button className="botao-excel" onClick={handleClick}>
        <i className="far fa-file-excel"></i> Exportar Excel
      </Button>
    </div>
  );
}

export default MyExportCSV;
