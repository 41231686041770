import React, { useState } from "react";
import { Table } from "reactstrap";
import { Link } from "react-router-dom";
import cNames from "classnames";

const SortCol = props => {
  const { onSortChange, sortDir, children, columnKey, classNames } = props;

  const _onSortChange = () => {
    if (onSortChange) {
      onSortChange(columnKey, "desc");
    }
  };

  return (
    <Link
      to="#"
      onClick={e => {
        e.preventDefault();
        _onSortChange();
      }}
      className={cNames(
        "fixed-table-sort-col",
        { active: sortDir },
        classNames
      )}
    >
      <span>{children}</span>

      {sortDir ? (
        <span className="ml-1">
          {sortDir === "desc" ? (
            <i className={cNames("mdi", "mdi-arrow-down", classNames)}></i>
          ) : (
            <i className={cNames("mdi", "mdi-arrow-up", classNames)}></i>
          )}
        </span>
      ) : null}
    </Link>
  );
};

const SelectCol = ({
  row,
  onChange,
  selectedRecords,
  idField,
  disableSelection,
}) => {
  const disabled =
    disableSelection && disableSelection.includes(row[idField]) ? true : false;
  return (
    <>
      <div className="h-100 w-100 d-flex align-items-center justify-content-center">
        <div className="checkbox checkbox-single">
          {!disabled ? (
            <input
              label=""
              type="checkbox"
              className="pl-0"
              checked={selectedRecords.includes(row)}
              onChange={e => {
                onChange(row, e.target.checked);
              }}
              id={"check" + row[idField]}
            />
          ) : null}
        </div>
      </div>
    </>
  );
};

const SelectAllCol = ({ totalRecords, onChange, selectedRecords }) => {
  return (
    <div className="h-100 w-100 d-flex align-items-center justify-content-center">
      <div className="checkbox checkbox-single">
        <input
          label=""
          type="checkbox"
          className="pl-0"
          id={`checkAll-Records`}
          checked={selectedRecords.length === totalRecords}
          onChange={e => onChange(e.target.checked)}
        />
      </div>
    </div>
  );
};

const SimpleTable = ({
  columns,
  data,
  rowClassNamesFn,
  sortDir,
  onSortChange,
  classNames,
  selectable,
  onRecordSelect,
  idField,
  disableSelection,
  footer,
}) => {
  const allowSelection = selectable || false;

  const [selectedRecords, setselectedRecords] = useState([]);

  // on selecting record
  const onRecordSelected = (record, selected) => {
    let modifiedRecords = [...(selectedRecords || [])];
    if (selected) {
      modifiedRecords.push(record);
    } else {
      modifiedRecords = modifiedRecords.filter(
        item => JSON.stringify(item) !== JSON.stringify(record)
      );
    }

    setselectedRecords(modifiedRecords);

    if (onRecordSelect) {
      onRecordSelect(modifiedRecords);
    }
  };

  const onChangeAllSelect = selected => {
    let modifiedRecords = [];
    if (selected) {
      modifiedRecords = [
        ...data.filter(d => !disableSelection?.includes(d[idField])),
      ];
    }

    setselectedRecords(modifiedRecords);
    if (onRecordSelect) {
      onRecordSelect(modifiedRecords);
    }
  };

  let cols = [];

  if (allowSelection) {
    cols.push({
      Header: (
        <SelectAllCol
          totalRecords={data.length}
          onChange={onChangeAllSelect}
          selectedRecords={selectedRecords}
        />
      ),
      accessor: "id",
      Cell: function selectColRenderer(props) {
        return (
          <SelectCol
            row={props["original"]}
            idField={idField}
            onChange={onRecordSelected}
            disableSelection={disableSelection}
            selectedRecords={selectedRecords}
          />
        );
      },
      width: 40,
    });
  }
  cols = [...cols, ...columns];

  return (
    <Table responsive className={cNames(classNames)}>
      <thead>
        <tr>
          {cols.map((c, idx) => {
            const isSortEnabled = c["sortEnabled"];

            return (
              <th key={idx} className={cNames("", c["headerClassNames"])}>
                {isSortEnabled ? (
                  <SortCol
                    onSortChange={onSortChange}
                    sortDir={sortDir}
                    columnKey={c["accessor"]}
                  >
                    {c["Header"]}
                  </SortCol>
                ) : (
                  <>{c["Header"]}</>
                )}
              </th>
            );
          })}
        </tr>
      </thead>
      <tbody>
        {data.map((row, rowIndex) => {
          const rowClases = rowClassNamesFn
            ? rowClassNamesFn(row, rowIndex)
            : "";

          return (
            <tr key={`row-${rowIndex}`} className={rowClases}>
              {cols.map((c, idx) => {
                const Col = c["Cell"];
                const val = data[rowIndex][c["accessor"]];
                const colWidth = c["width"] || c["minWidth"];
                const colSpan = c["colSpanFn"] ? c["colSpanFn"](row) : 1;

                return (
                  <td
                    key={idx}
                    style={{
                      width: colWidth,
                      minWidth: colWidth,
                      maxWidth: colWidth,
                    }}
                    className={cNames(c["classNames"])}
                    colSpan={colSpan}
                  >
                    <Col value={val} original={row} rowIndex={rowIndex} />
                  </td>
                );
              })}
            </tr>
          );
        })}
        {footer ? footer : null}
      </tbody>
    </Table>
  );
};

export default SimpleTable;
