import React, { useState } from "react";
import { Card, CardBody, Form, Row } from "reactstrap";
import { useDispatch } from "react-redux";
import { listSellers } from "../../store/actions";
import { useSolar } from "../../hooks";

export default function SellersFilters({ filter, setFilter }) {
  const dispatch = useDispatch();
  const solar = useSolar();

  return (
    <Card>
      <CardBody>
        <div className="p-1">
          <div className="d-flex align-items-center">
            <div className="me-2">
              <i
                className="bx bxs-filter-alt  h4"
                style={{ color: solar ? "#FF601A" : "#2D567B" }}
              ></i>
            </div>
            <div className="flex-1 overflow-hidden">
              <h5
                className="font-size-20"
                style={{ color: solar ? "#FF601A" : "#2D567B" }}
              >
                FILTRAR
              </h5>
            </div>
          </div>
        </div>
        <div className="p-4 border-top">
          <Form>
            <div>
              <Row className="mb-3">
                <label className="col-md-4 col-form-label text-end">
                  CNPJ:
                </label>
                <div className="col-md-8">
                  <input
                    className="form-control"
                    type="text"
                    placeholder="CNPJ"
                    name="cnpj"
                    value={filter.cnpj}
                    onChange={e =>
                      setFilter({ ...filter, [e.target.name]: e.target.value })
                    }
                  />
                </div>
              </Row>
              <Row className="mb-3">
                <label className="col-md-4 col-form-label text-end">
                  Código:
                </label>
                <div className="col-md-8">
                  <input
                    className="form-control"
                    type="text"
                    placeholder="Código de Vendedor"
                    name="codigo"
                    value={filter.codigo}
                    onChange={e =>
                      setFilter({ ...filter, [e.target.name]: e.target.value })
                    }
                  />
                </div>
              </Row>
              <Row className="mb-3">
                <label className="col-md-4 col-form-label text-end">
                  Nome Vendedor:
                </label>
                <div className="col-md-8">
                  <input
                    className="form-control"
                    type="text"
                    placeholder="Nome Vendedor"
                    name="nome"
                    value={filter.nome}
                    onChange={e =>
                      setFilter({ ...filter, [e.target.name]: e.target.value })
                    }
                  />
                </div>
              </Row>{" "}
              <Row className="mb-3">
                <label className="col-md-4 col-form-label text-end">
                  Código Superior:
                </label>
                <div className="col-md-8">
                  <input
                    className="form-control"
                    type="number"
                    placeholder="Código do Superior"
                    name="codigo_sup"
                    value={filter.codigo_sup}
                    onChange={e =>
                      setFilter({ ...filter, [e.target.name]: e.target.value })
                    }
                  />
                </div>
              </Row>
            </div>
          </Form>
        </div>
        <div className="pt-3 border-top">
          <button
            className="btn "
            style={{ background: solar ? "#FF601A" : "#2D567B", color: "#fff" }}
            onClick={() => dispatch(listSellers(filter))}
          >
            <i className="bx bxs-filter-alt me-2" style={{ color: "#fff" }}></i>
            Filtrar
          </button>
        </div>
      </CardBody>
    </Card>
  );
}
