import React from "react";
import { Card, CardBody } from "reactstrap";
import { Link } from "react-router-dom";

// components
import AvailableCouponsChart from "./AvailableCouponsChart";

const AvailableCoupons = () => {
  return (
    <div>
      <Card>
        <CardBody className="text-center">
          <div>
            <h5 className="mt-2">Cupons Disponíveis</h5>
            <div className="mb-3">
              <AvailableCouponsChart data={4} />
            </div>
            <Link to="#" className="btn btn-primary text-uppercase">
              VER MAIS
            </Link>
          </div>
        </CardBody>
      </Card>
    </div>
  );
};

export default AvailableCoupons;
