import React, { useEffect, useState } from "react";
import { Col, Form, Row, InputGroup, Label, Button } from "reactstrap";

//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";

// components
import CustomSelect from "../../../components/CustomSelect";

// utils
import { formateDate } from "../../../utils";

const FilterResults = ({
  onApplyFilters,
  manufacturer,
  statusOptions,
  query,
}) => {
  const [statusOpts, setStatusOpts] = useState([]);
  const [filters, setFilters] = useState({});

  const idSituacao = query.get("idSituacao");
  const statusOptsMock = [
    {
      value: 1,
      label: "Peça Solicitada",
    },
    {
      value: 5,
      label: "Em Analise",
    },
    {
      value: 6,
      label: "Peça Disponivel",
    },

    {
      value: 9,
      label: "Aguardando peça defeituosa",
    },
  ];

  /*
  manufacturer options
  */
  const [manufacturerOpts, setManufacturerOpts] = useState([]);
  useEffect(() => {
    const opts = (manufacturer || []).map(m => {
      return {
        value: m.id,
        label: m.name,
      };
    });
    setManufacturerOpts(opts);
  }, [manufacturer]);

  const getQueryUrl = () => {
    const filterSituacao = statusOptsMock.find(
      item => Number(item.value) == Number(idSituacao)
    );
    if (filterSituacao) {
      setFilters({
        ["s"]: { value: filterSituacao.value, label: filterSituacao.label },
      });
      onApply({ situacaoQueryUrl: filterSituacao.value });
    }
  };

  useEffect(() => {
    getQueryUrl();
  }, [idSituacao]);
  /*
  Status options
  */

  useEffect(() => {
    const opts = (statusOptions || []).map(m => {
      return {
        value: m.id,
        label: m.name,
      };
    });
    setStatusOpts(opts);
  }, [statusOptions]);

  /*
  filters handeling
  */

  const onChangeFilters = (field, value) => {
    const modifiedFilters = { ...filters };
    modifiedFilters[field] = value;
    setFilters(modifiedFilters);
  };

  /*
  on apply filters
  */
  const onApply = ({ situacaoQueryUrl }) => {
    const modifiedFilters = {};
    if (filters["code"]) {
      modifiedFilters["code"] = filters["code"];
    }
    if (filters.date_at && filters.date_at.length && filters.date_at[0]) {
      var date_at = filters.date_at[0];
      date_at = formateDate(date_at, "DD/MM/YY");
      modifiedFilters["date_at"] = date_at;
    }
    if (filters.date_from && filters.date_from.length && filters.date_from[0]) {
      var date_from = filters.date_from[0];
      date_from = formateDate(date_from, "DD/MM/YY");
      modifiedFilters["date_from"] = date_from;
    }
    if (filters["t11code"]) {
      modifiedFilters["t11code"] = filters["t11code"];
    }
    if (filters["client"]) {
      modifiedFilters["client"] = filters["client"];
    }
    if (filters.s && filters.s !== null && filters.s.value) {
      modifiedFilters.s = filters.s.value;
    }
    if (filters.q && filters.q !== null && filters.q.value) {
      modifiedFilters.q = filters.q.value;
    }

    if (situacaoQueryUrl) {
      modifiedFilters.s = situacaoQueryUrl;
    }
    console.log("modifiedFilters", modifiedFilters);
    onApplyFilters(modifiedFilters);
  };

  /*
  // clear filters
  // */
  // const onClearFilters = () => {
  //   setFilters({});
  // };

  return (
    <React.Fragment>
      <Col md={6}>
        <div className="p-4 border-top">
          <Form>
            <div>
              <Row className="mb-3">
                <Label
                  htmlFor="example-text-input"
                  className="col-md-5 col-form-label"
                >
                  Número da solicitação:
                </Label>
                <div className="col-md-6">
                  <input
                    type="text"
                    className="form-control"
                    id="code"
                    name="code"
                    placeholder="Número da solicitação:"
                    value={filters["code"] || ""}
                    onChange={e => {
                      onChangeFilters("code", e.target.value);
                    }}
                  />
                </div>
              </Row>
              <Row className="mb-3">
                <Label
                  htmlFor="example-text-input"
                  className="col-md-5 col-form-label"
                >
                  Data da solicitação:
                </Label>
                <Col md={3}>
                  <div className="docs-datepicker">
                    <InputGroup>
                      <Flatpickr
                        className="form-control docs-date d-block"
                        placeholder="dd/mm/aaaa"
                        options={{
                          altInput: true,
                          dateFormat: "d-m-y",
                        }}
                        id="date_from"
                        name="date_from"
                        value={filters["date_from"] || ""}
                        onChange={date => {
                          onChangeFilters("date_from", date);
                        }}
                      />
                    </InputGroup>
                    <div className="docs-datepicker-container" />
                  </div>
                </Col>
                <Col md={3}>
                  <div className="docs-datepicker">
                    <InputGroup>
                      <Flatpickr
                        className="form-control docs-date d-block"
                        placeholder="dd/mm/aaaa"
                        options={{
                          mode: "single",
                          altInput: true,
                          dateFormat: "d-m-y",
                        }}
                        id="date_at"
                        name="date_at"
                        value={filters["date_at"] || ""}
                        onChange={date => {
                          onChangeFilters("date_at", date);
                        }}
                      />
                    </InputGroup>
                    <div className="docs-datepicker-container" />
                  </div>
                </Col>
              </Row>
              <Row className="mb-3">
                <Label
                  htmlFor="example-number-input"
                  className="col-md-3 col-form-label"
                >
                  Solicitante:
                </Label>
                <div className="col-md-8">
                  <input
                    className="form-control"
                    type="number"
                    placeholder="Digite o codigo ou nome"
                    value={filters["t11code"] || ""}
                    id="t11code"
                    name="t11code"
                    onChange={e => {
                      onChangeFilters("t11code", e.target.value);
                    }}
                  />
                </div>
              </Row>
            </div>
          </Form>
        </div>
      </Col>
      <Col md={6}>
        <div className="p-4 border-top">
          <Row className="mb-3">
            <Label
              htmlFor="example-text-input"
              className="col-md-4 col-form-label"
            >
              Nome do cliente:
            </Label>
            <div className="col-md-8">
              <input
                className="form-control"
                type="text"
                placeholder="Digite o nome ou razão social"
                id="client"
                name="client"
                value={filters["client"] || ""}
                onChange={e => {
                  onChangeFilters("client", e.target.value);
                }}
              />
            </div>
          </Row>
          <Row className="mb-3">
            <Label
              htmlFor="example-text-input"
              className="col-md-4 col-form-label"
            >
              Situação:
            </Label>
            <div className="col-md-8">
              <CustomSelect
                id="s"
                name="s"
                options={statusOpts}
                value={filters["s"] || ""}
                onChange={value => {
                  onChangeFilters("s", value);
                }}
                isClearable={true}
              />
            </div>
          </Row>
          <Row className="mb-3">
            <Label
              htmlFor="example-text-input"
              className="col-md-4 col-form-label"
            >
              Fabricante:
            </Label>
            <div className="col-md-8">
              <CustomSelect
                id="q"
                name="q"
                options={manufacturerOpts}
                value={filters["q"] || ""}
                onChange={value => {
                  onChangeFilters("q", value);
                }}
                isClearable={true}
              />
            </div>
          </Row>
        </div>
      </Col>
      <div className="pt-3 border-top">
        <Button className="botao-filtrar" onClick={onApply}>
          <i className="bx bxs-filter-alt me-2"></i>
          Filtrar
        </Button>
      </div>
    </React.Fragment>
  );
};

export default FilterResults;
