export const emailValidation = enteredEmail => {
  const reg =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  if (enteredEmail == "") {
    return "Campo obrigatório";
  } else if (reg.test(String(enteredEmail).toLowerCase())) {
    return "";
  } else {
    return "Email inválido";
  }
};

export const validTel = enteredTel => {
  var newValue;
  newValue = enteredTel.replace(/^(\d{4})(\d{4})/, "$1-$2");
  return newValue;
};

export const validCel = enteredCel => {
  var newValue;
  newValue = enteredCel.replace(/^(\d{5})(\d{4})/, "$1-$2");
  return newValue;
};

export const validCep = enteredCep => {
  var newValue = enteredCep
    .replace(/\D/g, "")
    .replace(/(\d{5})(\d)/, "$1-$2")
    .replace(/(-\d{3})\d+?$/, "$1");

  return newValue;
};

export function validaCpfCnpj(cpf) {
  cpf = cpf.replace(/[^\d]+/g, "");

  if (cpf == "") {
    return "Campo obrigatório";
  } else if (cpf.length <= 11) {
    if (
      !cpf ||
      cpf.length != 11 ||
      cpf == "00000000000" ||
      cpf == "11111111111" ||
      cpf == "22222222222" ||
      cpf == "33333333333" ||
      cpf == "44444444444" ||
      cpf == "55555555555" ||
      cpf == "66666666666" ||
      cpf == "77777777777" ||
      cpf == "88888888888" ||
      cpf == "99999999999"
    )
      return "Digite um cpf válido";
    var soma = 0;
    var resto;
    for (var i = 1; i <= 9; i++)
      soma = soma + parseInt(cpf.substring(i - 1, i)) * (11 - i);
    resto = (soma * 10) % 11;
    if (resto == 10 || resto == 11) resto = 0;
    if (resto != parseInt(cpf.substring(9, 10))) return "Digite um cpf válido";
    soma = 0;
    for (var i = 1; i <= 10; i++)
      soma = soma + parseInt(cpf.substring(i - 1, i)) * (12 - i);
    resto = (soma * 10) % 11;
    if (resto == 10 || resto == 11) resto = 0;
    if (resto != parseInt(cpf.substring(10, 11))) return "Digite um cpf válido";
    return "";
  } else {
    if (
      !cpf ||
      cpf.length != 14 ||
      cpf == "00000000000000" ||
      cpf == "11111111111111" ||
      cpf == "22222222222222" ||
      cpf == "33333333333333" ||
      cpf == "44444444444444" ||
      cpf == "55555555555555" ||
      cpf == "66666666666666" ||
      cpf == "77777777777777" ||
      cpf == "88888888888888" ||
      cpf == "99999999999999"
    )
      return "Digite um cpf válido";
    var tamanho = cpf.length - 2;
    var numeros = cpf.substring(0, tamanho);
    var digitos = cpf.substring(tamanho);
    var soma = 0;
    var pos = tamanho - 7;
    for (var i = tamanho; i >= 1; i--) {
      soma += numeros.charAt(tamanho - i) * pos--;
      if (pos < 2) pos = 9;
    }
    var resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
    if (resultado != digitos.charAt(0)) return "Digite um cpf válido";
    tamanho = tamanho + 1;
    numeros = cpf.substring(0, tamanho);
    soma = 0;
    pos = tamanho - 7;
    for (var i = tamanho; i >= 1; i--) {
      soma += numeros.charAt(tamanho - i) * pos--;
      if (pos < 2) pos = 9;
    }
    resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
    if (resultado != digitos.charAt(1)) return "Digite um cpf válido";
    return "";
  }
}
