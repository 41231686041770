import { API_SUCCESS, API_FAIL, GET_MENU_ITEMS } from "./actionTypes";

const INIT_STATE = {
  menuItems: [],
};

const SidebarMenu = (state = INIT_STATE, action) => {
  switch (action.type) {
    case API_SUCCESS:
      switch (action.payload.actionType) {
        case GET_MENU_ITEMS:
          return {
            ...state,
            menuItems: action.payload.data,
            getMenusLoading: false,
            isMenuItemFetched: true,
          };

        default:
          return { ...state };
      }
    case API_FAIL:
      switch (action.payload.actionType) {
        case GET_MENU_ITEMS:
          return {
            ...state,
            menuItemsError: action.payload.error,
            getMenusLoading: false,
            isMenuItemFetched: true,
          };

        default:
          return { ...state };
      }

    case GET_MENU_ITEMS:
      return {
        ...state,
        getMenusLoading: true,
        isMenuItemFetched: false,
      };

    default:
      return state;
  }
};

export default SidebarMenu;
