import React, { useState, useCallback, useEffect } from "react";
import {
  Card,
  CardBody,
  Col,
  Form,
  Row,
  Alert,
  FormGroup,
  Label,
  Input,
  Button,
  FormFeedback,
} from "reactstrap";
import { useDropzone } from "react-dropzone";
import classnames from "classnames";

//validation
import { useFormik } from "formik";
import * as Yup from "yup";

import { useDispatch, useSelector } from "react-redux";

// actions
import {
  addNewLive,
  uploadLiveConfigFiles,
  deleteLiveConfigFile,
} from "../../../store/actions";

// components
import CustomSelect from "../../../components/CustomSelect";
import Loader from "../../../components/Loader";

const checkFileIsImage = file => {
  const validImageTypes = ["image/gif", "image/jpeg", "image/png"];
  const fileType = file["type"];
  let isImage = true;
  if (!validImageTypes.includes(fileType)) {
    isImage = false;
  }
  return isImage;
};
const AddEditNewLive = ({
  isEditMode,
  video,
  files,
  getTvConfigGalleryLoading,
}) => {
  const { addLoading } = useSelector(state => ({
    addLoading: state.Gallery.addLoading,
  }));

  const dispatch = useDispatch();
  const videoId = video && video.id;

  /*
  selected files (fetch, create & dropzone)
  */
  const [selectedFiles, setSelectedFiles] = useState([]);
  useEffect(() => {
    if (files && files.length) {
      const modifiedFiles = (files || []).map(f => {
        const isImage = checkFileIsImage(f);
        return {
          ...f,
          preview: f.path || "",
          isImage: isImage,
        };
      });
      setSelectedFiles(modifiedFiles);
    }
  }, [files]);

  const onDrop = useCallback(nFiles => {
    const newFiles = nFiles.map(file => {
      const isImage = checkFileIsImage(file);
      return {
        file: file,
        name: file.name,
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
        isImage: isImage,
        isNew: true,
      };
    });
    setSelectedFiles(prevSelectedFiles => {
      return [...prevSelectedFiles, ...newFiles];
    });
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
  });

  /**
   * Formats the size
   */
  const formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  };

  /*
  remove files
  */
  const onRemoveFile = index => {
    let modifiledFiles = [...selectedFiles];
    if (modifiledFiles[index] && !modifiledFiles[index].isNew) {
      dispatch(deleteLiveConfigFile(videoId, modifiledFiles[index].id));
    } else if (modifiledFiles[index] && modifiledFiles[index].isNew) {
      if (index > -1) {
        modifiledFiles.splice(index, 1);
      }
      setSelectedFiles(modifiledFiles);
    }
  };

  /*
  status
  */
  const statusOptions = [
    { value: "PUBLISHED", label: "AO VIVO" },
    { value: "DRAFT", label: "RASCUNHO" },
    { value: "RECORDED", label: "DEIXAR GRAVADO" },
  ];

  const onChangeStatus = value => {
    if (value && value.value) {
      validation.setFieldValue("status", value.value);
    }
  };

  /*
  update video
  */
  const [videoDetails, setVideoDetails] = useState();
  useEffect(() => {
    if (video) {
      setVideoDetails(video);
    }
  }, [video]);

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      title: (videoDetails && videoDetails.name) || "",
      url: (videoDetails && videoDetails.youtube_id) || "",
      status: (videoDetails && videoDetails.status) || "",
    },
    validationSchema: Yup.object({
      title: Yup.string().required("Please Enter title"),
      url: Yup.string().required("Please Enter url"),
      status: Yup.string().required("Please Enter status"),
    }),
    onSubmit: values => {
      var params = {
        "live[title]": values.title,
        "live[youtube_url]": values.url,
        "live[post_status]": values.status,
      };
      if (isEditMode) {
        params["live[id]"] = videoId;
      }
      dispatch(addNewLive(params));
      if (isEditMode) {
        var newFiles = (selectedFiles || []).filter(f => f.isNew);
        newFiles = (newFiles || []).map(f => f.file);
        dispatch(uploadLiveConfigFiles(videoId, newFiles));
      } else {
        validation.resetForm();
      }
    },
  });

  const status = validation.values.status
    ? (statusOptions || []).find(s => s.value === validation.values.status)
    : "";

  return (
    <Row>
      <Col md={12}>
        <Card>
          <CardBody className="position-relative">
            {(addLoading || getTvConfigGalleryLoading) && <Loader />}
            <Form
              noValidate
              onSubmit={e => {
                e.preventDefault();
                validation.handleSubmit();
                return false;
              }}
            >
              <div className="mb-3">
                <h5 className="font-size-17 m-0">
                  <i className="mdi mdi-pencil-box-multiple-outline me-3"></i>
                  Adicionar Nova Live
                </h5>
                <hr />
              </div>
              {isEditMode ? (
                <Alert color="info">VOCÊ ESTÁ NO MODO EDIÇÃO</Alert>
              ) : null}

              <Row>
                <Col md={12}>
                  <FormGroup className="mb-3">
                    <Label htmlFor="title">Título: </Label>
                    <Input
                      placeholder="Digite o Título"
                      value={validation.values.title || ""}
                      name="title"
                      id="title"
                      onBlur={validation.handleBlur}
                      onChange={validation.handleChange}
                      className={classnames("form-control", {
                        "is-invalid":
                          validation.touched.title && validation.errors.title,
                      })}
                    />
                    {validation.touched.title && validation.errors.title ? (
                      <FormFeedback type="invalid">
                        {validation.errors.title}
                      </FormFeedback>
                    ) : null}
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md={6} xs={12}>
                  <FormGroup className="mb-3">
                    <Label htmlFor="url">YouTube URL: </Label>
                    <Input
                      placeholder="Digite o Título"
                      value={validation.values.url || ""}
                      name="url"
                      id="url"
                      onBlur={validation.handleBlur}
                      onChange={validation.handleChange}
                      className={classnames("form-control", {
                        "is-invalid":
                          validation.touched.url && validation.errors.url,
                      })}
                    />
                    {validation.touched.url && validation.errors.url ? (
                      <FormFeedback type="invalid">
                        {validation.errors.url}
                      </FormFeedback>
                    ) : null}
                  </FormGroup>
                </Col>
                <Col md={6} xs={12}>
                  <FormGroup className="mb-3">
                    <Label htmlFor="title">Status:</Label>
                    <CustomSelect
                      value={status}
                      options={statusOptions}
                      onChange={onChangeStatus}
                    />
                  </FormGroup>
                </Col>
              </Row>
              {isEditMode && (
                <Row>
                  <Col xs={12}>
                    <Label>Upload de arquivos: </Label>
                    <div className="mb-3">
                      <div className="dropzone">
                        <div
                          className="dz-message needsclick"
                          {...getRootProps()}
                        >
                          <input {...getInputProps()} />
                          <div className="mb-3">
                            <i className="display-4 text-muted uil uil-cloud-upload" />
                          </div>
                          <h4>Drop files here or click to upload.</h4>
                        </div>
                      </div>
                      <div
                        className="dropzone-previews mt-3"
                        id="file-previews"
                      >
                        {selectedFiles.map((f, key) => {
                          return (
                            <Card
                              className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                              key={key}
                            >
                              <div className="p-2">
                                <Row className="align-items-center">
                                  <Col className="col-auto">
                                    {f.isImage ? (
                                      <img
                                        height="80"
                                        className="avatar-sm rounded bg-light"
                                        alt={f.name}
                                        src={f.preview}
                                      />
                                    ) : (
                                      <div className="avatar-sm rounded bg-soft-primary d-flex align-items-center justify-content-center">
                                        <i className="mdi mdi-file-document font-size-24" />
                                      </div>
                                    )}
                                  </Col>
                                  <Col>
                                    <a
                                      href={f.path}
                                      className="text-muted font-weight-bold"
                                    >
                                      {f.name}
                                    </a>
                                    <p className="mb-0">
                                      <strong>{f.formattedSize}</strong>
                                    </p>
                                  </Col>
                                  <Col className="float-end" xs={"auto"}>
                                    <Button
                                      color="text"
                                      onClick={() => onRemoveFile(key)}
                                    >
                                      <i className="mdi mdi-close" />
                                    </Button>
                                  </Col>
                                </Row>
                              </div>
                            </Card>
                          );
                        })}
                      </div>
                    </div>
                  </Col>
                </Row>
              )}

              <Row>
                <Col xs={12} className="text-end">
                  <Button className="btn text-white" style={{backgroundColor: "#2D567B"}} type="submit">
                    Salvar
                  </Button>
                </Col>
              </Row>
            </Form>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

export default AddEditNewLive;
