import React from "react";
import { Row, Col, InputGroupText, InputGroup, Input } from "reactstrap";

const Modulo = ({ filters, setFilters }) => {
  return (
    <Row className="engloba-vendedor-cliente">
      <Col className="engloba-vendedor">
        <Row className="linha-titulo-vendedor">
          <h4 className="titulos">Nome:</h4>
        </Row>
        <Row className="engloba-vendedor-codigo">
          <Col className="busca-vendedor" md={12}>
            <InputGroup>
              <InputGroupText className="fundo-icone">
                <i className="ph-user"></i>
              </InputGroupText>
              <Input
                id="nome-cliente"
                className="titulo-input"
                placeholder="Nome do produto"
                onChange={e =>
                  setFilters(state => {
                    return {
                      ...state,
                      [e.target.name]: e.target.value,
                    };
                  })
                }
                value={filters.nome}
                name="nome"
              />
            </InputGroup>
          </Col>
        </Row>
      </Col>
      <Col className="engloba-nome-cliente">
        <Row className="linha-titulo-cliente">
          <h4 className="titulos">Fabricante:</h4>
        </Row>
        <Col className="nome-cliente">
          <InputGroup>
            <InputGroupText className="fundo-icone">
              <i className="ph-factory"></i>
            </InputGroupText>
            <Input
              id="nome-cliente"
              className="titulo-input"
              placeholder="Fabricante"
              onChange={e =>
                setFilters(state => {
                  return {
                    ...state,
                    [e.target.name]: e.target.value,
                  };
                })
              }
              value={filters.fabricante}
              name="fabricante"
            />
          </InputGroup>
        </Col>
      </Col>
    </Row>
  );
};

export default Modulo;
