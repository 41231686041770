import React, { useEffect } from "react";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import { useParams } from "react-router-dom";

// redux
import { useSelector, useDispatch } from "react-redux";

// components
import Breadcrumb from "../../../components/Breadcrumb";
import Loader from "../../../components/Loader";
import VideoDetails from "./VideoDetails";
import Event from "../Event";

// actions
import { getTVvideoDetails } from "../../../store/actions";

const Index = () => {
  const dispatch = useDispatch();
  const { videoId } = useParams();
  const { videoDetails, getTvvideoDetailsLoading, commentPostLoading } =
    useSelector(state => ({
      videoDetails: state.Gallery.videoDetails,
      getTvvideoDetailsLoading: state.Gallery.getTvvideoDetailsLoading,
      commentPostLoading: state.Gallery.commentPostLoading,
    }));

  /*
  get details
  */
  useEffect(() => {
    if (videoId) {
      dispatch(getTVvideoDetails(videoId));
    }
  }, [dispatch, videoId]);

  const recorded = videoDetails["recorded"] || [];
  const comments = videoDetails["comments"] || [];
  const video = videoDetails["onDemand"] || {};
  const files = videoDetails["files"] || [];

  return (
    <div className="page-content">
      <Container>
        {/* Render Breadcrumb */}
        <Breadcrumb title="Página Inicial" breadcrumbItem="TV A.Dias" />
        <div>
          <Row>
            <Col md={12}>
              <VideoDetails
                video={video}
                getTvvideoDetailsLoading={getTvvideoDetailsLoading}
                comments={comments}
                files={files}
                videoId={videoId}
              />
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <Card>
                <CardBody className="position-relative">
                  <h5 className="mb-4">Últimos Eventos</h5>
                  {getTvvideoDetailsLoading || commentPostLoading ? (
                    <Loader />
                  ) : null}
                  <Row>
                    {(recorded || []).map((record, key) => (
                      <Col lg={3} md={6} xs={12} key={key}>
                        <Event record={record} />
                      </Col>
                    ))}
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  );
};

export default Index;
