import React from "react";
import { useSolar } from "../../hooks";

const Button = ({ children, background, button = true, href, ...rest }) => {
  const isSolar = useSolar();

  return (
    <>
      {button ? (
        <button
          className="btn"
          style={{
            background: background
              ? background
              : isSolar
              ? "#ff6501"
              : "#0072bc",
            color: "#fff",
          }}
          {...rest}
        >
          {children}
        </button>
      ) : (
        <a
          className="btn"
          style={{
            background: background
              ? background
              : isSolar
              ? "#ff6501"
              : "#0072bc",
            color: "#fff",
          }}
          href={href}
          {...rest}
        >
          {children}
        </a>
      )}
    </>
  );
};

export default Button;
