import React from "react";
import { Card, CardBody } from "reactstrap";
import Moment from 'react-moment';
import 'moment/locale/pt-br';
import { Link } from "react-router-dom";

const Timeline = ({ data }) => {
  return (
    <Card>
      <CardBody>
        <h5 className="titulo-timeline">Timeline</h5>
        <div>
          <ul className="list-unstyled">
            {/* Render Horizontal Timeline Events */}
            {data.map((status, key) => key < 5 && (
              <li
                key={key}
                className={key == 0 ? "d-flex justify-content-between active mb-3" : "d-flex justify-content-between mb-3"}
              >
                <p style={{ fontSize: 20, marginRight: 20, color: key == 0 && "#FF601A" }}><i className={status.type.icon_class}></i></p>
                <div style={{ flex: 1 }}>
                  <h5 className="font-size-15 fw-bold d-flex justify-content-between">
                    <span style={{ width: "80%", display: "block" }}>{status.message}</span>
                    <span className="text-muted fw-normal font-size-12 ms-2 float-end d-block">
                      <Moment fromNow>{status.created_at}</Moment>
                    </span>
                  </h5>
                  <Link to={status.link} className="fw-bold text-muted1 ms-3 ms-xs-0 font-size-13">
                    Ver detalhes{status.viewMoreText}
                    <i className="mdi mdi-arrow-right ms-1" />
                  </Link>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </CardBody>
    </Card>
  );
};

export default Timeline;
