import { call, put, takeEvery, all, fork } from "redux-saga/effects";
// Crypto Redux States
import { SettlementsActionTypes } from "./actionTypes";
import { settlementsApiSuccess, settlementsApiFail } from "./action";

//Include Both Helper File with needed methods
import { getSettlementsList as getSettlementsListApi } from "../../api/index";

function* getSettlements(filters) {
  try {
    const response = yield call(getSettlementsListApi, filters);
    let list = response;
    yield put(
      settlementsApiSuccess(SettlementsActionTypes.GET_SETTLEMENTS_LIST, list)
    );
  } catch (error) {
    yield put(
      settlementsApiFail(SettlementsActionTypes.GET_SETTLEMENTS_LIST, error)
    );
  }
}
export function* watchGetSettlements() {
  yield takeEvery(SettlementsActionTypes.GET_SETTLEMENTS_LIST, getSettlements);
}

function* settlementsSaga() {
  yield all([fork(watchGetSettlements)]);
}

export default settlementsSaga;
