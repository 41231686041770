import React from "react";

const TicketIcon = ({size = "22", style = {}, color = {}}) => (
    <svg 
        width={size} 
        height={size}
        viewBox="0 0 24 20" 
        style={style}
        fill="none" 
        xmlns="http://www.w3.org/2000/svg"
    >
        <path 
            d="M14.3997 12.86V6.95358M5.01196 1.45996H12.0012C12.0409 2.87503 13.2005 4.00996 14.6252 4.00996C16.0499 4.00996 17.2094 2.87503 17.2491 1.45996H18.9884C21.0936 1.45996 22.8002 3.16655 22.8002 5.27173V14.7287C22.8002 16.8338 21.0936 18.5404 18.9884 18.5404H17.2491C17.2094 17.1254 16.0499 15.9904 14.6252 15.9904C13.2005 15.9904 12.0409 17.1254 12.0012 18.5404H5.01197C2.90679 18.5404 1.20021 16.8338 1.20021 14.7287L1.2002 5.27173C1.2002 3.16655 2.90678 1.45996 5.01196 1.45996Z" 
            stroke={color} 
            strokeWidth="2" 
            strokeLinecap="round" 
            strokeLinejoin="round"
        />
    </svg>
);

export default TicketIcon;
