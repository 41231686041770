import { LeadsActionTypes } from "./actionTypes";

const INIT_STATE = {
  leads: [],
  leadDetails: [],
  getLeadsLoading: false,
  getLeadDetailLoading: false,
  updateLeadDetailLoading: false,
};

const Leads = (state = INIT_STATE, action) => {
  switch (action.type) {
    case LeadsActionTypes.API_RESPONSE_SUCCESS:
      switch (action.payload.actionType) {
        case LeadsActionTypes.GET_LEADS_LIST:
          return {
            ...state,
            leads: action.payload.data.data,
            getLeadsLoading: false,
            isLeadsFetched: true,
          };
        case LeadsActionTypes.GET_LEAD_DETAILS:
          return {
            ...state,
            leadDetails: action.payload.data,
            getLeadDetailLoading: false,
            isLeadDetailsFetched: true,
          };
        case LeadsActionTypes.UPDATE_LEAD_DETAILS:
          return {
            ...state,
            updateLeadDetailLoading: false,
            isLeadDetailupdated: true,
          };

        default:
          return { ...state };
      }
    case LeadsActionTypes.API_RESPONSE_ERROR:
      switch (action.payload.actionType) {
        case LeadsActionTypes.GET_LEADS_LIST:
          return {
            ...state,
            leads: [],
            getLeadsLoading: false,
            isLeadsFetched: false,
          };
        case LeadsActionTypes.GET_LEAD_DETAILS:
          return {
            ...state,
            leadDetails: [],
            getLeadDetailLoading: false,
            isLeadsDetailFetched: false,
          };
        case LeadsActionTypes.UPDATE_LEAD_DETAILS:
          return {
            ...state,
            updateLeadDetailLoading: false,
            isLeadDetailupdated: false,
          };
        default:
          return { ...state };
      }

    case LeadsActionTypes.GET_LEADS_LIST:
      return {
        ...state,
        getLeadsLoading: true,
        isleadsFetched: false,
      };
    case LeadsActionTypes.GET_LEAD_DETAILS:
      return {
        ...state,
        getLeadDetailLoading: true,
        isLeadDetailFetched: false,
      };
    case LeadsActionTypes.UPDATE_LEAD_DETAILS:
      return {
        ...state,
        updateLeadDetailLoading: true,
        isLeadDetailupdated: false,
      };
    default:
      return state;
  }
};

export default Leads;
