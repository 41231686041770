import {
  CHOOSE_COMPANY,
  API_SUCCESS,
  API_FAIL,
  IS_LOADING,
} from "./actionTypes";

export const companyapiSuccess = (actionType, data) => ({
  type: API_SUCCESS,
  payload: { actionType, data },
});

export const companyapiFail = (actionType, error) => ({
  type: API_FAIL,
  payload: { actionType, error },
});

// export const isLoading = () => ({
//   type: IS_LOADING,
// });

export const getChooseCompany = data => ({
  type: CHOOSE_COMPANY,
  payload: data,
});
