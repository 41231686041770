import React from "react";
export default function IconCurrencyDollar(props) {
  const colorFill = props?.colorFill?.replaceAll("#", "") || "none";
  const colorStroke = props?.colorStroke || "#13BD72";

  return (
    <svg
      width="23"
      height="23"
      viewBox="0 0 20 20"
      fill={colorFill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.0039 5.68982V6.89749"
        stroke={colorStroke}
        strokeWidth="1.20767"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.0039 12.9359V14.1435"
        stroke={colorStroke}
        strokeWidth="1.20767"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.0038 17.1627C14.0057 17.1627 17.2499 13.9186 17.2499 9.91669C17.2499 5.91481 14.0057 2.67065 10.0038 2.67065C6.00197 2.67065 2.75781 5.91481 2.75781 9.91669C2.75781 13.9186 6.00197 17.1627 10.0038 17.1627Z"
        stroke={colorStroke}
        strokeWidth="1.20767"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.19368 12.9359H10.9109C11.3113 12.9359 11.6953 12.7768 11.9784 12.4937C12.2615 12.2106 12.4205 11.8267 12.4205 11.4263C12.4205 11.0259 12.2615 10.642 11.9784 10.3588C11.6953 10.0757 11.3113 9.9167 10.9109 9.9167H9.09943C8.69907 9.9167 8.3151 9.75766 8.03199 9.47455C7.74889 9.19145 7.58984 8.80748 7.58984 8.40711C7.58984 8.00674 7.74889 7.62277 8.03199 7.33967C8.3151 7.05657 8.69907 6.89752 9.09943 6.89752H11.8167"
        stroke={colorStroke}
        strokeWidth="1.20767"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
