import {
  PlacesAutocomplete,
  geocodeByAddress,
  MapPin,
  getLatLng,
  Spinner,
  React,
} from "./helpers/importer.helper";

export default function PlacesAutoComplete(props) {
  const handleChange = address => {
    props.setAddress(address);
  };

  const handleSelect = address => {
    props.setAddress(address);

    geocodeByAddress(address)
      .then(results => getLatLng(results[0]))
      .then(latLng => {
        // update center state
        props.setMapCenter({ lat: latLng.lat, lng: latLng.lng });
        props.setAttPlacesAutoComplete(false);

        props.setAttPlacesAutoComplete(true);
      })
      .catch(error => console.error("Error", error));
  };

  return (
    <div className="auto-complete-input">
      <PlacesAutocomplete
        value={props.address}
        onChange={handleChange}
        onSelect={handleSelect}
        searchOptions={{
          types: ["address"],
          componentRestrictions: {
            country: ["br"],
          },
        }}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <>
            <input
              {...getInputProps({
                placeholder: "Insira seu endereço...",
                className: `location-search-input ${
                  suggestions.length > 0 && "teste"
                }`,
              })}
            />
            <div className={`autocomplete-dropdown-container solar-shadow`}>
              {loading && (
                <div className="loading">
                  <Spinner type="grow" size="sm" />
                </div>
              )}
              {suggestions.map((suggestion, k) => {
                const className = suggestion.active
                  ? "suggestion-item--active"
                  : "suggestion-item";
                // inline style for demonstration purpose
                const style = suggestion.active
                  ? {
                      backgroundColor: "#fafafa",
                      cursor: "pointer",
                      padding: 13,
                    }
                  : {
                      backgroundColor: "#f5f5f5",
                      cursor: "pointer",
                      padding: 13,
                    };
                return (
                  <div
                    key={k}
                    {...getSuggestionItemProps(suggestion, {
                      className,
                      style,
                    })}
                  >
                    <MapPin />
                    <span style={{ marginLeft: 10 }}>
                      {suggestion.description}
                    </span>
                  </div>
                );
              })}
            </div>
          </>
        )}
      </PlacesAutocomplete>
    </div>
  );
}
