import React from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";
import { useSolar } from "../../hooks";

const Footer = () => {
  const isSolar = useSolar();
  const igLink = isSolar ? "https://www.instagram.com/adias.solar" : "https://www.instagram.com/clube.adias/";

  return (
    <React.Fragment>
      <footer className="footer">
        <Container fluid={true}>
          <Row>
            <Col sm={6}>
              2014 - 2022 © Clube ADias{" "}
              <Link to="/politicadeprivacidade"> Política de Privacidade</Link>{" "}
              <Link to="/termosdeuso">Termos de Uso</Link>
            </Col>
            <Col sm={6}>
              <div className="text-sm-end d-none d-sm-block">
                <a
                  href="https://www.linkedin.com/company/adias-ar-condicionado/"
                  target="blank"
                  rel="noreferrer"
                  style={{ marginRight: 10 }}
                >
                  <i className="fab fa-linkedin"></i>
                </a>
                <a
                  href="https://www.youtube.com/@adiasarcondicionado/"
                  target="blank"
                  rel="noreferrer"
                  style={{ marginRight: 10 }}
                >
                  <i className="fab fa-youtube"></i>
                </a>
                <a
                  href={igLink}
                  target="blank"
                  rel="noreferrer"
                  style={{ marginRight: 10 }}
                >
                  <i className="fab fa-instagram"></i>
                </a>
              </div>
            </Col>
          </Row>
        </Container>
      </footer>
    </React.Fragment>
  );
};

export default Footer;
