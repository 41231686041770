import { React, useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Button, Col, Form, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap"
import { createCustomField } from "../../../../store/actions";
import ButtonLoader from "../../../../components/ButtonLoader";
import { useColors } from "../../../../hooks/useColors";

const AddCustomFieldModal = ({ isOpen, toggle, manufacturerId }) => {

  const minLengthPermited = 0;
  const maxLengthPermited = 255;

  const [ fieldFormat, setFieldFormat ] = useState('text');
  const [ name, setName ] = useState(null);
  const [ description, setDescription ] = useState(null);
  const [ possibleValues, setPossibleValues ] = useState(null);
  const [ defaultValue, setDefaultValue ] = useState(null);
  const [ minLength, setMinLength ] = useState(minLengthPermited);
  const [ maxLength, setMaxLength ] = useState(maxLengthPermited);

  const minLengthInputRef = useRef(null);
  const maxLengthInputRef = useRef(null);

  const { colorDefault } = useColors(); 

  const { errorCreateCustomField, loading, customFieldCreated } = useSelector(state => ({
    errorCreateCustomField: state.CustomField.create.error,
    loading: state.CustomField.create.loading,
    customFieldCreated: state.CustomField.create.customFieldCreated
  }));

  const optionsFieldFormat = [
    { value: 'text', label: 'Texto' },
    { value: 'date', label: 'Data' },
    { value: 'integer', label: 'Inteiro' },
    { value: 'list', label: 'Lista' },
  ];

  const handleFieldFormatChange = (e) => {
    const format = e.target.value;

    if (format != 'text' && format != 'integer') {
      setMinLength(minLengthPermited);
      setMaxLength(maxLengthPermited);
    }

    if (format != 'list') {
      setPossibleValues(null);
    }

    setFieldFormat(format);
  }

  const handleMinSizeInputChange = (e) => {
    const valueMin = Math.min(e.target.value, maxLengthPermited);

    setMinLength(valueMin);
    if (validateMaxGreaterThanMin(valueMin, maxLength)) {
      setMaxLength(valueMin)
    }
  }

  const handleMaxSizeInputChange = (e) => {
    const valueMax = Math.min(e.target.value, maxLengthPermited)

    setMaxLength(valueMax);
    if (validateMaxGreaterThanMin(minLength, valueMax)) {
      setMinLength(valueMax)
    }
  }

  const validateMaxGreaterThanMin = (valueMin, valueMax) => {
    return valueMax <= valueMin;
  }

  const dispatch = useDispatch();

  const handleClickCreateCustomField = () => {
    if (!checkSelectedFieldFormat()) {
      return toast.warning('Selecione um tipo de campo');
    }

    if (!checkCompletedName()) {
      return toast.warning('Preencha o nome do campo personalizado');
    }

    const data = {
      customField: {
        field_format: fieldFormat,
        name: name,
        description: description,
        possible_values: possibleValues,
        min_length: minLength,
        max_length: maxLength,
        default_value: defaultValue,
        type: 'Manufacturer',
      },
      manufacturer_id: manufacturerId,
    }
    dispatch(createCustomField({ data }));
  }

  const checkSelectedFieldFormat = () => {
    return fieldFormat;
  }

  const checkCompletedName = () => {
    return name;
  }

  useEffect(() => {
    validateCustomFieldCreated();
  }, [customFieldCreated])

  const validateCustomFieldCreated = () => {
    if (customFieldCreated) {
      resetValues();
      toast.success('Campo personalizado criado com sucesso!');
      toggle();
    }
  }

  const resetValues = () => {
    setFieldFormat('text');
    setName(null);
    setDescription(null);
    setPossibleValues(null);
    setDefaultValue(null);
    setMinLength(minLengthPermited);
    setMaxLength(maxLengthPermited);
  }

  useEffect(() => {
    validateCustomFieldCreateError();
  }, [errorCreateCustomField])

  const validateCustomFieldCreateError = () => {
    if (errorCreateCustomField) {
      toast.error('Erro ao criar campo personalizado')
    }
  }

  return (
    <Modal size="lg" isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>Criar campo personalizado</ModalHeader>
      <ModalBody>
        <Row className="d-flex justify-content-start">
          <Col>
            <Form>
              <FormGroup className="mt-2">
                <Label>
                  Selecione um formato: 
                </Label>
                <Input
                  type="select"
                  name="field_format"
                  value={ fieldFormat }
                  onChange={handleFieldFormatChange}>
                    { optionsFieldFormat.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                </Input>
              </FormGroup>

              <FormGroup className="mt-2">
                <Label>
                  Nome: 
                </Label>
                <Input
                  type="text"
                  placeholder="Informe um nome"
                  value={ name }
                  onChange={ (e) => setName(e.target.value) } 
                />
              </FormGroup>

              <FormGroup className="mt-2">
                <Label>
                    Descrição: 
                </Label>
                <Input
                  md={10}
                  type="textarea"
                  placeholder="Informe uma descrição"
                  value={ description }
                  onChange={ (e) => setDescription(e.target.value) }>
                </Input>
              </FormGroup>

              { (fieldFormat === 'text' || fieldFormat === 'integer') && (
                <>
                  <FormGroup className="mt-2">
                    <Label>
                        Tamanho do Campo (min/max):
                    </Label>
                    <Col>
                        <Input
                          type="number"
                          value={minLength}
                          id="min-length"
                          placeholder="Informe o tamanho mínimo"
                          onChange={handleMinSizeInputChange}
                          min={0}
                          max={255}
                          innerRef={ minLengthInputRef }
                          onClick={ (e) => minLengthInputRef.current.select() }
                        />
                    </Col>
                    <Col className="mt-1">
                        <Input
                          type="number"
                          value={maxLength}
                          id="max-length"
                          placeholder="Informe o tamanho máximo"
                          onChange={handleMaxSizeInputChange}
                          min={0}
                          max={255}
                          innerRef={ maxLengthInputRef }
                          onClick={ (e) => maxLengthInputRef.current.select() }
                        />
                    </Col>
                  </FormGroup>
                </>
              )}

              { (fieldFormat === 'list') && (
                <FormGroup className="mt-2">
                  <Label>
                      Valores possíveis(separados por ";"): 
                  </Label>
                  <Input
                    type="textarea"
                    placeholder="Informe os possíveis valores separados por ';'"
                    value={ possibleValues }
                    onChange={ (e) => setPossibleValues(e.target.value) }>
                  </Input>
                </FormGroup>
              )}

              <FormGroup className="mt-2">
                <Label>
                  Padrão: 
                </Label>
                <Input
                  type="text"
                  placeholder="Informe um valor padrão"
                  value={ defaultValue }
                  onChange={ (e) => setDefaultValue(e.target.value) } />
              </FormGroup>
            </Form>
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <ButtonLoader 
          color="light"
          colorBg={ colorDefault } 
          onClick={ handleClickCreateCustomField }
          disabled={ loading }
          loading={ loading }  
          type="submit" 
          text="Criar" />
        <Button 
          color="danger"
          disabled={ loading }
          onClick={ () => toggle() }
        >
          Cancelar
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default AddCustomFieldModal;