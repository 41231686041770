import React from "react";
import { Modal, ModalHeader, ModalBody, Button } from "reactstrap";

// components
import ButtonLoader from "./ButtonLoader";
import { XCircle } from "phosphor-react";
const ConfirmationModal = ({
  loading,
  message,
  messageTitle = "Tem certeza?",
  isOpen,
  onConfirm,
  onClose,
  textButtonConfirm = "Excluir",
  colorButtonConfirm = "danger",
  textButtonClose = "Cancelar",
  icon,
  colorBg,
}) => {
  const deleteMessage = message
    ? message
    : "tem certeza que deseja excluir este (s) item (ns)?";
  return (
    <Modal isOpen={isOpen} toggle={onClose} centered={true}>
      <ModalHeader toggle={onClose} className="border-0"></ModalHeader>
      <ModalBody>
        <div className="text-center">
          {icon ? icon : <XCircle size={70} color="#d26060" />}
          <h3 className="mt-0 mb-3">{messageTitle}</h3>
          <p className="mb-4 text-muted">{deleteMessage}</p>
          <div className="mb-5">
            <ButtonLoader
              color={colorButtonConfirm}
              className="me-2"
              onClick={onConfirm}
              disabled={loading}
              text={textButtonConfirm}
              loading={loading}
              colorBg={colorBg}
            />
            <Button color="light" onClick={onClose}>
              {textButtonClose}
            </Button>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ConfirmationModal;
