import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { Card, CardBody } from "reactstrap";
import { Badge } from "react-bootstrap";
import Loader from "../../../components/Loader";
import { Link } from "react-router-dom";

const CadastroLista = ({ cadastros, getRegistrationLoading }) => {
  const columns = [
    {
      name: '# ID',
      cell: (row) => {
        const url = `/cadastro/revenda/${row.id}`;
        return (<Link className="text-black" to={url}>{row.id}</Link>)
      },
      maxWidth: '10px',
    },
    {
      name: 'CPF / CNPJ',
      cell: (row) => {
        const url = `/cadastro/revenda/${row.id}`;
        return (<Link className="text-black" to={url}>{row.cpfcnpj}</Link>)
      },
      minWidth: '25px',
    },
    {
      name: 'Nome',
      cell: (row) => {
        const url = `/cadastro/revenda/${row.id}`;
        return (<Link className="text-black" to={url}>{row.nome_revenda}</Link>)
      },
      minWidth: '60px',

    },
    {
      name: 'UF / Município',
      cell: (row) => {
        const url = `/cadastro/revenda/${row.id}`;
        return (<Link className="text-black" to={url}>{row.uf} / {row.municipio}</Link>)
      },

    },
    {
      name: 'Código',
      cell: (row) => {
        const url = `/cadastro/revenda/${row.id}`;
        return (<Link className="text-black" to={url}>{row.cod_revenda}</Link>)
      },
      maxWidth: '10px',
    },
    {
      name: 'Data',
      cell: (row) => {
        const databanco = row.created_at;
        const dataobjeto = new Date(databanco);
        const options = {
          year: 'numeric',
          month: 'numeric',
          day: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
          second: 'numeric',
        };

        const dataFormatada = dataobjeto.toLocaleString('pt-BR', options);
        const url = `/cadastro/revenda/${row.id}`;
        return (<Link className="text-black" to={url}>{dataFormatada}</Link>)
      },
      minWidth: '70px',

    },
    {
      name: 'Vendedor',
      cell: (row) => {
        const url = `/cadastro/revenda/${row.id}`;
        return (<Link className="text-black" to={url}>{row.supervisor}</Link>)
      },
      maxWidth: '20px',
    },
    {
      name: 'Status',
      maxWidth: '20px',
      cell: (row) => {
        let badgeColor = '';
        let iconClass = '';
        let labelText = '';

        switch (row.status) {
          case 'C':
            badgeColor = 'bg-info bg-opacity-25 text-info rounded-pill';
            iconClass = 'fa fa-refresh';
            labelText = 'Atualizado';
            break;
          case 'A':
            badgeColor = 'bg-indigo bg-opacity-25 text-indigo rounded-pill';
            iconClass = '';
            labelText = 'Novo';
            break;
          case 'B':
          case 'F':
            badgeColor = 'bg-warning bg-opacity-25 text-warning rounded-pill';
            iconClass = 'fa fa-exclamation-circle';
            labelText = 'Corrigir';
            break;
          case 'D':
            badgeColor = 'bg-success bg-opacity-25 text-success rounded-pill';
            iconClass = 'fa fa-check';
            labelText = 'Aprovado';
            break;
          case 'E':
            badgeColor = 'bg-danger bg-opacity-25 text-danger rounded-pill';
            iconClass = 'fa fa-ban';
            labelText = 'Reprovado';
            break;
          default:
            break;
        }

        return (
          <Badge className={badgeColor}>
            <i className={iconClass}></i> {labelText}
          </Badge>
        );
      },
    },
    {
      name: 'Origem',
      minWidth: '60px',
      cell: (row) => {
        let badgeClassname = '';
        let iconClass = '';
        let labelText = '';

        switch (row.origem) {
          case 'cadastro/novo':
            badgeClassname = 'badge bg-info bg-gradient bg-opacity-75 rounded-3 p-2 text-dark-blue';
            iconClass = 'fa fa-clone';
            labelText = 'Link Público';
            break;
          case '':
            badgeClassname = 'BTN badge bg-dark-blue bg-gradient p-2 rounded-3 border-0  text-white';
            iconClass = 'fa fa-edit';
            labelText = 'Formulário Interno';
            break;
          default:
            break;
        }
        const url = `/cadastro/revenda/${row.id}`;
        return (
          <Link to={url}>
            <Badge className={badgeClassname}>
              <i className={iconClass}></i> {labelText}
            </Badge>
          </Link>
        );
      },
    }
  ];

  const paginationOptions = {
    rowsPerPageText: 'Linhas por página:',
    rangeSeparatorText: 'de',
    selectAllRowsItem: true,
    selectAllRowsItemText: 'Todos',
  };

  return (
    <>
      <Card className="p-2">
        <CardBody className="card-body">
          <div>
            <h5 className="titulo-card">
              Lista de Cadastros
            </h5>
            {getRegistrationLoading && <Loader />}
            <DataTable
              columns={columns}
              data={cadastros}
              pagination
              paginationPerPage={10} // Defina o número de linhas por página
              paginationRowsPerPageOptions={[10, 20, 30, 40, 50]} // Opções de linhas por página
              noDataComponent="Nenhum cadastro foi encontrado."
              paginationComponentOptions={paginationOptions} // Configurar opções de paginação
            />
          </div>
        </CardBody>
      </Card>
    </>
  );
};

export default CadastroLista;