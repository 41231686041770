import React from "react";
import { useHistory } from "react-router-dom";
import {
  ModalHeader,
  TituloModal,
  BotaoEntrar,
  Image,
  P,
  BotaoVoltar,
} from "../Authentication/ModalRegister/style";
import { Container } from "reactstrap";
import IlustracaoConfirmada from "../../assets/images/svg/Ilustracao_solicitacao_confirmada.svg";

const CompleteRegistration = () => {
  const history = useHistory();
  return (
    <Container
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <TituloModal style={{ paddingLeft: 0 }}>
          Seu cadastro foi concluído com sucesso!
        </TituloModal>
        <Image src={IlustracaoConfirmada} />
        <P>
          Obrigada por efetuar seu cadastro, em até 48h o seu acesso será
          liberado. O consultor da A adias Solar entrará em contato com você.
        </P>
        <BotaoVoltar onClick={() => history.push("/login")}>Voltar</BotaoVoltar>
      </div>
    </Container>
  );
};

export default CompleteRegistration;
