import { call, put, takeEvery, all, fork } from "redux-saga/effects";
import { RegistrationsActionTypes } from "./actionTypes";
import { registrationApiSuccess, registrationApiFail } from "./action";

import {
  getRegistration as getRegistrationApi,
  getRegistrationDetails as getRegistrationDetailsApi,
  createOrUpdateRegistration as createOrUpdateRegistrationApi,
  getSuperiorRevenda as getSuperiorRevendaApi
} from "../../api/registration";
import { toast } from "react-toastify";

function* getRegistrationDetails({ payload: filters }) {
  try {
    const response = yield call(getRegistrationDetailsApi, filters);
    yield put(
      registrationApiSuccess(
        RegistrationsActionTypes.GET_REGISTRATION_LIST,
        response
      )
    );
  } catch (error) {
    toast.info(`Ocorreu um erro ao buscar os cadastros. Tente novamente.`, {
      theme: "colored",
    });
    yield put(
      registrationApiFail(RegistrationsActionTypes.GET_REGISTRATION_LIST, error)
    );
  }
}

function* getRegistration({ payload: id }) {
  try {
    const response = yield call(getRegistrationApi, id);
    yield put(
      registrationApiSuccess(
        RegistrationsActionTypes.GET_REGISTRATION,
        response
      )
    );
  } catch (error) {
    toast.info(
      `Ocorreu um erro ao buscar os dados do cadastro. Tente novamente.`,
      { theme: "colored" }
    );
    yield put(
      registrationApiFail(RegistrationsActionTypes.GET_REGISTRATION, error)
    );
  }
}

function* getSuperiorRevenda() {
  try {
    const response = yield call(getSuperiorRevendaApi);
    yield put(
      registrationApiSuccess(
        RegistrationsActionTypes.GET_SUPERIOR_REVENDA,
        response
      )
    );
  } catch (error) {
    yield put(
      registrationApiFail(RegistrationsActionTypes.GET_SUPERIOR_REVENDA, error)
    );
  }
}

function* createOrUpdateRegistration({ payload: params }) {
  try {
    const response = yield call(createOrUpdateRegistrationApi, params.data);

    yield put(
      registrationApiSuccess(
        RegistrationsActionTypes.CREATE_UPDATE_REGISTRATION,
        response
      )
    );

    toast.success(`Cadastro de revenda realizado com sucesso!`, {
      theme: "colored",
    });

    params.history.push('/cadastro');
  } catch (error) {
    yield put(
      registrationApiFail(
        RegistrationsActionTypes.CREATE_UPDATE_REGISTRATION,
        error
      )
    );

    toast.error(`Ocorreu um erro durante a realização do cadastro. `, {
      theme: "colored",
    });
  }
}

export function* watchGetRegistrationDetails() {
  yield takeEvery(
    RegistrationsActionTypes.GET_REGISTRATION_LIST,
    getRegistrationDetails
  );
}

export function* watchGetRegistration() {
  yield takeEvery(RegistrationsActionTypes.GET_REGISTRATION, getRegistration);
}

export function* watchCreateOrUpdateRegistration() {
  yield takeEvery(
    RegistrationsActionTypes.CREATE_UPDATE_REGISTRATION,
    createOrUpdateRegistration
  );
}

export function* watchGetSuperiorRevenda() {
  yield takeEvery(
    RegistrationsActionTypes.GET_SUPERIOR_REVENDA,
    getSuperiorRevenda
  );
}

function* registrationSaga() {
  yield all([
    fork(watchGetRegistrationDetails),
    fork(watchGetRegistration),
    fork(watchCreateOrUpdateRegistration),
    fork(watchGetSuperiorRevenda),
  ]);
}

export default registrationSaga;
