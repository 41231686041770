import React, { useState, useCallback, useEffect } from "react";
import { Row, Col, Alert, FormGroup, Button, Spinner } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { apiClient2 } from "../../../../api/apiCore2";
import getCroppedImg from "../../../../components/crop/cropImage";
import Typography from "@material-ui/core/Typography";
import Slider from "@material-ui/core/Slider";
import Cropper from "react-easy-crop";
import { dataURLtoFile } from "../../../../utils";
import { styles } from "../../../../components/crop/styles";
import { useSelector } from "react-redux";
import { apiClient } from "../../../../api/apiCore";
import { toast } from "react-toastify";

function Budget() {
  const { userOrcamento, user } = useSelector(state => {
    return {
      user: state.Profile.profileDetails.user,
      userOrcamento: state.Profile.profileDetails.user?.user_orcamento,
    };
  });

  const [budgetFile, setBudgetFile] = useState();
  const [image, setImage] = useState();

  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [rotation, setRotation] = useState(0);
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [, setCroppedImage] = useState(null);

  const [showLoading, setShowLoading] = useState(false);
  const [companyName, setCompanyName] = useState();
  const [companySite, setCompanySite] = useState();
  const [phoneNumber, setPhoneNumber] = useState();
  const [cellPhoneNumber, setCellPhoneNumber] = useState();
  const [companyEmail, setCompanyEmail] = useState();
  const [, setPhotoBudgetAsFile] = useState();

  const checkeds = {
    companyReceiveEmailsTransacionais: true,
    companySendEmailsTransacionais: true,
    companySendEmailsTransacionaisToMyClients: true,
  };

  useEffect(() => {
    if (userOrcamento) {
      if (Object.keys(userOrcamento).length > 0) {
        setCompanyName(userOrcamento.nome_empresa);
        setCompanySite(userOrcamento.site);
        setPhoneNumber(userOrcamento.telefone);
        setCellPhoneNumber(userOrcamento.celular);
        setCompanyEmail(userOrcamento.email);
      }
    }
  }, [userOrcamento]);

  useEffect(() => {
    //GET /api/solar/get-logo/${id}
    const getImage = async () => {
      const response = await apiClient.get(
        `/api/solar/get-logo/${userOrcamento?.user_id}`
      );
      setBudgetFile(response.logo_url);
    };

    if (userOrcamento) {
      if (Object.keys(userOrcamento).length > 0) {
        getImage();
      }
    }
  }, [userOrcamento]);

  useEffect(() => {
    if (image) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setBudgetFile(reader.result);
      };
      reader.readAsDataURL(image);
    } else setBudgetFile(null);
  }, [image]);

  const saveCroppedImage = async () => {
    if (budgetFile) {
      try {
        const croppedImageT = await getCroppedImg(
          budgetFile,
          croppedAreaPixels,
          rotation
        );

        setCroppedImage(croppedImageT);
        var file = dataURLtoFile(croppedImageT, "croppedImage.jpeg");

        setPhotoBudgetAsFile(file);

        return file;
      } catch (e) {
        return 0;
      }
    }
  };

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const handleValidSubmit = async (event, values) => {
    const respImage = await saveCroppedImage();

    const formData = new FormData();

    formData.append("nome_empresa", values.companyName);
    formData.append("site", values.companySIte);
    formData.append("telefone", values.companyDeskphoneNumber);
    formData.append("celular", values.companyCellphoneNumber);
    formData.append("email", values.companyUsefullEmail);
    formData.append("use_email", values.companySendEmailsTransacionais ? 1 : 0);
    formData.append(
      "recebercopia",
      values.companyReceiveEmailsTransacionais ? 1 : 0
    );
    formData.append(
      "enviarclientes",
      values.companySendEmailsTransacionaisToMyClients ? 1 : 0
    );
    formData.append("image", image);

    const formDataImage = new FormData();
    formDataImage.append("file", respImage);
    formDataImage.append("userid", userOrcamento?.user_id);

    try {
      // var formData = new FormData();
      setShowLoading(true);

      if (userOrcamento?.user_id) {
        apiClient.create(`/api/solar/logo-upload`, formDataImage);
      }

      apiClient2
        .create(`/users/orcamentos/atualizar-dados`, formData)
        .then(() => {
          toast.success("Dados salvos com sucesso", { theme: "colored" });
          setShowLoading(false);
        })
        .catch(() => {
          toast.error("Erro ao tentar salvar os dados na base de dados", {
            theme: "colored",
          });
          setShowLoading(false);
        });
    } catch (error) {
      setShowLoading(false);
      toast.error(error, { theme: "colored" });
    }
  };

  const maskTelephone = v => {
    return v
      .replace(/\D/g, "")
      .replace(/(\d{2})(\d)/, "($1) $2")
      .replace(/(\d{4})(\d)/, "$1-$2")
      .replace(/(-\d{4})\d+?$/, "$1");
  };
  const maskCellphone = v => {
    return v
      .replace(/\D/g, "")
      .replace(/(\d{2})(\d)/, "($1) $2")
      .replace(/(\d{5})(\d)/, "$1-$2")
      .replace(/(-\d{4})\d+?$/, "$1");
  };

  return (
    <>
      <AvForm
        id="form-budget"
        className="form-horizontal"
        model={checkeds}
        onValidSubmit={(e, v) => {
          handleValidSubmit(e, v);
        }}
      >
        <FormGroup>
          <Alert color="info">Informações de orçamento.</Alert>
          <Row>
            <h5 className="mb-4">Dados do Orçamento/Dados para E-mails</h5>
            <Col md={6}>
              <AvField
                label="Nome da Empresa"
                type="text"
                className="form-control"
                id="formrow-company-name-Input"
                value={companyName}
                // onChange={userChange}
                validate={{
                  required: {
                    value: true,
                    errorMessage: "Este campo é obrigatório",
                  },
                  minLength: {
                    value: 5,
                    errorMessage:
                      "O nome da empresa deve possuir no mínimo 5 letras",
                  },
                }}
                name="companyName"
              />
            </Col>
            <Col md={6}>
              <AvField
                label="Site"
                type="text"
                className="form-control"
                id="formrow-site-Input"
                value={companySite}
                // onChange={userChange}
                name="companySIte"
                validate={{
                  required: {
                    value: true,
                    errorMessage: "Este campo é obrigatório",
                  },
                  minLength: {
                    value: 8,
                    errorMessage:
                      "O nome da empresa deve possuir no mínimo 8 letras",
                  },
                }}
              />
            </Col>
            <Col md={6} className="mt-2">
              <AvField
                label="Telefone"
                type="text"
                className="form-control"
                id="formrow-deskphone-number-Input"
                value={phoneNumber}
                onChange={e => {
                  setPhoneNumber(maskTelephone(e.target.value));
                }}
                name="companyDeskphoneNumber"
                validate={{
                  required: {
                    value: true,
                    errorMessage: "Este campo é obrigatório",
                  },
                  minLength: {
                    value: 14,
                    errorMessage:
                      "A quantidade de digitos para número de telefone está incorreta.",
                  },
                  maxLength: {
                    value: 14,
                    errorMessage:
                      "A quantidade de digitos para número de telefone está incorreta.",
                  },
                }}
              />
            </Col>
            <Col md={6} className="mt-2">
              <AvField
                label="Celular"
                type="text"
                className="form-control"
                id="formrow-cellphone-Input"
                value={cellPhoneNumber}
                onChange={e => {
                  setCellPhoneNumber(maskCellphone(e.target.value));
                }}
                validate={{
                  required: {
                    value: true,
                    errorMessage: "Este campo é obrigatório",
                  },
                  minLength: {
                    value: 15,
                    errorMessage:
                      "A quantidade de digitos para número de celular está incorreta.",
                  },
                  maxLength: {
                    value: 15,
                    errorMessage:
                      "A quantidade de digitos para número de celular está incorreta.",
                  },
                }}
                name="companyCellphoneNumber"
              />
            </Col>
            <Col md={12} className="mt-2">
              <AvField
                label="E-mail"
                type="email"
                className="form-control"
                id="formrow-email-Input"
                value={companyEmail}
                validate={{
                  required: {
                    value: true,
                    errorMessage: "Este campo é obrigatório",
                  },
                  minLength: {
                    value: 7,
                    errorMessage:
                      "O nome da empresa deve possuir no mínimo 7 letras",
                  },
                }}
                name="companyUsefullEmail"
              />
            </Col>
          </Row>
          <Row>
            <FormGroup check>
              <h5 className="my-4">E-mails Transacionais</h5>
              <Col md={12}>
                <div className="d-flex">
                  <AvField
                    type="checkbox"
                    className="form-control"
                    id="formrow-send-emails-transacionais-Input"
                    trueValue={true}
                    name="companySendEmailsTransacionais"
                  />
                  <label
                    className="cursor-pointer"
                    htmlFor="formrow-send-emails-transacionais-Input"
                  >
                    {" "}
                    Desejo utilizar estas informações no envio de e-mails
                    transacionais.
                  </label>
                </div>
              </Col>
              <Col md={12}>
                <div className="d-flex">
                  <AvField
                    trueValue={true}
                    type="checkbox"
                    className="form-control"
                    id="formrow-receive-emails-transacionais-Input"
                    name="companyReceiveEmailsTransacionais"
                  />
                  <label
                    className="cursor-pointer"
                    htmlFor="formrow-receive-emails-transacionais-Input"
                  >
                    {" "}
                    Desejo receber E-mails transacionais (
                    <a
                      style={{ Color: "#2D567B" }}
                      href="mailto:termoclimaarcondicionado@hotmail.com"
                    >
                      {user?.email}
                    </a>
                    ).
                  </label>
                </div>
              </Col>
              <Col md={12}>
                <div className="d-flex">
                  <AvField
                    trueValue={true}
                    type="checkbox"
                    className="form-control"
                    id="formrow-send-emails-transacionais-client-Input"
                    // value={user.firstname || ""}
                    // onChange={userChange}
                    name="companySendEmailsTransacionaisToMyClients"
                  />
                  <label
                    className="cursor-pointer"
                    htmlFor="formrow-send-emails-transacionais-client-Input"
                  >
                    {" "}
                    Desejo que envie E-mails transacionais aos meus clientes.
                  </label>
                </div>
              </Col>
            </FormGroup>
          </Row>
          <div>
            <h5 className="my-4">Imagem para Orçamento</h5>
            <Alert color="warning">
              Carregue um arquivo de imagem, a qual será utilizada para gerar um
              orçamento, e ajuste conforme o necessário.
            </Alert>

            <div className="d-flex">
              <div className="w-100 position-relative">
                <div className="d-flex justify-content-between">
                  <div
                    style={{
                      height: 150,
                      width: 150,
                    }}
                    className="position-relative"
                  >
                    <Cropper
                      image={budgetFile}
                      crop={crop}
                      rotation={rotation}
                      zoom={zoom}
                      aspect={4 / 3}
                      onCropChange={setCrop}
                      onRotationChange={setRotation}
                      onCropComplete={onCropComplete}
                      onZoomChange={setZoom}
                    />
                    <Row
                      id="cropSliders"
                      className="d-flex justify-content-between"
                      style={{
                        width: `${
                          document.getElementById("col-crop")?.offsetWidth
                        }px`,
                      }}
                    >
                      <Col md={4} className="d-flex justify-content-between">
                        <div
                          className={styles.sliderContainer}
                          style={{ width: "100px" }}
                        >
                          <Typography
                            variant="overline"
                            classes={{ root: styles.sliderLabel }}
                          >
                            Zoom
                          </Typography>
                          <Slider
                            value={zoom}
                            min={1}
                            max={3}
                            step={0.1}
                            aria-labelledby="Zoom"
                            classes={{ root: styles.slider }}
                            onChange={(e, zoom) => setZoom(zoom)}
                          />
                        </div>
                        <div
                          className={styles.sliderContainer}
                          style={{ width: "100px" }}
                        >
                          <Typography
                            variant="overline"
                            classes={{ root: styles.sliderLabel }}
                          >
                            Rotacionar
                          </Typography>
                          <Slider
                            value={rotation}
                            min={0}
                            max={360}
                            step={1}
                            aria-labelledby="Rotation"
                            classes={{ root: styles.slider }}
                            onChange={(e, rotation) => setRotation(rotation)}
                          />
                        </div>
                      </Col>
                      <Col md={8} className="d-flex justify-content-between">
                        <label
                          htmlFor="input-file-img-budget"
                          className="mb-0 me-3 btn-default cursor-pointer hover-img-upload j-c-center mt-auto light-btn"
                        >
                          Imagem
                        </label>
                        <div className="d-flex align-items-start flex-column">
                          <Button
                            variant="contained"
                            color="success"
                            className="btn-default cursor-pointer hover-img-upload j-c-center mt-auto"
                            style={{ minWidth: 135 }}
                            disabled={showLoading}
                            type="submit"
                          >
                            {showLoading ? <Spinner /> : "Salvar"}
                          </Button>
                          {/* URL.createObjectURL */}
                          <input
                            accept="image/*"
                            hidden
                            onChange={e => {
                              const file = e.target.files[0];
                              if (file && file.type.substring(0, 5) === "image")
                                setImage(file);
                              else setImage(null);
                            }}
                            id="input-file-img-budget"
                            type="file"
                          />
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </FormGroup>
      </AvForm>
    </>
  );
}

export default Budget;
