import styled from "styled-components";
import { useSolar } from "../../../hooks";
const isSolar = useSolar();

export const SidebarMenu = styled.li`
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  height: ${props =>
    props.openSidebar && props.openSubMenu.id == props.id
      ? props.openSubMenu.open
        ? `${props.subItemsLenght * 45 + 45}px`
        : "45px"
      : "45px"};
  transition: all 0.2s linear;
  background: ${props =>
    props.isSolar ? "#FF601A" : "#2D567B"};
  ul {
    margin: 0px 29px 0px 0px;
    padding: 0;
    width: 100%;
    overflow: hidden;
  }
  a {
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 45px;
    padding: 5px;
    position: relative;
    font-size: 15px;
    font-weight: 500;
    border-radius: 10px;
    transition: all 0.4s;
    overflow: hidden;
    color: ${props => 
      !props.openSidebar
      ? props.data.link == props.location.pathname
        ? props.theme.colors.lighterOrange
        : (isSolar ? props.theme.colors.white : props.theme.colors.white)
      : (isSolar ? "#FFF" : "transparent")};
    z-index: 10;
    background-color: ${props =>
      !props.openSidebar
        ? props.data.link == props.location.pathname
          ? props.theme.colors.white
          : (isSolar ? props.theme.colors.white : props.theme.colors.white)
        : (isSolar ? props.theme.colors.solar : "transparent")};
    cursor: pointer;
  }
  a > div {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 0 2px;
  }
  .icon-label-arrow {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    z-index: 10;
    color: #fff;
  }
  .icon-label-arrow .icon-menu {
    display: flex;
    align-items: center;
    flex-direction: row;
  }
  .icon-label-arrow .icon-menu .icon {
    transition: all 0.2s linear;
    font-size: ${props => (props.openSidebar ? "1rem" : "1.5rem")};
    margin-left: 3px;
    margin-top: 3px;
    color: ${props => (props.isSolar ? "#fff" : "#fff")};
  }
  .icon-label-arrow .icon-menu span {
    transition: opacity 0.3s linear;
    margin-left: 10px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    opacity: ${props => (props.openSidebar ? `1` : "0")};
    font-weight: 200;
    color: "#FFF";
  }
  .arrow {
    width: 30px;
  }
  .arrow svg {
    width: 20px;
    height: 20px;
    color: ${props => (props.isSolar ? "#fff" : "#fff")};
    transition: all 0.2s linear;
    transform: ${props =>
      props.openSubMenu.id == props.id
        ? props.openSubMenu.open
          ? `rotate(180deg)`
          : "rotate(0deg)"
        : "rotate(0deg)"};
  }
`;
