import { API_SUCCESS, API_FAIL, GET_MENU_ITEMS } from "./actionTypes";

export const menuapiSuccess = (actionType, data) => ({
  type: API_SUCCESS,
  payload: { actionType, data },
});

export const menuapiFail = (actionType, error) => ({
  type: API_FAIL,
  payload: { actionType, error },
});

// recenet users
export const getMenuItems = data => ({
  type: GET_MENU_ITEMS,
  payload: data,
});
