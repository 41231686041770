export const applyPercentOrMoney = (percent, money, totalValue) => {
  let total = totalValue;
  if (total * (percent / 100) <= total - money) {
    return 1;
  } else {
    return 0;
  }
};

export const formatMoney = value => {
  var v = value.replace(/\D/g, "");
  v = (v / 100).toFixed(2) + "";
  v = v.replace(".", ",");
  v = v.replace(/(\d)(\d{3})(\d{3}),/g, "$1.$2.$3,");
  v = v.replace(/(\d)(\d{3}),/g, "$1.$2,");
  return (value = v);
};
