import React, { useEffect } from "react";
import { Card, CardBody, Col, Container, Row } from "reactstrap";

// redux
import { useSelector, useDispatch } from "react-redux";

// components
import Breadcrumb from "../../components/Breadcrumb";
import Loader from "../../components/Loader";
import Live from "./Live";
import Event from "./Event";

// actions
import { getTVGallary } from "../../store/actions";

const TvDaysLive = () => {
  const dispatch = useDispatch();
  const { tvGallery, getTvGalleryLoading } = useSelector(state => ({
    tvGallery: state.Gallery.tvGallery,
    getTvGalleryLoading: state.Gallery.getTvGalleryLoading,
  }));

  /*
  get details
  */
  useEffect(() => {
    dispatch(getTVGallary());
  }, [dispatch]);

  const recorded = tvGallery.recorded || [];

  return (
    <React.Fragment>
      <div className="page-content">
        <Container>
          {/* Render Breadcrumb */}
          <Breadcrumb title="Página Inicial" breadcrumbItem="TV A.Dias" />
          <div>
            <Row>
              <Col md={12}>
                <Live
                  live={tvGallery["live"]}
                  getTvGalleryLoading={getTvGalleryLoading}
                />
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <Card>
                  <CardBody className="position-relative">
                    <h5 className="mb-4">Últimos Eventos</h5>
                    {getTvGalleryLoading && <Loader />}
                    <Row>
                      {(recorded || []).map((record, key) => (
                        <Col lg={3} md={6} xs={12} key={key}>
                          <Event record={record} />
                        </Col>
                      ))}
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default TvDaysLive;
