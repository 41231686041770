import React from "react";
import { ArInput } from "../../../../../../components/adias-input";
import { Col, Row, Label } from "reactstrap";
import { useEffect } from "react";
import {
  grids,
  initialValuesToFormik,
  inversor,
} from "../../mockAndValidation";

export const Inversores = ({
  errors,
  touched,
  resetForm,
  values,
  handleChange,
  handleBlur,
}) => {
  useEffect(() => {
    if (values.options == inversor) {
      resetForm({
        values: { ...initialValuesToFormik, options: values.options },
      });
    }
  }, [values.options]);

  return (
    <>
      <Row>
        <Col md={4}>
          <ArInput title="Nome" errors={errors} name="nome" touched={touched} />
        </Col>
        <Col md={4}>
          <ArInput
            title="Modelo"
            errors={errors}
            name="modelo"
            touched={touched}
          />
        </Col>
        <Col md={4}>
          <ArInput
            title="Fabricante"
            errors={errors}
            name="fabricante"
            touched={touched}
          />
        </Col>
      </Row>

      <Row>
        <Col md={4}>
          <ArInput
            title="Potência (kw)"
            errors={errors}
            name="potencia"
            type="number"
            touched={touched}
          />
        </Col>
        <Col md={4}>
          <ArInput
            title="Potência Overload (wp)"
            errors={errors}
            name="potenciaOverload"
            type="number"
            touched={touched}
          />
        </Col>
        <Col md={4}>
          <ArInput
            title="Overload Porcentagem"
            errors={errors}
            name="overloadPorcentagem"
            type="number"
            touched={touched}
          />
        </Col>
      </Row>

      <Row>
        <Col md={4}>
          <ArInput
            title="Voltagem Max Por Entrada"
            errors={errors}
            name="voltagemMaxPorEntrada"
            type="number"
            touched={touched}
          />
        </Col>
        <Col md={4}>
          <ArInput
            title="Voltagem Min Por Entrada"
            errors={errors}
            name="voltagemMinPorEntrada"
            type="number"
            touched={touched}
          />
        </Col>{" "}
        <Col md={4}>
          <ArInput
            title="Corrente Max Por Entrada"
            errors={errors}
            name="correnteMaxPorEntrada"
            type="number"
            touched={touched}
          />
        </Col>
      </Row>

      <Row>
        <Col md={4}>
          <ArInput
            title="Corrente De Curto"
            errors={errors}
            name="correnteDeCurto"
            type="number"
            touched={touched}
          />
        </Col>
        <Col md={4}>
          <ArInput
            title="Numero De Entradas"
            errors={errors}
            name="numeroDeEntradas"
            type="number"
            touched={touched}
          />
        </Col>{" "}
        <Col md={4}>
          <ArInput
            title="Altura (mm)"
            errors={errors}
            name="altura"
            type="number"
            touched={touched}
          />
        </Col>
      </Row>

      <Row>
        <Col md={4}>
          <ArInput
            title="Comprimento (mm)"
            errors={errors}
            name="comprimento"
            type="number"
            touched={touched}
          />
        </Col>
        <Col md={4}>
          <ArInput
            title="Largura (mm)"
            errors={errors}
            name="largura"
            type="number"
            touched={touched}
          />
        </Col>{" "}
        <Col md={4}>
          <ArInput
            title="Peso (kg)"
            errors={errors}
            name="peso"
            type="number"
            touched={touched}
          />
        </Col>
      </Row>

      <Row>
        <Col md={4}>
          <ArInput
            title="Tensao Ca"
            errors={errors}
            name="tensaoCa"
            touched={touched}
          />
        </Col>
        <Col md={4}>
          <Label style={{ marginTop: 8, marginBottom: 5 }}>Grid</Label>
          <select
            className="form-select"
            aria-label="Filter select"
            name="grid"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.grid}
            style={{ height: 35 }}
          >
            {grids?.map(item => {
              return (
                <option key={item.value} value={item.value}>
                  {item?.name}
                </option>
              );
            })}
          </select>
        </Col>{" "}
        <Col md={4}>
          <ArInput
            title="Numero De MPPT"
            errors={errors}
            name="numeroDeMPPT"
            type="number"
            touched={touched}
          />
        </Col>
      </Row>

      <Row>
        <Col md={4}>
          <ArInput
            title="Stringbox 1"
            errors={errors}
            name="stringbox1"
            touched={touched}
          />
        </Col>
        <Col md={4}>
          <ArInput
            title="Stringbox 2"
            errors={errors}
            name="stringbox2"
            touched={touched}
          />
        </Col>{" "}
        <Col md={4}>
          <ArInput
            title="Custo Distribuidor"
            errors={errors}
            name="custo"
            type="number"
            touched={touched}
          />
        </Col>
      </Row>

      <Row>
        <Col md={4}>
          <ArInput
            title="Preço Cliente Final"
            errors={errors}
            name="preco"
            type="number"
            touched={touched}
          />
        </Col>
        <Col md={4}>
          <ArInput
            title="Fusível"
            errors={errors}
            name="fusivel"
            touched={touched}
          />
        </Col>{" "}
        <Col md={4}>
          <ArInput
            title="Qtd Fusível"
            errors={errors}
            name="qtdFusivel"
            type="number"
            touched={touched}
          />
        </Col>
      </Row>
    </>
  );
};
