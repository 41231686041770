import React from "react";
export default function IconLightning(props) {
  const colorFill = props?.colorFill || "none";
  const colorStroke = props?.colorStroke || "#FFBA00";

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="18"
      viewBox="0 0 17 18"
      fill={colorFill}
    >
      <path
        d="M6.375 16.4375L7.4375 11.125L3.1875 9.53125L10.625 1.5625L9.5625 6.875L13.8125 8.46875L6.375 16.4375Z"
        stroke={colorStroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
