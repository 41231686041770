import React from "react";
import { Button } from "reactstrap";

const ButtonLoader = ({
  color,
  className,
  onClick,
  disabled,
  text,
  loading,
  type,
  colorBg,
}) => {
  return (
    <Button
      color={color}
      className={className}
      onClick={onClick}
      disabled={disabled}
      type={type}
      style={{ backgroundColor: colorBg }}
    >
      {loading ? (
        <i className="bx bx-loader bx-spin font-size-16 align-middle me-2" />
      ) : null}
      {text}
    </Button>
  );
};

export default ButtonLoader;
