import React, { useState, useEffect } from "react";

import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import BootstrapTheme from "@fullcalendar/bootstrap";
import ptBrLocale from "@fullcalendar/core/locales/pt-br";
import "@fullcalendar/bootstrap/main.css";
import ViewEventModal from "./ViewEventModal";

const Calender = ({ events }) => {
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const onOpenModal = () => setIsOpen(true);
  const onCloseModal = () => {
    setIsOpen(false);
    setSelectedEvent(null);
  };
  const [calendarEvents, setCalendarEvents] = useState([]);
  useEffect(() => {
    const modifiedEvents = (events || []).map(e => {
      return {
        ...e,
        title: e.title,
        start: new Date(e.start),
        end: new Date(e.end),
        display: "block",
        backgroundColor: e.backgroundColor,
      };
    });
    setCalendarEvents([...modifiedEvents]);
  }, [events]);
  const onEventClick = arg => {
    if (arg && arg.event && arg.event.title) {
      const event = {
        title: arg.event.title,
        date: arg.event.start,
        ...arg.event.extendedProps,
      };
      onOpenModal();
      setSelectedEvent(event);
    }
  };
  return (
    <React.Fragment>
      {/* fullcalendar control */}
      <FullCalendar
        plugins={[BootstrapTheme, dayGridPlugin, interactionPlugin]}
        slotDuration={"00:15:00"}
        handleWindowResize={true}
        themeSystem="bootstrap"
        headerToolbar={{
          left: "prev,next today",
          center: "title",
          right: "dayGridMonth,dayGridWeek,dayGridDay",
        }}
        events={calendarEvents}
        displayEventTime={false}
        editable={false}
        droppable={false}
        selectable={true}
        eventClick={onEventClick}
        locale={ptBrLocale}
      />

      {isOpen && selectedEvent ? (
        <ViewEventModal
          isOpen={isOpen}
          onClose={onCloseModal}
          event={selectedEvent}
        />
      ) : null}
    </React.Fragment>
  );
};

export default Calender;
