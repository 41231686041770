import React from "react";
import { Row, Col, Badge } from "reactstrap";

const Status = () => {
  return (
    <div className="pt-3">
      <ul>
        <li className="mb-3">
          <Row>
            <Col lg={10}>Request created</Col>
            <Col lg={2} className="text-end text-muted">
              21 Sep, 2021 at 8:28am
            </Col>
          </Row>
        </li>

        <li className="mb-3">
          <Row>
            <Col lg={10}>
              Status Changed from{" "}
              <Badge color="warning" className="bg-warning mx-1">
                Under Analysis
              </Badge>{" "}
              to
              <Badge color="info" className="bg-info mx-1">
                Part Requested
              </Badge>
              by
              <Badge color="success" className="bg-success mx-1">
                {" "}
                Juliana Alexandre martins ara
              </Badge>
            </Col>
            <Col lg={2} className="text-end text-muted">
              21 Sep, 2021 at 8:28am
            </Col>
          </Row>
        </li>

        <li className="">
          <Row>
            <Col lg={10}>
              Status Modified
              <Badge color="warning" className="bg-warning mx-1">
                Ask Requested
              </Badge>{" "}
              to
              <Badge color="info" className="bg-info mx-1">
                Ask Available
              </Badge>
              by
              <Badge color="success" className="bg-success mx-1">
                {" "}
                Juliana Alexandre martins ara
              </Badge>
            </Col>
            <Col lg={2} className="text-end text-muted">
              21 Sep, 2021 at 8:28am
            </Col>
          </Row>
        </li>
      </ul>
    </div>
  );
};

export default Status;
