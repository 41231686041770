import { useEffect, useState } from "react";
import { removeLast } from "../../../../../../utils";
import {
  PresentationChart,
  GraphicsLine,
  React,
} from "../../../helpers/importer.helper";
import Footer from "../../Footer";
import "./styles.scss";

export default function ContainerBottom({ dataPdf }) {
  const [dataTable, setDataTable] = useState([]);
  const [dataConsumo, setDataConsumo] = useState([]);
  const [dataGeracao, setDataGeracao] = useState([]);

  const getDataGeracao = () => {
    let arrayValues = [];
    dataPdf?.lead?.calculations?.irradiation_fv_kwh.map(item => {
      let conta = (Object.values(item)[0] / 1000) * dataPdf?.kwpPdf;
      arrayValues.push(conta.toFixed(2));
    });

    setDataGeracao(arrayValues);
  };

  useEffect(() => {
    if (dataPdf?.lead?.lead?.consumption_type && dataPdf?.kwpPdf) {
      getDataGeracao();
    }
  }, [dataPdf?.lead?.lead?.consumption_type, dataPdf?.kwpPdf]);

  const getDataConsumo = () => {
    Object.values(dataPdf?.lead?.calculations?.med_cost[0]).map(
      (values, indexOne) =>
        setDataTable(state => [
          ...state,
          {
            mes: Object.keys(dataPdf?.lead?.calculations?.med_cost[0]).filter(
              (v, indexTwo) => indexOne == indexTwo && v
            )[0],
            consumo:
              Number(values.replace(",", ".")) /
              Number(dataPdf?.lead?.lead?.kwh_price),

            geracao: dataGeracao[indexOne],
          },
        ])
    );
  };

  useEffect(() => {
    if (dataGeracao.length > 0) {
      getDataConsumo();
    }
  }, [dataGeracao]);

  const formatArraydataTable = () => {
    let array = [];
    dataTable.map(item => array.push(parseInt(item.consumo)));
    setDataConsumo(array);
  };

  useEffect(() => {
    formatArraydataTable();
  }, [dataTable]);

  return (
    <div className="container-pdf-6-bottom">
      <div className="container-pdf-6-bottom-header">
        <PresentationChart />
        <span>
          COMPARATIVO DA ENERGIA CONSUMIDA COM E SEM O SISTEMA FOTOVOLTAICO:
        </span>
      </div>
      <div className="container-pdf-6-bottom-table-graphics">
        <table>
          <tr style={{ background: "#E5E5E5" }}>
            <th>Meses</th>
            <th>Consumo kWh</th>
            <th>Geração FV kWh</th>
          </tr>
          {dataTable.map((item, k) => (
            <tr key={k}>
              <td>{item.mes}</td>
              <td className="orange">
                {removeLast(
                  parseFloat(item.consumo)
                    .toFixed(2)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, "."),
                  ".",
                  ","
                )}
              </td>
              <td className="blue">{parseFloat(item.geracao)}</td>
            </tr>
          ))}
        </table>
        <div className="container-pdf-6-bottom-graphic">
          {dataConsumo.length > 0 && dataGeracao.length > 0 ? (
            <GraphicsLine dataConsumo={dataConsumo} dataGeracao={dataGeracao} />
          ) : (
            <div />
          )}
        </div>
      </div>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <p>
          Os valores apresentados são estimativas, baseiam-se em medições
          fornecidas por banco de dados do CRESESB ou NASA e variam de acordo
          com o mês do ano em que se mede a produção e de fatores meteorológicos
          específicos de cada ano e já consideram as perdas de inversão,
          temperatura, fiação e outras.
          <br />
          <br /> kWpico é a medida utilizada para definir a máxima potência
          instantânea em corrente contínua gerada pelo Sistema Fotovoltaico a
          1000W/m² disponíveis de radiação solar.
          <br />
          <br />
          kWh é a soma da energia total gerada em um dado período de tempo.
        </p>
        <Footer dataPdf={dataPdf} />
      </div>
    </div>
  );
}
