import { Formik } from "formik";
import { useEffect } from "react";
import { Form, Tooltip } from "reactstrap";
import PlacesAutoComplete from "../../components/places-auto-complete";
import IconTrash from "../../assets/images/icons/trash";
import { toast } from "react-toastify";
import {
  Card,
  Col,
  Container,
  Row,
  Input,
  Spinner,
  IconComingSoon,
  useState,
  IconWarningCircle,
  MagnifyingGlassComponent,
  Separator,
  DataWithPictures,
  IconSumBeamsInPanel,
  IconPlus,
  IconSunBeams,
  IconPanelRechargingBatery,
  months,
  IconPowerBox,
  IconPacked,
  IconTote,
  initialValuesToFormik,
  SignupSchema,
  sizingAfterCalculateLabel,
  React,
  useHistory,
  apiClient,
} from "./helpers/importer.helper";
import "../../styles/main.scss";
import { Typeahead } from "react-bootstrap-typeahead";
export default function CalculatorReceivesData() {
  const sizingAfterCalculateIcons = [
    { icon: <IconSunBeams /> },
    { icon: <IconSumBeamsInPanel /> },
    { icon: <IconPanelRechargingBatery /> },
    { icon: <IconPowerBox /> },
  ];

  const history = useHistory();

  const [averageConsumption, setAverageConsumption] = useState(0);
  const [KWhOrReais, setKWhOrReis] = useState(false);
  const [kitChoise, setKitChoise] = useState(0);
  const [errors, setErrors] = useState({
    kwhPrice: "",
  });

  const [showCalcResults, setShowCalcResults] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);

  const [roofsTypes, setRoofsTypes] = useState([{}]);
  const [irradiationTypes, setIrradiationTypes] = useState([]);

  const [mapCenter, setMapCenter] = useState({
    lat: null,
    lng: null,
  });
  const [, setZoom] = useState(40);
  const [attPlacesAutoComplete, setAttPlacesAutoComplete] = useState(false);

  const [indexAvarageConsumptionInput, setIndexAvarageConsumptionInput] =
    useState(1);
  const [enableButtonCalc, setEnableButtonCalc] = useState(false);

  const [address, setAddress] = useState("");
  const [userSelectRoofType, setUserSelectRoofType] = useState("1");
  const [kwhPrice, setKwhPrice] = useState();
  const [nameKwhPrice, setNameKwhPrice] = useState("");
  const [systemLosses, setSystemLosses] = useState();
  const [userSelectIrradiationType, setUserSelectIrradiationType] =
    useState("Global Horizontal");
  const [clientName, setClientName] = useState();
  const [clientEmail, setClientEmail] = useState();
  const [clientPhone, setClientPhone] = useState();

  const [tariff, setTariff] = useState([]);

  const [sizingAfterCalculate, setSizingAfterCalculate] = useState();
  const [loadingIcons, setLoadingIcons] = useState(false);

  const [averageConsumptionAdded, setAverageConsumptionAdded] = useState([
    {
      name: `average_consumption_added_1`,
      value: "0,00",
    },
  ]);

  const [averageConsumptionMonthlyAdded, setAverageConsumptionMonthlyAdded] =
    useState(
      months.map(item => {
        return {
          name: item.name,
          value: "0,00",
        };
      })
    );
  const monthsArray = [
    "Janeiro",
    "Fevereiro",
    "Marco",
    "Abril",
    "Maio",
    "Junho",
    "Julho",
    "Agosto",
    "Setembro",
    "Outubro",
    "Novembro",
    "Dezembro",
  ];

  useEffect(() => {
    setKwhPrice(formatValueToMoney("085", true));
    setSystemLosses(formatValueToPercentage("25"));
  }, []);

  const handleCalcAvarageConsumtion = async () => {
    setShowCalcResults(true);
    setLoadingIcons(true);

    try {
      const resp = await apiClient.create("/api/solar/v1/solar-calculator", {
        latitude: mapCenter.lat,
        longitude: mapCenter.lng,
        kwh_price: kwhPrice,
        system_losses: systemLosses,
        consumption: {
          avg_account_type: !KWhOrReais ? "real" : "kwh",
          type:
            averageConsumption == 1
              ? "monthly_consumption"
              : "average_consumption",
          values:
            averageConsumption == 1
              ? averageConsumptionMonthlyAdded
              : averageConsumptionAdded,
        },
      });
      setSizingAfterCalculate(Object.values(resp.result));
    } catch (err) {
      toast.error("Ops ocorreu um erro", { theme: "colored" });
    }

    setLoadingIcons(false);
  };

  useEffect(() => {
    if (showCalcResults) {
      var myDiv = document.getElementById("sentinel");
      let Coordinates = myDiv.getBoundingClientRect();
      window.scrollTo(0, Coordinates.y - 200);
    }
  }, [showCalcResults]);

  const handleSaveLead = async () => {
    setShowSpinner(true);

    const newAverageConsumptionAdded = averageConsumptionAdded.map(
      (item, k) => {
        return { [monthsArray[k]]: item.value };
      }
    );

    const newAverageConsumptionMonthlyAdded =
      averageConsumptionMonthlyAdded.map((item, k) => {
        return { [monthsArray[k]]: item.value };
      });

    let newObj = {
      attributes: {
        address: address,
        roof_type: userSelectRoofType,
        kwh_price: kwhPrice,
        system_losses: systemLosses,
        irradiation: userSelectIrradiationType,
        name: clientName,
        email: clientEmail,
        latitude: mapCenter.lat,
        longitude: mapCenter.lng,
        phone: clientPhone,
        kit_type: kitChoise == 0 ? "pronto" : "personalizado",
        consumption_type: averageConsumption == 1 ? "monthly" : "average",
        avg_account_type: !KWhOrReais ? "real" : "kwh",
        consumption: {
          values:
            averageConsumption == 1
              ? newAverageConsumptionMonthlyAdded
              : newAverageConsumptionAdded,
        },
      },
    };
    try {
      const resp = await apiClient.create(
        "/api/solar/order_consumption",
        newObj
      );

      if (resp?.message == "success") {
        toast.success("Dados enviados com sucesso!", {
          theme: "colored",
        });

        history.push(
          `/solar/${kitChoise == 1 ? "pedidos/novo/kits" : "kits-newOrder"}/${
            resp?.data?.id
          }`
        );
      } else {
        toast.error("Erro ao enviar dados", {
          theme: "colored",
        });
      }
      setShowSpinner(false);
    } catch (err) {
      setShowSpinner(false);
    }
  };

  const formatValueToMoney = (value, showSymbolMoney = true) => {
    var v = value.replace(/\D/g, "");
    v = (v / 100).toFixed(2) + "";
    v = v.replace(".", ",");
    v = v.replace(/(\d)(\d{3})(\d{3}),/g, "$1.$2.$3,");
    v = v.replace(/(\d)(\d{3}),/g, "$1.$2,");
    return showSymbolMoney ? (value = `${v}`) : v;
  };

  const formatValueToPhone = e => {
    var value = e.target.value;
    var valor = value;
    var retorno = valor.replace(/\D/g, "");
    retorno = retorno.replace(/^0/, "");
    if (retorno.length > 10) {
      retorno = retorno.replace(
        /^(\d\d)(\d{1})(\d{4})(\d{4}).*/,
        "($1) $2 $3-$4"
      );
    } else if (retorno.length > 5) {
      if (retorno.length == 6 && e.code == "Backspace") {
        // necessário pois senão o "-" fica sempre voltando ao dar backspace
        return;
      }
      retorno = retorno.replace(/^(\d\d)(\d{4})(\d{0,4}).*/, "($1) $2-$3");
    } else if (retorno.length > 2) {
      retorno = retorno.replace(/^(\d\d)(\d{0,5})/, "($1) $2");
    } else {
      if (retorno.length != 0) {
        retorno = retorno.replace(/^(\d*)/, "($1");
      }
    }
    return retorno;
  };

  const formatValueToPercentage = value => {
    return value.length >= 6
      ? value.replace(/\D/g, "").replace(/(\d{3})(\d)/, "$1.$2")
      : value.replace(/\D/g, "").replace(/(\d{2})(\d)/, "$1.$2");
  };

  const handleChangeAveragesConsumptions = (e, name, variable, setVariable) => {
    const newObj = variable.map(item => {
      return name == item.name
        ? { ...item, value: formatValueToMoney(e.target.value) }
        : item;
    });
    var validateFields = [];
    variable.map(item => {
      validateFields.push(Object.values(item)[1]);
    });
    if (validateFields.indexOf("0,00") == -1) setEnableButtonCalc(true);
    else setEnableButtonCalc(false);
    setVariable(newObj);
  };

  const handleAddAverageConsumptionInput = () => {
    let temp = indexAvarageConsumptionInput + 1;
    const element = averageConsumptionAdded;
    if (
      element[element.length - 1].value != "0,00" &&
      element[element.length - 1].value != ""
    ) {
      setAverageConsumptionAdded([
        ...element,
        { name: `average_consumption_added_${temp}`, value: "0,00" },
      ]);
      setIndexAvarageConsumptionInput(temp);
      setTimeout(() => {
        document.getElementById(`average_consumption_added_${temp}`)?.focus();
      }, 100);
    } else {
      toast.error("Para adicionar um novo campo, insira valor no último", {
        theme: "colored",
      });
      document.getElementById(`average_consumption_added_${temp - 1}`)?.focus();
    }
  };

  const handleRemoveAverageConsumptionInput = indexToDelete => {
    const element = averageConsumptionAdded;
    var array = [...element];
    const elementWillBeDeleted = element[indexToDelete];
    var index = array.indexOf(elementWillBeDeleted);
    if (index !== -1) {
      array.splice(index, 1);
      setAverageConsumptionAdded(array);
    }
  };

  const changeAverageConsumptionOrKitChoise = (
    choise = "averageConsumption",
    val = 0
  ) => {
    if (choise == "averageConsumption") {
      setAverageConsumption(averageConsumption == 0 ? 1 : 0);
      averageConsumption == 1
        ? setAverageConsumptionAdded([
            { name: `average_consumption_added_1`, value: "" },
          ])
        : setAverageConsumptionMonthlyAdded(
            months.map(item => {
              return {
                name: item.name,
                value: "0,00",
              };
            })
          );
    } else if (choise == "kitChoise" && val != kitChoise)
      setKitChoise(kitChoise == 0 ? 1 : 0);
  };

  const getDatasToSelects = () => {
    apiClient.get(`/api/solar/roofs`).then(res => {
      setRoofsTypes(res.data);
    });
    apiClient.get(`/api/solar/v1/irradiation/all`).then(res => {
      setIrradiationTypes(res);
    });
  };
  useEffect(() => {
    getDatasToSelects();
  }, []);

  useEffect(() => {
    if (kwhPrice != "") setErrors({ ...errors, kwhPrice: "" });
    else setErrors({ ...errors, kwhPrice: "Campo endereço obrigatório" });
  }, [kwhPrice]);

  const getTariff = async () => {
    let obj = { latitude: mapCenter.lat, longitude: mapCenter.lng };
    const resp = await apiClient.create(`/api/solar/tariff`, obj);
    const arrUniq = [...new Map(resp.data.map(v => [v.tarifa, v])).values()];
    setTariff(arrUniq);
    setNameKwhPrice(resp.data[0].concessionaria);
  };

  useEffect(() => {
    if (mapCenter.lat !== null && mapCenter.lng !== null) {
      getTariff();
    }
  }, [mapCenter]);

  const handleSelectKhw = e => {
    let tarifa = e.target.value.replace(".", ",");
    setKwhPrice(tarifa);

    let find = tariff.filter(item => item.tarifa == e.target.value);
    if (find.length >= 1) {
      setNameKwhPrice(find[0].concessionaria);
    } else {
      setNameKwhPrice("-");
    }
  };

  const changeKwpOrReais = () => {
    setKWhOrReis(state => !state);
  };

  return (
    <>
      <Container
        id="calculator-receives-data"
        className="page-content solar-styles"
      >
        <Formik
          initialValues={initialValuesToFormik}
          validationSchema={SignupSchema}
        >
          {({}) => (
            <Form>
              <Card className="card p-4 solar-shadow">
                <section id="section-1">
                  <h2 className="main-title">
                    Insira os dados do local de instalação:
                  </h2>{" "}
                  <Row>
                    <Col md={11} className="mx-auto">
                      <Row>
                        <Col md={12} className="mt-5">
                          <div className="d-flex">
                            <label htmlFor="">Endereço</label>

                            <i
                              data-toggle="tooltip"
                              data-placement="top"
                              title="Tooltip"
                            >
                              <IconWarningCircle
                                colorStroke="#0090A3"
                                colorFill="#fff"
                              />
                            </i>
                          </div>
                          <PlacesAutoComplete
                            setMapCenter={setMapCenter}
                            setAddress={setAddress}
                            address={address}
                            setZoom={setZoom}
                            setAttPlacesAutoComplete={setAttPlacesAutoComplete}
                            attPlacesAutoComplete={attPlacesAutoComplete}
                          />
                        </Col>
                        <Col md={3} className="mt-5">
                          <div className="d-flex">
                            <label htmlFor="">Tipo de telhado</label>
                            <i>
                              <IconWarningCircle
                                colorStroke="#0090A3"
                                colorFill="#fff"
                              />
                            </i>
                          </div>
                          <select
                            className="form-control"
                            onChange={e =>
                              setUserSelectRoofType(e.target.value)
                            }
                          >
                            {roofsTypes?.map(item => (
                              <option key={item.index} value={item.index}>
                                {item?.roof_name}
                              </option>
                            ))}
                          </select>
                        </Col>
                        <Col md={3} className="mt-5">
                          <div className="d-flex">
                            <label htmlFor="">Valor Kwh</label>
                            <i>
                              <IconWarningCircle
                                colorStroke="#0090A3"
                                colorFill="#fff"
                              />
                            </i>
                          </div>
                          <Typeahead
                            name="kwh_price"
                            id="kwh_price"
                            onBlur={handleSelectKhw}
                            onFocus={() => setNameKwhPrice([])}
                            allowNew
                            options={tariff}
                            labelKey="tarifa"
                            newSelectionPrefix="Adicionar nova tarifa: "
                            renderMenuItemChildren={item => (
                              <option
                                style={{
                                  paddingInline: "20px",
                                  paddingBlock: "5px",
                                }}
                                value={nameKwhPrice}
                              >
                                {item?.tarifa +
                                  " - " +
                                  item?.concessionaria.substring(0, 15) +
                                  "..."}
                              </option>
                            )}
                          />
                          <p className="description-input">
                            * Dados estimados pela aneel
                          </p>
                        </Col>
                        <Col md={3} className="mt-5">
                          <div className="d-flex">
                            <label htmlFor="">Perdas do Sistema (%)</label>
                            <i>
                              <IconWarningCircle
                                colorStroke="#0090A3"
                                colorFill="#fff"
                              />
                            </i>
                          </div>
                          <Input
                            name="system_losses_in_percentage"
                            id="kwh_price"
                            value={systemLosses}
                            maxlength={6}
                            onChange={e =>
                              setSystemLosses(
                                formatValueToPercentage(e.target.value)
                              )
                            }
                          />{" "}
                          <p className="description-input">
                            * A critério do integrador
                          </p>
                        </Col>
                        <Col md={3} className="mt-5">
                          <div className="d-flex">
                            <label htmlFor="">Irradiaçāo</label>
                            <i>
                              <IconWarningCircle
                                colorStroke="#0090A3"
                                colorFill="#fff"
                              />
                            </i>
                          </div>
                          <select
                            onChange={e =>
                              setUserSelectIrradiationType(e.target.value)
                            }
                          >
                            {irradiationTypes?.map((item, index) => (
                              <option key={index} value={item?.name}>
                                {item?.name}
                              </option>
                            ))}
                          </select>
                          <p className="description-input">
                            * Dados fornecidos pela LABREN
                          </p>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </section>
                <div className="mt-3 mb-4">
                  <Separator color="#E7E7E7" />
                </div>
                <section id="section-2">
                  <div className="container-sections">
                    <div className="section-width d-flex  flex-column mb-3">
                      <h2 className="main-title mb-3">
                        Consumo do Seu Cliente:
                      </h2>
                      <div id="selector-consumption" className="cursor-pointer">
                        <div
                          id="mark-choice-consumption"
                          className="position-relative"
                          onClick={() => changeAverageConsumptionOrKitChoise()}
                        >
                          <div
                            className={`position-absolute mark-choice-consumption-span mark-choice-consumption-span-${
                              averageConsumption == 0 ? "left" : "right"
                            }`}
                          >
                            <span />
                          </div>
                        </div>
                        <div
                          className="d-flex j-c-center"
                          onClick={() => changeAverageConsumptionOrKitChoise()}
                        >
                          <label
                            className={`m-0 px-3 cursor-pointer ${
                              averageConsumption == 0
                                ? "active-average-consumption"
                                : ""
                            }`}
                            htmlFor=""
                          >
                            Média de Consumo
                          </label>
                          <label
                            className={`m-0 px-3 cursor-pointer ${
                              averageConsumption == 1
                                ? "active-average-consumption"
                                : ""
                            }`}
                            htmlFor=""
                          >
                            Consumo Mensal
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="section-width d-flex  flex-column mb-3">
                      <h2 className="main-title mb-3">Unidade de medida:</h2>
                      <div id="selector-consumption" className="cursor-pointer">
                        <div
                          id="mark-choice-consumption"
                          className="position-relative"
                          onClick={() => changeKwpOrReais()}
                        >
                          <div
                            className={`position-absolute mark-choice-consumption-span mark-choice-consumption-span-${
                              KWhOrReais == 0 ? "left" : "right"
                            }`}
                          >
                            <span />
                          </div>
                        </div>
                        <div
                          className="d-flex j-c-center"
                          onClick={() => changeKwpOrReais()}
                        >
                          <label
                            className={`m-0 px-3 cursor-pointer ${
                              !KWhOrReais ? "active-average-consumption" : ""
                            }`}
                            htmlFor=""
                          >
                            Valor em R$
                          </label>
                          <label
                            className={`m-0 px-3 cursor-pointer ${
                              KWhOrReais ? "active-average-consumption" : ""
                            }`}
                            htmlFor=""
                          >
                            Valor em KWh
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div id="monthly-consumption">
                    {averageConsumption == 1 ? (
                      <Row className="h-100">
                        <Col md={11} className="mx-auto">
                          <Row>
                            {averageConsumptionMonthlyAdded?.map(
                              (item, index) => (
                                <Col md={2} key={index} className="mt-5">
                                  <div className="position-relative">
                                    <div className="d-flex">
                                      <label htmlFor="">{item.name}:</label>
                                      <div>
                                        <IconWarningCircle
                                          colorStroke="#0090A3"
                                          colorFill="#fff"
                                        />
                                      </div>
                                    </div>
                                    <div className="position-relative">
                                      <div className="input-cifrao">
                                        {!KWhOrReais ? "R$" : "KWh"}
                                      </div>
                                      <Input
                                        style={{
                                          border: `1px solid ${
                                            item.value == "0,00" ||
                                            item.value == "0,00"
                                              ? "#FF601A"
                                              : "#d2d2d2"
                                          }`,
                                        }}
                                        className="input-padding"
                                        key={index}
                                        name={item.name}
                                        id={`${item.name}`}
                                        value={item.value}
                                        onChange={e =>
                                          handleChangeAveragesConsumptions(
                                            e,
                                            item.name,
                                            averageConsumptionMonthlyAdded,
                                            setAverageConsumptionMonthlyAdded
                                          )
                                        }
                                      />
                                    </div>
                                  </div>
                                </Col>
                              )
                            )}
                          </Row>
                          <div className="w-100 d-flex justify-content-end mt-5">
                            <button
                              type="button"
                              onClick={() => handleCalcAvarageConsumtion()}
                              disabled={!enableButtonCalc}
                              style={{ maxWidth: 220 }}
                              className="button text-uppercase"
                            >
                              <span className="me-1">Calcular</span>
                              <i>
                                <MagnifyingGlassComponent colorStroke="#fff" />
                              </i>
                            </button>
                          </div>
                        </Col>
                      </Row>
                    ) : (
                      <Row className="h-100">
                        <Col
                          md={11}
                          className="mx-auto position-relative h-100"
                        >
                          <label htmlFor="">Gasto Médio:</label>
                          <Row>
                            {averageConsumptionAdded.map((item, index) => (
                              <Col
                                md={3}
                                key={index}
                                className="center-mobile d-flex"
                              >
                                <div className="d-flex mt-2">
                                  <span className="index-display d-flex j-c-center m-0">
                                    {index + 1}
                                  </span>
                                </div>
                                <div className="position-relative">
                                  <div className="input-cifrao">
                                    {!KWhOrReais ? "R$" : "KWh"}
                                  </div>
                                  <Input
                                    key={index}
                                    name={item.name}
                                    value={item.value}
                                    id={`${item.name}`}
                                    onChange={e =>
                                      handleChangeAveragesConsumptions(
                                        e,
                                        item.name,
                                        averageConsumptionAdded,
                                        setAverageConsumptionAdded
                                      )
                                    }
                                    className="mb-5 input-padding"
                                  />
                                </div>
                                {averageConsumptionAdded.length != 1 ? (
                                  <div
                                    className="d-flex mt-2"
                                    style={{ marginLeft: -40, zIndex: 10 }}
                                  >
                                    <span
                                      onClick={() => {
                                        handleRemoveAverageConsumptionInput(
                                          index
                                        );
                                      }}
                                      className="index-display index-display-no-bg d-flex j-c-center  cursor-pointer m-0"
                                    >
                                      <IconTrash width={20} height={20} />
                                    </span>
                                  </div>
                                ) : null}
                              </Col>
                            ))}
                            <Col md={3} className="d-flex center-mobile">
                              <div className="d-flex j-c-center">
                                <span className="index-display index-display-no-bg d-flex j-c-center m-0"></span>
                              </div>
                              <button
                                className={`d-flex a-i-center j-c-center main-solar-bg-light-bordered ${
                                  averageConsumptionAdded.length == 11
                                    ? "opacity-3 button-disabled"
                                    : ""
                                }`}
                                style={{ maxWidth: 199 }}
                                type="button"
                                disabled={averageConsumptionAdded.length == 11}
                                onClick={() =>
                                  handleAddAverageConsumptionInput()
                                }
                              >
                                <p>Adicionar</p>
                                <IconPlus
                                  colorStroke="#FF601A"
                                  width={20}
                                  height={20}
                                />
                              </button>
                            </Col>
                          </Row>
                          <div className="container-button-cal">
                            <button
                              type="button"
                              disabled={
                                averageConsumptionAdded[0].value == "0,00" ||
                                averageConsumptionAdded[0].value == "" ||
                                mapCenter.lat == null
                              }
                              onClick={() => handleCalcAvarageConsumtion()}
                              style={{ maxWidth: 250 }}
                              className="text-uppercase"
                            >
                              <span className="me-1">Calcular</span>
                              <i>
                                <MagnifyingGlassComponent colorStroke="#fff" />
                              </i>
                            </button>
                          </div>
                        </Col>
                      </Row>
                    )}
                  </div>
                </section>
              </Card>
              {showCalcResults ? (
                <>
                  <Card className="solar-shadow p-5 mt-5">
                    <section id="section-3">
                      <h2 className="main-title">Dimensionamento Estimado:</h2>
                      <Row>
                        <Col md={11} className="mx-auto">
                          {loadingIcons ? (
                            <div className="d-flex justify-content-center">
                              <Spinner style={{ color: "#FF601A" }} />
                            </div>
                          ) : (
                            <Row>
                              {sizingAfterCalculate?.map((item, index) => (
                                <Col md={3} key={index} className="mt-5">
                                  <DataWithPictures
                                    icon={sizingAfterCalculateIcons[index].icon}
                                    value={item}
                                    size={sizingAfterCalculateLabel[1][index]}
                                    description={`${sizingAfterCalculateLabel[0][index]}`}
                                    stringColor="#3F3F3F"
                                    stringSize={20}
                                    stringWeight={600}
                                  />
                                </Col>
                              ))}
                            </Row>
                          )}
                        </Col>
                      </Row>
                    </section>
                  </Card>
                  <Card id="sentinel" className="solar-shadow p-5 mt-5">
                    <section id="section-4">
                      <h2 className="main-title">
                        {/* Você prefere começar um orçamento de qual forma?: */}
                      </h2>
                      <Row>
                        <Col md={11} className="mx-auto">
                          <div className="d-flex j-c-center my-5 buttons-mobile">
                            <button
                              onClick={() =>
                                changeAverageConsumptionOrKitChoise(
                                  "kitChoise",
                                  0
                                )
                              }
                              type="button"
                              className={`mx-4 text-uppercase button-width-mobile ${
                                kitChoise == 0 ? "active-default" : "inactive"
                              }`}
                            >
                              <span className="me-1">kit pronto</span>
                              <IconPacked colorStroke="#fff" />
                            </button>

                            <button
                              id="button-1"
                              type="button"
                              onClick={() =>
                                changeAverageConsumptionOrKitChoise(
                                  "kitChoise",
                                  1
                                )
                              }
                              className={`text-uppercase position-relative button-width-mobile ${
                                kitChoise == 1 ? "active-yellow" : "inactive"
                              }`}
                            >
                              {" "}
                              <span className="me-1">Kit personalizado</span>
                              <i>
                                <IconTote colorStroke="#fff" />
                              </i>
                              <div
                                id="icon-coming-soon"
                                className="position-absolute"
                              >
                                {/* <IconComingSoon width="100%" height="100%" /> */}
                              </div>
                            </button>
                          </div>
                          <Separator color="#E7E7E7" />
                          <h2
                            className="main-title text-captalize mt-3 f-w-700"
                            style={{
                              color: kitChoise == 0 ? "#FF601A" : "#f4b200",
                            }}
                          >
                            {kitChoise == 0
                              ? "Kit pronto"
                              : "kit personalizado"}
                          </h2>
                          <div className="my-3">
                            <p className="f-w-400">
                              {/* Para começar um novo orçamento coloque o: */}
                              Para seu controle e follow-up, preencha os dados
                              básicos do seu cliente
                            </p>
                          </div>
                          <Row>
                            <Col md={12}>
                              <label htmlFor="" className="f-w-600">
                                Nome do Cliente
                              </label>
                              <div>
                                <Input
                                  name="client_name"
                                  id="client_name"
                                  value={clientName}
                                  onChange={e => setClientName(e.target.value)}
                                  required
                                />
                              </div>
                            </Col>
                            <Col md={8} className="mt-3">
                              <label htmlFor="" className="f-w-600">
                                E-mail do Cliente
                              </label>
                              <div>
                                <Input
                                  name="client_email"
                                  id="client_email"
                                  value={clientEmail}
                                  onChange={e => setClientEmail(e.target.value)}
                                  required
                                />
                              </div>
                            </Col>
                            <Col md={4} className="mt-3">
                              <label htmlFor="" className="f-w-600">
                                Telefone do Cliente
                              </label>
                              <div>
                                <Input
                                  name="client_phone"
                                  id="client_phone"
                                  value={clientPhone}
                                  onChange={e =>
                                    setClientPhone(formatValueToPhone(e))
                                  }
                                  required
                                />
                              </div>
                            </Col>
                          </Row>
                          <div className="w-100 d-flex justify-content-end mt-5">
                            <button
                              type="button"
                              style={{
                                maxWidth: 220,
                              }}
                              className="text-uppercase"
                              disabled={showSpinner}
                              onClick={handleSaveLead}
                            >
                              <span
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  width: "100%",
                                  height: "100%",
                                }}
                              >
                                {showSpinner ? (
                                  <Spinner size="sm" />
                                ) : (
                                  "Próximo"
                                )}
                              </span>
                            </button>
                          </div>
                        </Col>
                      </Row>
                    </section>
                  </Card>
                </>
              ) : null}
            </Form>
          )}
        </Formik>
      </Container>
    </>
  );
}
