import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getProductDetails, addToCart } from "../../../store/actions";
import { toast } from "react-toastify";
import { formato } from "../../../utils";
import {
  CardBody,
  Col,
  Row,
  Card,
  FormGroup,
  Label,
  Input,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
  Table,
} from "reactstrap";

import Availability1 from "../../../assets/images/solar-availability-1.png";
import Availability2 from "../../../assets/images/solar-availability-2.png";
import Availability3 from "../../../assets/images/solar-availability-3.png";

import ImagemSolar from "../../assets/images/pngs/SolarPanel.png";
import LightningKit from "../../assets/images/icons/lightning-kit";
import ModalAdias from "../../../components/Modal";
import { useProfile } from "../../../hooks";
import { filiais } from "../../../utils/filiais";

const FindedItem = ({ produto }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [filialAtiva, setFilialAtiva] = useState(0);
  const [filialSelecionada, setFilialSelecionada] = useState(0);
  const { userProfile, setUserProfile } = useProfile();
  const [checkProfile, setCheckProfile] = useState(false);
  const dispatch = useDispatch();
  const [precoVenda, setPrecoVenda] = useState(
    Number(produto.PRCVENDA.STRETPRCFILADIAS[filialAtiva].PRCVENDA)
  );
  const { findedItems, addedItems, vendedor } = useSelector(state => {
    return {
      findedItems: state.Order.newOrder.newOrder.findedItems,
      addedItems: state.Order.newOrder.newOrder.addedItems,
      vendedor: state.Order.newOrder.newOrder.vendedor,
    };
  });
  const item = findedItems[produto.CODPRODUTO]
    ? findedItems[produto.CODPRODUTO]
    : {};
  const descMax = Number(vendedor.VENDEDOR.PERCDESCONTO) / 100;
  const valTabela = Number(
    produto.PRCVENDA.STRETPRCFILADIAS[filialAtiva].PRCVENDA
  );
  const valMin = valTabela - valTabela * descMax;
  const valMax =
    Number(vendedor.VENDEDOR.TEMRT) == 1
      ? Number(produto.PRCVENDA.STRETPRCFILADIAS[filialAtiva].PRCVENDA)
      : valTabela * 1.5;
  const [varPre, setVarPre] = useState(
    Number(produto.PRCVENDA.STRETPRCFILADIAS[filialAtiva].PRCVENDA)
  );

  useEffect(() => {
    setVarPre(Number(produto.PRCVENDA.STRETPRCFILADIAS[filialAtiva].PRCVENDA));
  }, [produto]);

  useEffect(() => {
    canOrCanNot();
  }, []);

  var profileTrueOrFalse = false;
  function canOrCanNot() {
    userProfile.claims.groups.map(value => {
      if (value === 8 || value === 9 || (value >= 14 && value <= 20)) {
        setCheckProfile((profileTrueOrFalse = true));
      }
    });
  }

  const addToCartBtn = (filialSelecionada, filialAtiva) => {
    const result = addedItems.find(i => i.cod === produto.CODPRODUTO);

    if (
      produto.PRDDISPS.STRETPRDFILADIAS[filialAtiva].ESTOQUE >= 3 &&
      produto.PRDDISPS.STRETPRDFILADIAS[filialAtiva].ESTOQUE <= 19
    ) {
      if (checkProfile) {
        return toast.info("Entrar em contato com o consultor", {
          theme: "colored",
        });
      }
    }
    if (checkProfile) {
      if (
        produto.PRDDISPS.STRETPRDFILADIAS[filialAtiva].ESTOQUE >= 3 &&
        produto.PRDDISPS.STRETPRDFILADIAS[filialAtiva].ESTOQUE <= 19
      ) {
        return;
      }
    }

    if (!result) {
      let data = {
        cod: produto.CODPRODUTO,
        produto: {
          ...produto,
          CODFILIAL: filialSelecionada,
          ITEM: "",
        },
        filial: filialAtiva,
        precoVenda: precoVenda,
      };
      toast.success(`${produto.DESCRICAO} adicionado ao carrinho.`, {
        theme: "colored",
      });
      dispatch(addToCart(data));
    } else {
      toast.warning(`${produto.DESCRICAO} já consta no carrinho.`, {
        theme: "colored",
      });
    }
  };

  useEffect(() => {
    if (!findedItems[produto.CODPRODUTO]) {
      dispatch(getProductDetails(produto.CODPRODUTO));
    }
  }, [dispatch]);

  /* function getCodFab() {
    let val = Object.values(item.atributosEspeciais);
    let selo1 = val.find(i => i.Codattr == "304");
    let selo2 = val.find(i => i.Codattr == "305");
    if (!(selo1 && selo2)) {
      return false;
    } else {
      return (selo1?.Valor || "") + " / " + (selo2.Valor || "");
    } 
  }*/

  /* function getSeloA() {
        let val = Object.values(item.atributosEspeciais);
    let selo1 = val.find(i => i.Codattr == "188");
    if (selo1) {
      return selo1.Valor;
    } else {
      return false;
    } 
  }*/

  return (
    <React.Fragment>
      <Card
        className="mt-4"
        /*   hidden={
          ocultarSemEstoque &&
          produto.PRDDISPS.STRETPRDFILADIAS[filialAtiva].ESTOQUE < 1
        } */
      >
        <CardBody>
          <Row className="d-flex align-items-center">
            <Col md={1}>
              {findedItems[produto?.CODPRODUTO]?.fotos && (
                <img
                  src={findedItems[produto?.CODPRODUTO]?.fotos[0]}
                  className="parceiro-input"
                />
              )}
            </Col>

            <Col md={ImagemSolar ? 3 : 4}>
              <h5 style={{ fontSize: 16, fontWeight: 500 }}>
                {produto.DESCRICAO}
              </h5>
              <Row>
                <Col md={6} className="mt-2 d-flex align-items-center">
                  <div>
                    <p className="text-muted mb-0" style={{ fontSize: 11 }}>
                      Cód. Fabricante: {/*  {getCodFab() || ""}  */} NULL
                    </p>
                    <p className="text-muted mb-0" style={{ fontSize: 11 }}>
                      SKU: {produto.CODPRODUTO}
                    </p>
                  </div>
                </Col>
                <Col
                  md={6}
                  className="mt-2"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                  }}
                >
                  <LightningKit />
                  <span
                    style={{
                      color: "#762B00",
                      fontWeight: "bold",
                      fontSize: 12,
                    }}
                  >
                    {(precoVenda / produto.KWP).toLocaleString(
                      "pt-br",
                      formato
                    )}
                  </span>
                  <span style={{ color: "#762B00", fontSize: 12 }}>R$/KWp</span>
                </Col>
              </Row>
            </Col>
            <Col md={2}>
              <p className="align-center">Preço de Venda</p>
              <div className="campo-valores">
                <Input
                  className="mb-0 mt-4 pre-venda"
                  value={varPre.toLocaleString("pt-br", formato)}
                  onChange={e => {
                    setVarPre(e.target.value);
                  }}
                  onBlur={() => {
                    let val =
                      String(varPre)
                        .replace(/[^0-9,]*/g, "")
                        .replace(",", ".") || 0;
                    if (Number(val) >= valMin && Number(val) <= valMax) {
                      setPrecoVenda(Number(val));
                    } else {
                      setVarPre(Number(precoVenda));
                      toast.warning(
                        `O preço tabela não autorizado. Deve ser maior que ${valMin.toLocaleString(
                          "pt-br",
                          formato
                        )} e menor que ${valMax.toLocaleString(
                          "pt-br",
                          formato
                        )}`,
                        { theme: "colored" }
                      );
                    }
                  }}
                />
              </div>
            </Col>
            <Col md={2}>
              <p className="align-center">Valor Total</p>
              <div className="campo-valores">
                <p className="mb-0 val-total">
                  {Number(
                    produto.PRCVENDA.STRETPRCFILADIAS[filialAtiva].PRCVENDA
                  ).toLocaleString("pt-br", formato)}
                </p>
              </div>
            </Col>
            <Col md={2}>
              <p className="align-center">Disponibilidade</p>
              <div className="disp-text">
                {produto.PRDDISPS.STRETPRDFILADIAS[filialAtiva].ESTOQUE >= 1 ? (
                  <p className="mb-0" style={{ color: "#FF601A" }}>
                    <span className="h3" style={{ color: "#FF601A" }}>
                      <img className="mb-2" src={Availability1}></img>
                    </span>{" "}
                    <br />
                    Disponível
                  </p>
                ) : (
                  <p className="mb-0" style={{ color: "#8B0000" }}>
                    <span className="h3" style={{ color: "#8B0000" }}>
                      <img className="mb-2" src={Availability3}></img>
                    </span>{" "}
                    <br />
                    Sem Estoque
                  </p>
                )}
              </div>
            </Col>
            <Col md={2}>
              <Button
                className="but-modal"
                onClick={() => setIsModalOpen(!isModalOpen)}
              >
                Ver detalhes
              </Button>
              <div />
              {produto.PRDDISPS.STRETPRDFILADIAS[filialAtiva].ESTOQUE >= 1 && (
                <Button
                  className="mt-2 but-add"
                  onClick={() => addToCartBtn(filialSelecionada, filialAtiva)}
                >
                  Adicionar
                </Button>
              )}
            </Col>
          </Row>
          <hr className="mt-4 mb-4" />
          <Row onChange={e => setFilialAtiva(e.target.ariaLabel)}>
            <Col md={3}>
              <p style={{ fontWeight: 500 }}>Disponível nas Filiais:</p>
            </Col>
            {produto.PRDDISPS.STRETPRDFILADIAS.map((value, index) => (
              <Col md={3} key={index}>
                <FormGroup check>
                  <Input
                    type="radio"
                    value={value.CODFILPRD}
                    aria-label={index}
                    name={`filial-${produto.CODPRODUTO}`}
                    checked={index == filialAtiva}
                    className="check-filial"
                    onChange={() => setFilialSelecionada(value.CODFILPRD)}
                  />
                  <Label check className="text-indigo ocultare">
                    {filiais[Number(value.CODFILPRD)]}
                    <br />
                    {value.ESTOQUE >= 3 && value?.PRAZOCALC && (
                      <small
                        className="text-muted mb-0"
                        style={{ fontSize: 11 }}
                      >
                        Entrega em até {value.PRAZOCALC} dias úteis
                      </small>
                    )}
                  </Label>
                </FormGroup>
              </Col>
            ))}
          </Row>
        </CardBody>
      </Card>

      <ModalAdias
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        produto={produto}
        produtoDetails={item}
      />

      <div />
    </React.Fragment>
  );
};

export default FindedItem;
