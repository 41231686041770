import React from "react";
import { Row, Col, Card, CardBody } from "reactstrap";
import AmbientObject from "./AmbientObject";

const Ambients = ({ ambients }) => {
  const [allAmbients, setAllAmbients] = ambients;
  return (
    <>
      <Row>
        <Col>
          <Card>
            <CardBody className="p-5">
              <div>
                <div>
                  <div className="d-flex list-type-none text-center p-0 justify-content-center">
                    <div className="text-indigo " style={{ width: "16%" }}>
                      Modelo
                    </div>
                    <div className="text-indigo" style={{ width: "16%" }}>
                      Categoria
                    </div>
                    <div className="text-indigo" style={{ width: "16%" }}>
                      Ciclo
                    </div>
                    <div className="text-indigo" style={{ width: "16%" }}>
                      Capacidade
                    </div>
                    <div className="text-indigo" style={{ width: "16%" }}>
                      Voltagem
                    </div>
                    <div className="text-indigo" style={{ width: "16%" }}>
                      Ambiente
                    </div>
                  </div>
                </div>
                <div className="mt-3">
                  {allAmbients &&
                    allAmbients.map((item, key) => (
                      <AmbientObject
                        item={item}
                        key={key}
                        ambients={[allAmbients, setAllAmbients]}
                      />
                    ))}
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default Ambients;
