import React, { useEffect, useState } from "react";
import { Loader } from "react-bootstrap-typeahead";
import { useDispatch, useSelector } from "react-redux";
import { Alert, Button, Col, Container, Row, Table } from "reactstrap";
import AddCustomFieldModal from "../modalAddCustomField";
import { getCustomFields } from "../../../../store/actions";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { HeadCol } from "../../../../components/Columns";
import { Name, FieldFormat, Description } from "./Columns";
import CustomTable from "../../../../components/CustomTable";

const CustomFields = () => {

  const { manufacturerId } = useParams();
  
  const dispatch = useDispatch();

  const { customFields, loadingList, errorList, customFieldCreated } = useSelector(state => ({
    customFields: state.CustomField.list.customFields,
    loadingList: state.CustomField.list.loading,
    errorList: state.CustomField.list.error,
    customFieldCreated: state.CustomField.create.customFieldCreated
  }));

  const [ addCustomFieldModalIsOpen, setAddCustomFieldModalIsOpen ] = useState();

  const toggleAddCustomFieldModal = () => {
    setAddCustomFieldModalIsOpen(!addCustomFieldModalIsOpen);
  }

  useEffect(() => {
    if (errorList) {
      toast.error('Erro ao buscar campos personalizados')
    }
  }, [errorList]);
  useEffect(() => {
    if (manufacturerId) {
      dispatch(getCustomFields(manufacturerId));
    }
  }, [manufacturerId]);
  useEffect(() => {
    if (customFieldCreated) {
      dispatch(getCustomFields(manufacturerId));
    }
  }, [customFieldCreated])
  const columns = [
    {
      Header: <HeadCol label="Nome do campo" />,
      Cell: function colRenderer(props) {
        return <Name customFields={props.original.custom_field} />;
      }
    },
    {
      Header: <HeadCol label="Format" />,
      Cell: function colRenderer(props) {
        return <FieldFormat customFields={props.original.custom_field} />;
      }
    },
    {
      Header: <HeadCol label="Descrição" />,
      Cell: function colRenderer(props) {
        return <Description customFields={props.original.custom_field} />;
      }
    },
  ]
  return (
    <React.Fragment>
      <div>
        <Container>
          <Row className="d-flex justify-content-between align-items-end">
            <Col>
              <h5>
                <i className="fa fa-gift"></i> Campos adicionais:
              </h5>
            </Col>
            <Col>
              <Button 
                style={{ color: 'white' }} 
                className="btn btn-success float-end mb-2"
                disabled={ loadingList }
                onClick={ toggleAddCustomFieldModal }
              >
                Criar novo campo
              </Button>
            </Col>
            <Col xs={12}>
              <div>
                { !loadingList ?
                  ( customFields && customFields.length > 0 ? (
                    <CustomTable
                      columns={columns}
                      data={customFields}
                      classNames="align-middle custom-table table-nowrap table-check"
                      selectable={false}
                      idField={"id"}
                    />
                    ): (
                      <Alert color="info">Nenhum campo personalizado encontrado</Alert>
                    )
                  ): (
                    <Loader />
                  )
                }
              </div>
            </Col>
          </Row>
        </Container>
        <AddCustomFieldModal
          isOpen={addCustomFieldModalIsOpen}
          toggle={toggleAddCustomFieldModal}
          manufacturerId={manufacturerId}
        />
      </div>
    </React.Fragment>
  );
}
export default CustomFields;