import React from "react";

const SideCard = ({ children, type, title }) => {
  let bgColor, borderColor, color;

  switch (type) {
    case "success":
      borderColor = "#27ba51";
      bgColor = "#abd1b6";
      break;
    case "warning":
      borderColor = "#07bc0c";
      bgColor = "#4cd964";
      break;
    case "danger":
      borderColor = "#07bc0c";
      bgColor = "#4cd964";
      break;
    case "info":
      borderColor = "#07bc0c";
      bgColor = "#4cd964";
      break;
    case "ar":
      borderColor = "#055160";
      bgColor = "#cff4fc";
      break;
    case "solar":
      borderColor = "#FF601A";
      bgColor = "#FFE0D3";
      break;
    default:
      borderColor = "";
      bgColor = "#86cf9a";
      break;
  }

  return (
    <div
      className="relative"
      style={{
        backgroundColor: bgColor,
        padding: "15px",
        borderLeft: `solid 5px ${borderColor}`,
      }}
    >
      <h3 style={{ marginBottom: "20px" }}>{title}</h3>
      {children}
    </div>
  );
};

export default SideCard;
