import { takeEvery, fork, put, all, call } from "redux-saga/effects";

// Login Redux States
import {
  EDIT_PROFILE,
  GET_PROFILE_DETAILS,
  UPDATE_PROFILE_DETAILS,
} from "./actionTypes";
import {
  profileSuccess,
  profileError,
  profileApiFail,
  profileApiSuccess,
} from "./actions";

//Include Both Helper File with needed methods
import { getFirebaseBackend } from "../../../helpers/firebase_helper";

import {
  getProfileDetails as getProfileDetailsApi,
  updateProfileDetails as updateProfileDetailsApi,
} from "../../../api/index";
import { toast } from "react-toastify";

const fireBaseBackend = getFirebaseBackend();

function* editProfile({ payload: { user } }) {
  try {
    if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
      const response = yield call(
        fireBaseBackend.editProfileAPI,
        user.username,
        user.idx
      );
      yield put(profileSuccess(response));
    }
  } catch (error) {
    yield put(profileError(error));
  }
}

function* getProfileDetails({ payload: filters }) {
  try {
    const response = yield call(getProfileDetailsApi, filters);
    yield put(profileApiSuccess(GET_PROFILE_DETAILS, response));
  } catch (error) {
    yield put(profileApiFail(GET_PROFILE_DETAILS, error));
  }
}

function* updateProfileDetails({ payload: data }) {
  try {
    const response = yield call(updateProfileDetailsApi, data);
    if (response.success) {
      toast.success(`Informações do usuário atualizadas com sucesso.`, {
        theme: "colored",
      });
    }
    yield put(profileApiSuccess(UPDATE_PROFILE_DETAILS, response));
  } catch (error) {
    yield put(profileApiFail(UPDATE_PROFILE_DETAILS, error));
  }
}

export function* watchProfileEdit() {
  yield takeEvery(EDIT_PROFILE, editProfile);
}
export function* watchGetProfileDetails() {
  yield takeEvery(GET_PROFILE_DETAILS, getProfileDetails);
}
export function* watchUpdateProfileDetails() {
  yield takeEvery(UPDATE_PROFILE_DETAILS, updateProfileDetails);
}

function* ProfileSaga() {
  yield all([
    fork(watchProfileEdit),
    fork(watchGetProfileDetails),
    fork(watchUpdateProfileDetails),
  ]);
}

export default ProfileSaga;
