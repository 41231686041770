import { API_FAIL, API_SUCCESS, CREATE_COLLABORATOR, DELETE_COLLABORATOR, GET_COLLABORATORS, UPDATE_COLLABORATOR } from "./actionTypes";

export const collaboratorApiSuccess = (actionType, data) => ({
  type: API_SUCCESS,
  payload: { actionType, data },
});

export const collaboratorApiFail = (actionType, error) => ({
  type: API_FAIL,
  payload: { actionType, error },
});

export const getCollaborators = () => ({
  type: GET_COLLABORATORS
});

export const createCollaborator = (data, history) => ({
  type: CREATE_COLLABORATOR,
  payload: { data, history },
});

export const updateCollaborator = (sellerId, data, history) => ({
  type: UPDATE_COLLABORATOR,
  payload: { sellerId, data, history },
});
