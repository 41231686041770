import { LeadsActionTypes } from "./actionTypes";

export const leadsApiSuccess = (actionType, data) => ({
  type: LeadsActionTypes.API_RESPONSE_SUCCESS,
  payload: { actionType, data },
});

export const leadsApiFail = (actionType, error) => ({
  type: LeadsActionTypes.API_RESPONSE_ERROR,
  payload: { actionType, error },
});

export const getLeadsList = filters => ({
  type: LeadsActionTypes.GET_LEADS_LIST,
  payload: filters,
});

export const getLeadDetails = id => ({
  type: LeadsActionTypes.GET_LEAD_DETAILS,
  payload: id,
});

export const updateLeadDetails = data => ({
  type: LeadsActionTypes.UPDATE_LEAD_DETAILS,
  payload: data,
});
