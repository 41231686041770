import React, { useState } from "react";
import { Row, Col, Container, Card, CardBody, Spinner } from "reactstrap";
import { Link, useHistory } from "react-router-dom";
import "./style.scss";
import Logo from "../../../assets/images/icons/arcondicionado.svg";
import LogoSimples from "../../../assets/images/logos/adias/solar_laranja.svg";
import logoSolar from "../../../assets/images/icons/painelsolar.svg";

import { chooseCompanyInitial } from "../../../utils";
import { useDispatch } from "react-redux";
import { SignOut } from "phosphor-react";

const ChooseCompany = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [loadingChooseCompany, setLoadingChooseCompany] = useState({
    loading: false,
    companyName: "",
  });

  const handleClick = system => {
    chooseCompanyInitial(system, history, dispatch, setLoadingChooseCompany);
  };

  return (
    <Container className="choose-company-center">
      <Row>
        <div className="logo-and-description">
          <div className="container-logo">
            <img src={LogoSimples} />
            <p>O que você deseja acessar ?</p>
          </div>
          <Link to="/logout" replace className="button-logout">
            <SignOut style={{ marginRight: 5 }} size={17} color="#bdbdbd" />{" "}
            Sair
          </Link>
        </div>
        <Col md={6}>
          <Card
            onClick={() =>
              !loadingChooseCompany.loading && handleClick("adias")
            }
          >
            <CardBody className="card-choose ar">
            <div className="content-card">
            <div className="border-animation">
                  <img src={Logo} className="logo-ar" />
                <p className="text-white">
                  {loadingChooseCompany.companyName == "adias" &&
                  loadingChooseCompany.loading ? (
                    <Spinner size="sm" />
                  ) : (
                    "Sistema Ar condicionado"
                  )}
                </p>
                </div>
                </div>
            </CardBody>
          </Card>
        </Col>
        <Col md={6}>
          <Card
            className="card-two"
            onClick={() =>
              !loadingChooseCompany.loading && handleClick("solar")
            }
          >
            <CardBody className="card-choose solar">
              <div className="content-card">
                <div className="border-animation">
                  <img src={logoSolar} />
                <p>
                  {" "}
                  {loadingChooseCompany.companyName == "solar" &&
                  loadingChooseCompany.loading ? (
                    <Spinner size="sm" />
                  ) : (
                    "Sistema Fotovoltaico"
                  )}
                </p>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default ChooseCompany;
