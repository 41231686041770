import { ManufacturerDetailsActionTypes } from "./actionTypes"
const INIT_STATE = {
  manufacturerDetails: [],
  loading: false,
  error: null
}
const ManufacturerDetails = (state = INIT_STATE, action) => {
  switch (action.type) {
    case ManufacturerDetailsActionTypes.API_RESPONSE_SUCCESS:
      switch (action.payload.actionType) {
        case ManufacturerDetailsActionTypes.GET_MANUFACTURER_DETAILS: 
          return {
            manufacturerDetails: action.payload.data,
            loading: false,
            error: null
          }
      }
    case ManufacturerDetailsActionTypes.API_RESPONSE_ERROR:
      switch (action.payload.actionType) {
        case ManufacturerDetailsActionTypes.GET_MANUFACTURER_DETAILS:
          return {
            manufacturerDetails: [],
            loading: false,
            error: action.payload
          }
      }
    
    case ManufacturerDetailsActionTypes.GET_MANUFACTURER_DETAILS:
      return {
        manufacturerDetails: [...state.manufacturerDetails],
        manufacturerDetails: [],
        error: null,
        loading: true
      }
    default: return state;
  }
};
export default ManufacturerDetails;