import React, { useState } from "react";
import { Card, CardBody, Row, Col, Form, Button } from "reactstrap";
import { mask } from "../../../utils/otherUtils";
import "../../../components/styles.css";
import "../../../pages/ConsultarOrcamento";
import { useSolar } from "../../../hooks";
import SearchIcon from "../../../components/icons/search";

const Filters = ({ onApplyFilters }) => {
  const [filters, setFilters] = useState({});
  const isSolar = useSolar();
  const onChangeFilters = (field, value) => {
    const modifiedFilters = { ...filters };
    modifiedFilters[field] = value;
    setFilters(modifiedFilters);
  };
  const onApply = () => {
    onApplyFilters(filters);
  };
  const onClear = () => {
    setFilters({});
  };
  return (
    <Card>
      <CardBody>
        <h5>
          <SearchIcon size={18} color={"#0F172A"}/> Pesquisar clientes
        </h5>
        <hr />
        <Form>
          <Row>
            <Col md={6}>
              <div className="mb-3">
                <label>Código</label>
                <input
                  type="text"
                  className="form-control"
                  id="customer[CODIGO]"
                  name="customer[CODIGO]"
                  placeholder="Código do cliente"
                  value={filters["customer[CODIGO]"] || ""}
                  onChange={e => {
                    onChangeFilters("customer[CODIGO]", e.target.value);
                  }}
                />
              </div>
            </Col>
            <Col md={6}>
              <div className="mb-3">
                <label>CPF/CNPJ</label>
                <input
                  type="text"
                  className="form-control"
                  name="customer[CPFCNPJ]"
                  id="customer[CPFCNPJ]"
                  placeholder="CPF/CNPJ do cliente"
                  value={filters["customer[CPFCNPJ]"] || ""}
                  onChange={e => {
                    onChangeFilters("customer[CPFCNPJ]", mask(e.target.value));
                  }}
                />
              </div>
            </Col>
            <Col md={6}>
              <div className="mb-3">
                <label>Nome</label>
                <input
                  type="text"
                  className="form-control"
                  id="customer[NOME]"
                  name="customer[NOME]"
                  placeholder="Nome do cliente"
                  value={filters["customer[NOME]"] || ""}
                  onChange={e => {
                    onChangeFilters("customer[NOME]", e.target.value);
                  }}
                />
              </div>
            </Col>
          </Row>
          <hr className="mt-2" />
          <div className="d-flex justify-content-end consultar-orcamento">
            <Button className={isSolar ? "me-2 botao-busca-solar" : "me-2 botao-busca"} onClick={onApply}>
              Pesquisar
            </Button>
            <Button className={isSolar ? "botao-limpar-solar" : "botao-limpar"} onClick={onClear}>
              Limpar Filtro
            </Button>
          </div>
        </Form>
      </CardBody>
    </Card>
  );
};

export default Filters;
