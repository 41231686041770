import React, { useEffect } from "react";
import { Card, CardBody, Spinner, Table } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { getPayments } from "../../store/order/action";
import { formatDate } from "../../utils";
import { useHistory } from "react-router-dom";

const PaymentReceived = ({ date }) => {
  const dispatch = useDispatch();
  const formato = {
    minimumFractionDigits: 2,
    style: "currency",
    currency: "BRL",
  };
  const history = useHistory();
  const { pagamentos } = useSelector(state => ({
    pagamentos: state.Order.pagamentos,
  }));

  useEffect(() => {
    dispatch(getPayments());
  }, [dispatch, location.pathname]);

  const analise = {
    APA: "CADASTRO APROVADO",
    APM: "CADASTRO APROVADO",
    AMA: "AGUARDANDO APROVAÇÃO (ATÉ 48 HORAS)",
    SUS: "CADASTRO REPROVADO",
    FRD: "CADASTRO REPROVADO",
    RPM: "REPROVADO (FALTA DE CONTATO)",
  };
  // COMECA A LOGICA AQUI
  function getStatus({
    processorID,
    processorCode,
    responseCode,
    responseMessage,
    clearsale_Status,
    processorMessage,
  }) {
    if (processorID == "pagseguro") {
      switch (processorCode) {
        case "1":
          return "Aguardando pagamento";
        case "2":
          return "Em Análise";
        case "3":
          if (responseCode == "0" && responseMessage == "CAPTURED")
            return "Paga";
          return "Paga (Sem capturar)";
        case "4":
          return "Disponível";
        case "5":
          return "Em Disputa";
        case "6":
          return "Devolvida";
        default:
          return "Cancelada";
      }
    }

    if (processorID == "POS") {
      if (responseMessage == "CAPTURED") return "Pagamento via Terminal";
      return "Cancelada";
    }

    switch (responseCode) {
      case "0":
        if (clearsale_Status == "APA" || clearsale_Status == "APM")
          return "Capturado";
        return "Em Analise";
      case "1":
        return `Não Autorizado - Código: ${processorMessage}`;
      case "1024":
        return `Dados Incorretos`;
      default:
        return "Erro";
    }
  }

  return (
    <div className="mt-5">
      <Card className="overflow-hidden table-card-border card-pagamentos-detalhes">
        <CardBody className="p-3">
          <div className="card-pagamentos">
            <h6>
              Pagamentos recebidos e processados para esse pedido. Pagamentos
              serão aceitos somente até {formatDate(date)}
            </h6>
          </div>
          {pagamentos.loading ? (
            <Spinner />
          ) : pagamentos.success &&
            (typeof pagamentos?.pagamentos == "object"
              ? [pagamentos?.pagamentos]
              : pagamentos?.pagamentos
            )?.length > 0 ? (
            <Table className="tabela-pagamento">
              <thead>
                <tr>
                  <th></th>
                  <th className="titulo-pagamentos">Chave</th>
                  <th className="titulo-pagamentos">Status</th>
                  <th className="titulo-pagamentos">Análise</th>
                  <th className="titulo-pagamentos">Data e Horário</th>
                  <th className="titulo-pagamentos">Parcelas</th>
                  <th className="titulo-pagamentos">Valor Total</th>
                  <th className="titulo-pagamentos">Boleto</th>
                </tr>
              </thead>
              <tbody>
                {(typeof pagamentos?.pagamentos == "object"
                  ? [pagamentos?.pagamentos]
                  : pagamentos?.pagamentos
                ).map(value => {
                  return (
                    <tr key={value.idpedido_pagamento}>
                      <td>
                        <i className="fas fa-thumbs-up"></i>
                      </td>
                      <td>{value.chave}</td>
                      <td>{getStatus(value)}</td>
                      <td>{analise[value.clearsale_Status]}</td>
                      <td>{value.datainsert}</td>
                      <td>{value.parcelas}x</td>
                      <td>
                        {Number(value.valor_tot).toLocaleString(
                          "pt-br",
                          formato
                        )}
                      </td>
                      <td>
                        {value?.link_boleto?.length > 0 ? (
                          <a
                            href={value.link_boleto}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <i className="fas fa-file-invoice-dollar"></i>
                          </a>
                        ) : (
                          "-"
                        )}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          ) : (
            <p className="mb-0">
              <span className="fw-bold">Busca Finalizada</span>
              <span className="text-muted ms-2">
                Nenhum pagamento a ser exibido.
              </span>
            </p>
          )}
        </CardBody>
      </Card>
    </div>
  );
};

export default PaymentReceived;
