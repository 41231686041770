import {
  PROFILE_ERROR,
  PROFILE_SUCCESS,
  EDIT_PROFILE,
  RESET_PROFILE_FLAG,
  GET_PROFILE_DETAILS,
  PROFILE_SUCCESS_API,
  PROFILE_FAIL_API,
  UPDATE_PROFILE_DETAILS,
} from "./actionTypes";

export const editProfile = user => {
  return {
    type: EDIT_PROFILE,
    payload: { user },
  };
};

export const profileSuccess = msg => {
  return {
    type: PROFILE_SUCCESS,
    payload: msg,
  };
};

export const profileError = error => {
  return {
    type: PROFILE_ERROR,
    payload: error,
  };
};

export const resetProfileFlag = () => {
  return {
    type: RESET_PROFILE_FLAG,
  };
};

export const profileApiSuccess = (actionType, data) => ({
  type: PROFILE_SUCCESS_API,
  payload: { actionType, data },
});

export const profileApiFail = (actionType, data) => ({
  type: PROFILE_FAIL_API,
  payload: { actionType, data },
});

export const getProfileDetailsList = filters => ({
  type: GET_PROFILE_DETAILS,
  payload: filters,
});

export const updateProfileDetailsList = data => ({
  type: UPDATE_PROFILE_DETAILS,
  payload: data,
});
