import React, { useState, useEffect } from "react";
import Flatpickr from "react-flatpickr";
import { useDispatch } from "react-redux";
import {
  Row,
  Col,
  InputGroupText,
  InputGroup,
  Input,
  Button,
} from "reactstrap";
import { Portuguese } from "flatpickr/dist/l10n/pt.js";
import { mask } from "../../utils";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import { getVendedor as getVendedorApi } from "../../api/index";
import "flatpickr/dist/themes/material_blue.css";
import { listBudgets } from "../../store/budget/action";
import { useSolar } from "../../hooks";
import { toast } from "react-toastify";

const OrcamentoFilter = ({ isOpen }) => {
  const dispatch = useDispatch();
  const [parceiros, setParceiros] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [vendedores, setVendedores] = useState([]);
  const filterBy = () => true;
  const isSolar = useSolar();

  const [filters, setFilters] = useState({
    empresa: isSolar ? "02" : "01",

    vend_codigo: "",
    nome_cliente: "",
    cli_cpfcnpj: "",
    dateI: "",
    dateF: "",
    num_pedido: "",
  });

  const changeVendedores = inputValue => {
    setIsLoading(true);
    setVendedores([]);

    getVendedorApi(inputValue)
      .then(res => {
        let data = [];
        res.map(value => {
          data.push({
            codigo: value.CODIGO,
            nome: value.NOME,
            label: `${value.CODIGO} - ${value.NREDUZ.trim()}`,
            nreduz: value.NREDUZ,
            cnpj: value.CPFCNPJ,
          });
        });

        setVendedores(data);
        setIsLoading(false);
      })
      .catch(console.log);
  };

  const buscaOrcamentos = () => {
    if (
      filters.vend_codigo == "" &&
      filters.nome_cliente == "" &&
      filters.cli_cpfcnpj == "" &&
      filters.dateI == "" &&
      filters.dateF == "" &&
      filters.num_pedido == ""
    )
      return toast.info(
        `Não é possível buscar todos os orçamentos. Utilize pelo menos um filtro.`,
        { theme: "colored" }
      );

    dispatch(listBudgets(filters));
  };

  const limparFiltros = () => {
    setFilters({
      empresa: isSolar ? "02" : "01",
      cod_filial: "02",
      vend_codigo: "",
      nome_cliente: "",
      cli_cpfcnpj: "",
      dateI: "",
      dateF: "",
      num_pedido: "",
    });
    setParceiros([]);
  };

  useEffect(() => {
    if (parceiros.length > 0)
      setFilters({ ...filters, vend_codigo: parceiros[0].codigo });
  }, [parceiros]);

  return (
    <Row className="card-principal" hidden={isOpen}>
      <Row className="engloba-vendedor-cliente">
        <Col className="engloba-vendedor">
          <Row className="linha-titulo-vendedor">
            <h4 className="titulos">Vendedor:</h4>
          </Row>
          <Row className="engloba-vendedor-codigo">
            <Col className="busca-vendedor" md={8}>
              <InputGroup>
                <InputGroupText className="fundo-icone">
                  <i className="ph-magnifying-glass" />
                </InputGroupText>
                <AsyncTypeahead
                  id="parceiro"
                  className="titulo-input"
                  onChange={setParceiros}
                  options={vendedores}
                  placeholder="Buscar vendedor"
                  selected={parceiros}
                  minLength={3}
                  filterBy={filterBy}
                  onSearch={changeVendedores}
                  emptyLabel="Não foi encontrado nenhum vendedor"
                  promptText="Buscando vendedores"
                  searchText="Buscando vendedores"
                  isLoading={isLoading}
                  inputProps={{
                    id: "parceiro-selecionado",
                  }}
                  renderMenuItemChildren={option => (
                    <div>
                      {option.codigo} {option.nreduz}
                      <div>
                        <small>{option.nome}</small>
                      </div>
                    </div>
                  )}
                />
              </InputGroup>
            </Col>
            <Col className="codigo" md={4}>
              <Input
                id="codigo-vendedor"
                className="titulo-input color-card"
                placeholder="Código vendedor"
                value={parceiros[0]?.codigo || ""}
                disabled={true}
                readOnly
              />
            </Col>
          </Row>
        </Col>
        <Col className="engloba-nome-cliente">
          <Row className="linha-titulo-cliente">
            <h4 className="titulos">Nome do Cliente:</h4>
          </Row>
          <Col className="nome-cliente">
            <InputGroup>
              <InputGroupText className="fundo-icone">
                <i className="ph-user"></i>
              </InputGroupText>
              <Input
                id="nome-cliente"
                className="titulo-input"
                placeholder="Nome do cliente"
                onChange={e =>
                  setFilters({
                    ...filters,
                    [e.target.name]: e.target.value.toUpperCase(),
                  })
                }
                name="nome_cliente"
                value={filters.nome_cliente}
              />
            </InputGroup>
          </Col>
        </Col>
      </Row>
      <Row className="engloba-data-status">
        <Col className="engloba-data">
          <Row className="titulo-data">
            <h4 className="titulos">Data</h4>
          </Row>
          <Row className="engloba-inicio-fim">
            <Col className="data-inicio">
              <InputGroup>
                <InputGroupText className="fundo-icone">
                  <i className="ph-calendar-check"></i>
                </InputGroupText>
                <Flatpickr
                  id="data-inicio"
                  className="form-control   titulo-input"
                  placeholder="Data inicial"
                  options={{
                    altInput: true,
                    locale: Portuguese,
                    altFormat: "d F, Y",
                    dateFormat: "Ymd",
                  }}
                  value={filters.dateI}
                  onChange={([date]) =>
                    setFilters({
                      ...filters,
                      dateI: `${date.getFullYear()}${(date.getMonth() + 1)
                        .toString()
                        .padStart(2, "0")}${date
                        .getDate()
                        .toString()
                        .padStart(2, "0")}`,
                    })
                  }
                />
              </InputGroup>
            </Col>
            <Col className="data-fim">
              <InputGroup>
                <InputGroupText className="fundo-icone">
                  <i className="ph-calendar-check"></i>
                </InputGroupText>
                <Flatpickr
                  id="data-final"
                  className="form-control titulo-input"
                  placeholder="Data final"
                  options={{
                    altInput: true,
                    locale: Portuguese,
                    altFormat: "d F, Y",
                    dateFormat: "Ymd",
                  }}
                  readOnly={false}
                  disabled={false}
                  value={filters.dateF}
                  onChange={([date]) =>
                    setFilters({
                      ...filters,
                      dateF: `${date.getFullYear()}${(date.getMonth() + 1)
                        .toString()
                        .padStart(2, "0")}${date
                        .getDate()
                        .toString()
                        .padStart(2, "0")}`,
                    })
                  }
                />
              </InputGroup>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="engloba-código">
        <Col>
          <Row className="titulo-codigo">
            <h4 className="titulos">Código</h4>
            <hr />
          </Row>
          <Row className="engloba-inputs">
            <Col className="numero-pedido">
              <InputGroup>
                <InputGroupText className="fundo-icone">
                  <i className="fas fa-cubes"></i>
                </InputGroupText>
                <Input
                  id="codigo"
                  className="titulo-input"
                  placeholder="Número do orçamento"
                  onChange={e =>
                    setFilters({ ...filters, [e.target.name]: e.target.value })
                  }
                  name="num_pedido"
                  value={filters.num_pedido}
                />
              </InputGroup>
            </Col>
            <Col className="cpf-cnpj">
              <InputGroup>
                <InputGroupText className="fundo-icone">
                  <i className="ph-user"></i>
                </InputGroupText>
                <Input
                  id="cpf"
                  placeholder="000.000.000-00"
                  maxLength={18}
                  className="titulo-input"
                  onChange={e =>
                    setFilters({
                      ...filters,
                      [e.target.name]: e.target.value.replace(/[^0-9]+/g, ""),
                    })
                  }
                  name="cli_cpfcnpj"
                  value={mask(filters.cli_cpfcnpj)}
                />
              </InputGroup>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="linha-botao">
        <Col className="engloba-botoes md-12">
          <Button className="botao-limpar" onClick={() => limparFiltros()}>
            {" "}
            <i className="ph-eraser borracha"></i>Limpar
          </Button>
          <Button
            className="botao-busca"
            onClick={() => buscaOrcamentos()}
          >
            {" "}
            <i className="ph-magnifying-glass lupa" />
            Buscar Orçamentos
          </Button>
        </Col>
      </Row>
    </Row>
  );
};

export default OrcamentoFilter;
