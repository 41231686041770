import React, { useEffect, useState } from "react";

import { connect, useDispatch, useSelector } from "react-redux";

import { Container, CardBody, Card, Spinner } from "reactstrap";

import "./colaboradores.css";
import Breadcrumb from "../../components/Breadcrumb";
import DataTable from "react-data-table-component";
import { mask, telMask, validCep } from "../../utils";

import { Alert } from "@mui/material";
import { getCollaborators } from "../../store/actions";
import { useColors } from "../../hooks/useColors";
import { useProfile, useSolar } from "../../hooks";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { toast } from "react-toastify";
import { validCel } from "../CreateColaboradores/functions";

const Colaboradores = () => {
  const { collaborators, loading } = useSelector(state => ({
    collaborators: state.Collaborator.collaborators,
    loading: state.Collaborator.loading,
  }));

  const isSolar = useSolar();
  const { colorDefault } = useColors();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCollaborators());
  }, [dispatch]);

  const { userProfile } = useProfile();

  const paginationComponentOptions = {
    rowsPerPageText: "Resultados por página",
    rangeSeparatorText: "de",
    selectAllRowsItem: true,
    selectAllRowsItemText: "Todos",
  };

  const columns = [
    {
      name: "#CÓDIGO",
      selector: row => row.codigo,
    },
    {
      name: "CPF/CNPJ",
      selector: row => row.cpfcnpj,
    },
    {
      name: "Colaborador",
      selector: row => row.colaborador,
    },
    {
      name: "Celular",
      selector: row => row.celular,
    },
    {
      name: "Status",
      selector: row => row.status,
    },
  ];

  const history = useHistory();

  const handleClickNewCollaborator = () => {  
    history.push((isSolar ? "/solar" : "") + "/colaboradores/create");
  };

  return (
    <React.Fragment>
      <div className="page-content colaboradores">
        <Container>
          <h3 className="titulo-colaboradores">Colaboradores</h3>
          <Breadcrumb title="Página Inicial" breadcrumbItem="Cadastro" />

          <button
            style={{ backgroundColor: colorDefault }}
            className="botao-cadastro d-block"
            onClick={handleClickNewCollaborator}
            disabled={loading}
          >
            <i className="fas fa-plus icone-plus"></i>
            Cadastrar colaborador
          </button>

          <Card className="card-lista-colaboradores">
            <CardBody>
              <div className="engloba-h4-icone">
                <h4 className="titulo-card">
                  <i className="ph-user-plus icone-user-plus"></i> Lista de
                  colaboradores
                </h4>
              </div>
              <hr />
              {loading ? (
                <div className="loading">
                  <Spinner />
                </div>
              ) : collaborators?.length > 0 ? (
                <>
                  <DataTable
                    columns={columns}
                    data={collaborators.map((item, k) => {
                      return {
                        id: k,
                        codigo: item.CODIGO,
                        cpfcnpj: mask(item.CPFCNPJ),
                        colaborador: item.NOME,
                        celular: `${item.DDD_CEL} ${validCel(item.CELULAR)}`,
                        status:
                          item.A3_XBLQCLU == "S" ? (
                            <div
                              className="text-white"
                              style={{ padding: 8, borderRadius: 5, background: "#f3565d" }}
                            >
                              Bloqueado
                            </div>
                          ) : (
                            <div
                              className="text-white"
                              style={{
                                padding: 8,
                                borderRadius: 5,
                                background: "#45b6af",
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <i
                                style={{ marginRight: 3 }}
                                className=" ph-arrows-counter-clockwise-fill"
                              ></i>
                              Liberado
                            </div>
                          ),
                      }
                    }
                    )}
                    pagination
                    paginationComponentOptions={paginationComponentOptions}
                    onRowClicked={e =>
                      history.push((isSolar ? "/solar" : "") + `/colaboradores/${e.codigo}/edit`)
                    }
                    style={{ cursor: "pointer" }}
                  />
                </>
              ) : (
                <Alert severity="info">Nenhum dado econtrado</Alert>
              )}
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Colaboradores;
