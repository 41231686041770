import styled from "styled-components";

export const Container = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;
  top: 0;
  bottom: 0;
  left: 0;

  transition: all 0.2s linear;
  width: ${props => (props.openSidebar ? `${250}px` : `${70}px`)};
  box-shadow: 0 2px 4px rgb(15 34 58 / 12%);
  z-index: 99;

  @media print {
    /* Ocultar o componente durante a impressão */
    display: none;
  }

  @media (max-width: 1000px) {
    overflow: hidden;
    width: ${props => (props.openSidebar ? `${250}px` : `${0}px`)};
  }
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 98%;
  height: 80px;
  padding-top: 10px;
  background: ${props =>
    props.isSolar ? "#FF601A" : "#2D567B"};
`;

export const Content = styled.ul`
  display: flex;
  flex-direction: column;
  width: ${props => (props.openSidebar ? "100%" : "99%")};
  height: 93%;
  background: ${props =>
    props.isSolar ? "#FF601A" : "#2D567B"};
  margin: 0px;
  padding: 10px 13px;

  overflow-y: ${props => (props.openSidebar ? "scroll" : "visible")};
  ::-webkit-scrollbar {
    width: 5px; /* width of the entire scrollbar */
  }
  ::-webkit-scrollbar-track {
    background: #fff; /* color of the tracking area */
  }
  ::-webkit-scrollbar-thumb {
    background-color: #69696936; /* color of the scroll thumb */
    border-radius: 20px; /* roundness of the scroll thumb */
    border: 3px solid #69696936; /* creates padding around scroll thumb */
  }
`;

export const SidebarMenu = styled.li`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  height: ${props =>
    props.openSidebar && props.openSubMenu.id == props.id
      ? props.openSubMenu.open
        ? `${props.subItemsLenght * 40 + 54}px`
        : "54px"
      : "54px"};

  background: yellow;
  ul {
    margin: 0;
    padding: 0;
    width: 100%;
    overflow: hidden;
  }
  a {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 45px;
    padding: 2px 10px;
    color: #7b8190;
    position: relative;
    font-size: 15px;
    font-weight: 500;

    overflow: hidden;
    border-radius: 3px;
    cursor: pointer;
  }
  a > div {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 0 2px;
  }
  a > div > div > svg {
    width: ${props => (props.openSidebar ? `20px` : "30px")};
    height: ${props => (props.openSidebar ? `20px` : "30px")};
  }
  a > div > span {
    transition: opacity 0.3s linear;
    margin-left: 10px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    opacity: ${props => (props.openSidebar ? `1` : "0")};
    color: "#FFF !important"
  }
  .arrow {
    width: 30px;
    margin-top: 3px;
  }
  .arrow svg {
    width: 17px;
    height: 17px;

    transform: ${props =>
      props.openSubMenu.id == props.id
        ? props.openSubMenu.open
          ? `rotate(180deg)`
          : "rotate(0deg)"
        : "rotate(0deg)"};
  }
`;

export const Email = styled.div`
      width: 135px
`;

export const ConsultorDiv = styled.div`
  border: 1px solid #ffffff30;

`;
export const TextEmail = styled.div`
  font-size: 10px;
  width: 103px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const TextNomeConsultor = styled.div`
    font-size: 12px;
  
`;

export const ButtonCopy = styled.button`
  &:hover
  {
    border: 1px solid ${props =>
    props.isSolar ? "#FF9E73" : "#7eacc4a1"};
    background-color: ${props =>
    props.isSolar ? "#ff9e738a! important" : "#7eacc4a1! important"};
  }

`;

export const TextWhatsApp = styled.div`
    font-size: 12px;
`;

export const Image = styled.img`
  height: ${props => (props.openSidebar ? "120px" : "50px")};
`;

export const Loading = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
