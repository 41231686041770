const formatter = new Intl.NumberFormat("de-DE", {
  style: "currency",
  currency: "EUR",
  // minimumFractionDigits: 0,
  signDisplay: "auto",
});

const formateCurrency = value => {
  var numberString = formatter
    .formatToParts(value)
    .map(({ type, value }) => {
      switch (type) {
        case "currency":
          return ``;
        default:
          return value;
      }
    })
    .reduce((string, part) => string + part);
  return `R$ ${numberString}`;
};

const displayCurreny = value => {
  return `R$ ${value}`;
};

export { formatter, formateCurrency, displayCurreny };
