import React, { useState } from "react";
import { Row, CardBody, Card, Form, InputGroup } from "reactstrap";

//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";
import { Portuguese } from "flatpickr/dist/l10n/pt.js";

const Filter = ({
  setCode,
  setCodeVend,
  setCodeFornec,
  setDate,
  refetch,
  setLoadingFilter,
}) => {
  const [, setPickDate] = useState("");

  const picks = () => {
    setPickDate(new Date());
  };

  function formatDate(date) {
    let data = new Date(date);

    let dataFormatada = `${data.getFullYear()}-${(data.getMonth() + 1)
      .toString()
      .padStart(2, "0")}-${data.getDate().toString().padStart(2, "0")}`;
    setDate(dataFormatada.split("-").reverse().join("/"));
  }

  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <div className="p-1">
            <div className="d-flex align-items-center">
              <div className="me-2">
                <i className="bx bxs-filter-alt h4" style={{color: "#2D567B"}}></i>
              </div>
              <div className="flex-1 overflow-hidden">
                <h5 className="font-size-20" style={{color: "#2D567B"}}>Consultar Fechamento</h5>
              </div>
            </div>
          </div>
          <div className="p-4 border-top">
            <Form>
              <div>
                <Row className="mb-3">
                  <label
                    htmlFor="example-text-input"
                    className="col-md-4 col-form-label"
                  >
                    Número de Fechamento:
                  </label>
                  <div className="col-md-8">
                    <input
                      onChange={e => setCode(e.target.value)}
                      className="form-control"
                      type="text"
                      placeholder="Núm. Fechamento ou Pedido de compra"
                    />
                  </div>
                </Row>
                <Row className="mb-3">
                  <label
                    htmlFor="example-text-input"
                    className="col-md-4 col-form-label"
                  >
                    Data de Emissão:
                  </label>
                  <div className="col-md-8 docs-datepicker">
                    <InputGroup>
                      <Flatpickr
                        className="form-control d-block selectData"
                        placeholder="dd/mm/aaaa"
                        options={{
                          altInput: true,
                          locale: Portuguese,
                          altFormat: "d/m/Y",
                          dateFormat: "Ymd",
                        }}
                        /* value={dataFim.split("-").join("")} */
                        onChange={date => formatDate(date)}
                      />
                      <div className="input-group-append">
                        <button
                          type="button"
                          className="btn btn-outline-secondary docs-datepicker-trigger"
                          onClick={picks}
                        >
                          <i className="fa fa-calendar" aria-hidden="true" />
                        </button>
                      </div>
                    </InputGroup>
                    <div className="docs-datepicker-container" />
                  </div>
                </Row>
                <Row className="mb-3">
                  <label
                    htmlFor="example-number-input"
                    className="col-md-4 col-form-label"
                  >
                    Vendedor:
                  </label>
                  <div className="col-md-8">
                    <input
                      className="form-control"
                      type="number"
                      placeholder="Código de Vendedor"
                      onChange={e => setCodeVend(e.target.value)}
                    />
                  </div>
                </Row>
                <Row className="mb-3">
                  <label
                    htmlFor="example-number-input"
                    className="col-md-4 col-form-label"
                  >
                    Fornecedor:
                  </label>
                  <div className="col-md-8">
                    <input
                      className="form-control"
                      type="number"
                      placeholder="Código de Vendedor"
                      onChange={e => setCodeFornec(e.target.value)}
                    />
                  </div>
                </Row>
              </div>
            </Form>
          </div>
          <div className="pt-3 border-top">
            <button
              onClick={() => {
                setLoadingFilter(true);
                refetch();
              }}
              className="btn text-white"
              style={{backgroundColor: "#2D567B"}}
            >
              <i className="bx bxs-filter-alt me-2"></i>Filtrar
            </button>
          </div>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default Filter;
