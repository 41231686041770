import {
  PROFILE_ERROR,
  PROFILE_SUCCESS,
  EDIT_PROFILE,
  RESET_PROFILE_FLAG,
  GET_PROFILE_DETAILS,
  PROFILE_FAIL_API,
  PROFILE_SUCCESS_API,
  UPDATE_PROFILE_DETAILS,
} from "./actionTypes";

const initialState = {
  error: "",
  success: "",
  updatedProfile: false,
  profileDetails: {
    user: {
      firstname: "",
      lastname: "",
      code: "",
    },
    userAddress: {
      address: "",
      number: "",
      complement: "",
      zipcode: "",
      district: "",
      city: "",
      state: "",
    },
    userInfo: {
      about: "",
      taxvat: "",
      telephone: "",
      cellphone: "",
      gender: "",
      birth_date: "",
      website: "",
    },
  },
};

const profile = (state = initialState, action) => {
  switch (action.type) {
    case EDIT_PROFILE:
      state = { ...state };
      break;
    case PROFILE_SUCCESS:
      state = { ...state, success: action.payload };
      break;
    case PROFILE_ERROR:
      state = { ...state, error: action.payload };
      break;
    case RESET_PROFILE_FLAG:
      state = { ...state, success: null };
      break;
    case PROFILE_SUCCESS_API:
      switch (action.payload.actionType) {
        case GET_PROFILE_DETAILS:
          return {
            ...state,
            profileDetails: action.payload.data,
            getProfileLoading: false,
            isProfileFetched: true,
          };
    
        case UPDATE_PROFILE_DETAILS:
          return {
            ...state,
            getAllProfileLoading: false,
            isProfileFetched: true,
            updatedProfile: false,
          };
        default:
          return state;
      }
    case PROFILE_FAIL_API:
      switch (action.payload.actionType) {
        case GET_PROFILE_DETAILS:
          return {
            ...state,
            getProfileLoading: false,
            isProfileFetched: false,
          };
        case UPDATE_PROFILE_DETAILS:
          return {
            ...state,
            getAllProfileLoading: false,
            isProfileFetched: false,
            updatedProfile: false,
          };
        default:
          return state;
      };

    case GET_PROFILE_DETAILS:
      return {
        ...state,
        getProfileLoading: true,
        isProfileFetched: false,
      };

    case UPDATE_PROFILE_DETAILS:
      return {
        ...state,
        getAllProfileLoading: true,
        isProfileFetched: false,
        updatedProfile: true,
      };

    default:
      state = { ...state };
      break;
  }
  return state;
};

export default profile;
