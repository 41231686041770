import { CustomersActionTypes } from "./actionTypes";

const INIT_STATE = {
  customers: {},
  customerDetails: {},
  addressByZipCode: {},
  telephones: {}
};

const Customers = (state = INIT_STATE, action) => {
  switch (action.type) {
    case CustomersActionTypes.API_RESPONSE_SUCCESS:
      switch (action.payload.actionType) {
        case CustomersActionTypes.GET_CUSTOMERS_LIST:
          return {
            ...state,
            customers: action.payload.data,
            getCustomersLoading: false,
            isCustomersFetched: true,
            isDetailsSaved: false,
          };
        case CustomersActionTypes.GET_CUSTOMERS_DETAILS:
          return {
            ...state,
            customerDetails: action.payload.data,
            getCustomerDetailsLoading: false,
            isCustomerDetailsFetched: true,
          };

        case CustomersActionTypes.SAVE_CUSTOMERS_DETAILS:
          return {
            ...state,
            saveCustomerDetailsLoading: false,
            isDetailsSaved: true,
          };
        case CustomersActionTypes.GET_ADDRES_BY_ZIPCODE:
          const existingCategories = { ...state.categories };
          var newCategory = {};
          newCategory[action.payload.data.id] = action.payload.data.data;
          const totalAddresses = { ...existingCategories, ...newCategory };

          return {
            ...state,
            addressesByZipCode: totalAddresses,
            getCustomerDetailsLoading: false,
            isAddressFetched: true,
          };
        case CustomersActionTypes.GET_MULTI_TELEPHONE:
          return {
              ...state,
              telephones: action.payload.data,
              getCustomerDetailsLoading: false,
          };
        case CustomersActionTypes.SAVE_MULTITELEPHONES_DETAILS:
          return {
              ...state,
              telephones: action.payload.data,
              getCustomerDetailsLoading: false,
          };
        default:
          return { ...state };
      }
    case CustomersActionTypes.API_RESPONSE_ERROR:
      switch (action.payload.actionType) {
        case CustomersActionTypes.GET_CUSTOMERS_LIST:
          return {
            ...state,
            customers: [],
            getCustomersLoading: false,
            isCustomersFetched: false,
          };
        case CustomersActionTypes.GET_CUSTOMERS_DETAILS:
          return {
            ...state,
            getCustomerDetailsLoading: false,
            isCustomerDetailsFetched: false,
          };
        case CustomersActionTypes.SAVE_CUSTOMERS_DETAILS:
          return {
            ...state,
            saveCustomerDetailsLoading: false,
            isDetailsSaved: false,
          };
        case CustomersActionTypes.GET_ADDRES_BY_ZIPCODE:
          return {
            ...state,
            getCustomerDetailsLoading: false,
            isAddressFetched: false,
          };
        case CustomersActionTypes.GET_MULTI_TELEPHONE:
          return {
              ...state,
              getCustomerDetailsLoading: false,
          };  
        case CustomersActionTypes.SAVE_MULTITELEPHONES_DETAILS:
        return {
            ...state,
            getCustomerDetailsLoading: false,
        };
        default:
          return { ...state };
      }

    case CustomersActionTypes.GET_CUSTOMERS_LIST:
      return {
        ...state,
        getCustomersLoading: true,
        isCustomersFetched: false,
      };
    case CustomersActionTypes.GET_CUSTOMERS_DETAILS:
      return {
        ...state,
        getCustomerDetailsLoading: true,
        isCustomerDetailsFetched: false,
        isDetailsSaved: false,
      };
    case CustomersActionTypes.SAVE_CUSTOMERS_DETAILS:
      return {
        ...state,
        saveCustomerDetailsLoading: true,
        isDetailsSaved: false,
      };
    case CustomersActionTypes.GET_ADDRES_BY_ZIPCODE:
      return {
        ...state,
        getCustomerDetailsLoading: true,
        isAddressFetched: false,
      };
    case CustomersActionTypes.RESET_CUSTOMERS:
      let flags = {};
      flags[action.payload.flag] = action.payload.value;
      return {
        ...state,
        ...flags,
      };
    case CustomersActionTypes.GET_MULTI_TELEPHONE:
      return {
        ...state,
        getCustomerDetailsLoading: false,
      };  
    case CustomersActionTypes.SAVE_MULTITELEPHONES_DETAILS:
      return {
          ...state,
          getCustomerDetailsLoading: false,
      };

    default:
      return state;
  }
};

export default Customers;
