import React from "react";
import { Row, Col } from "reactstrap";
import { Link } from "react-router-dom";

// image
import loyaltyImg from "../../assets/images/others/loyalty.png";

const Loyalty = () => {
  return (
    <div className="w-100">
      <Row>
        <Col xs={12}>
          <h4 className="fw-bold mb-3 mt-3">Fidelidade Clube Adias</h4>
        </Col>
      </Row>
      <div className="w-100">
        <img src={loyaltyImg} alt="adias-loyalty" className="w-100" />
      </div>
      <p>
        * Benefícios sujeitos a alterações sem abiso prévio e ultilização dentro
        da vigência da sazão. / ** Utilização ao final da sazão vigente.
      </p>
      <div className="text-end mb-3">
        <Link to="#" className="btn btn-primary text-uppercase">
          SAIBA MAIS
        </Link>
      </div>
    </div>
  );
};

export default Loyalty;
