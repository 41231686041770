import styled from "styled-components";

export const ModalHeader = styled.div`
  width: 100%;
  display: flex;
`;

export const ContainerLogos = styled.div`
  width: 100%;
  display: flex;
`;

export const DivLoading = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  padding-top: 50%;
  z-index: 99;
  width: 100%;
  height: 100%;
  background-color: #a4a4a496;
`;

export const TituloModal = styled.h1`
  font-weight: bold;
  font-size: 20px;
  margin-bottom: 30px;
  padding-left: 28px;
  color: #404b5a;
`;

export const TitleInputs = styled.h3`
  font-weight: normal;
  font-size: 15px;
  margin-top: 30px;
  color: #7e7e7e;

  :nth-child(1) {
    margin-top: 0;
  }
`;

export const BotaoEntrar = styled.button`
  padding: 10px 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${props => (props.isSolar ? "#FF601A" : "#2D567B")};
  color: #fff;
  border: none;
  margin-top: 30px;
  font-size: 17px;
  border-radius: 5px;
  margin-bottom: 20px;
  width: 100%;
  max-width: 145px;
  height: 43px;
  transition: all 0.5 linear;
  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
  &:hover {
    background-color: ${props => (props.isSolar ? "#c65717" : "#0067b4d6")};
  }
`;

export const Image = styled.img`
  width: 100%;
  max-width: 300px;
  height: 100%;
`;

export const P = styled.p`
  font-size: 16px;
  color: #7e7e7e;
  margin-top: 30px;
  text-align: center;
  max-width: 510px;
`;

export const BotaoVoltar = styled.button`
  padding: 10px 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  -webkit-box-shadow: 0px 0px 12px 3px #dedede;
  box-shadow: 0px 0px 12px 3px #dedede;
  color: #2D567B;
  border: none;
  margin-top: 30px;
  font-size: 17px;
  border-radius: 5px;
  margin-bottom: 20px;
  width: 100%;
  max-width: 145px;
  height: 43px;
  transition: all 0.2s ease-in-out;

  &:hover {
    transform: scale(1.2);
  }
`;
