// Carousel images
import img1 from "../../assets/images/small/img-8.jpg";
import img2 from "../../assets/images/small/img-9.jpg";
import img3 from "../../assets/images/small/img-10.jpg";

// images
import avatar2 from "../../assets/images/users/avatar-2.jpg";

const bannerItems = [
  {
    src: img1,
  },
  {
    src: img2,
  },
  {
    src: img3,
  },
];
const series1 = [
  {
    data: [25, 66, 41, 89, 63, 25, 44, 20, 36, 40, 54],
  },
];

const options1 = {
  fill: {
    colors: ["#5b73e8"],
  },
  chart: {
    width: 70,
    sparkline: {
      enabled: !0,
    },
  },
  plotOptions: {
    bar: {
      columnWidth: "50%",
    },
  },
  labels: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
  xaxis: {
    crosshairs: {
      width: 1,
    },
  },
  tooltip: {
    fixed: {
      enabled: !1,
    },
    x: {
      show: !1,
    },
    y: {
      title: {
        formatter: function () {
          return "";
        },
      },
    },
    marker: {
      show: !1,
    },
  },
};

const series2 = [70];

const options2 = {
  fill: {
    colors: ["#34c38f"],
  },
  chart: {
    sparkline: {
      enabled: !0,
    },
  },
  dataLabels: {
    enabled: !1,
  },
  plotOptions: {
    radialBar: {
      hollow: {
        margin: 0,
        size: "60%",
      },
      track: {
        margin: 0,
      },
      dataLabels: {
        show: !1,
      },
    },
  },
};

const series3 = [55];

const options3 = {
  fill: {
    colors: ["#5b73e8"],
  },
  chart: {
    sparkline: {
      enabled: !0,
    },
  },
  dataLabels: {
    enabled: !1,
  },
  plotOptions: {
    radialBar: {
      hollow: {
        margin: 0,
        size: "60%",
      },
      track: {
        margin: 0,
      },
      dataLabels: {
        show: !1,
      },
    },
  },
};

const series4 = [
  {
    data: [25, 66, 41, 89, 63, 25, 44, 12, 36, 9, 54],
  },
];

const options4 = {
  fill: {
    colors: ["#f1b44c"],
  },
  chart: {
    width: 70,
    sparkline: {
      enabled: !0,
    },
  },
  plotOptions: {
    bar: {
      columnWidth: "50%",
    },
  },
  labels: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
  xaxis: {
    crosshairs: {
      width: 1,
    },
  },
  tooltip: {
    fixed: {
      enabled: !1,
    },
    x: {
      show: !1,
    },
    y: {
      title: {
        formatter: function () {
          return "";
        },
      },
    },
    marker: {
      show: !1,
    },
  },
};

const widgetData = [
  {
    id: 1,
    icon: "mdi mdi-arrow-up-bold",
    title: "Total Revenue",
    value: 34152,
    prefix: "$",
    suffix: "",
    badgeValue: "2.65%",
    decimal: 0,
    charttype: "bar",
    chartheight: 40,
    chartwidth: 70,
    color: "success",
    desc: "since last week",
    series: series1,
    options: options1,
  },
  {
    id: 2,
    icon: "mdi mdi-arrow-down-bold",
    title: "Orders",
    value: 5643,
    decimal: 0,
    charttype: "radialBar",
    chartheight: 45,
    chartwidth: 45,
    prefix: "",
    suffix: "",
    badgeValue: "0.82%",
    color: "danger",
    desc: "since last week",
    series: series2,
    options: options2,
  },
  {
    id: 3,
    icon: "mdi mdi-arrow-down-bold",
    title: "Customers",
    value: 45254,
    decimal: 0,
    prefix: "",
    suffix: "",
    charttype: "radialBar",
    chartheight: 45,
    chartwidth: 45,
    badgeValue: "6.24%",
    color: "danger",
    desc: "since last week",
    series: series3,
    options: options3,
  },
  {
    id: 4,
    icon: "mdi mdi-arrow-up-bold",
    title: "Growth",
    value: 12.58,
    decimal: 2,
    prefix: "+",
    suffix: "%",
    charttype: "bar",
    chartheight: 40,
    chartwidth: 70,
    badgeValue: "10.51%",
    color: "success",
    desc: "since last week",
    series: series4,
    options: options4,
  },
];

const topVendors = [
  {
    id: 1,
    venderor_profileImageUrl: avatar2,
    venderor: "Alexandre",

    valor_total: "R$: 15.000,00",
    potencial_de_compra: "R$ 0,00",
    percentual_do_faturamento: "0,0%",
    número_do_pedido: "001",
    data_do_ultimo_pedido: "04/09/2021",
    hasTrophy: true,
  },
  {
    id: 2,
    venderor_profileImageUrl: avatar2,
    venderor: "Alexandre",
    valor_total: "R$ 0,00",
    potencial_de_compra: "R$ 0,00",
    percentual_do_faturamento: "0,0%",
    número_do_pedido: "001",
    data_do_ultimo_pedido: "04/09/2021",
    hasTrophy: true,
  },
  {
    id: 3,
    venderor_profileImageUrl: avatar2,
    venderor: "Cássia",

    valor_total: "R$: 14.234,10",
    potencial_de_compra: "R$ 0,00",
    percentual_do_faturamento: "0,0%",
    número_do_pedido: "002",
    data_do_ultimo_pedido: "31/08/2021",
    hasTrophy: true,
  },
  {
    id: 4,
    venderor_profileImageUrl: avatar2,
    venderor: "Erick",

    valor_total: "R$: 12.116,95",
    potencial_de_compra: "R$ 0,00",
    percentual_do_faturamento: "0,0%",
    número_do_pedido: "003",
    data_do_ultimo_pedido: "24/08/2021",
  },
  {
    id: 5,
    venderor_profileImageUrl: avatar2,
    venderor: "Yoko",

    valor_total: "R$: 10.369,15",
    potencial_de_compra: "R$ 0,00",
    percentual_do_faturamento: "0,0%",
    número_do_pedido: "004",
    data_do_ultimo_pedido: "19/08/2021",
  },
];

const purchaseChartData = [
  {
    dateIndex: 1,
    date: "2021-10-01T06:30:36.648Z",
    vendas: 2500,
    meta: 2000,
  },
  {
    dateIndex: 3,
    date: "2021-10-03T06:30:36.648Z",
    vendas: 5500,
    meta: 1500,
  },
  {
    dateIndex: 6,
    date: "2021-10-06T06:30:36.648Z",
    vendas: 8700,
    meta: 2000,
  },
  {
    dateIndex: 9,
    date: "2021-10-09T06:30:36.648Z",
    vendas: 7500,
    meta: 3000,
  },
  {
    dateIndex: 12,
    date: "2021-10-12T06:30:36.648Z",
    vendas: 9800,
    meta: 6500,
  },
  {
    dateIndex: 15,
    date: "2021-10-15T06:30:36.648Z",
    vendas: 9000,
    meta: 2500,
  },
  {
    dateIndex: 18,
    date: "2021-10-18T06:30:36.648Z",
    vendas: 10500,
    meta: 2000,
  },
  {
    dateIndex: 21,
    date: "2021-10-21T06:30:36.648Z",
    vendas: 9800,
    meta: 1000,
  },
  {
    dateIndex: 23,
    date: "2021-10-23T06:30:36.648Z",
    vendas: 14000,
    meta: 6000,
  },
  {
    dateIndex: 25,
    date: "2021-10-25T06:30:36.648Z",
    vendas: 11000,
    meta: 4500,
  },
  {
    dateIndex: 27,
    date: "2021-10-27T06:30:36.648Z",
    vendas: 15432.25,
    meta: 2000,
  },
  {
    dateIndex: 30,
    date: "2021-10-30T06:30:36.648Z",
    vendas: 8400,
    meta: 2000,
  },
];
export { widgetData, bannerItems, topVendors, purchaseChartData };
