/* eslint-disable no-undef */
import AvInput from "availity-reactstrap-validation/lib/AvInput";
import AvFeedback from "availity-reactstrap-validation/lib/AvFeedback";
import AvForm from "availity-reactstrap-validation/lib/AvForm";
import AvGroup from "availity-reactstrap-validation/lib/AvGroup";
import React, { useEffect, useState } from "react";
import "./cartaoCadastro.css";
import {
  Container,
  Card,
  CardBody,
  Row,
  Label,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Spinner,
  Alert,
} from "reactstrap";
import Breadcrumb from "../../components/Breadcrumb";
import { postNewCardData, getExistingCardData } from "../../api/card";
import { consultaCep } from "../../api/order";
import { getProfileDetailsList } from "../../store/auth/profile/actions";
import { formateDate, formatInvertDate } from "../../utils";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

function CartaoCadastro() {
  const dispatch = useDispatch();
  const {
    fname,
    lname,
    emailAdd,
    birth_date,
    zipcode,
    tel,
    num,
    comp,
    taxvat,
  } = useSelector(state => {
    return {
      fname: state?.Profile?.profileDetails?.user?.firstname,
      lname: state?.Profile?.profileDetails?.user?.lastname,
      emailAdd: state?.Profile?.profileDetails?.user?.email,
      birth_date: state?.Profile?.profileDetails?.userInfo?.birth_date,
      zipcode: state?.Profile?.profileDetails?.userAddress?.zipcode,
      tel: state?.Profile?.profileDetails?.userInfo?.telephone,
      num: state?.Profile?.profileDetails?.userAddress?.number,
      comp: state?.Profile?.profileDetails?.userAddress?.complement,
      taxvat: state?.Profile?.profileDetails?.userInfo?.taxvat,
    };
  });

  const [createdAt, setCreatedAt] = useState("");
  const [isLoadingStart, setIsLoadingStart] = useState(false);

  useEffect(() => {
    const getData = async () => {
      setIsLoadingStart(true);
      const response = await getExistingCardData();
      if (response.cadastro === null) {
        setIsRegister(false);
      } else {
        setIsRegister(true);
        let date = formateDate(response?.cadastro?.created_at, "DD/MM/YYYY");
        setCreatedAt(date);
      }
      setIsLoadingStart(false);
    };
    getData();
    dispatch(getProfileDetailsList());
  }, [isRegister]);

  const [cnpj, setCnpj] = useState("");
  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");
  const [cpf, setCpf] = useState("");
  const [email, setEmail] = useState("");
  const [telephone, setTelephone] = useState("");
  const [birthDate, setBirthDate] = useState("");
  const [number, setNumber] = useState("");
  const [complement, setComplement] = useState("");
  const [cep, setCep] = useState("");
  const [address, setAddress] = useState("");
  const [bairro, setBairro] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [isRegister, setIsRegister] = useState(false);
  const [isLoadingCep, setIsLoadingCep] = useState(false);

  useEffect(() => {
    setCnpj(taxvat);
    setName(fname);
    setSurname(lname);
    setEmail(emailAdd);
    setBirthDate(formateDate(birth_date));
    setCep(zipcode);
    setTelephone(tel);
    setNumber(num);
    setComplement(comp);
  }, [taxvat, fname, lname, emailAdd, birth_date, zipcode, tel, num, comp]);

  const handleSubmit = (e, val) => {
    val.dtnascimento = formatInvertDate(val.dtnascimento.split("-").join(""));
    val.cnpj = val.cnpj.replace(/\D+/g, "");
    delete val.termos;
    val.id = "";
    callApi({ data: val });
  };

  const callApi = async obj => {
    const response = await postNewCardData(obj);
    if (response.success) {
      toast.success(response.msg, { theme: "colored" });
      setIsRegister(true);
    } else {
      toast.error(response.msg, { theme: "colored" });
    }
  };

  const callAddress = async cep => {
    setIsLoadingCep(true);
    const response = await consultaCep(cep);
    setAddress(response.address.address);
    setCity(response.address.city);
    setBairro(response.address.district);
    setState(response.address.state);
    setIsLoadingCep(false);
  };

  const maskCnpj = v => {
    return v
      .replace(/\D+/g, "")
      .replace(/(\d{2})(\d)/, "$1.$2")
      .replace(/(\d{3})(\d)/, "$1.$2")
      .replace(/(\d{3})(\d)/, "$1/$2")
      .replace(/(\d{4})(\d)/, "$1-$2")
      .replace(/(-\d{2})\d+?$/, "$1");
  };

  const maskCpf = v => {
    return v
      .replace(/\D/g, "")
      .replace(/(\d{3})(\d)/, "$1.$2")
      .replace(/(\d{3})(\d)/, "$1.$2")
      .replace(/(\d{3})(\d)/, "$1-$2")
      .replace(/(-\d{2})\d+?$/, "$1");
  };

  const maskTelephone = v => {
    return v
      .replace(/\D/g, "")
      .replace(/(\d{2})(\d)/, "($1) $2")
      .replace(/(\d{4})(\d)/, "$1-$2")
      .replace(/(-\d{4})\d+?$/, "$1");
  };

  const maskCep = v => {
    if (v.length >= 9) {
      callAddress(v);
    }
    return v
      .replace(/\D/g, "")
      .replace(/(\d{5})(\d)/, "$1-$2")
      .replace(/(-\d{3})\d+?$/, "$1");
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container>
          <div className="d-flex align-items-baseline gap-2">
            <h1 className="mt-3">Solicitação de Cartão RT</h1>
            <p>
              Preencha este cadastro na sua totalidade para dar continuidade a
              solicitação do cartão
            </p>
          </div>
          <Breadcrumb
            title="Pagina Inicial"
            breadcrumbItem="Cadastro Portador Cartão"
          />
          {isLoadingStart ? (
            <Spinner />
          ) : isRegister ? (
            <Alert color="info">
              <i className="fas fa-info-circle me-2"></i>
              Você já possui um cadastro de cartão pendente criado em{" "}
              {createdAt}
            </Alert>
          ) : (
            <Card>
              <CardBody>
                <div className="d-flex align-items-center gap-2">
                  <i className="fa fa-id-card"></i>
                  <h3>Cadastro Perfil Portador Cartão</h3>
                </div>
                <hr></hr>
                <AvForm onValidSubmit={handleSubmit}>
                  <Row>
                    <AvGroup>
                      <Label for="cnpj">CNPJ</Label>
                      <InputGroup>
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText style={{ height: "100%" }}>
                            <i className="fa fa-building"></i>
                          </InputGroupText>
                        </InputGroupAddon>
                        <AvInput
                          name="cnpj"
                          placeholder="CNPJ"
                          required
                          disabled={cnpj === "" ? false : true}
                          value={cnpj}
                          onChange={e => setCnpj(maskCnpj(e.target.value))}
                        />
                        <AvFeedback>Campo obrigatório!</AvFeedback>
                      </InputGroup>
                    </AvGroup>
                  </Row>
                  <Row className="mt-4">
                    <AvGroup>
                      <Label for="empresa">Nome da Empresa</Label>
                      <InputGroup>
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText style={{ height: "100%" }}>
                            <i className="fa fa-building"></i>
                          </InputGroupText>
                        </InputGroupAddon>
                        <AvInput
                          name="empresa"
                          placeholder="Empresa"
                          required
                        />
                        <AvFeedback>Campo obrigatório!</AvFeedback>
                      </InputGroup>
                    </AvGroup>
                  </Row>
                  <Row className="mt-4">
                    <AvGroup>
                      <Label for="nome">Nome dono</Label>
                      <InputGroup>
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText style={{ height: "100%" }}>
                            <i className="fas fa-user"></i>
                          </InputGroupText>
                        </InputGroupAddon>
                        <AvInput
                          name="nome"
                          placeholder="Nome"
                          required
                          value={name}
                          onChange={e => setName(e.target.value)}
                        />
                        <AvFeedback>Campo obrigatório!</AvFeedback>
                      </InputGroup>
                    </AvGroup>
                  </Row>
                  <Row className="mt-4">
                    <AvGroup>
                      <Label for="sobrenome">Sobrenome dono</Label>
                      <InputGroup>
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText style={{ height: "100%" }}>
                            <i className="fas fa-user"></i>
                          </InputGroupText>
                        </InputGroupAddon>
                        <AvInput
                          name="sobrenome"
                          placeholder="Sobrenome"
                          required
                          value={surname}
                          onChange={e => setSurname(e.target.value)}
                        />
                        <AvFeedback>Campo obrigatório!</AvFeedback>
                      </InputGroup>
                    </AvGroup>
                  </Row>
                  <Row className="mt-4">
                    <AvGroup>
                      <Label for="dtnascimento">Data de Nascimento</Label>
                      <InputGroup>
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText style={{ height: "100%" }}>
                            <i className="fas fa-calendar-alt"></i>
                          </InputGroupText>
                        </InputGroupAddon>
                        <AvInput
                          type="date"
                          name="dtnascimento"
                          required
                          placeholder="dd/mm/aaaa"
                          value={birthDate}
                          onChange={e => setBirthDate(e.target.value)}
                        />
                        <AvFeedback>Campo obrigatório!</AvFeedback>
                      </InputGroup>
                    </AvGroup>
                  </Row>
                  <Row className="mt-4">
                    <AvGroup>
                      <Label for="cpf">CPF</Label>
                      <InputGroup>
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText style={{ height: "100%" }}>
                            <i className="fa fa-building"></i>
                          </InputGroupText>
                        </InputGroupAddon>
                        <AvInput
                          name="cpf"
                          required
                          placeholder="xxx.xxx.xxx-xx"
                          value={cpf}
                          onChange={e => setCpf(maskCpf(e.target.value))}
                        />
                        <AvFeedback>Campo obrigatório!</AvFeedback>
                      </InputGroup>
                    </AvGroup>
                  </Row>
                  <Row className="mt-4">
                    <AvGroup>
                      <Label for="email">E-mail</Label>
                      <InputGroup>
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText style={{ height: "100%" }}>
                            <i className="fas fa-envelope"></i>
                          </InputGroupText>
                        </InputGroupAddon>
                        <AvInput
                          name="email"
                          type="email"
                          value={email}
                          onChange={e => setEmail(e.target.value)}
                          required
                          placeholder="E-mail"
                        />
                        <AvFeedback>Campo obrigatório!</AvFeedback>
                      </InputGroup>
                    </AvGroup>
                  </Row>
                  <Row className="mt-4">
                    <AvGroup>
                      <Label for="nome_mae">Nome da mãe</Label>
                      <InputGroup>
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText style={{ height: "100%" }}>
                            <i className="fas fa-user"></i>
                          </InputGroupText>
                        </InputGroupAddon>
                        <AvInput name="nome_mae" required placeholder="Nome" />
                        <AvFeedback>Campo obrigatório!</AvFeedback>
                      </InputGroup>
                    </AvGroup>
                  </Row>
                  <Row className="mt-4">
                    <AvGroup>
                      <Label for="telefone">DDD + Telefone</Label>
                      <InputGroup>
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText style={{ height: "100%" }}>
                            <i className="fas fa-phone-alt"></i>
                          </InputGroupText>
                        </InputGroupAddon>
                        <AvInput
                          name="telefone"
                          required
                          validate={{
                            maxLength: { value: 14 },
                          }}
                          value={telephone}
                          placeholder="(xx) xxxxx-xxxx"
                          onChange={e =>
                            setTelephone(maskTelephone(e.target.value))
                          }
                        />
                        <AvFeedback>Por favor, insira o Telefone</AvFeedback>
                      </InputGroup>
                    </AvGroup>
                  </Row>
                  <p className="mt-3 mb-0 font-bold">Localização</p>
                  <Row className="mt-3">
                    <AvGroup>
                      <Label for="cep">CEP</Label>
                      <InputGroup>
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText style={{ height: "100%" }}>
                            <i className="fas fa-bullseye"></i>
                          </InputGroupText>
                        </InputGroupAddon>
                        <AvInput
                          name="cep"
                          required
                          placeholder="xxxxx-xxx"
                          value={cep}
                          onChange={e => setCep(maskCep(e.target.value))}
                        />
                        <AvFeedback>Por favor, insira o CEP</AvFeedback>
                        {isLoadingCep ? (
                          <Spinner
                            animation="grow"
                            className={`rounded-circle position-spinner`}
                          />
                        ) : null}
                      </InputGroup>
                    </AvGroup>
                  </Row>
                  <Row className="mt-3">
                    <AvGroup>
                      <Label for="endereco">Endereço</Label>
                      <InputGroup>
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText style={{ height: "100%" }}>
                            <i className="fas fa-map-marker-alt"></i>
                          </InputGroupText>
                        </InputGroupAddon>
                        <AvInput
                          name="endereco"
                          disabled
                          value={address}
                          placeholder="ex.: Av. Paulista"
                        />
                        <AvFeedback>Por favor, insira o endereço</AvFeedback>
                      </InputGroup>
                    </AvGroup>
                  </Row>
                  <Row className="mt-4">
                    <AvGroup>
                      <Label for="numero">Numero</Label>
                      <InputGroup>
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText style={{ height: "100%" }}>
                            <i className="fas fa-sort-numeric-down-alt"></i>
                          </InputGroupText>
                        </InputGroupAddon>
                        <AvInput
                          name="numero"
                          placeholder="ex.: 220"
                          value={number}
                          onChange={e => setNumber(e.target.value)}
                        />
                        <AvFeedback>Por favor, insira o numero</AvFeedback>
                      </InputGroup>
                    </AvGroup>
                  </Row>
                  <Row className="mt-4">
                    <AvGroup>
                      <Label for="complemento">Complemento</Label>
                      <InputGroup>
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText style={{ height: "100%" }}>
                            <i className="fas fa-sort-numeric-down-alt"></i>
                          </InputGroupText>
                        </InputGroupAddon>
                        <AvInput
                          name="complemento"
                          placeholder="ex.: apto. 20"
                          value={complement}
                          onChange={e => setComplement(e.target.value)}
                        />
                      </InputGroup>
                    </AvGroup>
                  </Row>
                  <Row className="mt-4">
                    <AvGroup>
                      <Label for="bairro">Bairro</Label>
                      <InputGroup>
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText style={{ height: "100%" }}>
                            <i className="fas fa-map-signs"></i>
                          </InputGroupText>
                        </InputGroupAddon>
                        <AvInput
                          disabled
                          name="bairro"
                          value={bairro}
                          placeholder="ex.: Bela Vista"
                        />
                        <AvFeedback>Por favor, insira o Bairro</AvFeedback>
                      </InputGroup>
                    </AvGroup>
                  </Row>
                  <Row className="mt-4">
                    <AvGroup>
                      <Label for="cidade">Cidade</Label>
                      <InputGroup>
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText style={{ height: "100%" }}>
                            <i className="far fa-map"></i>
                          </InputGroupText>
                        </InputGroupAddon>
                        <AvInput
                          disabled
                          name="cidade"
                          value={city}
                          placeholder="ex.: São Paulo"
                        />
                        <AvFeedback>Por favor, insira o cidade</AvFeedback>
                      </InputGroup>
                    </AvGroup>
                  </Row>
                  <Row className="mt-4">
                    <AvGroup>
                      <Label for="estado">UF</Label>
                      <InputGroup>
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText style={{ height: "100%" }}>
                            <i className="fas fa-map"></i>
                          </InputGroupText>
                        </InputGroupAddon>
                        <AvInput
                          disabled
                          name="estado"
                          value={state}
                          placeholder="ex.: SP"
                        />
                        <AvFeedback>Por favor, insira o estado</AvFeedback>
                      </InputGroup>
                    </AvGroup>
                  </Row>
                  <iframe
                    className="mt-4"
                    title="Termos de serviço adias"
                    style={{ minHeight: "600px", width: "100%" }}
                    src="https://clubev4.adias.com.br/v3/files/pdf/termos_social_bank2021.pdf"
                  ></iframe>
                  <Row className="mt-4">
                    <AvGroup check required>
                      <AvInput
                        className="ml-0 me-2"
                        type="checkbox"
                        name="termos"
                        required
                      />
                      <Label check for="termos">
                        Li e concordo com os termos
                      </Label>
                    </AvGroup>
                  </Row>
                  <Row className="mt-4">
                    <Input
                      className="submit-btn"
                      type="submit"
                      name="submit"
                      value="Solicitar Cartão"
                    />
                  </Row>
                </AvForm>
              </CardBody>
            </Card>
          )}
        </Container>
      </div>
    </React.Fragment>
  );
}

export default CartaoCadastro;
