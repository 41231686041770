import { RulesActionTypes } from "./actionTypes";

export const rulesApiSuccess = (actionType, data) => ({
  type: RulesActionTypes.API_RESPONSE_SUCCESS,
  payload: { actionType, data },
});

export const rulesApiFail = (actionType, error) => ({
  type: RulesActionTypes.API_RESPONSE_ERROR,
  payload: { actionType, error },
});

export const getRulesList = filters => ({
  type: RulesActionTypes.GET_RULES_LIST,
  payload: { filters },
});
