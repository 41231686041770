import React, { useEffect, useMemo, useState } from "react";
import { Container, Input, Label, Card, CardBody, Spinner } from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import "./autoGestao.css";
import DataTable from "react-data-table-component";
import { Link } from "react-router-dom";
import { getTableaus } from "../../store/management/action";

/* const data = [
      {
        id: 1,
        nome: "Análise de comissões e bonificações",
        descricao:
          "Visualização detalhada de comissão e bonificação retroativa",
        visualizar: "",
      },
      {
        id: 2,
        nome: "Gestão Carteira de Vendas",
        descricao: "Gestão Carteira de Vendas",
        visualizar: "",
      },
      {
        id: 3,
        nome: "Gestão Consumidor Final",
        descricao: "Painel de autogestão para televendas",
        visualizar: "",
      },
      {
        id: 4,
        nome: "Gestão de carteira (NOVO)",
        descricao: "Estamos testando um novo formato de comissão",
        visualizar: "",
      },
    ]; */

const FilterComponent = ({ filterText, onFilter }) => (
  <>
    <div className="engloba-label-input">
      <Label className="procurar-label">Procurar:</Label>
      <Input
        id="search"
        type="text"
        placeholder="Digite aqui"
        aria-label="Search Input"
        value={filterText}
        onChange={onFilter}
        className="input-pesquisa"
      />
    </div>
  </>
);

const AutoGestao = () => {
  const dispatch = useDispatch();
  const { loading, success, tableaus } = useSelector(state => {
    return {
      loading: state.Management.loading,
      success: state.Management.success,
      tableaus: state.Management.tableaus,
    };
  });
  const [filterText, setFilterText] = useState("");

  const [filteredItems, setFilteredItems] = useState([]);

  const subHeaderComponentMemo = useMemo(() => {
    return (
      <FilterComponent
        onFilter={e => setFilterText(e.target.value)}
        filterText={filterText}
      />
    );
  }, [filterText]);

  const columns = [
    {
      name: "Nome",
      selector: row => row.title,
    },
    {
      name: "Descrição",
      selector: row => row.description,
    },

    {
      name: "",
      selector: row => row.link,
      cell: row => (
        <Link to={`orcamento/${row.link}`}>
          <i className="ph-eye"></i> vizualizar
        </Link>
      ),
    },

    {
      name: "",
      selector: row => row.link,
      cell: row => (
        <Link to={`/tableau/dashs/create/${row.link}`}>
          <i className="ph-pencil"> </i> editar
        </Link>
      ),
    },
  ];
  const paginationComponentOptions = {
    rowsPerPageText: "Resultados por página",
    rangeSeparatorText: "de",
    selectAllRowsItem: true,
    selectAllRowsItemText: "Todos",
  };

  useEffect(() => {
    if (tableaus) {
      if (tableaus?.length > 1) {
        setFilteredItems(
          tableaus.filter(
            item =>
              item.title &&
              item?.title.toLowerCase().includes(filterText.toLowerCase())
          )
        );
      }
    }
  }, [filterText]);

  useEffect(() => {
    dispatch(getTableaus());
  }, []);

  return (
    <React.Fragment>
      <div className="page-content auto-gestao">
        <Container>
          <h2 className="gestao-adias">Gestão A.Dias</h2>
          {loading && <Spinner />}
          {success && (
            <Card>
              <CardBody>
                <h3 className="titulo-paineis">
                  <i className="ph-list-bullets icone-titulo-paineis"></i>
                  Paineis disponíveis
                </h3>
                <hr className="linha-auto-gestao" />
                <Link to="/tableau/dashs/create" className="criar-dash">
                  Criar Nova Dash
                </Link>

                <DataTable
                  columns={columns}
                  data={filteredItems}
                  pagination
                  paginationComponentOptions={paginationComponentOptions}
                  subHeader
                  subHeaderComponent={subHeaderComponentMemo}
                />
              </CardBody>
            </Card>
          )}
        </Container>
      </div>
    </React.Fragment>
  );
};

export default AutoGestao;
