import { apiClient } from "./apiCore";
// import { apiClient2 } from "./apiCore2";

export const listBudgets = filters => {
  const baseUrl = `/api/orcamentos`;
  return apiClient.create(`${baseUrl}`, filters);
};

export const getBudget = ({ id, empresa, filial }) => {
  const baseUrl = `/api/orcamento/${id}/${empresa}/${filial}`;
  return apiClient.get(`${baseUrl}`);
};
