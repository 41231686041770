import React, { useState } from "react";
import { Media, Button } from "reactstrap";
import classnames from "classnames";

import { Link } from "react-router-dom";

// utils
import { formateDate } from "../../../utils/index";

//dummy data
import { comments } from "./data";
import SimpleBar from "simplebar-react";

const CommentBox = ({ comment, index, total }) => {
  const commentDate = formateDate(comment.date, "Do MMM YYYY, h:mm:ss a");
  return (
    <Media
      className={classnames("d-flex", "py-4", {
        "border-bottom": total !== index + 1,
      })}
    >
      <img
        src={comment["user"]["image"]}
        className="me-2 rounded-circle avatar-xs"
        alt="img"
      />
      <Media body className="flex-1">
        <h5 className="font-size-15 mt-0 mb-1">
          {comment["user"]["name"]}{" "}
          <small className="text-muted float-end">{commentDate} </small>
        </h5>
        <p className="text-muted">{comment["comment"]}</p>

        <Link to="#" className="text-muted font-13 d-inline-block">
          <i className="mdi mdi-reply"></i> Reply
        </Link>

        {/* {comment.childComment
              ? comment.childComment.map((child, key) => (
                <Media className="d-flex mt-4" key={"_media_" + key}>
                  <img
                    src={child.img}
                    className="me-2 rounded-circle avatar-xs"
                    alt="img"
                  />
                  <Media body className="flex-1">
                    <h5 className="font-size-15 mt-0 mb-1">{child.name} <small className="text-muted float-end">{child.date}</small></h5>
                    <p className="text-muted">{child.description}</p>

                    <Link to="#" className="text-muted font-13 d-inline-block"><i
                      className="mdi mdi-reply"></i> Reply</Link>
                  </Media>
                </Media>
              ))
              : null} */}
      </Media>
    </Media>
  );
};

const InputArea = ({ value, onChange }) => {
  return (
    <>
      <div className="border rounded mt-4">
        <form action="#">
          <textarea
            rows="3"
            value={value}
            onChange={e => {
              onChange(e.target.value);
            }}
            className="form-control border-0 resize-none"
            placeholder="Escrever comentario..."
          ></textarea>
        </form>
      </div>
      <div className="mt-3 text-end">
        <Button color="success">Enviar</Button>
      </div>
    </>
  );
};
const ChatTab = () => {
  const [text, setText] = useState("");
  const onChageText = value => {
    setText(value);
  };
  const total = (comments || []).length;
  return (
    <div>
      <SimpleBar style={{ maxHeight: "430px" }}>
        <div>
          {(comments || []).map((comment, key) => (
            <CommentBox comment={comment} key={key} index={key} total={total} />
          ))}
        </div>
      </SimpleBar>
      <InputArea value={text} onChange={onChageText} />
    </div>
  );
};

export default ChatTab;
