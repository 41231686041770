import React, { useEffect, useState } from "react";
import { Card, CardBody, Row, Col, Alert } from "reactstrap";
import { Link } from "react-router-dom";

// components
import CustomTable from "../../../components/CustomTable";
import Loader from "../../../components/Loader";
import { HeadCol } from "../../../components/Columns";
import {
  Email,
  Name,
  Kwp,
  Despesa,
  Telefone,
  Endereço,
  Action,
} from "./Columns";
import LeadsPagination from "./Pagination";
import { useSelector } from "react-redux";

const ListTable = ({ getLeadsLoading }) => {
  const { leads } = useSelector(state => ({
    leads: state.Leads.leads,
  }));

  const [listaPaginada, setListaPaginada] = useState([]);
  const [defaultPage, setdefaultPage] = useState(0);
  const changeIndex = valor => setdefaultPage(valor);

  useEffect(() => {
    if (leads.length >= 1) {
      setListaPaginada(paginate(leads));
    }
  }, [leads]);

  function paginate(arr) {
    let leads = [];
    let data = arr;
    while (data.length) {
      leads.push(data.splice(0, 10));
    }

    return leads;
  }

  const columns = [
    {
      Header: <HeadCol label="Nome" />,
      accessor: "name",
      Cell: function colRenderer(props) {
        return <Name row={props} />;
      },
      minWidth: 180,  
    },
    {
      Header: <HeadCol label="Kwp" />,
      accessor: "kwp",
      Cell: function colRenderer(props) {
        return <Kwp row={props} />;
      },
    },
    {
      Header: <HeadCol label="Despesa/mês" />,
      accessor: "despesa",
      Cell: function colRenderer(props) {
        return <Despesa row={props} />;
      },
      
    },
    {
      Header: <HeadCol label="E-mail" />,
      accessor: "email",
      Cell: function colRenderer(props) {
        return <Email row={props} />;
      },
    },
    {
      Header: <HeadCol label="Telefone" />,
      accessor: "phone",
      Cell: function colRenderer(props) {
        return <Telefone row={props} />;
      },
    },
    {
      Header: <HeadCol label="Endereço" />,
      accessor: "address",
      Cell: function colRenderer(props) {
        return <Endereço row={props} />;
      },
    },
    {
      Header: <HeadCol label="" />,
      accessor: "action",
      Cell: function colRenderer(props) {
        return <Action row={props} />;
      },
    },
  ];

  return (
    <Card>
      <CardBody>
        <Row>
          <Col md={6}>
            <h5>
              <i className="fa fa-list"></i> Clientes
            </h5>
          </Col>
          <Col md={6}>
            <Link to="#" className="btn btn-dark-blue float-end" style={{background: '#2D567B'}}>
              Criar Lead
            </Link>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col xs={12}>
            <div className="mt-3 position-relative">
              {getLeadsLoading && <Loader />}
              {listaPaginada.length > 0 ? (
                <CustomTable
                  columns={columns}
                  data={
                    listaPaginada.length >= 1 ? listaPaginada[defaultPage] : []
                  }
                  classNames="align-middle custom-table table-nowrap table-borderless table-check"
                  selectable={false}
                  idField={"name"}
                />
              ) : !getLeadsLoading ? (
                <Alert color="info">Nenhum dado foi encontrado</Alert>
              ) : (
                <div style={{ height: 60 }} />
              )}
              {listaPaginada.length > 0 ? (
                <LeadsPagination
                  totalPages={listaPaginada.length}
                  index={defaultPage}
                  changeIndex={changeIndex}
                />
              ) : null}
            </div>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

export default ListTable;
