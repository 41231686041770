import React from "react";
import { Card, CardBody, Table } from "reactstrap";
import { formatInvertDate } from "../../../utils/dateUtils";

function SettlementItem({ settlement, colorDefault }) {
  const formato = {
    minimumFractionDigits: 2,
    style: "currency",
    currency: "BRL",
  };
  return (
    <Card className="tabela-comissao">
      <CardBody>
        <Table className="tabela-nf">
          <tbody>
            <tr className="d-flex justify-content-between text-center">
              <td
                scope="row"
                style={{
                  width: "72px",
                }}
              >
                {settlement.fechamento.NUM}
              </td>
              <td style={{ width: "56px" }}>{settlement.fechamento.ITEM}</td>
              <td
                style={{ width: "82px" }}
              >{`${settlement.fechamento.FORNECE}`}</td>
              <td style={{ width: "115px" }}>
                {parseFloat(settlement.fechamento.SUM_TOTAL).toLocaleString(
                  "pt-br",
                  formato
                )}
              </td>

              <td style={{ width: "99px" }}>
                {formatInvertDate(settlement.fechamento.EMISSAO)}
              </td>
              <td style={{ width: "122px" }}>
                <a
                  style={{ color: colorDefault }}
                  href={`./zcm_cartao/${settlement.fechamento.NUM}`}
                >
                  {" "}
                  Mais detalhes{" "}
                </a>
              </td>
            </tr>
          </tbody>
        </Table>
      </CardBody>
    </Card>
  );
}

export default SettlementItem;
