import { apiClient } from "./apiCore";

const getCustomFields = manufacturerId => {
  const baseUrl = `/api/warranty/manufacturers/${manufacturerId}/customFields`;
  return apiClient.get(baseUrl);
}

const createCustomField = data => {
  const baseUrl = "/api/warranty/customField";
  return apiClient.create(`${baseUrl}`, data);
};

export { getCustomFields, createCustomField };