import { ModalOpen } from "./actionTypes";

const INIT_STATE = {
  open: false,
};

const Modal = (state = INIT_STATE, action) => {
  switch (action.type) {
    case ModalOpen.ISOPEN:
      return {
        open: action.payload,
      };

    case ModalOpen.ISCLOSE:
      return {
        ...state,
        open: false,
      };
    default:
      return state;
  }
};

export default Modal;
