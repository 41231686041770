import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Row,
  Col,
} from 'reactstrap';
import { sendWarrantyDownload } from '../../../../store/actions';
import ButtonLoader from '../../../../components/ButtonLoader';
import { toast } from 'react-toastify';
import { useColors } from '../../../../hooks/useColors';

const AddWarrantyDownload = ({ isOpen, toggle, manufacturerId }) => {

  const dispatch = useDispatch();
  
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [file, setFile] = useState(null);

  const { loading, error, warrantyDownloadAdded } = useSelector(state => ({
    loading: state.WarrantyDownload.send.loading,
    error: state.WarrantyDownload.send.error,
    warrantyDownloadAdded: state.WarrantyDownload.send.downloadSent,
  }));

  const { colorDefault } = useColors();
  
  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleClickSendDownload = () => {
    if (!checkCompletedFields()) {
      return toast.warning('Preencha todos os campos');
    }

    if (!checkSelectedFile()) {
      return toast.warning('Selecione um documento para envio');
    }

    const data = {
      name: name,
      desc: description,
      file: file,
      manufacturerId: manufacturerId
    };

    dispatch(sendWarrantyDownload(data));
  }

  const checkCompletedFields = () => {
    return name && description;
  }

  const checkSelectedFile = () => {
    return file;
  }

  useEffect(() => {
    if (isOpen && error) {
      toast.error(error);
    }
  }, [error]);

  useEffect(() => {
    const isDownloadAdded = warrantyDownloadAdded != null;
    if (isOpen && isDownloadAdded) {
      resetValues();
      toast.success('Documento enviado com sucesso!');
      toggle();
    }
  }, [warrantyDownloadAdded]);

  const resetValues = () => {
    setName('');
    setDescription('');
    setFile(null);
  }

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>Envie um novo documento</ModalHeader>
      <ModalBody>
        <Row className='d-flex justify-content-start'>
          <Col>
            <Form>
              <FormGroup>
                <Label>
                  Nome:* 
                </Label>
                <Input
                  type="text"
                  placeholder='Informe um nome'
                  value={name}
                  onChange={(e) => setName(e.target.value)} />
              </FormGroup>

              <FormGroup className="mt-2">
                <Label>
                  Descrição:* 
                </Label>
                <Input
                  type="textarea"
                  placeholder='Informe uma descrição'
                  value={description}
                  onChange={(e) => setDescription(e.target.value)} />
              </FormGroup>

              <FormGroup className="mt-2">
                <Label>
                  Arquivo:*
                </Label>
                <Input className='ms-2' type="file" onChange={handleFileChange} />
              </FormGroup>
            </Form>
          </Col>
        </Row>
        <ModalFooter>
          <ButtonLoader 
            color="light"
            colorBg={ colorDefault } 
            type="submit" 
            disabled={loading}
            loading={loading}
            onClick={handleClickSendDownload}
            text="Enviar" /> 
            {' '}
          <Button disabled={loading} color="danger" onClick={toggle}>
            Cancelar
          </Button>
        </ModalFooter>
      </ModalBody>
    </Modal>
  );
};

export default AddWarrantyDownload;