import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Container,
  CardBody,
  Col,
  Row,
  Card,
  Label,
  Spinner,
} from "reactstrap";
import { Redirect, useParams } from "react-router-dom";

import {
  getOrderActive,
  getProductDetails,
  clearNewOrder,
  addProductBySku,
  removeOrderActive,
} from "../../store/actions";
import Breadcrumb from "../../components/Breadcrumb";
//import Component
import Banner from "./Banner";
import "./newOrder.css";
import OrderFilter from "./OrderFilter";
import FindedItem from "./FindedItem";
import AddedItem from "./AddedItem";

import Frete from "./Frete";
import RegisterUser from "./RegisterUser";
import Saldo from "./Saldo";
import Cupons from "./Cupons";
import Arquiteto from "./Arquiteto";
import { formato } from "../../utils";
import Resumo from "./Resumo";
import PedidoEmitido from "./PedidoEmitido";
import ControleRemoto from "./ControleRemoto";
import { toast } from "react-toastify";

const Dashboard = ({ history }) => {
  const dispatch = useDispatch();
  const { newOrder, loading, order, addedItems } = useSelector(state => ({
    newOrder: state.Order.newOrder.newOrder,
    loading: state.Order.loading,
    order: state.Order.orderActive.order,
    addedItems: state.Order.newOrder.newOrder.addedItems,
  }));
  const { id } = useParams();
  const [frete, setFrete] = useState({});
  const [valores, setValores] = useState({});
  const [valArquiteto, setValArquiteto] = useState({});
  const [valorRt, setValorRt] = useState({});
  const [ocultarSemEstoque, setOcultarSemEstoque] = useState(true);
  const [ocultarRt, setOcultarRt] = useState(false);
  const [nfIsOpen, setNfIsOpen] = useState(false);
  const [obsIsOpen, setObsIsOpen] = useState(false);
  const [cepCliente, setCepCliente] = useState(null);
  const [pagtoSelected, setPagtoSelected] = useState([]);
  const [client, setClient] = useState({});
  const [saldo, setSaldo] = useState(0);
  const [itensCarrinho, setItensCarrinho] = useState({});
  const [filterPedido, setFilterPedido] = useState({});
  const [pedidoEmitido, setPedidoEmitido] = useState(false);
  const [allProducts, setAllProducts] = useState([]);
  const [productsObj, setProductsObj] = useState({});
  const [sortedObj, setSortedObj] = useState({});

  const [type, setType] = useState("order");

  const valTot = Somar(Object.values(valores));
  const valArq = Somar(Object.values(valArquiteto));
  const valRt = Somar(Object.values(valorRt));

  useEffect(() => {
    dispatch(clearNewOrder());
    setFrete({});
    setValores({});
    setValArquiteto({});
    setValorRt({});
    setOcultarSemEstoque(true);
    setOcultarRt(false);
    setNfIsOpen(false);
    setObsIsOpen(false);
    setCepCliente(null);
    setPagtoSelected([]);
    setClient({});
    setSaldo(0);
    setItensCarrinho({});
    setFilterPedido({});
    setPedidoEmitido(false);
    setType("order");
  }, []);

  useEffect(() => {
    if (id) {
      toast.warning(
        "Atenção: valores de equipamentos e frete poderão serem alterados.",
        { theme: "colored" }
      );
      dispatch(getOrderActive(id));
    } else {
      dispatch(removeOrderActive());
    }
  }, [id]);

  useEffect(() => {
    if (id) {
      (order?.items_orcamento || []).map(value => {
        dispatch(getProductDetails(value.Produto));
        const result = addedItems.find(i => i.cod === value.Produto);
        if (!result) {
          let data = {
            sku: value.Produto,
            tabela: order.TabelaPreco,
            pagto: order.CondicaoPagamento,
            filial: value.CodFilial,
            precoVenda: value.PrecoVend,
            quant: value.Qtdven,
            item: value.Item,
          };
          dispatch(addProductBySku(data));
        }
      });
    }
  }, [order]);

  useEffect(() => {
    if (allProducts.length >= 1) {
      setProductsObj(groupBy(allProducts, "MARCA"));
    }
  }, [allProducts]);

  useEffect(() => {
    handleSortingObj();
  }, [productsObj]);

  const pedido = {
    client: client,
    type_order: "order",
    product_type: "",
    budget_infos: {
      codigo_cliente: 0,
      tipo_cliente: 0,
    },
    items: retornaItems(),
    shipping: {
      id: frete?.id || "",
      shipping_selected: {
        delivery_delay: frete?.delivery_delay || "",
        id: frete?.id || "",
        price: frete?.price || "",
        description: frete?.description || "",
        price_percent: frete?.price_percent || "",
        usado_percentual: frete?.usado_percentual || "",
        frete_gratis: frete?.frete_gratis || "",
      },
      price: frete?.price || "",
    },
    filters: filterPedido,
    services: {
      product_type: "",
      ambientes: [],
    },
    discountcashback: saldo,
  };

  const handleSortingObj = () => {
    if (Object.keys(productsObj).length >= 1) {
      let selectedDropdown = "PREÇO";
      switch (selectedDropdown) {
        case "PREÇO":
          Object.keys(productsObj).map(a => {
            setSortedObj(prev => ({
              ...prev,
              [a]: productsObj[a].sort((a, b) => {
                if (
                  a.PRCVENDA.STRETPRCFILADIAS[0].PRCVENDA >
                  b.PRCVENDA.STRETPRCFILADIAS[0].PRCVENDA
                ) {
                  return 1;
                }
                if (
                  a.PRCVENDA.STRETPRCFILADIAS[0].PRCVENDA <
                  b.PRCVENDA.STRETPRCFILADIAS[0].PRCVENDA
                ) {
                  return -1;
                }
              }),
            }));
          });
          break;
        case "EFICIENCIA":
          Object.keys(productsObj).map(a => {
            setSortedObj(prev => ({
              ...prev,
              [a]: productsObj[a].sort((c, d) => {
                let order = { " ": -6, E: -5, D: -4, C: -3, B: -2, A: -1 };
                return (order[c.SELOA] || 0) - (order[d.SELOA] || 0);
              }),
            }));
          });
          break;
        case "DISPONIBILIDADE":
          Object.keys(productsObj).map(a => {
            productsObj[a].sort((a, b) => {
              if (a.SELOA > b.SELOA) {
                return 1;
              }
              if (a.SELOA < b.SELOA) {
                return -1;
              }
            });
          });
          break;

        default:
          break;
      }
    }
  };

  function retornaItems() {
    let items = [];

    newOrder.addedItems.map(value => {
      function getEstoques(es) {
        let result =
          value.produto.PRDDISPS?.STRETPRDFILADIAS?.find(
            i => i.CODFILPRD === es
          ) || "";
        return result?.ESTOQUE || 0;
      }

      let produto = {
        ...value.produto,
        PRCVENDA: value.precoVenda,
        ESTOQUE_CD01: getEstoques("01"),
        ESTOQUE_CD02: getEstoques("02"),
        ESTOQUE_CD03: getEstoques("03"),
        ESTOQUE_CD04: getEstoques("04"),
        precos: {
          cd:
            value.produto.PRDDISPS?.STRETPRDFILADIAS[value.filial]?.CODFILPRD ||
            "",
          preco: value.precoVenda,
          diferente: "N",
        },
        PRECODIFER:
          value.produto.PRCVENDA?.STRETPRCFILADIAS[value.filial]?.PRECODIFER ||
          "",
        ESTOQUE:
          value.produto.PRDDISPS?.STRETPRDFILADIAS[value.filial]?.ESTOQUE || "",
        instalacao: false,
        VALSERV: 0,
        ...itensCarrinho[value.produto.CODPRODUTO],
      };

      items.push(produto);
    });

    return items;
  }

  function Somar(array) {
    var total = 0;
    for (var i = 0; i < array.length; i++) {
      if (array[i] >= 2) {
        total += array[i];
      }
    }
    return total;
  }

  function groupBy(arr, property) {
    return arr.reduce(function (memo, x) {
      if (!memo[x[property]]) {
        memo[x[property]] = [];
      }
      memo[x[property]].push(x);
      return memo;
    }, {});
  }

  const addValTot = valor => {
    setValores(valor);
  };

  const addValArqu = valor => {
    setValArquiteto(valor);
  };

  const addValorRt = valor => {
    setValorRt(valor);
  };

  const addFrete = valor => {
    setFrete(valor);
  };

  const changeOcultarRt = () => {
    setOcultarRt(!ocultarRt);
  };

  const changeType = type => {
    setType(type);
  };

  const changeCepCliente = cep => {
    setCepCliente(cep);
  };

  const changePagtoSelected = pagto => {
    setPagtoSelected(pagto);
  };

  const changeSaldo = saldo => {
    setSaldo(Number(saldo));
  };

  const changeClient = client => {
    setClient(client);
  };

  const changeItensCarrinho = itens => {
    setItensCarrinho(itens);
  };

  const addFilterPedido = filter => {
    setFilterPedido(filter);
  };

  const changePedidoEmitido = () => {
    setPedidoEmitido(!pedidoEmitido);
  };

  useEffect(() => {
    if (newOrder.orderProducts) {
      setAllProducts(newOrder.orderProducts.produtos);
    }
  }, [newOrder.orderProducts]);

  const renderView = () => (
    <React.Fragment>
      <Breadcrumb title="Página Inicial" breadcrumbItem="Emissão do pedido" />
      <Row>
        <Col md={12}>
          <div className="mb-4">
            {/* import Banner */}
            <Banner />
          </div>
        </Col>
      </Row>

      {loading && (
        <div className="carregando-emissao">
          <Spinner />
        </div>
      )}

      {!pedidoEmitido ? (
        <React.Fragment>
          <OrderFilter
            ocultarRt={ocultarRt}
            changeOcultarRt={changeOcultarRt}
            changePagtoSelected={changePagtoSelected}
            addFilterPedido={addFilterPedido}
            filterPedido={filterPedido}
            history={history}
          />
          <hr style={{ borderTop: "1.5px solid #D5DCE5" }} />

          {newOrder?.orderProducts ? (
            <Row className="mt-4" id="busca">
              <Col>
                {newOrder.orderProducts.isLoading ? (
                  <>
                    <Spinner style={{ margin: "20% 50%" }} className="mt-4" />
                  </>
                ) : allProducts.length !== 0 ? (
                  <Row className="mt-4" id="busca">
                    <hr />
                    <Col>
                      <Row>
                        <Col>
                          <Label className="label-titulo">
                            Produtos Encontrados:{" "}
                          </Label>
                        </Col>
                      </Row>
                      <Col>
                        {Object.keys(sortedObj).map((i, k) => {
                          //check if product is out of stock
                          let x = 0;
                          while (
                            sortedObj[i][x].PRDDISPS.STRETPRDFILADIAS[0]
                              .ESTOQUE <= 0
                          ) {
                            x++;
                          }
                          return (
                            <Card key={k} className="px-4 py-4">
                              <h2>Orçamento {k + 1}: </h2>
                              <CardBody>
                                <FindedItem
                                  produto={sortedObj[i][x]}
                                  ocultarSemEstoque={ocultarSemEstoque}
                                />
                              </CardBody>
                            </Card>
                          );
                        })}
                      </Col>
                    </Col>
                  </Row>
                ) : null}
              </Col>
            </Row>
          ) : (
            <div />
          )}
          <hr style={{ borderTop: "1.5px solid #D5DCE5" }} />
          {(newOrder?.addedItems.length || 0) > 0 ? (
            <Row className="mt-4" id="carrinho">
              <Col>
                <h4
                  className="mb-4"
                  style={{ fontSize: 18, fontWeight: "bold" }}
                >
                  Produtos Adicionados
                </h4>

                {newOrder.addedItems.map(item => (
                  <AddedItem
                    key={item.cod}
                    item={item}
                    addValTot={addValTot}
                    valores={valores}
                    ocultarRt={ocultarRt}
                    changeItensCarrinho={changeItensCarrinho}
                    itensCarrinho={itensCarrinho}
                    valArquiteto={valArquiteto}
                    addValArqu={addValArqu}
                    valorRt={valorRt}
                    addValorRt={addValorRt}
                  />
                ))}

                <Card
                  style={{
                    borderRadius: "0 0  15px 15px",
                    backgroundColor: "#EBFFE5",
                    zIndex: 100,
                    marginTop: -15,
                  }}
                >
                  <CardBody>
                    <Row className="d-flex justify-content-end">
                      <Col md={4} className="d-flex justify-content-between">
                        <h4
                          className="mb-0"
                          style={{ color: "#1B7700", fontWeight: "bold" }}
                        >
                          Subtotal
                        </h4>
                        <h4
                          className="mb-0"
                          style={{ color: "#1B7700", fontWeight: "bold" }}
                        >
                          {Number(valTot).toLocaleString("pt-br", formato)}
                        </h4>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          ) : (
            <div />
          )}
          <Row id="sentinel" className="mt-4">
            <RegisterUser
              valTot={valTot}
              changeCepCliente={changeCepCliente}
              pagtoSelected={pagtoSelected}
              changeClient={changeClient}
              client={client}
            />
          </Row>
          <Row className="mt-3" id="notas">
            <Cupons />
          </Row>
          <Row className="mt-3">
            <Saldo changeSaldo={changeSaldo} />
          </Row>
          <Row className="mt-3">
            <Card>
              <CardBody>
                <Row>
                  <Col md={11}>
                    <p
                      className="mb-0 d-flex align-items-center"
                      style={{ fontWeight: "bold" }}
                    >
                      <i
                        className="ph-notepad"
                        style={{
                          color: "#13b013",
                          marginRight: 20,
                          fontSize: 25,
                        }}
                      ></i>
                      Adicionar Observação Interna:
                    </p>
                  </Col>
                  <Col md={1}>
                    <a
                      href="#"
                      style={{
                        width: "fit-content",
                        marginLeft: "auto",
                        display: "block",
                      }}
                      onClick={event => {
                        event.preventDefault();
                        setObsIsOpen(!obsIsOpen);
                      }}
                    >
                      {obsIsOpen ? (
                        <i className="fas fa-chevron-up"></i>
                      ) : (
                        <i className="fas fa-chevron-down"></i>
                      )}
                    </a>
                  </Col>
                </Row>
                <Row
                  hidden={!obsIsOpen}
                  style={{
                    marginTop: 30,
                    paddingTop: 30,
                    borderTop: "1px solid #d4d4d4",
                  }}
                >
                  <textarea className="form-control" />
                </Row>
              </CardBody>
            </Card>
          </Row>
          <Row className="mt-3">
            <Card>
              <CardBody>
                <Row>
                  <Col md={11}>
                    <p
                      className="mb-0 d-flex align-items-center"
                      style={{ fontWeight: "bold" }}
                    >
                      <i
                        className="ph-notepad"
                        style={{
                          color: "#13b013",
                          marginRight: 20,
                          fontSize: 25,
                        }}
                      ></i>{" "}
                      Adicionar Observação de Nota Fiscal:
                    </p>
                  </Col>
                  <Col md={1}>
                    <a
                      href="#"
                      style={{
                        width: "fit-content",
                        marginLeft: "auto",
                        display: "block",
                      }}
                      onClick={event => {
                        event.preventDefault();
                        setNfIsOpen(!nfIsOpen);
                      }}
                    >
                      {nfIsOpen ? (
                        <i className="fas fa-chevron-up"></i>
                      ) : (
                        <i className="fas fa-chevron-down"></i>
                      )}
                    </a>
                  </Col>
                </Row>
                <Row
                  hidden={!nfIsOpen}
                  style={{
                    marginTop: 30,
                    paddingTop: 30,
                    borderTop: "1px solid #d4d4d4",
                  }}
                >
                  <textarea className="form-control" />
                </Row>
              </CardBody>
            </Card>
          </Row>
          <Row className="d-flex justify-content-between" id="frete">
            <Col md={7}>
              <Frete
                valTot={valTot}
                addFrete={addFrete}
                cepCliente={cepCliente}
              />
              {/* <Arquiteto valArq={valArq} /> */}
            </Col>
            <Col md={5}>
              <Resumo
                valTot={valTot}
                valRt={valRt}
                frete={frete}
                saldo={saldo}
                pedido={pedido}
                changePedidoEmitido={changePedidoEmitido}
                changeType={changeType}
              />
            </Col>
          </Row>
          <ControleRemoto />
        </React.Fragment>
      ) : (
        <PedidoEmitido changePedidoEmitido={changePedidoEmitido} type={type} />
      )}
    </React.Fragment>
  );

  return (
    <React.Fragment>
      <div className="page-content neworder">
        <Container>
          {id ? (
            order ? (
              order?.Situacao < "F" ? (
                addedItems.length > 0 ? (
                  <React.Fragment>{renderView()}</React.Fragment>
                ) : (
                  <Spinner />
                )
              ) : (
                <Redirect to={`/pedidos/${order?.NumPedido}/order`} />
              )
            ) : (
              <Spinner />
            )
          ) : (
            renderView()
          )}
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Dashboard;
