import React from "react";
import { TitleInputs } from "../../style";
import { ArInput } from "../../../../../components/adias-input";
import { Col, Row } from "reactstrap";

export const Contato = ({ errors, touched, handleBlur }) => {
  return (
    <>
      {" "}
      <TitleInputs>CONTATO</TitleInputs>
      <Row>
        {" "}
        <Col md={12}>
          <ArInput
            title="Email"
            errors={errors}
            name="EMAILCONT_PF"
            touched={touched}
            onBlur={handleBlur}
          />
        </Col>
      </Row>
      <Row>
        <Col md={2}>
          <ArInput
            title="DDD"
            errors={errors}
            name="DDDCONT_PF"
            touched={touched}
            maxLength={2}
            onBlur={handleBlur}
          />
        </Col>
        <Col md={4}>
          <ArInput
            title="Fixo"
            errors={errors}
            name="TELCONT_PF"
            touched={touched}
            mask="phoneNoDDD"
            onBlur={handleBlur}
          />
        </Col>
        <Col md={2}>
          <ArInput
            title="DDD"
            errors={errors}
            name="DDDFCONT_PF"
            touched={touched}
            maxLength={2}
            onBlur={handleBlur}
          />
        </Col>
        <Col md={4}>
          <ArInput
            title="Celular"
            errors={errors}
            name="TELFCONT_PF"
            touched={touched}
            mask="phoneNoDDD"
            onBlur={handleBlur}
          />
        </Col>
      </Row>
    </>
  );
};
