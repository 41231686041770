import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "reactstrap";
import { useSelector, useDispatch } from "react-redux";

// actions
import { getDashboardDetails } from "../../store/actions";

//import Component
import Banner from "./Banner";
import MiniWidget from "./MiniWidget";
import Category from "./Category";
import OrderStatus from "./OrderStatus";
import TopVendors from "./TopVendors";
import Benefits from "./Benefits";
import Timeline from "./Timeline";
import YouWin from "./YouWin";
import Guarantee from "./Guarantee";
import Performance from "./Performance";
import PurchasePotential from "./PurchasePotential";
import "./Dashboard.css";
import { useSolar } from "../../hooks";
import { useLocation } from "react-router-dom";
import { Modal } from "react-bootstrap";

const Dashboard = () => {
  const dispatch = useDispatch();
  const { dashboardDetails } = useSelector(state => ({
    dashboardDetails: state.Dashboard.dashboardDetails,
  }));

  const location = useLocation();

  const isSolar = useSolar();
  const [showModal, setShow] = useState(false);

  /*
  get dashboard details
  */

  useEffect(() => {
    if (location?.search?.includes("open_modal")) {
      setShow(true);
    }
  }, []);

  const handleClose = () => {
    setShow(false);
  };

  useEffect(() => {
    dispatch(getDashboardDetails(isSolar ? "02" : "01"));
  }, [dispatch]);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container className="dashboard">
          {/* banner */}
          <Row>
            <Col md={12}>
              <div className="mb-4">
                <Banner
                  bannerItems={dashboardDetails?.mini_banner?.values || []}
                />
              </div>
            </Col>
          </Row>

          {/* mini widgets */}
          <MiniWidget data={dashboardDetails} />
          <div>
            <hr />
          </div>
          <Row>
            {dashboardDetails?.fidelidade ? (
              <Col xs={12} lg={4}>
                <Category data={dashboardDetails} />
              </Col>
            ) : (
              <div />
            )}

            <Col xs={12} lg={dashboardDetails?.fidelidade ? 8 : 12}>
              <OrderStatus data={dashboardDetails} />
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <TopVendors data={dashboardDetails} />
            </Col>
          </Row>
          <div>
            <hr />
          </div>
          <Row>
            <Col xs={12} lg={4}>
              <Benefits data={dashboardDetails} />
              {/* <YouWin data={dashboardDetails} /> */}
            </Col>
            <Col xs={12} lg={8}>
              {dashboardDetails && dashboardDetails.length > 0 && (
                <Timeline
                  data={
                    dashboardDetails?.notifications?.original
                      ?.last_10_unread_notifications || []
                  }
                />
              )}
              <Guarantee data={dashboardDetails} />
            </Col>
          </Row>
          <div>
            <hr />
          </div>
          <Row>
            <Col xs={12}>
              <Performance />
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <PurchasePotential data={dashboardDetails} />
            </Col>
          </Row>
        </Container>
      </div>
      {dashboardDetails?.dashModal?.length > 0 && (
        <Modal show={showModal} onHide={handleClose}>
          <Modal.Body
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              padding: dashboardDetails?.dashModal[0]?.link_imagem ? 0 : 20,
            }}
          >
            {dashboardDetails?.dashModal[0]?.link_imagem ? (
              <img
                width="100%"
                src={dashboardDetails?.dashModal[0]?.link_imagem}
              />
            ) : (
              <div
                dangerouslySetInnerHTML={{
                  __html: dashboardDetails?.dashModal[0]?.post_content,
                }}
              />
            )}
          </Modal.Body>
        </Modal>
      )}
    </React.Fragment>
  );
};

export default Dashboard;
