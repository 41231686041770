import React, { useState } from 'react';
import { Container, Alert } from 'reactstrap';
import "./DfaEnable.css";
import qrcode from "../../assets/images/vendedores/qrcode.png"
const DfaEnable = () => {
    const [temConsulta, setTemConsulta] = useState(true)
    return (
        <React.Fragment>
            <div className="page-content DfaEnable">
                <Container>
                    <h1 className="titulo-autenticacao">Autenticação em 2 Passos</h1>
                    <div>
                        {temConsulta &&
                            <Alert className='alert-autenticacao'>
                                Você Entrou! Esta disponível em sua conta a autenticação em 2 Passos. Aumente a segurança da sua conta. Veja mais.
                                <a href="#" onClick={(e) => {
                                    e.preventDefault();
                                    setTemConsulta(!temConsulta)
                                }}>
                                    <i className="ph-x"></i>
                                </a>
                            </Alert>
                        }
                    </div>
                    <h4 className='subtitulo-autenticacao'>Garanta a segurança de sua conta com a autenticação em 2 passos ADias</h4>
                    <hr />
                    <p>Como realizar a autenticação?</p>
                    <ol className='lista-realizar-autenticacao'>
                        <li>Acesse a loja de Aplicativos do seu Celular
                            <a href='https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=pt_BR' className='link-app'> Android </a>
                            ou
                            <a href='https://apps.apple.com/br/app/google-authenticator/id388497605' className='link-app'> Iphone </a>
                            e Baixe o Google Authenticator;
                        </li>
                        <li>Escanei o Código Abaixo;</li>
                        <li>Clique em Ativar, mas não se esqueça de copiar a chave que aparece abaixo do seu código, ela é sua segurança caso algo aconteça com seu dispositivo;</li>
                        <li>Após a ativação. Um novo login será solicitado, desta vez pedindo um código.</li>
                    </ol>
                    <div className='engloba-qrcode'>
                        <img src={qrcode} />
                        <h4 className='token'>Token: N2VSSV6UFKJU4ELR</h4>
                        <a href='/2fa/validate' className='btn-ativar-autenticacao'>Ativar autenticação</a>
                    </div>
                </Container>
            </div>
        </React.Fragment >
    );
};
export default DfaEnable;