import { apiClient } from "./apiCore";

export const submitForm = data => {
  const baseUrl = "/api/cartrules/storerule";

  return apiClient.create(baseUrl, JSON.stringify(data));
};

export const updateForm = data => {
  const baseUrl = "/api/cartrules/storerule";
  return apiClient.create(baseUrl, data);
};

export const getRulesColumnsFields = () => {
  const baseUrl = "/api/cartrules/rulesColumnsFields";
  return apiClient.get(baseUrl);
};

export const getRules = id => {
  const baseUrl = `/api/cartrules/getrules?id=${id}`;
  return apiClient.get(baseUrl);
};
