import React from "react";
import {
  CardBody,
  Container,
  Card,
  Form,
  FormGroup,
  Label,
  Input,
  Col,
  Button,
} from "reactstrap";
import "./imageCreator.css";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import imgcreator1 from "../../assets/images/vendedores/img-creator1.png";
import imgcreator2 from "../../assets/images/vendedores/img-creator2.png";

//importar aqui os templates do solar

import { useSolar } from "../../hooks";

const ImageCreator = () => {
  const isSolar = useSolar();
  return (
    <React.Fragment>
      <div className="page-content image-creator">
        <Container>
          <Card>
            <CardBody>
              <h4>Criador de Imagens</h4>
              <hr />
              <Form>
                <Col md={8}>
                  <FormGroup>
                    <Label for="nomeDoTemplate">Nome do Template:</Label>
                    <Input
                      id="nomeDoTemplate"
                      name="nomeDoTemplate"
                      placeholder=""
                      type="text"
                    />
                  </FormGroup>
                </Col>
                <img src={isSolar ? "" : imgcreator1} className="img-1" />
                <Form className="area-edicao">
                  <FormGroup row>
                    <Col sm={8}>
                      <ReactQuill
                        theme="snow"
                        id="texto"
                        placeholder="Digite um local"
                      />
                    </Col>
                  </FormGroup>
                </Form>
                <img src={isSolar ? "" : imgcreator2} className="img-2" />
              </Form>
              <div className="engloba-test-template">
                <p>Seus Templates:</p>
                <div className="engloba-botoes">
                  <p>Test Templete</p>
                  <Button className="botao-search">
                    <i className="ph-magnifying-glass"></i>
                  </Button>
                  <Button className="botao-trash">
                    <i className="ph-trash"></i>
                  </Button>
                </div>
              </div>
              <Button
                className="botao-salvar"
                style={{
                  backgroundColor: isSolar && "#FF601A",
                  borderColor: isSolar && "#FF601A",
                }}
              >
                Salvar
              </Button>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ImageCreator;
