import React from "react";
import { Card, CardBody } from "reactstrap";

const RankingAchieved = () => {
  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <h5 className="text-success">
            RANKING ACHIEVED DURING THE LAST MONTHS
          </h5>
          <hr />
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default RankingAchieved;
