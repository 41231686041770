import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";

//i18n
import { withTranslation } from "react-i18next";
import SidebarNav from "./SidebarNav";

import logoDark from "../../assets/images/logo-dark.png";
import logoSolar from "../../Solar/assets/images/logos/logo-solar.png";

const Sidebar = props => {
  function tToggle() {
    var body = document.body;
    body.classList.toggle("vertical-collpsed");
    body.classList.toggle("sidebar-enable");
  }
  return (
    <React.Fragment>
      <div
        className={`vertical-menu`}
        style={{ backgroundColor: props.solar && "white" }}
      >
        <div
          className="navbar-brand-box"
          style={{ backgroundColor: props.solar && "white" }}
        >
          <Link to="/" className={`logo logo-dark`}>
            <span className="logo-sm">
              <img
                src={props.solar ? logoSolar : logoDark}
                alt=""
                height="20"
              />
            </span>
            <span className="logo-lg">
              <img
                src={props.solar ? logoSolar : logoDark}
                alt=""
                height="20"
              />
            </span>
          </Link>

          <Link
            to={props.solar ? "/solar/" : "/"}
            className={`logo logo-light ${
              props.solar && "d-flex justify-content-center align-items-center"
            }`}
          >
            <span className="logo-sm">
              <img
                src={props.solar ? logoSolar : logoDark}
                alt=""
                height="20"
              />
            </span>
            <span className="logo-lg">
              <img
                src={props.solar ? logoSolar : logoDark}
                alt=""
                height="60"
              />
            </span>
          </Link>
        </div>
        <button
          onClick={() => {
            tToggle();
          }}
          type="button"
          className="btn btn-sm px-3 font-size-16 header-item waves-effect vertical-menu-btn"
        >
          {/* <i className="fa fa-fw fa-bars"></i> */}
        </button>
        <div data-simplebar className="h-100">
          <SidebarNav solar={props.solar} />
        </div>
      </div>
    </React.Fragment>
  );
};

Sidebar.propTypes = {
  type: PropTypes.string,
};

const mapStatetoProps = state => {
  return {
    layout: state.Layout,
  };
};
export default connect(
  mapStatetoProps,
  {}
)(withRouter(withTranslation()(Sidebar)));
