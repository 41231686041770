import { ADD_STATUS, REMOVE_STATUS } from "./actionTypes";

const INIT_STATE = {
  status: [],
};

const KitsNew = (state = INIT_STATE, action) => {
  switch (action.type) {
    case ADD_STATUS:
      return {
        ...state,
        status: [...state.status, action.payload],
      };
    case REMOVE_STATUS:
      return {
        status: [],
      };
    default:
      return state;
  }
};

export default KitsNew;
