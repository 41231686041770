import React from "react";
import { Card, CardBody, Col, Row } from "reactstrap";

// components
import SvgIcon from "../../components/SvgIcon";

const MyRating = () => {
  return (
    <React.Fragment>
      <Row className="mt-4 my-rating">
        <Col xs={12} lg={6}>
          <Card className="mb-0">
            <CardBody className="px-0">
              <Row className="g-0 mb-5">
                <Col md={6} className="widget">
                  <div className="px-4">
                    <p className="text-muted mb-1">Pedidos bo Mes</p>
                    <h4 className="mb-0">3</h4>
                    <div className="snipets text-end mt-n2">
                      <SvgIcon
                        name="clipboard-check"
                        className="icon icon-xl"
                      />
                    </div>
                  </div>
                </Col>
                <Col md={6}>
                  <div className="px-4">
                    <p className="text-muted mb-1">Faturamento do Mês</p>
                    <h4 className="mb-0">R$ 1.010,01</h4>
                    <div className="snipets text-end mt-n2">
                      <SvgIcon name="page" className="icon icon-xl" />
                    </div>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
        <Col xs={12} lg={6}>
          <Card className="mb-0">
            <CardBody className="px-0">
              <Row className="g-0">
                <Col md={6} className="widget">
                  <div className="px-4">
                    <p className="text-muted mb-1">Pedidos bo Mes</p>
                    <h4 className="mb-0">3</h4>
                    <div className="snipets text-end mt-n2">
                      <SvgIcon
                        name="clipboard-check"
                        className="icon icon-xl"
                      />
                    </div>
                  </div>
                </Col>
                <Col md={6}>
                  <div className="px-4">
                    <p className="text-muted mb-1">Faturamento do Mês</p>
                    <h4 className="mb-0">R$ 1.010,01</h4>
                    <div className="snipets text-end mt-n2">
                      <SvgIcon name="page" className="icon icon-xl" />
                    </div>
                  </div>
                </Col>
              </Row>
              <p className="text-end text-muted mb-1 mt-4 pe-3">
                Sazão atual 01 / 0 4 / 2021 - 31 / 08 / 2021
              </p>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default MyRating;
