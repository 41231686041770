import React from "react";

import { Col, Button, Row } from "reactstrap";

const LeadsPagination = ({ totalPages, index, changeIndex }) => {
  let page_active = Number(index);
  let total_pages = Number(totalPages);
  let pages = [];
  let page_initial = page_active > 3 ? page_active - 3 : 1;
  let page_final =
    page_active + 3 <= total_pages ? page_active + 3 : total_pages;
  for (let page = page_initial; page <= page_final; page++) {
    pages.push(page);
  }

  return (
    <React.Fragment>
      <Row className="my-5">
        <Col md={3}>
          <p>{total_pages} Páginas de Resultados</p>
        </Col>
        <Col md={5} className="d-flex justify-content-between">
          {!(page_active == 0) ? (
            <Button color="info" outline onClick={() => changeIndex(0)}>
              <i className="fas fa-angle-double-left"></i>
            </Button>
          ) : (
            <div />
          )}

          {pages.map(value => {
            return (
              <Button
                key={value}
                color="info"
                outline={!(value === page_active + 1)}
                onClick={() => changeIndex(value - 1)}
              >
                {value}
              </Button>
            );
          })}

          {!(page_active == total_pages - 1) ? (
            <Button
              color="info"
              outline
              onClick={() => changeIndex(total_pages - 1)}
            >
              <i className="fas fa-angle-double-right"></i>
            </Button>
          ) : (
            <div />
          )}
        </Col>
        <Col md={3} />
      </Row>
    </React.Fragment>
  );
};

export default LeadsPagination;
