import React, { useEffect } from "react";
import { Col, FormGroup, Input, Label, Spinner } from "reactstrap";
import FindedItem from "../FindedItem";
import ProductsPagination from "../ProductsPagination";
import { useSelector } from "react-redux";
import { useDataLayer } from "../../../hooks/DataLayerHooks";

const ListProducts = ({
  paginatedProducts,
  ocultarSemEstoque,
  setOcultarSemEstoque,
  currentPage,
  isRevenda,
  dataProducts,
  setCurrentPage,
}) => {
  const { isLoading, success, produtos } = useSelector(state => {
    return {
      isLoading: state.Order.newOrder.newOrder.orderProducts?.isLoading,
      success: state.Order.newOrder.newOrder.orderProducts?.success,
      produtos: state.Order.newOrder.newOrder.orderProducts?.produtos,
    };
  });

  const { pushToDataLayer } = useDataLayer();

  useEffect(() => {
    if (success) {
      const itemsDataLayer = produtos?.map(product => ({
        item_id: product.CODPRODUTO,
        item_name: product.DESCRICAO,
        item_brand: product.MARCA,
        item_category: product.CATEGORIA,
        price: product.PRCVENDA?.STRETPRCFILADIAS[0]?.PRCVENDA,
        quantity: 1
      }));
  
      pushToDataLayer(
        'view_item_list',
        {
          ecommerce: {
            currency: "BRL",
            items: itemsDataLayer
          }
        }
      );
    } 
  }, [success]);

  return (
    <Col>
      <a id="title-result-list-products">‎</a> {/* scroll até aqui quando o usuário clicar em um dos botões de paginação */}
      <h4 className="mt-4" style={{ fontSize: 20, color: "#2D567B" }}>Produtos Encontrados:</h4>

      {isLoading ? (
        <>
          <Spinner style={{ margin: "20% 50%" }} className="mt-4" />
        </>
      ) : produtos?.length > 0 &&
        paginatedProducts.length > 0 ? (
        <>
          {paginatedProducts[currentPage]
            .sort((a, b) => {
              if (
                a.PRCVENDA.STRETPRCFILADIAS[0].PRCVENDA >
                b.PRCVENDA.STRETPRCFILADIAS[0].PRCVENDA
              ) {
                return 1;
              }
              if (
                a.PRCVENDA.STRETPRCFILADIAS[0].PRCVENDA <
                b.PRCVENDA.STRETPRCFILADIAS[0].PRCVENDA
              ) {
                return -1;
              }
            })
            .map((value, key) => (
              <FindedItem
                id={key}
                key={value.CODPRODUTO}
                produto={value}
                ocultarSemEstoque={ocultarSemEstoque}
                isRevenda={isRevenda}
              />
            ))}{" "}
          <Col md={12}>
            <ProductsPagination
              unsortedProducts={dataProducts}
              products={paginatedProducts}
              page={currentPage}
              changePage={setCurrentPage}
            />
          </Col>
        </>
      ) : (
        <div>
          <p>Não foram encontrados produtos</p>
        </div>
      )}
    </Col>
  );
};

export default ListProducts;
