import { call, put, takeEvery, all, fork } from "redux-saga/effects";
// Crypto Redux States
import { BudgetActionTypes } from "./actionTypes";
import { budgetApiSuccess, budgetApiFail } from "./action";

import {
  listBudgets as listBudgetsApi,
  getBudget as getBudgetApi,
} from "../../api/index";
import { useSolar } from "../../hooks";
import { toast } from "react-toastify";

function* listBudgets({ payload: filters }) {
  try {
    let data = {
      filters: filters,
    };
    let response = yield call(listBudgetsApi, data);

    if (response.success) {
      yield put(
        budgetApiSuccess(BudgetActionTypes.LIST_BUDGETS, response.budgetsList)
      );
    } else {
      toast.info(`Ocorreu um erro ao buscar os orçamentos. Tente novamente.`, {
        theme: "colored",
      });
      yield put(budgetApiFail(BudgetActionTypes.LIST_BUDGETS, ""));
    }
  } catch (error) {
    toast.info(`Ocorreu um erro ao buscar os orçamentos. Tente novamente.`, {
      theme: "colored",
    });
    yield put(budgetApiFail(BudgetActionTypes.LIST_BUDGETS, error));
  }
}

function* getBudget(props) {
  var id = props.payload.id;
  var codFilial = props.payload.codFilial;

  const isSolar = useSolar();
  try {
    let data = {
      id: id,
      empresa: isSolar ? "02" : "01",
      filial: codFilial,
    };
    let response = yield call(getBudgetApi, data);

    if (response.success) {
      yield put(budgetApiSuccess(BudgetActionTypes.GET_BUDGET, response.data));
    } else {
      toast.info(`Ocorreu um erro ao buscar o orçamento. Tente novamente.`, {
        theme: "colored",
      });
      yield put(budgetApiFail(BudgetActionTypes.GET_BUDGET, ""));
    }
  } catch (error) {
    toast.info(`Ocorreu um erro ao buscar o orçamento. Tente novamente.`, {
      theme: "colored",
    });
    yield put(budgetApiFail(BudgetActionTypes.GET_BUDGET, error));
  }
}

export function* watchListBudgets() {
  yield takeEvery(BudgetActionTypes.LIST_BUDGETS, listBudgets);
}
export function* watchGetBudget() {
  yield takeEvery(BudgetActionTypes.GET_BUDGET, getBudget);
}

function* budgetSaga() {
  yield all([fork(watchListBudgets), fork(watchGetBudget)]);
}

export default budgetSaga;
