import styled from "styled-components";

export const Input = styled.input`
  width: 100%;
  height: 35px;
  // border: none;
  border-radius: 5px;
  border: 1px solid #d2d2d2;
  padding: 0 10px;

  &.erros {
    border: 1px solid #7c0000;
  }

  &:focus {
    border: 1px solid ${props => (props.isSolar ? "#FF601A" : "#2D567B")};
  }
`;

export const Erros = styled.div`
  color: #7c0000;
`;

export const Label = styled.label`
  margin: 0;
  margin-bottom: 5px !important;
  margin-top: 0.5rem !important;
`;
