import React, { useEffect, useState } from "react";

// import "./promotionRules.css";

import { Alert, Col, Row } from "reactstrap";
import { Plus } from "phosphor-react";

import {
  addRuleCondition
} from "../functions";
import { AvField } from "availity-reactstrap-validation";
import { toast } from "react-toastify";
import { operatorOptions } from "../constants";
import { useSolar } from "../../../hooks";
import StateSelector from "../../../components/stateSpinner";

const RuleSelection = ({
  cartRuleData,
  rulesColumnFields = [],
  selectedRulesConditions,
  setSelectedRulesConditions
}) => {

  const isSolar = useSolar();

  const [selectedCartRuleForAdd, setSelectedCartRuleForAdd] = useState({});
  const [selectedProductRuleForAdd, setSelectedProductRuleForAdd] = useState({});
  const [cartRuleValueForAdd, setCartRuleValueForAdd] = useState("");
  const [productRuleValueForAdd, setProductRuleValueForAdd] = useState("");

  const [operatorCartRuleForAdd, setOperatorCartRuleForAdd] = useState(operatorOptions[0]);
  const [operatorProductRuleForAdd, setOperatorProductRuleForAdd] = useState(operatorOptions[0]);

  const [inputTypeCartValueForAdd, setInputTypeCartValueForAdd] = useState("text");
  const [inputPlaceHolderCartValueForAdd, setInputPlaceHolderCartValueForAdd] = useState("Informe o valor");
  const [inputTypeProductValueForAdd, setInputTypeProductValueForAdd] = useState("text");
  const [inputPlaceHolderProductValueForAdd, setPlaceHolderProductValueForAdd] = useState("Informe o valor");

  const [selectedStates, setSelectedStates] = useState([]);

  useEffect(() => {
    if (operatorCartRuleForAdd) {
      if (operatorCartRuleForAdd.id == '=' || operatorCartRuleForAdd.id == '>' || operatorCartRuleForAdd.id == '<') {
        setInputTypeCartValueForAdd("text")
        setInputPlaceHolderCartValueForAdd("Informe o valor");
      } else {
        setInputTypeCartValueForAdd("textarea")
        setInputPlaceHolderCartValueForAdd("Informe os valores separados por ','");
      }
    }
  }, [operatorCartRuleForAdd]);

  useEffect(() => {
    if (operatorProductRuleForAdd) {
      if (operatorProductRuleForAdd.id == '=' || operatorProductRuleForAdd.id == '>=' || operatorProductRuleForAdd.id == '<=') {
        setInputTypeProductValueForAdd("text")
        setPlaceHolderProductValueForAdd("Informe o valor");
      } else {
        setInputTypeProductValueForAdd("textarea")
        setPlaceHolderProductValueForAdd("Informe os valores separados por ','");
      }
    }
  }, [operatorProductRuleForAdd]);


  const handleClickForAdd = (
    valueNotFoundErrorMessage,
    selectedRuleColumnFieldForAdd,
    valueRuleColumnFieldForAdd,
    setValueRuleColumnFieldForAdd,
    operatorForAdd,
  ) => {
    if (!valueRuleColumnFieldForAdd) {
      return toast.error(valueNotFoundErrorMessage);
    }

    addRuleCondition(
      cartRuleData?.id ? cartRuleData.id : "",
      selectedRuleColumnFieldForAdd,
      valueRuleColumnFieldForAdd,
      operatorForAdd,
      selectedRulesConditions,
      setSelectedRulesConditions
    );
    setValueRuleColumnFieldForAdd("");
  };

  useEffect(() => {
    if (getCartRulesColumnFields().length > 0)
      setSelectedCartRuleForAdd(getCartRulesColumnFields()[0]);
  }, [rulesColumnFields]);

  useEffect(() => {
    if (getProductsColumnFields().length > 0)
      setSelectedProductRuleForAdd(getProductsColumnFields()[0]);
  }, [rulesColumnFields]);

  const getCartRulesColumnFields = () => {
    return rulesColumnFields ? rulesColumnFields.filter((item, k) => item.ruletype == 'cart') : [];
  };

  const getProductsColumnFields = () => {
    return rulesColumnFields ? rulesColumnFields.filter((item, k) => item.ruletype == 'product') : [];
  };

  useEffect(() => {
    if (selectedStates.length > 0) {
      const concatenatedSelectedStates = selectedStates.join(", ");
      setCartRuleValueForAdd(concatenatedSelectedStates);
    } else {
      setCartRuleValueForAdd("");

    }
  }, [selectedStates]);

  useEffect(() => {
    if (selectedCartRuleForAdd?.fieldname == 'uf') {
      setOperatorCartRuleForAdd(operatorOptions.find(item => item.id == '|'));
    }
  }, [selectedCartRuleForAdd]);

  const getOperatorOptions = () => {
    return operatorOptions.filter((item, k) => 
    selectedCartRuleForAdd ? 
    ((item.type == selectedCartRuleForAdd.charactertype || item.type == 'all') && (item.ruleType == selectedCartRuleForAdd.type || item.ruleType == 'all')) : 
    item.type == 'all')
  }

  return (
    <>
      {getCartRulesColumnFields().length > 0 ? (
        <Row className="mb-3 d-flex justify-content-between align-items-end">
          <Col md={4}>
            <AvField
              type="select"
              name="selectedCartRuleForAdd"
              label="Adicione uma regra de carrinho"
              onChange={e => {
                setSelectedCartRuleForAdd(getCartRulesColumnFields().find(item => item.id == e.target.value));
              }}
            >
              {getCartRulesColumnFields().map((item, k) => (
                <option
                  key={k}
                  value={item.id}
                >
                  {item.fielddescription.toUpperCase()}
                </option>
              ))}
            </AvField>
          </Col>
          {selectedCartRuleForAdd?.fieldname != 'uf' && (
            <Col md={3}>
              <AvField
                type="select"
                name="selectedOperatorCartRuleForAdd"
                label="Condição para regra"
                disabled={!selectedCartRuleForAdd}
                onChange={e => {
                  setOperatorCartRuleForAdd(operatorOptions.find(item => item.id == e.target.value));
                }}
              >
                {getOperatorOptions().map((item, k) => (
                  <option
                    key={k}
                    value={item.id}
                  >
                    {item.description.toUpperCase()}
                  </option>
                ))}
              </AvField>
            </Col>
          )}
          <Col md={4}>
            {selectedCartRuleForAdd?.fieldname != 'uf' ? (
              <AvField
                name="cartRuleValueForAdd"
                onChange={e => setCartRuleValueForAdd(e.target.value.toUpperCase())}
                type={inputTypeCartValueForAdd}
                placeholder={inputPlaceHolderCartValueForAdd}
                value={cartRuleValueForAdd}
                disabled={!selectedCartRuleForAdd}
              />
            ) : (
              <AvField
                name="selectedStates"
                label="Estados selecionados"
                onChange={e => setCartRuleValueForAdd(e.target.value.toUpperCase())}
                type="text"
                value={cartRuleValueForAdd}
                disabled
              />
            )}
          </Col>
          {selectedCartRuleForAdd?.fieldname == 'uf' && (
            <Col md={3}>
              <StateSelector
                selectedStates={selectedStates} setSelectedStates={setSelectedStates}
              />
            </Col>
          )}
          <Col md={1}>
            <button
              onClick={() => handleClickForAdd(
                "Digite um valor para adicionar a regra de carrinho",
                selectedCartRuleForAdd,
                cartRuleValueForAdd,
                setCartRuleValueForAdd,
                operatorCartRuleForAdd,
              )}
              type="button"
              className="btn btn-success w-100"
              disabled={!cartRuleValueForAdd}
            >
              <Plus size={22} color="#f0f0f0" weight="bold" />
            </button>
          </Col>
        </Row>
      ) : (<Alert color="info">Nenhuma opção para cadastrar regras de carrinho encontrada</Alert>)}
      {!isSolar && (
        getProductsColumnFields().length > 0 ? (
          <Row className="mb-3 d-flex justify-content-between align-items-end">
            <Col md={4}>
              <AvField
                type="select"
                name="selectedProductRuleForAdd"
                label="Adicione uma regra de produto"
                onChange={e => {
                  setSelectedProductRuleForAdd(getProductsColumnFields().find(item => item.id == e.target.value));
                }}
              >
                {getProductsColumnFields().map((item, k) => (
                  <option
                    key={k}
                    value={item.id}
                  >
                    {item.fielddescription.toUpperCase()}
                  </option>
                ))}
              </AvField>
            </Col>
            <Col md={3}>
              <AvField
                type="select"
                name="operatorProductRuleForAdd"
                label="Condição para regra"
                disabled={!selectedProductRuleForAdd}
                onChange={e => {
                  setOperatorProductRuleForAdd(operatorOptions.find(item => item.id == e.target.value));
                }}
              >
                {operatorOptions.filter((item, k) => item.type == selectedProductRuleForAdd.charactertype || item.type == 'all').map((item, k) => (
                  <option
                    key={k}
                    value={item.id}
                  >
                    {item.description.toUpperCase()}
                  </option>
                ))}
              </AvField>
            </Col>
            <Col md={4}>
              <AvField
                name="productRuleValueForAdd"
                onChange={e => setProductRuleValueForAdd(e.target.value.toUpperCase())}
                type={inputTypeProductValueForAdd}
                placeholder={inputPlaceHolderProductValueForAdd}
                value={productRuleValueForAdd}
                disabled={!selectedProductRuleForAdd}
              />
            </Col>
            <Col md={1}>
              <button
                onClick={() => handleClickForAdd(
                  "Digite um valor para adicionar a regra de produto",
                  selectedProductRuleForAdd,
                  productRuleValueForAdd,
                  setProductRuleValueForAdd,
                  operatorProductRuleForAdd,
                )}
                type="button"
                className="btn btn-success w-100"
                disabled={!productRuleValueForAdd}
              >
                <Plus size={22} color="#f0f0f0" weight="bold" />
              </button>
            </Col>
          </Row>
        ) : (
          <Alert color="info">Nenhuma opção para cadastrar regras de produto encontrada</Alert >
        )
      )}
    </>
  );
}

export default RuleSelection;