import { apiClient } from "./apiCore";

export const getCollaborators = () => {
  const baseUrl = `/api/collaborators`;
  return apiClient.get(baseUrl);
};

export const create = data => {
  const baseUrl = `/api/collaborator`;
  return apiClient.create(baseUrl, data);
};

export const update = (id, data) => {
  const baseUrl = `/api/collaborator/${id}`;
  return apiClient.update(baseUrl, data);
};