import React, { useState, useEffect, useCallback } from "react";
import { Link } from "react-router-dom";
import { Form, Button, Alert } from "reactstrap";
import classnames from "classnames";

// redux
import { useSelector, useDispatch } from "react-redux";

// utils
import { timeAgo } from "../../../utils/index";

// images
import profilePlaceholder from "../../../assets/images/others/user-dummy-img.jpg";

// actions
import {
  postVideoComment,
  getTVvideoDetails,
  deleteMessage,
} from "../../../store/actions";

// components
import AppScrollBar from "../../../components/AppScrollBar";
import ConfirmationModal from "../../../components/ConfirmationModal";
import ButtonLoader from "../../../components/ButtonLoader";

const DeleteButton = ({ onClick, className }) => {
  return (
    <Link
      to="#"
      className={classnames(
        "text-muted",
        "font-13",
        "d-inline-block",
        className
      )}
      onClick={onClick}
    >
      <i className="mdi mdi-close"></i> Delete
    </Link>
  );
};
const MessageAdd = ({
  value,
  onChange,
  onSubmit,
  hasReply,
  containerClass,
  onCancel,
  loading,
}) => {
  return (
    <div className={containerClass}>
      <Form>
        <div className="border border-light-1 rounded mb-3">
          <textarea
            rows="3"
            className="form-control border-0 resize-none"
            placeholder="Sua Mensagem..."
            value={value || ""}
            onChange={e => onChange(e.target.value)}
          />
        </div>
        <div className="text-end">
          <ButtonLoader
            color="primary"
            type="button"
            disabled={!value || loading}
            onClick={onSubmit}
            text={hasReply ? "Responder" : "Comentar"}
            loading={loading}
          />
          {hasReply && (
            <Button
              color="light"
              type="button"
              className="ms-2"
              onClick={onCancel}
            >
              cancelar
            </Button>
          )}
        </div>
      </Form>
    </div>
  );
};

const Reply = ({ reply, onOpenDelete }) => {
  const time = reply.created_at ? timeAgo(reply.created_at) : null;

  return (
    <div className="d-flex align-items-start mt-4">
      <div className="flex-shrink-0 me-2">
        <img
          className="rounded-circle avatar-xs"
          src={reply.img_profile ? reply.img_profile : profilePlaceholder}
          alt="avatar-4 images"
        />
      </div>

      <div className="flex-grow-1">
        <h5 className="font-size-15 mb-1">
          {reply.username ? reply.username : "-"}{" "}
          {time ? <small className="text-muted float-end">{time}</small> : null}
        </h5>
        <p className="text-muted">{reply.message || ""}</p>

        {/* delete reply */}
        <DeleteButton onClick={() => onOpenDelete(reply.id)} />
      </div>
    </div>
  );
};

const Comment = ({
  comment,
  onPostReply,
  commentPosted,
  onOpenDelete,
  commentPostLoading,
}) => {
  const hasReply = comment.replies && comment.replies.length;
  const replies = comment.replies || [];

  /*
   reply div handeling
  */
  const [isOpen, setIsOpen] = useState(false);
  const onOpen = () => {
    setIsOpen(true);
  };
  const onClose = () => {
    setIsOpen(false);
  };

  /*
    post comment
  */
  const [text, setText] = useState("");
  const onChangeText = value => {
    setText(value);
  };
  useEffect(() => {
    if (commentPosted) {
      setIsOpen(false);
      setText("");
    }
  }, [commentPosted]);

  const time = comment.created_at ? timeAgo(comment.created_at) : null;

  return (
    <div className="d-flex align-items-start border-bottom border-light-1 py-4">
      <div className="flex-shrink-0 me-2">
        <img
          className="rounded-circle avatar-xs"
          src={comment.img_profile ? comment.img_profile : profilePlaceholder}
          alt="profile"
        />
      </div>

      <div className="flex-grow-1">
        <h5 className="font-size-15 mb-1">
          {comment.username ? comment.username : "-"}
          {time ? <small className="text-muted float-end">{time}</small> : null}
        </h5>
        <p className="text-muted">{comment.message}</p>

        {/* reply comment */}
        <Link
          to="#"
          className="text-muted font-13 d-inline-block"
          onClick={onOpen}
        >
          <i className="mdi mdi-reply"></i> Reply
        </Link>

        {/* delete comment */}
        <DeleteButton
          onClick={() => onOpenDelete(comment.id)}
          className="ms-4"
        />
        {hasReply
          ? replies.map((reply, key) => (
              <Reply reply={reply} key={key} onOpenDelete={onOpenDelete} />
            ))
          : null}
        {isOpen ? (
          <MessageAdd
            value={text}
            onChange={onChangeText}
            hasReply={true}
            containerClass="mt-2"
            onCancel={onClose}
            onSubmit={() => onPostReply(comment.id, text)}
            loading={commentPostLoading}
          />
        ) : null}
      </div>
    </div>
  );
};

const Comments = ({ videoId, comments }) => {
  const dispatch = useDispatch();
  const {
    commentPosted,
    commentDeleted,
    commentDeleteLoading,
    commentPostLoading,
  } = useSelector(state => ({
    commentPosted: state.Gallery.commentPosted,
    commentPostLoading: state.Gallery.commentPostLoading,
    commentDeleted: state.Gallery.commentDeleted,
    commentDeleteLoading: state.Gallery.commentDeleteLoading,
  }));

  /*
    post comment
    */
  const [text, setText] = useState("");
  const onChangeText = value => {
    setText(value);
  };
  /*
  post comment & reply
  */
  const onPostComment = () => {
    const params = {
      message: text,
      reply_to: "",
    };
    dispatch(postVideoComment(videoId, params));
  };

  const onPostReply = (commentId, message) => {
    const params = {
      message: message,
      reply_to: commentId,
    };
    dispatch(postVideoComment(videoId, params));
  };

  /*
  delete reply modal confirmation
  */
  const [selectedMessage, setSelectedMessage] = useState(null);
  const [isOpenDelete, setIsOpenDelete] = useState(false);
  const onOpenDelete = id => {
    if (id) {
      setSelectedMessage(id);
    }
    setIsOpenDelete(true);
  };
  const onCloseDelete = useCallback(() => {
    setSelectedMessage(null);
    setIsOpenDelete(false);
  }, []);

  /*
  delete comment / reply
  */
  const onDeleteMessage = () => {
    dispatch(deleteMessage(selectedMessage));
  };

  /*
  re-fetch details after post / delete message
  */
  useEffect(() => {
    if (videoId && commentPosted) {
      dispatch(getTVvideoDetails(videoId));
      setText("");
    }
  }, [dispatch, videoId, commentPosted]);

  useEffect(() => {
    if (videoId && commentDeleted) {
      onCloseDelete();
      dispatch(getTVvideoDetails(videoId));
    }
  }, [commentDeleted, onCloseDelete]);

  const hasComments = comments && (comments || []).length;

  return (
    <div>
      <h5 className="font-size-16"> Comentários</h5>
      {hasComments ? (
        <AppScrollBar style={{ maxHeight: "430px" }}>
          {(comments || []).map((comment, key) => (
            <Comment
              comment={comment}
              key={key}
              onPostReply={onPostReply}
              commentPosted={commentPosted}
              onOpenDelete={onOpenDelete}
              commentPostLoading={commentPostLoading}
            />
          ))}
        </AppScrollBar>
      ) : (
        <Alert color="info">Nenhum comentário até o momento</Alert>
      )}

      <MessageAdd
        value={text}
        onChange={onChangeText}
        onSubmit={onPostComment}
        containerClass="mt-4"
        loading={commentPostLoading}
      />
      {isOpenDelete ? (
        <ConfirmationModal
          message="Você está prestes a apagar este comentário. Se for um comentário pai, apagará todos os seus filhos"
          isOpen={isOpenDelete}
          onClose={onCloseDelete}
          onConfirm={onDeleteMessage}
          loading={commentDeleteLoading}
        />
      ) : null}
    </div>
  );
};

export default Comments;
