import React from "react";
import { Row, Col, InputGroupText, InputGroup, Input } from "reactstrap";
import { grids } from "../../../register/mockAndValidation";

const Inversor = ({ filters, setFilters }) => {
  return (
    <>
      <Row className="engloba-vendedor-cliente">
        <Col className="engloba-vendedor">
          <Row className="linha-titulo-vendedor">
            <h4 className="titulos">Nome:</h4>
          </Row>
          <Row className="engloba-vendedor-codigo">
            <Col className="busca-vendedor" md={12}>
              <InputGroup>
                <InputGroupText className="fundo-icone">
                  <i className="ph-user"></i>
                </InputGroupText>
                <Input
                  id="nome-cliente"
                  className="titulo-input"
                  placeholder="Nome do produto"
                  onChange={e =>
                    setFilters(state => {
                      return {
                        ...state,
                        [e.target.name]: e.target.value,
                      };
                    })
                  }
                  value={filters.nome}
                  name="nome"
                />
              </InputGroup>
            </Col>
          </Row>
        </Col>
        <Col className="engloba-nome-cliente">
          <Row className="linha-titulo-cliente">
            <h4 className="titulos">Potência Overload:</h4>
          </Row>
          <Col className="nome-cliente">
            <InputGroup>
              <InputGroupText className="fundo-icone">
                <i className="ph-lightning"></i>
              </InputGroupText>
              <Input
                id="nome-cliente"
                className="titulo-input"
                placeholder="Potência overload"
                onChange={e =>
                  setFilters(state => {
                    return {
                      ...state,
                      [e.target.name]: e.target.value,
                    };
                  })
                }
                value={filters.potenciaOverload}
                name="potenciaOverload"
              />
            </InputGroup>
          </Col>
        </Col>
      </Row>

      <Row className="engloba-vendedor-cliente">
        <Col className="engloba-vendedor">
          <Row className="linha-titulo-vendedor">
            <h4 className="titulos">Grid:</h4>
          </Row>
          <Row className="engloba-vendedor-codigo">
            <Col className="busca-vendedor" md={12}>
              <InputGroup>
                <InputGroupText className="fundo-icone">
                  <i className="ph-squares-four"></i>
                </InputGroupText>
                {/*      <Input
                  id="nome-cliente"
                  className="titulo-input"
                  placeholder="Grid"
                  onChange={e =>
                    setFilters(state => {
                      return {
                        ...state,
                        [e.target.name]: e.target.value,
                      };
                    })
                  }
                  value={filters.grid}
                  name="grid"
                /> */}
                <select
                  className="form-select"
                  aria-label="Filter select"
                  name="grid"
                  onChange={e =>
                    setFilters(state => {
                      return {
                        ...state,
                        [e.target.name]: e.target.value,
                      };
                    })
                  }
                  value={filters.grid}
                  /*  style={{ height: 35 }} */
                >
                  {grids?.map(item => {
                    return (
                      <option key={item.value} value={item.value}>
                        {item?.name}
                      </option>
                    );
                  })}
                </select>
              </InputGroup>
            </Col>
          </Row>
        </Col>
        <Col className="engloba-nome-cliente">
          <Row className="linha-titulo-cliente">
            <h4 className="titulos">Fabricante:</h4>
          </Row>
          <Col className="nome-cliente">
            <InputGroup>
              <InputGroupText className="fundo-icone">
                <i className="ph-factory"></i>
              </InputGroupText>
              <Input
                id="nome-cliente"
                className="titulo-input"
                placeholder="Fabricante"
                onChange={e =>
                  setFilters(state => {
                    return {
                      ...state,
                      [e.target.name]: e.target.value,
                    };
                  })
                }
                value={filters.fabricante}
                name="fabricante"
              />
            </InputGroup>
          </Col>
        </Col>
      </Row>
    </>
  );
};

export default Inversor;
