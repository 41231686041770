import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getRulesList } from "../../store/actions";
import { Container, Spinner } from "reactstrap";

// components
import Breadcrumb from "../../components/Breadcrumb";
import RulesFilter from "./RulesFilter";
import RulesList from "./RulesList";
import { useCurrentCompany, useProfile } from "../../hooks";
import { useHistory } from "react-router-dom";
import { isInTheGroup } from "../../helpers/user_helper";
import { toast } from "react-toastify";

const Rules = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { userProfile } = useProfile();

  const { loading, success, filters, user_restric_page } = useSelector(state => {
    return {
      loading: state.Rules.loading,
      success: state.Rules.success,
      filters: state.Rules.filters,
      user_restric_page: state.FirebaseRemoteConfig.user_restric_page,
    };
  });

  var userCode = userProfile?.claims?.code;

  useEffect(() => {
    if (user_restric_page && userCode) {
      var user_restric_page_object = JSON.parse(user_restric_page);
      const adminGroup = 1;
      var isVisible =  findUser(user_restric_page_object, userCode) || isInTheGroup([adminGroup], userProfile);

      if (!isVisible) {
        toast.error('Você não tem permissão para acessar cupons e promoções');
        history.replace('/');
      }
    }
  }, [userCode, location.pathname, user_restric_page]);

  function findUser(users, find) {
    return users.indexOf(find) >= 0;
  }

  useEffect(() => {
    filters.company = useCurrentCompany();
    dispatch(getRulesList(filters));
  }, [dispatch]);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container>
          <Breadcrumb
            title="Página Inicial"
            breadcrumbItem="Consultar Regras"
          />

          <RulesFilter />

          {loading ? <Spinner className="mt-4" /> : <div />}

          {success ? <RulesList /> : <div />}
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Rules;
