import { useEffect, useState } from "react";
import {
  Lightning,
  CurrencyCircleDollar,
  React,
} from "../../../helpers/importer.helper";
import "./styles.scss";

export default function ContainerTop({ dataPdf, dataProductAttributes }) {
  const [dataTableBar, setDataTableBar] = useState([]);
  const formato = {
    minimumFractionDigits: 2,
    style: "currency",
    currency: "BRL",
  };
  useEffect(() => {
    if (dataProductAttributes.length > 0) {
      let modEspc =
        dataProductAttributes[0]?.atributos.find(a => a.Codattr === "_modulo")
          ?.Valor || "";
      let modQtd =
        dataProductAttributes[0]?.atributos.find(
          a => a.Codattr === "_qtdModulo"
        )?.Valor || "";

      let invEspc =
        dataProductAttributes[0]?.atributos.find(
          a => a.Codattr === "_nomeInversor"
        )?.Valor || "";
      let invQtd =
        dataProductAttributes[0]?.atributos.find(
          a => a.Codattr === "_qtdInversor"
        )?.Valor || "";

      let estEspec = `${
        dataProductAttributes[0]?.atributos.find(
          a => a.Codattr === "_nomeEstrutura"
        )?.Valor || ""
      } - ${
        dataProductAttributes[0]?.atributos.find(
          a => a.Codattr === "_nomeEstrutura2"
        )?.Valor || ""
      } - ${
        dataProductAttributes[0]?.atributos.find(
          a => a.Codattr === "_nomeEstrutura3"
        )?.Valor || ""
      } - ${
        dataProductAttributes[0]?.atributos.find(
          a => a.Codattr === "_nomeEstrutura4"
        )?.Valor || ""
      } - ${
        dataProductAttributes[0]?.atributos.find(
          a => a.Codattr === "_nomeEstrutura4"
        )?.Valor || ""
      }`;

      let estQtd =
        Number(
          dataProductAttributes[0]?.atributos.find(
            a => a.Codattr === "_qtdEstrutura1"
          )?.Valor || 0
        ) +
        Number(
          dataProductAttributes[0]?.atributos.find(
            a => a.Codattr === "_qtdEstrutura2"
          )?.Valor || 0
        ) +
        Number(
          dataProductAttributes[0]?.atributos.find(
            a => a.Codattr === "_qtdEstrutura3"
          )?.Valor || 0
        ) +
        Number(
          dataProductAttributes[0]?.atributos.find(
            a => a.Codattr === "_qtdEstrutura4"
          )?.Valor || 0
        ) +
        Number(
          dataProductAttributes[0]?.atributos.find(
            a => a.Codattr === "_qtdEstrutura5"
          )?.Valor || 0
        );

      let aceEspec = `${
        dataProductAttributes[0]?.atributos.find(
          a => a.Codattr === "_nomeStringBox"
        )?.Valor || ""
      }${
        " - " +
          dataProductAttributes[0]?.atributos.find(
            a => a.Codattr === "_nomeStringBox2"
          )?.Valor || ""
      }`;

      let aceQtd =
        Number(
          dataProductAttributes[0]?.atributos.find(
            a => a.Codattr === "_qtdStringbox1"
          )?.Valor || 0
        ) +
        Number(
          dataProductAttributes[0]?.atributos.find(
            a => a.Codattr === "_qtdStringbox2"
          )?.Valor || 0
        );

      let conEspec = "Conector MC4";
      let conQtd = dataProductAttributes[0]?.atributos.find(
        a => a.Codattr === "_qtdMC4"
      )?.Valor;

      let cabEspec = `CABO 6MM-1800V PRETO E CABO 6MM-1800V VERMELHO`;
      let cabQtd =
        Number(
          dataProductAttributes[0]?.atributos.find(
            a => a.Codattr === "_qtdCaboPreto"
          )?.Valor || 0
        ) +
        Number(
          dataProductAttributes[0]?.atributos.find(
            a => a.Codattr === "_qtdCaboVermelho"
          )?.Valor || 0
        );

      let traEspec = dataProductAttributes[0]?.atributos.find(
        a => a.Codattr === "versao"
      ).Valor;
      let traQtd =
        dataProductAttributes[0]?.atributos.find(a => a.Codattr === "versao")
          .Valor === "X"
          ? "Não"
          : "Sim";

      setDataTableBar([
        {
          equipamentos: "Módulos",
          especificacao: modEspc,
          qtd: modQtd,
        },
        {
          equipamentos: "Inversor(es)",
          especificacao: invEspc,
          qtd: invQtd,
        },
        {
          equipamentos: "Estruturas",
          especificacao: estEspec,
          qtd: estQtd,
        },
        {
          equipamentos: "Acessórios",
          especificacao: aceEspec,
          qtd: aceQtd,
        },
        {
          equipamentos: "Conexões",
          especificacao: conEspec,
          qtd: conQtd,
        },
        {
          equipamentos: "Cabos",
          especificacao: cabEspec,
          qtd: cabQtd,
        },
        // {
        //   equipamentos: "Instalação",
        //   especificacao: dataPdf?.dataOrcamento?.orcamento?.items[0].ValServ,
        //   qtd: "per",
        // },
        {
          equipamentos: "Trafo",
          especificacao: traEspec,
          qtd: traQtd,
        },
      ]);
    }
  }, [dataProductAttributes]);

  return (
    <>
      <div className="container-pdf-7-top-header">
        <div className="container-pdf-7-top-header-img">
          <CurrencyCircleDollar />
        </div>
        <span className="container-pdf-7-top-header-span">
          INVESTIMENTO APROXIMADO:
        </span>
      </div>
      <div className="installation-ower">
        <Lightning />
        <span>Potência da Instalação:</span>
        <p>{dataPdf?.kwpPdf?.toString().replace(".", ",") || ""} kWp</p>
      </div>
      <div className="d-flex justify-content-center mb-3 font-bold">
        {
          dataProductAttributes[0]?.atributos?.find(
            a => a.Codattr === "_NomeProduto"
          ).Valor
        }
      </div>
      <div className="d-flex justify-content-center">
        <img
          className="image-product"
          src={dataProductAttributes[0]?.fotos[0]}
        />
      </div>
      <table>
        <tr style={{ background: "#E5E5E5" }}>
          <th>Equipamentos</th>
          <th>Especificações</th>
          <th>Quantidade</th>
        </tr>
        {dataTableBar.map((item, k) =>
          item.equipamentos == "Instalação" ? (
            dataPdf?.dataOrcamento?.orcamento?.items[0].ValServ ==
            "0.0" ? null : (
              <tr key={k}>
                <td style={{ textAlign: "left" }}>{item.equipamentos}</td>
                <td style={{ textAlign: "left" }} className="orange">
                  {item.especificacao}
                </td>
                <td className="blue">{item.qtd}</td>
              </tr>
            )
          ) : (
            <tr key={k}>
              <td style={{ textAlign: "left" }}>{item.equipamentos}</td>
              <td style={{ textAlign: "left" }} className="orange">
                {item.especificacao}
              </td>
              <td className="blue">{item.qtd}</td>
            </tr>
          )
        )}
      </table>
      <div className="average-cost">
        <span>Custo Médio do Investimento:</span>
        <p>
          {parseFloat(dataPdf?.dataOrcamento?.orcamento?.total).toLocaleString(
            "pt-br",
            formato
          )}
        </p>
      </div>
      {parseFloat(dataPdf?.dataOrcamento?.orcamento?.items[0].ValServ) !==
        0 && (
        <div className="average-cost">
          <span>Custo do Serviço:</span>
          <p>
            {parseFloat(
              dataPdf?.dataOrcamento?.orcamento?.items[0].ValServ
            ).toLocaleString("pt-br", formato)}
          </p>
        </div>
      )}
    </>
  );
}
