function totalFootageOfMetalProfiles(numberOfModules, moduleLength) {
  return numberOfModules * moduleLength + 100;
}

function quantityOfMetalProfiles({ numberOfModules, definedSizeOption }) {
  const adiasFactor = 2.3; // 2.3
  return ((numberOfModules * adiasFactor) / definedSizeOption) * 1000;
}

function miniTrilhoProfile({ numberOfModules, limitNumberOfModulesPerLine }) {
  const adiasFactor = 1; // 1
  return (
    (numberOfModules / limitNumberOfModulesPerLine) * 2 +
    adiasFactor +
    (numberOfModules + adiasFactor)
  );
}

function supportQuantityDefinition({ profileTotalFootage }) {
  const adiasFactor = 1200; // 1200 milimeters
  profileTotalFootage / adiasFactor;
}

export {
  totalFootageOfMetalProfiles,
  quantityOfMetalProfiles,
  miniTrilhoProfile,
  supportQuantityDefinition,
};
