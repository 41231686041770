import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Col,
  Row,
  Card,
  CardBody,
  FormGroup,
  Label,
  Input,
  Spinner,
} from "reactstrap";
import { mask, onlyNumbersAndLetters, telMask, validaCpfCnpj } from "../../utils";
import { consultaCep } from "../../api";
import InputMask from "react-input-mask";
import {
  getCustomer,
  addEndValido,
  getFreteValue,
  clearCustomer,
} from "../../store/actions";
import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";
import { Portuguese } from "flatpickr/dist/l10n/pt.js";
import { useParams } from "react-router-dom";

import { FormProspect } from "../NewOrders/RegisterUser/FormProspect";
import { FormPartner } from "../NewOrders/RegisterUser/FormPartner";
import { toast } from "react-toastify";

const RegisterUser = ({
  valTot,
  changeCepCliente,
  pagtoSelected,
  changeClient,
}) => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { customer, frete, order } = useSelector(state => ({
    customer: state.Order.newOrder.newOrder.customer,
    frete: state.Order.newOrder.newOrder.frete,
    order: state.Order.orderActive.order,
  }));

  const [isOpen, setIsOpen] = useState(true);
  const [cpfcnpj, setCpfcnpj] = useState("");
  const [dadosCliente, setDadosCliente] = useState({});
  const [endFat, setEndFat] = useState({});
  const [endEnt, setEndEnt] = useState({});
  const [dadosBanco, setDadosBanco] = useState({});
  const [sameEnd, setSameEnd] = useState(false);
  const [endEntValid, setEndEntValid] = useState(false);
  const [endFatValid, setEndFatValid] = useState(false);
  const [contFat, setContFat] = useState(0);
  const [contEnt, setContEnt] = useState(0);
  const [countId, setCountId] = useState(0);
  const [formVisible] = useState({
    prospect: true,
    client: false,
    partner: false,
  });

  const address = frete.address;

  useEffect(() => {
    setCpfcnpj("");
    setDadosCliente({});
    setEndFat({});
    setEndEnt({});
    setEndEntValid({});
    setEndFatValid({});
    dispatch(clearCustomer());
  }, []);

  useEffect(() => {
    if (id) {
      setCpfcnpj(order?.cliente?.CPFCNPJ.replace(/[^0-9]+/g, "") || "");
    }
  }, [order]);

  useEffect(() => {
    if (endEnt.CEP?.replace(/[^0-9]+/g, "").length == 8 && cpfcnpj) {
      consultaCep(endEnt.CEP)
        .then(res => {
          dispatch(addEndValido(res.address));
          if (contEnt > 0) {
            endEnt.NumEnd = "";
          }
          setEndEntValid(res.address);
          setEndEnt({
            ...endEnt,
            UF: res.address.state.trim(),
            MUNICIPIO: res.address.city.trim(),
          });

          if (res.address.address.trim()) {
            setEndEnt({
              ...endEnt,
              ENDERECO: res.address.address.trim(),
            });
          }

          if (res.address.district.trim()) {
            setEndEnt({
              ...endEnt,
              BAIRRO: res.address.district.trim(),
            });
          }

          setContEnt(contEnt + 1);
          changeCepCliente(endEnt.CEP);
        })
        .catch(console.log());
    } else {
      changeCepCliente(null);
      setEndEntValid(false);
    }
  }, [endEnt.CEP]);

  useEffect(() => {
    if (endFat.CEP?.replace(/[^0-9]+/g, "").length == 8 && cpfcnpj) {
      consultaCep(endFat.CEP)
        .then(res => {
          if (contFat > 0) {
            endFat.NumEnd = "";
          }
          setEndFatValid(res.address);
          setEndFat({
            ...endFat,
            UF: res.address.state.trim(),
            MUNICIPIO: res.address.city.trim(),
          });

          if (res.address.address.trim()) {
            setEndFat({
              ...endFat,
              ENDERECO: res.address.address.trim(),
            });
          }

          if (res.address.district.trim()) {
            setEndFat({
              ...endFat,
              BAIRRO: res.address.district.trim(),
            });
          }
          setContFat(contFat + 1);
        })
        .catch(console.log());
    } else {
      setEndFatValid(false);
    }
  }, [endFat.CEP]);

  useEffect(() => {
    if (address && valTot > 0) {
      dispatch(getFreteValue(valTot));
    }
  }, [address, valTot, dispatch]);

  useEffect(() => {
    if (formVisible.client || formVisible.partner || formVisible.prospect) {
      if (validaCpfCnpj(cpfcnpj)) {
        dispatch(getCustomer({ cpfcnpj: cpfcnpj }));
        setDadosCliente({});
        setDadosBanco({});
        setEndFat({});
        setEndEnt({});

        document.getElementById("cpfcnpj").classList.remove("erro-field");
      } else {
        if (cpfcnpj.length == 11 || cpfcnpj.length == 14) {
          toast.warning(`CPF ou CNPJ digitado inválido. Tente novamente!`);
          document.getElementById("cpfcnpj").classList.add("erro-field");
        }
      }
    }
  }, [cpfcnpj]);

  useEffect(() => {
    if (id && countId == 0 && order) {
      setDadosCliente({
        TP_PESSOA: order?.cliente?.TP_PESSOA.trim() || "",
        Rg: order?.cliente?.Rg.trim() || 2,
        Contrib: order?.cliente?.Contrib.trim() || "",
        Inscr: order?.cliente?.Inscr.trim() || "Isento",
        N_COMPLETO: order?.cliente?.N_COMPLETO.trim() || "",
        N_REDZ: order?.cliente?.N_REDZ.trim() || "",
        EMAIL: order?.cliente?.EMAIL.trim() || "",
        CONTATO: order?.cliente?.CONTATO.trim() || "",
        CargoCPD: order?.cliente?.CargoCPD.trim() || "",
        TEL: order?.cliente?.TEL.trim() || "",
        Tel2: order?.cliente?.CELULAR.trim() || "",
        DDDTEL: order?.cliente?.DDD.trim() || "",
        DDDTel2: order?.cliente?.DDD_CEL.trim() || "",
        DtNasc: order?.cliente?.DtNasc.trim() || "",
      });

      setEndFat({
        CEP: order?.cliente?.CEP.trim() || "",
        UF: order?.cliente?.UFCob.trim() || "",
        MUNICIPIO: order?.cliente?.MUNICIPIO.trim() || "",
        BAIRRO: order?.cliente?.BAIRRO.trim() || "",
        ENDERECO: order?.cliente?.ENDERECO.trim().split(",")[0] || "",
        NumEnd: order?.cliente?.ENDERECO.trim().split(",")[1] || "",
        COMPL: order?.cliente?.ENDERECO.trim().split(",")[2] || "",
      });

      setEndEnt({
        CEP: order?.cliente?.CEP_ENT.trim() || "",
        UF: order?.cliente?.UF_ENT.trim() || "",
        MUNICIPIO: order?.cliente?.MunicipEnt.trim() || "",
        BAIRRO: order?.cliente?.BAI_ENT.trim() || "",
        ENDERECO: order?.cliente?.END_ENT.trim().split(",")[0] || "",
        NumEnd: order?.cliente?.END_ENT.trim().split(",")[1] || "",
        COMPL: order?.cliente?.END_ENT.trim().split(",")[2] || "",
      });

      setCountId(1);
    } else {
      setDadosCliente({
        TP_PESSOA: customer.data?.TP_PESSOA.trim() || "F",
        Rg: customer.data?.Rg.trim() || 2,
        Contrib: customer.data?.Contrib.trim() || "",
        Inscr: customer.data?.Inscr.trim() || "Isento",
        N_COMPLETO: customer.data?.N_COMPLETO.trim() || "",
        N_REDZ: customer.data?.N_REDZ.trim() || "",
        EMAIL: customer.data?.EMAIL.trim() || "",
        CONTATO: customer.data?.CONTATO.trim() || "",
        CargoCPD: customer.data?.CargoCPD.trim() || "",
        TEL: customer.data?.TEL.trim() || "",
        Tel2: customer.data?.CELULAR.trim() || "",
        DDDTEL: customer.data?.DDD.trim() || "",
        DDDTel2: customer.data?.DDD_CEL.trim() || "",
        DtNasc: customer.data?.DtNasc.trim() || "",
      });

      setEndFat({
        CEP: customer.data?.CEP.trim() || "",
        UF: customer.data?.UFCob.trim() || "",
        MUNICIPIO: customer.data?.MUNICIPIO.trim() || "",
        BAIRRO: customer.data?.BAIRRO.trim() || "",
        ENDERECO: customer.data?.ENDERECO.trim().split(",")[0] || "",
        NumEnd: customer.data?.NumEndCob.trim() || "",
        COMPL: customer.data?.COMPL.trim() || "",
      });

      setEndEnt({
        CEP: customer.data?.CEP_ENT.trim() || "",
        UF: customer.data?.UF_ENT.trim() || "",
        MUNICIPIO: customer.data?.MunicipEnt.trim() || "",
        BAIRRO: customer.data?.BAI_ENT.trim() || "",
        ENDERECO: customer.data?.END_ENT.trim().split(",")[0] || "",
        NumEnd: customer.data?.NumEndEnt.trim() || "",
        COMPL: customer.data?.ComplemEnt.trim() || "",
      });
    }
  }, [customer.data]);

  useEffect(() => {
    changeClient({
      id: ("000000" + customer.data?.CODCLIENTE).slice(-6) || "",
      cpfcnpj: cpfcnpj || "",
      pessoa: cpfcnpj.length > 11 ? "J" : "F",
      customer_type:
        dadosCliente.TP_PESSOA || (cpfcnpj.length > 11 ? "J" : "F"),
      contribution:
        (cpfcnpj.length > 11 ? "J" : "F") == "F"
          ? 2
          : dadosCliente.Contrib || 2,
      social_number:
        (cpfcnpj.length > 11 ? "J" : "F") == "F"
          ? dadosCliente.Rg
          : dadosCliente.Inscr || "ISENTO",
      fullname: dadosCliente.N_COMPLETO || "",
      name_reduced: dadosCliente.N_REDZ || "",
      contact: dadosCliente.CONTATO || "",
      job_title: dadosCliente.CargoCPD || "",
      email: dadosCliente.EMAIL || "",
      dt_nasc:
        (cpfcnpj.length > 11 ? "J" : "F") == "F"
          ? dadosCliente.DtNasc || ""
          : "",
      loja: customer.data?.Loja || "",
      customer_ficha: {
        AGENCIA: dadosBanco.AGENCIA || "",
        CONTA: dadosBanco.CONTA || "",
        DTADMISS: dadosBanco.DTADMISS || "",
        CNPJTRAB: dadosBanco.CNPJTRAB || "",
        EMPTRAB: dadosBanco.EMPTRAB || "",
        CAPSOC: dadosBanco.CAPSOC || "",
        SOCIO: dadosBanco.SOCIO || "",
        FATMEN: dadosBanco.FATMEN || "",
        FUNCEMP: dadosBanco.FUNCEMP || "",
        CPFREF: dadosBanco.CPFREF || "",
        CODIBGEC: endFatValid?.cidadde_info?.codigo_ibge || "",
        CODIBGEE: endEntValid?.cidadde_info?.codigo_ibge || "",
      },
      customer_address: [
        {
          zipcode: (endFat.CEP || "").replace(/[^0-9]+/g, ""),
          district: endFat.BAIRRO || "",
          city: (endFat.MUNICIPIO || "")
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, ""),
          state: endFat.UF || "",
          address: endFat.ENDERECO || "",
          number: endFat.NumEnd || "",
          complement: endFat.COMPL || "",
        },
        {
          zipcode: (endEnt.CEP || "").replace(/[^0-9]+/g, ""),
          district: endEnt.BAIRRO || "",
          city: (endEnt.MUNICIPIO || "")
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, ""),
          state: endEnt.UF || "",
          address: endEnt.ENDERECO || "",
          number: endEnt.NumEnd || "",
          complement: endEnt.COMPL || "",
        },
      ],
      customer_phones: [
        {
          ddd: dadosCliente.DDDTEL || "",
          phone: dadosCliente.TEL || "",
        },
        {
          ddd: dadosCliente.DDDTel2 || "",
          phone: dadosCliente.Tel2 || "",
        },
      ],
    });
  }, [cpfcnpj, dadosCliente, endFat, endEnt, dadosBanco]);

  return (
    <Card id="cliente">
      <CardBody>
        <Row>
          <Col md={11}>
            <p
              className="mb-0 d-flex align-items-center"
              style={{ fontWeight: "bold" }}
            >
              <i
                className="ph-user"
                style={{ color: "#2D567B", marginRight: 20, fontSize: 25 }}
              ></i>
              Prospect
            </p>
          </Col>
          <Col md={1}>
            <a
              href="#"
              style={{
                width: "fit-content",
                marginLeft: "auto",
                display: "block",
              }}
              onClick={event => {
                event.preventDefault();
                setIsOpen(!isOpen);
              }}
            >
              {isOpen ? (
                <i className="fas fa-chevron-up"></i>
              ) : (
                <i className="fas fa-chevron-down"></i>
              )}
            </a>
          </Col>
        </Row>

        {isOpen ? (
          <>
            {formVisible.client ? (
              <Row className="mt-4">
                <Col>
                  <h5 className="h5">Informações de Cadastro</h5>
                  <FormGroup>
                    <Row className="mt-4">
                      <Col md={4}>
                        <Label>CPF/CNPJ</Label>
                        <Input
                          id="cpfcnpj"
                          placeholder="000.000.000-00"
                          value={mask(cpfcnpj || "")}
                          onChange={e =>
                            setCpfcnpj(e.target.value.replace(/[^0-9]+/g, ""))
                          }
                          maxLength={18}
                        />
                      </Col>
                      <Col md={4}>
                        <Label>Tipo de pessoa</Label>
                        <select
                          className="form-control"
                          value={cpfcnpj.length > 11 ? "J" : "F"}
                          disabled
                          readOnly
                        >
                          <option value="F">Física</option>
                          <option value="J">Jurídica</option>
                        </select>
                      </Col>
                    </Row>
                    {customer.searched && (
                      <Row className="mt-3">
                        {(cpfcnpj.length > 11 ? "J" : "F") == "J" ? (
                          <React.Fragment>
                            <Col md={4}>
                              <Label>Tipo Inscrição</Label>
                              <select
                                className="form-control"
                                value={dadosCliente.Contrib || 2}
                                name="Contrib"
                                onChange={e =>
                                  setDadosCliente({
                                    ...dadosCliente,
                                    [e.target.name]: e.target.value,
                                  })
                                }
                              >
                                <option value={2}>ISENTO</option>
                                <option value={1}>Inscrição Estadual</option>
                              </select>
                            </Col>
                            <Col md={4}>
                              <Label>Inscrição Estadual</Label>
                              <Input
                                value={
                                  dadosCliente.Contrib == 2
                                    ? "ISENTO"
                                    : dadosCliente.Inscr || ""
                                }
                                name="Inscr"
                                onChange={e =>
                                  setDadosCliente({
                                    ...dadosCliente,
                                    [e.target.name]: e.target.value,
                                  })
                                }
                                readOnly={dadosCliente.Contrib == 2}
                              />
                            </Col>
                          </React.Fragment>
                        ) : (
                          <Col md={4}>
                            <Label>RG da Inscrição</Label>
                            <Input
                              placeholder="00.000.000-0"
                              value={dadosCliente.Rg || ""}
                              name="Rg"
                              onChange={e =>
                                setDadosCliente({
                                  ...dadosCliente,
                                  [e.target.name]: e.target.value,
                                })
                              }
                            />
                          </Col>
                        )}
                      </Row>
                    )}
                  </FormGroup>
                  {customer.loading && <Spinner className="mt-4" />}
                  {customer.searched && (
                    <div>
                      <h5 className="h5 mt-4">Dados do Cliente</h5>
                      <FormGroup>
                        <Row className="mt-4">
                          <Col md={6}>
                            <Label>
                              {(cpfcnpj.length > 11 ? "J" : "F") == "J"
                                ? "Razão social"
                                : "Nome Completo"}
                            </Label>
                            <Input
                              value={dadosCliente.N_COMPLETO || ""}
                              name="N_COMPLETO"
                              onChange={e =>
                                setDadosCliente({
                                  ...dadosCliente,
                                  [e.target.name]: e.target.value,
                                })
                              }
                            />
                          </Col>
                          <Col md={6}>
                            <Label>Nome fantasia / Nome Reduzido</Label>
                            <Input
                              value={dadosCliente.N_REDZ || ""}
                              name="N_REDZ"
                              onChange={e =>
                                setDadosCliente({
                                  ...dadosCliente,
                                  [e.target.name]: e.target.value,
                                })
                              }
                            />
                          </Col>
                        </Row>
                        <Row className="mt-3">
                          <Col md={4}>
                            <Label>E-mail</Label>
                            <Input
                              value={dadosCliente.EMAIL || ""}
                              name="EMAIL"
                              onChange={e =>
                                setDadosCliente({
                                  ...dadosCliente,
                                  [e.target.name]: e.target.value,
                                })
                              }
                            />
                          </Col>
                          <Col md={4}>
                            <Label>Contato</Label>
                            <Input
                              value={dadosCliente.CONTATO || ""}
                              name="CONTATO"
                              onChange={e =>
                                setDadosCliente({
                                  ...dadosCliente,
                                  [e.target.name]: e.target.value,
                                })
                              }
                            />
                          </Col>
                          <Col md={4}>
                            <Label>Cargo</Label>
                            <Input
                              value={dadosCliente.CargoCPD || ""}
                              name="CargoCPD"
                              onChange={e =>
                                setDadosCliente({
                                  ...dadosCliente,
                                  [e.target.name]: e.target.value,
                                })
                              }
                            />
                          </Col>
                        </Row>
                        <Row className="mt-3">
                          <Col md={4}>
                            <Label>Telefone Comercial (FIXO)</Label>
                            <Row>
                              <Col md={2}>
                                <Input
                                  value={dadosCliente.DDDTEL || ""}
                                  name="DDDTEL"
                                  onChange={e =>
                                    setDadosCliente({
                                      ...dadosCliente,
                                      [e.target.name]: e.target.value,
                                    })
                                  }
                                />
                              </Col>
                              <Col md={10}>
                                <Input
                                  value={
                                    dadosCliente.TEL?.length > 4
                                      ? telMask(dadosCliente.TEL)
                                      : dadosCliente.TEL || ""
                                  }
                                  name="TEL"
                                  onChange={e =>
                                    setDadosCliente({
                                      ...dadosCliente,
                                      [e.target.name]: e.target.value.replace(
                                        /[^0-9]+/g,
                                        ""
                                      ),
                                    })
                                  }
                                  maxLength={9}
                                />
                              </Col>
                            </Row>
                          </Col>
                          <Col md={4}>
                            <Label>Telefone Celular</Label>
                            <Row>
                              <Col md={2}>
                                <Input
                                  value={dadosCliente.DDDTel2 || ""}
                                  name="DDDTel2"
                                  onChange={e =>
                                    setDadosCliente({
                                      ...dadosCliente,
                                      [e.target.name]: e.target.value,
                                    })
                                  }
                                />
                              </Col>
                              <Col md={10}>
                                <Input
                                  value={
                                    dadosCliente.Tel2?.length > 4
                                      ? telMask(dadosCliente.Tel2)
                                      : dadosCliente.Tel2 || ""
                                  }
                                  name="Tel2"
                                  onChange={e =>
                                    setDadosCliente({
                                      ...dadosCliente,
                                      [e.target.name]: e.target.value.replace(
                                        /[^0-9]+/g,
                                        ""
                                      ),
                                    })
                                  }
                                  maxLength={10}
                                />
                              </Col>
                            </Row>
                          </Col>
                          {(cpfcnpj.length > 11 ? "J" : "F") == "J" || (
                            <Col md={4}>
                              <Label>Data de Nascimento</Label>
                              <Flatpickr
                                className="form-control d-block"
                                placeholder="dd M,yyyy"
                                value={dadosCliente.DtNasc || ""}
                                options={{
                                  altInput: true,
                                  locale: Portuguese,
                                  altFormat: "d F, Y",
                                  dateFormat: "Ymd",
                                }}
                                onChange={([date]) =>
                                  setDadosCliente({
                                    ...dadosCliente,
                                    DtNasc: `${date.getFullYear()}${(
                                      "00" +
                                      (date.getMonth() + 1)
                                    ).slice(-2)}${("00" + date.getDate()).slice(
                                      -2
                                    )}`,
                                  })
                                }
                              />
                            </Col>
                          )}
                        </Row>
                        <Row className="mt-4">
                          <Col>
                            <div className="form-check form-switch">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                role="switch"
                                id="flexSwitchCheckChecked"
                              />
                              <label className="form-check-label d-flex align-items-center">
                                Permitir contato via whatsapp{" "}
                                <i
                                  style={{ marginLeft: 10 }}
                                  className="ph-whatsapp-logo"
                                ></i>
                              </label>
                            </div>
                          </Col>
                        </Row>
                      </FormGroup>
                      <h5 className="h5 mt-4">
                        Endereço Faturamento e Cobrança
                      </h5>
                      <FormGroup>
                        <Row className="mt-4">
                          <Col md={3}>
                            <Label>CEP</Label>
                            <InputMask
                              mask="99999-999"
                              value={endFat.CEP || ""}
                              className="form-control"
                              name="CEP"
                              onChange={e => {
                                setEndFat({
                                  ...endFat,
                                  [e.target.name]: e.target.value,
                                });
                                if (sameEnd)
                                  setEndEnt({
                                    ...endFat,
                                    [e.target.name]: e.target.value,
                                  });
                              }}
                            />
                          </Col>
                          <Col md={3}>
                            <Label>Estado</Label>
                            <Input
                              value={endFatValid.state || endFat.UF || ""}
                              name="UF"
                              onChange={e => {
                                setEndFat({
                                  ...endFat,
                                  [e.target.name]: e.target.value,
                                });
                                if (sameEnd)
                                  setEndEnt({
                                    ...endFat,
                                    [e.target.name]: e.target.value,
                                  });
                              }}
                              readOnly={!endFatValid}
                            />
                          </Col>
                          <Col md={3}>
                            <Label>Cidade</Label>
                            <Input
                              value={endFatValid.city || endFat.MUNICIPIO || ""}
                              name="MUNICIPIO"
                              onChange={e => {
                                setEndFat({
                                  ...endFat,
                                  [e.target.name]: e.target.value,
                                });
                                if (sameEnd)
                                  setEndEnt({
                                    ...endFat,
                                    [e.target.name]: e.target.value,
                                  });
                              }}
                              readOnly={!endFatValid}
                            />
                          </Col>
                          <Col md={3}>
                            <Label>Bairro</Label>
                            <Input
                              value={
                                endFatValid.district || endFat.BAIRRO || ""
                              }
                              name="BAIRRO"
                              onChange={e => {
                                setEndFat({
                                  ...endFat,
                                  [e.target.name]: e.target.value,
                                });
                                if (sameEnd)
                                  setEndEnt({
                                    ...endFat,
                                    [e.target.name]: e.target.value,
                                  });
                              }}
                              readOnly={!endFatValid}
                            />
                          </Col>
                        </Row>
                        <Row className="mt-4">
                          <Col md={4}>
                            <Label>Endereço</Label>
                            <Input
                              value={
                                endFatValid.address || endFat.ENDERECO || ""
                              }
                              name="ENDERECO"
                              onChange={e => {
                                setEndFat({
                                  ...endFat,
                                  [e.target.name]: e.target.value,
                                });
                                if (sameEnd)
                                  setEndEnt({
                                    ...endFat,
                                    [e.target.name]: e.target.value,
                                  });
                              }}
                              readOnly={!endFatValid}
                            />
                          </Col>
                          <Col md={4}>
                            <Label>Número</Label>
                            <Input
                              value={endFat.NumEnd || ""}
                              name="NumEnd"
                              onChange={e => {
                                setEndFat({
                                  ...endFat,
                                  [e.target.name]: e.target.value,
                                });
                                if (sameEnd)
                                  setEndEnt({
                                    ...endFat,
                                    [e.target.name]: e.target.value,
                                  });
                              }}
                              readOnly={!endFatValid}
                            />
                          </Col>
                          <Col md={4}>
                            <Label>Complemento</Label>
                            <Input
                              value={endFat.COMPL || ""}
                              name="COMPL"
                              onChange={e => {
                                setEndFat({
                                  ...endFat,
                                  [e.target.name]: e.target.value,
                                });
                                if (sameEnd)
                                  setEndEnt({
                                    ...endFat,
                                    [e.target.name]: e.target.value,
                                  });
                              }}
                              readOnly={!endFatValid}
                            />
                          </Col>
                        </Row>
                      </FormGroup>
                      <h5 className="h5 mt-4">Endereço Entrega</h5>
                      <FormGroup>
                        <FormGroup check className="mt-4">
                          <Input
                            type="checkbox"
                            checked={sameEnd}
                            className="endCheck"
                            onChange={e => {
                              setSameEnd(!sameEnd);
                              if (e.target.checked) setEndEnt(endFat);
                            }}
                          />
                          <Label check>
                            Usar o mesmo endereço para entrega
                          </Label>
                        </FormGroup>
                        <Row className="mt-4">
                          <Col md={3}>
                            <Label>CEP</Label>
                            <InputMask
                              mask="99999-999"
                              value={endEnt.CEP || ""}
                              readOnly={sameEnd}
                              className="form-control"
                              name="CEP"
                              onChange={e =>
                                setEndEnt({
                                  ...endEnt,
                                  [e.target.name]: e.target.value,
                                })
                              }
                            />
                          </Col>
                          <Col md={3}>
                            <Label>Estado</Label>
                            <Input
                              value={endEntValid.state || endEnt.UF || ""}
                              readOnly={sameEnd || !endEntValid}
                              name="UF"
                              onChange={e =>
                                setEndEnt({
                                  ...endEnt,
                                  [e.target.name]: e.target.value,
                                })
                              }
                            />
                          </Col>
                          <Col md={3}>
                            <Label>Cidade</Label>
                            <Input
                              value={endEntValid.city || endEnt.MUNICIPIO || ""}
                              readOnly={sameEnd || !endEntValid}
                              name="MUNICIPIO"
                              onChange={e =>
                                setEndEnt({
                                  ...endEnt,
                                  [e.target.name]: e.target.value,
                                })
                              }
                            />
                          </Col>
                          <Col md={3}>
                            <Label>Bairro</Label>
                            <Input
                              value={
                                endEntValid.district || endEnt.BAIRRO || ""
                              }
                              readOnly={sameEnd || !endEntValid}
                              name="BAIRRO"
                              onChange={e =>
                                setEndEnt({
                                  ...endEnt,
                                  [e.target.name]: e.target.value,
                                })
                              }
                            />
                          </Col>
                        </Row>
                        <Row className="mt-4">
                          <Col md={4}>
                            <Label>Endereço</Label>
                            <Input
                              value={
                                endEntValid.address || endEnt.ENDERECO || ""
                              }
                              readOnly={sameEnd || !endEntValid}
                              name="ENDERECO"
                              onChange={e =>
                                setEndEnt({
                                  ...endEnt,
                                  [e.target.name]: e.target.value,
                                })
                              }
                            />
                          </Col>
                          <Col md={4}>
                            <Label>Número</Label>
                            <Input
                              value={endEnt.NumEnd || ""}
                              readOnly={sameEnd || !endEntValid}
                              name="NumEnd"
                              maxLength={6}
                              onChange={e =>
                                setEndEnt({
                                  ...endEnt,
                                  [e.target.name]: onlyNumbersAndLetters(e.target.value),
                                })
                              }
                            />
                          </Col>
                          <Col md={4}>
                            <Label>Complemento</Label>
                            <Input
                              value={endEnt.COMPL || ""}
                              readOnly={sameEnd || !endEntValid}
                              name="COMPL"
                              onChange={e =>
                                setEndEnt({
                                  ...endEnt,
                                  [e.target.name]: e.target.value,
                                })
                              }
                            />
                          </Col>
                        </Row>
                      </FormGroup>
                      {pagtoSelected &&
                        !(
                          (pagtoSelected[0]?.type || "") == "Pagamento Online"
                        ) && (
                          <React.Fragment>
                            <h5 className="h5 mt-4">
                              Análise de crédito -{" "}
                              {cpfcnpj.length > 11
                                ? "(Pessoa Jurídica)"
                                : "(Pessoa Física)"}
                            </h5>
                            <FormGroup>
                              <Row className="mt-4">
                                <Col md={4}>
                                  <Label>Banco</Label>
                                  <Input
                                    value={dadosBanco.BANCO || ""}
                                    name="BANCO"
                                    onChange={e =>
                                      setDadosBanco({
                                        ...dadosBanco,
                                        [e.target.name]: e.target.value,
                                      })
                                    }
                                  />
                                </Col>
                                <Col md={4}>
                                  <Label>Agencia</Label>
                                  <Input
                                    value={dadosBanco.AGENCIA || ""}
                                    name="AGENCIA"
                                    onChange={e =>
                                      setDadosBanco({
                                        ...dadosBanco,
                                        [e.target.name]: e.target.value,
                                      })
                                    }
                                  />
                                </Col>
                                <Col md={4}>
                                  <Label>Conta Corrente</Label>
                                  <Input
                                    value={dadosBanco.CONTA || ""}
                                    name="CONTA"
                                    onChange={e =>
                                      setDadosBanco({
                                        ...dadosBanco,
                                        [e.target.name]: e.target.value,
                                      })
                                    }
                                  />
                                </Col>
                              </Row>
                              {cpfcnpj.length > 11 ? (
                                <React.Fragment>
                                  <Row className="mt-4">
                                    <Col md={4}>
                                      <Label>Capital Social</Label>
                                      <Input
                                        value={dadosBanco.CAPSOC || ""}
                                        name="CAPSOC"
                                        onChange={e =>
                                          setDadosBanco({
                                            ...dadosBanco,
                                            [e.target.name]: e.target.value,
                                          })
                                        }
                                      />
                                    </Col>
                                    <Col md={4}>
                                      <Label>Faturamento Médio Mensal</Label>
                                      <Input
                                        value={dadosBanco.FATMEN || ""}
                                        name="FATMEN"
                                        onChange={e =>
                                          setDadosBanco({
                                            ...dadosBanco,
                                            [e.target.name]: e.target.value,
                                          })
                                        }
                                      />
                                    </Col>
                                    <Col md={4}>
                                      <Label>Data Fundação</Label>
                                      <Input
                                        value={dadosBanco.FUNCEMP || ""}
                                        name="FUNCEMP"
                                        onChange={e =>
                                          setDadosBanco({
                                            ...dadosBanco,
                                            [e.target.name]: e.target.value,
                                          })
                                        }
                                      />
                                    </Col>
                                  </Row>
                                  <Row className="mt-4">
                                    <Col md={6}>
                                      <Label>Nome do Sócio</Label>
                                      <Input
                                        value={dadosBanco.SOCIO || ""}
                                        name="SOCIO"
                                        onChange={e =>
                                          setDadosBanco({
                                            ...dadosBanco,
                                            [e.target.name]: e.target.value,
                                          })
                                        }
                                      />
                                    </Col>
                                    <Col md={6}>
                                      <Label>CPF do Sócio</Label>
                                      <InputMask
                                        mask="999.999.999-99"
                                        className="form-control"
                                        value={dadosBanco.CPFREF || ""}
                                        name="CPFREF"
                                        onChange={e =>
                                          setDadosBanco({
                                            ...dadosBanco,
                                            [e.target.name]:
                                              e.target.value.replace(
                                                /[^0-9]+/g,
                                                ""
                                              ),
                                          })
                                        }
                                      />
                                    </Col>
                                  </Row>
                                </React.Fragment>
                              ) : (
                                <React.Fragment>
                                  <Row className="mt-4">
                                    <Col md={6}>
                                      <Label>Empresa</Label>
                                      <Input
                                        value={dadosBanco.EMPTRAB || ""}
                                        name="EMPTRAB"
                                        onChange={e =>
                                          setDadosBanco({
                                            ...dadosBanco,
                                            [e.target.name]: e.target.value,
                                          })
                                        }
                                      />
                                    </Col>
                                    <Col md={6}>
                                      <Label>CNPJ</Label>
                                      <InputMask
                                        mask="99.999.999/9999-99"
                                        className="form-control"
                                        value={dadosBanco.CNPJTRAB || ""}
                                        name="CNPJTRAB"
                                        onChange={e =>
                                          setDadosBanco({
                                            ...dadosBanco,
                                            [e.target.name]:
                                              e.target.value.replace(
                                                /[^0-9]+/g,
                                                ""
                                              ),
                                          })
                                        }
                                      />
                                    </Col>
                                  </Row>
                                  <Row className="mt-4">
                                    <Col md={6}>
                                      <Label>Telefone</Label>
                                      <Input
                                        value={dadosBanco.TELEFONE || ""}
                                        name="TELEFONE"
                                        onChange={e =>
                                          setDadosBanco({
                                            ...dadosBanco,
                                            [e.target.name]: e.target.value,
                                          })
                                        }
                                      />
                                    </Col>
                                    <Col md={6}>
                                      <Label>Data Admissão</Label>
                                      <Input
                                        value={dadosBanco.DTADMISS || ""}
                                        name="DTADMISS"
                                        onChange={e =>
                                          setDadosBanco({
                                            ...dadosBanco,
                                            [e.target.name]: e.target.value,
                                          })
                                        }
                                      />
                                    </Col>
                                  </Row>
                                </React.Fragment>
                              )}
                            </FormGroup>
                          </React.Fragment>
                        )}
                    </div>
                  )}
                </Col>
              </Row>
            ) : formVisible.prospect ? (
              <FormProspect changeClient={changeClient} />
            ) : (
              formVisible.partner && <FormPartner />
            )}
          </>
        ) : (
          <div />
        )}
      </CardBody>
    </Card>
  );
};

export default RegisterUser;
