import React from "react";
import { Card, CardBody, Row, Col } from "reactstrap";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import SvgIcon from "../../components/SvgIcon";

// components
import SectionTitle from "./SectionTitle";

const BillingAddress = () => {
  const { order } = useSelector(state => ({
    order: state.Order.orderActive.order
  }));

  return (
    <div>
      <SectionTitle title="Endereço de Cobrança" />
      <Card>
        <CardBody className="p-4">
          <div className="">
            <p>
              <span className="fw-bold me-3">CEP</span>
              {order?.CEPCobranca || ""}
            </p>
            <p>
              <span className="fw-bold me-3">Endereço</span>
              {order?.EnderecoCobranca || ""}
            </p>
            <p>
              <span className="fw-bold me-3">Complemento</span>
              {order?.ComplementoCobranca || ""}
            </p>
            <p>
              <span className="fw-bold me-3">Cidade - UF</span>
              {order?.MunicipioCobranca || ""} - {order?.EstadoCobranca || ""}
            </p>
            <p className="bairro">
              <span className="fw-bold me-3">Bairro</span>
              {order?.BairroCobranca || ""}
            </p>
          </div>
        </CardBody>
      </Card>
    </div>
  );
};

const DeliveryAddress = () => {
  const { order } = useSelector(state => ({
    order: state.Order.orderActive.order
  }));

  return (
    <div>
      <SectionTitle title="Endereço de Entrega" />
      <Card>
        <CardBody className="p-4 position-relative">
          <div>
            <p>
              <span className="fw-bold me-3">CEP</span>
              {order?.CEPEntrega || ""}
            </p>
            <p>
              <span className="fw-bold me-3">Endereço</span>
              {order?.EnderecoEntrega || ""}
            </p>
            <p>
              <span className="fw-bold me-3">Complemento</span>
              {order?.ComplementoEntrega || ""}
            </p>
            <p>
              <span className="fw-bold me-3">Cidade - UF</span>
              {order?.MunicipioEntrega || ""} - {order?.UFEntrega || ""}
            </p>
            <p className="bairro">
              <span className="fw-bold me-3">Bairro</span>
              {order?.BairroEntrega || ""}
            </p>
          </div>
          {(order?.Situacao || "A") < "F" &&
            <div className="card-action-custom">
              <Link to={`/pedidos/${order?.NumPedido || ""}/order/edit`}>
              <SvgIcon name="edit-contained" className="ms-2 icon-sm"/>
              </Link>
            </div>
          }
        </CardBody>
      </Card>
    </div>
  );
};

const Addresses = () => {
  return (
    <div>
      <Row>
        <Col xs={12} md={6}>
          <BillingAddress />
        </Col>
        <Col xs={12} md={6}>
          <DeliveryAddress />
        </Col>
      </Row>
    </div>
  );
};

export default Addresses;
