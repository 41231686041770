import {
  GET_CLOSURES,
  API_SUCCESS,
  API_FAIL,
  CREATE_UPLOAD,
  IS_LOADING,
  DELETE_CLOSURE,
  ATT_FILE,
} from "./actionTypes";

const INIT_STATE = {
  data: [],
  dataUpload: {},
  loadingUpload: false,
  loadingDelete: false,
  loadingClosures: false,
  loadingFile: false,
};

const PromotionRules = (state = INIT_STATE, action) => {
  switch (action.type) {
    case API_SUCCESS:
      switch (action.payload.actionType) {
        case GET_CLOSURES:
          return {
            ...state,
            data: action.payload,
            loadingClosures: false,
            loadingFile: false,
          };
        case CREATE_UPLOAD:
          return {
            ...state,
            dataUpload: action.payload,
            loadingUpload: false,
          };
        case DELETE_CLOSURE:
          return {
            ...state,
            dataUpload: action.payload,
            loadingDelete: false,
          };
        default:
          return state;
      }
    case API_FAIL:
      switch (action.payload.actionType) {
        case GET_CLOSURES:
          return {
            ...state,
            data: [],
            loadingClosures: false,
            loadingFile: false,
          };
        case CREATE_UPLOAD:
          return {
            ...state,
            dataUpload: [],
            loadingUpload: false,
          };
        case DELETE_CLOSURE:
          return {
            ...state,
            dataUpload: [],
            loadingDelete: false,
          };
        default:
          return state;
      }

    case IS_LOADING:
      switch (action.payload) {
        case CREATE_UPLOAD:
          return {
            ...state,
            loadingUpload: true,
          };
        case ATT_FILE:
          return {
            ...state,
            loadingFile: true,
          };
        case GET_CLOSURES:
          return {
            ...state,
            loadingClosures: true,
          };
        case DELETE_CLOSURE:
          return {
            ...state,
            loadingDelete: true,
          };
        default:
          return state;
      }

    default:
      return state;
  }
};

export default PromotionRules;
