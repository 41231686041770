import React, { useEffect } from "react";
import { Col, Row, FormGroup, Label, Input } from "reactstrap";
import { consultaCep } from "../../../../api";

import InputMask from "react-input-mask";

import { validEmail, onlyAcceptNumber, onlyNumbersAndLetters } from "../../../../utils/otherUtils";
import { getFreteValue, addEndValido } from "../../../../store/actions";
import { useDispatch, useSelector } from "react-redux";

export const FormProspect = ({
  changeClient,
  errosDataProspectAndPartner,
  setErrosDataProspectAndPartner,
  valTot,
  dataLeed,
  verfyClientEmailExists,
  formVisible,
  dataProspect,
  setDataProspect,
}) => {
  const dispatch = useDispatch();
  const { frete } = useSelector(state => ({
    frete: state.Order.newOrder.newOrder.frete,
  }));
  useEffect(() => {
    if (formVisible.prospect == false) {
      setErrosDataProspectAndPartner({
        name: "",
        email: "",
        ddd: "",
        tel: "",
        cep: "",
        address: "",
        number: "",
        complement: "",
        state: "",
        city: "",
        district: "",
      });
    }
  }, [formVisible.prospect == false]);

  useEffect(() => {
    setDataProspect({
      name: dataLeed?.lead?.name ? dataLeed?.lead?.name : "",
      email: dataLeed?.lead?.email ? dataLeed?.lead?.email : "",
      ddd: dataLeed?.lead?.phone ? dataLeed?.lead?.phone.substr(1, 2) : "",
      tel: dataLeed?.lead?.phone
        ? dataLeed?.lead?.phone.substr(4, dataLeed?.lead?.phone.length)
        : "",
      cep: "",
      address: "",
      number: "",
      complement: "",
      state: "",
      city: "",
      district: "",
    });
  }, [dataLeed]);

  const getCep = cep => {
    if (cep?.replace(/[^0-9]+/g, "").length == 8) {
      setDataProspect({
        ...dataProspect,
        address: "...",
        state: "...",
        city: "...",
        district: "...",
      });
      consultaCep(cep)
        .then(res => {
          dispatch(addEndValido(res.address));

          setDataProspect({
            ...dataProspect,
            address: res.address.address,
            state: res.address.state,
            city: res.address.city,
            district: res.address.district,
            cep: cep,
          });
          getFreteValue(valTot, "02");
        })
        .catch(err => console.log(err));
    }
  };

  useEffect(() => {
    changeClient({
      fullname: dataProspect.name || "",
      email: dataProspect.email,
      customer_address: [
        {
          zipcode: (dataProspect.cep || "").replace(/[^0-9]+/g, ""),
          district: dataProspect.district || "",
          city: (dataProspect.city || "")
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, ""),
          state: dataProspect.state || "",
          address: dataProspect.address || "",
          number: dataProspect.number || "",
          complement: dataProspect.complement || "",
        },
      ],
      customer_phones: [
        {
          ddd: dataProspect.ddd || "",
          phone: dataProspect.tel || "",
        },
      ],
    });
  }, [dataProspect]);

  const verfiyEmail = e => {
    setDataProspect({
      ...dataProspect,
      email: e.target.value.replace(/[^a-zA-Z\d\w@.]/g, ""),
    });
    const isValid = validEmail(e.target.value);
    if (isValid) {
      setErrosDataProspectAndPartner({
        ...errosDataProspectAndPartner,
        email: "",
      });
    } else {
      setErrosDataProspectAndPartner({
        ...errosDataProspectAndPartner,
        email: "Digite um email válido",
      });
    }
  };

  const formatPhone = e => {
    var value = e.target.value;
    var valor = value;
    var retorno = valor.replace(/\D/g, "");
    retorno = retorno.replace(/^0/, "");

    if (retorno.length < 9) {
      retorno = retorno.replace(/^(\d{4})(\d{0,4}).*/, "$1-$2");
    } else {
      retorno = retorno.replace(/^(\d{5})(\d{4}).*/, "$1-$2");
    }
    return retorno;
  };

  return (
    <Row className="mt-4">
      <Col>
        <h5 className="h5"> Dados do Prospect</h5>
        <FormGroup>
          <Row className="mt-4">
            {" "}
            <Col md={6}>
              <Label>Nome:</Label>
              <Input
                id="name"
                value={dataProspect?.name}
                onChange={e =>
                  setDataProspect({ ...dataProspect, name: e.target.value })
                }
              />
            </Col>{" "}
            <Col md={6}>
              <Label>E-mail:</Label>
              <Input
                id="email"
                value={dataProspect?.email}
                onChange={verfiyEmail}
                onBlur={e => verfyClientEmailExists(e.target.value)}
              />
              <p className="erros">{errosDataProspectAndPartner.email}</p>
            </Col>
          </Row>
          <Row className="mt-4">
            {" "}
            <Col md={3}>
              <Label>DDD:</Label>
              <Input
                id="ddd"
                onChange={e =>
                  setDataProspect({
                    ...dataProspect,
                    ddd: onlyAcceptNumber(e),
                  })
                }
                maxLength={2}
                value={dataProspect.ddd}
              />
            </Col>{" "}
            <Col md={9}>
              <Label>Telefone:</Label>
              <Input
                id="tel"
                onChange={e =>
                  setDataProspect({
                    ...dataProspect,
                    tel: formatPhone(e),
                  })
                }
                value={dataProspect.tel}
              />
            </Col>
          </Row>
        </FormGroup>{" "}
        <h5 style={{ marginTop: 20 }} className="h5">
          {" "}
          Endereço para Entrega
        </h5>
        <FormGroup>
          <Row className="mt-4">
            <Col md={4}>
              <Label>CEP:</Label>
              <InputMask
                className="form-control"
                mask="99999-999"
                id="cep"
                onChange={e => getCep(e.target.value)}
                disabled={frete.loading}
              />{" "}
              {frete.loading && (
                <p style={{ marginTop: 5 }}>Buscando dados do cep...</p>
              )}
            </Col>{" "}
            <Col md={8}>
              <Label>Endereço:</Label>
              <Input
                id="endereco"
                value={dataProspect.address}
                onChange={e =>
                  setDataProspect({ ...dataProspect, address: e.target.value })
                }
              />
            </Col>
          </Row>
          <Row className="mt-4">
            {" "}
            <Col md={3}>
              <Label>Número:</Label>
              <Input
                id="numero"
                onChange={e =>
                  setDataProspect({
                    ...dataProspect,
                    number: onlyNumbersAndLetters(e.target.value),
                  })
                }
                value={dataProspect.number}
              />
            </Col>{" "}
            <Col md={9}>
              <Label>Complemento:</Label>
              <Input
                id="complemento"
                onChange={e =>
                  setDataProspect({
                    ...dataProspect,
                    complement: e.target.value,
                  })
                }
              />
            </Col>
          </Row>
          <Row className="mt-4">
            <Col md={12}>
              <Label>Bairro:</Label>
              <Input
                id="bairro"
                value={dataProspect.district}
                onChange={e =>
                  setDataProspect({ ...dataProspect, district: e.target.value })
                }
              />
            </Col>{" "}
          </Row>
          <Row className="mt-4">
            <Col md={9}>
              <Label>Cidade:</Label>
              <Input
                id="cidade"
                disabled
                value={dataProspect.city}
                onChange={e =>
                  setDataProspect({ ...dataProspect, city: e.target.value })
                }
              />
            </Col>{" "}
            <Col md={3}>
              <Label>Estado:</Label>
              <Input
                id="uf"
                disabled
                value={dataProspect.state}
                onChange={e =>
                  setDataProspect({ ...dataProspect, state: e.target.value })
                }
              />
            </Col>{" "}
          </Row>
        </FormGroup>
      </Col>
    </Row>
  );
};
