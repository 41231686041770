import React from "react";
export default function MapPin(props) {
  const colorFill = props?.colorFill?.replaceAll("#", "") || "none";
  const colorStroke = props?.colorStroke?.replaceAll("#", "") || "#696969";

  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 15 16"
      fill={colorFill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.5 8.46875C8.53553 8.46875 9.375 7.62928 9.375 6.59375C9.375 5.55822 8.53553 4.71875 7.5 4.71875C6.46447 4.71875 5.625 5.55822 5.625 6.59375C5.625 7.62928 6.46447 8.46875 7.5 8.46875Z"
        stroke={colorStroke}
        strokeWidth="0.9375"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.1875 6.59375C12.1875 10.8125 7.5 14.0938 7.5 14.0938C7.5 14.0938 2.8125 10.8125 2.8125 6.59375C2.8125 5.35055 3.30636 4.15826 4.18544 3.27919C5.06451 2.40011 6.2568 1.90625 7.5 1.90625C8.7432 1.90625 9.93549 2.40011 10.8146 3.27919C11.6936 4.15826 12.1875 5.35055 12.1875 6.59375V6.59375Z"
        stroke={colorStroke}
        strokeWidth="0.9375"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
