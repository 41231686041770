import { GalleryActionTypes } from "./actionTypes";

const INIT_STATE = {
  tvGallery: {},
  tvConfigGallery: {},
  videoDetails: {},
};

const Gallery = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GalleryActionTypes.API_RESPONSE_SUCCESS:
      switch (action.payload.actionType) {
        case GalleryActionTypes.GET_TV_GALLERY:
          return {
            ...state,
            tvGallery: action.payload.data,
            getTvGalleryLoading: false,
          };
        case GalleryActionTypes.GET_TV_CONFIG_GALLERY:
          return {
            ...state,
            tvConfigGallery: action.payload.data,
            getTvConfigGalleryLoading: false,
            liveAdded: false,
            fileUploaded: false,
          };
        case GalleryActionTypes.ADD_NEW_LIVE:
          return {
            ...state,
            addLoading: false,
            liveAdded: true,
          };
        case GalleryActionTypes.UPLOAD_LIVE_CONFIG_FILES:
          return {
            ...state,
            fileUploadLoading: false,
            fileUploaded: true,
          };
        case GalleryActionTypes.DELETE_LIVE_CONFIG_FILE:
          return {
            ...state,
            isFileDeleted: true,
          };
        case GalleryActionTypes.GET_TV_VIDEO_DETAILS:
          return {
            ...state,
            videoDetails: action.payload.data,
            getTvvideoDetailsLoading: false,
            commentPosted: false,
            commentDeleted: false,
          };
        case GalleryActionTypes.POST_VIDEO_COMMENT:
          return {
            ...state,
            commentPosted: true,
            commentPostLoading: false,
          };
        case GalleryActionTypes.DELETE_MESSAGE:
          return {
            ...state,
            commentDeleted: true,
            commentDeleteLoading: false,
          };
        default:
          return { ...state };
      }
    case GalleryActionTypes.API_RESPONSE_ERROR:
      switch (action.payload.actionType) {
        case GalleryActionTypes.GET_TV_GALLERY:
          return {
            ...state,
            tvGallery: {},
            getTvGalleryLoading: false,
          };
        case GalleryActionTypes.GET_TV_CONFIG_GALLERY:
          return {
            ...state,
            tvConfigGallery: {},
            getTvConfigGalleryLoading: false,
            liveAdded: false,
            fileUploaded: false,
          };
        case GalleryActionTypes.ADD_NEW_LIVE:
          return {
            ...state,
            addLoading: false,
            liveAdded: false,
          };
        case GalleryActionTypes.UPLOAD_LIVE_CONFIG_FILES:
          return {
            ...state,
            fileUploadLoading: false,
            fileUploaded: false,
          };
        case GalleryActionTypes.DELETE_LIVE_CONFIG_FILE:
          return {
            ...state,
            isFileDeleted: false,
          };
        case GalleryActionTypes.GET_TV_VIDEO_DETAILS:
          return {
            ...state,
            videoDetails: {},
            getTvvideoDetailsLoading: false,
            commentPosted: false,
            commentDeleted: false,
          };
        case GalleryActionTypes.POST_VIDEO_COMMENT:
          return {
            ...state,
            commentPosted: false,
            commentPostLoading: false,
          };
        case GalleryActionTypes.DELETE_MESSAGE:
          return {
            ...state,
            commentDeleted: false,
            commentDeleteLoading: false,
          };
        default:
          return { ...state };
      }

    case GalleryActionTypes.GET_TV_GALLERY:
      return {
        ...state,
        getTvGalleryLoading: true,
      };
    case GalleryActionTypes.GET_TV_CONFIG_GALLERY:
      return {
        ...state,
        getTvConfigGalleryLoading: true,
        liveAdded: false,
        fileUploaded: false,
      };
    case GalleryActionTypes.ADD_NEW_LIVE:
      return {
        ...state,
        addLoading: true,
        liveAdded: false,
      };
    case GalleryActionTypes.UPLOAD_LIVE_CONFIG_FILES:
      return {
        ...state,
        fileUploadLoading: true,
        fileUploaded: false,
      };
    case GalleryActionTypes.DELETE_LIVE_CONFIG_FILE:
      return {
        ...state,
        isFileDeleted: false,
      };
    case GalleryActionTypes.GET_TV_VIDEO_DETAILS:
      return {
        ...state,
        getTvvideoDetailsLoading: true,
      };
    case GalleryActionTypes.POST_VIDEO_COMMENT:
      return {
        ...state,
        commentPosted: false,
        commentPostLoading: true,
      };
    case GalleryActionTypes.DELETE_MESSAGE:
      return {
        ...state,
        commentDeleted: false,
        commentDeleteLoading: true,
      };
    default:
      return state;
  }
};

export default Gallery;
