import React from "react";
import { Table, Row, Card, CardBody, Col } from "reactstrap";

//dummy data
import { details1 } from "./data";
const DadosSolicitante = () => {
  return (
    <Card>
      <CardBody>
        <div className="p-1">
          <div className="d-flex align-items-center">
            <div className="flex-1 overflow-hidden">
              <h5 className="font-size-20">Dados Solicitante</h5>
            </div>
          </div>
        </div>
        <div className="pt-4 border-top">
          <Row>
            <Col lg={4} xs={12}>
              <Table className="table table-borderless" responsive>
                <tbody>
                  <tr>
                    <th className="text-end" style={{ width: "35%" }}>
                      Solicitação:{" "}
                    </th>
                    <td>{details1.id}</td>
                  </tr>
                  <tr>
                    <th className="text-end">Parceiro: </th>
                    <td>{details1.address}</td>
                  </tr>
                  <tr>
                    <th className="text-end">Endereço:</th>
                    <td>{details1.address2}</td>
                  </tr>
                  <tr>
                    <th className="text-end">Instalado pelo Revendedor:</th>
                    <td>{details1.method}</td>
                  </tr>
                </tbody>
              </Table>
            </Col>
            <Col lg={4} xs={12}>
              <Table className="table table-borderless" responsive>
                <tbody>
                  <tr>
                    <th className="text-end" style={{ width: "35%" }}>
                      Criada em:
                    </th>
                    <td>{details1.date}</td>
                  </tr>
                  <tr>
                    <th className="text-end">E-mail:</th>
                    <td>{details1.email}</td>
                  </tr>
                  <tr>
                    <th className="text-end">CEP:</th>
                    <td>{details1.cep}</td>
                  </tr>
                </tbody>
              </Table>
            </Col>
            <Col lg={4} xs={12}>
              <Table className="table table-borderless" responsive>
                <tbody>
                  <tr>
                    <th className="text-end" style={{ width: "35%" }}>
                      Fabricante:
                    </th>
                    <td>{details1.fabricante}</td>
                  </tr>
                  <tr>
                    <th className="text-end">Telefone:</th>
                    <td>{details1.telephone}</td>
                  </tr>
                  <tr>
                    <th className="text-end">Cidade/Estado:</th>
                    <td>{details1.estado}</td>
                  </tr>
                </tbody>
              </Table>
            </Col>
          </Row>
        </div>
      </CardBody>
    </Card>
  );
};

export default DadosSolicitante;
