import React from "react";
import { Card, CardBody, Row } from "reactstrap";

import {
  AvField,
} from "availity-reactstrap-validation";

import { typesRuleDiscountOptions } from "./constants";
import { formatDiscountAmount } from "./functions";
import Switch from "../../components/Switch";

const PromotionActions = ({
  typeRuleDiscount,
  setTypeRuleDiscount,
  discountAmount,
  setDiscountAmount,
  maximumProductsAllowed,
  setMaximumProductsAllowed,
  applyToZipcode,
  setApplyToZipcode
}) => {
  const handleDiscountAmountChange = (e) => {
    const maskedValue = formatDiscountAmount(e.target.value, typeRuleDiscount);
    setDiscountAmount(maskedValue);
  };

  const handleTypeRuleDiscountChange = (e) => {
    setDiscountAmount(0);
    setTypeRuleDiscount(e.target.value);
  }

  return (
    <Card>
      <CardBody>
        <div className="w-100 d-flex justify-content-center flex-column">
          <h5>
            <i className="fas fa-edit me-1"></i> Ações do desconto
          </h5>
          <hr />
          <Row className="mb-3">
            <AvField
              type="select"
              name="typeRuleDiscount"
              label="Selecione o tipo de desconto"
              helpMessage="Os tipos de desconto são Porcentagem e Dinheiro"
              onChange={handleTypeRuleDiscountChange}
              value={typeRuleDiscount}
            >
              {typesRuleDiscountOptions.map((typeRuleDiscount, index) => {
                return (
                  <option key={index} value={typeRuleDiscount.id}>
                    {typeRuleDiscount.description}
                  </option>)
              })}
            </AvField>
          </Row>

          <Row className="mb-3">
            <AvField
              name="discountAmount"
              type="text"
              label="Digite o valor do desconto"
              placeholder="Digite o valor do desconto"
              onChange={handleDiscountAmountChange}
              value={discountAmount}
              validate={{
                required: { value: true, errorMessage: 'Campo obrigatório' }
              }}
            />
          </Row>

          <Row className="mb-3">
            <AvField
              name="maximumProductsAllowed"
              type="number"
              label="Máximo de produtos com desconto:"
              placeholder="Digite aqui..."
              onChange={(e) => setMaximumProductsAllowed(e.target.value)}
              value={maximumProductsAllowed}
            />
          </Row>

          <Switch
            textLabel={"Aplica desconto no frete: "}
            setChecked={setApplyToZipcode}
            checked={applyToZipcode}
          />
        </div>
      </CardBody>
    </Card>
  );
}

export default PromotionActions;