import { DownloadActionTypes } from "./actionTypes";

export const downloadApiSuccess = (actionType, data) => ({
  type: DownloadActionTypes.API_RESPONSE_SUCCESS,
  payload: { actionType, data },
});

export const downloadApiFail = (actionType, error) => ({
  type: DownloadActionTypes.API_RESPONSE_ERROR,
  payload: { actionType, error },
});

// recenet users
export const getDownloadDetails = filters => ({
  type: DownloadActionTypes.GET_DOWNLOAD_DETAILS,
  payload: filters,
});

// categories filter options
export const getCategoriesOptions = categoryId => ({
  type: DownloadActionTypes.GET_CATEGORIES_OPTIONS_DETAILS,
  payload: categoryId,
});
