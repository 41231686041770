import React, { useState } from "react";
import { Link } from "react-router-dom";
import * as moment from "moment";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const tableFile = ({ data, deleteFile }) => {
  const [open, setOpen] = React.useState(false);
  const [idDelete, setIdDelete] = useState();

  const handleClickOpen = id => {
    setOpen(true);
    setIdDelete(id);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const getStatus = value => {
    switch (value) {
      case 1:
        return "Em Analise";

      case 2:
        return "Recusado";

      case 3:
        return "Aceito";

      case 4:
        return "Pago";
      case 5:
        return "Recebido";
      case 6:
        return "Em Processamento";
      default:
        console.log(`Sorry, we are out of ${value}.`);
    }
  };

  return (
    <>
      <table className="table">
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">Arquivo</th>
            <th scope="col">Enviardo por</th>
            <th scope="col">Enviardo em</th>
            <th scope="col">Autualizado em</th>
            <th scope="col">Status</th>
            <th scope="col">Obs</th>
            <th scope="col"></th>
          </tr>
        </thead>
        <tbody>
          {data?.fechamento_files.map((item, k) => (
            <tr
              key={k}
              style={{
                background: item?.status_id == 3 && "#dff0d8",
                color: item?.status_id == 3 && "#3c763d",
              }}
            >
              <th scope="row">{item?.id}</th>
              <td className="text-primary">
                <Link
                  style={{ display: "flex" }}
                  target="_blank"
                  href={item.url}
                >
                  <span style={{ marginTop: -4 }}>
                    <i className="mdi mdi-download-circle font-size-20 me-1"></i>
                  </span>
                  <p>{item.file_original}</p>
                </Link>
              </td>
              <td>
                {item.user.code} - {item.user.firstname}
              </td>
              <td>
                {moment(item?.created_at.substring(0, 10)).format("L")}{" "}
                {item?.created_at.substring(10, 20)}
              </td>
              <td>
                {moment(item?.updated_at.substring(0, 10)).format("L")}{" "}
                {item?.updated_at.substring(10, 20)}
              </td>
              <td>{getStatus(item?.status_id)}</td>
              <td>{item?.obs}</td>
              <td>
                {item.status_id == 1 && (
                  <button
                    onClick={() => handleClickOpen(item.id)}
                    className="d-flex align-items-center btn btn-danger"
                  >
                    <i style={{ marginRight: 3 }} className="ph-x-bold"></i>{" "}
                    Apagar
                  </button>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>{" "}
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Excluir"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Você tem certeza de que deseja excluir?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancelar</Button>
          <Button
            onClick={() => {
              handleClose();
              deleteFile(idDelete);
            }}
          >
            Excluir
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default tableFile;
