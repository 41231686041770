export const DownloadActionTypes = {
  API_RESPONSE_SUCCESS: "@@download/API_RESPONSE_SUCCESS",
  API_RESPONSE_ERROR: "@@download/API_RESPONSE_ERROR",

  //get download details
  GET_DOWNLOAD_DETAILS: "@@download/GET_DOWNLOAD_DETAILS",

  // get categories filter options
  GET_CATEGORIES_OPTIONS_DETAILS: "@@download/GET_CATEGORIES_OPTIONS_DETAILS",
};
