import React, { useEffect, useState } from "react"
import { Alert, Button, Card, CardBody, Col, Container, Label, Modal, ModalBody, ModalHeader, Row } from "reactstrap";
import Breadcrumb from "../../components/Breadcrumb";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import { getVendedor } from "../../api";
import SharedHistoryTable from "./sharedHistoryTable";
import { useDispatch, useSelector } from "react-redux";
import { deleteSharing, getSharingHistory, insertSharing } from "../../store/sharedResales/action";
import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";
import { Portuguese } from "flatpickr/dist/l10n/pt.js";
import { useColors } from "../../hooks/useColors";
import { toast } from "react-toastify";

const SharedResaleModal = ({ sharedSeller, isOpen, toggle }) => {

  const dispatch = useDispatch();
  const { colorDefault } = useColors();

  const getSharedSellerId = () => {
    return sharedSeller.data.user.CODIGO
  }

  const filterBy = () => true;

  const [responsibleSeller, setResponsibleSeller] = useState([]);

  const [sellers, setSellers] = useState([]);
  const [isLoadingSellers, setIsLoadingSellers] = useState(false);

  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();

  const { sharedHistory, loading, searchShares } = useSelector(state => ({
    sharedHistory: state.SharedResale.shares,
    loading: state.SharedResale.loading,
    searchShares: state.SharedResale.searchShares
  }));

  const searchSellers = (inputValue) => {
    setIsLoadingSellers(true);

    getVendedor(inputValue)
      .then(res => {
        let data = [];

        res.map(value => {
          data.push({
            codigo: value.CODIGO,
            nome: value.NOME,
            label: `${value.CODIGO} - ${value.NREDUZ.trim()}`,
            nreduz: value.NREDUZ,
            cnpj: value.CPFCNPJ,
          });
        });

        setSellers(data);
        setIsLoadingSellers(false);
      })
      .catch(console.log);
  };

  const handleClickAdd = () => {
    if (!responsibleSeller[0] || !startDate || !endDate) {
      return toast.error('Preencha todos os campos');
    }

    if (new Date(startDate) > new Date(endDate)) {
      setEndDate(startDate);
      return toast.error('A data de ínicio não pode ser superior ao final');
    }

    const insertData = {
      sharedSellerId: sharedSeller.data.user.CODIGO,
      responsibleSellerId: responsibleSeller[0].codigo,
      startDate: startDate,
      endDate: endDate,
    };

    dispatch(insertSharing(insertData))
  }

  const handleClickDelete = (sharingId) => {
    dispatch(deleteSharing(sharingId));
  }

  useEffect(() => {
    if (sharedSeller && isOpen) {
      dispatch(getSharingHistory(getSharedSellerId()))
    }
  }, [isOpen]);

  useEffect(() => {
    if (searchShares) {
      dispatch(getSharingHistory(getSharedSellerId()))
    }
  }, [searchShares]);

  function changeDate(dateY) {
    let data = dateY.date;
    let dataFormatada = `${data.getFullYear()}${(data.getMonth() + 1)
      .toString()
      .padStart(2, "0")}${data.getDate().toString().padStart(2, "0")}`;
    return dataFormatada;
  }

  return (
    <Modal size="xl" isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>Compartilhar a revenda #{getSharedSellerId()} {sharedSeller?.data.user?.NOME} </ModalHeader>
      <ModalBody>
        <Row>
          <Col md={6}>
            <Label for="select-partner">Selecione o vendedor que terá acesso a revenda:</Label>
            <AsyncTypeahead
              id="sellers"
              className="parceiro-input"
              onChange={setResponsibleSeller}
              options={sellers}
              placeholder="Pesquisar..."
              selected={responsibleSeller}
              minLength={3}
              filterBy={filterBy}
              isLoading={isLoadingSellers}
              onSearch={searchSellers}
              emptyLabel="Não foi encontrado nenhum vendedor"
              promptText="Buscando vendedores"
              searchText="Buscando vendedores"
              // onBlur={() => validatePartner()}
              inputProps={{
                id: "parceiro-selecionado",
              }}
              renderMenuItemChildren={option => (
                <div>
                  {option.codigo} {option.nreduz}
                  <div>
                    <small>{option.nome}</small>
                  </div>
                </div>
              )}
            />
          </Col>
          <Col md={3}>
            <Label for="exampleEmail">
              Data de início:
            </Label>
            <Flatpickr
              className="form-control d-block selectData"
              placeholder="dd/mm/aaaa"
              options={{
                altInput: true,
                locale: Portuguese,
                altFormat: "d/m/Y",
                dateFormat: "Ymd",
              }}
              value={startDate}
              onChange={([date]) =>
                setStartDate(changeDate({ date }))
              }
            />
          </Col>
          <Col md={3}>
            <Label for="exampleEmail">
              Data final:
            </Label>
            <Flatpickr
              className="form-control d-block selectData"
              placeholder="&#xf133; dd/mm/aaaa"
              options={{
                altInput: true,
                locale: Portuguese,
                altFormat: "d/m/Y",
                dateFormat: "Ymd",
              }}
              value={endDate}
              onChange={([date]) =>
                setEndDate(changeDate({ date }))
              }
            />
          </Col>
        </Row>
        <Row className="d-flex align-items-end justify-content-start">
          <Col md={3}>
            <Button
              className="mt-2" style={{ backgroundColor: colorDefault, color: 'white' }}
              onClick={handleClickAdd}
            >
              Adicionar novo compartilhamento
            </Button>
          </Col>
        </Row>

        <SharedHistoryTable
          sharedHistory={sharedHistory}
          handleClickDelete={handleClickDelete}
          loading={loading}
        />
      </ModalBody>
    </Modal>
  )
};

export default SharedResaleModal;