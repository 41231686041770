export const GalleryActionTypes = {
  API_RESPONSE_SUCCESS: "@@gallery/API_RESPONSE_SUCCESS",
  API_RESPONSE_ERROR: "@@gallery/API_RESPONSE_ERROR",

  GET_TV_GALLERY: "@@gallery/GET_TV_GALLERY",
  GET_TV_CONFIG_GALLERY: "@@gallery/GET_TV_CONFIG_GALLERY",
  LOAD_RECET_DATA: "@@gallery/LOAD_RECET_DATA",
  ADD_NEW_LIVE: "@@gallery/ADD_NEW_LIVE",
  UPLOAD_LIVE_CONFIG_FILES: "@@gallery/UPLOAD_LIVE_CONFIG_FILES",
  DELETE_LIVE_CONFIG_FILE: "@@gallery/DELETE_LIVE_CONFIG_FILE",
  GET_TV_VIDEO_DETAILS: "@@gallery/GET_TV_VIDEO_DETAILS",
  POST_VIDEO_COMMENT: "@@gallery/POST_VIDEO_COMMENT",
  DELETE_MESSAGE: "@@gallery/DELETE_MESSAGE",
};
