import React, { useState } from "react";
import {
  TabContent,
  TabPane,
  NavLink,
  NavItem,
  Nav,
  Card,
  Row,
  Col,
  CardBody,
} from "reactstrap";

import classnames from "classnames";

//componentds
import ChatTab from "./ChatTab";
import Status from "./Status";

const CHAT_TABS = {
  CHAT: "chat",
  STATUS: "status",
};
const DadosSolicitante = () => {
  const [activeTab, setactiveTab] = useState(CHAT_TABS.STATUS);

  const toggle = tab => {
    if (activeTab !== tab) {
      setactiveTab(tab);
    }
  };

  return (
    <Card className="">
      <CardBody>
        <div className="p-1">
          <div className="d-flex align-items-center">
            <div className="flex-1 overflow-hidden">
              <h5 className="font-size-20">Chat e Status</h5>
            </div>
          </div>
        </div>
        <div className="pt-4 border-top">
          <Row>
            <Col lg={12} xs={12}>
              <Nav tabs className="nav-tabs-custom">
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      active: activeTab === CHAT_TABS.CHAT,
                    })}
                    onClick={() => {
                      toggle(CHAT_TABS.CHAT);
                    }}
                  >
                    <span className="d-block d-sm-none">
                      <i className="fas fa-home"></i>
                    </span>
                    <span className="d-none d-sm-block">Chat</span>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      active: activeTab === CHAT_TABS.STATUS,
                    })}
                    onClick={() => {
                      toggle(CHAT_TABS.STATUS);
                    }}
                  >
                    <span className="d-block d-sm-none">
                      <i className="far fa-user"></i>
                    </span>
                    <span className="d-none d-sm-block">Status</span>
                  </NavLink>
                </NavItem>
              </Nav>

              <TabContent activeTab={activeTab} className="">
                <TabPane tabId={CHAT_TABS.CHAT}>
                  <ChatTab />
                </TabPane>
                <TabPane tabId={CHAT_TABS.STATUS} className="">
                  <Status />
                </TabPane>
              </TabContent>
            </Col>
          </Row>
        </div>
      </CardBody>
    </Card>
  );
};

export default DadosSolicitante;
