import React, { useState, useEffect } from "react";
import { Col, Row, FormGroup, Label, Input, Spinner } from "reactstrap";
import { consultaCep } from "../../../../api";
import InputMask from "react-input-mask";
import { mask } from "../../../../utils";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import { getVendedor as getVendedorApi } from "../../../../api";
import { useDispatch, useSelector } from "react-redux";
import {
  validEmail,
  formatValueToPhone,
  onlyAcceptNumber,
  onlyNumbersAndLetters,
} from "../../../../utils/otherUtils";
import {
  getCustomer,
  addEndValido,
  getFreteValue,
} from "../../../../store/actions";
export const FormPartner = ({
  changeClient,
  errosDataProspectAndPartner,
  setErrosDataProspectAndPartner,
  valTot,
}) => {
  const dispatch = useDispatch();
  const { customer } = useSelector(state => ({
    customer: state.Order.newOrder.newOrder.customer,
  }));
  const [dataProspect, setDataProspect] = useState({
    name: "",
    email: "",
    ddd: "",
    tel: "",
    cep: "",
    address: "",
    number: "",
    complement: "",
    state: "",
    city: "",
    district: "",
  });

  const [parceiros, setParceiros] = useState([]);
  const [filters, setFilters] = useState({
    vendedores: [{ codigo: "", label: "" }],
  });
  const filterBy = () => true;
  const [isLoadingVendedores, setIsLoadingVendedores] = useState(false);

  useEffect(() => {
    setErrosDataProspectAndPartner({
      name: "",
      email: "",
      ddd: "",
      tel: "",
      cep: "",
      address: "",
      number: "",
      complement: "",
      state: "",
      city: "",
      district: "",
    });
  }, []);

  const getCep = cep => {
    if (cep?.replace(/[^0-9]+/g, "").length == 8) {
      setDataProspect({
        ...dataProspect,
        address: "...",
        state: "...",
        city: "...",
        district: "...",
        cep: cep,
      });
      consultaCep(cep)
        .then(res => {
          dispatch(addEndValido(res.address));
          setDataProspect({
            ...dataProspect,
            address: res.address.address,
            state: res.address.state,
            city: res.address.city,
            district: res.address.district,
          });
        })
        .catch(err => console.log(err));
    }
  };

  const changeVendedores = inputValue => {
    setIsLoadingVendedores(true);
    setFilters({ ...filters, vendedores: [] });

    getVendedorApi(inputValue)
      .then(res => {
        let data = [];
        res.map(value => {
          data.push({
            codigo: value.CODIGO,
            nome: value.NOME,
            label: `${value.CODIGO} - ${value.NREDUZ.trim()}`,
            nreduz: value.NREDUZ,
            cnpj: value.CPFCNPJ,
          });
        });

        setFilters({ ...filters, vendedores: data });
        setIsLoadingVendedores(false);
      })
      .catch(console.log);
  };

  useEffect(() => {
    if (parceiros[0]?.cnpj) {
      dispatch(getCustomer({ cpfcnpj: parceiros[0]?.cnpj }));
      setDataProspect({});
    }
  }, [parceiros[0]?.cnpj]);

  useEffect(() => {
    setDataProspect({
      name: customer.data?.N_COMPLETO.trim() || "",
      email: customer.data?.EMAIL.trim() || "",
      ddd: customer.data?.DDD.trim() || "",
      tel: customer.data?.TEL.trim() || "",
      cep: customer.data?.CEP.trim() || "",
      address: customer.data?.ENDERECO.trim().split(",")[0] || "",
      number: customer.data?.NumEndCob.trim() || "",
      complement: customer.data?.COMPL.trim() || "",
      state: customer.data?.UFCob.trim() || "",
      city: customer.data?.MunicipEnt.trim() || "",
      district: customer.data?.BAI_ENT.trim() || "",
    });
  }, [customer]);

  useEffect(() => {
    changeClient({
      fullname: dataProspect.name || "",
      email: dataProspect.email,
      customer_address: [
        {
          zipcode: (dataProspect.cep || "").replace(/[^0-9]+/g, ""),
          district: dataProspect.district || "",
          city: (dataProspect.city || "")
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, ""),
          state: dataProspect.state || "",
          address: dataProspect.address || "",
          number: dataProspect.number || "",
          complement: dataProspect.complement || "",
        },
      ],
      customer_phones: [
        {
          ddd: dataProspect.ddd || "",
          phone: dataProspect.tel || "",
        },
      ],
    });
  }, [dataProspect]);

  const verfiyEmail = e => {
    setDataProspect({ ...dataProspect, email: e.target.value });
    const isValid = validEmail(e.target.value);
    if (isValid) {
      setErrosDataProspectAndPartner({
        ...errosDataProspectAndPartner,
        email: "",
      });
    } else {
      setErrosDataProspectAndPartner({
        ...errosDataProspectAndPartner,
        email: "Digite um email válido",
      });
    }
  };

  return (
    <Row className="mt-4">
      <Col>
        <h5 className="h5"> Dados do Parceiro</h5>
        <FormGroup>
          <Label>Selecione o Parceiro:</Label>
          <Row>
            <Col
              md={6}
              className="d-flex justify-content-end align-items-center"
            >
              <AsyncTypeahead
                id="parceiro"
                className="parceiro-input"
                onChange={setParceiros}
                options={filters.vendedores}
                placeholder="Pesquisar..."
                selected={parceiros}
                minLength={3}
                filterBy={filterBy}
                onSearch={changeVendedores}
                emptyLabel="Não foi encontrado nenhum vendedor"
                promptText="Buscando vendedores"
                searchText="Buscando vendedores"
                inputProps={{
                  id: "parceiro-selecionado",
                }}
                renderMenuItemChildren={option => (
                  <div>
                    {option.codigo} {option.nreduz}
                    <div>
                      <small>{option.nome}</small>
                    </div>
                  </div>
                )}
              />
              {isLoadingVendedores ? (
                <Spinner className="loading-parceiro" />
              ) : (
                <i className="fas fa-search campo-buscar"></i>
              )}
            </Col>
            <Col md={6}>
              <Input
                placeholder="CPF/CNPJ Vendedor"
                value={mask(parceiros[0]?.cnpj || "")}
                disabled={true}
                readOnly
              />
            </Col>
          </Row>
          <Row className="mt-4">
            {" "}
            <Col md={6}>
              <Label>Nome:</Label>
              <Input
                id="name"
                value={dataProspect.name}
                onChange={e =>
                  setDataProspect({ ...dataProspect, name: e.target.value })
                }
              />
            </Col>{" "}
            <Col md={6}>
              <Label>E-mail:</Label>
              <Input
                id="email"
                value={dataProspect.email}
                onChange={verfiyEmail}
              />
              <p className="erros">{errosDataProspectAndPartner.email}</p>
            </Col>
          </Row>
          <Row className="mt-4">
            {" "}
            <Col md={3}>
              <Label>DDD:</Label>
              <Input
                id="ddd"
                onChange={e =>
                  setDataProspect({
                    ...dataProspect,
                    ddd: onlyAcceptNumber(e),
                  })
                }
                maxLength={2}
                value={dataProspect.ddd}
              />
            </Col>{" "}
            <Col md={9}>
              <Label>Telefone:</Label>
              <Input
                id="tel"
                onChange={e =>
                  setDataProspect({
                    ...dataProspect,
                    tel: formatValueToPhone(e),
                  })
                }
                value={dataProspect.tel}
              />
            </Col>
          </Row>
        </FormGroup>{" "}
        <h5 style={{ marginTop: 20 }} className="h5">
          {" "}
          Endereço para Entrega
        </h5>
        <FormGroup>
          <Row className="mt-4">
            <Col md={4}>
              <Label>CEP:</Label>
              <InputMask
                className="form-control"
                mask="99999-999"
                id="cep"
                onChange={e => getCep(e.target.value)}
              />
            </Col>{" "}
            <Col md={8}>
              <Label>Endereço:</Label>
              <Input
                id="endereco"
                value={dataProspect.address}
                onChange={e =>
                  setDataProspect({ ...dataProspect, address: e.target.value })
                }
              />
            </Col>
          </Row>
          <Row className="mt-4">
            {" "}
            <Col md={3}>
              <Label>Número:</Label>
              <Input
                id="numero"
                maxLength={6}
                onChange={e =>
                  setDataProspect({
                    ...dataProspect,
                    number: onlyNumbersAndLetters(e.target.value),
                  })
                }
                value={dataProspect.number}
              />
            </Col>{" "}
            <Col md={9}>
              <Label>Complemento:</Label>
              <Input
                id="complemento"
                value={dataProspect.complement}
                onChange={e =>
                  setDataProspect({
                    ...dataProspect,
                    complement: e.target.value,
                  })
                }
              />
            </Col>
          </Row>
          <Row className="mt-4">
            <Col md={12}>
              <Label>Bairro:</Label>
              <Input
                id="bairro"
                value={dataProspect.district}
                onChange={e =>
                  setDataProspect({ ...dataProspect, district: e.target.value })
                }
              />
            </Col>{" "}
          </Row>
          <Row className="mt-4">
            <Col md={9}>
              <Label>Cidade:</Label>
              <Input
                id="cidade"
                disabled
                value={dataProspect.city}
                onChange={e =>
                  setDataProspect({ ...dataProspect, city: e.target.value })
                }
              />
            </Col>{" "}
            <Col md={3}>
              <Label>Estado:</Label>
              <Input
                id="uf"
                disabled
                value={dataProspect.state}
                onChange={e =>
                  setDataProspect({ ...dataProspect, state: e.target.value })
                }
              />
            </Col>{" "}
          </Row>
        </FormGroup>
      </Col>
    </Row>
  );
};
