const labels = [
  [
    /*Title*/ " horas de luz solar utilizável por ano",
    /*Descr*/ "Com base na análise diária dos padrões climáticos.",
  ],
  [
    /*Title*/ "  m² tamanho recomendado para sua instalação",
    /*Descr*/ "Este tamanho cobrirá cerca de 97% do seu uso de eletricidade.",
  ],
  [
    /*Title*/ " Economia de R$ ",
    /*Descr*/ "Economia líquida estimada para o seu telhado em 25 anos.",
  ],
];
export { labels };
