export const API_SUCCESS = "API_SUCCESS";
export const API_FAIL = "API_FAIL";

export const GET_CLOSURES = "GET_CLOSURES";

export const CREATE_UPLOAD = "CREATE_UPLOAD";

export const IS_LOADING = "IS_LOADING";

export const ATT_FILE = "ATT_FILE";

export const DELETE_CLOSURE = "DELETE_CLOSURE";
