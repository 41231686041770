import { SettlementsActionTypes } from "./actionTypes";

export const settlementsApiSuccess = (actionType, data) => ({
  type: SettlementsActionTypes.API_RESPONSE_SUCCESS,
  payload: { actionType, data },
});

export const settlementsApiFail = (actionType, error) => ({
  type: SettlementsActionTypes.API_RESPONSE_ERROR,
  payload: { actionType, error },
});

export const getSettlementsList = filters => ({
  type: SettlementsActionTypes.GET_SETTLEMENTS_LIST,
  payload: { filters },
});
