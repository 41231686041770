import { call, put, takeEvery, takeLatest } from "redux-saga/effects";

// Login Redux States
import { GET_FIREBASE_REMOTE_CONFIG } from "./actionTypes";
//Include Both Helper File with needed methods
import { getFirebaseBackend } from "../../helpers/firebase_helper";
import { firebaseRemoteConfigSuccess } from "./actions";

const fireBaseBackend = getFirebaseBackend();

function* getFirebaseRemoteConfig({ payload: props }) {
  try {
    if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
      const response = yield call(fireBaseBackend.getFirebaseRemoteConfig, {
        methodLogin: props?.data,
        defaultValue: props?.defaultValue,
      });
      console.log({getFirebaseRemoteConfig:response,payload: props});
      yield put(firebaseRemoteConfigSuccess(response, props.actionType));
    }
  } catch (error) {
    console.log("erro:", error);
  }
}

function* authSaga() {
  yield takeEvery(GET_FIREBASE_REMOTE_CONFIG, getFirebaseRemoteConfig);
}

export default authSaga;
