import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getProductDetails, addToCart } from "../../store/actions";
import { formato } from "../../utils";
import { useProfile } from "../../hooks";
import thermometerFull from "../../assets/images/thermometers/thermometer-full.png";
import thermometerMinimum from "../../assets/images/thermometers/thermometer-minimum.png";
import thermometerMedium from "../../assets/images/thermometers/thermometer-medium.png";
import {
  CardBody,
  Col,
  Row,
  Card,
  FormGroup,
  Label,
  Input,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
  Table,
  Tooltip,
  Toast,
  Alert,
} from "reactstrap";

import A from "../../assets/images/bandeira/bandeira-a.png";
import B from "../../assets/images/bandeira/bandeira-b.png";
import C from "../../assets/images/bandeira/bandeira-c.png";
import D from "../../assets/images/bandeira/bandeira-d.png";
import E from "../../assets/images/bandeira/bandeira-e.png";
import F from "../../assets/images/bandeira/bandeira-f.png";
import ModalAdias from "../../components/Modal";
import { toast } from "react-toastify";
import { red } from "@material-ui/core/colors";
import { filiais } from "../../utils/filiais";
import { Ticket } from "phosphor-react";
import { Link } from "react-router-dom";
import SvgIcon from "../../components/SvgIcon";
import { useDataLayer } from "../../hooks/DataLayerHooks";

const FindedItem = ({ produto, ocultarSemEstoque, pedido, id, isRevenda }) => {
  const { pushToDataLayer } = useDataLayer();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { userProfile, setUserProfile } = useProfile();

  const [isTooltipOpen, setIsTooltipOpen] = useState(false);
  //pega o estoque na filial selecionada
  //menor ou igual a 1 sem estoque
  const semEstoque = isRevenda ? 1 : 0;
  //maior ou igual a dois estoque baixo
  const estoqueBaixo = isRevenda ? 2 : 1;
  //menor ou igual a 19 estoque baixo
  const estoqueBaixoMax = 19;
  //maior ou igual a vinte estoque normal
  const estoqueNormal = 20;

  const limitAddEstoqueCarrinhoRevenda = 5;

  const dispatch = useDispatch();
  const [precoVenda, setPrecoVenda] = useState(0);
  const { findedItems, addedItems, vendedor } = useSelector(state => ({
    findedItems: state.Order.newOrder.newOrder.findedItems,
    addedItems: state.Order.newOrder.newOrder.addedItems,
    vendedor: state.Order.newOrder.newOrder.vendedor,
  }));
  const item = findedItems[produto.CODPRODUTO]
    ? findedItems[produto.CODPRODUTO]
    : {};

  const descMax = Number(vendedor.VENDEDOR.PERCDESCONTO) / 100;
  const valTabela = Number(
    produto.PRCVENDA.STRETPRCFILADIAS[0].PRCVENDA
  );
  const valMin = valTabela - valTabela * descMax;
  const valMax =
    Number(vendedor.VENDEDOR.TEMRT) == 1
      ? Number(produto.PRCVENDA.STRETPRCFILADIAS[0].PRCVENDA)
      : valTabela * 1.5;
  const [varPre, setVarPre] = useState(0);

  const bandeiras = {
    A: A,
    B: B,
    C: C,
    D: D,
    E: E,
    F: F,
  };

  let totalValue = 0;

  useEffect(() => {
    if (produto.PRCVENDA.STRETPRCFILADIAS) {
      setVarPre(
        Number(produto.PRCVENDA.STRETPRCFILADIAS[0].PRCVENDA)
      );
      setPrecoVenda(
        Number(produto.PRCVENDA.STRETPRCFILADIAS[0].PRCVENDA)
      );
    }
  }, [produto]);

  const checkCompany = company => {
    let num = parseInt(company);
    if (num >= 1 && num <= 7) {
      return true;
    } else {
      return false;
    }
  };

  const addToCartBtn = async () => {
    if (isRevenda) {
      //se tiver um estoque menor que 5 o usuario revenda não pode adicionar produto no carrinho
      if (totalStock < limitAddEstoqueCarrinhoRevenda) {
        return toast.info("Entrar em contato com o consultor", {
          theme: "colored",
        });
      }
    }

    if (totalStock >= semEstoque) {
      const result = addedItems.find(i => i.cod === produto.CODPRODUTO);
      if (!result) {
        let data = {
          cod: produto.CODPRODUTO,
          produto: {
            ...produto,
            ITEM: "",
            sku: produto.CODPRODUTO,
            valorTabela: precoVenda
          },
          promotionDiscount: 0,
          precoVenda: precoVenda,
        };

        toast.success(`${produto.CODPRODUTO} adicionado ao carrinho.`, {
          theme: "colored",
        });

        if (produto.PROMOCAO) {
          const itemPromo = produto.PROMOCAO.map((item) => item);
          toast.success(`${itemPromo[0].nome}`, {
            theme: "colored",
          });
        }

        pushToDataLayer(
          'add_to_cart',
          {
            ecommerce: {
              currency: 'BRL',
              items: [{
                item_id: produto.CODPRODUTO,
                item_name: produto.DESCRICAO,
                item_brand: produto.MARCA,
                item_category: produto.CATEGORIA,
                price: produto.PRCVENDA?.STRETPRCFILADIAS[0]?.PRCVENDA,
                quantity: 1
              }]
            }
          }
        );

        dispatch(addToCart(data));
        data.produto.PRCRT = data.precoVenda;
        data.produto.subtotal = data.precoVenda;
      } else {
        toast.warning(`${produto.CODPRODUTO} já consta no carrinho.`, {
          theme: "colored",
        });
      }
    }
  };

  useEffect(() => {
    if (!findedItems[produto.CODPRODUTO]) {
      dispatch(getProductDetails(produto.CODPRODUTO));
    }
  }, [dispatch]);

  function getCodFab() {
    if (item.atributosEspeciais) {
      let val = Object.values(item.atributosEspeciais);
      let selo1 = val.find(i => i.Codattr == "304");
      let selo2 = val.find(i => i.Codattr == "305");
      if (!(selo1 && selo2)) {
        return false;
      } else {
        return (selo1?.Valor || "") + " / " + (selo2.Valor || "");
      }
    } else {
      return false;
    }
  }

  function getSeloA() {
    if (item.atributosEspeciais) {
      let val = Object.values(item.atributosEspeciais);
      let selo1 = val.find(i => i.Codattr == "188");
      if (selo1) {
        return selo1.Valor;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }
  var arrayEstoque = [];
  function hasEstoque() {
    if (produto) {
      for (var items in produto.PRDDISPS.STRETPRDFILADIAS) {
        if (produto.PRDDISPS.STRETPRDFILADIAS[items].ESTOQUE <= semEstoque) {
          arrayEstoque.push(true);
        } else {
          arrayEstoque.push(false);
        }
      }

      return !arrayEstoque.includes(false);
    }
  }

  const totalStock = produto.PRDDISPS.STRETPRDFILADIAS.reduce((total, item) => total + item.ESTOQUE, 0);

  return (
    <React.Fragment>
      <Card
        className="mt-4"
        hidden={
          ocultarSemEstoque && hasEstoque()
          /* estoqueFilialSelect < 1 */
        }
      >
        <CardBody>
          <Row className="d-flex align-items-center gap-2 mb-2">
            {produto?.PROMOCAO &&
              produto.PROMOCAO.map((item, k) => (
                <Link
                  to="#"
                  onClick={e => {
                    if (!item?.link) {
                      e.preventDefault();
                    } else {
                      window.open(item.link);
                    }
                  }}
                  key={k}
                  style={{
                    background: item.cor,
                    cursor: item?.link ? "pointer" : "auto",
                  }}
                  className="tickets-campaign"
                >
                  <Ticket size={18} color="#fff" weight="bold" />
                  <span>{item.label}</span>
                </Link>
              ))}

            {produto?.PRCVENDA?.STRETPRCFILADIAS[0]?.PRECODIFER ==
              "S" && (
                <Link
                  style={{
                    background: "#004476",
                  }}
                  className="tickets-campaign"
                >
                  <Ticket size={18} color="#fff" weight="bold" />
                  <span>Preço especial!</span>
                </Link>
              )}
          </Row>

          <Row className="d-flex align-items-center">
            {item.fotos ? (
              <Col md={1}>
                <img
                  src={item?.fotos[0]}
                  className="parceiro-input hov-hand"
                  onClick={() => setIsModalOpen(!isModalOpen)}
                />
              </Col>
            ) : (
              <div />
            )}

            <Col md={item?.fotos ? 3 : 4}>
              <h5 style={{ fontSize: 16, fontWeight: 500 }}>
                {produto.DESCRICAO}
              </h5>
              <Row>
                <Col className="mt-2 d-flex align-items-center">
                  <div>
                    <p className="text-muted mb-0" style={{ fontSize: 11 }}>
                      Cód. Fabricante: {getCodFab() || ""}
                    </p>
                    <p className="text-muted mb-0" style={{ fontSize: 11 }}>
                      SKU: {produto.CODPRODUTO}
                    </p>
                  </div>
                  <img
                    src={bandeiras[getSeloA() || produto.SELOA]}
                    className="selo-a"
                  />
                </Col>
              </Row>
            </Col>
            <Col md={2}>
              <p className="align-center">Tabela Mínima</p>
              <div className="campo-valores">
                <Input
                  className="mb-0 mt-4 pre-venda"
                  value={varPre.toLocaleString("pt-br", formato)}
                  onChange={e => {
                    setVarPre(e.target.value);
                  }}
                  disabled={
                    vendedor?.VENDEDOR?.PERCDESCONTO == 0 ? true : false
                  }
                  onBlur={() => {
                    let val =
                      String(varPre)
                        .replace(/[^0-9,]*/g, "")
                        .replace(",", ".") || 0;
                    if (Number(val) >= valMin && Number(val) <= valMax) {
                      setPrecoVenda(Number(val));
                    } else {
                      setVarPre(Number(precoVenda));
                      toast.warning(
                        `O preço tabela não autorizado. Deve ser maior que ${valMin.toLocaleString(
                          "pt-br",
                          formato
                        )} e menor que ${valMax.toLocaleString(
                          "pt-br",
                          formato
                        )}`,
                        { theme: "colored" }
                      );
                    }
                  }}
                />
              </div>
            </Col>
            <Col md={2}>
              <p className="align-center">Valor Tabela</p>
              <div className="campo-valores">
                <p className="mb-0 val-total">
                  {Number(
                    produto.PRCVENDA.STRETPRCFILADIAS[0].PRCVENDA
                  ).toLocaleString("pt-br", formato)}
                </p>
              </div>
            </Col>
            <Col md={2}>
              <p className="align-center">Disponibilidade</p>
              <div className="disp-text">
                {totalStock >= estoqueNormal ? (
                  <p className="mb-0" style={{ color: "#13b013" }}>
                    <span className="h3" style={{ color: "#13b013" }}>
                      <img src={thermometerFull} />
                    </span>{" "}
                    <br />
                    Estoque normal{" "}
                  </p>
                ) : totalStock >= estoqueBaixo ? (
                  <p className="mb-0" style={{ color: "#FFBF00" }}>
                    <span className="h3" style={{ color: "#FFBF00" }}>
                      {/* <img src={thermometerMedium} /> */}
                      {isRevenda ? (
                        totalStock <= 3 ? (
                          <SvgIcon name="query" />
                        ) : (
                          totalStock
                        )
                      ) : (
                        totalStock
                      )}
                    </span>{" "}
                    <br />
                    {totalStock < limitAddEstoqueCarrinhoRevenda &&
                      isRevenda
                      ? "Sob Consulta"
                      : `Estoque baixo ${checkCompany(
                        JSON.parse(localStorage.getItem("_app_auth_user"))
                          ?.claims?.company
                      )
                        ? `(${totalStock})`
                        : null
                      }`}
                  </p>
                ) : totalStock <= semEstoque ? (
                  <p className="mb-0" style={{ color: "#8B0000" }}>
                    <span className="h3" style={{ color: "#8B0000" }}>
                      <img src={thermometerMinimum} />
                    </span>{" "}
                    <br />
                    Sem Estoque
                  </p>
                ) : (
                  ""
                )}
              </div>
            </Col>
            <Col
              md={2}
              style={{ height: "100%", justifyContent: "space-between" }}
            >
              {item.atributos ? (
                <Button
                  className="but-modal"
                  onClick={() => setIsModalOpen(!isModalOpen)}
                >
                  Ver detalhes
                </Button>
              ) : (
                <div />
              )}

              {totalStock != semEstoque &&
                (totalStock < limitAddEstoqueCarrinhoRevenda &&
                  isRevenda ? (
                  <Button
                    id={`addButton-${id}`}
                    style={{ textAlign: "center", marginTop: 5 }}
                    disabled
                    className="mt-2 but-add-second"
                  >
                    Contate Consultor
                  </Button>
                ) : (
                  <Button
                    id={`addButton-${id}`}
                    className="mt-2 but-add-second"
                    onClick={() => addToCartBtn()}
                  >
                    Adicionar
                  </Button>
                ))}

              {totalStock != semEstoque &&
                totalStock < limitAddEstoqueCarrinhoRevenda &&
                isRevenda ? (
                <Tooltip
                  target={`addButton-${id}`}
                  isOpen={isTooltipOpen}
                  placement="bottom"
                  toggle={() => setIsTooltipOpen(prev => !prev)}
                  style={{ backgroundColor: "rgb(0, 86, 150)" }}
                >
                  Entrar em contato com o consultor
                </Tooltip>
              ) : null}
            </Col>
          </Row>
        </CardBody>
      </Card>

      {item?.atributos ? (
        <ModalAdias
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          produtoDetails={item}
          produto={produto}
        />
      ) : (
        <div />
      )}
    </React.Fragment>
  );
};

export default FindedItem;
