import React, { useState } from "react";
import { ArInput } from "../../../../components/adias-input";
import { Col, Row } from "reactstrap";
import { TitleInputs } from "../../style";

import { getAdressesFromZipCode } from "../../../../api";

export const EnderecoFaturamento = ({
  errors,
  touched,
  values,
  setFieldValue,
  isOpenEnderecoEntrega,
}) => {
  const [, setLoadingCep] = useState(false);
  const [dataCep, setDataCep] = useState({
    ENDERECO: "",
    BAIRRO: "",
    MUNICIPIO: "",
    UF: "",
  });
  const getCep = cep => {
    if (cep?.replace(/[^0-9]+/g, "").length == 8) {
      setLoadingCep(true);
      setFieldValue("ENDERECO", "");
      setFieldValue("BAIRRO", "");
      setFieldValue("MUNICIPIO", "");
      setFieldValue("UF", "");
      getAdressesFromZipCode(cep)
        .then(res => {
          if (!isOpenEnderecoEntrega) {
            setFieldValue("END_ENT", res.data.logradouro);
            setFieldValue("BAI_ENT", res.data.bairro);
            setFieldValue("MunicipEnt", res.data.localidade);
            setFieldValue("UF_ENT", res?.data?.uf || res?.data?.UF);
            setFieldValue("CEP_ENT", cep);
            setFieldValue("NumEndEnt", values.NumEndCob);
            setFieldValue("COMPL_ENT", values.COMPL);
          }

          setFieldValue("ENDERECO", res.data.logradouro);
          setFieldValue("BAIRRO", res.data.bairro);
          setFieldValue("MUNICIPIO", res.data.localidade);
          setFieldValue("UF", res?.data?.uf || res?.data?.UF);
          setDataCep({
            ENDERECO: res.data.logradouro || "",
            BAIRRO: res.data.bairro || "",
            MUNICIPIO: res.data.localidade || "",
            UF: res.data.uf || "",
          });
        })
        .catch(err => console.log(err));
      setLoadingCep(false);
    }
  };
  return (
    <>
      {" "}
      <TitleInputs>ENDEREÇO FATURAMENTO {"&"} COBRANÇA</TitleInputs>
      <Row>
        <Col md={3}>
          <ArInput
            title="CEP"
            errors={errors}
            name="CEP"
            touched={touched}
            onchange={(e, value) => getCep(value)}
            mask="cep"
          />
        </Col>
        <Col md={3}>
          <ArInput title="Estado" errors={errors} name="UF" touched={touched} />
        </Col>
        <Col md={3}>
          <ArInput
            title="Cidade"
            errors={errors}
            name="MUNICIPIO"
            touched={touched}
          />
        </Col>
        <Col md={3}>
          <ArInput
            title="Bairro"
            errors={errors}
            name="BAIRRO"
            touched={touched}
          />
        </Col>
      </Row>
      <Row>
        <Col md={4}>
          <ArInput
            title="Endereço"
            errors={errors}
            name="ENDERECO"
            touched={touched}
          />
        </Col>
        <Col md={4}>
          <ArInput
            title="Número"
            errors={errors}
            name="NumEndCob"
            maxLength={6}
            touched={touched}
          />
        </Col>
        <Col md={4}>
          <ArInput
            title="Complemento"
            errors={errors}
            name="COMPL"
            touched={touched}
          />
        </Col>
      </Row>
    </>
  );
};
