import { CustomFieldActionTypes } from "./actionTypes";

const INIT_STATE = {
  list: {
    loading: false,
    error: null,
    customFields: []
  },
  create: {
    loading: false,
    customFieldCreated: null,
    error: null
  }
}

const CustomField = (state = INIT_STATE, action) => {
  switch (action.type) {
    case CustomFieldActionTypes.API_RESPONSE_SUCCESS:
      switch (action.payload.actionType) {
        case CustomFieldActionTypes.CREATE_CUSTOM_FIELD:
          return {
            ...state,
            create: {
              loading: false,
              customFieldCreated: action.payload.data,
              error: false
            }
          }

        case CustomFieldActionTypes.GET_CUSTOM_FIELDS:
          return {
            ...state,
            list: {
              loading: false,
              error: null,
              customFields: action.payload.data
            }
          }

        default: state;
      }
    case CustomFieldActionTypes.API_RESPONSE_FAIL:
      switch (action.payload.actionType) {
        case CustomFieldActionTypes.CREATE_CUSTOM_FIELD:
          return {
            ...state,
            create: {
              loading: false,
              customFieldCreated: null,
              error: action.payload.error
            }
          }

        case CustomFieldActionTypes.GET_CUSTOM_FIELDS:
          return {
            ...state,
            list: {
              ...state,
              loading: false,
              error: action.payload.error
            }
          }
      }

    case CustomFieldActionTypes.CREATE_CUSTOM_FIELD:
      return {
        ...state,
        create: {
          loading: true,
          customFieldCreated: null,
          error: null
        }
      }

    case CustomFieldActionTypes.GET_CUSTOM_FIELDS:
      return {
        list: {
          customFields: [...state.list.customFields],
          loading: true,
          error: null
        },
        create: {
          loading: false,
          customFieldCreated: null,
          error: null
        }
      }

    default: return state;
  }
};

export default CustomField;