import React from "react";
import { Col, Row } from "reactstrap";

//import Components
import { HeadCol, TitleCol, Actions } from "../../Events/Events/ClassesListCol";
import CustomTable from "../../../components/CustomTable";

const ClassesList = () => {
  const classesList = [
    {
      id: 1,
      title:
        "Technical Training VRF Trane + A.Dias Rio de Janeiro - 11/23/2021	",
    },
  ];

  const columns = [
    {
      Header: <HeadCol label="Class" />,
      accessor: "globalRating",
      Cell: function colRenderer(props) {
        return <TitleCol data={props} />;
      },
      width: 400,
    },
    {
      Header: <HeadCol label="" />,
      accessor: "deleteIcon",
      Cell: function colRenderer(props) {
        return <Actions data={props} />;
      },
      width: 50,
    },
  ];
  return (
    <React.Fragment>
      <Row>
        <Col md={6}>
          <h3>classes</h3>
        </Col>
        <Col md={6}>
          <span className="float-end">
            <button className="btn btn-primary float-end">new class</button>
          </span>
        </Col>
      </Row>
      <hr className="my-4" />

      <CustomTable
        columns={columns}
        data={classesList || []}
        classNames="table-hover table-borderless custom-table align-middle"
        selectable={false}
        idField={"id"}
      />
    </React.Fragment>
  );
};

export default ClassesList;
