import { React } from "../../../helpers/importer.helper";
import Footer from "../../Footer";
import "./styles.scss";

export default function ContainerBottom({ dataPdf }) {
  const formato = {
    minimumFractionDigits: 2,
    currency: "BRL",
  };

  const total_value =
    parseFloat(dataPdf?.dataOrcamento?.orcamento?.total) +
    parseFloat(dataPdf?.dataOrcamento?.orcamento?.items[0].ValServ);

  return (
    <div className="container-bottom">
      <h1>Investimento Médio do Sistema:</h1>
      <div className="money">
        <span>R$</span>
        <p>{total_value.toLocaleString("pt-br", formato)}</p>
      </div>
      <div className="container-cards">
        <div className="card-double">
          <span>
            <strong>Obs:</strong> Este é um Estudo Simplificado de Viabilidade
            Econômica para um Sistema Solar Fotovoltaico, não é um Orçamento. Os
            preços são valores orientativos, os investimentos considerados nesse
            estudo são valores médios baseados em preços praticados no mercado,
            resultado de trabalhos estatísticos e de pesquisas realizadas.
            Alguns fatores podem implicar no preço de um Sistema Solar
            Fotovoltaico, tais como tipo de telhado, acessibilidade, logística,
            distâncias e mão de obra. Alterações de layout podem influenciar no
            preço. O preço final está condicionado à visita técnica ao local
            onde será instalado o sistema.
          </span>

          <div className="card-bottom">
            {/* <GraphicsBar categories={categories} data={data} /> */}
          </div>
        </div>
      </div>
      <Footer dataPdf={dataPdf} />
    </div>
  );
}
