import {
  CardDefault,
  React,
  Row,
  Col,
  IconFolder,
  readyToStart,
  useState,
  toast,
  IconClipBoardText,
  ChartLineUp,
  IconCaretUp,
  Separator,
  Button,
  Spinner,
  apiClient,
} from "../../helpers/importer.helper";

export default function SectionSix({ data, setData, id, setShowCompleteCard }) {
  const [loading, setLoading] = useState(false);
  const [choiceReadyToStart, setChoiceReadyToStart] = useState(0);
  const [file, setFile] = useState("Nenhum arquivo selecionado");
  const handleSubmit = async () => {
    setLoading(true);
    /* 
    let choiceReadyToStartChosen =
      choiceReadyToStart == 0
        ? "RequestBudget"
        : choiceReadyToStart == 1
        ? "RequestDetailedBudget"
        : "RequestTechnicalVisit";

    var obj = {
      property_type: typeOfEstablishment,
      avg_account_price: data.avg_account_price,
      has_contracted_demand: data.has_contracted_demand,
      energy_tariff: data.energy_tariff,
      budget_type: choiceReadyToStartChosen,
      energy_bill_file:
        choiceReadyToStartChosen == "RequestBudget"
          ? ""
          : data.energy_bill_file,
      filename: choiceReadyToStartChosen == "RequestBudget" ? "" : file,
    }; */

    var formData = new FormData();
    formData.append("lead_id", id);
    formData.append("file", data.energy_bill_file);
    try {
      await apiClient.create(`/api/solar/smart_calculation/upload`, formData);
      localStorage.removeItem("dataUser");
      toast.success("Orçamento solicitado com sucesso!", {
        theme: "colored",
      });
    } catch (err) {
      toast.error("Ocorreu um erro ao solicitar orçamento", {
        theme: "colored",
      });
    }

    setShowCompleteCard(true);

    setLoading(false);
  };

  const getFile = async obj => {
    setFile(obj.target.files[0].name);
    setData({ ...data, energy_bill_file: obj.target.files[0] });
  };
  return (
    <section id="section-6" className="mt-4 section solar-styles">
      <CardDefault checkbox={false} title="">
        <Row>
          <Col md={10} className="mx-auto">
            <div className="text-center">
              <div className="mt-5">
                <h2 className="pt-4 big-title f-w-600">Pronto para começar?</h2>
              </div>
              <p className="my-5">
                Encontre um fornecedor de energia solar em sua área para obter
                mais informações e começar a discutir a instalação. Você também
                pode aprender mais sobre o processo de energia solar e como
                escolher um fornecedor de energia solar com as opções abaixo:
              </p>
            </div>
            <div id="request-budget" className={`d-flex j-c-center flex-wrap`}>
              {readyToStart.map((item, index) => (
                <div
                  key={index}
                  onClick={() => {
                    setChoiceReadyToStart(index);
                  }}
                  className={`bordered d-flex j-c-center radius-15 border-colored-hover cursor-pointer ${
                    choiceReadyToStart == index ? "active" : null
                  }`}
                >
                  <div className="text-center">
                    <div className="m-4 icon-show-desktop">
                      <i>
                        {index == 0 ? (
                          <IconFolder
                            colorStroke="#000"
                            width={51}
                            height={52}
                          />
                        ) : index == 1 ? (
                          <IconClipBoardText width={51} height={52} />
                        ) : (
                          <ChartLineUp
                            colorStroke="#000"
                            width={51}
                            height={52}
                          />
                        )}
                      </i>
                    </div>
                    <div className="d-flex">
                      <i className="icon-show-mobile">
                        {index == 0 ? (
                          <IconFolder
                            colorStroke="#000"
                            width={21}
                            height={22}
                          />
                        ) : index == 1 ? (
                          <IconClipBoardText width={21} height={22} />
                        ) : (
                          <ChartLineUp
                            colorStroke="#000"
                            width={21}
                            height={22}
                          />
                        )}
                      </i>
                      <h3 className="font-size-22 f-w-400">
                        {index == 0
                          ? "Solicitar Orçamento Aproximado"
                          : index == 1
                          ? "Solicitar Orçamento Detalhado"
                          : "Solicitar Visita Técnica"}
                      </h3>
                      <div className="icon-show-mobile">
                        <IconCaretUp />
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <div className="my-5">
              <Separator />
            </div>
            {choiceReadyToStart != 0 ? (
              <div>
                <div className="my-5">
                  <p>
                    Caso queira solicitar um orçamento mais preciso, anexe sua
                    conta de energia no campo abaixo:
                  </p>
                </div>
                <div className="d-flex j-c-center">
                  <div id="father-upload-files" className="w-75">
                    <div className="bordered radius-15 d-flex p-3">
                      {/* onChange={(e) => {setInputFile(e?.target?.files?[0].name)}} */}
                      <input
                        accept="application/pdf, image/*"
                        hidden
                        type="file"
                        name=""
                        id="inputFile"
                        onChange={getFile}
                      />
                      <label
                        htmlFor="inputFile"
                        className="cursor-pointer border-right px-3 bordered"
                      >
                        Escolher arquivo
                      </label>
                      <p className="separator mt-1">{file}</p>
                    </div>
                    <small>*Arquivo em formato PDF ou IMAGEM</small>
                  </div>
                </div>
              </div>
            ) : null}
            <div className="d-flex justify-content-end">
              <Button
                className="solar-bg-gradient mt-4 button"
                onClick={handleSubmit}
              >
                {loading ? <Spinner /> : "Solicitar Orçamento"}
              </Button>
            </div>
          </Col>
        </Row>
      </CardDefault>
    </section>
  );
}
