import React, { useState, useEffect } from "react";
import {
  Container,
  Card,
  CardBody,
  Col,
  Row,
  FormGroup,
  Button,
} from "reactstrap";
import "./produtoPromocao.css";
import { AvForm, AvField } from "availity-reactstrap-validation";
import Breadcrumb from "../../components/Breadcrumb";
import DataTable from "react-data-table-component";
import { Link } from "react-router-dom";
import {
  tabelaDatasToSelect,
  fidelidade,
  TiposUnidadesDeMedida,
} from "./selectDatas/selectDatas";
import axios from "axios";
import moment from "moment";
import { toast } from "react-toastify";

const data = [
  {
    id: 1,
    id: "20",
    nome: "	DIA D",
    label: "Produto DIA D",
    gatilho: "0",
    inicio: "20/02/2019",
    fim: "20/02/2019",
    dias: "0 dia(s)",
    status: "",
  },
  {
    id: 2,
    id: "20",
    nome: "	DIA D",
    label: "Produto DIA D",
    gatilho: "0",
    inicio: "20/02/2019",
    fim: "20/02/2019",
    dias: "0 dia(s)",
    status: "",
  },
  {
    id: 3,
    id: "20",
    nome: "	DIA D",
    label: "Produto DIA D",
    gatilho: "0",
    inicio: "20/02/2019",
    fim: "20/02/2019",
    dias: "0 dia(s)",
    status: "",
  },
  {
    id: 4,
    id: "20",
    nome: "	DIA D",
    label: "Produto DIA D",
    gatilho: "0",
    inicio: "20/02/2019",
    fim: "20/02/2019",
    dias: "0 dia(s)",
    status: "",
  },
];

const ProdutoPromocao = () => {
  const columns = [
    {
      name: "ID",
      selector: row => row.id,
    },
    {
      name: "	Nome",
      selector: row => row.nome,
    },
    {
      name: "Label",
      selector: row => row.label,
    },
    {
      name: "Gatilho",
      selector: row => row.gatilho,
    },
    {
      name: "	Inicio",
      selector: row => row.inicio,
    },
    {
      name: "Fim",
      selector: row => row.fim,
    },
    {
      name: "Dias restantes",
      selector: row => row.dias,
    },

    {
      name: "",
      selector: row => row.status,
      cell: row => (
        <Link to={`orcamento/${row.visualizar}`}>
          <i className="ph-play"> </i>
        </Link>
      ),
    },
    {
      name: "",
      selector: row => row.status,
      cell: row => (
        <Link to={`orcamento/${row.visualizar}`}>
          <i className="ph-trash"> </i>
        </Link>
      ),
    },
  ];
  const paginationComponentOptions = {
    rowsPerPageText: "Resultados por página",
    rangeSeparatorText: "de",
    selectAllRowsItem: true,
    selectAllRowsItemText: "Todos",
  };

  const handleValidSubmit = () => {
    toast.success("Nova promoçāo cadastrada com sucesso!", {
      theme: "colored",
    });
  };

  const minPromotionDate = moment(new Date()).format("YYYY-MM-DD");
  const maxPromotionDate = moment(new Date()).format("YYYY-MM-DD");

  const [inputTable] = useState();
  const [brasilStates, setBrasilStates] = useState([]);
  const [inputQtdeGatilho] = useState();
  const [inputTextoNotificacao] = useState();
  const [inputTextoLabel] = useState();
  const [inputExpressaoBusca] = useState();
  const [inputUf] = useState();
  const [inputCorLabel] = useState();
  const [inputDataInicial] = useState();
  const [inputDataFinal] = useState();
  const [inputNomeCampanha] = useState();
  const [inputLinkLabel] = useState();
  const [inputExpressaoCategoria] = useState();
  const [inputTipo, setInputTipo] = useState("Porcentagem (%)");
  const [inputValor] = useState();

  useEffect(() => {
    const brasilStates = async () => {
      const response = await axios.get(
        "https://servicodados.ibge.gov.br/api/v1/localidades/estados/"
      );
      setBrasilStates(response.data);
    };
    brasilStates();
  }, []);

  return (
    <React.Fragment>
      <div className="page-content gerenciar-promocoes">
        <Container>
          <h1 className="titulo-pagina">Promoções</h1>
          <Breadcrumb title="Página Inicial" breadcrumbItem="Promoções" />
          <Card>
            <CardBody>
              <h4>
                <i className="ph-pencil"> </i>Cadastrar nova promoção
              </h4>
              <hr />
              <AvForm
                id="form-Product-Promotion"
                className="form-horizontal"
                onValidSubmit={(e, v) => {
                  handleValidSubmit(e, v);
                }}
              >
                <FormGroup>
                  <Row className="engloba-formulario">
                    <Col md={6}>
                      <AvField
                        id="select-table"
                        type="select"
                        label="Tabela"
                        value={inputTable}
                        name="inputTable"
                      >
                        {tabelaDatasToSelect?.map((item, index) => {
                          return (
                            <option
                              key={`tabela-datas-to-select-${index}`}
                              value={item}
                            >
                              {item}
                            </option>
                          );
                        })}
                      </AvField>
                    </Col>
                    <Col md={6}>
                      <AvField
                        id="exampleSelect"
                        name="select"
                        type="select"
                        label="Fidelidade"
                      >
                        {fidelidade?.map((item, index) => {
                          return (
                            <option
                              key={`fidelidade-to-select-${index}`}
                              value={item}
                            >
                              {item}
                            </option>
                          );
                        })}
                      </AvField>
                    </Col>
                    <Col md={6}>
                      <AvField
                        id="input-quantidade-gatilho"
                        type="number"
                        label="Quantidade para Gatilho:"
                        value={inputQtdeGatilho}
                        name="inputQtdeGatilho"
                        min="0"
                        validate={{
                          required: {
                            value: true,
                            errorMessage:
                              "O campo 'Quantidade para Gatilho' é obrigatório",
                          },
                        }}
                      />
                    </Col>
                    <Col md={6}>
                      <AvField
                        id="input-notificacao-gatilho"
                        type="text"
                        label="Texto da Notificação (Enviada quando batida a meta de Quantidade):"
                        value={inputTextoNotificacao}
                        name="inputTextoNotificacao"
                        validate={{
                          required: {
                            value: true,
                            errorMessage:
                              "O campo 'Texto da Notificação' é obrigatório",
                          },
                        }}
                      />
                    </Col>
                    <Col md={6}>
                      <AvField
                        id="input-texto-label"
                        type="text"
                        label="Texto do Label"
                        value={inputTextoLabel}
                        name="inputTextoLabel"
                        validate={{
                          required: {
                            value: true,
                            errorMessage:
                              "O campo 'Texto do Label' é obrigatório",
                          },
                        }}
                      />
                    </Col>
                    <Col md={6}>
                      <AvField
                        id="input-expressao-de-busca"
                        type="text"
                        label="Expressão de busca:"
                        value={inputExpressaoBusca}
                        name="inputExpressaoBusca"
                        validate={{
                          required: {
                            value: true,
                            errorMessage:
                              "O campo 'Expressão de busca' é obrigatório",
                          },
                        }}
                      />
                    </Col>
                    <Col md={6}>
                      <AvField
                        id="input-uf"
                        type="select"
                        label="Cidade:"
                        value={inputUf}
                        name="inputUf"
                        validate={{
                          required: {
                            value: true,
                            errorMessage: "O campo 'Cidade' é obrigatório",
                          },
                        }}
                      >
                        {brasilStates &&
                          brasilStates?.map((item, index) => {
                            return (
                              <option
                                key={`brazil-state-${index}`}
                                value={index}
                              >
                                {item?.nome}
                              </option>
                            );
                          })}
                      </AvField>
                    </Col>
                    <Col md={6}>
                      <AvField
                        id="input-cor-label"
                        type="color"
                        label="Cor do label"
                        value={inputCorLabel}
                        name="inputCorLabel"
                        validate={{
                          required: {
                            value: true,
                            errorMessage:
                              "O campo 'Cor do label' é obrigatório",
                          },
                        }}
                      />
                    </Col>
                    <Col md={6}>
                      <AvField
                        id="input-inicio"
                        type="date"
                        label="Data Inicial:"
                        value={inputDataInicial}
                        name="inputData Inicial"
                        min={minPromotionDate}
                        validate={{
                          required: {
                            value: true,
                            errorMessage:
                              "O campo 'Data Inicial' é obrigatório",
                          },
                        }}
                      />
                    </Col>
                    <Col md={6}>
                      <AvField
                        id="input-final"
                        type="date"
                        label="Data Final:"
                        value={inputDataFinal}
                        name="inputDataFinal"
                        min={maxPromotionDate}
                        validate={{
                          required: {
                            value: true,
                            errorMessage: "O campo 'Data final' é obrigatório",
                          },
                        }}
                      />
                    </Col>
                    <Col md={6}>
                      <AvField
                        id="input-nome-campanha"
                        type="text"
                        label="Nome da campanha"
                        value={inputNomeCampanha}
                        name="inputNomeCampanha"
                        validate={{
                          required: {
                            value: true,
                            errorMessage:
                              "O campo 'Nome da campanha' é obrigatório",
                          },
                        }}
                      />
                    </Col>
                    <Col md={6}>
                      <AvField
                        id="input-link-label"
                        type="text"
                        label="Link do label"
                        value={inputLinkLabel}
                        name="inputLinkLabel"
                        validate={{
                          required: {
                            value: true,
                            errorMessage:
                              "O campo 'Link da label' é obrigatório",
                          },
                        }}
                      />
                    </Col>
                    <Col md={6}>
                      <AvField
                        id="input-link-label"
                        type="text"
                        label="Nome da Campanha:"
                        value={inputNomeCampanha}
                        name="inputNo"
                        validate={{
                          required: {
                            value: true,
                            errorMessage:
                              "O campo 'Nome da Campanha:' é obrigatório",
                          },
                        }}
                      />
                    </Col>
                    <Col md={6}>
                      <AvField
                        id="input-link-label"
                        type="text"
                        label="Expressão da
                                        categoria:"
                        value={inputExpressaoCategoria}
                        name="inputExpressaoCategoria"
                        validate={{
                          required: {
                            value: true,
                            errorMessage:
                              "O campo 'Expressão da categoria' é obrigatório",
                          },
                        }}
                      />
                    </Col>
                    <Col md={6}>
                      <AvField
                        id="input-tipos-unidade"
                        type="select"
                        label="Tipo:"
                        value={inputTipo}
                        name="inputTipo"
                        onChange={e => setInputTipo(e.target.value)}
                        validate={{
                          required: {
                            value: true,
                            errorMessage: "O campo 'Tipo' é obrigatório",
                          },
                        }}
                      >
                        {TiposUnidadesDeMedida &&
                          TiposUnidadesDeMedida?.map((item, index) => {
                            return (
                              <option
                                key={`tipos-unidades-${index}`}
                                value={item}
                              >
                                {item}
                              </option>
                            );
                          })}
                      </AvField>
                    </Col>
                    <Col>
                      <AvField
                        id="input-valor"
                        type="text"
                        label={`Valor em ${inputTipo}`}
                        value={inputValor}
                        name="inputValor"
                        validate={{
                          required: {
                            value: true,
                            errorMessage: "O campo 'Valor' é obrigatório",
                          },
                        }}
                      />
                    </Col>
                    <Col md={12} className="d-flex justify-content-end">
                      <Button className="botao-cadastro">
                        Cadastrar nova promoção
                      </Button>
                    </Col>
                  </Row>
                </FormGroup>
              </AvForm>
            </CardBody>
          </Card>

          <Card>
            <CardBody>
              <h4>
                <i className="ph-list-bullets"></i>Promoções Cadastradas
              </h4>
              <hr />
              <DataTable
                columns={columns}
                data={data}
                pagination
                paginationComponentOptions={paginationComponentOptions}
                subHeader
              />
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ProdutoPromocao;
