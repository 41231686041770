import {
  labels,
  Check,
  CurrencyDollar,
  HouseLine,
  Sun,
  Separator,
  Card,
  CardBody,
  PlacesAutoComplete,
  React,
} from "./helpers/importer.helper";

export default function CardAnalysisCompleted(props) {
  const components = [
    { id: 1, icon: <Sun /> },
    { id: 2, icon: <HouseLine /> },
    { id: 3, icon: <CurrencyDollar /> },
  ];

  return (
    <div id="AnalysisCompleted" className="solar-styles">
      <div className="mb-3 places-auto-complete-desktop">
        <PlacesAutoComplete
          setAddress={props.setAddress}
          setMapCenter={props.setMapCenter}
          setZoom={props.setZoom}
          address={props.address}
          setAttPlacesAutoComplete={props.setAttPlacesAutoComplete}
          attPlacesAutoComplete={props.attPlacesAutoComplete}
        />
      </div>
      <Card className="solar-shadow bordered">
        <CardBody>
          <div className="d-flex">
            <i>
              <Check />
            </i>
            <div className="d-flex j-c-center">
              <p className="description mx-3">
                Análise concluida. Com base no endereço, seu telhado tem:
              </p>
            </div>
          </div>
          {(props.icons || components).map((item, index) => (
            <div key={item.id}>
              {index == 2 ? <Separator color="#f5f5f5" /> : null}
              <div className="d-flex mt-4" key={`icon_${index}`}>
                <div className="icon-father h-100 d-flex j-c-center">
                  {item.icon}
                </div>
                <div className="mx-3">
                  <div className="titles">
                    <h2 className={`f-w-700 mb-2 h2-${index}`}>
                      {index == 2 ? labels[index][0] : null}
                      {index == 0
                        ? props.hoursSunLight
                        : index == 1
                        ? props.areaInMetters
                        : props.moneySaved}
                      {index != 2 ? labels[index][0] : null}
                    </h2>
                  </div>
                  <p className="f-w-400">{labels[index][1]}</p>
                </div>
              </div>
            </div>
          ))}
        </CardBody>
      </Card>
    </div>
  );
}
