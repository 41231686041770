import React from "react";
import { CardBody, Col, Row, Card, Input, Fade } from "reactstrap";
import { formato } from "../../../utils";

const AddedItem = ({ item }) => {
  return (
    <Card className="mb-0 mt-4">
      <CardBody>
        <Row style={{ alignItems: "center" }}>
          <Col md={6}>
            <p
              data-toggle="tooltip"
              data-placement="top"
              title={item.nome}
              className="mb-0"
              style={{
                fontWeight: 500,
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
            >
              {item?.nome}
            </p>
          </Col>
          <Col md={2}>
            {item.precoCliente
              ? item.precoCliente.toLocaleString("pt-br", formato)
              : "-"}
          </Col>
          <Col md={2}>{item?.qtd ? Math.round(item.qtd) : 1}</Col>
          <Col md={2}>{item?.subTotal.toLocaleString("pt-br", formato)}</Col>
        </Row>
      </CardBody>
    </Card>
  );
};

export default AddedItem;
