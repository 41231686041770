import PedidoEmitido from "../kit-pronto/PedidoEmitido";
import {
  Container,
  useState,
  Map,
  useParams,
  useEffect,
  React,
  PlacesAutoComplete,
  SectionOne,
  SectionTwo,
  SectionThree,
  SectionFour,
  SectionFive,
  SectionSix,
  Spinner,
  toast,
  apiClient,
  FilePdf,
} from "./helpers/importer.helper";

export default function AnalysisCompleted() {
  const [userData, setUserData] = useState({});
  const [accountPriceType, setAccountPriceType] = useState("real");
  const [typeOfEstablishment, setTypeOfEstablishment] = useState("Residencial");
  const [address, setAddress] = useState("");
  const [zoom, setZoom] = useState(40);
  const [attPlacesAutoComplete, setAttPlacesAutoComplete] = useState(false);
  const params = useParams();
  const dataStorage = localStorage.getItem("dataUser");
  const dataSotarageUser = JSON.parse(dataStorage);
  const [loadingUserData, setLoadingUserData] = useState(false);
  const [attGetUpdate, setAttGetUpdate] = useState(false);
  const [showCompleteCard, setShowCompleteCard] = useState(false);
  const [economiaLiquidaEstimada, setEconomiaLiquidaEstimada] = useState("");
  const [data, setData] = useState({
    avg_account_price: "0,00",
    has_contracted_demand: false,
    energy_tariff: "",
    budget_type: "",
    energy_bill_file: "",
  });
  const [mapCenter, setMapCenter] = useState({
    lat: -23.6807243,
    lng: -47.1696611,
  });

  /*  useEffect(() => {
    if (!dataSotarageUser?.data?.attributes) {
      history.push("/");
    }
  }, []); */

  const getInfoUser = async () => {
    setLoadingUserData(true);
    try {
      const resp = await apiClient.get(`/api/solar/lead/${params.id}`, {
        type: "smart",
      });
      const respData = resp;

      setAddress(respData?.lead?.address);
      setMapCenter({
        lat: respData?.lead?.latitude,
        lng: respData?.lead?.longitude,
      });
      setUserData(respData);
      setData({
        ...data,
        avg_account_price: respData.calculations?.avg_account_price,
        energy_tariff: `${respData.calculations["energy tariff"]}`,
      });
    } catch (err) {
      toast.error("Ocorreu um erro ao buscar os dados", { theme: "colored" });
    }
    setLoadingUserData(false);
  };

  useEffect(() => {
    if (params.id) {
      getInfoUser();
    }
  }, [params.id, attGetUpdate]);

  const updateInfoUser = async (update, tariff) => {
    let newObj = {};
    if (update == "attAddress") {
      newObj = {
        latitude: mapCenter.lat,
        longitude: mapCenter.lng,
        address: address,
      };
    } else if (update == "attMoney") {
      newObj = {
        avg_account_price: data.avg_account_price,
        avg_account_price_type: accountPriceType,
      };
    } else {
      newObj = {
        energy_tariff: tariff,
      };
    }

    await apiClient.update(
      `/api/solar/smart_calculation/${params.id}/recalculate`,
      newObj
    );

    setAttGetUpdate(prev => !prev);
  };

  useEffect(() => {
    if (attPlacesAutoComplete) {
      updateInfoUser("attAddress");
    }
  }, [attPlacesAutoComplete]);

  return (
    <>
      <div
        id="analisys-completed"
        className="solar-styles"
        style={{ overflow: "hidden" }}
      >
        {" "}
        {loadingUserData ? (
          <div className="solar-rain-loader">
            <Spinner />
          </div>
        ) : (
          <div>
            {!showCompleteCard ? (
              <div className="w-100 position-relative">
                <Container className="d-flex places-auto-complete-desktop">
                  {typeof userData.file == "string" && (
                    <a
                      rel="noreferrer"
                      href={userData.file}
                      target="_blank"
                      style={{
                        position: "fixed",
                        bottom: 20,
                        right: 20,
                        background: "#FF601A",
                        padding: "12px 12px",
                        borderRadius: "50%",
                      }}
                    >
                      <FilePdf size={30} color="#fff" weight="thin" />
                    </a>
                  )}
                  <div className="px-3 py-5 w-100">
                    <h2 className="main-title f-w-700">Insira seu endereço:</h2>
                    <div className="mt-4">
                      <PlacesAutoComplete
                        setMapCenter={setMapCenter}
                        setAddress={setAddress}
                        address={address}
                        setZoom={setZoom}
                        setAttPlacesAutoComplete={setAttPlacesAutoComplete}
                        attPlacesAutoComplete={attPlacesAutoComplete}
                      />
                    </div>
                  </div>
                </Container>
                <div className="map-father position-absolute w-100">
                  <Map
                    setMapCenter={setMapCenter}
                    setAddress={setAddress}
                    address={address}
                    setZoom={setZoom}
                    mapCenter={mapCenter}
                    zoom={zoom}
                  />
                </div>
                <Container>
                  <SectionOne
                    userData={userData}
                    setMapCenter={setMapCenter}
                    setAddress={setAddress}
                    address={address}
                    setZoom={setZoom}
                    setAttPlacesAutoComplete={setAttPlacesAutoComplete}
                    attPlacesAutoComplete={attPlacesAutoComplete}
                    economiaLiquidaEstimada={economiaLiquidaEstimada}
                  />

                  <SectionTwo
                    dataSotarageUser={dataSotarageUser}
                    setData={setData}
                    data={data}
                    typeOfEstablishment={typeOfEstablishment}
                    setTypeOfEstablishment={setTypeOfEstablishment}
                    updateInfoUser={updateInfoUser}
                    accountPriceType={accountPriceType}
                    setAccountPriceType={setAccountPriceType}
                  />

                  <SectionThree
                    data={data}
                    setData={setData}
                    mapCenter={mapCenter}
                    address={address}
                    userData={userData}
                    setUserData={setUserData}
                    typeOfEstablishment={typeOfEstablishment}
                    updateInfoUser={updateInfoUser}
                    id={params?.id}
                  />

                  <SectionFour userData={userData} />

                  <SectionFive
                    userData={userData}
                    data={data}
                    setEconomiaLiquidaEstimada={setEconomiaLiquidaEstimada}
                  />

                  {typeof userData.file != "string" && (
                    <SectionSix
                      data={data}
                      setData={setData}
                      typeOfEstablishment={typeOfEstablishment}
                      id={params.id}
                      setShowCompleteCard={setShowCompleteCard}
                    />
                  )}
                </Container>
              </div>
            ) : (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  minHeight: "100vh",
                }}
              >
                <PedidoEmitido type={"budget"} hiddenVisualizar={true} />
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
}
