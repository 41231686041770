import React, { useState, useEffect } from "react";
import {
  CardBody,
  Card,
  FormGroup,
  Label,
  Input,
  Button,
  Table,
  Spinner,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import { formato } from "../../utils";
import { emitirPedido, editarPedido } from "../../store/actions";
import { useDispatch, useSelector } from "react-redux";
import { validaCpfCnpj } from "../../utils";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

const Resumo = ({
  valTot,
  frete,
  saldo,
  pedido,
  changePedidoEmitido,
  valRt,
  changeType,
}) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { emissaoPedido, order } = useSelector(state => ({
    order: state.Order.orderActive.order,
    emissaoPedido: state.Order.newOrder.newOrder.emissaoPedido,
  }));
  const [aceiteTerm, setAceiteTerm] = useState(false);
  const [fetch, setFetch] = useState(false);
  const [modalTerm, setModalTerm] = useState(false);

  const validarErros = () => {
    let erros = [];
    if (!validaCpfCnpj(pedido.client.cpfcnpj))
      erros.push("CPF ou CNPJ inválido. Revise e tente novamente!");
    if (pedido.items.length < 1)
      erros.push("Não é possível emitir pedido com carrinho vazio.");
    if (!(pedido.filters.condpagto && pedido.filters.tabela_preco))
      erros.push("Tabela e/ou condição de pagamento não selecionados.");
    if (!pedido.shipping.id) erros.push("Frete não selecionado.");
    if (!pedido.client.email) erros.push("Email não preenchido.");
    if (!pedido.client.customer_phones[1].phone)
      erros.push("Celular não preenchido.");
    if (!(pedido.client.fullname && pedido.client.name_reduced))
      erros.push("Nome e nome reduzido não preenchido.");
    if (pedido.client.pessoa == "F" && !pedido.client.dt_nasc)
      erros.push("Data de Nascimento não preenchida.");
    if (
      !(
        pedido.client.customer_address[0].zipcode &&
        pedido.client.customer_address[0].address &&
        pedido.client.customer_address[0].number
      )
    )
      erros.push("Endereço de cobrança não preenchido.");
    if (
      !(
        pedido.client.customer_address[1].zipcode &&
        pedido.client.customer_address[1].address &&
        pedido.client.customer_address[1].number
      )
    )
      erros.push("Endereço de entrega não preenchido.");
    return erros;
  };

  const emitirNovoPedido = type => {
    if (aceiteTerm) {
      let erros = validarErros();

      if (erros.length > 0) {
        erros.map(value => {
          toast.warning(value, { theme: "colored" });
        });
        return false;
      }

      if (id) {
        dispatch(
          editarPedido({
            id: order.NumPedido,
            pedido,
          })
        );
      } else {
        if (type == "order") {
          changeType("order");
          dispatch(emitirPedido(pedido));
        }
        if (type == "budget") {
          changeType("budget");
          dispatch(
            emitirPedido({
              ...pedido,
              type_order: "budget",
              budget_infos: {
                codigo_cliente: pedido.client.id,
                tipo_cliente: 0,
              },
            })
          );
        }
      }
    } else {
      toast.warning(
        `É preciso aceitar os termos de fornecimento para emitir pedidos.`,
        { theme: "colored" }
      );
    }
  };

  useEffect(() => {
    if (!emissaoPedido.loading && emissaoPedido.data && fetch) {
      if (emissaoPedido.data?.success) {
        changePedidoEmitido();
      } else {
        toast.error(emissaoPedido.data.msg, { theme: "colored" });
      }
      setFetch(false);
    }
  }, [emissaoPedido, fetch]);

  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <h4
            style={{
              fontSize: 23,
              fontWeight: "bold",
              color: "#202833",
              marginBottom: 25,
            }}
          >
            Resumo
          </h4>
          <Table className="mt-0 tabela-resumo mb-0" borderless>
            <tbody>
              <tr>
                <td style={{ padding: "0 0 13px 0" }} colSpan={2}>
                  Valor dos Produtos
                </td>
                <td style={{ padding: "0 0 13px 0", textAlign: "end" }}>
                  {Number(valTot).toLocaleString("pt-br", formato)}
                </td>
              </tr>
              <tr>
                <td style={{ padding: "0 0 13px 0" }} colSpan={2}>
                  Frete
                </td>
                <td style={{ padding: "0 0 13px 0", textAlign: "end" }}>
                  {Number(frete?.price || 0).toLocaleString("pt-br", formato)}
                </td>
              </tr>
              <tr>
                <td style={{ padding: "0 0 13px 0" }} colSpan={2}>
                  Desconto Aplicado
                </td>
                <td style={{ padding: "0 0 13px 0", textAlign: "end" }}>
                  R$ 0,00
                </td>
              </tr>
              <tr style={{ borderBottom: "1px solid #e7e7e7" }}>
                <td style={{ padding: "0 0 17px 0" }} colSpan={2}>
                  Saldo em conta utilizado
                </td>
                <td style={{ padding: "0 0 17px 0", textAlign: "end" }}>
                  {(saldo || 0).toLocaleString("pt-br", formato)}
                </td>
              </tr>
              <tr style={{ borderBottom: "1px solid #e7e7e7" }}>
                <td
                  style={{
                    display: "flex",
                    alignItems: "center",
                    padding: "17px 0 17px 0",
                  }}
                >
                  Cashabck BTUPay (RT)
                  <i
                    className="ph-coin"
                    style={{ fontSize: 15, marginLeft: 10 }}
                  ></i>
                </td>
                <td style={{ padding: "17px 0 17px 0", textAlign: "end" }}>
                  {((valRt / valTot || 0) * 100).toFixed(2)} %
                </td>
                <td style={{ padding: "17px 0 17px 0", textAlign: "end" }}>
                  {(valRt || 0).toLocaleString("pt-br", formato)}
                </td>
              </tr>
              <tr>
                <td style={{ padding: "20px 0 35px 0" }} colSpan={2}>
                  <h4
                    className="mb-0"
                    style={{
                      fontSize: 23,
                      fontWeight: "bold",
                      color: "#202833",
                    }}
                  >
                    Valor Total
                  </h4>
                </td>
                <td style={{ padding: "20px 0 35px 0" }}>
                  <h4
                    className="mb-0"
                    style={{
                      fontSize: 23,
                      fontWeight: "bold",
                      color: "#202833",
                      marginBottom: 25,
                      textAlign: "end",
                    }}
                  >
                    {Number(
                      Number(valTot) + Number(frete?.price || 0) - Number(saldo)
                    ).toLocaleString("pt-br", formato)}
                  </h4>
                </td>
              </tr>
            </tbody>
          </Table>
          <FormGroup check className="d-flex">
            <Input
              type="checkbox"
              value="A"
              className="modalidade aceite"
              onClick={e => setAceiteTerm(e.target.checked)}
            />
            <Label
              className={aceiteTerm ? "label-prazo-active" : ""}
              style={{ marginLeft: 20, fontWeight: 500 }}
            >
              Li e concordo com os{" "}
              <a
                href="#"
                onClick={e => {
                  e.preventDefault();
                  setModalTerm(!modalTerm);
                }}
              >
                Termos e condições <br />
                de emissão de pedidos e orçamentos
              </a>
            </Label>
          </FormGroup>
          {emissaoPedido.loading && (
            <div className="carregando-emissao">
              <Spinner />
            </div>
          )}

          <Button
            style={{
              backgroundColor: "#13b013",
              color: "#FFFFFF",
              width: "100%",
              marginTop: 35,
              display: "block",
              fontSize: 17,
              borderColor: "#13b013",
              padding: 20,
              borderRadius: 6,
            }}
            onClick={() => {
              emitirNovoPedido("order");
              setFetch(true);
            }}
          >
            {id ? "EDITAR PEDIDO" : "EMITIR PEDIDO"}
          </Button>

          <Button
            style={{
              backgroundColor: "#DCF3FE",
              color: "#13b013",
              width: "100%",
              marginTop: 30,
              display: "block",
              fontSize: 17,
              borderColor: "#DCF3FE",
              padding: 20,
              borderRadius: 6,
            }}
            onClick={() => {
              emitirNovoPedido("budget");
              setFetch(true);
            }}
          >
            EMITIR ORÇAMENTO
          </Button>
        </CardBody>
      </Card>
      <Modal
        isOpen={modalTerm}
        size="lg"
        toggle={() => setModalTerm(!modalTerm)}
      >
        <ModalHeader toggle={() => setModalTerm(!modalTerm)}>
          Termos e condições de emissão de pedidos e orçamentos
        </ModalHeader>
        <ModalBody>
          <ul>
            <li>
              Equipamentos faturados por MPS Distribuidora Mercantil LTDA CNPJ
              09.570.732/0004-34, Rua Carlota de Almeida Lemos, 46 Jd. Paradiso
              - Campo Grande/ MS - CEP. 79117-004
            </li>
            <li>
              A condi&#231;&#227;o de pagamento &#224; prazo depende da
              an&#225;lise de cr&#233;dito, podendo ser solicitado uma nova
              condi&#231;&#227;o de pagamento para libera&#231;&#227;o do
              pedido.{" "}
            </li>
            <li>
              Caso o comprador n&#227;o efetue o pagamento no prazo m&#225;ximo
              de 03 dias corridos (para a vista ou antecipado), o pedido
              ser&#225; cancelado automaticamente no sistema.{" "}
            </li>
            <li>
              Para vendas a prazo, os vencimentos das parcelas ser&#227;o
              contados a partir da data de emiss&#227;o da nota fiscal.{" "}
            </li>
            <li>
              N&#227;o efetuar pagamentos dos produtos para terceiros. Todos
              pagamentos dever&#227;o ser efetuados para MPS Distribuidora
              Mercantil Ltda
            </li>
            <li>
              Prazo de entrega &#233; v&#225;lido a partir da data de
              faturamento da nota fiscal.{" "}
            </li>
            <li>
              Aten&#231;&#227;o: no caso de produtos trif&#225;sicos, certificar
              se a voltagem est&#225; de acordo com a rede el&#233;trica local,
              em caso de erro de rede/tens&#227;o,&nbsp;a resposabilidade &#233;
              do comprador.{" "}
            </li>
            <li>
              A MPS Distribuidora n&#227;o &#233; respons&#225;vel pela
              instala&#231;&#227;o dos equipamentos e sim a empresa ou
              t&#233;cnico autonomo contratado pelo comprador.{" "}
            </li>
            <li>
              Aten&#231;&#227;o: A confer&#234;ncia do estado da mercadoria
              &#233;&nbsp; de responsabilidade do comprador. Favor conferir no
              ato do recebimento.{" "}
            </li>
            <li>
              O valor do frete n&#227;o est&#225; incluso no pre&#231;o dos
              produtos. O frete ser&#225; adicionado ao total do pedido.{" "}
            </li>
            <li>
              Nota fiscal para empresa com I.E o ICMS &#233; de 12% (produto
              nacional), e 4% (produto importado), diferen&#231;as de
              al&#237;quotas &#233; de responsabilidade do comprador, inclusive
              em caso de Substitui&#231;&#227;o Tribut&#225;ria. Nota fiscal
              para consumidor final (Pessoa f&#237;sica ou Pessoa Jur&#237;dica
              com I.E Isento) o ICMS &#233; de 12%, cujo difal &#233; de
              responsabilidade do vendedor e desde j&#225; o comprador autoriza
              o vendedor a solicitar eventuais restitui&#231;&#245;es e
              ressarcimentos de impostos, nos termos do artigo 166 do
              C&#243;digo Tribut&#225;rio Nacional – Lei n&#186; 5.172/1966,
              inclusive em decorr&#234;ncia de decis&#227;o judicial que
              reconhe&#231;a que o vendedor esteja desobrigado a recolher o
              diferencial de al&#237;quotas.
            </li>
            <li>
              No caso de devolu&#231;&#227;o por desist&#234;ncia imotivada do
              comprador / re-entrega / troca de mercadoria, o custo do frete
              ser&#225; de responsabilidade do mesmo, o qual poder&#225; ser
              compensado na devolu&#231;&#227;o dos valores pagos.{" "}
            </li>
            <li>
              No caso de troca ou devolu&#231;&#227;o, somente ser&#225;
              entregue o novo produto ou a devolu&#231;&#227;o do valor pago,
              ap&#243;s o retorno do produto devolvido em nosso centro de
              distribui&#231;&#227;o em mato grosso do sul.{" "}
            </li>
            <li>
              Embalagens avariadas est&#227;o sujeitas a cobran&#231;a de
              deprecia&#231;&#227;o, a qual poder&#225; ser compensada na
              devolu&#231;&#227;o dos valores pagos.{" "}
            </li>
            <li>
              N&#227;o trocamos produto que j&#225; tenham sido instalados.{" "}
            </li>
            <li>
              Para sua seguran&#231;a, todos os pedidos est&#227;o sujeitos
              &#224; confirma&#231;&#227;o dos dados cadastrais e de entrega.
              esta confirma&#231;&#227;o, quando necess&#225;ria, ser&#225;
              feita atrav&#233;s de contato telef&#244;nico, e visa
              exclusivamente garantir a indentidade do comprador e evitar
              qualquer dano ou preju&#237;zo aos nossos clientes.{" "}
            </li>
            <li>
              Desde j&#225; o comprador autoriza a vendedora a proceder com o
              apontamento em cadastro de devedores (Serasa, SPC e afins) no caso
              de inadimplencia (parcial ou total) dos valores correspondentes
              &#224; venda objeto deste instrumento, sem prejuizo do respectivo
              protesto dos titulos emitidos em razao da mesma.{" "}
            </li>
            <li>
              N&#227;o nos responsabilizamos por transporte em escadas,
              elevadores ou por guincho. eventuais custos adicionais s&#227;o de
              responsabilidade do cliente. Sugerimos que confira as
              dimens&#245;es do produto e certifique-se de passar&#225;
              normalmente por elevadores, portas e corredores do local de
              entrega.{" "}
            </li>
          </ul>
          <p>Se o seu equipamento é do tipo VRF:</p>
          <ul>
            <li>
              O Start up trata-se dos procedimentos de partida inicial feitos
              pelo fabricante ou empresa autorizada, onde é verificado o padrão
              da instalação, as condições operacionais e performance dos
              equipamentos. As unidades VRF deverão obrigatoriamente ter sua
              partida inicial (Star-up) executada pelo fabricante.
            </li>
            <li>
              A solicitação de Start-up deve ser feita com pelo menos 15 dias de
              antecedência com envio do checklist totalmente preenchido para o
              seu consultor.
            </li>
            <li>
              Os serviços de Start-up (checklist, verificação da instalação,
              execução da partida dos equipamentos, etc.) estão inclusos nos
              preços da presente proposta.
            </li>
            <li>
              O Start-up feito pelo fabricante ou empresa autorizada é válido
              para uma visita em horário comercial. Caso não seja possível
              realizar em horário comercial seja, falta de energia, por
              incompatibilidades na instalação ou problemas na obra que sejam de
              responsabilidade da empresa instaladora ou cliente, será enviado
              orçamento para retorno ao local e todos os custos envolvidos
              (visita técnica, estadia, etc) serão por conta da empresa
              instaladora ou cliente. A nova data para o Start-up neste caso
              precisa ser reagendada com no mínimo 15 dias de antecedência.
            </li>
            <li>
              A garantia será de acordo com as regras e política de cada
              Fabricante e está condicionada à instalação, que deverá ser feita
              por empresa credenciada pelo fabricante e o cliente deve manter
              Contrato de Manutenção Preventiva também com empresa Credenciada.
              A não instalação e falta de manutenção com empresa Credenciada
              acarreta na perda da garantia.
            </li>
            <li>
              A garantia dos equipamentos consiste unicamente em reparar ou
              substituir peças e componentes com defeitos de fabricação, não
              estando inclusa a mão de obra para a troca e/ou reparo dos
              equipamentos, fluído frigorífico, materiais de desgaste normal ou
              quaisquer outras despesas decorrentes. O prazo final de garantia
              não é modificado, mesmo em caso de substituição ou reparo de
              componentes.
            </li>
          </ul>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default Resumo;
