import React, { useState } from "react";
import { Container, Card, CardBody, Alert } from "reactstrap";
import "./cadastroPendente.css";

const CartaoCadastroPendente = () => {
  const [isHidden, setIsHidden] = useState(false);
  const [temConsulta, setTemConsulta] = useState(true);
  return (
    <React.Fragment>
      <div className="page-content cadastro-pendente">
        <Container>
          {temConsulta && (
            <Card>
              <CardBody>
                <div className="engloba-titulo-icones">
                  <h2 className="titulo-cadastro-pendente">
                    <i className="ph-user-plus user-plus"></i>Cadastros
                    pendentes de solicitação de cartão
                  </h2>
                  <div className="engloba-icones">
                    <a
                      href="#"
                      onClick={e => {
                        e.preventDefault();
                        setIsHidden(!isHidden);
                      }}
                    >
                      {isHidden ? (
                        <i className="ph-caret-down"></i>
                      ) : (
                        <i className="ph-caret-up"></i>
                      )}
                    </a>

                    <a href="#">
                      <i className="ph-wrench"> </i>
                    </a>
                    <a href="#">
                      <i className="ph-arrows-clockwise"> </i>
                    </a>

                    <a
                      href="#"
                      onClick={e => {
                        e.preventDefault();
                        setTemConsulta(!temConsulta);
                      }}
                    >
                      <i className="ph-x"></i>
                    </a>
                  </div>
                </div>
                <hr />
                <div hidden={isHidden}>
                  <Alert>Nenhum item encontrado.</Alert>
                </div>
              </CardBody>
            </Card>
          )}
        </Container>
      </div>
    </React.Fragment>
  );
};

export default CartaoCadastroPendente;
