const arrayDatasToContact = [
  {
    name: "Luciana Mazurega",
    tel: "(11) 3649-4221",
  },
  {
    name: "Rafael Dias",
    tel: "(11) 3649-4083",
  },
];
const arrayDatasToContactSolar = [
  {
    name: "Eduardo klepacz",
    tel: "(11) 9 7425-8663",
  },
  {
    name: "Rafael Dias",
    tel: "(11) 3649-4083",
  },
  {
    name: "Bruno Dias",
    tel: "(11) 3649-4085",
  },
];

export { arrayDatasToContact, arrayDatasToContactSolar };
