import { EventsActionTypes } from "./actionTypes";

const INIT_STATE = {
  eventDetails: {},
};

const Events = (state = INIT_STATE, action) => {
  switch (action.type) {
    case EventsActionTypes.API_RESPONSE_SUCCESS:
      switch (action.payload.actionType) {
        case EventsActionTypes.GET_EVENTS_DETAILS:
          return {
            ...state,
            eventDetails: action.payload.data,
            getEventDetailsLoading: false,
            isEventDetailsFetched: false,
          };

        case EventsActionTypes.UPDATE_EVENTS:
          return {
            ...state,
            isEventUpdated: true,
          };

        case EventsActionTypes.DELETE_EVENT:
          return {
            ...state,
            isEventDeleted: true,
          };

        default:
          return { ...state };
      }
    case EventsActionTypes.API_RESPONSE_ERROR:
      switch (action.payload.actionType) {
        case EventsActionTypes.GET_EVENTS_DETAILS:
          return {
            ...state,
            eventDetailsError: action.payload.error,
            getEventDetailsLoading: false,
            isEventDetailsFetched: true,
          };

        case EventsActionTypes.UPDATE_EVENTS:
          return {
            ...state,
            error: action.payload.error,
            isEventUpdated: false,
          };

        case EventsActionTypes.DELETE_EVENT:
          return {
            ...state,
            error: action.payload.error,
            isEventDeleted: false,
          };

        default:
          return { ...state };
      }

    case EventsActionTypes.GET_EVENTS_DETAILS:
      return {
        ...state,
        getEventDetailsLoading: true,
        isEventDetailsFetched: false,
      };

    case EventsActionTypes.UPDATE_EVENTS:
      return {
        ...state,
        isEventUpdated: false,
      };

    case EventsActionTypes.DELETE_EVENT:
      return {
        ...state,
        isEventDeleted: false,
      };

    default:
      return state;
  }
};

export default Events;
