import { OrderListActionTypes } from "./actionTypes";

const INIT_STATE = {
  ordersList: [],
  success: false,
  loading: false,
  filters: {
    page: 1,
    qtd_per_page: 300000,
    filters: {
      vend_codigo: "",
      nome_cliente: "",
      dateI: "",
      dateF: "",
      fila: "",
      cli_cpfcnpj: "",
      num_pedido: "",
      nota_fisca: "",
      empresa: "",
    },
  },
};

const OrderList = (state = INIT_STATE, action) => {
  switch (action.type) {
    case OrderListActionTypes.API_RESPONSE_SUCCESS:
      switch (action.payload.actionType) {
        case OrderListActionTypes.GET_ORDER_LIST:
          return {
            ...state,
            success: action.payload.data.success,
            ordersList: action.payload.data.ordersList,
            total_pages: action.payload.data.pages,
            loading: false,
          };

        default:
          return state;
      }

    case OrderListActionTypes.API_RESPONSE_ERROR:
      switch (action.payload.actionType) {
        default:
          return state;
      }

    case OrderListActionTypes.GET_ORDER_LIST:
      return {
        ...state,
        filters: action.payload,
        loading: true,
        success: false,
      };

    default:
      return state;
  }
};

export default OrderList;
