import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getProductDetails, addToCart } from "../../store/actions";
import { formato } from "../../utils";
import {
  Col,
  Row,
  FormGroup,
  Label,
  Input,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
  Table,
} from "reactstrap";

import A from "../../assets/images/bandeira/bandeira-a.png";
import B from "../../assets/images/bandeira/bandeira-b.png";
import C from "../../assets/images/bandeira/bandeira-c.png";
import D from "../../assets/images/bandeira/bandeira-d.png";
import E from "../../assets/images/bandeira/bandeira-e.png";
import F from "../../assets/images/bandeira/bandeira-f.png";
import { toast } from "react-toastify";
import { filiais } from "../../utils/filiais";

const FindedItem = ({ produto }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [filialAtiva, setFilialAtiva] = useState(0);

  const dispatch = useDispatch();
  const [precoVenda, setPrecoVenda] = useState(
    Number(produto.PRCVENDA.STRETPRCFILADIAS[filialAtiva].PRCVENDA)
  );
  const { findedItems, addedItems, vendedor } = useSelector(state => ({
    findedItems: state.Order.newOrder.newOrder.findedItems,
    addedItems: state.Order.newOrder.newOrder.addedItems,
    vendedor: state.Order.newOrder.newOrder.vendedor,
  }));
  const item = findedItems[produto.CODPRODUTO]
    ? findedItems[produto.CODPRODUTO]
    : {};
  const descMax = Number(vendedor.VENDEDOR.PERCDESCONTO) / 100;
  const valTabela = Number(
    produto.PRCVENDA.STRETPRCFILADIAS[filialAtiva].PRCVENDA
  );
  const valMin = valTabela - valTabela * descMax;
  const valMax =
    Number(vendedor.VENDEDOR.TEMRT) == 1
      ? Number(produto.PRCVENDA.STRETPRCFILADIAS[filialAtiva].PRCVENDA)
      : valTabela * 1.5;
  const [varPre, setVarPre] = useState(
    Number(produto.PRCVENDA.STRETPRCFILADIAS[filialAtiva].PRCVENDA)
  );

  const bandeiras = {
    A: A,
    B: B,
    C: C,
    D: D,
    E: E,
    F: F,
  };

  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);
  let tamanhos = [];
  Object.keys(item.tamanhos || {}).map(function (key) {
    tamanhos.push([key, item.tamanhos[key]]);
  });

  const next = () => {
    if (animating) return;
    const nextIndex =
      activeIndex === item.fotos.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };

  const previous = () => {
    if (animating) return;
    const nextIndex =
      activeIndex === 0 ? item.fotos.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };

  const goToIndex = newIndex => {
    if (animating) return;
    setActiveIndex(newIndex);
  };

  const slides = (item.fotos || []).map(item => {
    return (
      <CarouselItem
        onExiting={() => setAnimating(true)}
        onExited={() => setAnimating(false)}
        key={item}
      >
        <img src={item} alt="" />
      </CarouselItem>
    );
  });

  const addToCartBtn = () => {
    if (produto.PRDDISPS.STRETPRDFILADIAS[filialAtiva].ESTOQUE > 0) {
      const result = addedItems.find(i => i.cod === produto.CODPRODUTO);
      if (!result) {
        let data = {
          cod: produto.CODPRODUTO,
          produto: {
            ...produto,
            ITEM: "",
          },
          filial: filialAtiva,
          precoVenda: precoVenda,
        };
        toast.success(`${produto.CODPRODUTO} adicionado ao carrinho.`, {
          theme: "colored",
        });
        dispatch(addToCart(data));
      } else {
        toast.warning(`${produto.CODPRODUTO} já consta no carrinho.`, {
          theme: "colored",
        });
      }
    }
  };

  useEffect(() => {
    if (!findedItems[produto.CODPRODUTO]) {
      dispatch(getProductDetails(produto.CODPRODUTO));
    }
  }, [dispatch]);

  function getCodFab() {
    if (item.atributosEspeciais) {
      let val = Object.values(item.atributosEspeciais);
      let selo1 = val.find(i => i.Codattr == "304");
      let selo2 = val.find(i => i.Codattr == "305");
      if (!(selo1 && selo2)) {
        return false;
      } else {
        return (selo1?.Valor || "") + " / " + (selo2.Valor || "");
      }
    } else {
      return false;
    }
  }

  function getSeloA() {
    if (item.atributosEspeciais) {
      let val = Object.values(item.atributosEspeciais);
      let selo1 = val.find(i => i.Codattr == "188");
      if (selo1) {
        return selo1.Valor;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  return (
    <React.Fragment>
      <Row className="d-flex align-items-center">
        {item.fotos ? (
          <Col md={1}>
            <img src={item?.fotos[0]} className="parceiro-input" />
          </Col>
        ) : (
          <div />
        )}

        <Col md={item?.fotos ? 3 : 4}>
          <h5 style={{ fontSize: 16, fontWeight: 500 }}>{produto.DESCRICAO}</h5>
          <Row>
            <Col className="mt-2 d-flex align-items-center">
              <div>
                <p className="text-muted mb-0" style={{ fontSize: 11 }}>
                  Cód. Fabricante: {getCodFab() || ""}
                </p>
                <p className="text-muted mb-0" style={{ fontSize: 11 }}>
                  SKU: {produto.CODPRODUTO}
                </p>
              </div>
              <img
                src={bandeiras[getSeloA() || produto.SELOA]}
                className="selo-a"
              />
            </Col>
          </Row>
        </Col>
        <Col md={2}>
          <p className="align-center">Preço de Venda</p>
          <div className="campo-valores">
            <Input
              className="mb-0 mt-4 pre-venda"
              value={varPre.toLocaleString("pt-br", formato)}
              onChange={e => {
                setVarPre(e.target.value);
              }}
              onBlur={() => {
                let val =
                  String(varPre)
                    .replace(/[^0-9,]*/g, "")
                    .replace(",", ".") || 0;
                if (Number(val) >= valMin && Number(val) <= valMax) {
                  setPrecoVenda(Number(val));
                } else {
                  setVarPre(Number(precoVenda));
                  toast.warning(
                    `O preço tabela não autorizado. Deve ser maior que ${valMin.toLocaleString(
                      "pt-br",
                      formato
                    )} e menor que ${valMax.toLocaleString("pt-br", formato)}`,
                    { theme: "colored" }
                  );
                }
              }}
            />
          </div>
        </Col>
        <Col md={2}>
          <p className="align-center">Valor Tabela</p>
          <div className="campo-valores">
            <p className="mb-0 val-total">
              {Number(
                produto.PRCVENDA.STRETPRCFILADIAS[filialAtiva].PRCVENDA
              ).toLocaleString("pt-br", formato)}
            </p>
          </div>
        </Col>
        <Col md={2}>
          <p className="align-center">Disponibilidade</p>
          <div className="disp-text">
            {produto.PRDDISPS.STRETPRDFILADIAS[filialAtiva].ESTOQUE >= 5 ? (
              <p className="mb-0" style={{ color: "#13b013" }}>
                <span className="h3" style={{ color: "#13b013" }}>
                  <i className="fas fa-thermometer-three-quarters"></i>
                </span>{" "}
                <br />
                Estoque normal
              </p>
            ) : produto.PRDDISPS.STRETPRDFILADIAS[filialAtiva].ESTOQUE >= 1 ? (
              <p className="mb-0" style={{ color: "#FFBF00" }}>
                <span className="h3" style={{ color: "#FFBF00" }}>
                  <i className="fas fa-thermometer-quarter"></i>
                </span>{" "}
                <br />
                Estoque baixo
              </p>
            ) : (
              <p className="mb-0" style={{ color: "#8B0000" }}>
                <span className="h3" style={{ color: "#8B0000" }}>
                  <i className="fas fa-thermometer-empty"></i>
                </span>{" "}
                <br />
                Sem Estoque
              </p>
            )}
          </div>
        </Col>
        <Col md={2}>
          {item.fotos ? (
            <Button
              className="but-modal"
              onClick={() => setIsModalOpen(!isModalOpen)}
            >
              Ver detalhes
            </Button>
          ) : (
            <div />
          )}
          {produto.PRDDISPS.STRETPRDFILADIAS[filialAtiva].ESTOQUE >= 1 ? (
            <Button className="mt-2 but-add" onClick={() => addToCartBtn()}>
              Adicionar
            </Button>
          ) : (
            <div />
          )}
        </Col>
      </Row>
      <hr className="mt-4 mb-4" />
      <Row onChange={e => setFilialAtiva(e.target.ariaLabel)}>
        <Col md={3}>
          <p style={{ fontWeight: 500 }}>Disponível nas Filiais:</p>
        </Col>
        {produto.PRDDISPS.STRETPRDFILADIAS.map((value, index) => (
          <Col md={3} key={index}>
            <FormGroup check>
              <Input
                type="radio"
                value={value.CODFILPRD}
                aria-label={index}
                name={`filial-${produto.CODPRODUTO}`}
                checked={index == filialAtiva}
                className="check-filial"
                onChange={() => {}}
              />
              <Label check className="text-indigo ocultare">
                {filiais[Number(value.CODFILPRD)]}
              </Label>
            </FormGroup>
          </Col>
        ))}
      </Row>

      {item.fotos ? (
        <Modal size="lg" isOpen={isModalOpen}>
          <ModalHeader toggle={() => setIsModalOpen(!isModalOpen)}>
            {produto.DESCRICAO}
          </ModalHeader>
          <ModalBody className="p-5">
            <Row>
              <Col>
                <Carousel
                  activeIndex={activeIndex}
                  next={next}
                  previous={previous}
                >
                  <CarouselIndicators
                    items={item.fotos}
                    activeIndex={activeIndex}
                    onClickHandler={goToIndex}
                  />
                  {slides}
                  <CarouselControl
                    direction="prev"
                    directionText="Previous"
                    onClickHandler={previous}
                  />
                  <CarouselControl
                    direction="next"
                    directionText="Next"
                    onClickHandler={next}
                  />
                </Carousel>
              </Col>
            </Row>

            <h4 className="mt-4 mb-4">Características Principais</h4>
            <Row className="mt-2">
              {Object.values(item.atributosEspeciais).map(value => (
                <Col md={6} className="mt-2" key={value.Codattr}>
                  <h5>{value.Nome}</h5>
                  <p>{value.Valor}</p>
                </Col>
              ))}
            </Row>

            <h4 className="mt-4 mb-4">Tamanhos</h4>
            <Table striped>
              <tbody>
                {tamanhos.map((value, index) => (
                  <tr key={index}>
                    <td>{value[0]}</td>
                    <td>{value[1]}</td>
                  </tr>
                ))}
              </tbody>
            </Table>

            <h4 className="mt-4 mb-4">Características</h4>
            <Table striped>
              <tbody>
                {item.atributos.map(value => (
                  <tr key={value.Codattr}>
                    <td>{value.Nome}</td>
                    <td>{value.Valor}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </ModalBody>
        </Modal>
      ) : (
        <div />
      )}
    </React.Fragment>
  );
};

export default FindedItem;
