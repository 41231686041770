import React, { useEffect } from "react";
import { Container } from "reactstrap";
import { TelaToda, Card } from "./style.js";
import "./style.js";
import { useSolar } from "../../hooks/SolarHooks.js";
import NotFoundGifAr from "../../assets/gifs/erro_404_azul.gif";
import NotFoundGifSolar from "../../assets/gifs/erro_404_laranja.gif";

const NotFound = () => {
  const isSolar = useSolar();

  useEffect(() => {
    var ob = document.getElementById("principal-container");
    if (isSolar) {
      ob.classList.add("notFoundSolar");
    } else {
      ob.classList.add("notFoundAr");
    }
  }, [document, isSolar]);
  return (
    <React.Fragment>
      <div className="page-content NotFound">
        <Container>
          <TelaToda>
            <Card style={{ backgroundColor: isSolar ? "#fff" : "#f7f9fd" }}>
              {isSolar ? (
                <img src={NotFoundGifSolar} />
              ) : (
                <img src={NotFoundGifAr} />
              )}
            </Card>
          </TelaToda>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default NotFound;
