//images - TODO remove it
import avatar2 from "../../../assets/images/users/avatar-2.jpg";
import avatar1 from "../../../assets/images/users/avatar-1.jpg";

const details1 = {
  id: "#7344",
  address: "386019- Termoline ar condici Termoline ar condicionado Itda",
  address2: "AV DOS TARUMAS, 115 - SETOR INDUSTRIAL SUL",
  method: "SIM",
  date: "04/08/2021",
  email: "commercial@termoline.com.br",
  cep: "78557446",
  fabricante: "elgin",
  telephone: "(66) 35316239",
  estado: "Sinop / Mato Grosso",
};

const documentsList = [
  {
    id: 1,
    name: "Boletim Informativo Elgin",
    fileName: "Serie de produtos Elgin.psd",
    fileLink: "#",
    desc: "Documento para consultar, como localizar Serir de Produtos Elgin",
  },
  {
    id: 2,
    name: "Formulario para Snalise de troca do compressor",
    fileName: "Formulario pre-troca de compressor.pdf",
    fileLink: "#",
    desc: "Para a Troca de Compressor e necessario o preenchimento total desse check-list.",
  },
];

const attachments = [
  {
    id: 1,
    fileName: "checklist.pdf",
    fileLink: "#",
    date: "04/08/2021 18:32:24",
    desc: "Documento para consultar, como localizar Serir de Produtos Elgin",
  },
  {
    id: 1,
    fileName: "checklist.pdf",
    fileLink: "#",
    date: "04/08/2021 18:32:24",
    desc: "Documento para consultar, como localizar Serir de Produtos Elgin",
  },
];

const comments = [
  {
    id: 1,
    user: {
      name: "Juliana",
      image: avatar1,
    },
    comment:
      "Bom dia! Compressor solicitado. Prazo medio para recebimento de pesa na A.Dias de 10 a 20 disa uteis, Favor confirmar endereco complete para envio e o pessoa responsavel pelo recebimento, asiam que disponivel despacharemos via transportadora.",
    date: new Date().toISOString(),
  },
  {
    id: 1,
    user: {
      name: "Termoline ar condici",
      image: avatar2,
    },
    comment:
      "AVENIDA NATALINO JOAO BRESCANSIN, 1500 CEP 78890-134 - sorriso Mato grosso aos cuidados de Roni.",
    date: new Date().toISOString(),
  },
];

const chatFields = [
  {
    id: 1,
    user: {
      name: "Juliana",
      image: avatar1,
    },
    comment:
      "OS: 58685527 / 17222000033410 ELECTRONIC CONTROL BOARD (only this board in the parts list)",
    date: new Date().toISOString(),
  },
  {
    id: 1,
    user: {
      name: "Hebert",
      image: avatar2,
    },
    comment: "Okay",
    date: new Date().toISOString(),
  },
];

export { details1, documentsList, attachments, comments, chatFields };
