import React from "react";
export default function IconChartLine(props) {
  const colorFill = props?.colorFill?.replaceAll("#", "") || "none";
  const colorStroke = props?.colorStroke || "#FF601A";
  const width = props?.width || 21;
  const height = props?.height || 21;

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 42 41"
      fill={colorFill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M35.9981 32.9961H5.85938V7.9361"
        stroke={colorStroke}
        strokeWidth="2.51353"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M33.4866 10.4421L20.9288 22.9721L15.9056 17.9601L5.85938 27.9841"
        stroke={colorStroke}
        strokeWidth="2.51353"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M33.4859 16.7071V10.4421H27.207"
        stroke={colorStroke}
        strokeWidth="2.51353"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
