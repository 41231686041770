import React from "react";
import { Col, Row, Alert, Button, Spinner } from "reactstrap";

import { useSolar } from "../../hooks";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { apiClient } from "../../api/apiCore";

const ChildrenBudgetUrl = ({ orderChildren, setOrderChildren, clientData }) => {
  const isSolar = useSolar();

  const makeOrderChildren = async item => {
    const newArray = orderChildren.map((orcamento, k) => {
      return orcamento.Orcamento == item.Orcamento
        ? { ...orcamento, loading: true }
        : orcamento;
    });
    setOrderChildren(newArray);

    var obj = null;

    obj = {
      ...clientData,
      CODEMPRESA: item.CODEMPRESA,
      CODFILIAL: item.CODFILIAL,
    };
    const res = await apiClient.create(
      `api/orcamentos/efetiva/${item.Orcamento}`,
      obj
    );

    if (!res.erro) {
      toast.success(
        `Orçamento ${item.Orcamento} atrelado a este pedido efetivado com sucesso!`,
        {
          theme: "colored",
        }
      );
      const newArray = orderChildren.map((orcamento, k) => {
        return orcamento.Orcamento == item.Orcamento
          ? { ...orcamento, erro: false, numPedido: res.pedido, loading: false }
          : orcamento;
      });
      setOrderChildren(newArray);
    } else {
      toast.error(
        `Ocorreu um erro ao tentar efetivar o orcamento  ${
          item.Orcamento
        } atrelado a este pedido ${res.msg ? ":" + res.msg : ""}`,
        {
          theme: "colored",
        }
      );
      const newArray = orderChildren.map((orcamento, k) => {
        return orcamento.Orcamento == item.Orcamento
          ? { ...orcamento, erro: true, loading: false }
          : orcamento;
      });
      setOrderChildren(newArray);
    }
  };
  return (
    <Row>
      <Col xs={12}>
        {orderChildren.length > 0 ? (
          orderChildren.map((item, k) =>
            item.erro ? (
              <Alert
                style={{ display: "flex", alignItems: "center" }}
                color="danger"
              >
                Ocorreu um erro ao efetivar o orçamento {item.Orcamento}{" "}
                atrelado a esse pedido.
                <Button
                  color="danger"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginLeft: 10,
                  }}
                  onClick={() => makeOrderChildren(item)}
                  disabled={item?.loading}
                >
                  {item?.loading ? "Carregando..." : "Efetivar Novamente"}
                </Button>
              </Alert>
            ) : (
              <Alert key={k} style={{ display: "flex" }} color="success">
                Link para o pedido atrelado:{" "}
                {isSolar ? (
                  <Link
                    to={`/solar/pedidos/${item?.numPedido}/${item.CODFILIAL}/order`}
                    target="_blank"
                    style={{ marginLeft: 5 }}
                  >
                    {window?.location.host}/solar/pedidos/
                    {item?.numPedido}/{item.CODFILIAL}/order`
                  </Link>
                ) : (
                  <Link
                    to={`/pedidos/${item?.numPedido}/${item.CODFILIAL}/order`}
                    target="_blank"
                  >
                    {window?.location.host}/pedidos/{item?.numPedido}/
                    {item.CODFILIAL}/order
                  </Link>
                )}
              </Alert>
            )
          )
        ) : (
          <Alert
            className="await-order"
            style={{ display: "flex" }}
            color="primary"
          >
            Os pedidos atrelados a este estão sendo emitidos. Aguarde...
          </Alert>
        )}
      </Col>
    </Row>
  );
};

export default ChildrenBudgetUrl;
