import React from "react";

import { Card, CardBody, Col, Row } from "reactstrap"
import ManufacturerItem from "./ManufacturerItem";
import { Loader } from "react-bootstrap-typeahead";

const ManufacturersList = ({
  manufacturers,
  getManufacturersLoading
}) => {
  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <Row>
            <Col md={6}>
              <h5>
                <i className="fa fa-list"></i> Fabricantes
              </h5>
            </Col>
          </Row>
            {
                manufacturers.map((value, index) => {
                    return (
                      <ManufacturerItem
                        key={index}
                        manufacturer={value}
                      />
                    );
                })
              }

            { getManufacturersLoading && (
              <div style={{ textAlign: 'center'}}>
                <Loader  />
              </div>
            )}  
          </CardBody>
      </Card>
    </React.Fragment>
  )
}

export default ManufacturersList;