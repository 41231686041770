import React, { useState, useEffect } from "react";
import { Card, CardBody, Row, Col, Tooltip } from "reactstrap";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { formatInvertDate } from "../../utils/dateUtils";
import { mask, telMask, copyToClipboard, makeCall } from "../../utils";
import { useColors } from "../../hooks/useColors";
import SvgIcon from "../../components/SvgIcon";
import SectionTitle from "./SectionTitle";

const Header = ({ isOpen, colapse }) => {
  const { order } = useSelector(state => ({
    order: state.Order.orderActive.order,
  }));

  const { colorDefault, color600 } = useColors();
  const [isTooltipCpfCnpjOpen, setIsTooltipCpfCnpjOpen] = useState(false);

  useEffect(() => {
    if(isTooltipCpfCnpjOpen)
    {
      setTimeout(() => {
        setIsTooltipCpfCnpjOpen(false);
      }, 1500);
    }
  }, [isTooltipCpfCnpjOpen]);

  return (
    <React.Fragment>
      <Row>
        <Col>
          <p className="texto-cliente">
            <span className="fw-bold me-3 texto-data">Nome</span>
            {(order?.cliente?.N_COMPLETO || "").substr(0, 20)}
          </p>
        </Col>
        <Col>
          <p className="texto-cliente">
            <span className="fw-bold me-3">CPF/CNPJ</span>
            {mask(order?.cliente?.CPFCNPJ || "")}
            <button
              onClick={() => {copyToClipboard(order?.cliente?.CPFCNPJ || ""); setIsTooltipCpfCnpjOpen(true);}}
              className="btn pt-0 mx-1"
              id="tooltip-cpfcnpj-btn"
              style={{height: "32px"}}
            >
              <Tooltip
                target={"tooltip-cpfcnpj-btn"}
                isOpen={isTooltipCpfCnpjOpen}
                placement="top"
                toggle={() => setIsTooltipCpfCnpjOpen(!isTooltipCpfCnpjOpen)} 
                trigger="manual" 
                style={{ backgroundColor: colorDefault }}
              >
                Copiado!
              </Tooltip>
              <SvgIcon name="copy" color={color600}/>
            </button>
          </p>
        </Col>
        <Col className="me-auto">
          <p className="texto-cliente">
            <span className="fw-bold me-3">Celular</span>(
            {(order?.DDDCel || "").trim()}){" "}
            {telMask((order?.cliente?.CELULAR || "").trim())}
            <button
              onClick={() =>
                makeCall(
                  `${order?.DDDCel.trim() || ""}${
                    order?.cliente?.CELULAR.trim() || ""
                  }`
                )
              }
              style={{ border: "none", backgroundColor: "transparent" }}
            >
              <SvgIcon name="phone-call" className= "icon-sm ms-2"/>
            </button>
          </p>
        </Col>

        <a
          href="#"
          style={{ width: "fit-content", marginLeft: "auto", display: "block" }}
          onClick={event => {
            event.preventDefault();
            colapse();
          }}
        >
          {isOpen ? (
            <i className="fas fa-chevron-down"></i>
          ) : (
            <i className="fas fa-chevron-up"></i>
          )}
        </a>
      </Row>
      <Row style={{ marginTop: 10 }}>
        <Col>
          <p className="texto-fantasia">
            <strong>Nome Fantasia: </strong>
            {order?.cliente?.N_REDZ || ""}
          </p>
        </Col>
      </Row>
    </React.Fragment>
  );
};
const ClientData = () => {
  const { order } = useSelector(state => ({
    order: state.Order.orderActive.order,
  }));

  const { colorDefault, color600 } = useColors();
  const [isTooltipEmailOrderOpen, setIsTooltipEmailOrderOpen] = useState(false);

  useEffect(() => {
    if(isTooltipEmailOrderOpen)
    {
      setTimeout(() => {
        setIsTooltipEmailOrderOpen(false);
      }, 1500);
    }
  }, [isTooltipEmailOrderOpen]);

  const [isOpen, setIsOpen] = useState(true);
  const colapse = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div>
      <SectionTitle title="Dados do cliente" />
      <Card>
        <CardBody className="cardbody-dados">
          {/* header */}
          <div>
            <Header colapse={colapse} isOpen={isOpen} />
          </div>
          <div className="pt-3 border-data " hidden={isOpen}>
            <Row className="mb-4">
              <Col>
                <p className=" texto-dados texto-cliente">
                  <span className="fw-bold me-3">Código do cliente</span>
                  <Link
                    to={`/cliente/${order?.Cliente || ""}/editar`}
                    className="texto-dados"
                    style={{color: "#FF9E73 !important"}}
                  >
                    {order?.Cliente || ""}
                    <SvgIcon name="edit-contained" className="ms-2 icon-sm"/>
                  </Link>
                </p>
              </Col>
            </Row>
            <Row>
              <Col md={6} xs={12}>
                <p className="texto-cliente-data">
                  <span className="fw-bold me-3">E-mail</span>
                  {order?.cliente?.EMAIL || ""}
                  <button
                    onClick={() => {
                      copyToClipboard(order?.cliente?.EMAIL.trim() || "");
                      setIsTooltipEmailOrderOpen(true);
                      }
                    }
                    className="btn pt-0 mx-1"
                    id="tooltip-email-order-btn"
                    style={{height: "32px"}}
                  >
                    <Tooltip
                      target={"tooltip-email-order-btn"}
                      isOpen={isTooltipEmailOrderOpen}
                      placement="top"
                      toggle={() => setIsTooltipEmailOrderOpen(!isTooltipEmailOrderOpen)} 
                      trigger="manual" 
                      style={{ backgroundColor: colorDefault }}
                    >
                      Copiado!
                    </Tooltip>
                    <SvgIcon 
                      name="copy"
                      className="h6"
                      color={color600}
                    />
                  </button>
                </p>
                <p className="texto-cliente-data">
                  <span className="fw-bold me-3">RG / I.E.</span>
                  {order?.cliente?.Rg
                    ? order?.cliente?.Rg
                    : order?.InscricaoEstadual || ""}
                </p>
                <p className="texto-cliente">
                  <span className="fw-bold me-3">CONTATO</span>
                  {order?.cliente?.CONTATO || ""}
                </p>
              </Col>
              <Col md={4} xs={12}>
                <p className="texto-cliente-data">
                  <span className="fw-bold me-3">Data de Nascimento</span>
                  {formatInvertDate(order?.cliente?.DtNasc || "")}
                </p>
                <p className="texto-cliente texto-clientdata">
                  <span className="fw-bold me-3">Tel. Fixo</span>(
                  {order?.DDD || ""}) {order?.cliente?.TEL || ""}
                  <button
                    onClick={() =>
                      makeCall(
                        `${order?.DDD.trim() || ""}${
                          order?.cliente?.TEL.trim() || ""
                        }`
                      )
                    }
                    style={{ border: "none", backgroundColor: "transparent" }}
                  >
                    <SvgIcon name="phone-call" className= "icon-sm ms-2"/>
                  </button>
                </p>
              </Col>
              <Col md={2} xs={12}>
                <p className="texto-cliente-data">
                  <span className="fw-bold me-3">Cargo</span>
                  {order?.cliente?.CARGO || ""}
                </p>
                <p className="mb-3 mb-lg-0">
                  <span className="fw-bold me-3">Tipo</span>
                  {order?.cliente?.TP_PESSOA == "F"
                    ? "Física"
                    : "Jurídica" || ""}
                </p>
              </Col>
            </Row>
          </div>
        </CardBody>
      </Card>
    </div>
  );
};

export default ClientData;
