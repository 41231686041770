import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { Alert, Col, Row, Card, CardBody } from "reactstrap";
import { useSolar, useViewport } from "../../hooks";

import OrderItem from "./OrderItem";
import OrderPagination from "./OrderPagination";

const OrdersList = ({
  idType,
  setIdType,
  clienteId,
  setClienteId,
  parceiros,
  setParceiros,
  setNumPedido
}) => {
  const { ordersList } = useSelector(state => ({
    ordersList: state.OrderList.ordersList,
  }));

  /* let sorted = ordersList.sort(function (a, b) {
    let d1 = a.Data.split("/");
    let data1 = new Date(`${d1[2]}/${d1[1]}/${d1[0]}`);

    let d2 = b.Data.split("/");
    let data2 = new Date(`${d2[2]}/${d2[1]}/${d2[0]}`);

    if (asc) {
      if (data1 > data2) {
        return 1;
      }
      if (data1 < data2) {
        return -1;
      }
      return 0;
    } else {
      if (data1 < data2) {
        return 1;
      }
      if (data1 > data2) {
        return -1;
      }
      return 0;
    }
  }); */

  const [lista, setLista] = useState(ordersList);
  const [listaPaginada, setListaPaginada] = useState([]);
  const [asc, setAsc] = useState(true);
  const [index, setIndex] = useState(0);
  const changeIndex = valor => setIndex(valor);
  const isSolar = useSolar();

  useEffect(() => {
    setListaPaginada(paginate(lista));
  }, [lista]);

  function paginate(arr) {
    let orders = [];
    let data = arr;
    while (data.length) {
      orders.push(data.splice(0, 10));
    }

    return orders;
  }

  function despaginate(arr) {
    let orders = [];

    arr.map(value => {
      value.map(item => {
        orders.push(item);
      });
    });

    return orders;
  }
  const { width } = useViewport();
  const [verifyScroll, setVerifyScroll] = useState(false);

  useEffect(() => {
    if (width < 1080) {
      setVerifyScroll(true);
    } else {
      setVerifyScroll(false);
    }
  }, [width]);
  return (
    <React.Fragment>
      <Row className="mt-4">
        <Col>
          <h4>Lista de Pedidos:</h4>
        </Col>
      </Row>
      <div style={{ overflowX: verifyScroll ? "scroll" : "hidden" }}>
        <div style={{ minWidth: verifyScroll ? 1080 : 890 }}>
          <Row className="mt-2">
            <Col>
              <Card
                style={{
                  borderLeftWidth: 10,
                }}
              >
                <CardBody
                  style={{
                    display: "grid",
                    gridTemplateColumns: isSolar
                      ? "80px 80px 200px 120px 150px 80px 80px 70px 130px 0.3fr"
                      : "80px 80px 200px 120px 150px 70px 70px 130px 0.3fr",
                  }} /* className="d-flex flex-column flex-md-row justify-content-between card-list" */
                >
                  <p
                    id="dataCad"
                    style={{
                      textAlign: "center",
                      marginBottom: 0,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      color: "#005696",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      let data = despaginate(listaPaginada);
                      data.sort(function (a, b) {
                        let d1 = a.Data.split("/");
                        let data1 = new Date(`${d1[2]}/${d1[1]}/${d1[0]}`);

                        let d2 = b.Data.split("/");
                        let data2 = new Date(`${d2[2]}/${d2[1]}/${d2[0]}`);

                        if (asc) {
                          if (data1 > data2) {
                            return 1;
                          }
                          if (data1 < data2) {
                            return -1;
                          }
                          return 0;
                        } else {
                          if (data1 < data2) {
                            return 1;
                          }
                          if (data1 > data2) {
                            return -1;
                          }
                          return 0;
                        }
                      });
                      setAsc(!asc);
                      setLista(data);
                    }}
                  >
                    <strong>Cadastro</strong>
                    <Link
                      to="#"
                      className="justify-content-center d-flex align-items-center"
                      style={{ marginLeft: 5 }}
                    >
                      <i className="ph-arrows-down-up"></i>
                    </Link>
                  </p>
                  <p
                    id="numPedido"
                    style={{
                      textAlign: "center",
                      marginBottom: 0,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      color: "#005696",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      let data = despaginate(listaPaginada);
                      data.sort(function (a, b) {
                        if (asc) {
                          if (a.NumPedido > b.NumPedido) {
                            return 1;
                          }
                          if (a.NumPedido < b.NumPedido) {
                            return -1;
                          }
                          return 0;
                        } else {
                          if (a.NumPedido < b.NumPedido) {
                            return 1;
                          }
                          if (a.NumPedido > b.NumPedido) {
                            return -1;
                          }
                          return 0;
                        }
                      });
                      setAsc(!asc);
                      setLista(data);
                    }}
                  >
                    <strong>Pedido</strong>
                    <Link
                      to="#"
                      className="justify-content-center d-flex align-items-center"
                      style={{ marginLeft: 5 }}
                    >
                      <i className="ph-arrows-down-up"></i>
                    </Link>
                  </p>
                  <p
                    id="nomCli"
                    style={{
                      textAlign: "center",
                      marginBottom: 0,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      color: "#005696",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      let data = despaginate(listaPaginada);
                      data.sort(function (a, b) {
                        if (asc) {
                          if (a.NomeCliente > b.NomeCliente) {
                            return 1;
                          }
                          if (a.NomeCliente < b.NomeCliente) {
                            return -1;
                          }
                          return 0;
                        } else {
                          if (a.NomeCliente < b.NomeCliente) {
                            return 1;
                          }
                          if (a.NomeCliente > b.NomeCliente) {
                            return -1;
                          }
                          return 0;
                        }
                      });
                      setAsc(!asc);
                      setLista(data);
                    }}
                    className="order-cliente"
                  >
                    <strong>Cliente</strong>
                    <Link
                      to="#"
                      className="justify-content-center d-flex align-items-center"
                      style={{ marginLeft: 5 }}
                    >
                      <i className="ph-arrows-down-up"></i>
                    </Link>
                  </p>
                  <p
                    id="valTot"
                    style={{
                      textAlign: "center",
                      marginBottom: 0,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      color: "#005696",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      let data = despaginate(listaPaginada);
                      data.sort(function (a, b) {
                        if (asc) {
                          if (Number(a.total) > Number(b.total)) {
                            return 1;
                          }
                          if (Number(a.total) < Number(b.total)) {
                            return -1;
                          }
                          return 0;
                        } else {
                          if (Number(a.total) < Number(b.total)) {
                            return 1;
                          }
                          if (Number(a.total) > Number(b.total)) {
                            return -1;
                          }
                          return 0;
                        }
                      });
                      setAsc(!asc);
                      setLista(data);
                    }}
                  >
                    <strong>Valor total</strong>
                    <Link
                      to="#"
                      className="justify-content-center d-flex align-items-center"
                      style={{ marginLeft: 5 }}
                    >
                      <i className="ph-arrows-down-up"></i>
                    </Link>
                  </p>
                  <p
                    id="filaSit"
                    style={{
                      textAlign: "center",
                      marginBottom: 0,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      color: "#005696",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      let data = despaginate(listaPaginada);
                      data.sort(function (a, b) {
                        if (asc) {
                          if (a.queue > b.queue) {
                            return 1;
                          }
                          if (a.queue < b.queue) {
                            return -1;
                          }
                          return 0;
                        } else {
                          if (a.queue < b.queue) {
                            return 1;
                          }
                          if (a.queue > b.queue) {
                            return -1;
                          }
                          return 0;
                        }
                      });
                      setAsc(!asc);
                      setLista(data);
                    }}
                  >
                    <strong>Fila</strong>
                    <Link
                      to="#"
                      className="justify-content-center d-flex align-items-center"
                      style={{ marginLeft: 5 }}
                    >
                      <i className="ph-arrows-down-up"></i>
                    </Link>
                  </p>
                  <p
                    id="vend1"
                    style={{
                      textAlign: "center",
                      marginBottom: 0,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      color: "#005696",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      let data = despaginate(listaPaginada);
                      data.sort(function (a, b) {
                        if (asc) {
                          if (Number(a.Vendedor1) > Number(b.Vendedor1)) {
                            return 1;
                          }
                          if (Number(a.Vendedor1) < Number(b.Vendedor1)) {
                            return -1;
                          }
                          return 0;
                        } else {
                          if (Number(a.Vendedor1) < Number(b.Vendedor1)) {
                            return 1;
                          }
                          if (Number(a.Vendedor1) > Number(b.Vendedor1)) {
                            return -1;
                          }
                          return 0;
                        }
                      });
                      setAsc(!asc);
                      setLista(data);
                    }}
                  >
                    <strong>Vendedor</strong>
                    <Link
                      to="#"
                      className="justify-content-center d-flex align-items-center"
                      style={{ marginLeft: 5 }}
                    >
                      <i className="ph-arrows-down-up"></i>
                    </Link>
                  </p>
                  <p
                    id="vend2"
                    style={{
                      textAlign: "center",
                      marginBottom: 0,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      color: "#005696",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      let data = despaginate(listaPaginada);
                      data.sort(function (a, b) {
                        if (asc) {
                          if (Number(a.Vendedor2) > Number(b.Vendedor2)) {
                            return 1;
                          }
                          if (Number(a.Vendedor2) < Number(b.Vendedor2)) {
                            return -1;
                          }
                          return 0;
                        } else {
                          if (Number(a.Vendedor2) < Number(b.Vendedor2)) {
                            return 1;
                          }
                          if (Number(a.Vendedor2) > Number(b.Vendedor2)) {
                            return -1;
                          }
                          return 0;
                        }
                      });
                      setAsc(!asc);
                      setLista(data);
                    }}
                  >
                    <strong>Parceiro</strong>
                    <Link
                      to="#"
                      className="justify-content-center d-flex align-items-center"
                      style={{ marginLeft: 5 }}
                    >
                      <i className="ph-arrows-down-up"></i>
                    </Link>
                  </p>

                  {isSolar && (
                    <p
                      id="vend2"
                      style={{
                        textAlign: "center",
                        marginBottom: 0,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-start",
                        color: "#005696",
                        cursor: "pointer",
                      }}
                    >
                      <strong>Tipo</strong>
                      <Link
                        to="#"
                        className="justify-content-center d-flex align-items-center"
                        style={{ marginLeft: 5 }}
                      >
                        <i className="ph-arrows-down-up"></i>
                      </Link>
                    </p>
                  )}

                  <p
                    id="notf"
                    style={{
                      textAlign: "center",
                      marginBottom: 0,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      color: "#005696",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      let data = despaginate(listaPaginada);
                      data.sort(function (a, b) {
                        if (asc) {
                          if (Number(a.Nota) > Number(b.Nota)) {
                            return 1;
                          }
                          if (Number(a.Nota) < Number(b.Nota)) {
                            return -1;
                          }
                          return 0;
                        } else {
                          if (Number(a.Nota) < Number(b.Nota)) {
                            return 1;
                          }
                          if (Number(a.Nota) > Number(b.Nota)) {
                            return -1;
                          }
                          return 0;
                        }
                      });
                      setAsc(!asc);
                      setLista(data);
                    }}
                  >
                    <strong>Nota Fiscal</strong>
                    <Link
                      to="#"
                      className="justify-content-center d-flex align-items-center"
                      style={{ marginLeft: 5 }}
                    >
                      <i className="ph-arrows-down-up"></i>
                    </Link>
                  </p>
                </CardBody>
              </Card>
            </Col>
          </Row>

          {listaPaginada[index] ? (
            listaPaginada[index].map((value, index) => {
              return (
                <OrderItem
                  key={index}
                  order={value}
                  setIdType={setIdType}
                  setClienteId={setClienteId}
                  parceiros={parceiros}
                  setParceiros={setParceiros}
                  setNumPedido={setNumPedido}
                />
              );
            })
          ) : (
            <Alert color="info">Nenhum dado foi encontrado</Alert>
          )}
        </div>{" "}
      </div>
      {listaPaginada[index] && (
        <OrderPagination
          orders={listaPaginada}
          index={index}
          changeIndex={changeIndex}
        />
      )}
    </React.Fragment>
  );
};

export default OrdersList;
