import React, { useState } from "react";

import {
  Container,
  Card,
  CardBody,
  Col,
  FormGroup,
  Button,
  Row,
  Spinner,
} from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";

import "./autoGestaoCreate.css";
import { toast } from "react-toastify";

const AutoGestaoCreate = () => {
  const [titleName, setTitleName] = useState();
  const [description, setDescription] = useState();
  const [url, setUrl] = useState();
  const [site, setSite] = useState();
  const [params, setParams] = useState();
  const [showLoading, setShowLoading] = useState();

  const handleValidSubmit = () => {
    toast.success("Seu formulário foi enviado com sucesso!");
    setTitleName("");
    setDescription("");
    setUrl("");
    setSite("");
    setParams("");
    setShowLoading("");
  };

  return (
    <>
      <div className="page-content create-auto">
        <Container>
          <h1 className="titulo-promocoes">Promoções</h1>

          <Card>
            <CardBody>
              <h2 className="titulo-dash">
                <i className="ph-list-bullets icone-titulo-Listagem"></i>
                Listagem de Dashs
              </h2>
              <hr />
              <AvForm
                id="form-budget"
                className="form-horizontal mt-5"
                onValidSubmit={(e, v) => {
                  handleValidSubmit(e, v);
                }}
              >
                <FormGroup>
                  <AvField
                    label="Título"
                    type="text"
                    className="form-control mb-3"
                    id="formrow-titlename-Input"
                    value={titleName}
                    validate={{
                      required: {
                        value: true,
                        errorMessage: "O campo 'titulo' é obrigatório",
                      },
                      minLength: {
                        value: 5,
                        errorMessage:
                          "O campo 'título' deve possuir no mínimo 5 caracteres",
                      },
                    }}
                    name="titlename"
                  />
                  <AvField
                    label="Descriçāo"
                    type="text"
                    className="form-control mb-3"
                    id="formrow-description-Input"
                    value={description}
                    validate={{
                      required: {
                        value: true,
                        errorMessage: "O campo 'descriçāo' é obrigatório",
                      },
                      minLength: {
                        value: 10,
                        errorMessage:
                          "O campo 'descriçāo' deve possuir no mínimo 10 caracteres",
                      },
                    }}
                    name="description"
                  />
                  <AvField
                    label="Url"
                    type="text"
                    className="form-control mb-3"
                    id="formrow-url-Input"
                    value={url}
                    validate={{
                      required: {
                        value: true,
                        errorMessage: "O campo 'Url' é obrigatório",
                      },
                      minLength: {
                        value: 2,
                        errorMessage:
                          "Ao campo 'Url' deve possuir no mínimo 2 caracteres",
                      },
                    }}
                    name="url"
                  />
                  <AvField
                    label="Site"
                    type="text"
                    className="form-control mb-3"
                    id="formrow-site-Input"
                    value={site}
                    validate={{
                      required: {
                        value: true,
                        errorMessage: "O campo 'Site' é obrigatório",
                      },
                      minLength: {
                        value: 10,
                        errorMessage:
                          "O campo 'site' deve possuir no mínimo 10 caracteres",
                      },
                    }}
                    name="site"
                  />
                  <AvField
                    label="Parametros"
                    type="text"
                    className="form-control mb-3"
                    id="formrow-parametros-Input"
                    value={params}
                    validate={{
                      required: {
                        value: true,
                        errorMessage: "O campo 'parametros' é obrigatório",
                      },
                      minLength: {
                        value: 5,
                        errorMessage:
                          "O campo 'parametros' deve possuir no mínimo 5 caracteres",
                      },
                    }}
                    name="params"
                  />
                  <div id="formrow-checkbox">
                    <label className="titulo-grupos">Grupos:</label>
                    <Row>
                      <Col md={3}>
                        <AvField
                          label="Administradores"
                          name="administradores"
                          type="checkbox"
                          className="input-radio cursor-pointer"
                        />
                        <AvField
                          label="Diretorias"
                          name="diretorias"
                          type="checkbox"
                          className="input-radio cursor-pointer"
                        />
                        <AvField
                          label="Gerente"
                          name="gerente"
                          type="checkbox"
                          className="input-radio cursor-pointer"
                        />
                        <AvField
                          label="Supervisor"
                          name="supervisor"
                          type="checkbox"
                          className="input-radio cursor-pointer"
                        />
                        <AvField
                          label="Teste Multi Usuario"
                          name="teste-multi-usuario"
                          type="checkbox"
                          className="input-radio cursor-pointer"
                        />
                      </Col>
                      <Col md={3}>
                        <AvField
                          label="Vendedor Revenda"
                          name="vendedor-revenda"
                          type="checkbox"
                          className="input-radio cursor-pointer"
                        />
                        <AvField
                          label="Assistente Revenda"
                          name="assistente-revenda"
                          type="checkbox"
                          className="input-radio cursor-pointer"
                        />
                        <AvField
                          label="Funcionario Clube"
                          name="funcionario-clube"
                          type="checkbox"
                          className="input-radio cursor-pointer"
                        />
                        <AvField
                          label="Dono Revenda"
                          name="dono-revenda"
                          type="checkbox"
                          className="input-radio cursor-pointer"
                        />
                        <AvField
                          label="Parceiro Bronze"
                          name="parceiro-Bronze"
                          type="checkbox"
                          className="input-radio cursor-pointer"
                        />
                      </Col>
                      <Col md={3}>
                        <AvField
                          label="Funcionario Revenda"
                          name="funcionario-revenda"
                          type="checkbox"
                          className="input-radio cursor-pointer"
                        />
                        <AvField
                          label="Usuarios"
                          name="usuarios"
                          type="checkbox"
                          className="input-radio cursor-pointer"
                        />
                        <AvField
                          label="Vendedor Consumidor"
                          name="vendedor-consumidor"
                          type="checkbox"
                          className="input-radio cursor-pointer"
                        />
                        <AvField
                          label="Arquitetos"
                          name="arquitetos"
                          type="checkbox"
                          className="input-radio cursor-pointer"
                        />
                        <AvField
                          label="Parceiro Clube"
                          name="parceiro-clube"
                          type="checkbox"
                          className="input-radio cursor-pointer"
                        />
                      </Col>
                      <Col md={3}>
                        <AvField
                          label="Parceiro Diamante"
                          name="parceiro-diamante"
                          type="checkbox"
                          className="input-radio cursor-pointer"
                        />
                        <AvField
                          label="Parceiro Ouro"
                          name="parceiro-ouro"
                          type="checkbox"
                          className="input-radio cursor-pointer"
                        />
                        <AvField
                          label="Parceiro Prata"
                          name="parceiro-prata"
                          type="checkbox"
                          className="input-radio cursor-pointer"
                        />
                        <AvField
                          label="Parceiro Esmeralda"
                          name="parceiro-esmeralda"
                          type="checkbox"
                          className="input-radio cursor-pointer"
                        />
                      </Col>
                    </Row>
                  </div>
                  <div className="w-100 d-flex justify-content-end">
                    <Button
                      variant="contained"
                      color="success"
                      className="btn-default cursor-pointer hover-img-upload j-c-center mt-auto"
                      style={{ minWidth: 135 }}
                      disabled={showLoading}
                      type="submit"
                    >
                      {showLoading ? <Spinner /> : "Salvar"}
                    </Button>
                  </div>
                </FormGroup>
              </AvForm>
            </CardBody>
          </Card>
        </Container>
      </div>
    </>
  );
};

export default AutoGestaoCreate;
