import { useEffect, useState } from "react";
import { Folder, React } from "../../../helpers/importer.helper";
import "./styles.scss";
export default function ContainerTop({ dataPdf, dataProductAttributes }) {
  const [totalModules, setTotalModules] = useState(0);
  const bestAngulation = String(
    Math.round(dataPdf?.lead?.lead?.latitude)
  ).replace("-", "");

  //SOMA TODOS OS MODULOS DE TODOS OS PRODUTOS
  const sumModules = () => {
    var vlAtual = 0;
    var total = 0;

    for (var i = 0; i <= dataProductAttributes.length; i++) {
      for (var j = 0; j <= dataProductAttributes[i]?.atributos.length; j++) {
        if (dataProductAttributes[i]?.atributos[j]?.Codattr == "_qtdModulo") {
          vlAtual = dataProductAttributes[i]?.atributos[j]?.Valor;
          total = vlAtual + total;
        }
      }
    }
    setTotalModules(total);
  };

  useEffect(() => {
    if (dataProductAttributes?.length > 0) {
      sumModules();
    }
  }, [dataProductAttributes]);
  return (
    <div className="container-pdf-6-top">
      <div className="container-pdf-6-top-header">
        <Folder />
        <span>PROJETO SIMULADO:</span>
      </div>
      <div className="container-pdf-6-top-project">
        <div className="container-pdf-6-top-project-info">
          <span>Melhor Sistema Dimensionado:</span>
          <p>{dataPdf?.kwpPdf} kWp</p>
        </div>

        <div className="container-pdf-6-top-project-info">
          <span>Número de Módulos Fotovoltaicos Necessários:</span>

          <p>{totalModules} Módulos</p>
        </div>

        <div className="container-pdf-6-top-project-info">
          <span>Área Estimada do Sistema:</span>
          <p>{totalModules * 2.5} M²</p>
        </div>

        <div className="container-pdf-6-top-project-info">
          <span>Melhor Angulação e Melhor Orientação:</span>
          <p>{bestAngulation}</p>
        </div>

        {/*   <div className="container-pdf-6-top-project-info">
          <span>Temperatura Média Anual:</span>
          <p></p>
        </div> */}

        <div className="container-pdf-6-top-project-info">
          <span>
            Base de cálculo considerando os índices Solarimétricos da posição
            geográfica:
          </span>
          <p>
            {dataPdf?.lead?.lead?.address}
            <br />
            Latitude: {dataPdf?.lead?.lead?.latitude} Longitude:
            {dataPdf?.lead?.lead?.longitude}
          </p>
        </div>
      </div>
    </div>
  );
}
