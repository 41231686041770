export const CustomersActionTypes = {
  API_RESPONSE_SUCCESS: "@@customers/API_RESPONSE_SUCCESS",
  API_RESPONSE_ERROR: "@@customers/API_RESPONSE_ERROR",

  GET_CUSTOMERS_LIST: "@@customers/GET_CUSTOMERS_LIST",
  GET_CUSTOMERS_DETAILS: "@@customers/GET_CUSTOMERS_DETAILS",
  SAVE_CUSTOMERS_DETAILS: "@@customers/SAVE_CUSTOMERS_DETAILS",
  GET_ADDRES_BY_ZIPCODE: "@@customers/GET_ADDRES_BY_ZIPCODE",
  GET_MULTI_TELEPHONE: "@@customers/GET_MULTI_TELEPHONE",
  SAVE_MULTITELEPHONES_DETAILS: "@@customers/SAVE_MULTITELEPHONES_DETAILS",

  // reset data
  RESET_CUSTOMERS: "@@customers/RESET_CUSTOMERS",
};
