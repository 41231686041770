import React from "react";
import { Table } from "reactstrap";

const Files = ({ files }) => {
  return (
    <div>
      <h5 className="px-2 font-size-16">Arquivos</h5>
      <div>
        <Table responsive hover className="border-light-1">
          <tbody>
            {(files || []).map((file, key) => (
              <tr key={key}>
                <td>
                  <a href={file.path} className="text-dark fw-regular">
                    {file.name}
                  </a>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </div>
  );
};

export default Files;
