import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, useParams } from "react-router-dom";
import { Card, CardBody, Col, Container, Row, Spinner } from "reactstrap";
import { apiClient } from "../../../api/apiCore";
import ProductsPagination from "./ProductsPagination";

import Breadcrumb from "../../../components/Breadcrumb";
import {
  addProductBySku,
  clearNewOrder,
  getNewOrder,
  getOrderActive,
  getProductDetails,
  removeAllItems,
  removeOrderActive,
} from "../../../store/actions";
//import Component
import AddedItem from "./AddedItem";
import Banner from "../../../pages/NewOrders/Banner";
import FindedItem from "./FindedItem";
import "./newOrder.css";
import OrderFilter from "./OrderFilter";

import { toast } from "react-toastify";
import { formato } from "../../../utils";
import Arquiteto from "../../../pages/NewOrders/Arquiteto";
import ControleRemoto from "../../../pages/NewOrders/ControleRemoto";
import Cupons from "../../../pages/NewOrders/Cupons";
import Frete from "./Frete";
import PedidoEmitido from "./PedidoEmitido";
import RegisterUser from "./RegisterUser";
import Resumo from "./Resumo";
import Saldo from "./Saldo";
import PartnerCompany from "../../../pages/NewOrders/PartnerCompany";
import { useProfile } from "../../../hooks";
import Seguro from "./Seguro";

const Dashboard = ({ history }) => {
  const dispatch = useDispatch();
  const { userProfile } = useProfile();
  const { newOrder, loading, order, addedItems, emissaoPedido } = useSelector(
    state => {
      return {
        newOrder: state.Order.newOrder.newOrder,
        loading: state.Order.loading,
        order: state.Order.orderActive.order,
        addedItems: state.Order.newOrder.newOrder.addedItems,
        emissaoPedido: state.Order.newOrder.newOrder.emissaoPedido,
      };
    }
  );

  const { id, token } = useParams();
  const [frete, setFrete] = useState({});
  const [valores, setValores] = useState({});
  const [valArquiteto, setValArquiteto] = useState({});
  const [valorRt, setValorRt] = useState({});
  const [ocultarSemEstoque, setOcultarSemEstoque] = useState(true);
  const [ocultarRt, setOcultarRt] = useState(false);
  const [nfIsOpen, setNfIsOpen] = useState(false);
  const [obsIsOpen, setObsIsOpen] = useState(false);
  const [cepCliente, setCepCliente] = useState(null);
  const [pagtoSelected, setPagtoSelected] = useState([]);
  const [client, setClient] = useState({});
  const [saldo, setSaldo] = useState(0);
  const [itensCarrinho, setItensCarrinho] = useState({});
  const [filterPedido, setFilterPedido] = useState({});
  const [pedidoEmitido, setPedidoEmitido] = useState(false);
  const [type, setType] = useState("order");
  const [paginatedProducts, setPaginatedProducts] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [discountValue, setDiscountValue] = useState();
  const [, setObsInterna] = useState("");
  const [, setObsNF] = useState("");

  const [percentWattpay, setPercentWattpay] = useState();

  const [sendValServ, setSendValServ] = useState(false);
  const [valServ, setValServ] = useState(0);

  const [dataLeed, setDataLeed] = useState([]);

  const [formVisible, setFormVisible] = useState({
    prospect: true,
    client: false,
    partner: false,
  });
  const [loadingProspect, setLoadingProspect] = useState(false);

  const [parceiros, setParceiros] = useState([]);
  const [seguro, setSeguro] = useState([
    {
      type: "seguroEng",
      name: "",
      percent: 0.5,
      valor: 0,
      checked: false,
    },
    { type: "seguroRd", name: "", percent: 1, valor: 0, checked: false },
  ]);
  const [errosDataProspectAndPartner, setErrosDataProspectAndPartner] =
    useState({
      name: "",
      email: "",
      ddd: "",
      tel: "",
      cep: "",
      address: "",
      number: "",
      complement: "",
      state: "",
      city: "",
      district: "",
    });

  const valTot = Somar(Object.values(valores));
  const valArq = Somar(Object.values(valArquiteto));
  const valRt = Somar(Object.values(valorRt));

  useEffect(() => {
    dispatch(clearNewOrder());
    setFrete({});
    setValores({});
    setValArquiteto({});
    setValorRt({});
    setOcultarSemEstoque(true);
    setOcultarRt(false);
    setNfIsOpen(false);
    setObsIsOpen(false);
    setCepCliente(null);
    setPagtoSelected([]);
    setClient({});
    setSaldo(0);
    setItensCarrinho({});
    setFilterPedido({});
    setPedidoEmitido(false);
    setType("order");
    dispatch(getNewOrder("CODEMPRESA=02&CODFILIAL=02", history));
  }, []);

  const getDataLeed = async () => {
    try {
      const resp = await apiClient.get(`/api/solar/lead/${token}`, {
        type: "order",
      });

      setDataLeed(resp);
    } catch (error) {
      toast.error(error, { theme: "colored" });
    }
  };

  useEffect(() => {
    if (token) getDataLeed();
  }, []);

  useEffect(() => {
    setObsInterna(order?.ObservacaoInterna);
    setObsNF(order?.ObservacaoNota);
  }, [order]);

  //verificar se o retorno da requisição getNewOrder é solar
  const isSolarRequestNewOrder = () => {
    var requestIsSolar = newOrder.tabelaInicial == "043";
    if (!requestIsSolar) {
      location.reload();
    }
  };

  useEffect(() => {
    if (newOrder?.tabelaInicial) {
      isSolarRequestNewOrder();
    }
  }, [newOrder.tabelaInicial]);

  useEffect(() => {
    if (id) {
      toast.warning(
        "Atenção: valores de equipamentos e frete poderão serem alterados.",
        { theme: "colored" }
      );
      dispatch(getOrderActive(id));
    } else {
      dispatch(removeOrderActive());
    }
  }, [id]);

  useEffect(() => {
    if (id) {
      (order?.items_orcamento || []).map(value => {
        dispatch(getProductDetails(value.Produto));
        const result = addedItems.find(i => i.cod === value.Produto);
        if (!result) {
          let data = {
            sku: value.Produto,
            tabela: order.TabelaPreco,
            pagto: order.CondicaoPagamento,
            filial: value.CodFilial,
            precoVenda: value.PrecoVend,
            quant: value.Qtdven,
            item: value.Item,
          };

          dispatch(addProductBySku(data));
        }
      });
    }
  }, [order]);

  useEffect(() => {
    if (newOrder.orderProducts?.produtos?.length > 0) {
      setPaginatedProducts(
        paginate(
          newOrder.orderProducts.produtos.sort((a, b) => {
            if (
              a.PRCVENDA?.STRETPRCFILADIAS[0]?.PRCVENDA >
              b.PRCVENDA?.STRETPRCFILADIAS[0]?.PRCVENDA
            ) {
              return 1;
            }
            if (
              a.PRCVENDA?.STRETPRCFILADIAS[0]?.PRCVENDA <
              b.PRCVENDA?.STRETPRCFILADIAS[0]?.PRCVENDA
            ) {
              return -1;
            }
          }),
          10
        )
      );
    } else {
      setPaginatedProducts([]);
    }
  }, [newOrder.orderProducts?.produtos]);

  useEffect(() => {
    if (
      newOrder?.orderProducts?.success &&
      newOrder?.orderProducts?.produtos.length < 1
    ) {
      toast.warning("Nenhum produto encontrado com os filtros atuais", {
        theme: "colored",
      });
    }
  }, [newOrder?.orderProducts]);

  const pedido = {
    client: client,
    type_order: "order",
    product_type: "",
    budget_infos: {
      codigo_cliente: 0,
      tipo_cliente: 0,
    },
    items: retornaItems(),
    itemsValTot: valTot,
    shipping: {
      id: frete?.id || "",
      shipping_selected: {
        delivery_delay: frete?.delivery_delay || "",
        id: frete?.id || "",
        price: frete?.price || "",
        description: frete?.description || "",
        price_percent: frete?.price_percent || "",
        usado_percentual: frete?.usado_percentual || "",
        frete_gratis: frete?.frete_gratis || "",
      },
      price: frete?.price || "",
    },
    filters: filterPedido,
    services: {
      product_type: "",
      ambientes: [],
    },
    discountcashback: saldo,
    ...discountValue,
    seguro,
    CODEMPRESA: "02",
    CODFILIAL: "02",
    typeOfKit: "kit-pronto",
  };

  function retornaItems() {
    let items = [];

    newOrder.addedItems.map(value => {
      function getEstoques(es) {
        let result =
          value.produto.PRDDISPS?.STRETPRDFILADIAS?.find(
            i => i.CODFILPRD === es
          ) || "";
        return result?.ESTOQUE || 0;
      }

      let produto = {
        ...value.produto,
        PRCVENDA: value.precoVenda,
        PRCRT: value.precoVenda,
        ESTOQUE_CD01: getEstoques("01"),
        ESTOQUE_CD02: getEstoques("02"),
        ESTOQUE_CD03: getEstoques("03"),
        ESTOQUE_CD04: getEstoques("04"),
        precos: {
          cd:
            value.produto.PRDDISPS?.STRETPRDFILADIAS[value.filial]?.CODFILPRD ||
            "",
          preco: value.precoVenda,
          diferente: "N",
        },
        PRECODIFER:
          value.produto.PRCVENDA?.STRETPRCFILADIAS[value.filial]?.PRECODIFER ||
          "",
        ESTOQUE:
          value.produto.PRDDISPS?.STRETPRDFILADIAS[value.filial]?.ESTOQUE || "",
        instalacao: false,
        VALSERV: sendValServ ? valServ : 0,
        ...itensCarrinho[value.produto.CODPRODUTO],
      };

      items.push(produto);
    });

    return items;
  }

  function Somar(array) {
    var total = 0;
    for (var i = 0; i < array.length; i++) {
      if (array[i] >= 2) {
        total += array[i];
      }
    }
    return total;
  }

  function paginate(arr, size) {
    return arr.reduce((acc, val, i) => {
      let idx = Math.floor(i / size);
      let page = acc[idx] || (acc[idx] = []);
      page.push(val);
      return acc;
    }, []);
  }

  const addValTot = valor => {
    setValores(valor);
  };

  const addValArqu = valor => {
    setValArquiteto(valor);
  };

  const addValorRt = valor => {
    setValorRt(valor);
  };

  const addFrete = valor => {
    setFrete(valor);
  };

  const changeOcultarRt = () => {
    setOcultarRt(!ocultarRt);
  };

  const changeType = type => {
    setType(type);
  };

  const changeCepCliente = cep => {
    setCepCliente(cep);
  };

  const changePagtoSelected = pagto => {
    setPagtoSelected(pagto);
  };

  const changeSaldo = saldo => {
    setSaldo(Number(saldo));
  };

  const changeClient = client => {
    setClient(prev => ({ ...prev, ...client }));
  };

  const changeItensCarrinho = itens => {
    setItensCarrinho(itens);
  };

  const addFilterPedido = filter => {
    setFilterPedido(filter);
  };

  const changePedidoEmitido = () => {
    setPedidoEmitido(!pedidoEmitido);
  };

  const renderView = () => {
    if ("fabricantes" in newOrder) {
      return (
        <React.Fragment>
          <Breadcrumb
            title="Página Inicial"
            breadcrumbItem="Emissão do pedido"
          />
          <Row>
            <Col md={12}>
              <div className="mb-4">
                {/* import Banner */}
                <Banner />
              </div>
            </Col>
          </Row>
          {loadingProspect && (
            <div className="carregando-emissao">
              <Spinner />
            </div>
          )}

          {loading && (
            <div className="carregando-emissao">
              <Spinner />
            </div>
          )}
          {/* VERIFICA SE O PEDIDO FOI EMITIDO  */}
          {!emissaoPedido?.data?.success ? (
            <React.Fragment>
              <OrderFilter
                ocultarRt={ocultarRt}
                changeOcultarRt={changeOcultarRt}
                changePagtoSelected={changePagtoSelected}
                addFilterPedido={addFilterPedido}
                filterPedido={filterPedido}
                history={history}
                dataLeed={dataLeed}
                parceiros={parceiros}
                setParceiros={setParceiros}
              />
              <hr style={{ borderTop: "1.5px solid #D5DCE5" }} />
              {newOrder?.orderProducts ? (
                <Row className="mt-4" id="busca">
                  <Col>
                    <h4 style={{ fontSize: 20, color: "#FF601A" }}>
                      Produtos Encontrados:
                    </h4>

                    {newOrder.orderProducts.isLoading ? (
                      <>
                        <Spinner
                          style={{ margin: "20% 50%" }}
                          className="mt-4"
                        />
                      </>
                    ) : newOrder.orderProducts?.produtos?.length > 0 &&
                      paginatedProducts.length > 0 ? (
                      <>
                        {/* <FormGroup check className="d-flex justify-content-end">
                          <Input
                            type="checkbox"
                            checked={ocultarSemEstoque}
                            className="sem-estoque"
                            onChange={() => setOcultarSemEstoque(prev => !prev)}
                          />
                          <Label check className="text-indigo ocultar">
                            Ocultar produtos sem estoque
                          </Label>
                        </FormGroup> */}
                        {paginatedProducts[currentPage].map(value => (
                          <FindedItem
                            key={value.CODPRODUTO}
                            produto={value}
                            ocultarSemEstoque={ocultarSemEstoque}
                          />
                        ))}{" "}
                        <Col md={12}>
                          <ProductsPagination
                            unsortedProducts={newOrder?.orderProducts?.produtos}
                            products={paginatedProducts}
                            page={currentPage}
                            changePage={setCurrentPage}
                          />
                        </Col>
                      </>
                    ) : (
                      <div>
                        <p>Não foram encontrados produtos</p>
                      </div>
                    )}
                  </Col>
                </Row>
              ) : (
                <div />
              )}
              <hr style={{ borderTop: "1.5px solid #D5DCE5" }} />
              {(newOrder?.addedItems.length || 0) > 0 ? (
                <Row className="mt-4" id="carrinho">
                  <Col>
                    <h4
                      className="mb-4"
                      style={{
                        fontSize: 18,
                        fontWeight: "bold",
                        color: "#FF601A",
                      }}
                    >
                      Produtos Adicionados
                    </h4>

                    {newOrder.addedItems.map(item => (
                      <AddedItem
                        key={item.cod}
                        item={item}
                        setValServ={setValServ}
                        setSendValServ={setSendValServ}
                        addValTot={addValTot}
                        valores={valores}
                        ocultarRt={ocultarRt}
                        changeItensCarrinho={changeItensCarrinho}
                        itensCarrinho={itensCarrinho}
                        valArquiteto={valArquiteto}
                        addValArqu={addValArqu}
                        valorRt={valorRt}
                        addValorRt={addValorRt}
                        setPercentWattpay={setPercentWattpay}
                      />
                    ))}

                    <Card
                      style={{
                        borderRadius: "0 0  15px 15px",
                        backgroundColor: "#FFF7F2",
                        zIndex: 100,
                        marginTop: -15,
                      }}
                    >
                      <CardBody>
                        <Row className="d-flex justify-content-end">
                          <Col
                            md={4}
                            className="d-flex justify-content-between"
                          >
                            <h4
                              className="mb-0"
                              style={{ color: "#FF601A", fontWeight: "bold" }}
                            >
                              Subtotal
                            </h4>
                            <h4
                              className="mb-0"
                              style={{ color: "#FF601A", fontWeight: "bold" }}
                            >
                              {valTot.toLocaleString("pt-br", formato)}
                            </h4>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              ) : (
                <div />
              )}
              <Row id="sentinel" className="mt-4">
                <RegisterUser
                  valTot={valTot}
                  changeCepCliente={changeCepCliente}
                  pagtoSelected={pagtoSelected}
                  changeClient={changeClient}
                  client={client}
                  formVisible={formVisible}
                  setFormVisible={setFormVisible}
                  errosDataProspectAndPartner={errosDataProspectAndPartner}
                  setErrosDataProspectAndPartner={
                    setErrosDataProspectAndPartner
                  }
                  dataLeed={dataLeed}
                  parceiros={parceiros}
                  setParceiros={setParceiros}
                />
              </Row>
              <Row className="mt-3" id="notas">
                <Cupons setDiscountValue={setDiscountValue} valTot={valTot} />
              </Row>
              <Row className="mt-3">
                <Saldo changeSaldo={changeSaldo} />
              </Row>
              <Row className="mt-3">
                <Card>
                  <CardBody>
                    <Row>
                      <Col md={11}>
                        <p
                          className="mb-0 d-flex align-items-center"
                          style={{ fontWeight: "bold" }}
                        >
                          <i
                            className="ph-notepad"
                            style={{
                              color: "#FF601A",
                              marginRight: 20,
                              fontSize: 25,
                            }}
                          ></i>
                          Adicionar Observação Interna:
                        </p>
                      </Col>
                      <Col md={1}>
                        <a
                          href="#"
                          style={{
                            width: "fit-content",
                            marginLeft: "auto",
                            display: "block",
                          }}
                          onClick={event => {
                            event.preventDefault();
                            setObsIsOpen(!obsIsOpen);
                          }}
                        >
                          {obsIsOpen ? (
                            <i className="fas fa-chevron-up"></i>
                          ) : (
                            <i className="fas fa-chevron-down"></i>
                          )}
                        </a>
                      </Col>
                    </Row>
                    <Row
                      hidden={!obsIsOpen}
                      style={{
                        marginTop: 30,
                        paddingTop: 30,
                        borderTop: "1px solid #d4d4d4",
                      }}
                    >
                      <textarea
                        className="form-control"
                        onChange={e =>
                          setClient(prev => ({
                            ...prev,
                            obs: e.target.value,
                          }))
                        }
                        value={client.obs}
                      />
                    </Row>
                  </CardBody>
                </Card>
              </Row>
              <Row className="mt-3">
                <Card>
                  <CardBody>
                    <Row>
                      <Col md={11}>
                        <p
                          className="mb-0 d-flex align-items-center"
                          style={{ fontWeight: "bold" }}
                        >
                          <i
                            className="ph-notepad"
                            style={{
                              color: "#FF601A",
                              marginRight: 20,
                              fontSize: 25,
                            }}
                          ></i>{" "}
                          Adicionar Observação de Nota Fiscal:
                        </p>
                        <small
                          className="text-muted"
                          style={{ marginLeft: "45px" }}
                        >
                          Adicionar dados do recebedor
                        </small>
                      </Col>
                      <Col md={1}>
                        <a
                          href="#"
                          style={{
                            width: "fit-content",
                            marginLeft: "auto",
                            display: "block",
                          }}
                          onClick={event => {
                            event.preventDefault();
                            setNfIsOpen(!nfIsOpen);
                          }}
                        >
                          {nfIsOpen ? (
                            <i className="fas fa-chevron-up"></i>
                          ) : (
                            <i className="fas fa-chevron-down"></i>
                          )}
                        </a>
                      </Col>
                    </Row>
                    <Row
                      hidden={!nfIsOpen}
                      style={{
                        marginTop: 30,
                        paddingTop: 30,
                        borderTop: "1px solid #d4d4d4",
                      }}
                    >
                      <textarea
                        className="form-control"
                        onChange={e =>
                          setClient(prev => ({
                            ...prev,
                            obsNot: e.target.value,
                          }))
                        }
                        value={client.obsNot}
                      />
                    </Row>
                  </CardBody>
                </Card>
              </Row>
              <Row className="d-flex justify-content-between" id="frete">
                <Col md={7}>
                  <Frete
                    valTot={valTot}
                    addFrete={addFrete}
                    cepCliente={cepCliente}
                  />
                  {/*  <Arquiteto valArq={valArq} /> */}
                  {userProfile?.integrador ? (
                    <div />
                  ) : (
                    <Card style={{ padding: 20 }}>
                      <PartnerCompany
                        parceiros={parceiros}
                        setParceiros={setParceiros}
                      />
                    </Card>
                  )}
                  <Seguro
                    seguro={seguro}
                    setSeguro={setSeguro}
                    pedido={pedido}
                  />
                </Col>

                <Col md={5}>
                  <Resumo
                    valTot={valTot}
                    valRt={valRt}
                    frete={frete}
                    saldo={saldo}
                    pedido={pedido}
                    changePedidoEmitido={changePedidoEmitido}
                    changeType={changeType}
                    formVisible={formVisible}
                    token={token}
                    percent={percentWattpay}
                    setLoadingProspect={setLoadingProspect}
                    discountValue={discountValue}
                  />
                </Col>
              </Row>
              <ControleRemoto />
            </React.Fragment>
          ) : (
            <PedidoEmitido
              changePedidoEmitido={changePedidoEmitido}
              type={type}
            />
          )}
        </React.Fragment>
      );
    } else {
      return (
        <div
          style={{
            marginInline: "auto",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Spinner />
        </div>
      );
    }
  };
  {
    (" ");
  }
  return (
    <React.Fragment>
      <div className="page-content neworder solar">
        <Container>
          {id ? (
            order ? (
              order?.Situacao < "F" ? (
                addedItems.length > 0 ? (
                  <React.Fragment>{renderView()}</React.Fragment>
                ) : (
                  <Spinner />
                )
              ) : (
                <Redirect to={`/pedidos/${order?.NumPedido}/order`} />
              )
            ) : (
              <Spinner />
            )
          ) : (
            renderView()
          )}
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Dashboard;
