import React from "react";
import { Col, Row, Card, CardBody, Button } from "reactstrap";
import classnames from "classnames";

//import images
import exclude1 from "../../assets/images/rewards/Exclude-1.svg";
import exclude2 from "../../assets/images/rewards/Exclude-2.svg";
import exclude3 from "../../assets/images/rewards/Exclude-3.svg";
import exclude4 from "../../assets/images/rewards/Exclude-4.svg";

const CouponTitle = ({ image, title, desc, contentClass }) => {
  return (
    <div className="h-100">
      <img src={image} className="w-100 h-100 img-fluid" alt="" />
      <div className="position-absolute top-50 start-50 translate-middle">
        <h3 className={classnames("text-uppercase", contentClass)}>{title}</h3>
        <p className={classnames("mb-0", "fs-6", contentClass)}>{desc}</p>
      </div>
    </div>
  );
};

const CoupontContent = ({ title, desc, onClickCoupon }) => {
  return (
    <div className="border border-secondary p-3 rounded-3 h-100 mt-xl-0 mt-3">
      <div className="text-center justify-content-center d-flex align-items-center">
        <div>
          <p className="text-muted fs-6 mb-2">{title}</p>
          <h6 className="fw-bold lh-base">{desc}</h6>
          <Button
            color="secondary"
            className="text-dark fw-bold text-uppercase mt-2"
            type="button"
            onClick={onClickCoupon}
          >
            user
          </Button>
        </div>
      </div>
    </div>
  );
};
const Coupons = () => {
  const onClickCoupon = () => {};
  return (
    <div className="">
      <div className="">
        <Row>
          <Col xs={12} lg={6}>
            <Card className="shadow-none">
              <CardBody>
                <Row className="align-items-center align-items-xl-stretch">
                  <Col xl={7}>
                    <CouponTitle
                      image={exclude1}
                      title="15% Off"
                      desc="Lorem ipsum"
                      contentClass="text-dark"
                    />
                  </Col>

                  <Col xl={5}>
                    <CoupontContent
                      title="Disponivel ate:"
                      desc="30 de setembro de 2021"
                      onClick={onClickCoupon}
                    />
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
          <Col xs={12} lg={6}>
            <Card className="shadow-none">
              <CardBody>
                <Row className="align-items-center align-items-xl-stretch">
                  <Col xl={7}>
                    <CouponTitle
                      image={exclude2}
                      title="15% Off"
                      desc="Lorem ipsum"
                      contentClass="text-dark"
                    />
                  </Col>

                  <Col xl={5}>
                    <CoupontContent
                      title="Disponivel ate:"
                      desc="30 de setembro de 2021"
                      onClick={onClickCoupon}
                    />
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
      <div className="">
        <Row className="mt-auto">
          <Col xs={12} lg={6}>
            <Card className="shadow-none">
              <CardBody>
                <Row className="align-items-center align-items-xl-stretch">
                  <Col xl={7}>
                    <CouponTitle
                      image={exclude3}
                      title="15% Off"
                      desc="Lorem ipsum"
                      contentClass="text-white"
                    />
                  </Col>

                  <Col xl={5}>
                    <CoupontContent
                      title="Disponivel ate:"
                      desc="30 de setembro de 2021"
                      onClick={onClickCoupon}
                    />
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
          <Col xs={12} lg={6}>
            <Card className="shadow-none">
              <CardBody>
                <Row className="align-items-center align-items-xl-stretch">
                  <Col xl={7}>
                    <CouponTitle
                      image={exclude4}
                      title="15% Off"
                      desc="Lorem ipsum"
                      contentClass="text-white"
                    />
                  </Col>

                  <Col xl={5}>
                    <CoupontContent
                      title="Disponivel ate:"
                      desc="30 de setembro de 2021"
                      onClick={onClickCoupon}
                    />
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Coupons;
