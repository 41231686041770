import { useEffect } from "react";
import {
  RectanglePageOne,
  dataTablePageFour,
  React,
  useState,
} from "../../helpers/importer.helper";
import Footer from "../Footer";
import "./styles.scss";
import {
  valuePercentCustMo,
  valueTariffPercent,
} from "../../../../../utils/formulas";
import { jurosCompostos } from "../../../../../utils";
import { useSelector } from "react-redux";
export default function PageFour({ dataPdf }) {
  const [valuesTariffPercent, setValuesTariffPercent] = useState([]);
  const [valuesPercentCustMO, setValuesPercentCustMO] = useState([]);
  const [valueKwhYearGenerate, setValueKwhYearGenerate] = useState([]);
  const [valueYearResult, setValueYearResult] = useState([]);
  const [valuePurchaseCost, setValuePurchaseCost] = useState([]);
  const [valueAccumulatedResult, setValueAccumulatedResult] = useState([]);

  const { active } = useSelector(state => ({
    active: state.Budget.active,
  }));

  useEffect(() => {
    if (active.fetch && dataPdf?.kwpPdf) {
      if (dataPdf) {
        if (Object.keys(dataPdf?.lead?.calculations).length >= 1) {
          const irradiacaoMedia =
            dataPdf?.lead?.calculations?.irradiation_kwh_day;
          const kwp = dataPdf?.kwpPdf || 0;
          const geracaoMediaAnualKwh = kwp * irradiacaoMedia * 30 * 12;
          const productPrice = parseFloat(
            dataPdf?.dataOrcamento?.orcamento.total
          );
          const getTarifa = ano =>
            jurosCompostos(
              dataPdf?.lead?.calculations
                ? Number(dataPdf.lead?.calculations["energy tariff"])
                : "",
              ano,
              0.1,
              true
            );

          const getCustoOM = ano =>
            jurosCompostos(productPrice * 0.05, ano, 0.05, true);
          const getGeracaoKwhAno = ano =>
            jurosCompostos(geracaoMediaAnualKwh, ano, 0.005, false);

          const getResultadoAnual = ano => {
            return getGeracaoKwhAno(ano) * getTarifa(ano) - getCustoOM(ano);
          };

          const getResultadoAcumulado = ano => {
            let sum = -productPrice;
            for (let i = 0; i <= ano; i++) {
              sum += getResultadoAnual(i);
            }
            return sum;
          };

          let custoEnergia = 0;
          for (let i = 0; i <= 25; i++) {
            custoEnergia += jurosCompostos(0 * 12, i, 0.1, true);
          }

          //GERA OS VALORES DO Geração kWh/ano
          const getValueKwhYearGenerate = () => {
            var arrayValues = [];
            for (var i = 0; i < dataTablePageFour.length; i++) {
              arrayValues.push(getGeracaoKwhAno(i));
            }
            return arrayValues;
          };

          setValueKwhYearGenerate(getValueKwhYearGenerate());

          //GERA OS VALORES DO RESULTADO ANUAL
          const getValueYearResult = () => {
            var arrayValues = [];
            for (var i = 0; i < dataTablePageFour.length; i++) {
              arrayValues.push(getResultadoAnual(i));
            }
            return arrayValues;
          };

          setValueYearResult(getValueYearResult());

          //GERA OS VALORES DO CUSTO AQUISIÇÃO
          const getValuePurchaseCost = () => {
            var conta = 0;
            var valor =
              Number(dataPdf?.dataOrcamento?.orcamento?.total) +
              Number(dataPdf?.dataOrcamento?.orcamento?.items[0]?.ValServ);

            var arrayValues = [];
            for (var i = 0; i < dataTablePageFour.length; i++) {
              if (i === 0) {
                arrayValues.push(-valor);
              } else {
                conta = Number(valueYearResult[i]) - valor;
                arrayValues.push(round(conta.toFixed(3), 2));
              }
            }

            return arrayValues;
          };

          setValuePurchaseCost(getValuePurchaseCost());

          //GERA OS VALORES DO RESULTADO ACUMULADO
          const getValueAccumulatedResult = () => {
            var arrayValues = [];
            for (var i = 0; i < dataTablePageFour.length; i++) {
              arrayValues.push(getResultadoAcumulado(i));
            }

            return arrayValues;
          };

          setValueAccumulatedResult(getValueAccumulatedResult());

          //GERA OS VALORES DO CUSTO O&M
          const valuePercentCustMo = () => {
            var arrayValues = [];
            for (var i = 0; i < dataTablePageFour.length; i++) {
              arrayValues.push(getCustoOM(i));
            }
            return arrayValues;
          };

          setValuesPercentCustMO(valuePercentCustMo());

          //GERA OS VALORES DA TARIFA KWH
          const valueTariffPercent = () => {
            var arrayValues = [];
            for (var i = 0; i < dataTablePageFour.length; i++) {
              arrayValues.push(getTarifa(i));
            }
            return arrayValues;
          };

          setValuesTariffPercent(valueTariffPercent());
        }
      }
    }
  }, [dataPdf?.lead, active.fetch, dataPdf?.kwpPdf]);

  // useEffect(() => {
  //   if (kwh_month_estimated_generation) {
  //     getValueKwhYearGenerate();
  //   }
  // }, [kwh_month_estimated_generation]);

  // useEffect(() => {
  //   if (valuePurchaseCost.length == dataTablePageFour.length) {
  //     getValueAccumulatedResult();
  //   }
  // }, [valuePurchaseCost]);

  // useEffect(() => {
  //   getValueYearResult();
  // }, [valueKwhYearGenerate, valuesTariffPercent]);

  // useEffect(() => {
  //   if (valueYearResult.length == dataTablePageFour.length) {
  //     getValuePurchaseCost();
  //   }
  // }, [valueYearResult]);

  const formato = {
    minimumFractionDigits: 2,
    currency: "BRL",
  };
  const kwh_month_estimated_generation =
    Number(dataPdf?.lead?.calculations?.kwp.replace(",", "")) *
    dataPdf?.lead?.calculations?.irradiation_kwh_day *
    30;

  const format = data => {
    var newData = data?.toLocaleString("pt-BR", formato);
    return newData;
  };

  //ARREDONDA NÚMEROS E DEFINE O TAMANHO DE CASAS APÓS A VÍRGULA
  const round = (num, places) => {
    if (!("" + num).includes("e")) {
      return +(Math.round(num + "e+" + places) + "e-" + places);
    } else {
      let arr = ("" + num).split("e");
      let sig = "";
      if (+arr[1] + places > 0) {
        sig = "+";
      }

      return +(
        Math.round(+arr[0] + "e" + sig + (+arr[1] + places)) +
        "e-" +
        places
      );
    }
  };

  useEffect(() => {
    valueTariffPercent();
    valuePercentCustMo();
  }, []);

  const getPurchaseCost = (key, valuePurchaseCost, valueAccumulatedResult) => {
    if (key == 0) {
      return format(valuePurchaseCost);
    } else if (Math.sign(valueAccumulatedResult) == -1) {
      return format(valueAccumulatedResult);
    } else {
      return "0";
    }
  };

  return (
    <>
      <div id="pageFour" className="scroll" />
      <div className="container-pdf-8">
        <img className="rectangle-pdf-four" src={RectanglePageOne} />

        <div className="table-div">
          <div className="header-table">Payback do Projeto</div>
          <table>
            <tr style={{ background: "#E5E5E5" }}>
              <th>Anos</th>
              <th>Tarifa kWh</th>
              <th>Geração kWh/ano</th>
              <th>Custo O&M</th>
              <th>Resultado Anual</th>
              <th>Custo Aquisição</th>
              <th>Resultado Acumulado</th>
            </tr>

            {dataTablePageFour.map((item, k) => (
              <tr key={k}>
                <td className="strong">{item.anos}</td>
                <td>
                  <span className="table-value-dollar-sign">R$</span>
                  <span className="table-value">
                    {item?.tafira ? format(valuesTariffPercent[k]) : "-"}
                  </span>
                </td>
                <td>{item?.geracao ? format(valueKwhYearGenerate[k]) : "-"}</td>
                <td>
                  <span className="table-value-dollar-sign">R$</span>
                  <span className="table-value">
                    {" "}
                    {item?.custo ? format(valuesPercentCustMO[k]) : "-"}
                  </span>
                </td>
                <td>
                  {" "}
                  <span className="table-value-dollar-sign">R$</span>
                  <span className="table-value">
                    {" "}
                    {item?.resultadoAnual ? format(valueYearResult[k]) : "-"}
                  </span>
                </td>
                <td>
                  {" "}
                  <span className="table-value-dollar-sign">R$</span>
                  <span className="table-value">
                    {item?.purchaseCost
                      ? getPurchaseCost(
                          k,
                          valuePurchaseCost[k],
                          valueAccumulatedResult[k - 1]
                        )
                      : "-"}
                  </span>
                </td>
                <td>
                  <span className="table-value-dollar-sign">R$</span>{" "}
                  <span tariffclassName="table-value">
                    {item?.resultadoAcumulado
                      ? format(valueAccumulatedResult[k])
                      : "-"}{" "}
                  </span>
                </td>
              </tr>
            ))}
          </table>
        </div>
        <p>
          O conteúdo deste estudo possui natureza meramente informativa com
          efeitos de orientação ao Cliente. O presente “Estudo de Viabilidade”
          não pode ser considerado como uma proposta, e não possui quaisquer
          efeitos vinculativos entre as Partes.
          <br />
          <br />
          Não obstante ao disposto no artigo 427 do Código Civil, este estudo é
          preliminar e não definitivo, e, como tal, não vinculativo, &quot;se o
          contrário não resultar nos termos dela&quot;. Ele é oferecido somente
          para discussão técnica e comercial, não constitui uma oferta de venda
          e/ou termo de contrato, estando o Cliente, ciente de que, após a
          visita técnica, e, sem aviso prévio, poderemos proceder com quaisquer
          ajustes que considerarmos pertinentes.
          <br />
          <br />
          As marcas e modelos dos produtos que fornecemos estão sujeitas a
          disponibilidade do estoque no momento do aceite do contrato de compra.
          Utilizamos somente marcas com excelência na qualidade, certificações
          Nacionais, Internacionais e, sempre optamos, pelo Kit Solar
          Fotovoltaico que apresentar o melhor rendimento, confiabilidade e
          esteja adequado ao espaço físico do local em que será instalado.
        </p>
        <Footer dataPdf={dataPdf} />
      </div>
    </>
  );
}
