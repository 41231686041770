import React from "react";
import "./style.css";
import { momentFormatDate } from "../../../../../utils/dateUtils";

export default function Footer({ dataPdf }) {
  return (
    <div className="footer-proposta">
      <p className="footer-p">
        Proposta emitida em:{" "}
        {momentFormatDate(dataPdf?.dataOrcamento?.orcamento?.DtEmissao)}
      </p>
      <p className="footer-p">{dataPdf?.dataOrcamento?.cliente?.nome}</p>
      <p className="footer-p">
        Proposta válida até:{" "}
        {momentFormatDate(dataPdf?.dataOrcamento?.orcamento?.Validade)}
      </p>
    </div>
  );
}
