import { ManufacturerDetailsActionTypes } from "./actionTypes";

export const manufacturerDetailsApiSuccess = (actionType, data) => ({
    type: ManufacturerDetailsActionTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, data },
  });
  
export const manufacturerDetailsApiFail = (actionType, error) => ({
    type: ManufacturerDetailsActionTypes.API_RESPONSE_ERROR,
    payload: { actionType, error },
});

export const getManufacturerDetails = manufacturerId => ({
    type: ManufacturerDetailsActionTypes.GET_MANUFACTURER_DETAILS,
    payload: manufacturerId
});