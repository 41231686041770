import React from "react";
import { Button } from "reactstrap";

const formatDate = date => {
  const split = date.split('-');
  return `${split[2]}/${split[1]}/${split[0]}`;
}

const Active = sharedResale => {
  const active = new Date(sharedResale.sharedResale.end_date) > new Date();

  return <div style={{
    width: '20px',
    height: '20px',
    borderRadius: '50%',
    backgroundColor: active ? 'green' : 'red',
  }}></div>;
}

const SharedId = sharedResale => {
  const id = sharedResale.sharedResale.id ? sharedResale.sharedResale.id : "-";
  return <div> {id} </div>;
}

const SharedSeller = sharedResale => {
  const sharedSeller =
    sharedResale.sharedResale.sharedSeller ?
      sharedResale.sharedResale.sharedSeller.CODIGO + '-' + sharedResale.sharedResale.sharedSeller.NOME :
      "-";
  return <div> {sharedSeller} </div>;
}

const ResponsibleSeller = sharedResale => {
  const responsibleSeller =
    sharedResale.sharedResale.responsibleSeller ?
      sharedResale.sharedResale.responsibleSeller.CODIGO + '-' + sharedResale.sharedResale.responsibleSeller.NOME :
      "-";
  return <div> {responsibleSeller} </div>;
}

const StartDate = sharedResale => {
  const startDate = sharedResale.sharedResale.start_date ? sharedResale.sharedResale.start_date : "-";
  return <div> {formatDate(startDate)} </div>;
}

const EndDate = sharedResale => {
  const endDate = sharedResale.sharedResale.end_date ? sharedResale.sharedResale.end_date : "-";
  return <div> {formatDate(endDate)} </div>;
}

const CreatedBy = sharedResale => {
  const createdBy = sharedResale.sharedResale.createdBy ? sharedResale.sharedResale.createdBy : "-";
  return <div> {createdBy} </div>;
}

const Action = ({ sharedResale, loadingDelete, handleClickDelete }) => {
  const active = new Date(sharedResale.end_date) > new Date();

  return <>
    {active && (
      <div>
        <Button
          className="ms-2"
          color="danger"
          size="sm"
          onClick={() => { handleClickDelete(sharedResale.id) }}
          disabled={loadingDelete}
        >
          <i className="mdi mdi-delete me-1" />
          deletar
        </Button>
      </div>
    )}
  </>
}

export { Active, SharedId, SharedSeller, ResponsibleSeller, StartDate, EndDate, CreatedBy, Action }