import React, { useEffect, useState } from "react";
import { Button, Col, Container, Row } from "reactstrap";
import { Title, Card, CardItem } from "./style.js";
import "./style.js";
import { useSolar } from "../../hooks/SolarHooks.js";

import { apiClient2 } from "../../api/apiCore2.js";
import { CheckCircle, CheckSquare, Clock, Trash, X } from "phosphor-react";
import moment from "moment";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

const Notifications = () => {
  const isSolar = useSolar();
  const [notifications, setNotificatinos] = useState([]);

  const getNotifications = async () => {
    const resp = await apiClient2.get("/notificacoes");
    setNotificatinos(resp.notificacoes);
  };

  useEffect(() => {
    getNotifications();
  }, []);

  const handleDelete = async id => {
    await apiClient2.get(`/notificacoes/delete?notification_id=${id}`);
    toast.success("Notificação excluida com sucesso! ", { theme: "coloreds" });
    getNotifications();
  };

  const markAllAsRead = async () => {
    await apiClient2.get(`/notificacoes/lida`);
    toast.success("Todas as notificações foram marcadas como lidas! ", {
      theme: "coloreds",
    });
    getNotifications();
  };

  const deleteAllNotifications = async () => {
    await apiClient2.get(`/notificacoes/deletes`);
    toast.success("Todas as notificações foram excluidas! ", {
      theme: "coloreds",
    });
    getNotifications();
  };
  return (
    <React.Fragment>
      <div className="page-content NotFound">
        <Container>
          <Card>
            <Row>
              <Col md={6} style={{ display: "flex", alignItems: "center" }}>
                <Title isSolar={isSolar}>CENTRAL DE NOTIFICAÇÕES</Title>
              </Col>
              <Col
                md={6}
                style={{ display: "flex", justifyContent: "flex-end" }}
              >
                {notifications.length > 0 && (
                  <>
                    <Button
                      style={{ marginRight: 10, fontWeight: "normal" }}
                      color="danger"
                      onClick={deleteAllNotifications}
                    >
                      <Trash size={20} color="#fff" weight="thin" />
                      Apagar todas notificações
                    </Button>
                    <Button
                      color="success"
                      style={{ fontWeight: "normal" }}
                      onClick={markAllAsRead}
                    >
                      <CheckCircle size={20} color="#fff" weight="thin" />
                      Marcar todas como lidas
                    </Button>
                  </>
                )}
              </Col>
            </Row>
            <hr />

            <Container>
              <Row>
                {notifications.map((item, k) => (
                  <CardItem
                    key={k}
                    style={{
                      backgroundColor: item.lido == 0 ? "#dff1ff" : "#f4f4f4",
                    }}
                  >
                    {" "}
                    <Col
                      md={12}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div>
                        <i className={item.type.icon_class} />{" "}
                        <Link to={isSolar ? "/solar" + item.link : item.link}>
                          <span>{item.message}</span>{" "}
                        </Link>
                      </div>
                      <X
                        style={{ cursor: "pointer", zIndex: 99 }}
                        size={15}
                        color="#000"
                        weight="thin"
                        onClick={() => handleDelete(item.id)}
                      />
                    </Col>
                    <Col
                      md={12}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        margin: 0,
                      }}
                    >
                      <Clock size={15} color="#b3bfcb" weight="bold" />
                      <p>
                        Recebida{" "}
                        {moment(
                          item.created_at,
                          "YYYY-MM-DD, h:mm:ss"
                        ).fromNow()}
                      </p>
                      {item.lido == 1 && (
                        <div className="lido">
                          {" "}
                          <CheckSquare size={15} color="#fff" weight="bold" />
                          Lida{" "}
                          {moment(
                            item.updated_at,
                            "YYYY-MM-DD, h:mm:ss"
                          ).fromNow()}
                        </div>
                      )}
                    </Col>{" "}
                  </CardItem>
                ))}
              </Row>
            </Container>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Notifications;
