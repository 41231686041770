import React, { useEffect, useState } from "react";
import { Form, Modal, Spinner } from "reactstrap";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { createUpload, isLoading } from "../../../store/closures/action";
import { CREATE_UPLOAD } from "../../../store/closures/actionTypes";
import { toast } from "react-toastify";

const SendInvoiceModel = ({
  noticemodal,
  notiseToggleModal,
  data,
  id,
  setNoticemodalmodal,
  setAttInfos,
  attInfos,
}) => {
  const [state, setState] = useState({
    id: "",
    nome: "",
    cnpj: "",
    banco: "",
    agencia: "",
    contaCorrente: "",
    arquivo: "",
    passoApasso: false,
    concordo: false,
  });
  const [fileName, setFileName] = useState("Nenhum arquivo selecionado");
  const dispatch = useDispatch();
  const { loadingUpload } = useSelector(state => state.Closures);

  useEffect(() => {
    if (data) {
      setState({
        id: id,
        nome: data?.fornecedor?.NOME,
        cnpj: data?.fornecedor?.CGC,
        banco: data?.fornecedor?.BANCO,
        agencia: data?.fornecedor?.AGENCIA,
        contaCorrente: data?.fornecedor?.NUMERO_CONTA,
        arquivo: "",
        passoApasso: false,
        concordo: false,
      });
    }
  }, [data]);

  const getFile = obj => {
    var file = obj.target.value;
    var fileName = file.split("\\");
    let validos = /(\.jpg|\.pdf)$/i;

    let nome = obj?.target?.files[0]?.name;
    if (!validos?.test(nome)) {
      return toast.error("Esse tipo de arquivo não é suportado!", {
        theme: "colored",
      });
    }

    if (obj.target.files[0].size > 10485760) {
      return toast.error("Esse tipo de arquivo não é suportado!", {
        theme: "colored",
      });
    }

    setFileName(fileName[fileName.length - 1]);
    setState({ ...state, arquivo: obj.target.files[0] });
  };

  const handleSubmit = e => {
    e.preventDefault();
    if (state.arquivo == "") {
      return toast.error("Selecione um arquivo!", { theme: "colored" });
    }
    const dataObject = {
      fechamento: state.id,
      favorecido: state.nome,
      cnpj: state.cnpj,
      file1: state.arquivo,
      euli: state.passoApasso,
      euconcordobanc: state.concordo,
    };
    dispatch(isLoading(CREATE_UPLOAD));
    dispatch(createUpload(dataObject, setNoticemodalmodal));
    setAttInfos(!attInfos);
  };
  return (
    <React.Fragment>
      <Modal size="lg" isOpen={noticemodal} toggle={notiseToggleModal}>
        {" "}
        <form onSubmit={handleSubmit}>
          <div className="modal-header">
            <div>
              <h5 className="modal-title mt-0 mb-3" id="myModalLabel">
                <i className="mdi mdi-cloud-download"></i> Upload de Nota Fiscal
              </h5>
              <p className="mb-1">
                Somente no formato <strong>PDF</strong> ou imagem JPG ate{" "}
                <strong>10MB.</strong>
              </p>
              <p className="text-primary">
                Lembre-se de ler o nosso{" "}
                <Link
                  to="/conhecimento/7"
                  className="text-success cursor-pointer"
                >
                  passo a passo{" "}
                </Link>
                antes de emitir sua nota fiscal.
              </p>{" "}
            </div>

            <button type="button" onClick={notiseToggleModal} className="close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <h5 className="mb-3">Conta para pagamento : </h5>
            <Form>
              <div className="mb-3">
                <label className="form-label">Favorecido :</label>
                <input
                  type="text"
                  className="form-control color-primary"
                  value={state.nome}
                  disabled
                />
              </div>
              <div className="mb-3">
                <label className="form-label">CNPJ :</label>
                <input
                  type="text"
                  className="form-control bg-light"
                  value={state.cnpj}
                  disabled
                />
              </div>
              <div className="mb-3">
                <label className="form-label">Banco :</label>
                <input
                  type="text"
                  className="form-control bg-light"
                  value={state.banco}
                  disabled
                />
              </div>
              <div className="mb-3">
                <label className="form-label">Agencia :</label>
                <input
                  type="text"
                  className="form-control bg-light"
                  value={state.agencia}
                  disabled
                />
              </div>
              <div className="mb-3">
                <label className="form-label">Conta Corrente :</label>
                <input
                  type="text"
                  className="form-control"
                  value={state.contaCorrente}
                  disabled
                />
              </div>
            </Form>
            <hr />
            <div>
              <h5 className="text-primary">
                <i className="mdi mdi-alert me-2"></i>Duvidas sobre como emitir
                sua RT? Leia nosso{" "}
                <Link
                  to="/conhecimento/7"
                  className="text-success cursor-pointer"
                >
                  Passo a Passo{" "}
                </Link>
              </h5>
              <p>
                Para alterar a conta de pagamento de RT, entre em contato
                conosco pelo{" "}
                <Link
                  to="/resolva/financeiro"
                  className="text-success cursor-pointer"
                >
                  Resolva Aqui
                </Link>
              </p>
              <h5 className="mb-3">Nota Fiscal de Servico : </h5>
              <div style={{ height: "100%" }} className="d-flex ">
                <div
                  style={{
                    borderTopLeftRadius: "0.25rem",
                    borderBottomLeftRadius: "0.25rem",
                  }}
                  className="position-relative bg-primary cursor-pointer"
                >
                  <p
                    style={{ top: 8, right: 21 }}
                    className="position-absolute m-0"
                  >
                    Escolher Arquivo
                  </p>
                  <input
                    type="file"
                    className="opacity-0 cursor-pointer "
                    style={{ maxWidth: 150 }}
                    onChange={getFile}
                  />
                </div>
                <input
                  type="text"
                  value={fileName}
                  className="form-control border"
                  disabled
                />
              </div>
              <div className="mt-3 mb-3">
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value=""
                    id="flexCheckDefault"
                    checked={state.passoApasso}
                    onClick={() =>
                      setState({ ...state, passoApasso: !state.passoApasso })
                    }
                  />
                  <label
                    className="form-check-label"
                    htmlFor="flexCheckDefault"
                  >
                    Li este <span className="text-success">passo a passo</span>{" "}
                    e a Nota Fiscal esta de{" "}
                    <span className="text-success">acordo</span>
                  </label>
                </div>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value=""
                    id="flexCheckChecked"
                    checked={state.concordo}
                    onClick={() =>
                      setState({ ...state, concordo: !state.concordo })
                    }
                  />
                  <label
                    className="form-check-label"
                    htmlFor="flexCheckChecked"
                  >
                    Concordo com os{" "}
                    <span className="text-success">dados bancarios</span> a cima
                    para realizacao do pagamento
                  </label>
                </div>
              </div>
              <p className="text-muted mb-4">*Obrigatorio</p>
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-cancel"
              onClick={notiseToggleModal}
            >
              Cancelar{" "}
            </button>
            <button
              type="submit"
              className="btn btn-primary btn-submit"
              style={{ minWidth: 120 }}
              disabled={state.passoApasso && state.concordo ? false : true}
            >
              {loadingUpload ? (
                <Spinner
                  style={{
                    width: 15,
                    height: 15,
                    marginBottom: -2,
                  }}
                />
              ) : (
                "Enviar Nota Fiscal"
              )}
            </button>
          </div>{" "}
        </form>
      </Modal>
    </React.Fragment>
  );
};

export default SendInvoiceModel;
