import React, { useState, useEffect } from "react";
import Flatpickr from "react-flatpickr";
import { useDispatch } from "react-redux";
import {
  Row,
  Col,
  InputGroupText,
  InputGroup,
  Input,
  Button,
} from "reactstrap";
import { Portuguese } from "flatpickr/dist/l10n/pt.js";
import { mask } from "../../../../../utils";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import { getVendedor as getVendedorApi } from "../../../../../api/index";
import "flatpickr/dist/themes/material_blue.css";
import { listBudgets } from "../../../../../store/budget/action";
import { useSolar } from "../../../../../hooks";
import { toast } from "react-toastify";
import { inversor, modulo, peca } from "../../register/mockAndValidation";
import Inversor from "./inversor";
import Modulo from "./modulo";
import Peca from "./peca";

import "./index.scss";

const OrcamentoFilter = ({ isOpen, optionsData, setOptionsData, getData }) => {
  const [optionsSelect] = useState([
    { id: 1, name: inversor },
    { id: 2, name: modulo },
    { id: 3, name: peca },
  ]);
  const [filters, setFilters] = useState({
    nome: "",
    fabricante: "",
    potenciaOverload: "",
    grid: "",
    categoria: "",
  });
  const isSolar = useSolar();

  const buscaOrcamentos = () => {
    if (
      filters.nome == "" &&
      filters.fabricante == "" &&
      filters.potenciaOverload == "" &&
      filters.grid == "" &&
      filters.categoria == ""
    ) {
      return toast.info(
        `Não é possível buscar todos os produtos. Utilize pelo menos um filtro.`,
        { theme: "colored" }
      );
    }
    getData(
      optionsData,
      filters.nome,
      filters.fabricante,
      filters.potenciaOverload,
      filters.grid,
      filters.categoria
    );
  };

  const limparFiltros = () => {
    setFilters({
      nome: "",
      fabricante: "",
      potenciaOverload: "",
      grid: "",
      categoria: "",
    });
    getData(optionsData);
  };

  return (
    <Row className="card-principal" style={{ gap: 20 }} hidden={isOpen}>
      <Col className="engloba-vendedor">
        <Row className="linha-titulo-vendedor">
          <h4 className="titulos">Tipo de produto:</h4>
        </Row>
        <Row className="engloba-vendedor-codigo">
          <Col className="busca-vendedor" md={6}>
            <div className="select">
              <div className="select-icon">
                <i className="ph-selection-bold"></i>
              </div>
              <select
                className="form-select"
                aria-label="Filter select"
                name="options"
                value={optionsData}
                onChange={e => setOptionsData(e.target.value)}
                style={{ border: "none", width: "100%" }}
              >
                {optionsSelect?.map(item => {
                  return (
                    <option key={item.id} value={item.name}>
                      {item?.name}
                    </option>
                  );
                })}
              </select>
            </div>
          </Col>
        </Row>{" "}
      </Col>
      {optionsData == inversor && (
        <Inversor filters={filters} setFilters={setFilters} />
      )}
      {optionsData == modulo && (
        <Modulo filters={filters} setFilters={setFilters} />
      )}
      {optionsData == peca && (
        <Peca filters={filters} setFilters={setFilters} />
      )}

      <Row className="linha-botao">
        <Col className="engloba-botoes md-12" style={{ gap: 20 }}>
          <Button className="botao-limpar" onClick={() => limparFiltros()}>
            {" "}
            <i className="ph-eraser borracha"></i>Limpar
          </Button>
          <Button
            style={{
              backgroundColor: "#FF601A",
              color: "#fff",
              display: "flex",
              alignItems: "center",
            }}
            onClick={buscaOrcamentos}
          >
            {" "}
            <i className="ph-magnifying-glass lupa" />
            Buscar Produto
          </Button>
        </Col>
      </Row>
    </Row>
  );
};

export default OrcamentoFilter;
