import React from "react";
import { Link } from "react-router-dom";
import { Row, Col, Card, CardBody } from "reactstrap";
import { useColors } from "../hooks/useColors";

const Breadcrumb = ({ breadcrumbItem, title }) => {
  const { colorDefault } = useColors();
  return (
    <Row>
      <Col className="col-12">
        <Card style={{ marginBottom: 30 }}>
          <CardBody>
            <div className="d-flex aligm-items-start">
              <Link to="#" style={{ color: colorDefault }}>
                {title}
              </Link>
              <Link to="#" style={{ margin: "0 10px", color: colorDefault }}>
                <i className="fas fa-chevron-right"></i>
              </Link>
              <Link style={{ color: colorDefault }} to="#">
                <strong>{breadcrumbItem}</strong>
              </Link>
            </div>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

export default Breadcrumb;
