import { RegistrationsActionTypes } from "./actionTypes";

export const registrationApiSuccess = (actionType, data) => ({
  type: RegistrationsActionTypes.API_RESPONSE_SUCCESS,
  payload: { actionType, data },
});

export const registrationApiFail = (actionType, error) => ({
  type: RegistrationsActionTypes.API_RESPONSE_ERROR,
  payload: { actionType, error },
});

export const getRegistrationList = filters => ({
  type: RegistrationsActionTypes.GET_REGISTRATION_LIST,
  payload: filters,
});

export const getRegistration = id => ({
  type: RegistrationsActionTypes.GET_REGISTRATION,
  payload: id,
});

export const getSuperiorRevenda = id => ({
  type: RegistrationsActionTypes.GET_SUPERIOR_REVENDA,
  payload: id,
});

export const createOrUpdateRegistration = (revendaData, history) => ({
  type: RegistrationsActionTypes.CREATE_UPDATE_REGISTRATION,
  payload: { data: revendaData, history: history },
});
