/** uppercase and lower case method */
const strToLowerCase = str => {
  return str.toLowerCase();
};

const strToUpperCase = str => {
  return str.toUpperCase();
};

const nl2br = (str, is_xhtml) => {
  var breakTag =
    is_xhtml || typeof is_xhtml === "undefined" ? "<br />" : "<br>";
  return (str + "").replace(
    /([^>\r\n]?)(\r\n|\n\r|\r|\n)/g,
    "$1" + breakTag + "$2"
  );
};

const removeXspaces = string => {
  var str = "";
  if (string && string !== undefined) {
    str = string.trim();
  }
  return str;
};

const capitalizeFirstLetter = s => {
  return s[0].toUpperCase() + s.slice(1);
};

const removeLast = (string, character, replacement) => {
  const lastIndex = string.lastIndexOf(character);
  if (lastIndex === -1) return string;
  const replaced =
    string.substring(0, lastIndex) +
    replacement +
    string.substring(lastIndex + 1);
  return replaced;
};

// Função para remover acentos e converter strings para caracteres minúsculos 
const normalizeString = (string) => {
  let normalizedStr = string.toLowerCase();

  normalizedStr = normalizedStr.normalize("NFD").replace(/[\u0300-\u036f]/g, "");

  return normalizedStr;
}

export {
  strToLowerCase,
  strToUpperCase,
  nl2br,
  removeXspaces,
  capitalizeFirstLetter,
  removeLast,
  normalizeString
};
