import React from "react";
import { Col, Row } from "reactstrap";

const Switch = ({
  textLabel,
  setChecked,
  checked,
  showCheckInString = false
}) => {
  return (
    <Col>
      <div className="d-flex align-items-center">
        <label className="m-0">
          {textLabel}
        </label>
        <div className="ms-2 form-check form-switch">
          <input
            className="form-check-input"
            type="checkbox"
            role="switch"
            onChange={() => setChecked(!checked)}
            checked={checked}
          />
          <label
            className="form-check-label"
            htmlFor="aplicaFrete"
            style={{ color: "#9f9f9f" }}
          >
            {showCheckInString ? checked ? "Sim" : "Não" : ""}
          </label>
        </div>
      </div>
    </Col>
  );
}

export default Switch;