import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getLeadDetails } from "../../../../store/actions";

import { Card, CardBody, Spinner } from "reactstrap";
import Breadcrumb from "../../../../components/Breadcrumb";
import LeadForm from "./LeadForm";
import LeadShow from "./LeadShow";
import { useParams } from "react-router-dom";

const Lead = () => {
  const { id } = useParams();

  const dispatch = useDispatch();

  const type = location.pathname.includes("/editar") ? "edit" : "ver";

  const { leadDetails, getLeadDetailLoading } = useSelector(state => {
    return {
      leadDetails: state.Leads.leadDetails,
      getLeadDetailLoading: state.Leads.getLeadDetailLoading,
    };
  });

  useEffect(() => {
    dispatch(getLeadDetails(id));
  }, []);

  return (
    <div className="page-content">
      <div className="container">
        <Breadcrumb
          title="Página Inicial"
          breadcrumbItem={`${type === "ver" ? "Visualizar" : "Editar"} Lead`}
        />
        {getLeadDetailLoading ? (
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Spinner />
          </div>
        ) : (
          <Card className="cardprincipal">
            <CardBody className="card-body">
              {type === "ver" ? (
                <LeadShow leadInfo={leadDetails} />
              ) : (
                <LeadForm leadInfo={leadDetails} />
              )}
            </CardBody>
          </Card>
        )}
      </div>
    </div>
  );
};

export default Lead;
