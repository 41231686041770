import React from "react";
export default function IconTrash(props) {
  const colorStroke = props?.colorStroke || "#000";
  const width = props?.width || 30;
  const height = props?.height || 30;
  return (
    <svg
      fill={colorStroke}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 30 30"
      width={width}
      height={height}
    >
      <path
        fill="none"
        stroke={colorStroke}
        strokeLinecap="round"
        strokeMiterlimit="10"
        strokeWidth="2"
        d="M6 5L24 5M13 4L17 4"
      />
      <path d="M6,8v16c0,1.105,0.895,2,2,2h14c1.105,0,2-0.895,2-2V8H6z" />
    </svg>
  );
}
