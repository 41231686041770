/* eslint-disable no-undef */
import React, { useState, useEffect, createRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  CardBody,
  Col,
  Row,
  Card,
  FormGroup,
  Label,
  Input,
  Button,
} from "reactstrap";
import { AsyncTypeahead, Typeahead } from "react-bootstrap-typeahead";
import { getVendedor as getVendedorApi } from "../../api/index";
import {
  getNewOrder,
  changeGroup,
  changeCategory,
  changePagto,
  getOrderProducts,
  zerarCarrinho,
  atualizaCarrinho,
} from "../../store/actions";
import { mask, returnLogo } from "../../utils";
import { useParams } from "react-router-dom";
import AdvancedFilter from "./AdvancedFilter";
import Ambients from "./Ambients";
import { toast } from "react-toastify";

const OrderFilter = ({
  ocultarRt,
  changeOcultarRt,
  changePagtoSelected,
  addFilterPedido,
  filterPedido,
}) => {
  const { id } = useParams();
  const catSelector = createRef();
  const gruSelector = createRef();
  const dispatch = useDispatch();
  const { newOrder, order } = useSelector(state => ({
    newOrder: state.Order.newOrder.newOrder,
    order: state.Order.orderActive.order,
  }));

  const [form, setForm] = useState({
    capacidade: "",
    categoria: "",
    condpagto: "",
    fabricante: "",
    grupos: "",
    ciclo: "",
    tabela_preco: "",
    voltagem: "",
    estoque: "",
    descricao: "",
    bitolaliq: "",
    bitolasuc: "",
    codfabric: "",
  });
  const [filters, setFilters] = useState({
    vendedores: [{ codigo: "", label: "" }],
    tabela: [],
    condPagto: [],
    grupos: [],
    categorias: [],
    capacidade: [],
    voltagem: [],
    fabricantes: [],
  });
  const [initial, setInitial] = useState([]);
  const [parceiros, setParceiros] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [capVisible, setCapVisible] = useState(false);
  const [fabVisible, setFabVisible] = useState(false);
  const [capSelected, setCapSelected] = useState([]);
  const [fabSelected, setFabSelected] = useState([]);
  const [cicSelected, setCicSelected] = useState([]);
  const [volSelected, setVolSelected] = useState([]);
  const [tabela, setTabela] = useState([]);
  const [pagamento, setPagamento] = useState([]);
  const [grupo, setGrupo] = useState([]);
  const [categoria, setCategoria] = useState([]);
  const [ambientName, setAmbientName] = useState("");
  const [count, setCount] = useState(0);
  const [varEscolha, setVarEscolha] = useState([]);
  const [allAmbients, setAllAmbients] = useState([]);
  const [selectedDropdown, setSelectedDropdown] = useState("");

  const fabs = ["GR", "LG", "EL", "DA", "TR", "SM"];
  const [countFunction, setCountFuction] = useState(0);
  const filterBy = () => true;

  const changeVendedores = inputValue => {
    setIsLoading(true);
    setFilters({ ...filters, vendedores: [] });

    getVendedorApi(inputValue)
      .then(res => {
        let data = [];
        res.map(value => {
          data.push({
            codigo: value.CODIGO,
            nome: value.NOME,
            label: `${value.CODIGO} - ${value.NREDUZ.trim()}`,
            nreduz: value.NREDUZ,
            cnpj: value.CPFCNPJ,
          });
        });

        setFilters({ ...filters, vendedores: data });
        setIsLoading(false);
      })
      .catch(console.log);
  };

  function getProducts(e) {
    e.preventDefault();
    var count = countFunction + 1;
    var myDiv = document.getElementById("sentinel");
    let Coordinates = myDiv.getBoundingClientRect();
    const start = Coordinates.top;
    const end = Coordinates.bottom;

    dispatch(getOrderProducts(form));

    if (count <= 1) {
      if (start >= 0 && end <= window.innerHeight) {
      } else {
        window.scrollTo(0, Coordinates.y + 200);
      }
    }
    setCountFuction(state => state + 1);
  }

  function addAmbients() {
    let formCopy = JSON.parse(JSON.stringify(form));
    formCopy.ambient_name = ambientName;
    setAllAmbients(prev => [...prev, formCopy]);
  }

  function clearCheckboxes() {
    let capacidade = document.getElementsByName("capacidade");
    for (let cap of capacidade) {
      cap.checked = false;
    }
    let ciclo = document.getElementsByName("ciclo");
    for (let cic of ciclo) {
      cic.checked = false;
    }
    let voltagem = document.getElementsByName("voltagem");
    for (let vol of voltagem) {
      vol.checked = false;
    }
    let fabricante = document.getElementsByName("fabricante");
    for (let fab of fabricante) {
      fab.checked = false;
    }
    form.capacidade = "";
    form.fabricante = "";
    form.ciclo = "";
    form.voltagem = "";

    setCapSelected([]);
    setCicSelected([]);
    setFabSelected([]);
    setVolSelected([]);
  }

  useEffect(() => {
    if (
      !(
        newOrder.tabela &&
        newOrder.condPagto &&
        newOrder.grupos &&
        newOrder.categorias
      )
    )
      dispatch(getNewOrder());
  }, [dispatch]);

  useEffect(() => {
    if (newOrder) {
      let tab = [];
      Object.keys(newOrder.tabela || {}).map(function (key) {
        tab.push({ codigo: key, label: newOrder.tabela[key].trim() });
      });

      let pag = [];
      let pagto = Object.values(newOrder.condPagto || []);
      let pag_keys = Object.keys(newOrder.condPagto || []);
      for (let i = pagto.length; i >= 0; i--) {
        Object.keys(pagto[i] || {}).map(function (key) {
          pag.push({
            codigo: key,
            label: pagto[i][key].name.trim(),
            type: pag_keys[i],
          });
        });
      }

      let gru = [];
      Object.values(newOrder.grupos || {}).map(value => {
        gru.push({ codigo: value.CODIGO.trim(), label: value.DESCR.trim() });
      });

      let cat = [];
      Object.values(newOrder.categorias || []).map(value => {
        cat.push({ codigo: value.CODIGO.trim(), label: value.DESCR.trim() });
      });

      let cap = Object.values(newOrder.capacidade || []);
      let volt = Object.values(newOrder.voltagem || []);
      let fab = Object.values(newOrder.fabricantes || []);

      setFilters({
        ...filters,
        tabela: tab,
        condPagto: pag,
        grupos: gru,
        categorias: cat,
        capacidade: cap,
        voltagem: volt,
        fabricantes: fab,
      });

      if (count <= 5) {
        setInitial({
          tabela: tab,
          condPagto: pag,
          grupos: gru,
          categorias: cat,
          capacidade: cap,
          voltagem: volt,
          fabricantes: fab,
        });

        if (id && order) {
          if (tabela.length < 1 && tab[0]?.codigo) {
            let result = tab.find(i => i.codigo == order.TabelaPreco);
            if (result) setTabela([result]);
          }

          if (pagamento.length < 1 && pag[0]?.codigo) {
            let result = pag.find(i => i.codigo == order.CondicaoPagamento);
            if (result) setPagamento([result]);
          }
        } else {
          if (tabela.length < 1 && tab[0]?.codigo) {
            let result = tab.find(i => i.codigo == newOrder.tabelaInicial);
            if (result) setTabela([result]);
          }

          if (pagamento.length < 1 && pag[0]?.codigo) {
            let result = pag.find(i => i.codigo == "123");
            if (result) setPagamento([result]);
          }
        }

        if (grupo.length < 1 && gru[0]?.codigo) {
          let result = gru.find(i => i.codigo == "INV");
          if (result) setGrupo([result]);
        }

        if (categoria.length < 1 && cat[0]?.codigo) {
          let result = cat.find(i => i.codigo == "HIW");
          if (result) setCategoria([result]);
        }
        setForm({
          ...form,
          tabela_preco: newOrder.tabelaInicial,
          condpagto: "123",
          grupos: "INV",
          categoria: "HIW",
        });
      }

      setCount(count + 1);
    }
  }, [newOrder]);

  useEffect(() => {
    let table = "";
    if (tabela.length > 0) {
      table = tabela[0].codigo;
      dispatch(changePagto(table));
    }
    setForm({ ...form, tabela_preco: table });
  }, [tabela]);

  useEffect(() => {
    let pagto = "";
    if (pagamento.length > 0) {
      pagto = pagamento[0]?.codigo || "";
      addFilterPedido({
        ...filterPedido,
        Nomecondpagto: pagamento[0]?.label || "",
        condpagto: pagamento[0]?.codigo || "",
        Nometabela_preco: tabela[0]?.label || "",
        tabela_preco: tabela[0]?.codigo || "",
        vendedor2: parceiros[0]?.codigo || "",
      });
    }
    setForm({ ...form, condpagto: pagto });
    changePagtoSelected(pagamento);
  }, [pagamento]);

  useEffect(() => {
    let gru = "";
    if (grupo.length > 0) {
      gru = grupo[0].codigo;
      if (count > 6) {
        setCategoria([]);
      }
      setFilters({
        ...filters,
        capacidade: initial.capacidade,
      });
      dispatch(changeGroup(gru));
    } else {
      if (filters.length > 0) {
        setFilters({
          ...filters,
          capacidade: initial.capacidade,
          categorias: initial.categorias,
        });
      }
    }
    // if(catSelector.current)
    catSelector.current.clear();
    clearCheckboxes();
    setForm({
      ...form,
      grupos: gru,
      categoria: "",
    });
  }, [grupo]);

  useEffect(() => {
    let cat = "";
    if (categoria.length > 0) {
      cat = categoria[0].codigo;
      if (grupo[0] && grupo[0].codigo) {
        let paramns = {
          group: grupo[0].codigo,
          category: cat,
        };

        dispatch(changeCategory(paramns));
      } else {
        setCategoria([]);
      }
    } else {
      if (filters.length > 0) {
        setFilters({
          ...filters,
          capacidade: initial.capacidade,
        });
      }
    }
    clearCheckboxes();
    setForm({ ...form, categoria: cat });
  }, [categoria]);

  return (
    <Row id="filter">
      <Col>
        <Card>
          <CardBody className="filter-order">
            <Row>
              <Col md={8}>
                <FormGroup>
                  <Label className="label-titulo">Selecione o Parceiro:</Label>
                  <Row>
                    <Col
                      md={6}
                      className="d-flex justify-content-end align-items-center"
                    >
                      <AsyncTypeahead
                        id="parceiro"
                        className="parceiro-input"
                        onChange={setParceiros}
                        options={filters.vendedores}
                        placeholder="Pesquisar..."
                        selected={parceiros}
                        minLength={3}
                        filterBy={filterBy}
                        onSearch={changeVendedores}
                        emptyLabel="Não foi encontrado nenhum vendedor"
                        promptText="Buscando vendedores"
                        searchText="Buscando vendedores"
                        isLoading={isLoading}
                        inputProps={{
                          id: "parceiro-selecionado",
                        }}
                        renderMenuItemChildren={option => (
                          <div>
                            {option.codigo} {option.nreduz}
                            <div>
                              <small>{option.nome}</small>
                            </div>
                          </div>
                        )}
                      />
                      {isLoading || (
                        <i className="fas fa-search campo-buscar"></i>
                      )}
                    </Col>
                    <Col md={6}>
                      <Input
                        placeholder="CPF/CNPJ Vendedor"
                        value={mask(parceiros[0]?.cnpj || "")}
                        disabled={true}
                        readOnly
                      />
                    </Col>
                  </Row>
                </FormGroup>
              </Col>
              {newOrder.comRt < 1 || (
                <Col md={2} className="ml-auto">
                  <FormGroup check className="d-flex justify-content-end">
                    <Input
                      type="checkbox"
                      checked={ocultarRt}
                      className="ocultar-rt"
                      onChange={() => changeOcultarRt()}
                    />
                    <Label check className="text-indigo ocultar">
                      Ocultar valor RT
                    </Label>
                  </FormGroup>
                </Col>
              )}
            </Row>
            <Row className="mt-4">
              <Col md={5}>
                <FormGroup>
                  <Label className="label-titulo">Tabela:</Label>
                  <Typeahead
                    clearButton
                    id="select-tabela"
                    labelKey="label"
                    onFocus={() => {
                      setTabela([]);
                      setVarEscolha(tabela);
                    }}
                    onBlur={() => {
                      if (tabela.length < 1) setTabela(varEscolha);
                    }}
                    onChange={setTabela}
                    options={filters.tabela}
                    placeholder="Escolha a tabela de preço:"
                    selected={tabela}
                  />
                </FormGroup>
              </Col>
              <Col md={5}>
                <FormGroup>
                  <Label className="label-titulo">Pagamento:</Label>
                  <Typeahead
                    clearButton
                    id="select-condpagto"
                    labelKey="label"
                    onFocus={() => {
                      setPagamento([]);
                      setVarEscolha(pagamento);
                    }}
                    onBlur={() => {
                      if (pagamento.length < 1) setPagamento(varEscolha);
                    }}
                    onChange={e => {
                      if (newOrder.addedItems.length > 0) {
                        if (confirm) {
                          setPagamento(e);
                          toast.info(
                            `Ao atualizar a condição de pagamento, os valores dos produtos são atualizados e podem sofrer alterações.`,
                            { theme: "colored" }
                          );
                          dispatch(
                            atualizaCarrinho({
                              pgto: e,
                              tabela,
                            })
                          );
                        } else {
                          setPagamento(pagamento);
                        }
                      } else {
                        setPagamento(e);
                        dispatch(zerarCarrinho());
                      }
                    }}
                    options={filters?.condPagto || []}
                    placeholder="Escolha a condição de pagamento:"
                    selected={pagamento}
                    renderMenuItemChildren={option => (
                      <div>
                        {option.label}
                        <div>
                          <small>{option.type}</small>
                        </div>
                      </div>
                    )}
                  />
                </FormGroup>
              </Col>
            </Row>
            <hr className="linha" />
            <Row>
              <Col md={6}>
                <h4 className="subtitulo">Dados do ambiente:</h4>
              </Col>
              <Col md={6}>
                <Button
                  color="light"
                  style={{
                    color: "#0067B4",
                    backgroundColor: "#FFF",
                    borderColor: "#F5F5F5",
                    borderWidth: "2px",
                    display: "flex",
                    width: "fit-content",
                    marginLeft: "auto",
                  }}
                  onClick={() => {
                    gruSelector.current.clear();
                    catSelector.current.clear();
                    clearCheckboxes();
                    setForm({
                      ...form,
                      grupos: "",
                      categoria: "",
                      capacidade: "",
                      fabricante: "",
                      ciclo: "",
                      voltagem: "",
                    });
                    setFilters({
                      ...filters,
                      capacidade: initial.capacidade,
                      categorias: initial.categorias,
                    });
                  }}
                >
                  Limpar Filtros <i className="fa fa-times ocultar"></i>
                </Button>
              </Col>
            </Row>
            <Row className="mt-4">
              <Col md={4}>
                <FormGroup>
                  <Label className="label-titulo">Grupo:</Label>
                  <Typeahead
                    clearButton
                    id="select-grupo"
                    labelKey="label"
                    onChange={setGrupo}
                    options={filters.grupos}
                    placeholder="Escolha o grupo de produtos:"
                    ref={gruSelector}
                    selected={grupo}
                    onFocus={() => {
                      setGrupo([]);
                      setVarEscolha(grupo);
                    }}
                    onBlur={() => {
                      if (grupo.length < 1) setGrupo(varEscolha);
                    }}
                  />
                </FormGroup>
              </Col>
              <Col md={4}>
                <FormGroup>
                  <Label className="label-titulo">Categoria:</Label>
                  <Typeahead
                    clearButton
                    id="select-categoria"
                    labelKey="label"
                    onChange={setCategoria}
                    options={filters.categorias}
                    placeholder="Escolha a categoria do produto:"
                    ref={catSelector}
                    selected={categoria}
                    onFocus={() => {
                      setCategoria([]);
                      setVarEscolha(categoria);
                    }}
                    onBlur={() => {
                      if (categoria.length < 1) setCategoria(varEscolha);
                    }}
                  />
                </FormGroup>
              </Col>
              <Col md={4}>
                <FormGroup>
                  <Label className="label-titulo">Ambiente:</Label>
                  <Input
                    value={ambientName}
                    onChange={e => setAmbientName(e.target.value)}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row className="mt-4">
              <Col>
                <FormGroup>
                  <Row className="d-flex align-items-center mb-3">
                    <Col md={2}>
                      <Label className="label-titulo mb-0">Capacidade:</Label>
                    </Col>
                    <Col md={8} className="d-flex flex-wrap">
                      {capSelected.map(value => (
                        <p
                          className="px-3 rounded mb-0 cap-selected"
                          key={value.id}
                        >
                          <small>
                            {value.label}
                            <i
                              className="far fa-times-circle"
                              onClick={() => {
                                let lab = [...capSelected];
                                let val =
                                  form.capacidade.length > 0
                                    ? form.capacidade.split(",")
                                    : [];
                                const indexLab = lab.indexOf(value);
                                const index = val.indexOf(value.id);
                                if (indexLab > -1) {
                                  lab.splice(indexLab, 1);
                                }
                                if (index > -1) {
                                  val.splice(index, 1);
                                }

                                document.getElementById(
                                  `cap-${value.id}`
                                ).checked = false;
                                setCapSelected(lab);
                                setForm({ ...form, capacidade: val.join() });
                              }}
                              style={{ marginLeft: 5 }}
                            ></i>
                          </small>
                        </p>
                      ))}
                    </Col>
                  </Row>
                  <Row>
                    {filters.capacidade.map((value, index) => (
                      <div
                        style={{ width: "20%", marginTop: 25 }}
                        key={index}
                        hidden={index < 10 ? false : !capVisible}
                      >
                        <Input
                          type="checkbox"
                          value={("00" + value.CODIGO).slice(-2)}
                          name="capacidade"
                          aria-label={value.DESCR}
                          id={`cap-${value.CODIGO}`}
                          onClick={e => {
                            let val =
                              form.capacidade.length > 0
                                ? form.capacidade.split(",")
                                : [];
                            let lab = [...capSelected];
                            if (e.target.checked) {
                              val.push(e.target.value);
                              lab.push({
                                id: e.target.value,
                                label: e.target.ariaLabel,
                              });
                            } else {
                              const index = val.indexOf(e.target.value);
                              const result = lab.find(
                                i => i.id === e.target.value
                              );
                              const indexLab = lab.indexOf(result);
                              if (index > -1) {
                                val.splice(index, 1);
                              }
                              if (indexLab > -1) {
                                lab.splice(indexLab, 1);
                              }
                            }
                            setForm({ ...form, [e.target.name]: val.join() });
                            setCapSelected(lab);
                          }}
                        />{" "}
                        <Label check className="text-indigo ocultar">
                          {value.DESCR}
                        </Label>
                      </div>
                    ))}
                  </Row>
                  {filters.capacidade.length > 8 ? (
                    <a
                      href="#"
                      onClick={e => {
                        e.preventDefault();
                        setCapVisible(!capVisible);
                      }}
                      className="ver-todos"
                    >
                      Ver todas as opções
                      <i className="fas fa-chevron-right"></i>
                    </a>
                  ) : (
                    <div />
                  )}
                </FormGroup>
              </Col>
            </Row>
            <Row className="mt-4">
              <div style={{ width: "40%" }}>
                <FormGroup>
                  <Row className="d-flex align-items-center mb-3">
                    <Col md={2}>
                      <Label className="label-titulo mb-0">Ciclo:</Label>
                    </Col>
                    <Col md={8} className="d-flex flex-wrap">
                      {cicSelected.map(value => (
                        <p
                          className="px-3 rounded mb-0 cap-selected"
                          key={value.id}
                        >
                          <small>
                            {value.label}
                            <i
                              className="far fa-times-circle"
                              onClick={() => {
                                let lab = [...cicSelected];
                                let val =
                                  form.ciclo.length > 0
                                    ? form.ciclo.split(",")
                                    : [];
                                const indexLab = lab.indexOf(value);
                                const index = val.indexOf(value.id);
                                if (indexLab > -1) {
                                  lab.splice(indexLab, 1);
                                }
                                if (index > -1) {
                                  val.splice(index, 1);
                                }

                                document.getElementById(
                                  `cic-${value.id}`
                                ).checked = false;
                                setCicSelected(lab);
                                setForm({ ...form, ciclo: val.join() });
                              }}
                              style={{ marginLeft: 5 }}
                            ></i>
                          </small>
                        </p>
                      ))}
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6} style={{ marginTop: 25 }}>
                      <Input
                        type="checkbox"
                        name="ciclo"
                        aria-label="Frio"
                        value="F"
                        id={`cic-F`}
                        onClick={e => {
                          let val =
                            form.ciclo.length > 0 ? form.ciclo.split(",") : [];
                          let lab = [...cicSelected];
                          if (e.target.checked) {
                            val.push(e.target.value);
                            lab.push({
                              id: e.target.value,
                              label: e.target.ariaLabel,
                            });
                          } else {
                            const index = val.indexOf(e.target.value);
                            const result = lab.find(
                              i => i.id === e.target.value
                            );
                            const indexLab = lab.indexOf(result);
                            if (index > -1) {
                              val.splice(index, 1);
                            }
                            if (indexLab > -1) {
                              lab.splice(indexLab, 1);
                            }
                          }
                          setForm({ ...form, [e.target.name]: val.join() });
                          setCicSelected(lab);
                        }}
                      />{" "}
                      <Label check className="text-indigo ocultar">
                        Frio
                      </Label>
                    </Col>
                    <Col md={6} style={{ marginTop: 25 }}>
                      <Input
                        type="checkbox"
                        name="ciclo"
                        aria-label="Quente"
                        value="Q"
                        id={`cic-Q`}
                        onClick={e => {
                          let val =
                            form.ciclo.length > 0 ? form.ciclo.split(",") : [];
                          let lab = [...cicSelected];
                          if (e.target.checked) {
                            val.push(e.target.value);
                            lab.push({
                              id: e.target.value,
                              label: e.target.ariaLabel,
                            });
                          } else {
                            const index = val.indexOf(e.target.value);
                            const result = lab.find(
                              i => i.id === e.target.value
                            );
                            const indexLab = lab.indexOf(result);
                            if (index > -1) {
                              val.splice(index, 1);
                            }
                            if (indexLab > -1) {
                              lab.splice(indexLab, 1);
                            }
                          }
                          setForm({ ...form, [e.target.name]: val.join() });
                          setCicSelected(lab);
                        }}
                      />{" "}
                      <Label check className="text-indigo ocultar">
                        Quente
                      </Label>
                    </Col>
                  </Row>
                </FormGroup>
              </div>
              <div style={{ width: "40%" }}>
                <FormGroup>
                  <Row className="d-flex align-items-center mb-3">
                    <Col md={3}>
                      <Label className="label-titulo mb-0">Voltagem:</Label>
                    </Col>
                    <Col md={8} className="d-flex flex-wrap">
                      {volSelected.map(value => (
                        <p
                          className="px-3 rounded mb-0 cap-selected"
                          key={value.id}
                        >
                          <small>
                            {value.label}
                            <i
                              className="far fa-times-circle"
                              onClick={() => {
                                let lab = [...volSelected];
                                let val =
                                  form.voltagem.length > 0
                                    ? form.voltagem.split(",")
                                    : [];
                                const indexLab = lab.indexOf(value);
                                const index = val.indexOf(value.id);
                                if (indexLab > -1) {
                                  lab.splice(indexLab, 1);
                                }
                                if (index > -1) {
                                  val.splice(index, 1);
                                }

                                document.getElementById(
                                  `vol-${value.id}`
                                ).checked = false;
                                setVolSelected(lab);
                                setForm({ ...form, voltagem: val.join() });
                              }}
                              style={{ marginLeft: 5 }}
                            ></i>
                          </small>
                        </p>
                      ))}
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6} style={{ marginTop: 25 }}>
                      <Input
                        type="checkbox"
                        name="voltagem"
                        value={1}
                        aria-label="110/MONOFASICO"
                        id={`vol-1`}
                        onClick={e => {
                          let val =
                            form.voltagem.length > 0
                              ? form.voltagem.split(",")
                              : [];
                          let lab = [...volSelected];
                          if (e.target.checked) {
                            val.push(e.target.value);
                            lab.push({
                              id: e.target.value,
                              label: e.target.ariaLabel,
                            });
                          } else {
                            const index = val.indexOf(e.target.value);
                            const result = lab.find(
                              i => i.id === e.target.value
                            );
                            const indexLab = lab.indexOf(result);
                            if (index > -1) {
                              val.splice(index, 1);
                            }
                            if (indexLab > -1) {
                              lab.splice(indexLab, 1);
                            }
                          }
                          setForm({ ...form, [e.target.name]: val.join() });
                          setVolSelected(lab);
                        }}
                      />{" "}
                      <Label check className="text-indigo ocultar">
                        110/MONOFASICO
                      </Label>
                    </Col>
                    <Col md={6} style={{ marginTop: 25 }}>
                      <Input
                        type="checkbox"
                        name="voltagem"
                        value={2}
                        aria-label="220/MONOFASICO"
                        id={`vol-2`}
                        onClick={e => {
                          let val =
                            form.voltagem.length > 0
                              ? form.voltagem.split(",")
                              : [];
                          let lab = [...volSelected];
                          if (e.target.checked) {
                            val.push(e.target.value);
                            lab.push({
                              id: e.target.value,
                              label: e.target.ariaLabel,
                            });
                          } else {
                            const index = val.indexOf(e.target.value);
                            const result = lab.find(
                              i => i.id === e.target.value
                            );
                            const indexLab = lab.indexOf(result);
                            if (index > -1) {
                              val.splice(index, 1);
                            }
                            if (indexLab > -1) {
                              lab.splice(indexLab, 1);
                            }
                          }
                          setForm({ ...form, [e.target.name]: val.join() });
                          setVolSelected(lab);
                        }}
                      />{" "}
                      <Label check className="text-indigo ocultar">
                        220/MONOFASICO
                      </Label>
                    </Col>
                    <Col md={6} style={{ marginTop: 25 }}>
                      <Input
                        type="checkbox"
                        name="voltagem"
                        value={3}
                        aria-label="220/TRIFASICO"
                        id={`vol-3`}
                        onClick={e => {
                          let val =
                            form.voltagem.length > 0
                              ? form.voltagem.split(",")
                              : [];
                          let lab = [...volSelected];
                          if (e.target.checked) {
                            val.push(e.target.value);
                            lab.push({
                              id: e.target.value,
                              label: e.target.ariaLabel,
                            });
                          } else {
                            const index = val.indexOf(e.target.value);
                            const result = lab.find(
                              i => i.id === e.target.value
                            );
                            const indexLab = lab.indexOf(result);
                            if (index > -1) {
                              val.splice(index, 1);
                            }
                            if (indexLab > -1) {
                              lab.splice(indexLab, 1);
                            }
                          }
                          setForm({ ...form, [e.target.name]: val.join() });
                          setVolSelected(lab);
                        }}
                      />{" "}
                      <Label check className="text-indigo ocultar">
                        220/TRIFASICO
                      </Label>
                    </Col>
                    <Col md={6} style={{ marginTop: 25 }}>
                      <Input
                        type="checkbox"
                        name="voltagem"
                        value={4}
                        aria-label="380/TRIFASICO"
                        id={`vol-4`}
                        onClick={e => {
                          let val =
                            form.voltagem.length > 0
                              ? form.voltagem.split(",")
                              : [];
                          let lab = [...volSelected];
                          if (e.target.checked) {
                            val.push(e.target.value);
                            lab.push({
                              id: e.target.value,
                              label: e.target.ariaLabel,
                            });
                          } else {
                            const index = val.indexOf(e.target.value);
                            const result = lab.find(
                              i => i.id === e.target.value
                            );
                            const indexLab = lab.indexOf(result);
                            if (index > -1) {
                              val.splice(index, 1);
                            }
                            if (indexLab > -1) {
                              lab.splice(indexLab, 1);
                            }
                          }
                          setForm({ ...form, [e.target.name]: val.join() });
                          setVolSelected(lab);
                        }}
                      />{" "}
                      <Label check className="text-indigo ocultar">
                        380/TRIFASICO
                      </Label>
                    </Col>
                  </Row>
                </FormGroup>
              </div>
            </Row>

            <Button
              onClick={e => addAmbients(e)}
              className="mt-4 buscar-produto"
            >
              ADICIONAR AMBIENTE
            </Button>
          </CardBody>
        </Card>
        {allAmbients.length >= 1 ? (
          <Ambients ambients={[allAmbients, setAllAmbients]} />
        ) : null}
        <Row>
          <Col>
            <AdvancedFilter
              getProducts={getProducts}
              form={form}
              setForm={setForm}
              fabSelected={fabSelected}
              setFabSelected={setFabSelected}
              fabVisible={fabVisible}
              setFabVisible={setFabVisible}
              fabs={fabs}
              filters={filters}
              returnLogo={returnLogo}
              selectedDropdown={selectedDropdown}
              setSelectedDropdown={setSelectedDropdown}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default OrderFilter;
