import React from "react";
const Name = customField => {
  const name = customField.customFields.name ? customField.customFields.name : "-";
  return <div>{ name }</div>;
}
const FieldFormat = customField => {
  const fieldFormat = customField.customFields.field_format ? customField.customFields.field_format : "-"; 
  return <div>{ fieldFormat }</div>;
}
const Description = customField => {
  const description = customField.customFields.description ? customField.customFields.description : "-";
  return <div>{ description }</div>;
}

export { Name, FieldFormat, Description };
