import React from "react";
import { Link } from "react-router-dom";
import { Row, Col, Card } from "reactstrap";

const Widgets = ({ widgetData }) => {
  return (
    <React.Fragment>
      <Row>
        <React.Fragment>
          <Col>
            <Card className="card-analise">
              <i className="mdi mdi-timer-sand text-white big-icon"></i>
              <div className="tamanho-card">
                <div className="float-end">
                  <h2 className="text-end text-white">
                    {widgetData["inanalisis"] ? widgetData["inanalisis"] : "-"}
                  </h2>
                  <h5 className="text-white">Em Analise</h5>
                </div>
              </div>
              <Link
                to="/warranty?idSituacao=5"
                className="py-1 px-2 bg-soft-dark"
              >
                <div className="d-flex align-items-center justify-content-between">
                  <h6 className="botao-cards">visualizar</h6>
                  <i className="mdi mdi-arrow-right-circle-outline text-white font-size-20"></i>
                </div>
              </Link>
            </Card>
          </Col>
          <Col>
            <Card className="card-peça">
              <i className="mdi mdi-bullhorn-outline text-white big-icon"></i>
              <div className="tamanho-card">
                <div className="float-end">
                  <h2 className="text-end text-white">
                    {widgetData["requestedpart"]
                      ? widgetData["requestedpart"]
                      : "-"}
                  </h2>
                  <h5 className="text-white">Peça Solicitada</h5>
                </div>
              </div>
              <Link
                to="/warranty?idSituacao=1 "
                className="py-1 px-2 bg-soft-dark"
              >
                <div className="d-flex align-items-center justify-content-between">
                  <h6 className="botao-cards">visualizar</h6>
                  <i className="mdi mdi-arrow-right-circle-outline text-white font-size-20"></i>
                </div>
              </Link>
            </Card>
          </Col>
          <Col>
            <Card className="card-peça-disponivel">
              <i className="mdi mdi-briefcase-outline text-white big-icon"></i>
              <div className="tamanho-card-peça">
                <div className="float-end">
                  <h2 className="text-end text-white">
                    {widgetData["availablepart"]
                      ? widgetData["availablepart"]
                      : "-"}
                  </h2>
                  <h5 className="text-white">Peça Disponivel</h5>
                </div>
              </div>
              <Link
                to="/warranty?idSituacao=6"
                className="py-1 px-2 bg-soft-dark"
              >
                <div className="d-flex align-items-center justify-content-between">
                  <h6 className="botao-cards">visualizar</h6>
                  <i className="mdi mdi-arrow-right-circle-outline text-white font-size-20"></i>
                </div>
              </Link>
            </Card>
          </Col>
          <Col md="4">
            <Card className="card-aguardando">
              <i className="mdi mdi-refresh text-white big-icon"></i>
              <div className="tamanho-card">
                <div className="float-end">
                  <h2 className="text-end text-white">
                    {widgetData["waitingdamagedpart"]
                      ? widgetData["waitingdamagedpart"]
                      : "-"}
                  </h2>
                  <h5 className="text-white">Aguardando Peça Defeituosa</h5>
                </div>
              </div>
              <Link
                to="/warranty?idSituacao=9"
                className="py-1 px-2 bg-soft-dark"
              >
                <div className="d-flex align-items-center justify-content-between">
                  <h6 className="botao-cards">visualizar</h6>
                  <i className="mdi mdi-arrow-right-circle-outline text-white font-size-20"></i>
                </div>
              </Link>
            </Card>
          </Col>
          <Col>
            <Card className="card-total">
              <i className="mdi mdi-gauge-full text-white big-icon"></i>
              <div className="tamanho-card">
                <div className="float-end">
                  <h2 className="text-end text-white">
                    {widgetData["total"] ? widgetData["total"] : "-"}
                  </h2>
                  <h5 className="text-white">Total</h5>
                </div>
              </div>
              <div className="py-1 px-2 bg-soft-dark">
                <div className="d-flex align-items-center justify-content-between">
                  <h6 className="botao-cards">visualizar</h6>
                  <i className="mdi mdi-arrow-right-circle-outline text-white font-size-20"></i>
                </div>
              </div>
            </Card>
          </Col>
        </React.Fragment>
      </Row>
    </React.Fragment>
  );
};

export default Widgets;
