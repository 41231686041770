import { GalleryActionTypes } from "./actionTypes";

export const galleryApiSuccess = (actionType, data) => ({
  type: GalleryActionTypes.API_RESPONSE_SUCCESS,
  payload: { actionType, data },
});

export const galleryApiFail = (actionType, error) => ({
  type: GalleryActionTypes.API_RESPONSE_ERROR,
  payload: { actionType, error },
});

export const getTVGallary = () => ({
  type: GalleryActionTypes.GET_TV_GALLERY,
});

export const getTVConfigGallary = videoId => ({
  type: GalleryActionTypes.GET_TV_CONFIG_GALLERY,
  payload: videoId,
});

export const loadRecentData = videoId => ({
  type: GalleryActionTypes.LOAD_RECET_DATA,
  payload: videoId,
});

export const addNewLive = data => ({
  type: GalleryActionTypes.ADD_NEW_LIVE,
  payload: data,
});

export const uploadLiveConfigFiles = (videoId, files) => ({
  type: GalleryActionTypes.UPLOAD_LIVE_CONFIG_FILES,
  payload: { videoId, files },
});

export const deleteLiveConfigFile = (videoId, arquivoId) => ({
  type: GalleryActionTypes.DELETE_LIVE_CONFIG_FILE,
  payload: { videoId, arquivoId },
});

export const getTVvideoDetails = videoId => ({
  type: GalleryActionTypes.GET_TV_VIDEO_DETAILS,
  payload: videoId,
});

export const postVideoComment = (videoId, commentData) => ({
  type: GalleryActionTypes.POST_VIDEO_COMMENT,
  payload: { videoId, commentData },
});

export const deleteMessage = messageId => ({
  type: GalleryActionTypes.DELETE_MESSAGE,
  payload: messageId,
});
