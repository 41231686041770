import React from "react";
import { Card, CardBody } from "reactstrap";

// images
import ouro from "../../assets/images/category/ouro.png";

const Category = () => {
  return (
    <div className="category">
      <Card className="mb-0">
        <CardBody className="text-center position-relative">
          <i className="mdi mdi-information-outline info-icon font-size-16 text-muted" />
          <h6 className="fw-bold mb-5">Sua categoria atual é</h6>
          <img src={ouro} width={184} className="mb-4" />
          <div>
            <button className="btn btn-primary">VER DETALHES</button>
          </div>
        </CardBody>
      </Card>
    </div>
  );
};

export default Category;
