const dataTable = [
  { mes: "Jan", consumo: 20018, geracao: 37627 },
  { mes: "Fev", consumo: 19275, geracao: 39502 },
  { mes: "Mar", consumo: 32747, geracao: 33879 },
  { mes: "Abr", consumo: 25210, geracao: 29842 },
  { mes: "Mai", consumo: 27871, geracao: 24652 },
  { mes: "Jun", consumo: 33602, geracao: 22850 },
  { mes: "Jul", consumo: 29762, geracao: 23355 },
  { mes: "Ago", consumo: 37163, geracao: 30275 },
  { mes: "Set", consumo: 36585, geracao: 30563 },
  { mes: "Out", consumo: 38877, geracao: 34321 },
  { mes: "Nov", consumo: 33400, geracao: 37051 },
  { mes: "Dez", consumo: 34921, geracao: 41015 },
];

const dataConsumo = [
  20018, 19275, 32747, 25210, 27871, 33602, 29762, 37163, 36585, 38877, 33400,
  34921,
];

const dataGeracao = [
  3762, 3950, 3389, 2984, 2465, 2285, 2335, 3027, 3056, 3432, 3705, 4101,
];

const dataTableBar = [
  {
    equipamentos: "Módulos",
    especificacao:
      "Painel Solar Fotovoltaico 590W 120 CELLS PERC BI FACIAL - REISEN",
    qtd: 543,
  },
  {
    equipamentos: "Módulos",
    especificacao:
      "Painel Solar Fotovoltaico 590W 120 CELLS PERC BI FACIAL - REISEN",
    qtd: 543,
  },
  {
    equipamentos: "Módulos",
    especificacao:
      "Painel Solar Fotovoltaico 590W 120 CELLS PERC BI FACIAL - REISEN",
    qtd: 543,
  },
  {
    equipamentos: "Módulos",
    especificacao:
      "Painel Solar Fotovoltaico 590W 120 CELLS PERC BI FACIAL - REISEN",
    qtd: 543,
  },
  {
    equipamentos: "Módulos",
    especificacao:
      "Painel Solar Fotovoltaico 590W 120 CELLS PERC BI FACIAL - REISEN",
    qtd: 543,
  },
  {
    equipamentos: "Módulos",
    especificacao:
      "Painel Solar Fotovoltaico 590W 120 CELLS PERC BI FACIAL - REISEN",
    qtd: 543,
  },
  {
    equipamentos: "Módulos",
    especificacao:
      "Painel Solar Fotovoltaico 590W 120 CELLS PERC BI FACIAL - REISEN",
    qtd: 543,
  },
  {
    equipamentos: "Módulos",
    especificacao:
      "Painel Solar Fotovoltaico 590W 120 CELLS PERC BI FACIAL - REISEN",
    qtd: 543,
  },
  {
    equipamentos: "Módulos",
    especificacao:
      "Painel Solar Fotovoltaico 590W 120 CELLS PERC BI FACIAL - REISEN",
    qtd: 543,
  },
  {
    equipamentos: "Módulos",
    especificacao:
      "Painel Solar Fotovoltaico 590W 120 CELLS PERC BI FACIAL - REISEN",
    qtd: 543,
  },
  {
    equipamentos: "Módulos",
    especificacao:
      "Painel Solar Fotovoltaico 590W 120 CELLS PERC BI FACIAL - REISEN",
    qtd: 543,
  },
  {
    equipamentos: "Módulos",
    especificacao:
      "Painel Solar Fotovoltaico 590W 120 CELLS PERC BI FACIAL - REISEN",
    qtd: 543,
  },
  {
    equipamentos: "Módulos",
    especificacao:
      "Painel Solar Fotovoltaico 590W 120 CELLS PERC BI FACIAL - REISEN",
    qtd: 543,
  },
];

const categories = [
  1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22,
  23,
];
const data = [
  -3000, -2000, -1000, -500, 0, 400, 600, 800, 1000, 1200, 1400, 1600, 1800,
  2000, 2200, 2400, 2600, 2800, 3000, 3200, 3400, 3600,
];

const dataTablePageFour = [
  {
    anos: 0,
    tafira: 0.6,
    geracao: "0",
    custo: "0",
    resultadoAnual: "0",
    purchaseCost: "0",
    resultadoAcumulado: "0",
  },
  {
    anos: 1,
    tafira: 0.66,
    geracao: "0",
    custo: "0",
    resultadoAnual: "0",
    purchaseCost: "0",
    resultadoAcumulado: "0",
  },
  {
    anos: 2,
    tafira: 0.66,
    geracao: "0",
    custo: "0",
    resultadoAnual: "0",
    purchaseCost: "0",
    resultadoAcumulado: "0",
  },
  {
    anos: 3,
    tafira: 0.66,
    geracao: "0",
    custo: "0",
    resultadoAnual: "0",
    purchaseCost: "0",
    resultadoAcumulado: "0",
  },
  {
    anos: 4,
    tafira: 0.66,
    geracao: "0",
    custo: "0",
    resultadoAnual: "0",
    purchaseCost: "0",
    resultadoAcumulado: "0",
  },
  {
    anos: 5,
    tafira: 0.66,
    geracao: "0",
    custo: "0",
    resultadoAnual: "0",
    purchaseCost: "0",
    resultadoAcumulado: "0",
  },
  {
    anos: 6,
    tafira: 0.66,
    geracao: "0",
    custo: "0",
    resultadoAnual: "0",
    purchaseCost: "0",
    resultadoAcumulado: "0",
  },
  {
    anos: 7,
    tafira: 0.66,
    geracao: "0",
    custo: "0",
    resultadoAnual: "0",
    purchaseCost: "0",
    resultadoAcumulado: "0",
  },
  {
    anos: 8,
    tafira: 0.66,
    geracao: "0",
    custo: "0",
    resultadoAnual: "0",
    purchaseCost: "0",
    resultadoAcumulado: "0",
  },
  {
    anos: 9,
    tafira: 0.66,
    geracao: "0",
    custo: "0",
    resultadoAnual: "0",
    purchaseCost: "0",
    resultadoAcumulado: "0",
  },
  {
    anos: 10,
    tafira: 0.66,
    geracao: "0",
    custo: "0",
    resultadoAnual: "0",
    purchaseCost: "0",
    resultadoAcumulado: "0",
  },
  {
    anos: 11,
    tafira: 0.66,
    geracao: "0",
    custo: "0",
    resultadoAnual: "0",
    purchaseCost: "0",
    resultadoAcumulado: "0",
  },
  {
    anos: 12,
    tafira: 0.66,
    geracao: "0",
    custo: "0",
    resultadoAnual: "0",
    purchaseCost: "0",
    resultadoAcumulado: "0",
  },
  {
    anos: 13,
    tafira: 0.66,
    geracao: "0",
    custo: "0",
    resultadoAnual: "0",
    purchaseCost: "0",
    resultadoAcumulado: "0",
  },
  {
    anos: 14,
    tafira: 0.66,
    geracao: "0",
    custo: "0",
    resultadoAnual: "0",
    purchaseCost: "0",
    resultadoAcumulado: "0",
  },
  {
    anos: 15,
    tafira: 0.66,
    geracao: "0",
    custo: "0",
    resultadoAnual: "0",
    purchaseCost: "0",
    resultadoAcumulado: "0",
  },
  {
    anos: 16,
    tafira: 0.66,
    geracao: "0",
    custo: "0",
    resultadoAnual: "0",
    purchaseCost: "0",
    resultadoAcumulado: "0",
  },
  {
    anos: 17,
    tafira: 0.66,
    geracao: "0",
    custo: "0",
    resultadoAnual: "0",
    purchaseCost: "0",
    resultadoAcumulado: "0",
  },
  {
    anos: 18,
    tafira: 0.66,
    geracao: "0",
    custo: "0",
    resultadoAnual: "0",
    purchaseCost: "0",
    resultadoAcumulado: "0",
  },
  {
    anos: 19,
    tafira: 0.66,
    geracao: "0",
    custo: "0",
    resultadoAnual: "0",
    purchaseCost: "0",
    resultadoAcumulado: "0",
  },
  {
    anos: 20,
    tafira: 0.66,
    geracao: "0",
    custo: "0",
    resultadoAnual: "0",
    purchaseCost: "0",
    resultadoAcumulado: "0",
  },
  {
    anos: 21,
    tafira: 0.66,
    geracao: "0",
    custo: "0",
    resultadoAnual: "0",
    purchaseCost: "0",
    resultadoAcumulado: "0",
  },
  {
    anos: 22,
    tafira: 0.66,
    geracao: "0",
    custo: "0",
    resultadoAnual: "0",
    purchaseCost: "0",
    resultadoAcumulado: "0",
  },
  {
    anos: 23,
    tafira: 0.66,
    geracao: "0",
    custo: "0",
    resultadoAnual: "0",
    purchaseCost: "0",
    resultadoAcumulado: "0",
  },
  {
    anos: 24,
    tafira: 0.66,
    geracao: "0",
    custo: "0",
    resultadoAnual: "0",
    purchaseCost: "0",
    resultadoAcumulado: "0",
  },
  {
    anos: 25,
    tafira: 0.66,
    geracao: "0",
    custo: "0",
    resultadoAnual: "0",
    purchaseCost: "0",
    resultadoAcumulado: "0",
  },
];

export {
  dataTable,
  dataConsumo,
  dataGeracao,
  dataTableBar,
  categories,
  data,
  dataTablePageFour,
};
