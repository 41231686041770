/* eslint-disable no-undef */
import React from "react";
import {
  Button,
  Card,
  CardBody,
  Col,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";

function AdvancedFilter({
  getProducts,
  fabSelected,
  setFabSelected,
  form,
  setForm,
  filters,
  fabs,
  fabVisible,
  returnLogo,
  setFabVisible,
  selectedDropdown,
  setSelectedDropdown,
}) {
  return (
    <div>
      <Card>
        <CardBody>
          <Row className="mt-4">
            <Col>
              <FormGroup>
                <Row className="d-flex align-items-center mb-3">
                  <Col md={2}>
                    <Label className="label-titulo mb-0">Fabricante:</Label>
                  </Col>
                  <Col md={7} className="d-flex flex-wrap">
                    {fabSelected.map(value => (
                      <p
                        className="px-3 rounded mb-0 cap-selected"
                        key={value.id}
                      >
                        <small>
                          {value.label}
                          <i
                            className="far fa-times-circle"
                            onClick={() => {
                              let lab = [...fabSelected];
                              let val =
                                form.fabricante.length > 0
                                  ? form.fabricante.split(",")
                                  : [];
                              const indexLab = lab.indexOf(value);
                              const index = val.indexOf(value.id);
                              if (indexLab > -1) {
                                lab.splice(indexLab, 1);
                              }
                              if (index > -1) {
                                val.splice(index, 1);
                              }

                              document.getElementById(
                                `fab-${value.id}`
                              ).checked = false;
                              setFabSelected(lab);
                              setForm({ ...form, fabricante: val.join() });
                            }}
                            style={{ marginLeft: 5 }}
                          ></i>
                        </small>
                      </p>
                    ))}
                  </Col>
                  <Col md={3}>
                    <Input
                      type="select"
                      value={selectedDropdown}
                      onChange={e => setSelectedDropdown(e.target.value)}
                    >
                      <option value="" disabled>
                        -
                      </option>
                      <option value="PREÇO">MELHOR PREÇO</option>
                      <option value="EFICIENCIA">MELHOR EFICIENCIA</option>
                      <option value="DISPONIBILIDADE">
                        MELHOR DISPONIBILIDADE
                      </option>
                    </Input>
                  </Col>
                </Row>
                <Row>
                  {filters.fabricantes.map((value, index) => (
                    <Col
                      md={2}
                      hidden={
                        fabs.indexOf(value.CODIGO.trim()) >= 0
                          ? false
                          : !fabVisible
                      }
                      key={index}
                    >
                      <div className="checkbox">
                        <input
                          type="checkbox"
                          name="fabricante"
                          value={value.CODIGO.trim()}
                          aria-label={value.DESCR.trim()}
                          id={`fab-${value.CODIGO.trim()}`}
                          onClick={e => {
                            let val =
                              form.fabricante.length > 0
                                ? form.fabricante.split(",")
                                : [];
                            let lab = [...fabSelected];
                            if (e.target.checked) {
                              val.push(e.target.value);
                              lab.push({
                                id: e.target.value,
                                label: e.target.ariaLabel,
                              });
                            } else {
                              const index = val.indexOf(e.target.value);
                              const result = lab.find(
                                i => i.id === e.target.value
                              );
                              const indexLab = lab.indexOf(result);

                              if (index > -1) {
                                val.splice(index, 1);
                              }
                              if (indexLab > -1) {
                                lab.splice(indexLab, 1);
                              }
                            }
                            setForm({
                              ...form,
                              [e.target.name]: val.join(),
                            });
                            setFabSelected(lab);
                          }}
                        />
                        <div className="box">
                          <img src={returnLogo(value.CODIGO.trim())} />
                          <p className="text-muted">{value.DESCR}</p>
                        </div>
                      </div>
                    </Col>
                  ))}
                </Row>
              </FormGroup>
              <a
                href="#"
                onClick={e => {
                  e.preventDefault();
                  setFabVisible(!fabVisible);
                }}
                className="ver-todos"
              >
                Ver todas as opções
                <i className="fas fa-chevron-right"></i>
              </a>
            </Col>
          </Row>
          <Row>
            <Col md={8}>
              <FormGroup className="d-flex">
                <Input type="checkbox"></Input>
                <Label className="ms-2">Mesclar Fabricantes</Label>
              </FormGroup>
              <FormGroup className="d-flex">
                <Input type="checkbox"></Input>
                <Label className="ms-2">
                  Gerar único orçamento com os melhores preços
                </Label>
              </FormGroup>
            </Col>
            <Col md={4}>
              <Button onClick={getProducts} className="mt-4 buscar-produto">
                BUSCAR PRODUTOS
              </Button>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </div>
  );
}

export default AdvancedFilter;
