import {
  API_SUCCESS,
  API_FAIL,
  CREATE_PROMOTION_RULES,
  UPDATE_PROMOTION_RULES,
  GET_RULES_COLUMNS_FIELDS,
  GET_RULES,
  IS_LOADING,
  GET_RULES_SELECT,
} from "./actionTypes";

const INIT_STATE = {
  conditionsSelect: [],
  conditions: [],
  promotion: [],
  data: {
    rules_conditions: []
  },
  loadingCreate: false,
  loadingRules: false,
  loadingRulesSelect: false,
  loadingConditions: false,
  loadingUpdate: false,
  loadinDataProduct: false,
};

const PromotionRules = (state = INIT_STATE, action) => {
  switch (action.type) {
    case API_SUCCESS:
      switch (action.payload.actionType) {
        case CREATE_PROMOTION_RULES:
          return {
            ...state,
            promotion: action.payload.data,
            conditions: action.payload.dataSecondary,
            loadingCreate: false,
          };
        case UPDATE_PROMOTION_RULES:
          return {
            ...state,
            dataUpdate: action.payload.data,
            loadingUpdate: false,
          };
        case GET_RULES:
          return {
            ...state,
            data: action.payload.data,
            loadingRules: false,
          };
        case GET_RULES_COLUMNS_FIELDS:
          return {
            ...state,
            rulesColumnsFields: action.payload.data,
            loadingRulesColumnsFields: false,
          };
        case GET_RULES_SELECT:
          return {
            ...state,
            conditionsSelect: action.payload.data,
            loadingRulesSelect: false,
          };
        default:
          return state;
      }

    case API_FAIL:
      switch (action.payload.actionType) {
        case CREATE_PROMOTION_RULES:
          return {
            ...state,
            promotion: [],
            conditions: [],
            loadingCreate: false,
          };
        case UPDATE_PROMOTION_RULES:
          return {
            ...state,
            dataUpdate: [],
            loadingUpdate: false,
          };
        case GET_RULES:
          return {
            ...state,
            data: [],
            loadingRules: false,
          };
        case GET_RULES_SELECT:
          return {
            ...state,
            conditionsSelect: [],
            loadingRulesSelect: false,
          };
        case GET_RULES_COLUMNS_FIELDS:
          return {
            ...state,
            loadingRulesColumnsFields: false,
          };
        default:
          return state;
      }

    case IS_LOADING:
      switch (action.payload.actionType) {
        case CREATE_PROMOTION_RULES:
          return {
            ...state,
            loadingCreate: true,
          };
        case UPDATE_PROMOTION_RULES:
          return {
            ...state,
            loadingUpdate: true,
          };
        case GET_RULES:
          return {
            ...state,
            loadingRules: true,
          };
        case GET_RULES_SELECT:
          return {
            ...state,
            loadingRulesSelect: true,
          };
        case GET_RULES_COLUMNS_FIELDS:
          return {
            ...state,
            loadingRulesColumnsFields: true,
          };
        default:
          return state;
      }

    default:
      return state;
  }
};

export default PromotionRules;
