import React, { useEffect, useState } from "react";
import { Card, CardBody, Col, Container, Row, Spinner } from "reactstrap";
import { Link, useParams } from "react-router-dom";
import "./imageGallery.css";

import { useSolar } from "../../../hooks";
import { imageGalleryData, imageGalleryDataSolar } from "../data";
import { apiClient2 } from "../../../api/apiCore2";

const ImageGallery = () => {
  const [isOpen, setisOpen] = useState(false);
  const isSolar = useSolar();
  const [imageGallery, setImageGallery] = useState([]);
  const [titlePage, setTitlePage] = useState("");
  const [loading, setLoading] = useState(false);
  const { id } = useParams();

  const getImages = async () => {
    setLoading(true);
    const resp = await apiClient2.get(`/marketing/${id}?jsonrequest=1`);
    setImageGallery(resp.documents);
    setTitlePage(resp.category.category);
    setLoading(false);
  };
  useEffect(() => {
    getImages();
  }, [id]);

  return (
    <React.Fragment>
      <div className="page-content imageGallery">
        <Container>
          <Card>
            <CardBody>
              <div className="engloba-titulo-icone">
                <h1 className="titulo-pag">
                  <i className="ph-list-bullets"></i>
                  {titlePage}
                </h1>
                <a href="#" onClick={() => setisOpen(!isOpen)}>
                  {isOpen ? (
                    <i className="ph-caret-down"></i>
                  ) : (
                    <i className="ph-caret-up"></i>
                  )}
                </a>
              </div>
              <hr />
              <div hidden={isOpen}>
                <Row className="engloba-redes-artes-lg-list">
                  {loading ? (
                    <div className="loading">
                      <Spinner />
                    </div>
                  ) : isSolar ? (
                    imageGalleryDataSolar.map((i, key) => (
                      <Col className="engloba-img-list" key={key} md={4}>
                        <Link to={i.link} className="link-marketing">
                          <img src={i.img} />
                        </Link>
                        <p>{i.label}</p>
                      </Col>
                    ))
                  ) : (
                    imageGallery.length > 0 &&
                    imageGallery.map((i, key) => (
                      <Col className="engloba-img-list" key={key} md={3} xl={2}>
                        <a href={i.url} className="link-marketing" download>
                          <img src={i.url} />
                        </a>
                        <p>{i.description}</p>
                      </Col>
                    ))
                  )}
                </Row>
              </div>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ImageGallery;
