import { ShoppingCart } from "phosphor-react";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-scroll";
import styled from "styled-components";
import { useSolar } from "../../hooks";
import CartIcon from "../../components/icons/cart";

const isSolar = useSolar();

const ControleBtn = styled.button`
  font-size: 16px;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${props =>
    props.isSolar ? "#FF601A" : "#7EACC4"};
  color: ;
  position: fixed;
  right: 20px;
  bottom: 20px;
  border: none;
  border-radius: 50%;
  z-index: 1000;
`;

const ControleRemoto = () => {
  const [openCtl, setOpenCtl] = useState(false);
  const isSolar = useSolar();

  const { addedItems } = useSelector(state => ({
    addedItems: state.Order.newOrder.newOrder.addedItems,
  }));

  return (
    <>
      <ControleBtn
        isSolar={isSolar}
        onClick={() => setOpenCtl(!openCtl)}
        className="relative"
      >
        <Link to="carrinho" smooth={true} duration={300} activeClass="active">
          <CartIcon size={22} color={isSolar ? "#FFF" : "#2D567B"} />
        </Link>
        {addedItems.length > 0 && (
          <div
            style={{
              position: "absolute",
              top: "-5px",
              right: "-7px",
              background: "white",
              borderRadius: "100%",
              width: "20px",
              height: "20px",
              border: isSolar
                ? "solid 2px #FF601A"
                : "solid 2px #7EACC4",
              fontSize: "12px",
              color: isSolar ? "#FF601A" : "#7EACC4",
              fontWeight: "bold",
            }}
          >
            {addedItems.length}
          </div>
        )}
      </ControleBtn>
    </>
  );
};

export default ControleRemoto;
