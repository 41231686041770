import { call, put, takeEvery, all, fork } from "redux-saga/effects";
// Crypto Redux States
import { SellerActionTypes } from "./actionTypes";
import { sellerApiSuccess, sellerApiFail } from "./action";

import {
  listSellers as listSellersApi,
  getSeller as getSellerApi,
  getTelephones as getTelephonesApi,
  updateSeller as updateSellerApi,
  deleteTelephone as deleteTelephoneApi,
  addTelephone as addTelephoneApi,
  updateTelephone as updateTelephoneApi,
} from "../../api/index";
import { toast } from "react-toastify";

function* listSellers({ payload: filters }) {
  try {
    let response = yield call(listSellersApi, filters);
    let data = {
      sellers: response.users,
      graph: {
        protheus: response.total_t11,
        clube: response.total_app,
      },
    };

    if (data) {
      yield put(sellerApiSuccess(SellerActionTypes.LIST_SELLERS, data));
    } else {
      toast.info(`Ocorreu um erro ao buscar os vendedores. Tente novamente.`, {
        theme: "colored",
      });
      yield put(sellerApiFail(SellerActionTypes.LIST_SELLERS, ""));
    }
  } catch (error) {
    toast.info(`Ocorreu um erro ao buscar os vendedores. Tente novamente.`, {
      theme: "colored",
    });
    yield put(sellerApiFail(SellerActionTypes.LIST_SELLERS, error));
  }
}

function* getSeller({ payload: id }) {
  try {
    let response = yield call(getSellerApi, id);
    let telefones = yield call(getTelephonesApi, id);

    let data = {
      ...response,
      ...telefones,
    };

    if (data) {
      yield put(sellerApiSuccess(SellerActionTypes.GET_SELLER, data));
    } else {
      toast.info(
        `Ocorreu um erro ao buscar os dados do vendedores. Tente novamente.`,
        { theme: "colored" }
      );
      yield put(sellerApiFail(SellerActionTypes.GET_SELLER, ""));
    }
  } catch (error) {
    toast.info(
      `Ocorreu um erro ao buscar os dados do vendedore. Tente novamente.`,
      { theme: "colored" }
    );
    yield put(sellerApiFail(SellerActionTypes.GET_SELLER, error));
  }
}

function* updateSeller({ payload: data }) {
  try {
    let response = yield call(updateSellerApi, data);

    if (response.success) {
      toast.success(`Dados do vendendor atualizados com sucessos!`, {
        theme: "colored",
      });
      try {
        let response = yield call(getSellerApi, data.id);
        let telefones = yield call(getTelephonesApi, data.id);

        let data = {
          ...response,
          ...telefones,
        };

        if (data) {
          yield put(sellerApiSuccess(SellerActionTypes.GET_SELLER, data));
        } else {
          toast.info(
            `Ocorreu um erro ao buscar os dados do vendedores. Tente novamente.`,
            { theme: "colored" }
          );
          yield put(sellerApiFail(SellerActionTypes.GET_SELLER, ""));
        }
      } catch (error) {
        toast.info(
          `Ocorreu um erro ao buscar os dados do vendedore. Tente novamente.`,
          { theme: "colored" }
        );
        yield put(sellerApiFail(SellerActionTypes.GET_SELLER, error));
      }
    } else {
      toast.info(
        `Ocorreu um erro ao atualizar os dados do vendedores. Tente novamente.`,
        { theme: "colored" }
      );
      yield put(sellerApiFail(SellerActionTypes.UPDATE_SELLER, ""));
    }
  } catch (error) {
    toast.info(
      `Ocorreu um erro ao atualizar os dados do vendedore. Tente novamente.`,
      { theme: "colored" }
    );
    yield put(sellerApiFail(SellerActionTypes.UPDATE_SELLER, error));
  }
}

function* deleteTelephone({ payload: params }) {
  try {
    let response = yield call(deleteTelephoneApi, params);

    if (response) {
      toast.success(response.message, { theme: "colored" });
      try {
        let response = yield call(getSellerApi, params.id);
        let telefones = yield call(getTelephonesApi, params.id);

        let data = {
          ...response,
          ...telefones,
        };

        if (data) {
          yield put(sellerApiSuccess(SellerActionTypes.GET_SELLER, data));
        } else {
          toast.info(
            `Ocorreu um erro ao buscar os dados do vendedores. Tente novamente.`,
            { theme: "colored" }
          );
          yield put(sellerApiFail(SellerActionTypes.GET_SELLER, ""));
        }
      } catch (error) {
        toast.info(
          `Ocorreu um erro ao buscar os dados do vendedore. Tente novamente.`,
          { theme: "colored" }
        );
        yield put(sellerApiFail(SellerActionTypes.GET_SELLER, error));
      }
    } else {
      toast.info(
        `Ocorreu um erro ao atualizar os dados do vendedores. Tente novamente.`,
        { theme: "colored" }
      );
      yield put(sellerApiFail(SellerActionTypes.UPDATE_SELLER, ""));
    }
  } catch (error) {
    toast.error(error, { theme: "colored" });
    yield put(sellerApiFail(SellerActionTypes.DELETE_TELEPHONE, error));
  }
}

function* addTelephone({ payload: params }) {
  try {
    let response = yield call(addTelephoneApi, params);

    if (response) {
      toast.success(response.message, { theme: "colored" });
      try {
        let response = yield call(getSellerApi, params.id);
        let telefones = yield call(getTelephonesApi, params.id);

        let data = {
          ...response,
          ...telefones,
        };

        if (data) {
          yield put(sellerApiSuccess(SellerActionTypes.GET_SELLER, data));
        } else {
          toast.info(
            `Ocorreu um erro ao buscar os dados do vendedores. Tente novamente.`,
            { theme: "colored" }
          );
          yield put(sellerApiFail(SellerActionTypes.GET_SELLER, ""));
        }
      } catch (error) {
        toast.info(
          `Ocorreu um erro ao buscar os dados do vendedore. Tente novamente.`,
          { theme: "colored" }
        );
        yield put(sellerApiFail(SellerActionTypes.GET_SELLER, error));
      }
    } else {
      toast.info(
        `Ocorreu um erro ao atualizar os dados do vendedores. Tente novamente.`,
        { theme: "colored" }
      );
      yield put(sellerApiFail(SellerActionTypes.UPDATE_SELLER, ""));
    }
  } catch (error) {
    // toast.error(error, { theme: "colored" });
    yield put(sellerApiFail(SellerActionTypes.ADD_TELEPHONE, error));
  }
}

function* updateTelephone({ payload: params }) {
  try {
    let response = yield call(updateTelephoneApi, {
      id: params.id,
      tel: {
        COD: params.tel.CODIGO,
        DDD: params.tel.DDD,
        TEL: params.tel.NUMERO,
      },
    });

    if (response) {
      toast.success(response.message, { theme: "colored" });
      try {
        let response = yield call(getSellerApi, params.id);
        let telefones = yield call(getTelephonesApi, params.id);

        let data = {
          ...response,
          ...telefones,
        };

        if (data) {
          yield put(sellerApiSuccess(SellerActionTypes.GET_SELLER, data));
        } else {
          toast.info(
            `Ocorreu um erro ao buscar os dados do vendedores. Tente novamente.`,
            { theme: "colored" }
          );
          yield put(sellerApiFail(SellerActionTypes.GET_SELLER, ""));
        }
      } catch (error) {
        toast.info(
          `Ocorreu um erro ao buscar os dados do vendedore. Tente novamente.`,
          { theme: "colored" }
        );
        yield put(sellerApiFail(SellerActionTypes.GET_SELLER, error));
      }
    } else {
      toast.info(
        `Ocorreu um erro ao atualizar os dados do vendedores. Tente novamente.`,
        { theme: "colored" }
      );
      yield put(sellerApiFail(SellerActionTypes.UPDATE_SELLER, ""));
    }
  } catch (error) {
    // toast.error(error, { theme: "colored" });
    yield put(sellerApiFail(SellerActionTypes.ADD_TELEPHONE, error));
  }
}

export function* watchListSellers() {
  yield takeEvery(SellerActionTypes.LIST_SELLERS, listSellers);
}

export function* watchGetSeller() {
  yield takeEvery(SellerActionTypes.GET_SELLER, getSeller);
}

export function* watchUpdateSeller() {
  yield takeEvery(SellerActionTypes.UPDATE_SELLER, updateSeller);
}

export function* watchDeleteTelephone() {
  yield takeEvery(SellerActionTypes.DELETE_TELEPHONE, deleteTelephone);
}

export function* watchAddTelephone() {
  yield takeEvery(SellerActionTypes.ADD_TELEPHONE, addTelephone);
}

export function* watchUpdateTelephone() {
  yield takeEvery(SellerActionTypes.UPDATE_TELEPHONE, updateTelephone);
}

function* sellerSaga() {
  yield all([
    fork(watchListSellers),
    fork(watchGetSeller),
    fork(watchUpdateSeller),
    fork(watchDeleteTelephone),
    fork(watchAddTelephone),
    fork(watchUpdateTelephone),
  ]);
}

export default sellerSaga;
