import React from "react";
import Chart from "react-apexcharts";

export default function GraphicsLine({ dataConsumo, dataGeracao }) {
  const options = {
    chart: {
      id: "basic-bar",

      toolbar: {
        show: true,
        offsetX: 0,
        offsetY: 0,
        tools: {
          download: false,
          selection: false,
          zoom: false,
          zoomin: false,
          zoomout: false,
          pan: false,
          reset: false,
        },
      },
    },

    markers: {
      size: [6],
      colors: "#fff",
      strokeColors: ["#FF601A", "#2D567B"],
      strokeWidth: 2,
    },
    noData: {
      text: "Nenhum dado Registrado",
      align: "center",
      verticalAlign: "middle",
      offsetX: 0,
      offsetY: 0,
    },
    title: {
      text: "Consumo x Geração",
      align: "center",
      margin: 10,
      style: {
        fontSize: "14px",
        fontWeight: "bold",
        fontFamily: undefined,
        color: "#263238",
      },
    },
    colors: ["#FF601A", "#2D567B"],
    xaxis: {
      categories: [
        "Jan",
        "Fev",
        "Mar",
        "Abr",
        "Mai",
        "Jun",
        "Jul",
        "Ago",
        "Set",
        "Out",
        "Nov",
        "Dez",
      ],
    },
    series: [
      {
        name: "Consumo kWh",
        data: dataConsumo,
      },
      {
        name: "Geração FV kWh",
        data: dataGeracao,
      },
    ],
  };

  return (
    <div className="app">
      <div className="row">
        <div className="mixed-chart">
          <Chart
            options={options}
            series={options.series}
            type="line"
            width="100%"
            height="260"
          />
        </div>
      </div>
    </div>
  );
}
