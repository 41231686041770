import * as Yup from "yup";

const initialValuesToFormik = {
  CPF_PF: "",
  NOME_PF: "",
  NOME_REDUZ: "",
  A3_XCONTAT: "",
  EMAILCONT_PF: "",
  DDDCONT_PF: "",
  TELCONT_PF: "",
  DDDFCONT_PF: "",
  TELFCONT_PF: "",
  CEP_PF: "",
  ENDERECO_PF: "",
  NUMEROEND_PF: "",
  COMPLEM_PF: "",
  BAIRRO_PF: "",
  MUNICIPIO_PF: "",
  UF_PF: "",
  nome_consultor: "",
};

const RegisterSchema = Yup.object().shape({
  CPF_PF: Yup.string().required("Campo obrigatório"),
  NOME_PF: Yup.string().required("Campo obrigatório"),
  NOME_REDUZ: Yup.string().required("Campo obrigatório"),
  A3_XCONTAT: Yup.string().required("Campo obrigatório"),
  EMAILCONT_PF: Yup.string()
    .email("Digite um email válido")
    .required("Campo obrigatório"),
  DDDCONT_PF: Yup.string().required("Campo obrigatório"),
  TELCONT_PF: Yup.string().required("Campo obrigatório"),
  DDDFCONT_PF: Yup.string().required("Campo obrigatório"),
  TELFCONT_PF: Yup.string().required("Campo obrigatório"),
  CEP_PF: Yup.string().required("Campo obrigatório"),
  ENDERECO_PF: Yup.string().required("Campo obrigatório"),
  NUMEROEND_PF: Yup.string(),
  COMPLEM_PF: Yup.string(),
  BAIRRO_PF: Yup.string().required("Campo obrigatório"),
  MUNICIPIO_PF: Yup.string().required("Campo obrigatório"),
  UF_PF: Yup.string().required("Campo obrigatório"),
});

export { initialValuesToFormik, RegisterSchema };
