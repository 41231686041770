import * as Yup from "yup";

const initialValuesToFormik = {
  avg_account_price: "",
  name: "",
  email: "",
  phone: "",
};

// Using custom test method
function validationPrice(message) {
  return this.test("validationPrice", message, function (value) {
    const { path, createError } = this;

    if (value == "0,00") {
      return createError({ path, message: message });
    }

    if (value == null) {
      return createError({ path, message: message });
    }

    return true;
  });
}

Yup.addMethod(Yup.mixed, "validationPrice", validationPrice);

const SignupSchema = Yup.object().shape({
  avg_account_price: Yup.string().validationPrice("Campo valor obrigatório"),
  name: Yup.string().required("Campo nome obrigatório"),
  email: Yup.string()
    .email("Digite um email válido")
    .required("Campo email obrigatório"),
  phone: Yup.string().required("Campo telefone obrigatório"),
});

export { initialValuesToFormik, SignupSchema };
