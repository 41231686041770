import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Container, Card, CardBody, Row, Col, Button } from "reactstrap";
import { useDispatch } from "react-redux";
import "flatpickr/dist/themes/material_blue.css";
import { getRegistrationList } from "../../../store/actions";
import Breadcrumb from "../../../components/Breadcrumb";
import CadastroFilter from "./CadastroFilter";
import CadastroLista from "./CadastroLista";
import { toast } from "react-toastify";

const ConsultarCadastro = () => {
  const dispatch = useDispatch();

  const [mostrarLista, setMostrarLista] = useState(false);
  const [filters, setFilters] = useState({
    cpfcnpj: '',
    nome_revenda: '',
    dataCadastro: '',
    status: '',
  });

  const { registrationDetails, getRegistrationLoading } = useSelector(state => ({
    registrationDetails: state.RegistrationsReducer.registrationDetails,
    getRegistrationLoading: state.RegistrationsReducer.getRegistrationLoading,
  }));

  const buscarCadastros = () => {
    try {
      dispatch(getRegistrationList({ ...filters }));
    } catch (error) {
      return toast.error(
        `Ocorreu um erro durante a consulta de cadastros: ${error}`,
        { theme: "colored" }
      );
    }

    setMostrarLista(true)
  };

  return (
    <>
      <div className="page-content">
        <Container>
          <Breadcrumb
            title="Página Inicial"
            breadcrumbItem="Consultar Cadastro"
          />
          <Card className="p-2">
            <CardBody className="card-body">
              <div>
                <h5 className="titulo-card">
                  Filtro de Cadastro
                </h5>
              </div>
              <CadastroFilter filters={filters} setFilters={setFilters}
              />
              <Row>
                <Col
                  md={5}
                  className="d-flex mt-3 ms-4 col-md-5 consultar-orcamento"
                >
                  <Button
                    className="btn btn-rounded px-4 bg-success text-white botao-busca"
                    onClick={() => buscarCadastros()}
                  >
                    <i className="fa fa-filter" style={{ marginRight: 10 }}></i>
                    Filtrar
                  </Button>
                </Col>
              </Row>
            </CardBody>
          </Card>
          {mostrarLista && <CadastroLista cadastros={registrationDetails} getRegistrationLoading={getRegistrationLoading} />}
        </Container>
      </div>
    </>
  );
};
export default ConsultarCadastro;
