import React, { useState, useEffect } from "react";
import { CardBody, Col, Row, Card, Input, Button } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { removeFromCart } from "../../store/actions";
import { formato, arredondar } from "../../utils";

import A from "../../assets/images/bandeira/bandeira-a.png";
import B from "../../assets/images/bandeira/bandeira-b.png";
import C from "../../assets/images/bandeira/bandeira-c.png";
import D from "../../assets/images/bandeira/bandeira-d.png";
import E from "../../assets/images/bandeira/bandeira-e.png";
import F from "../../assets/images/bandeira/bandeira-f.png";
import { toast } from "react-toastify";
import { filiais } from "../../utils/filiais";

const AddedItem = ({
  item,
  addValTot,
  valores,
  ocultarRt,
  changeItensCarrinho,
  itensCarrinho,
  addValArqu,
  valArquiteto,
  addValorRt,
  valorRt,
}) => {
  const dispatch = useDispatch();
  const { vendedor, findedItems } = useSelector(state => ({
    vendedor: state.Order.newOrder.newOrder.vendedor,
    findedItems: state.Order.newOrder.newOrder.findedItems,
  }));
  const [quant, setQuant] = useState(item.quant || 1);
  const [valVen, setValVen] = useState(item.precoVenda);
  const [precoVenda, setPrecoVenda] = useState(Number(item.precoVenda));
  // const [addServico, setAddServico] = useState(false);
  const [rtArquiteto, setRtArquiteto] = useState(0);
  // const [servico, setServico] = useState(0);
  const [valTot, setValTot] = useState(quant * Number(precoVenda));
  const valMin = Number(item.precoVenda) * 0.95;
  const valMax = Number(item.precoVenda) * 1.5;
  const totTabela = Number(item.precoVenda) * quant;
  const dif = valTot - totTabela;
  const percentMin = 5 + (dif / totTabela) * 100;
  const [valRt] = useState(
    dif > 0 ? dif * 0.7 + totTabela * 0.05 : valTot * (percentMin / 100)
  );
  const percent = dif > 0 ? (valRt / valTot) * 100 : percentMin;

  const detalhes = findedItems[item.cod] || {};

  const bandeiras = {
    A: A,
    B: B,
    C: C,
    D: D,
    E: E,
    F: F,
  };

  useEffect(() => {
    setPrecoVenda(item.precoVenda);
    setValVen(item.precoVenda);
    setValTot(quant * precoVenda);
  }, [item.precoVenda]);

  useEffect(
    () => setValTot(Number(quant) * Number(precoVenda)),
    [precoVenda, quant]
  );

  const removeItem = () => {
    let valTotal = valores;
    delete valTotal[item.produto.CODPRODUTO];
    addValTot(valTotal);

    let itemCar = itensCarrinho;
    delete itemCar[item.produto.CODPRODUTO];
    changeItensCarrinho(itemCar);

    let arqu = valArquiteto;
    delete arqu[item.produto.CODPRODUTO];
    addValArqu(arqu);

    let rt = valorRt;
    delete rt[item.produto.CODPRODUTO];
    addValorRt(rt);

    toast.info(`${item.produto.CODPRODUTO} removido do carrinho.`, {
      theme: "colored",
    });
    dispatch(removeFromCart(item));
  };

  function getCodFab() {
    if (detalhes.atributosEspeciais) {
      let val = Object.values(detalhes.atributosEspeciais);
      let selo1 = val.find(i => i.Codattr == "304");
      let selo2 = val.find(i => i.Codattr == "305");
      return (selo1?.Valor || "") + " / " + (selo2?.Valor || "");
    } else {
      return false;
    }
  }

  function getSeloA() {
    if (detalhes.atributosEspeciais) {
      let val = Object.values(detalhes.atributosEspeciais);
      let selo1 = val.find(i => i.Codattr == "188");
      return selo1?.Valor || false;
    } else {
      return false;
    }
  }

  const zerarRt = () => {
    setValVen(precoVenda);
  };

  useEffect(() => {
    let valTotal = {
      ...valores,
      [item.produto.CODPRODUTO]: Number(valTot),
    };
    addValTot(valTotal);

    let itemCar = {
      ...itensCarrinho,
      [item.produto.CODPRODUTO]: {
        AMOUNT: quant,
        VALORRT: Number(valRt) / Number(quant),
        PRCRT: precoVenda,
        subtotal: valTot,
        maskPrcVenda: precoVenda,
        PERCENT: percent,
      },
    };
    changeItensCarrinho(itemCar);

    let arqu = {
      ...valArquiteto,
      [item.produto.CODPRODUTO]: Number(rtArquiteto),
    };
    addValArqu(arqu);

    let rtVal = {
      ...valorRt,
      [item.produto.CODPRODUTO]: Number(valRt),
    };
    addValorRt(rtVal);
  }, [valTot, quant, valRt, precoVenda, percent, rtArquiteto]);

  return (
    <Card className="mb-0 mt-4">
      <CardBody>
        <Row className="d-flex align-items-end">
          <Col md={3}>
            <p className="mb-0" style={{ fontWeight: 500 }}>
              Enviado de:{" "}
              {
                filiais[
                  Number(
                    item.produto.PRDDISPS?.STRETPRDFILADIAS[item.filial]
                      ?.CODFILPRD
                  ) || item.filial
                ]
              }
            </p>
          </Col>
          <Col md={9} className="d-flex justify-content-end align-items-center">
            <Button color="info" size="sm" outline onClick={zerarRt}>
              Zerar RT
            </Button>
            <a
              href=""
              onClick={e => {
                e.preventDefault();
                removeItem();
              }}
              style={{ color: "#269FEC", marginLeft: 20 }}
            >
              <i className="far fa-trash-alt"></i>
            </a>
          </Col>
        </Row>
        <hr className="mb-3" />
        <Row>
          {detalhes.fotos ? (
            <Col md={1}>
              <img src={detalhes.fotos[0]} style={{ width: "100%" }} />
            </Col>
          ) : (
            <div />
          )}
          <Col md={8}>
            <h5>{item.produto.DESCRICAO}</h5>
            <Row>
              <Col className="mt-2 d-flex align-items-center">
                <div>
                  <p className="text-muted mb-0" style={{ fontSize: 11 }}>
                    Cód. Fabricante: {getCodFab() || ""}
                  </p>
                  <p className="text-muted mb-0" style={{ fontSize: 11 }}>
                    SKU: {item.produto.CODPRODUTO}
                  </p>
                </div>
                <img
                  src={bandeiras[getSeloA() || item.produto.SELOA]}
                  className="selo-a"
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <hr className="mb-3" />
        <div className="product-infos">
          <div className="product-info">
            <p className="info-title">Quantidade</p>
            <Input
              className="mb-0 mt-4 pre-venda"
              type="number"
              value={quant}
              onChange={e => setQuant(e.target.value)}
              min={1}
            />
          </div>
          <div className="product-info">
            <p className="info-title">Preço de Venda</p>
            <Input
              className="mb-0 mt-4 pre-venda"
              value={valVen.toLocaleString("pt-br", formato)}
              onChange={e => setValVen(e.target.value)}
              readOnly={vendedor?.VENDEDOR?.TEMRT != 1}
              onBlur={() => {
                let val =
                  String(valVen)
                    .replace(/[^0-9,]*/g, "")
                    .replace(",", ".") || 0;
                if (Number(val) >= valMin && Number(val) <= valMax) {
                  setPrecoVenda(Number(val));
                } else {
                  setValVen(Number(precoVenda));
                  toast.warning(
                    `O preço tabela não autorizado. Deve ser maior que ${valMin.toLocaleString(
                      "pt-br",
                      formato
                    )} e menor que ${valMax.toLocaleString("pt-br", formato)}`,
                    { theme: "colored" }
                  );
                }
              }}
            />
          </div>
          <div className="product-info">
            <p className="info-title">Valor Total</p>
            <p className="info-result mb-0">
              {Number(valTot).toLocaleString("pt-br", formato)}
            </p>
          </div>
          <div className="product-info">
            <p className="info-title">Tabela</p>
            <p className="info-result mb-0">
              {Number(item.precoVenda).toLocaleString("pt-br", formato)}
            </p>
          </div>
          <div className="product-info" hidden={ocultarRt}>
            <p className="info-title">%</p>
            <p className="info-result mb-0">{arredondar(percent)}</p>
          </div>
          <div className="product-info" hidden={ocultarRt}>
            <p className="info-title">Valor RT</p>
            <p className="info-result mb-0">
              {arredondar(valRt).toLocaleString("pt-br", formato)}
            </p>
            {/* <Input
                            className="mb-0 mt-4 pre-venda"
                            value={varRt.toLocaleString("pt-br", formato)}
                            onChange={(e) => setVarRt(e.target.value)}
                            // readOnly={vendedor?.VENDEDOR?.TEMRT != 1}
                            readOnly={true}
                            onBlur={() => {
                                let val = String(varRt).replace(/[^0-9,]g, '').replace(',', '.') || 0;
                                if (Number(val) <= minValRt && Number(val) >= 0) {
                                  
                                } else {
                                    setValVen(Number(precoVenda))
                                    toast.warning(`O preço tabela não autorizado. Deve ser maior que ${valMin.toLocaleString('pt-br', formato)} e menor que ${valMax.toLocaleString('pt-br', formato)}`, { theme: "colored" });
                                }
                            }}
                            type="text"
                        /> */}
          </div>
          {/* <div className="product-info">
                        <p className="info-title">10x no Cartão</p>
                        <p className="info-result">R$ 1.963,00</p>
                    </div> */}
          <div className="product-info" hidden={ocultarRt}>
            <p className="info-title">RT do Arquiteto</p>
            <Input
              className="mb-0 mt-4 pre-venda"
              type="number"
              value={rtArquiteto}
              onChange={e => setRtArquiteto(e.target.value)}
            />
          </div>
        </div>
        {/* <hr className="mb-3" />
                <Row>
                    <Col md={6}>
                        {!addServico ?
                            <a href="#" onClick={(e) => {
                                e.preventDefault();
                                setAddServico(true);
                            }} style={{ color: "#5C9FD2" }}>Incluir Serviço <i className="fa fa-plus" style={{ marginLeft: 5 }}></i></a>
                            : <Input className="pre-venda2" style={{ width: "30%", margin: 0 }} placeholder="R$ 0,00" type="number" value={servico} onChange={(e) => setServico(e.target.value)} />}
                    </Col>
                    <Col md={6}>
                        <a href="#" style={{ display: "block", width: "fit-content", marginLeft: "auto", color: "#5C9FD2" }}>Serviço de Instalação Incluso ({Number(servico).toLocaleString('pt-br', formato)})</a>
                    </Col>
                </Row> */}
      </CardBody>
    </Card>
  );
};

export default AddedItem;
