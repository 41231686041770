import React from "react";
export default function IconLightningKit() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="31"
      viewBox="0 0 30 31"
      fill="none"
    >
      <path
        d="M4.60938 13.8753V7.12006C4.60938 6.87589 4.70658 6.64173 4.87959 6.46907C5.05261 6.29642 5.28727 6.19942 5.53195 6.19942L14.7578 2.8418L23.9835 6.19942C24.2282 6.19942 24.4629 6.29642 24.6359 6.46907C24.8089 6.64173 24.9061 6.87589 24.9061 7.12006V13.8753C24.9061 23.542 16.6836 26.7412 15.0461 27.2821C14.86 27.3508 14.6555 27.3508 14.4694 27.2821C12.8319 26.7412 4.60938 23.542 4.60938 13.8753Z"
        stroke="#D24D00"
        strokeWidth="1.84516"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.8915 20.866L13.7785 16.4403L10.2305 15.1126L16.4396 8.47412L15.5525 12.8998L19.1006 14.2275L12.8915 20.866Z"
        stroke="#FFB706"
        strokeWidth="1.30246"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
