import styled from "styled-components";

export const Title = styled.h1`
  font-size: 18px;
  color: ${props => (props.isSolar ? "#FF601A" : "#2D567B")};
  font-weight: 700;
  text-transform: uppercase;
  margin: 0 !important;
`;

export const CardItem = styled.div`
  width: 100%;
  padding: 20px;

  :nth-child(1) {
    margin-top: 0;
  }
  margin-top: 20px;
  span {
    font-size: 14px;
    color: #577688;
    font-weight: normal;
  }

  div {
    display: flex;
    margin-bottom: 5px;
  }

  i {
    font-size: 15px;
    margin-right: 10px;
  }

  p {
    font-size: 13px;
    color: #577688;
    font-weight: normal;
    margin: 0;
    margin-left: 10px;
  }

  .lido {
    display: flex;
    align-items: center;
    background-color: #b3bfcb;
    font-size: 13px;
    margin-left: 10px;
    margin-bottom: 0;
    padding: 2px 6px;
    color: #fff;

    svg {
      margin-right: 5px;
    }
  }
`;

export const Card = styled.div`
  width: 100%;
  border-radius: 7.5px;
  box-shadow: 0px 3.69417px 9.78589px #e0e5eb;
  padding: 20px;
  margin-bottom: 30px;

  img {
    width: 100%;
    height: 100%;
    max-width: 576px;
  }
`;
export const ButtonClose = styled.p``;

export const Texto = styled.p`
  display: flex;
  font-size: 105px;
  font-weight: normal;
  justify-content: center;
`;
export const TextoPagina = styled.p`
  font-size: 40px;
  display: flex;
  justify-content: center;
`;
