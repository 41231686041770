import { put, call, takeEvery, fork, all } from "redux-saga/effects";
import { manufacturerDetailsApiFail, manufacturerDetailsApiSuccess } from "./action";
import { ManufacturerDetailsActionTypes } from "./actionTypes";
import { getManufacturerDetails as getManufacturerDetailsApi } from "../../../api";

function* getManufacturerDetails({ payload: manufacturerId }) {
  try {
    const response = yield call(getManufacturerDetailsApi, manufacturerId);
    yield put(
      manufacturerDetailsApiSuccess(ManufacturerDetailsActionTypes.GET_MANUFACTURER_DETAILS, response)
    );
  } catch (error) {
    yield put(
      manufacturerDetailsApiFail(ManufacturerDetailsActionTypes.GET_MANUFACTURER_DETAILS, error)
      );
  }
}

export function* watchGetManufacturerDetails() {
  yield takeEvery(
    ManufacturerDetailsActionTypes.GET_MANUFACTURER_DETAILS,
    getManufacturerDetails
  );
}

function* manufacturerDetailsSaga() {
  yield all([
    fork(watchGetManufacturerDetails)
  ]);
}

export default manufacturerDetailsSaga;