import React, { useEffect, useState } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col,
  Label,
  Input,
} from "reactstrap";

import "./style.scss";

const ModalBasic = ({
  openModal,
  setOpenModal,
  header,
  footer,
  children,
  onClick,
  textButton,
  modalAlert,
}) => {
  const toggle = () => !modalAlert && setOpenModal(state => !state);

  return (
    <Modal className="modal-insert-product" isOpen={openModal} toggle={toggle}>
      {header && <ModalHeader toggle={toggle}>{header}</ModalHeader>}
      <ModalBody>{children}</ModalBody>{" "}
      {footer && (
        <ModalFooter>
          {!modalAlert && (
            <Button color="primary" onClick={toggle}>
              Fechar
            </Button>
          )}
          <Button onClick={onClick} className="button-save">
            {textButton}
          </Button>
        </ModalFooter>
      )}
    </Modal>
  );
};

export default ModalBasic;
