import React from "react";
import { TitleInputs } from "../../style";
import { ArInput } from "../../../../../components/adias-input";
import { Col, Row } from "reactstrap";
import axios from "axios";
export const DadosPessoais = ({ errors, touched, setErros, handleBlur }) => {
  const verifyCnpjExists = async value => {
    let cnpjFormat = value
      .replaceAll(".", "")
      .replaceAll("/", "")
      .replaceAll("-", "");
    if (cnpjFormat.length == 14) {
      const resp = await axios.get(
        process.env.REACT_APP_API_URL_2 +
          `/cadastro/prospect/verifica-cnpj?cnpj=${cnpjFormat}`
      );

      setErros(state => {
        return { ...state, cnpjExist: resp.data };
      });
    }
  };

  return (
    <>
      {" "}
      <TitleInputs>DADOS PESSOAIS</TitleInputs>
      <Row>
        {" "}
        <Col md={6}>
          <ArInput
            title="CNPJ"
            errors={errors}
            name="CPF_PF"
            touched={touched}
            mask="cnpj"
            onchange={(e, value) => verifyCnpjExists(value)}
            onBlur={handleBlur}
          />
        </Col>
        <Col md={6}>
          <ArInput
            title="Razão Social"
            errors={errors}
            name="NOME_PF"
            touched={touched}
            onBlur={handleBlur}
          />
        </Col>
      </Row>
      <Row>
        {" "}
        <Col md={6}>
          <ArInput
            title="Nome Fantasia"
            errors={errors}
            name="NOME_REDUZ"
            touched={touched}
            onBlur={handleBlur}
          />
        </Col>
        <Col md={6}>
          <ArInput
            title="Nome para Contato"
            errors={errors}
            name="A3_XCONTAT"
            touched={touched}
            onBlur={handleBlur}
          />
        </Col>
      </Row>
    </>
  );
};
