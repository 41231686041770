import { fork, put, call, takeEvery, all } from "redux-saga/effects";
import { customFieldApiSuccess, customFieldApiFail } from "./action"
import { CustomFieldActionTypes } from "./actionTypes";
import { 
  createCustomField as createCustomFieldApi,
  getCustomFields as getCustomFieldsApi
 } from "../../api/customFields";

function* getCustomFields({ payload: manufacturerId }) {
  try {
    const response = yield call(getCustomFieldsApi, manufacturerId);
    yield put(
      customFieldApiSuccess(CustomFieldActionTypes.GET_CUSTOM_FIELDS, response)
    );
  } catch (error) {
    yield put(
      customFieldApiFail(CustomFieldActionTypes.GET_CUSTOM_FIELDS, error)
      );
  }
}

function* createCustomField({ payload: data }) {
  try {
    const { customField, manufacturer_id } = data;
    const response = yield call(createCustomFieldApi, { customField, manufacturer_id });
    yield put(
      customFieldApiSuccess(CustomFieldActionTypes.CREATE_CUSTOM_FIELD, response)
    );
  } catch (error) {
    yield put(
      customFieldApiFail(CustomFieldActionTypes.CREATE_CUSTOM_FIELD, error)
      );
  }
}

export function* watchGetCustomFields() {
  yield takeEvery(
    CustomFieldActionTypes.GET_CUSTOM_FIELDS,
    getCustomFields
  )
}

export function* watchCreateCustomField() {
  yield takeEvery(
    CustomFieldActionTypes.CREATE_CUSTOM_FIELD,
    createCustomField
  );
}

function* customFieldSaga() {
  yield all([
    fork(watchGetCustomFields),
    fork(watchCreateCustomField)
  ]);
}

export default customFieldSaga;