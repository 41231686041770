import { WarrantyActionTypes } from "./actionTypes";

const INIT_STATE = {
  warrantyDetails: [],
  invoice: [],
  manuId: ''
};

const Warranty = (state = INIT_STATE, action) => {
  switch (action.type) {
    case WarrantyActionTypes.API_RESPONSE_SUCCESS:
      switch (action.payload.actionType) {
        case WarrantyActionTypes.GET_WARRANTY_DETAILS:
          return {
            ...state,
            warrantyDetails: action.payload.data,
            getWarrantyLoading: false,
            isWarrantyFetched: true,
          };
        
        case WarrantyActionTypes.GET_INVOICE:
          return {
            ...state,
            invoice: action.payload.data ?? [],
            getWarrantyLoading: false,
          };

        case WarrantyActionTypes.GET_MANUFACTURER:
        return {
          ...state,
          manuId: action.payload.data,
        };

        case WarrantyActionTypes.CREATE_WARRANTY:
          return {
            ...state, 
            getWarrantyLoading: false,
          };  

        default:
          return { ...state };
      }
    case WarrantyActionTypes.API_RESPONSE_ERROR:
      switch (action.payload.actionType) {
        case WarrantyActionTypes.GET_WARRANTY_DETAILS:
          return {
            ...state,
            warrantyDetails: [],
            getWarrantyLoading: false,
            isWarrantyFetched: true,
          };

        case WarrantyActionTypes.GET_INVOICE:
          return {
            ...state,
            invoice: [],
            getWarrantyLoading: false,
          };

        case WarrantyActionTypes.GET_MANUFACTURER:
          return {
            ...state,
            manuId: action.payload.data,
          };

        case WarrantyActionTypes.CREATE_WARRANTY:
          return {
            ...state,
            data: action.payload,
              getWarrantyLoading: false,
          };

        default:
          return { ...state };
      }

    case WarrantyActionTypes.GET_WARRANTY_DETAILS:
      return {
        ...state,
        getWarrantyLoading: true,
        isWarrantyFetched: false,
      };

    case WarrantyActionTypes.GET_INVOICE:
      return {
        ...state,
        invoice: action.payload.data ?? [],
        getWarrantyLoading: true,
      };

    case WarrantyActionTypes.GET_MANUFACTURER:
      return {
        ...state,
        manuId: action.payload.data,
      };

    case WarrantyActionTypes.CREATE_WARRANTY:
      return {
        ...state,
        getWarrantyLoading: true,
      };

    

    default:
      return state;
  }
};

export default Warranty;
