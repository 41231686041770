import { AvGroup, AvInput, AvFeedback } from "availity-reactstrap-validation";
import React from "react";
import { Card, CardBody, Col, Row, Label, InputGroup } from "reactstrap";
import { telMask, validEmail } from "../../../utils/otherUtils";
import "./cadastrarrevenda.css";
import { toast } from "react-toastify";

const Contato = ({ formData, setFormData }) => {
  const validateEmail = () => {
    if (formData.email && !validEmail(formData.email)) {
      toast.error("E-mail inválido");
    }
  };

  return (
    <>
      <Card className="p-2">
        <CardBody className="card-body">
          <h4 className="titulo-card">Contato</h4>
          <div className="mt-4 mx-4">
            <Row className="justify-content-center">
              <Col md={6} className="mt-3">
                <Label>Nome para Contato</Label>
                <AvGroup>
                  <AvInput
                    id="nome_contato"
                    name="nome_contato"
                    value={formData.nomeContato ?? ""}
                    placeholder="Digite aqui..."
                    className="input-dados"
                    onChange={e =>
                      setFormData({ ...formData, nomeContato: e.target.value })
                    }
                    required
                  />
                  <AvFeedback>Campo obrigatório!</AvFeedback>
                </AvGroup>
              </Col>
              <Col md={6} className="mt-3">
                <Label>E-mail</Label>
                <AvGroup>
                  <AvInput
                    placeholder="Digite aqui..."
                    name="email"
                    className="input-dados"
                    onBlur={validateEmail}
                    value={formData.email ?? ""}
                    type="email"
                    onChange={e =>
                      setFormData({ ...formData, email: e.target.value })
                    }
                    required
                  />
                  <AvFeedback>Campo obrigatório!</AvFeedback>
                </AvGroup>
              </Col>
            </Row>
            <Row className="justify-content-center">
              <Col md={6}>
                <InputGroup className="justify-content-between">
                  <Col md={2} className="mt-3">
                    <Label>DDD</Label>
                    <AvGroup>
                      <AvInput
                        name="dddtel"
                        id="dddtel"
                        maxLength={2}
                        minLength={2}
                        value={formData.dddTel ?? ""}
                        placeholder="DDD"
                        className="input-dados"
                        onChange={e =>
                          setFormData({ ...formData, dddTel: e.target.value })
                        }
                        required
                      />
                      <AvFeedback>Campo obrigatório!</AvFeedback>
                    </AvGroup>
                  </Col>
                  <Col md={9} className="mt-3">
                    <Label>Telefone Fixo</Label>
                    <AvGroup>
                      <AvInput
                        name="telefone"
                        id="telefone"
                        required
                        value={
                          formData?.telefone?.length > 4
                            ? telMask(formData.telefone)
                            : formData.telefone || ""
                        }
                        maxLength={9}
                        minLength={9}
                        placeholder="Digite aqui..."
                        onChange={e =>
                          setFormData({
                            ...formData,
                            telefone: e.target.value.replace(/[^0-9]+/g, ""),
                          })
                        }
                        className="input-dados w-100"
                      />
                      <AvFeedback>Campo obrigatório!</AvFeedback>
                    </AvGroup>
                  </Col>
                </InputGroup>
              </Col>
              <Col md={6}>
                <InputGroup className="justify-content-between">
                  <Col md={2} className="mt-3">
                    <Label>DDD</Label>
                    <AvGroup>
                      <AvInput
                        name="dddcel"
                        id="dddcel"
                        maxLength={2}
                        minLength={2}
                        value={formData.dddCel ?? ""}
                        className="input-dados"
                        placeholder="DDD"
                        onChange={e =>
                          setFormData({ ...formData, dddCel: e.target.value })
                        }
                        required
                      />
                      <AvFeedback>Campo obrigatório!</AvFeedback>
                    </AvGroup>
                  </Col>
                  <Col md={9} className="mt-3">
                    <Label>Celular</Label>
                    <AvGroup>
                      <AvInput
                        name="celular"
                        id="celular"
                        value={
                          formData?.celular?.length > 4
                            ? telMask(formData.celular)
                            : formData.celular || ""
                        }
                        onChange={e =>
                          setFormData({
                            ...formData,
                            celular: e.target.value.replace(/[^0-9]+/g, ""),
                          })
                        }
                        maxLength={10}
                        minLength={10}
                        placeholder="Digite aqui..."
                        className="input-dados w-100"
                        required
                      />
                      <AvFeedback>Campo obrigatório!</AvFeedback>
                    </AvGroup>
                  </Col>
                </InputGroup>
              </Col>
            </Row>
          </div>
        </CardBody>
      </Card>
    </>
  );
};
export default Contato;
