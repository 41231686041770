import { Card, CardBody, React } from "./helpers/importer.helper";
import ArCondicionadoGenerico from "../../assets/images/pngs/arcondicionar_generico.jpg";
import { FilePdf } from "phosphor-react";
export default function CardModules({
  item,
  moduleSelected,
  setModuleSelected,
  dataEstoque,
}) {
  const generecImage =
    "https://diassolars3.s3-sa-east-1.amazonaws.com/images/products/images/2022/08/1467158604630ff3c75ee3c.jpg";
  const handleChange = () => {
    setModuleSelected({
      nome: item.nome,
      potencia: item.potencia,
      compimentoModulo: item.comprimento,
      precoCliente: item.precoCliente,
      previsao: item?.previsao || null,
      largura: item?.largura,
      fornecedor: item?.fornecedor,
      sku: item?.sku,
      linkFichaTecnica: item.linkFichatecnica,
      qtdEstoque: item.qtdEstoque,
      estoqueFuturo: item.estoqueFuturo,
      leadTime: item.leadTime,
    });
  };
  
  const textStyle = {
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 2,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    height: '3em', 
    lineHeight: '1.5em' 
  };

  var custo = item.precoCliente / item.potencia;

  const minimumStock = 1000 + item.estoqueFuturo;

  return (
    <>
      <Card className="card-modules" onClick={handleChange}>
        <CardBody
          style={{
            border:
              moduleSelected.nome == item.nome
                ? "2px solid #FF601A"
                : "2px solid #d1d1d1",
          }}
          className="card-border"
        >
          <span
            style={textStyle}
            data-toggle="tooltip"
            data-placement="top"
            title={item.name}
          >
            {item.nome}
          </span>
          <div className="card-box">
            <div className="card-box-img-input">
              <div className="card-box-img-info">
                <img src={item.linkImage ? item.linkImage : generecImage} />
                <div className="card-box-infos">
                  <p>Fabricante: {item.fabricante}</p>
                  <p>Potência: {item.potencia} W</p>
                  <p>
                    Custo: R${" "}
                    {custo.toLocaleString("pt-br", {
                      maximumFractionDigits: 2,
                      minimumFractionDigits: 2,
                    })}{" "}
                    R$/Watt
                  </p>
                </div>
              </div>
              <div className="buttons-container">
                <a
                  style={{
                    backgroundColor: item.linkFichatecnica
                      ? "transparent"
                      : "#d1d1d1",
                    cursor: item.linkFichatecnica ? "pointer" : "not-allowed",
                    border: item.linkFichatecnica
                      ? "1px solid #FF601A"
                      : "1px solid transparent",
                  }}
                  data-toggle="tooltip"
                  data-placement="top"
                  title={
                    !item.linkFichatecnica && "Pdf indisponível no momento"
                  }
                  className="btn"
                  target="_blank"
                  rel="noreferrer"
                  href={item.linkFichatecnica}
                >
                  <FilePdf
                    size={24}
                    color={item.linkFichatecnica ? "#FF601A" : "#fff"}
                  />
                </a>

                {(item.previsao && item.qtdEstoque < item.estoqueFuturo) ||
                (item.previsao &&
                  item.leadTime &&
                  item.qtdEstoque < minimumStock) ? (
                  <span>Previsão no CD: {item.previsao}</span>
                ) : (
                  <span>Pronta Entrega</span>
                )}
              </div>
            </div>
          </div>
        </CardBody>
      </Card>
    </>
  );
}
