import { call, put, takeEvery, all, fork } from "redux-saga/effects";
import { CREATE_COLLABORATOR, GET_COLLABORATORS, UPDATE_COLLABORATOR } from "./actionTypes";
import { collaboratorApiFail, collaboratorApiSuccess } from "./action";
import { getCollaborators as getCollaboratorsApi, create, update } from "../../api/collaborators"
import { toast } from "react-toastify";
import { useSolar } from "../../hooks/SolarHooks"


function* getCollaborators({ payload: id }) {
  try {
    const collaboratos = yield call(getCollaboratorsApi, id);
    yield put(collaboratorApiSuccess(GET_COLLABORATORS, collaboratos));
  } catch (error) {
    yield put(collaboratorApiFail(GET_COLLABORATORS, error));
  }
}

function* createCollaborator({ payload: { data, history } }) {
  try {
    const collaborator = yield call(create, data);
    yield put(collaboratorApiSuccess(CREATE_COLLABORATOR, collaborator));
    toast.success('Colaborador cadastrado com sucesso');

    const isSolar = useSolar();

    history.push((isSolar ? "/solar" : "") + '/colaboradores');
  } catch (error) {
    yield put(collaboratorApiFail(CREATE_COLLABORATOR, error));
  }
}

function* updateCollaborator({ payload: { sellerId, data, history } }) {
  try {
    const response = yield call(update, sellerId, data);
    yield put(collaboratorApiSuccess(UPDATE_COLLABORATOR, response));
    toast.success('Colaborador atualizado com sucesso');
  } catch (error) {
    yield put(collaboratorApiFail(UPDATE_COLLABORATOR, error));
  }
}

export function* watchGetCollaborators() {
  yield takeEvery(GET_COLLABORATORS, getCollaborators);
}

export function* watchCreateCollaborator() {
  yield takeEvery(CREATE_COLLABORATOR, createCollaborator);
}

export function* watchUpdateCollaborator() {
  yield takeEvery(UPDATE_COLLABORATOR, updateCollaborator);
}

function* collaboratorSaga() {
  yield all([
    fork(watchGetCollaborators),
    fork(watchCreateCollaborator),
    fork(watchUpdateCollaborator)
  ]);
}

export default collaboratorSaga;
