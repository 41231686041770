import { call, put, takeEvery, all, fork } from "redux-saga/effects";

// Crypto Redux States
import { GET_CLOSURES, CREATE_UPLOAD, DELETE_CLOSURE } from "./actionTypes";
import { closuresapiFail, closuresapiSuccess } from "./action";

//Include Both Helper File with needed methods
import {
  getClosures as getClosuresApi,
  submitUpload as submitUploadApi,
  deleteClosures as deleteClosuresApi,
} from "../../api/index";
import { toast } from "react-toastify";

function* getClosures({ payload: id }) {
  try {
    const resp = yield call(getClosuresApi, id);
    yield put(closuresapiSuccess(GET_CLOSURES, resp));
  } catch (error) {
    yield put(closuresapiFail(GET_CLOSURES, error));
  }
}

function* submitUpload({ payload: data }) {
  try {
    const resp = yield call(submitUploadApi, data.data);
    yield put(closuresapiSuccess(CREATE_UPLOAD, resp));
    toast.success(`Enviado com sucesso!`, { theme: "colored" });
    data.setModal(false);
  } catch (error) {
    toast.error(`Ocorreu um erro ao enviar os dados`, { theme: "colored" });
    yield put(closuresapiFail(CREATE_UPLOAD, error));
  }
}

function* deleteClosures({ payload: data }) {
  try {
    const resp = yield call(deleteClosuresApi, data);
    yield put(closuresapiSuccess(DELETE_CLOSURE, resp));
    toast.success(`Deletedo com sucesso!`, { theme: "colored" });
  } catch (error) {
    toast.error(`Ocorreu um erro ao deletar`, { theme: "colored" });
    yield put(closuresapiFail(DELETE_CLOSURE, error));
  }
}

export function* watchGetClosures() {
  yield takeEvery(GET_CLOSURES, getClosures);
}

export function* watchDeleteClosures() {
  yield takeEvery(DELETE_CLOSURE, deleteClosures);
}

export function* watchSubmitUpload() {
  yield takeEvery(CREATE_UPLOAD, submitUpload);
}

function* closuresSaga() {
  yield all([
    fork(watchGetClosures),
    fork(watchSubmitUpload),
    fork(watchDeleteClosures),
  ]);
}

export default closuresSaga;
