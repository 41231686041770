import React from "react";
import { Spinner } from "reactstrap";

const Loader = () => {
  return (
    <>
      <div className="loader-container">
        <div className="preloader-component">
          <div className="content-spinner">
            <div id="status">
              <div className="spinner">
                <Spinner color="primary" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Loader;
