import React from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { Card, CardBody, Col, Row } from "reactstrap";

const ManufacturerItem = data => {
  
  const history = useHistory();

  const handleClickVisualizar = () => {
    history.push(`/manufacturers/${data.manufacturer.id}`);
  }

  return (
    <React.Fragment>
      <Row className="mt-2">
        <Col>
          <Card>
            <CardBody className="d-flex flex-column flex-md-row align-items-center justify-content-between card-list text-center">
              <a
                style={{ cursor: "pointer" }}
                title="Visualizar"
                onClick={handleClickVisualizar}
              >
                { data.manufacturer.name }
              </a>
              <div
                style={{
                  cursor: "pointer",
                  display: "flex",
                  alignItems: "center",
                }}
                title="Visualizar"
                onClick={ handleClickVisualizar }
              >
                <i className="fa fa-eye"></i>&nbsp; Visualizar
              </div> 
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default ManufacturerItem;