import { DashboardActionTypes } from "./actionTypes";

export const dashboardApiSuccess = (actionType, data) => ({
  type: DashboardActionTypes.API_RESPONSE_SUCCESS,
  payload: { actionType, data },
});

export const dashboardApiFail = (actionType, error) => ({
  type: DashboardActionTypes.API_RESPONSE_ERROR,
  payload: { actionType, error },
});

// recenet users
export const getDashboardDetails = company => ({
  type: DashboardActionTypes.GET_DASHBOARD_DETAILS,
  payload: { company },
});
