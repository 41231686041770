import { call, put, takeEvery, all, fork } from "redux-saga/effects";
import {
  getSharingHistory as getSharingHistoryApi,
  insertSharing as insertSharingApi,
  deleteSharing as deleteSharingApi
} from "../../api/sharedResales";
import { sharedResalesApiFail, sharedResalesApiSuccess } from "./action";
import { DELETE_SHARING, GET_SHARING_HISTORY, INSERT_SHARING, UPDATE_SHARING } from "./actionTypes";
import { toast } from "react-toastify";

function* getSharingHistory({ payload: sharedSellerId }) {
  try {
    const response = yield call(getSharingHistoryApi, sharedSellerId);

    yield put(sharedResalesApiSuccess(GET_SHARING_HISTORY, response));
  } catch (error) {
    toast.error(error);
    yield put(sharedResalesApiFail(GET_SHARING_HISTORY, error));
  }
}

function* insertSharing({ payload: data }) {
  try {
    const response = yield call(insertSharingApi, data);
    toast.success('Compartilhamento adicionado com sucesso');

    yield put(sharedResalesApiSuccess(INSERT_SHARING, response));
  } catch (error) {
    toast.error(error);
    yield put(sharedResalesApiFail(INSERT_SHARING, error));
  }
}


function* deleteSharing({ payload: sharingId }) {
  try {
    const response = yield call(deleteSharingApi, sharingId);

    toast.success('Compartilhamento deletado com sucesso');
    yield put(sharedResalesApiSuccess(DELETE_SHARING, response));
  } catch (error) {
    toast.error(error);
    yield put(sharedResalesApiFail(DELETE_SHARING, error));
  }
}

export function* watchGetSharingHistory() {
  yield takeEvery(GET_SHARING_HISTORY, getSharingHistory);
}

export function* watchInsertSharing() {
  yield takeEvery(INSERT_SHARING, insertSharing);
}

export function* watchDeleteSharing() {
  yield takeEvery(DELETE_SHARING, deleteSharing);
}

function* sharedResalesSaga() {
  yield all([
    fork(watchGetSharingHistory),
    fork(watchInsertSharing),
    fork(watchDeleteSharing)
  ]);
}

export default sharedResalesSaga;
