import React, { useState } from "react";
import "./styles.css";
import {
  Carousel,
  CarouselControl,
  CarouselIndicators,
  CarouselItem,
  Col,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Table,
} from "reactstrap";
import { useSolar } from "../hooks";
import Button from "./Button";

function ModalAdias({ produto, produtoDetails, isModalOpen, setIsModalOpen }) {
  //produtoDetails -> produto dps do request de detalhes
  //produto -> produto normal

  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);
  let tamanhos = [];

  Object.keys(produtoDetails.tamanhos || {}).map(function (key) {
    tamanhos.push([key, produtoDetails.tamanhos[key]]);
  });

  const next = () => {
    if (animating) return;
    const nextIndex =
      activeIndex === produtoDetails.fotos.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };

  const previous = () => {
    if (animating) return;
    const nextIndex =
      activeIndex === 0 ? produtoDetails.fotos.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };

  const goToIndex = newIndex => {
    if (animating) return;
    setActiveIndex(newIndex);
  };

  const slides = (produtoDetails.fotos || []).map(produtoDetails => {
    return (
      <CarouselItem
        onExiting={() => setAnimating(true)}
        onExited={() => setAnimating(false)}
        key={produtoDetails}
      >
        <img src={produtoDetails} alt="" />
      </CarouselItem>
    );
  });

  const isSolar = useSolar();

  return !isSolar ? (
    <Modal size="lg" isOpen={isModalOpen}>
      <ModalHeader toggle={() => setIsModalOpen(!isModalOpen)}>
        {produto.DESCRICAO}
      </ModalHeader>
      <ModalBody className="p-5">
        {produtoDetails?.fotos && (
          <Row>
            <Col>
              <Carousel
                activeIndex={activeIndex}
                next={next}
                previous={previous}
              >
                <CarouselIndicators
                  items={produtoDetails?.fotos || []}
                  activeIndex={activeIndex}
                  onClickHandler={goToIndex}
                />
                {slides}
                <CarouselControl
                  direction="prev"
                  directionText="Previous"
                  onClickHandler={previous}
                />
                <CarouselControl
                  direction="next"
                  directionText="Next"
                  onClickHandler={next}
                />
              </Carousel>
            </Col>
          </Row>
        )}

        <h4 className="mt-4 mb-4">Características Principais</h4>
        <Row className="mt-2">
          {Object.values(produtoDetails.atributosEspeciais).map(value => (
            <Col md={6} className="mt-2" key={value.Codattr}>
              <h5>{value.Nome}</h5>
              <p>{value.Valor}</p>
            </Col>
          ))}
        </Row>

        <h4 className="mt-4 mb-4">Tamanhos</h4>
        <Table striped>
          <tbody>
            {tamanhos.map((value, index) => (
              <tr key={index}>
                <td>{value[0]}</td>
                <td>{value[1]}</td>
              </tr>
            ))}
          </tbody>
        </Table>

        <h4 className="mt-4 mb-4">Características</h4>
        <Table striped>
          <tbody>
            {produtoDetails.atributos.map(value => (
              <tr key={value.Codattr}>
                <td>{value.Nome}</td>
                <td>{value.Valor}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </ModalBody>
    </Modal>
  ) : (
    <Modal size="lg" isOpen={isModalOpen}>
      <ModalHeader toggle={() => setIsModalOpen(!isModalOpen)}>
        {produto.DESCRICAO}
      </ModalHeader>
      <ModalBody className="p-5">
        <Row>
          <Col>
            {(produtoDetails?.fotos || []).length >= 1 && (
              <Carousel
                activeIndex={activeIndex}
                next={next}
                previous={previous}
              >
                <CarouselIndicators
                  items={produtoDetails?.fotos || []}
                  activeIndex={activeIndex}
                  onClickHandler={goToIndex}
                />
                {slides}
                <CarouselControl
                  direction="prev"
                  directionText="Previous"
                  onClickHandler={previous}
                />
                <CarouselControl
                  direction="next"
                  directionText="Next"
                  onClickHandler={next}
                />
              </Carousel>
            )}
          </Col>
        </Row>

        <h4
          className={`${
            produtoDetails.fotos?.length >= 1 ? "mt-4" : null
          } mb-4`}
        >
          Características
        </h4>
        <Table striped>
          <tbody>
            {produtoDetails.atributos
              ?.sort((a, b) => a.Codattr.localeCompare(b.Codattr))
              .map(value => {
                if (!value.Nome) {
                  return null;
                } else {
                  if (typeof value.Valor == "string") {
                    if (value.Valor.includes("https")) {
                      return (
                        <tr key={value.Codattr}>
                          {" "}
                          <td style={{ width: "30%" }}>{value.Nome}</td>
                          <td>
                            <Button
                              button={false}
                              href={value.Valor}
                              target="_blank"
                            >
                              Visualizar PDF
                            </Button>
                          </td>
                        </tr>
                      );
                    }
                  }
                  return (
                    <tr key={value.Codattr}>
                      <td style={{ width: "30%" }}>{value.Nome}</td>
                      <td>{value.Valor}</td>
                    </tr>
                  );
                }
              })}
          </tbody>
        </Table>
      </ModalBody>
    </Modal>
  );
}

export default ModalAdias;
