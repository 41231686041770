import React from "react";
import {
  Dropdown,
  UncontrolledPopover,
  PopoverHeader,
  PopoverBody,
} from "reactstrap";
import { WhatsappLogo } from "phosphor-react";
import { Link } from "react-router-dom";
import "./style.scss";
import { useSelector } from "react-redux";
const WhatsApp = () => {
  const regex = /^([0-9]{2})([0-9]{4,5})([0-9]{4})$/;
  const callNumber = () => {
    window.open(
      "https://api.whatsapp.com/send?phone=" +
        "55" +
        user?.whatsappVendedor +
        "&text=" +
        "",
      "_blank"
    );
  };

  const { user } = useSelector(state => ({
    user: state.Profile.profileDetails,
  }));

  return (
    <Dropdown className="d-inline-block " id="whatsapp-btn">
      <div className="btn header-item noti-icon waves-effect d-flex align-items-center justify-content-center">
        {/*  <i className="mdi mdi-whatsapp" style={{ color: "#28b300" }}></i> */}
        <WhatsappLogo size={22} weight="light" color="#28b300" />
      </div>
      <UncontrolledPopover
        trigger="hover"
        placement="bottom"
        target="whatsapp-btn"
      >
        {" "}
        <div onClick={callNumber}>
          <PopoverHeader style={{ cursor: "pointer" }}>
            {" "}
            {user?.user?.name_sup}{" "}
          </PopoverHeader>{" "}
          <PopoverBody style={{ textAlign: "center", cursor: "pointer" }}>
            {user?.whatsappVendedor?.replace(regex, "($1)$2-$3")}
          </PopoverBody>{" "}
        </div>
      </UncontrolledPopover>
    </Dropdown>
  );
};

export default WhatsApp;
