import React from "react";
import { Card, CardBody, Row, Col } from "reactstrap";

import { Link } from "react-router-dom";

//import images
import categories from "../../assets/images/category/fidelidade.png";
import benefits from "../../assets/images/brands/benefits.png";
import fidelidade from "../../assets/pdf/fidelidade/clube_fidelidade.pdf"
import { useColors } from "../../hooks/useColors";

const ClubLoyaltyRating = () => {

const { colorDefault } = useColors();

  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <h5 className="text-success">
            CLASSIFICAÇÃO FIDELIDADE CLUBE ADIAS
          </h5>
          <hr />
            <div className="text-center mt-5">
              <h4 className="mb-4" style={{ color: colorDefault }}>FIDELIDADE CLUBE ADIAS</h4>
            </div>
            <div className="d-flex flex-column">
            <Row>
              <Col md={1}></Col>
              <Col md={10} className="fst-normal p-0">
                <p>
                  A parceria com a <span className="fw-bold" style={{ color: '#2D567B' }}>A.Dias</span> vai muito além de apenas fazer negócios.
                  Disponibilizamos uma grande estrutura para que o parceiro possa alcançar
                  um alto grau de <span style={{ color: '#FF601A' }}>desenvolvimento empresarial</span> e competir em 
                  alto nível com os grandes concorrentes no mercado.
                </p>
                <p>
                  A <span className="fw-bold" style={{ color: '#2D567B' }}>A.Dias</span> foi a primeira empresa do segmento a lançar para 
                  seus parceiros uma plataforma completa de gestão comercial: <strong style={{ color: '#2D567B' }}>O Clube A.Dias.</strong>
                </p>
                <p>
                  E agora inovou novamente, sendo a primeira empresa a lançar um 
                  <span style={{ color: '#FF601A' }}> programa de fidelidade</span> que, além de reconhecer a trajetória dos 
                  parceiros, proporcionará <span style={{ color: '#FF601A' }}> benefícios reais </span> e <span style={{ color: '#FF601A' }}>experiências incríveis</span>.
                </p>
              </Col>
              <Col md={1}></Col>
            </Row>

            </div>
            <div className="d-flex flex-column align-items-center">
              <img src={categories} width="1000" alt="" className="mt-3 img-fluid" />
              {/* <h6 className="mt-5">BENEFÍCIOS - ANO VIGÊNCIA 2021 - V.1</h6>
              <img src={benefits} alt="" width="1000px" className="mt-5" /> */}
              <h6 className="mt-5 mb-5">
                VEJA A APRESENTAÇÃO -{" "}
                <Link
                  target="_blank"
                  style={{ color: colorDefault }}
                  to={fidelidade}
                  download
                >
                  CLIQUE AQUI
                </Link>{" "}
              </h6>
            </div>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default ClubLoyaltyRating;
