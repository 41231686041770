import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Dropdown, DropdownToggle, DropdownMenu } from "reactstrap";

//i18n
import { withTranslation } from "react-i18next";
// Redux
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";

// hooks
import { useProfile } from "../../../hooks/UserHooks";
import { useSelector } from "react-redux";
import Avatar from "@mui/material/Avatar";
import { useSolar } from "../../../hooks";

const ProfileMenu = () => {
  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false);
  const { user } = useSelector(state => state.Profile.profileDetails);
  const isSolar = useSolar();

  const [profileUrl, setProfileUrl] = useState(null);

  /*
  display user name
  */
  const { userProfile } = useProfile();
  const displayName = userProfile
    ? userProfile["displayName"] !== null
      ? userProfile["displayName"].split(" ")[0]
      : userProfile["email"]
    : "";
  const nameFull = userProfile?.displayName || "NA DA";

  useEffect(() => {
    setProfileUrl(user?.user_info?.img_profile);
  }, [user]);

  function stringToColor(string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = "#";

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.substr(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
  }

  function stringAvatar(name) {
    return {
      sx: {
        bgcolor: stringToColor(name),
      },
      children: `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`,
    };
  }
  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item waves-effect"
          id="page-header-user-dropdown"
          tag="button"
          style={{ display: "flex", alignItems: "center" }}
        >
          {profileUrl ? (
            <img
              className="rounded-circle header-profile-user"
              src={profileUrl}
            />
          ) : (
            <div className="rounded-circle header-profile-user">
              <Avatar
                style={{
                  width: "100%",
                  height: "100%",
                  fontSize: 17,
                  fontWeight: "normal",
                }}
                {...stringAvatar(nameFull)}
              />
            </div>
          )}
          <span className="d-none d-xl-inline-block ms-1 fw-medium font-size-15">
            {displayName}
          </span>
          {user?.code && (
            <span className="d-none d-xl-inline-block ms-1 fw-medium font-size-15">
              {` - ${user.code}`}
            </span>
          )}

          <i className="uil-angle-down d-none d-xl-inline-block font-size-15"></i>
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          <Link
            to={isSolar ? "/solar/profile" : "/profile"}
            className="dropdown-item"
          >
            <i className="uil uil-user-circle font-size-18 align-middle text-muted me-1"></i>
            Ver Perfil
          </Link>
          {/* <div className="dropdown-divider" /> */}
          <Link to="/logout" className="dropdown-item">
            <i className="uil uil-sign-out-alt font-size-18 align-middle me-1 text-muted"></i>
            Sair
          </Link>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

ProfileMenu.propTypes = {
  success: PropTypes.any,
  t: PropTypes.any,
};

const mapStatetoProps = state => {
  const { error, success } = state.Profile;
  return { error, success };
};

export default withRouter(
  connect(mapStatetoProps, {})(withTranslation()(ProfileMenu))
);
