import React from "react";
export default function IconTote(props) {
  const colorStroke = props?.colorStroke || "#FF601A";
  const width = props?.width || 23;
  const height = props?.height || 23;

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 23 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.3987 19.4846H4.51046C4.34134 19.4846 4.17816 19.4223 4.05215 19.3095C3.92613 19.1967 3.84613 19.0414 3.82746 18.8733L2.60577 7.87808C2.59509 7.78202 2.6048 7.6848 2.63426 7.59275C2.66371 7.5007 2.71225 7.4159 2.77671 7.34388C2.84117 7.27187 2.92009 7.21426 3.00832 7.17482C3.09656 7.13538 3.19212 7.11499 3.28876 7.11499H19.6204C19.7171 7.11499 19.8126 7.13538 19.9009 7.17482C19.9891 7.21426 20.068 7.27187 20.1325 7.34388C20.1969 7.4159 20.2455 7.5007 20.2749 7.59275C20.3044 7.6848 20.3141 7.78202 20.3034 7.87808L19.0817 18.8733C19.0631 19.0414 18.9831 19.1967 18.8571 19.3095C18.731 19.4223 18.5679 19.4846 18.3987 19.4846Z"
        stroke={colorStroke}
        strokeWidth="1.82857"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.01953 9.86389V7.11509C8.01953 6.2038 8.38154 5.32984 9.02591 4.68546C9.67029 4.04108 10.5443 3.67908 11.4555 3.67908C12.3668 3.67908 13.2408 4.04108 13.8852 4.68546C14.5295 5.32984 14.8915 6.2038 14.8915 7.11509V9.86389"
        stroke={colorStroke}
        strokeWidth="1.82857"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
