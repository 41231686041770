import React from "react";
export default function iconUser(props) {
  const colorFill = props?.colorFill?.replaceAll("#", "") || "none";

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill={colorFill}
    >
      <path
        d="M10 12.5C12.7614 12.5 15 10.2614 15 7.5C15 4.73858 12.7614 2.5 10 2.5C7.23858 2.5 5 4.73858 5 7.5C5 10.2614 7.23858 12.5 10 12.5Z"
        stroke="#FF601A"
        strokeWidth="1.73913"
        strokeMiterlimit="10"
      />
      <path
        d="M2.42188 16.8757C3.18979 15.5453 4.2944 14.4406 5.62465 13.6725C6.9549 12.9044 8.46392 12.5 10 12.5C11.5361 12.5 13.0451 12.9044 14.3753 13.6725C15.7056 14.4406 16.8102 15.5453 17.5781 16.8757"
        stroke="#FF601A"
        strokeWidth="1.73913"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
