import { Card, CardBody, React } from "./helpers/importer.helper";

export default function CardDefault(props) {
  return (
    <>
      <Card className="card solar-styles solar-shadow position-relative">
        {props.checkbox && (
          <div
            style={{ right: 31 }}
            id="i-have-demand"
            className="position-absolute d-flex"
          >
            <input
              type="checkbox"
              className="mx-3"
              name=""
              id="checkbox-contracted-demand"
              onChange={props.onChange}
            />
            <label
              htmlFor="checkbox-contracted-demand"
              className="d-flex j-c-center cursor-pointer"
            >
              <div>
                <strong className="font-size-12">
                  Tenho demanda contratada
                </strong>
              </div>
            </label>
          </div>
        )}
        <CardBody>
          {props.title && (
            <div className="d-flex">
              <i>{props.icon}</i>
              <h2 className="main-title mx-3 mb-0">{props.title}</h2>
            </div>
          )}
          {props.description && (
            <div className="d-flex justify-content-start">
              <p className="description">{props.description}</p>
            </div>
          )}
          {props.children && (
            <div className="d-flex justify-content-start h-100">
              {props?.children}
            </div>
          )}
        </CardBody>
      </Card>
    </>
  );
}
