import React, { useState } from 'react';
import { Container, Row, Col, Button, Input, FormGroup, Label, Form, Alert, Card, CardBody } from 'reactstrap';
import "./cartaoPlanilhas.css";


const CartaoPlanilhas = () => {
    const [showedOpt, setShowedOpt] = useState(0)
    return (
        <React.Fragment>
            <div className="page-content cartao-planilhas">
                <Container>

                    <Row>
                        <Col md={6}>
                            <Card>
                                <CardBody>
                                    <div className="navegacao">
                                        <ul className="nav nav-pills nav-fill back-nav">
                                            <li className="nav-item">
                                                <a className={showedOpt == 0 ? "nav-link active" : "nav-link"} onClick={() => setShowedOpt(0)}>Planilha Endereços</a>
                                            </li>
                                            <li className="nav-item">
                                                <a className={showedOpt == 1 ? "nav-link active" : "nav-link"} onClick={() => setShowedOpt(1)}>Planilha portador</a>
                                            </li>
                                            <li className="nav-item">
                                                <a className={showedOpt == 2 ? "nav-link active" : "nav-link"} onClick={() => setShowedOpt(2)}>Planilha carga</a>
                                            </li>
                                            <li className="nav-item-planilhas">
                                                <a className={showedOpt == 3 ? "nav-link active" : "nav-link"} onClick={() => setShowedOpt(3)}>Planilha adeptos</a>
                                            </li>
                                        </ul>
                                    </div>
                                    <hr className='linha-planilhas' />

                                    {showedOpt == 0 &&
                                        <div className='planilha-endereco'>
                                            <h4><i className="ph-funnel icone-planilhas"></i>BAIXAR PLANILHA ENDEREÇOS</h4>
                                            <Form>
                                                <FormGroup row>
                                                    <Label
                                                        for="data"
                                                        sm={3}>
                                                        A partir de:
                                                    </Label>
                                                    <Col sm={6}>
                                                        <Input
                                                            id="exampleData"
                                                            name="data"
                                                            placeholder="01/01/2001"
                                                            type="data" />
                                                    </Col>
                                                </FormGroup>
                                            </Form>
                                        </div>
                                    }
                                    {showedOpt == 1 &&
                                        <div className='planilha-portador'>
                                            <h4><i className="ph-funnel icone-planilhas"></i>BAIXAR PLANILHA PORTADORES</h4>
                                            <Form>
                                                <FormGroup row>
                                                    <Label
                                                        for="exampledata"
                                                        sm={3}>
                                                        A partir de:
                                                    </Label>
                                                    <Col sm={6}>
                                                        <Input
                                                            id="data"
                                                            name="data"
                                                            placeholder="01/01/2001"
                                                            type="data" />
                                                    </Col>
                                                </FormGroup>
                                            </Form>
                                        </div>
                                    }
                                    {showedOpt == 2 &&
                                        <div className='planilha-carga'>
                                            <h4><i className="ph-funnel icone-planilhas"></i>BAIXAR PLANILHA CARGA</h4>

                                            <Form>
                                                <FormGroup row>
                                                    <Label
                                                        for="data"
                                                        sm={3}>
                                                        Fechamento
                                                    </Label>
                                                    <Col sm={6}>
                                                        <Input
                                                            id="exampleData"
                                                            name="data"
                                                            placeholder="01/01/2001"
                                                            type="data" />
                                                    </Col>
                                                </FormGroup>
                                            </Form>
                                        </div>
                                    }
                                    {showedOpt == 3 &&
                                        <div className='planilhas-adeptos'>
                                            <h4><i className="ph-funnel icone-planilhas"></i>BAIXAR PLANILHA ADEPTOS</h4>
                                            <div className='alerta'>
                                                <Alert>
                                                    Esta planilha contem todas as revendas que foram adeptas ao cartão
                                                </Alert>
                                            </div>
                                            <Form>
                                                <Label
                                                    for="data"
                                                    sm={3}>
                                                    Status
                                                </Label>
                                                <select name="select" className="mb-3">
                                                    <option value="valor1" selected>Liberado</option>
                                                    <option value="valor2" >Pendente</option>
                                                    <option value="valor3">Aguardando revisão revenda</option>
                                                </select>
                                            </Form>
                                        </div>
                                    }
                                    <hr/>
                                    <Button className='baixar-planilhas'>
                                        <i className="ph-funnel icone-planilhas"> </i>
                                        Baixar Planilha
                                    </Button>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>


                </Container>
            </div>
        </React.Fragment >
    );
};

export default CartaoPlanilhas;