import React from "react";
export default function IconFolder(props) {
  const colorFill = props?.colorFill?.replaceAll("#", "") || "none";
  const colorStroke = props?.colorStroke || "#FF601A";
  const width = props.width || 21;
  const height = props.height || 21;

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 22 21"
      fill={colorFill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.4388 18.3352H5.45425C5.28405 18.3352 5.12082 18.2676 5.00047 18.1472C4.88011 18.0269 4.8125 17.8636 4.8125 17.6934V6.14187C4.8125 5.97167 4.88011 5.80844 5.00047 5.68809C5.12082 5.56774 5.28405 5.50012 5.45425 5.50012H11.8718L15.0805 8.70889V17.6934C15.0805 17.8636 15.0129 18.0269 14.8926 18.1472C14.7722 18.2676 14.609 18.3352 14.4388 18.3352Z"
        stroke={colorStroke}
        strokeWidth="1.28351"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.375 5.50012V3.57486C7.375 3.40465 7.44261 3.24142 7.56297 3.12107C7.68332 3.00072 7.84655 2.93311 8.01675 2.93311H14.4343L17.643 6.14187V15.1264C17.643 15.2966 17.5754 15.4598 17.4551 15.5802C17.3347 15.7005 17.1715 15.7682 17.0013 15.7682H15.076"
        stroke={colorStroke}
        strokeWidth="1.28351"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.02344 12.5594H11.874"
        stroke={colorStroke}
        strokeWidth="1.28351"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.02344 15.1263H11.874"
        stroke={colorStroke}
        strokeWidth="1.28351"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
