import React from "react";

const SearchIcon = ({size = "22", style = {}, color = {}}) => (    
    
    <svg 
        width={size} 
        height={size} 
        viewBox="0 0 24 24" 
        fill="none" 
        style={style}
        xmlns="http://www.w3.org/2000/svg"
    >
        <path 
            d="M16.927 17.04L20.4001 20.4M19.2801 11.44C19.2801 15.7699 15.77 19.28 11.4401 19.28C7.11019 19.28 3.6001 15.7699 3.6001 11.44C3.6001 7.11006 7.11019 3.59998 11.4401 3.59998C15.77 3.59998 19.2801 7.11006 19.2801 11.44Z" 
            stroke={color}
            strokeWidth="2" 
            strokeLinecap="round"
        />
    </svg>
);

export default SearchIcon;
