import { call, put, takeEvery, all, fork } from "redux-saga/effects";
// Crypto Redux States
import { RulesActionTypes } from "./actionTypes";
import { rulesApiSuccess, rulesApiFail } from "./action";

//Include Both Helper File with needed methods
import { getRulesList as getRulesListApi } from "../../api/index";

function* getRules(filters) {
  try {
    const response = yield call(getRulesListApi, filters);
    let list = response;
    yield put(rulesApiSuccess(RulesActionTypes.GET_RULES_LIST, list));
  } catch (error) {
    yield put(rulesApiFail(RulesActionTypes.GET_RULES_LIST, error));
  }
}
export function* watchGetRules() {
  yield takeEvery(RulesActionTypes.GET_RULES_LIST, getRules);
}

function* rulesSaga() {
  yield all([fork(watchGetRules)]);
}

export default rulesSaga;
