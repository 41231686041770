import React, { useState, useEffect } from "react";
import {
  CardBody,
  Card,
  FormGroup,
  Label,
  Input,
  Button,
  Table,
  Spinner,
  Modal,
  ModalHeader,
  ModalBody,
  Tooltip,
} from "reactstrap";
import { formato } from "../../../utils";
import { emitirPedido, editarPedido } from "../../../store/actions";
import { getTermos, saveProspectClient } from "../../../api/index";
import { useDispatch, useSelector } from "react-redux";
import { validaCpfCnpj } from "../../../utils";
import { useHistory, useParams } from "react-router-dom";
import { useSolar } from "../../../hooks";
import { toast } from "react-toastify";

const Resumo = ({
  valTot,
  frete,
  saldo,
  pedido,
  changePedidoEmitido,
  valRt,
  changeType,
  formVisible,
  token,
  percent,
  setLoadingProspect,
  discountValue,
}) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const history = useHistory();
  const { emissaoPedido, order, emailExists } = useSelector(state => ({
    order: state.Order.orderActive.order,
    emissaoPedido: state.Order.newOrder.newOrder.emissaoPedido,
    emailExists: state.Order.dataEmailExists,
  }));

  const [aceiteTerm, setAceiteTerm] = useState(false);
  const [fetch, setFetch] = useState(false);
  const [modalTerm, setModalTerm] = useState(false);
  const [modalPedido, setModalPedido] = useState({ open: false, name: "" });
  const [dataSaveProspectClient, setDataSaveProspectClient] = useState(null);

  const [isTooltipOpen, setIsTooltipOpen] = useState(false);

  const isSolar = useSolar();

  const [termos, setTermos] = useState(<></>);
  var filterCheckedSeguro = pedido.seguro.filter(item => item.checked == true);
  var totalSeguro = filterCheckedSeguro.reduce(
    (total, valor) => total + valor?.valor,
    0
  );

  useEffect(() => {
    const getTermosApi = async company => {
      const response = await getTermos(company);
      setTermos(response);
    };

    getTermosApi(isSolar ? "02" : "01");
  }, []);

  const validarErros = typeFormIsClient => {
    let erros = [];
    if (typeFormIsClient) {
      if (!validaCpfCnpj(pedido.client.cpfcnpj))
        erros.push("CPF ou CNPJ inválido. Revise e tente novamente!");
    }
    if (pedido.items.length < 1)
      erros.push("Não é possível emitir pedido com carrinho vazio.");
    if (!(pedido.filters.condpagto && pedido.filters.tabela_preco))
      erros.push("Tabela e/ou condição de pagamento não selecionados.");
    if (!pedido.shipping.id) erros.push("Frete não selecionado.");
    if (!pedido.client.email) erros.push("Email não preenchido.");

    if (typeFormIsClient) {
      if (pedido.client.cpfcnpj.length <= 11) {
        if (
          !pedido.client.dt_nasc ||
          pedido.client.dt_nasc === "Data inválida"
        ) {
          erros.push("Data de nascimento inválida");
        }
      }
      if (!pedido.client.customer_phones[1].phone)
        erros.push("Celular não preenchido.");
      if (!pedido.client.customer_address[0].number)
        erros.push("Numero de faturamento não preenchido.");
      if (!pedido.client.customer_address[1].number)
        erros.push("Numero de entrega não preenchido.");
      if (!pedido.client.customer_phones[0].phone)
        erros.push("Telefone não preenchido.");
    } else {
      if (!pedido.client.customer_phones[0].phone)
        erros.push("Celular não preenchido.");
    }
    if (typeFormIsClient) {
      if (!(pedido.client.fullname && pedido.client.name_reduced))
        erros.push("Nome e nome reduzido não preenchido.");
    } else {
      if (!pedido.client.fullname) erros.push("Nome  não preenchido.");
    }
    if (typeFormIsClient) {
      if (pedido.client.pessoa == "F" && !pedido.client.dt_nasc)
        erros.push("Data de Nascimento não preenchida.");
    }
    if (
      !(
        pedido.client.customer_address[0].zipcode &&
        pedido.client.customer_address[0].address &&
        pedido.client.customer_address[0].number
      )
    )
      erros.push("Endereço de cobrança não preenchido.");
    if (typeFormIsClient) {
      if (
        !(
          pedido.client.customer_address[1].zipcode &&
          pedido.client.customer_address[1].address &&
          pedido.client.customer_address[1].number
        )
      )
        erros.push("Endereço de entrega não preenchido.");
    }

    if (!aceiteTerm) {
      erros.push(
        "É preciso aceitar os termos de fornecimento para emitir pedidos."
      );
    }
    return erros;
  };

  const openModal = type => {
    let erros = validarErros(formVisible.client);

    if (erros.length > 0) {
      erros.map(value => {
        toast.warning(value, { theme: "colored" });
      });
      return false;
    }
    if (type === "order") {
      token
        ? history.push("/solar/ordenar-por-consumo")
        : setModalPedido({ open: true, name: "order" });
    } else {
      setModalPedido({ open: true, name: "budget" });
    }
  };

  const emitirNovoPedido = async type => {
    var objDataProspect = {};
    var dataSave = null;
    setLoadingProspect(true);

    if (aceiteTerm) {
      if (id) {
        dispatch(
          editarPedido({
            id: order.NumPedido,
            pedido,
          })
        );
      } else {
        if (type == "order") {
          changeType("order");
          dispatch(emitirPedido(pedido));
        }
        if (type == "budget") {
          if (emailExists?.emailExists == "false") {
            objDataProspect = {
              CODEMPRESA: "02",
              CODFILIAL: "02",
              nome: pedido?.client?.fullname,
              email: pedido?.client?.email,
              ddd: pedido?.client?.customer_phones[0]?.ddd,
              telefone: pedido?.client?.customer_phones[0]?.phone,
              cep: pedido?.client?.customer_address[0]?.zipcode,
              endereco: pedido?.client?.customer_address[0]?.address,
              endereco_numero: pedido?.client?.customer_address[0]?.number,
              bairro: pedido?.client?.customer_address[0]?.district,
              cidade: pedido?.client?.customer_address[0]?.city,
              uf: pedido?.client?.customer_address[0]?.state,
              complemento: pedido?.client?.customer_address[0]?.complement,
            };

            dataSave = await saveProspectClient(objDataProspect);

            setDataSaveProspectClient(dataSave);
            setLoadingProspect(false);
          } else {
            changeType("budget");

            dispatch(
              emitirPedido({
                ...pedido,
                type_order: "budget",
                lead_id: token,
                budget_infos: {
                  codigo_cliente: pedido.client.id,
                  tipo_cliente: 0,
                },
              })
            );
          }
        }
      }
    } else {
      toast.warning(
        `É preciso aceitar os termos de fornecimento para emitir pedidos.`,
        { theme: "colored" }
      );
    }
    setModalPedido({ open: !modalPedido, name: "" });
    setLoadingProspect(false);
  };

  useEffect(() => {
    if (dataSaveProspectClient) {
      changeType("budget");

      dispatch(
        emitirPedido({
          ...pedido,
          type_order: "budget",
          lead_id: token,
          budget_infos: {
            codigo_cliente:
              dataSaveProspectClient?.message ==
              "Já existe um prospect com o email informado"
                ? dataSaveProspectClient.data.prospect_id
                : dataSaveProspectClient.prospect,

            tipo_cliente: "prospect",
          },
        })
      );
    }
  }, [dataSaveProspectClient]);

  useEffect(() => {
    if (!emissaoPedido.loading && emissaoPedido.data && fetch) {
      if (emissaoPedido.data?.success) {
        changePedidoEmitido();
      }
      setFetch(false);
    }
  }, [emissaoPedido, fetch]);

  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <h4
            style={{
              fontSize: 23,
              fontWeight: "bold",
              color: "#202833",
              marginBottom: 25,
            }}
          >
            Resumo
          </h4>
          <Table className="mt-0 tabela-resumo mb-0" borderless>
            <tbody>
              <tr>
                <td style={{ padding: "0 0 13px 0" }} colSpan={2}>
                  Valor dos Produtos
                </td>
                <td style={{ padding: "0 0 13px 0", textAlign: "end" }}>
                  {Number(valTot).toLocaleString("pt-br", formato)}
                </td>
              </tr>
              <tr>
                <td style={{ padding: "0 0 13px 0" }} colSpan={2}>
                  Frete
                </td>
                <td style={{ padding: "0 0 13px 0", textAlign: "end" }}>
                  {Number(frete?.price || 0).toLocaleString("pt-br", formato)}
                </td>
              </tr>
              <tr>
                <td style={{ padding: "0 0 13px 0" }} colSpan={2}>
                  Desconto Aplicado
                </td>
                <td style={{ padding: "0 0 13px 0", textAlign: "end" }}>
                  {"- " +
                    (discountValue?.discountValue || 0).toLocaleString(
                      "pt-br",
                      formato
                    )}
                </td>
              </tr>{" "}
              <tr>
                <td style={{ padding: "0 0 13px 0" }} colSpan={2}>
                  Seguro
                </td>
                <td style={{ padding: "0 0 13px 0", textAlign: "end" }}>
                  {(totalSeguro || 0).toLocaleString("pt-br", formato)}
                </td>
              </tr>
              <tr style={{ borderBottom: "1px solid #e7e7e7" }}>
                <td style={{ padding: "0 0 17px 0" }} colSpan={2}>
                  Saldo em conta utilizado
                </td>
                <td style={{ padding: "0 0 17px 0", textAlign: "end" }}>
                  {(saldo || 0).toLocaleString("pt-br", formato)}
                </td>
              </tr>
              <tr style={{ borderBottom: "1px solid #e7e7e7" }}>
                <td
                  style={{
                    display: "flex",
                    alignItems: "center",
                    padding: "17px 0 17px 0",
                  }}
                >
                  Cashback Wattpay
                  <i
                    className="ph-coin"
                    style={{ fontSize: 15, marginLeft: 10 }}
                  ></i>
                </td>
                {}
                <td style={{ padding: "17px 0 17px 0", textAlign: "end" }}>
                  {percent} %
                </td>
                <td style={{ padding: "17px 0 17px 0", textAlign: "end" }}>
                  {(valRt || 0).toLocaleString("pt-br", formato)}
                </td>
              </tr>
              <tr>
                <td style={{ padding: "20px 0 35px 0" }} colSpan={2}>
                  <h4
                    className="mb-0"
                    style={{
                      fontSize: 23,
                      fontWeight: "bold",
                      color: "#202833",
                    }}
                  >
                    Valor Total
                  </h4>
                </td>
                <td style={{ padding: "20px 0 35px 0" }}>
                  <h4
                    className="mb-0"
                    style={{
                      fontSize: 23,
                      fontWeight: "bold",
                      color: "#202833",
                      marginBottom: 25,
                      textAlign: "end",
                    }}
                  >
                    {Number(
                      Number(valTot) +
                        parseFloat(totalSeguro) +
                        Number(frete?.price || 0) -
                        Number(saldo) -
                        Number(discountValue?.discountValue || 0)
                    ).toLocaleString("pt-br", formato)}
                  </h4>
                </td>
              </tr>
            </tbody>
          </Table>
          <FormGroup check className="d-flex">
            <Input
              type="checkbox"
              value="A"
              className="modalidade aceite"
              onClick={e => setAceiteTerm(e.target.checked)}
            />
            <Label
              className={aceiteTerm ? "label-prazo-active" : ""}
              style={{ marginLeft: 20, fontWeight: 500 }}
            >
              Li e concordo com os{" "}
              <a
                href="#"
                onClick={e => {
                  e.preventDefault();
                  setModalTerm(!modalTerm);
                }}
              >
                Termos e condições <br />
                de emissão de pedidos e orçamentos
              </a>
            </Label>
          </FormGroup>
          {emissaoPedido.loading && (
            <div className="carregando-emissao">
              <Spinner />
            </div>
          )}

          <Button
            style={{
              backgroundColor: "#FF601A",
              color: "#FFFFFF",
              width: "100%",
              marginTop: 35,
              display: "block",
              fontSize: 17,
              border: "none",
              padding: 20,
              borderRadius: 6,
            }}
            onClick={() => {
              openModal("budget");
            }}
          >
            {id ? "EDITAR ORÇAMENTO" : "EMITIR ORÇAMENTO"}
          </Button>

          {!token && (
            <>
              <Button
                style={{
                  backgroundColor: !formVisible.client ? "#d6d0cb" : "#FFF7F2",
                  color: !formVisible.client ? "#a6a19d" : "#FF601A",
                  width: "100%",
                  marginTop: 30,
                  display: "block",
                  fontSize: 17,
                  border: "none",
                  padding: 20,
                  borderRadius: 6,
                  cursor: !formVisible.client ? "not-allowed" : "pointer",
                }}
                id="emitir-pedido"
                onClick={() => {
                  if (formVisible.client) {
                    openModal("order");
                  }
                }}
              >
                {token ? "VOLTAR PARA CALCULADORA" : "EMITIR PEDIDO"}
              </Button>
              {!formVisible.client ? (
                <Tooltip
                  target={"emitir-pedido"}
                  isOpen={isTooltipOpen}
                  placement="bottom"
                  toggle={() => setIsTooltipOpen(prev => !prev)}
                  style={{ backgroundColor: "rgb(255, 94, 0)" }}
                >
                  Para emitir pedido é necessario utilizar a opção
                  &#34;Cliente&#34;
                </Tooltip>
              ) : null}
            </>
          )}
        </CardBody>
      </Card>
      <Modal
        isOpen={modalPedido.open}
        size="sm"
        toggle={() => setModalPedido({ open: !modalPedido, name: "" })}
      >
        <ModalHeader
          toggle={() => setModalPedido({ open: !modalPedido, name: "" })}
          className="w-100 justify-content-center"
        >
          Deseja emitir o{" "}
          {modalPedido.name == "budget" ? "orçamento?" : "pedido?"}
        </ModalHeader>
        <ModalBody className="d-flex justify-content-center gap-5">
          <Button
            style={{
              backgroundColor: "#FF601A",
              color: "white",
            }}
            onClick={() => {
              emitirNovoPedido(
                modalPedido.name == "budget" ? "budget" : "order"
              );
              setFetch(true);
            }}
          >
            Confirmar
          </Button>
          <Button
            style={{ backgroundColor: "#E0E0E0", color: "#787878" }}
            onClick={() => setModalPedido({ open: !modalPedido, name: "" })}
          >
            Cancelar
          </Button>
        </ModalBody>
      </Modal>
      <Modal
        isOpen={modalTerm}
        size="lg"
        toggle={() => setModalTerm(!modalTerm)}
      >
        <ModalHeader toggle={() => setModalTerm(!modalTerm)}>
          Termos e condições de emissão de pedidos e orçamentos
        </ModalHeader>
        <ModalBody dangerouslySetInnerHTML={{ __html: termos }}></ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default Resumo;
