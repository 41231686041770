import React, { useEffect, useState } from "react";
import { FormGroup, Input, Label, Card, CardBody, Col, Row } from "reactstrap";
import { formato } from "../../../../utils";

const Seguro = ({ seguro, setSeguro, pedido }) => {
  const inputs = [
    {
      name: "seguroEng",
      title: `SEGURO DE RISCO DE ENGENHARIA`,
      subTitle:
        "Cobertura exclusiva para danos físicos ao gerador fotovoltaico durante a instalação no local da montagem dos equipamentos.",
      link: "https://storage.googleapis.com/clubev4.adias.com.br/produtos/MANUAL%20RISCO%20DE%20ENGENHARIA.pdf",

      checked: false,
    },
    {
      name: "seguroRd",
      title: `SEGURO DE RISCOS DIVERSOS EQUIPAMENTOS`,
      subTitle:
        "Cobertura exclusiva para danos físicos ao gerador fotovoltaico após a instalação.",
      link: "https://storage.googleapis.com/clubev4.adias.com.br/produtos/MANUAL%20RD.pdf",

      checked: false,
    },
  ];

  const getAttValSeguro = () => {
    const newSeguro = seguro.map((item, k) => {
      var val = (pedido.itemsValTot / 100) * item.percent;
      return {
        ...item,
        name: inputs[k].title + `- ${val?.toLocaleString("pt-br", formato)}`,
        valor: val,
      };
    });

    setSeguro(newSeguro);
  };

  useEffect(() => {
    if (pedido?.itemsValTot) {
      getAttValSeguro();
    }
  }, [pedido?.itemsValTot]);
  const handleChange = e => {
    const newSeguro = seguro.map(item => {
      return item.type == e.target.name
        ? { ...item, checked: !item.checked }
        : item;
    });

    setSeguro(newSeguro);
  };
  return (
    <Card>
      <CardBody>
        {" "}
        <h4
          style={{
            fontSize: 23,
            fontWeight: "bold",
            color: "#202833",
            marginBottom: 35,
          }}
        >
          Venda de Seguro
        </h4>
        <FormGroup
          check
          style={{ display: "flex", flexDirection: "column", gap: 10 }}
        >
          {inputs.map((item, k) => (
            <Col md={12} key={k}>
              <Input
                id={item.name}
                type="checkbox"
                value={seguro[k].checked}
                name={item.name}
                className="modalidade"
                onChange={handleChange}
              />
              <Label
                className={seguro[k].checked ? "label-prazo-active" : ""}
                style={{ marginLeft: 10, fontWeight: 400 }}
              >
                <strong>
                  {item.title +
                    `-  ${seguro[k].valor?.toLocaleString("pt-br", formato)}`}
                </strong>
                <br />
                {item.subTitle}{" "}
                <a target="_blank" href={item.link} rel="noreferrer">
                  Mais Detalhes
                </a>
              </Label>
            </Col>
          ))}
        </FormGroup>
      </CardBody>
    </Card>
  );
};

export default Seguro;
