import React from "react";
import { Link } from "react-router-dom";
import { Card, CardBody, Row, Col } from "reactstrap";

const YouWin = ({}) => {
  return (
    <div>
      <div>
        <Row noGutters>
          <Col xs={12}>
            {" "}
            <h4 className="fw-bold mb-4">Cashback</h4>
          </Col>
        </Row>
      </div>
      <Card className="mb-0">
        <CardBody>
          <h5 className="font-size-19 mb-5">Você Ganhou</h5>
          <div className="text-start px-5 px-xs-0">
            <h1 className="mb-3">
              332.95 <span className="font-size-16 text-muted">R$</span>
            </h1>
            <h5 className="text-success mb-3">
              + 4,67 (1.42 %) <i className="mdi mdi-chevron-up" />
            </h5>
            <p className="text-muted mb-4">Apr 15, 1:22 EDT Disclaimer</p>
            <Link to="#" className="btn btn-primary text-dark mb-2">
              VER DETALHES
            </Link>
          </div>
        </CardBody>
      </Card>
    </div>
  );
};

export default YouWin;
