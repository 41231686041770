import React from "react";
export default function Separator(props) {
  return (
    <div className="separator d-flex j-c-center mt-3">
      <span
        style={{
          backgroundColor: props?.color || "#a8a8a8",
          height: "1px",
          width: "100%",
        }}
      />
    </div>
  );
}
