import React from "react";
import { Row, Card, CardBody, Col, Button, Table } from "reactstrap";

import { Link } from "react-router-dom";
//dummy data
import { documentsList, attachments } from "./data";

const Tecnico = () => {
  return (
    <>
      <div className="d-flex align-items-center">
        <div className="me-2">
          <i className="bx bxs-right-arrow font-size-16 mb-2"></i>
        </div>
        <div className="flex-1 overflow-hidden">
          <h5 className="font-size-16">Técnico</h5>
        </div>
      </div>
      <div className="border-top mt-2">
        <Row>
          <Col lg={4} xs={12}>
            <Table className="table table-borderless" responsive>
              <tbody>
                <tr>
                  <th className="text-end" style={{ width: "35%" }}>
                    Nome:{" "}
                  </th>
                  <td>joao carlos mazadri</td>
                </tr>
              </tbody>
            </Table>
          </Col>
          <Col lg={4} xs={12}>
            <Table className="table table-borderless" responsive>
              <tbody>
                <tr>
                  <th className="text-end" style={{ width: "35%" }}>
                    Email:
                  </th>
                  <td>comercial@termoline.com.br</td>
                </tr>
              </tbody>
            </Table>
          </Col>
          <Col lg={4} xs={12}>
            <Table className="table table-borderless" responsive>
              <tbody>
                <tr>
                  <th className="text-end" style={{ width: "35%" }}>
                    Telefone:
                  </th>
                  <td>66 99223 9813</td>
                </tr>
              </tbody>
            </Table>
          </Col>
        </Row>
      </div>
    </>
  );
};

const Cliente = ({}) => {
  return (
    <>
      <div className="d-flex align-items-center">
        <div className="me-2">
          <i className="bx bxs-right-arrow font-size-16 mb-2"></i>
        </div>
        <div className="flex-1 overflow-hidden">
          <h5 className="font-size-16">Cliente</h5>
        </div>
      </div>
      <div className="border-top mt-2">
        <Row>
          <Col lg={6} xs={12}>
            <Table className="table table-borderless" responsive>
              <tbody>
                <tr>
                  <th
                    className="text-end text-uppercase"
                    style={{ width: "35%" }}
                  >
                    Cliente:
                  </th>
                  <td>Colonizadora feliz ltda</td>
                </tr>
                <tr>
                  <th className="text-end">Telefone: </th>
                  <td>(66) 3545-6500</td>
                </tr>
                <tr>
                  <th className="text-end">Endereço:</th>
                  <td>avenida natalino joao brescansin, 1500, 1500</td>
                </tr>
                <tr>
                  <th className="text-end">Bairro:</th>
                  <td>centro-norte</td>
                </tr>
                <tr>
                  <th className="text-end">Cidade:</th>
                  <td>sorriso</td>
                </tr>
                <tr>
                  <th className="text-end">NF:</th>
                  <td>391898</td>
                </tr>
              </tbody>
            </Table>
          </Col>
          <Col lg={6} xs={12}>
            <Table className="table table-borderless" responsive>
              <tbody>
                <tr>
                  <th className="text-end" style={{ width: "35%" }}>
                    CPF/CNPJ:
                  </th>
                  <td>4569998548</td>
                </tr>
                <tr>
                  <th className="text-end">Email:</th>
                  <td>administrativo@groupfrancio.com</td>
                </tr>
                <tr>
                  <th className="text-end">Complemento:</th>
                  <td>-</td>
                </tr>
                <tr>
                  <th className="text-end">CEP:</th>
                  <td>78890134</td>
                </tr>
                <tr>
                  <th className="text-end">Estado:</th>
                  <td>MT</td>
                </tr>
                <tr>
                  <th className="text-end">Data:</th>
                  <td>2021-06-17</td>
                </tr>
              </tbody>
            </Table>
          </Col>
        </Row>
      </div>
    </>
  );
};

const Analise = ({}) => {
  return (
    <>
      <div className="d-flex align-items-center">
        <div className="me-2">
          <i className="bx bxs-right-arrow font-size-16 mb-2"></i>
        </div>
        <div className="flex-1 overflow-hidden">
          <h5 className="font-size-16">Análise do defeito</h5>
        </div>
      </div>
      <div className="border-top mt-2">
        <Row>
          <Col lg={6} xs={12}>
            <Table className="table table-borderless" responsive>
              <tbody>
                <tr>
                  <th
                    className="text-end text-uppercase"
                    style={{ width: "35%" }}
                  >
                    Técnico Responsável:
                  </th>
                  <td>joao carlos mazadri</td>
                </tr>
                <tr>
                  <th className="text-end">Técnico Responsável:</th>
                  <td>euipamento nao liga</td>
                </tr>
                <tr>
                  <th className="text-end">Quais os Testes Realizados:</th>
                  <td>teste com amperimentro na corrente</td>
                </tr>
                <tr>
                  <th className="text-end">Nome da Peça:</th>
                  <td>-</td>
                </tr>
              </tbody>
            </Table>
          </Col>
          <Col lg={6} xs={12}>
            <Table className="table table-borderless" responsive>
              <tbody>
                <tr>
                  <th className="text-end" style={{ width: "35%" }}>
                    Telefone do Técnico:
                  </th>
                  <td>99 99223 9813</td>
                </tr>
                <tr>
                  <th className="text-end">Defeito Constatado:</th>
                  <td>
                    problem compressor fazendo muito barulho, sobe amperagem e
                    nao liga a maquina
                  </td>
                </tr>
                <tr>
                  <th className="text-end">Solução:</th>
                  <td>sem solucao</td>
                </tr>
              </tbody>
            </Table>
          </Col>
        </Row>
      </div>
    </>
  );
};

const Pecas = ({}) => {
  return (
    <>
      <div className="d-flex align-items-center">
        <div className="me-2">
          <i className="bx bxs-right-arrow font-size-16 mb-2"></i>
        </div>
        <div className="flex-1 overflow-hidden">
          <h5 className="font-size-16">Peças solicitadas em garantia</h5>
        </div>
      </div>
      <div className="border-top mt-2">
        <Table className="table table-bordered" responsive>
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">Quantidade</th>
              <th scope="col">Descrição</th>
              <th scope="col">Modelo</th>
              <th scope="col">Cod. Vista Explodida</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>8405</td>
              <td>1</td>
              <td>placa inverter do condensador</td>
              <td>38AGCA12M5</td>
              <td>00</td>
            </tr>
          </tbody>
        </Table>
      </div>
    </>
  );
};

const ItemDaNota = ({}) => {
  return (
    <>
      <div className="d-flex align-items-center">
        <div className="me-2">
          <i className="bx bxs-right-arrow font-size-16 mb-2"></i>
        </div>
        <div className="flex-1 overflow-hidden">
          <h5 className="font-size-16">Item da Nota Fiscal: 000399294</h5>
        </div>
      </div>
      <div className="border-top mt-2">
        <Table className="table table-bordered" responsive>
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">Código</th>
              <th scope="col">Modelo</th>
              <th scope="col">Descrição</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>7352</td>
              <td>FCINVHIW12F2SM2</td>
              <td>38AGCA12M5</td>
              <td className="text-uppercase">
                SPRINGER MIDEA INVERTER CONDENSADORA 12.000 FRIO 220V XTREME
                SAVE
              </td>
            </tr>
          </tbody>
        </Table>
      </div>
    </>
  );
};

const Dados = ({}) => {
  return (
    <>
      <div className="d-flex align-items-center">
        <div className="me-2">
          <i className="bx bxs-right-arrow font-size-16 mb-2"></i>
        </div>
        <div className="flex-1 overflow-hidden">
          <h5 className="font-size-16">Dados para Springer midea</h5>
        </div>
      </div>
      <div className="border-top mt-2">
        <Row>
          <Col lg={6} xs={12}>
            <Table className="table table-borderless" responsive>
              <tbody>
                <tr>
                  <th
                    className="text-end text-uppercase"
                    style={{ width: "35%" }}
                  >
                    Modelo da Evaporadora:
                  </th>
                  <td>45FRADD7852</td>
                </tr>
                <tr>
                  <th className="text-end">Modelo Condensadora:</th>
                  <td>45FRADD7852</td>
                </tr>
              </tbody>
            </Table>
          </Col>
          <Col lg={6} xs={12}>
            <Table className="table table-borderless" responsive>
              <tbody>
                <tr>
                  <th className="text-end" style={{ width: "35%" }}>
                    Série Evaporadora:
                  </th>
                  <td>0566985</td>
                </tr>
                <tr>
                  <th className="text-end">Série Condensadora:</th>
                  <td>45FRADD7852</td>
                </tr>
              </tbody>
            </Table>
          </Col>
        </Row>
      </div>
    </>
  );
};
const Documents = ({ data }) => {
  return (
    <>
      <div className="d-flex align-items-center">
        <div className="me-2">
          <i className="bx bxs-right-arrow font-size-16 mb-2"></i>
        </div>
        <div className="flex-1 overflow-hidden">
          <h5 className="font-size-16">Documents do Fabricante :</h5>
        </div>
      </div>
      <div className="border-top mt-2">
        <Table className="table table-bordered" responsive>
          <thead>
            <tr>
              <th scope="col">Nome</th>
              <th scope="col">Arquivo</th>
              <th scope="col">Descrição</th>
            </tr>
          </thead>
          <tbody>
            {(data || []).map((document, key) => (
              <tr key={key}>
                <td>{document["name"]}</td>
                <td>
                  <Link to={document["fileLink"]}>{document["fileName"]}</Link>
                </td>
                <td>{document["desc"]}</td>
                <td>
                  <Link to={document["fileLink"]}>
                    <i className="mdi mdi-download font-size-24 text-primary"></i>
                  </Link>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </>
  );
};

const Attachments = ({ data }) => {
  return (
    <>
      <div className="d-flex align-items-center">
        <div className="me-2">
          <i className="bx bxs-right-arrow font-size-16 mb-2"></i>
        </div>
        <div className="flex-1 overflow-hidden">
          <h5 className="font-size-16">Arquivos Enviados pelo solicitante:</h5>
        </div>
      </div>
      <div className="border-top mt-2">
        <Table className="table table-bordered" responsive>
          <tbody>
            {(data || []).map((document, key) => (
              <tr key={key}>
                <td>
                  <Link to={document["fileLink"]}>{document["fileName"]}</Link>
                </td>
                <td>{document["date"]}</td>
                <td>{document["desc"]}</td>
                <td>
                  <Link to={document["fileLink"]}>
                    <i className="mdi mdi-download font-size-24 text-primary"></i>
                  </Link>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        <div>
          <h5 className="fw-bold font-size-16 mt-4">
            Utilize essa opção para enviar fotos e documentos obrigatórios
            solicitados por esse fabricante.
          </h5>
          <p>
            Somente Arquivos de Fotos, Documentos do Microsoft Office e PDF são
            aceitos com até 8MB. Você pode selecionar mais de um arquivo usando
            a tecla SHIFT ou CTRL.
          </p>
          <div className="text-end mb-3">
            <Button color="primary">
              <i className="bx bx-plus-medical me-2"></i>Selecionar Arquivos
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

const Detalhes = () => {
  return (
    <Card>
      <CardBody>
        <div className="p-1">
          <div className="d-flex align-items-center">
            <div className="flex-1 overflow-hidden">
              <h5 className="font-size-20">Detalhes</h5>
            </div>
          </div>
        </div>
        <div className="pt-4 border-top">
          <Row>
            <Col xs={12}>
              <div className="p-1 pb-3">
                <Tecnico />
              </div>
            </Col>
            <Col xs={12}>
              <div className="p-1 pb-3">
                <Cliente />
              </div>
            </Col>

            <Col xs={12}>
              <div className="p-1 pb-3">
                <Analise />
              </div>
            </Col>
            <Col xs={12}>
              <div className="p-1 pb-3">
                <Pecas />
              </div>
            </Col>
            <Col xs={12}>
              <div className="p-1 pb-3">
                <ItemDaNota />
              </div>
            </Col>
            <Col xs={12}>
              <div className="p-1 pb-3">
                <Dados />
              </div>
            </Col>
            <Col xs={12}>
              <div className="p-1 pb-3">
                <Documents data={documentsList} />
              </div>
            </Col>

            <Col xs={12}>
              <div className="p-1 pb-3">
                <Attachments data={attachments} />
              </div>
            </Col>
          </Row>
        </div>
      </CardBody>
    </Card>
  );
};

export default Detalhes;
