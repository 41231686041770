import styled from "styled-components";

export const TituloProtheus = styled.h3`
Font-size: 26px;
color: #666;
margin-botton: 20px;
`


export const Card = styled.div`
width: 100%;
background-color: #fff;
padding: 12px 20px 15px;
margin-bottom: 20px;
margin-top: 30px;
`

export const TituloClassificacao = styled.h4`
Font-size: 18px;
color: #4db3a2 ;
`
export const LinhaCard = styled.hr`
border-top: 1px solid #E7E7E7;
margin-bottom: 10px;
`


export const AgrupaPedidosClube = styled.div`
display: flex;

`
export const AgrupaClube = styled.div`
display: flex;
flex-direction: column;
align-items: center;
width: 250px;
`
export const ImgClube = styled.img`
width: 200px;
margin-top: 40px;
`

export const BotaoClube = styled.button` 
color: #333333;
background-color: white;
border-color: #cccccc;
padding: 6px 13px;
width: fit-content;
border-radius: 25px;
border-width: 1px;
`

export const CategoriaPeriodo = styled.p`
font-size: 13px;
`
export const LinkCategorias = styled.a` 
color: #428bca;
font-size: 13px;
margin-top: 20px;
`

export const AgrupaGrafico = styled.div`
width: 100%;
display: flex;
justify-content: space-between;
align-items: flex-start;
`

export const AgrupaGraficoCategorias = styled.div`
width: 22%;
display: flex;
flex-direction: column;
align-items: center;
margin-top: 45px;
`
export const Grafico = styled.div`
width: 40%;
display: flex;
margin: 0 auto;
`
export const LinkGrafico = styled.a`
display: block;
text-align: center;
color: #333;
font-weight: 300;
font-size: 16px;
margin-top: 5px;
margin-bottom: 10px;

`

export const TextoGrafico = styled.p` 
font-weight: bold;
color: #7c7c7c;
font-size: 13px;
`


export const AgrupaPedidos = styled.div`
flex: 1;
`
export const TituloParceiro = styled.h3`
font-size: 23px;
`

export const LinkVoltar = styled.span`
padding: 8px 16px;
font-size: 13px;
color: #333333;
background-color: #e5e5e5;
display: flex;
align-items: center;
`
export const DivParceiro = styled.div`
display: flex;
justify-content: space-between;
`

export const SubtituloParceiro = styled.h4`
font-size: 17px;
margin-top: 40px;
`

export const AgrupaLinha = styled.div`
display: flex;
align-items: 100%;
align-items: flex-start;
justify-content: space-between;
`

export const AgrupaColuna = styled.div` 
display: flex;
flex-direction: column;
width: ${props => props.size}%;
`

export const InputParceiro = styled.div`
display: flex;
align-items: center;
border: 1px solid #D2D2D2;
width: fit-content;
padding: 4px 24px;
border-radius: 5px;
width: 100%;
background-color: ${props => props.editabled ? "#FFF" : "#d4d4d4"}
`

export const Icone = styled.p`
font-size: 15px;
padding-right: 10px;
border-right: 1px solid #D2D2D2;
display: flex;
margin-bottom: 0;
margin-right: 20px;
color: #A8A8A8
`

export const InputUser = styled.input`
border: none;
font-size: 16px;
outline: none;
flex: 1;
min-width: 20px;
background-color: transparent
`
export const InputUserSelect = styled.select`
border: none;
font-size: 16px;
outline: none;
flex: 1;
min-width: 20px;
background-color: transparent
`
export const Etiqueta = styled.label`
font-weight: 500;
font-size: 16px;
color: #696969;
margin-top: 20px

`

export const Alerta = styled.div` 
background-color: #d9edf7;
border-color: #bce8f1;
color: #31708f;
font-size: 15px;
margin-top: 20px;
margin-bottom: 20px;
width: 100%;
padding: 20px;
`

export const BotaoEnviar = styled.button`
    background-color: #35aa47;
    color: #FFF;
    padding: 10px 25px;
    display: block;
    border: 1px solid #35aa47;
    border-radius: 5px;
    font-size: 16px;
    font-weight: 500;
    margin-top: 50px;
`

export const IconeTitulo = styled.span`
    margin-right: 20px
`

export const TelefoneInput = styled.input`
    font-size: 16px;
    padding: 4px;
    outline: none;
    border-radius: 5px;
    width: ${props => props.width}%;
    border: 1px solid #D2D2D2;
    margin-right: 20px;
`

export const TelefoneCampo = styled.div`
    width: 60%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 40px;
`

export const BotaoTelefone = styled.button`
    background-color: ${props => props.color};
    color: #FFF;
    padding: 4px 8px;
    display: block;
    border: 1px solid ${props => props.color};
    border-radius: 5px;
    font-size: 16px;
    margin-right: 10px;
`

export const CampoBotoes = styled.div`
    display: flex;
    flex: 1
`