import { AvGroup, AvField } from "availity-reactstrap-validation";
import React, { useState, useEffect } from "react";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import {
  Card,
  CardBody,
  Col,
  Row,
  Input,
  Label,
  InputGroup,
  InputGroupText,
} from "reactstrap";
import "./cadastrarrevenda.css";
import { getConsultor as getConsultorApi } from "../../../api/index";

const DadosAdicionais = ({ formData, setFormData }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [vendedores, setVendedores] = useState([]);
  const [parceiros, setParceiros] = useState([]);

  const filterBy = () => true;

  const changeVendedores = inputValue => {
    setIsLoading(true);
    setVendedores([]);

    getConsultorApi(inputValue).then(res => {
      let data = [];
      res.map(value => {
        data.push({
          codigo: value.CODIGO,
          nome: value.NOME,
          label: `${value.CODIGO} - ${value.NREDUZ.trim()}`,
          nreduz: value.NREDUZ,
          cnpj: value.CPFCNPJ,
        });
      });

      setVendedores(data);
      setIsLoading(false);
    });
  };

  useEffect(() => {
    if (parceiros.length > 0) {
      setFormData({
        ...formData,
        codConsultor: parceiros[0].codigo,
        nomeConsultor: parceiros[0].nome,
      });
    }
  }, [parceiros]);

  parceiros[0]?.codigo || "";

  return (
    <>
      <Card className="p-2">
        <CardBody className="card-body">
          <h4 className="titulo-card">Dados Adicionais</h4>
          <div className="mx-4">
            <Row>
              <Col md={4} className="mt-3">
                <Label>Ramo de Atividade</Label>
                <AvGroup>
                  <AvField
                    type={"select"}
                    className="form-select form-control input-dados"
                    name="rmatividade"
                    id="ramo"
                    onChange={e =>
                      setFormData({
                        ...formData,
                        rmAtividade: e.target.value,
                      })
                    }
                    value={formData.rmAtividade ?? ""}
                    required
                    errorMessage={"Campo obrigatório!"}
                  >
                    <option disabled defaultValue value="">
                      Selecione uma opção
                    </option>
                    <option value="revenda">Revenda / Instalador</option>
                    <option value="arquiteto">Arquiteto / Engenheiro</option>
                    <option value="solar">Energia Solar</option>
                  </AvField>
                </AvGroup>
              </Col>
              <Col md={4} className="mt-3">
                <Label>Como conheceu a A.Dias</Label>
                <AvGroup>
                  <AvField
                    type={"select"}
                    required
                    name={"como_conheceu"}
                    className="form-select form-control input-dados"
                    onChange={e =>
                      setFormData({ ...formData, comoConheceu: e.target.value })
                    }
                    value={formData.comoConheceu ?? ""}
                    errorMessage={"Campo obrigatório!"}
                  >
                    <option disabled defaultValue value="">
                      Selecione uma opção
                    </option>
                    <option value="google">Google</option>
                    <option value="indicacao_revenda">Indicação revenda</option>
                    <option value="indicacao_fabrica">Indicação fábrica</option>
                    <option value="indicacao_cliente">Indicaçao cliente</option>
                    <option value="revista">Revista</option>
                    <option value="radio">Rádio</option>
                    <option value="ativo_prospect">Ativo no prospect</option>
                    <option value="site">Site</option>
                    <option value="visita">Recebi uma visita da A.Dias</option>
                    <option value="cadastro_supervisor">
                      Cadastro feito pelo supervisor em visita
                    </option>
                    <option value="outros">Outros</option>
                  </AvField>
                </AvGroup>
              </Col>
              {formData.comoConheceu == "indicacao_revenda" ||
              formData.comoConheceu == "indicacao_fabrica" ||
              formData.comoConheceu == "indicacao_cliente" ? (
                <Col md={4} className="mt-3">
                  <Label>Descreva</Label>
                  <Input
                    name="nome_indicou"
                    className="form-control input-dados"
                    placeholder="Digite aqui..."
                    value={formData.nomeIndicou ?? ""}
                    onChange={e =>
                      setFormData({ ...formData, nomeIndicou: e.target.value })
                    }
                  />
                </Col>
              ) : (
                <Col md={4} />
              )}
            </Row>
            <Row>
              <Col md={4} className="mt-3">
                <Label>Nome do Consultor A.Dias</Label>
                <Input
                  name="nome_consultor"
                  value={formData.nomeConsultorAdias ?? ""}
                  className="form-control input-dados"
                  placeholder="Digite aqui..."
                  onChange={e =>
                    setFormData({
                      ...formData,
                      nomeConsultorAdias: e.target.value,
                    })
                  }
                />
              </Col>
              {formData.idRevenda == "" ||
              formData.statusSituacao == "F" ||
              formData.statusSituacao == "G" ? (
                <>
                  <Col md={4} className="mt-3">
                    <Label>Consultor</Label>
                    <InputGroup>
                      <InputGroupText className="bg-dark-blue text-white border-0">
                        <i className="ph-magnifying-glass lupa h4 p-0 m-0 text-white"></i>
                      </InputGroupText>
                      <AsyncTypeahead
                        id="parceiro"
                        className="titulo-input w-75"
                        onChange={setParceiros}
                        options={vendedores}
                        placeholder="Buscar vendedor"
                        selected={parceiros}
                        minLength={3}
                        filterBy={filterBy}
                        onSearch={changeVendedores}
                        emptyLabel="Não foi encontrado nenhum vendedor"
                        promptText="Buscando vendedores"
                        searchText="Buscando vendedores"
                        isLoading={isLoading}
                        inputProps={{
                          id: "parceiro-selecionado",
                        }}
                        renderMenuItemChildren={option => (
                          <div>
                            {option.codigo} {option.nreduz}
                            <div>
                              <small>{option.nome}</small>
                            </div>
                          </div>
                        )}
                      />
                    </InputGroup>
                  </Col>
                  <Col md={4} className="mt-3">
                    <Label>Código do Consultor</Label>
                    <Input
                      name="cod_sup"
                      id="cod_consultor"
                      value={parceiros[0]?.codigo || ""}
                      disabled
                      className="input-dados"
                      onChange={e =>
                        setFormData({
                          ...formData,
                          codConsultor: e.target.value,
                        })
                      }
                    />
                  </Col>
                </>
              ) : (
                ""
              )}
              {formData.idRevenda !== "" ? (
                <>
                  <Col md={4} className="mt-3">
                    <Label>Status</Label>
                    <AvGroup>
                      <AvField
                        type={"select"}
                        className="form-select form-control input-dados"
                        name="status-revenda"
                        required
                        value={formData.statusSituacao ?? ""}
                        onChange={e =>
                          setFormData({
                            ...formData,
                            statusSituacao: e.target.value,
                          })
                        }
                        errorMessage={"Campo obrigatório!"}
                      >
                        <option value="">Selecione uma opção</option>
                        <option value="D">Aprovado</option>
                        <option value="B">Devolver para correção</option>
                        <option value="E">Reprovado</option>
                        <option value="F">Alterar Consultor</option>
                        <option value="G">Alterar Consultor e Aprovar</option>
                      </AvField>
                    </AvGroup>
                  </Col>
                </>
              ) : (
                <Col md={4} />
              )}
              {formData.idRevenda == "" ||
              formData.statusSituacao == "F" ||
              formData.statusSituacao == "G" ? (
                <>
                  <Col md={8}></Col>
                </>
              ) : (
                <>
                  <Col md={4}></Col>
                </>
              )}
            </Row>
          </div>
        </CardBody>
      </Card>
    </>
  );
};
export default DadosAdicionais;
