import React, { useState, useEffect } from 'react';
import { Button, Container, Spinner, Table } from 'reactstrap';
import { TituloProtheus, Card, LinhaCard, TituloParceiro, LinkVoltar, DivParceiro, SubtituloParceiro, AgrupaLinha, AgrupaColuna, InputParceiro, Icone, InputUser, Etiqueta, InputUserSelect, Alerta, BotaoEnviar, IconeTitulo, TelefoneCampo, TelefoneInput, BotaoTelefone, CampoBotoes } from "./style.js";
import 'react-circular-progressbar/dist/styles.css';
import { Link, Redirect, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux";
import { getSeller, updateSeller, deleteTelephone, addTelephone, updateTelephone } from '../../store/seller/action.js';
import SharedResaleModal from '../SharedResale/index.js';
import { useColors } from '../../hooks/useColors.js';
import { useProfile } from '../../hooks/UserHooks.js';
import { GET_FIREBASE_REMOTE_CONFIG_SHARED_RESALES_PERMISSION } from '../../store/firebaseRemoteConfig/actionTypes.js';
import { getFireBaseRemoteConfig } from '../../store/actions.js';

const Vendedor = () => {
    const dispatch = useDispatch();

    const { userProfile } = useProfile();

    const { sharedResalesPermission } = useSelector(state => ({
        sharedResalesPermission: state.FirebaseRemoteConfig.sharedResalesPermission,
    }));

    useEffect(() => {
        if (sharedResalesPermission) {
            setUserIsAllowedToShareResale(sharedResalesPermission.includes(userProfile.claims.code));
        }
    }, [sharedResalesPermission]);

    useEffect(() => {
        dispatch(getFireBaseRemoteConfig("SHARED_RESALE_PERMISSION", "", GET_FIREBASE_REMOTE_CONFIG_SHARED_RESALES_PERMISSION));
    }, []);

    const [userIsAllowedToShareResale, setUserIsAllowedToShareResale] = useState(false);

    const [sharedResalesModalIsOpen, setSharedResalesModalIsOpen] = useState(false);

    const toggleSharedResalesModalModal = () => {
        setSharedResalesModalIsOpen(!sharedResalesModalIsOpen);
    }

    const { colorDefault } = useColors();

    const { id, action } = useParams();
    const { details } = useSelector(state => ({
        details: state.Seller.details,
    }));
    const editabled = action == "edit" ? true : false
    const [seller, setSeller] = useState({
        CPFCNPJ: "",
        NOME: "",
        NOMECURTO: "",
        EMAIL: "",
        telephone: {
            DDD: "",
            TEL: "",
        },
        cel: {
            DDD: "",
            TEL: "",
        },
        A3_XCONTAT: "",
        supervidor: {
            codigo: "",
            nome: "",
        },
        CEP: "",
        ENDERECO: "",
        NUMERO: "",
        BAIRRO: "",
        CIDADE: "",
        ESTADO: "",
        FORNECEDOR: "",
        FORNECEDOR2: "",
        FORNECEDOR3: "",
        FORNECEDOR4: "",
        TEMRT: "",
        A3_XBLQCLU: "",
        A3_XBLQPED: "",
        A3_XBLQEVE: "",
        PERCDESCONTO: "",
    });
    const [newTel, setNewTel] = useState({
        DDD: "",
        TEL: ""
    })
    const [telephones, setTelephones] = useState([]);

    useEffect(() => {
        dispatch(getSeller(id));
    }, [dispatch]);

    useEffect(() => {
        setSeller({
            CPFCNPJ: details.data?.user?.CPFCNPJ || "",
            NOME: details.data?.user?.NOME || "",
            NOMECURTO: details.data?.user?.NREDUZ || "",
            EMAIL: details.data?.user?.EMAIL?.trim() || "",
            telephone: {
                DDD: details.data?.user?.DDD_TEL?.trim() || "",
                TEL: details.data?.user?.TELEFONE?.trim() || "",
            },
            cel: {
                DDD: details.data?.user?.DDD_CEL?.trim() || "",
                TEL: details.data?.user?.CELULAR?.trim() || "",
            },
            A3_XCONTAT: details.data?.user?.A3_XCONTAT?.trim() || "",
            supervidor: {
                codigo: details.data?.user?.CODIGO_SUP?.trim() || "",
                nome: details.data?.user?.SUPERVISOR?.trim() || "",
            },
            CEP: details.data?.user?.CEP?.trim() || "",
            ENDERECO: details.data?.user?.ENDERECO?.trim() || "",
            NUMERO: details.data?.user?.ENDNUM?.trim() || "",
            BAIRRO: details.data?.user?.BAIRRO?.trim() || "",
            CIDADE: details.data?.user?.CIDADE?.trim() || "",
            ESTADO: details.data?.user?.ESTADO?.trim() || "",
            FORNECEDOR: details.data?.user?.FORNECEDOR?.trim() || "",
            FORNECEDOR2: details.data?.user?.FORNECEDOR2?.trim() || "",
            FORNECEDOR3: details.data?.user?.FORNECEDOR3?.trim() || "",
            FORNECEDOR4: details.data?.user?.FORNECEDOR4?.trim() || "",
            TEMRT: (details.data?.user?.TEMRT || "") == "N" ? "N" : "S",
            A3_XBLQCLU: (details.data?.user?.A3_XBLQCLU || "") == "N" ? "N" : "S",
            A3_XBLQPED: (details.data?.user?.A3_XBLQPED || "") == "N" ? "N" : "S",
            A3_XBLQEVE: (details.data?.user?.A3_XBLQEVE || "") == "N" ? "N" : "S",
            PERCDESCONTO: details.data?.user?.PERCDESCONTO?.trim() || "",
        });
        setTelephones(details.data?.telephones || []);
    }, [details.data])

    return (
        <React.Fragment>
            <div className="page-content Vendedor">
                <Container>
                    {details.loading && <Spinner />}
                    {details.fetch && <React.Fragment>
                        <TituloProtheus>Cadastro no Protheus</TituloProtheus>
                        <Card>
                            <DivParceiro>
                                <TituloParceiro>Parceiro #{id} - {details?.data?.user?.NOME || ""}</TituloParceiro>
                                <Link to="/vendedores">
                                    <LinkVoltar><i className="ph-arrow-left"></i>Voltar</LinkVoltar>
                                </Link>
                            </DivParceiro>
                            {userIsAllowedToShareResale && (
                                <Button
                                    style={{ backgroundColor: colorDefault, color: 'white' }}
                                    onClick={() => {
                                        toggleSharedResalesModalModal()
                                    }}>
                                    Compartilhar carteira do vendedor
                                </Button>
                            )}
                            <LinhaCard />
                            <SubtituloParceiro>Dados do Parceiro</SubtituloParceiro>
                            <LinhaCard />
                            <AgrupaLinha>
                                <AgrupaColuna size={47}>
                                    <Etiqueta> CPF / CNPJ </Etiqueta>
                                    <InputParceiro editabled={false}>
                                        <Icone>
                                            <i className="fas fa-landmark"></i>
                                        </Icone>
                                        <InputUser
                                            value={seller.CPFCNPJ}
                                            readOnly={!false}
                                        />
                                    </InputParceiro>
                                </AgrupaColuna>
                                <AgrupaColuna size={47}>
                                    <Etiqueta> Sobrenome/Razão Social </Etiqueta>
                                    <InputParceiro editabled={false}>
                                        <Icone>
                                            <i className="fas fa-address-card"></i>
                                        </Icone>
                                        <InputUser
                                            readOnly={!false}
                                            value={seller.NOME}
                                        />
                                    </InputParceiro>
                                </AgrupaColuna>
                            </AgrupaLinha>
                            <AgrupaLinha>
                                <AgrupaColuna size={27}>
                                    <Etiqueta> Nome / Fantasia </Etiqueta>
                                    <InputParceiro editabled={editabled}>
                                        <Icone>
                                            <i className="fas fa-address-card"></i>
                                        </Icone>
                                        <InputUser
                                            value={seller.NOMECURTO}
                                            readOnly={!editabled}
                                            name="NOMECURTO"
                                            onChange={(e) => setSeller({ ...seller, [e.target.name]: e.target.value })}
                                        />
                                    </InputParceiro>
                                </AgrupaColuna>
                                <AgrupaColuna size={27}>
                                    <Etiqueta> Email</Etiqueta>
                                    <InputParceiro editabled={editabled}>
                                        <Icone>
                                            <i className="fas fa-envelope"></i>
                                        </Icone>
                                        <InputUser
                                            value={seller.EMAIL}
                                            readOnly={!editabled}
                                            name="EMAIL"
                                            onChange={(e) => setSeller({ ...seller, [e.target.name]: e.target.value })}
                                        />
                                    </InputParceiro>
                                </AgrupaColuna>
                                <AgrupaColuna size={27}>
                                    <Etiqueta> Nome Contato </Etiqueta>
                                    <InputParceiro editabled={editabled}>
                                        <Icone>
                                            <i className="fas fa-envelope"></i>
                                        </Icone>
                                        <InputUser
                                            value={seller.A3_XCONTAT}
                                            readOnly={!editabled}
                                            name="A3_XCONTAT"
                                            onChange={(e) => setSeller({ ...seller, [e.target.name]: e.target.value })}
                                        />
                                    </InputParceiro>
                                </AgrupaColuna>
                            </AgrupaLinha>
                            <AgrupaLinha>
                                <AgrupaColuna size={23}>
                                    <Etiqueta> DDD </Etiqueta>
                                    <InputParceiro editabled={editabled}>
                                        <Icone>
                                            <i className="fas fa-dot-circle"></i>
                                        </Icone>
                                        <InputUser
                                            value={seller.telephone.DDD}
                                            readOnly={!editabled}
                                            name="telephone-DDD"
                                            onChange={(e) => setSeller({
                                                ...seller, telephone: {
                                                    ...seller.telephone,
                                                    DDD: e.target.value
                                                }
                                            })}
                                        />
                                    </InputParceiro>
                                </AgrupaColuna>
                                <AgrupaColuna size={23}>
                                    <Etiqueta> Telefone </Etiqueta>
                                    <InputParceiro editabled={editabled}>
                                        <Icone>
                                            <i className="fas fa-phone-alt"></i>
                                        </Icone>
                                        <InputUser
                                            value={seller.telephone.TEL}
                                            readOnly={!editabled}
                                            name="telephone-TEL"
                                            onChange={(e) => setSeller({
                                                ...seller, telephone: {
                                                    ...seller.telephone,
                                                    TEL: e.target.value
                                                }
                                            })}
                                        />
                                    </InputParceiro>
                                </AgrupaColuna>
                                <AgrupaColuna size={23}>
                                    <Etiqueta> DDD </Etiqueta>
                                    <InputParceiro editabled={editabled}>
                                        <Icone>
                                            <i className="fas fa-dot-circle"></i>
                                        </Icone>
                                        <InputUser
                                            value={seller.cel.DDD}
                                            readOnly={!editabled}
                                            name="cel-DDD"
                                            onChange={(e) => setSeller({
                                                ...seller, cel: {
                                                    ...seller.cel,
                                                    DDD: e.target.value
                                                }
                                            })}
                                        />
                                    </InputParceiro>
                                </AgrupaColuna>
                                <AgrupaColuna size={23}>
                                    <Etiqueta> Celular </Etiqueta>
                                    <InputParceiro editabled={editabled}>
                                        <Icone>
                                            <i className="fas fa-phone-alt"></i>
                                        </Icone>
                                        <InputUser
                                            value={seller.cel.TEL}
                                            readOnly={!editabled}
                                            name="cel-TEL"
                                            onChange={(e) => setSeller({
                                                ...seller, cel: {
                                                    ...seller.cel,
                                                    TEL: e.target.value
                                                }
                                            })}
                                        />
                                    </InputParceiro>
                                </AgrupaColuna>
                            </AgrupaLinha>
                            <AgrupaLinha>
                                <AgrupaColuna size={23}>
                                    <Etiqueta> Código Supervisor</Etiqueta>
                                    <InputParceiro editabled={false}>
                                        <Icone>
                                            <i className="fas fa-address-card"></i>
                                        </Icone>
                                        <InputUser
                                            value={seller.supervidor.codigo}
                                            readOnly={!false}
                                        />
                                    </InputParceiro>
                                </AgrupaColuna>
                                <AgrupaColuna size={23}>
                                    <Etiqueta> Supervisor </Etiqueta>
                                    <InputParceiro editabled={false}>
                                        <Icone>
                                            <i className="fas fa-user-circle"></i>
                                        </Icone>
                                        <InputUser
                                            value={seller.supervidor.nome}
                                            readOnly={!false}
                                        />
                                    </InputParceiro>
                                </AgrupaColuna>
                                <AgrupaColuna size={23}>
                                    <Etiqueta> Data de Cadastro</Etiqueta>
                                    <InputParceiro editabled={false}>
                                        <Icone>
                                            <i className="fas fa-calendar-alt"></i>
                                        </Icone>
                                        <InputUser
                                            value={details.data?.dtCadastro?.trim() || ""}
                                            readOnly={!false}
                                        />
                                    </InputParceiro>
                                </AgrupaColuna>
                                <AgrupaColuna size={23}>
                                    <Etiqueta> Último Acesso</Etiqueta>
                                    <InputParceiro editabled={false}>
                                        <Icone>
                                            <i className="fas fa-calendar-alt"></i>
                                        </Icone>
                                        <InputUser
                                            value={details.data?.dtUltimoAcesso?.trim() || ""}
                                            readOnly={!false}
                                        />
                                    </InputParceiro>
                                </AgrupaColuna>
                            </AgrupaLinha>
                            <SubtituloParceiro>Endereço</SubtituloParceiro>
                            <LinhaCard />
                            <AgrupaLinha>
                                <AgrupaColuna size={27}>
                                    <Etiqueta> CEP </Etiqueta>
                                    <InputParceiro editabled={false}>
                                        <Icone>
                                            <i className="fas fa-dot-circle"></i>
                                        </Icone>
                                        <InputUser
                                            value={seller.CEP}
                                            readOnly={!false}
                                        />
                                    </InputParceiro>
                                </AgrupaColuna>
                                <AgrupaColuna size={27}>
                                    <Etiqueta> Endereço</Etiqueta>
                                    <InputParceiro editabled={false}>
                                        <Icone>
                                            <i className="fas fa-map-marker-alt"></i>
                                        </Icone>
                                        <InputUser
                                            value={seller.ENDERECO}
                                            readOnly={!false}
                                        />
                                    </InputParceiro>
                                </AgrupaColuna>
                                <AgrupaColuna size={27}>
                                    <Etiqueta> Número </Etiqueta>
                                    <InputParceiro editabled={false}>
                                        <Icone>
                                            <i className="fas fa-sort-numeric-down-alt"></i>
                                        </Icone>
                                        <InputUser
                                            value={seller.NUMERO}
                                            readOnly={!false}
                                        />
                                    </InputParceiro>
                                </AgrupaColuna>
                            </AgrupaLinha>
                            <AgrupaLinha>
                                <AgrupaColuna size={27}>
                                    <Etiqueta> Bairro </Etiqueta>
                                    <InputParceiro editabled={false}>
                                        <Icone>
                                            <i className="fas fa-map-signs"></i>
                                        </Icone>
                                        <InputUser
                                            value={seller.BAIRRO}
                                            readOnly={!false} />
                                    </InputParceiro>
                                </AgrupaColuna>
                                <AgrupaColuna size={27}>
                                    <Etiqueta> Cidade</Etiqueta>
                                    <InputParceiro editabled={false}>
                                        <Icone>
                                            <i className="fas fa-map"></i>
                                        </Icone>
                                        <InputUser
                                            value={seller.CIDADE}
                                            readOnly={!false}
                                        />
                                    </InputParceiro>
                                </AgrupaColuna>
                                <AgrupaColuna size={27}>
                                    <Etiqueta> Estado </Etiqueta>
                                    <InputParceiro editabled={false}>
                                        <Icone>
                                            <i className="fas fa-map"></i>
                                        </Icone>
                                        <InputUserSelect
                                            value={seller.ESTADO}
                                            readOnly={!false}
                                            disabled={!false}>
                                            <option>{details.data?.user?.ESTADO?.trim() || ""}</option>
                                        </InputUserSelect>
                                    </InputParceiro>
                                </AgrupaColuna>
                            </AgrupaLinha>
                            <SubtituloParceiro>Fornecedores</SubtituloParceiro>
                            <LinhaCard />
                            <AgrupaLinha>
                                <AgrupaColuna size={23}>
                                    <Etiqueta> Fornecedor 1 (Principal) </Etiqueta>
                                    <InputParceiro editabled={false}>
                                        <Icone>
                                            <i className="fas fa-user"></i>
                                        </Icone>
                                        <InputUser
                                            value={seller.FORNECEDOR}
                                            readOnly={!false}
                                        />
                                    </InputParceiro>
                                </AgrupaColuna>
                                <AgrupaColuna size={23}>
                                    <Etiqueta> Fornecedor 2 </Etiqueta>
                                    <InputParceiro editabled={false}>
                                        <Icone>
                                            <i className="fas fa-user"></i>
                                        </Icone>
                                        <InputUser
                                            value={seller.FORNECEDOR2}
                                            readOnly={!false}
                                        />
                                    </InputParceiro>
                                </AgrupaColuna>
                                <AgrupaColuna size={23}>
                                    <Etiqueta> Fornecedor 3 </Etiqueta>
                                    <InputParceiro editabled={false}>
                                        <Icone>
                                            <i className="fas fa-user"></i>
                                        </Icone>
                                        <InputUser
                                            value={seller.FORNECEDOR3}
                                            readOnly={!false}
                                        />
                                    </InputParceiro>
                                </AgrupaColuna>
                                <AgrupaColuna size={23}>
                                    <Etiqueta> Fornecedor 4 </Etiqueta>
                                    <InputParceiro editabled={false}>
                                        <Icone>
                                            <i className="fas fa-user"></i>
                                        </Icone>
                                        <InputUser
                                            value={seller.FORNECEDOR4}
                                            readOnly={!false}
                                        />
                                    </InputParceiro>
                                </AgrupaColuna>
                            </AgrupaLinha>
                            <SubtituloParceiro>Dados Adicionais e Parametros</SubtituloParceiro>
                            <LinhaCard />
                            <AgrupaLinha>
                                <AgrupaColuna size={23}>
                                    <Etiqueta>Possui RT</Etiqueta>
                                    <InputParceiro editabled={false}>
                                        <Icone>
                                            <i className="fas fa-thumbs-up"></i>
                                        </Icone>
                                        <InputUserSelect
                                            readOnly={!false}
                                            disabled={!false}
                                            value={seller.TEMRT}>
                                            <option value="S">Sim</option>
                                            <option value="N">Não</option>
                                        </InputUserSelect>
                                    </InputParceiro>
                                </AgrupaColuna>
                                <AgrupaColuna size={23}>
                                    <Etiqueta>Bloqueio Acesso Clube</Etiqueta>
                                    <InputParceiro editabled={false}>
                                        <Icone>
                                            <i className="fas fa-lock-open"></i>
                                        </Icone>
                                        <InputUserSelect
                                            readOnly={!false}
                                            disabled={!false}
                                            value={seller.A3_XBLQCLU}>
                                            <option value="S">Sim</option>
                                            <option value="N">Não</option>
                                        </InputUserSelect>
                                    </InputParceiro>
                                </AgrupaColuna>
                                <AgrupaColuna size={23}>
                                    <Etiqueta>Bloqueio Pedido</Etiqueta>
                                    <InputParceiro editabled={false}>
                                        <Icone>
                                            <i className="fas fa-lock-open"></i>
                                        </Icone>
                                        <InputUserSelect
                                            readOnly={!false}
                                            disabled={!false}
                                            value={seller.A3_XBLQPED}>
                                            <option value="S">Sim</option>
                                            <option value="N">Não</option>
                                        </InputUserSelect>
                                    </InputParceiro>
                                </AgrupaColuna>
                                <AgrupaColuna size={23}>
                                    <Etiqueta>Bloqueio Eventos</Etiqueta>
                                    <InputParceiro editabled={false}>
                                        <Icone>
                                            <i className="fas fa-lock-open"></i>
                                        </Icone>
                                        <InputUserSelect
                                            readOnly={!false}
                                            disabled={!false}
                                            value={seller.A3_XBLQEVE}>
                                            <option value="S">Sim</option>
                                            <option value="N">Não</option>
                                        </InputUserSelect>
                                    </InputParceiro>
                                </AgrupaColuna>
                            </AgrupaLinha>
                            <AgrupaLinha>
                                <AgrupaColuna size={23}>
                                    <Etiqueta>Percentual Desconto</Etiqueta>
                                    <InputParceiro editabled={false}>
                                        <Icone>
                                            <i className="fas fa-percent"></i>
                                        </Icone>
                                        <InputUser
                                            value={seller.PERCDESCONTO}
                                            readOnly={!false}
                                        />
                                    </InputParceiro>
                                </AgrupaColuna>
                            </AgrupaLinha>
                            {editabled ?
                                <BotaoEnviar onClick={() => {
                                    dispatch(updateSeller({ id, seller }))
                                }}>Salvar Dados</BotaoEnviar>
                                : <React.Fragment>
                                    <SubtituloParceiro>Colaboradores</SubtituloParceiro>
                                    <LinhaCard />
                                    {
                                        details.data.colaboradoresRevenda.length > 0 ?
                                            <Table striped>
                                                <thead>
                                                    <tr>
                                                        <th>
                                                            #CÓDIGO
                                                        </th>
                                                        <th>
                                                            CPF/CNPJ
                                                        </th>
                                                        <th>
                                                            Colaborador
                                                        </th>
                                                        <th>
                                                            Celular
                                                        </th>
                                                        <th>
                                                            Status
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {details.data.colaboradoresRevenda.map((value) => (
                                                        <tr key={value.CODIGO}>
                                                            <td>{value.CODIGO}</td>
                                                            <td>{value.CPFCNPJ}</td>
                                                            <td>{value.NREDUZ}</td>
                                                            <td>{value.DDD_CEL} {value.CELULAR}</td>
                                                            <td></td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </Table>
                                            : <Alerta>Nenhum item encontrado.</Alerta>
                                    }
                                </React.Fragment>}
                            <SharedResaleModal
                                sharedSeller={details}
                                isOpen={sharedResalesModalIsOpen}
                                toggle={toggleSharedResalesModalModal}
                            />
                            <LinhaCard />


                        </Card>
                        <Card>
                            <SubtituloParceiro><IconeTitulo><i className="fas fa-phone-alt"></i></IconeTitulo>Telefones</SubtituloParceiro>
                            <LinhaCard />
                            {telephones.map((value, index) => (
                                <TelefoneCampo key={index}>
                                    <TelefoneInput
                                        type="text"
                                        width={10}
                                        readOnly={!details.data?.cad_filho}
                                        placeholder={`${telephones[index].DDD}`}
                                        onChange={(e) => (telephones[index].DDD = e.target.value)}
                                    />
                                    <TelefoneInput
                                        type="text"
                                        width={60}
                                        readOnly={!details.data?.cad_filho}
                                        placeholder={`${telephones[index].NUMERO}`}
                                        onChange={(e) => (telephones[index].NUMERO = e.target.value)}
                                    />
                                    {details.data?.cad_filho && <CampoBotoes>
                                        <BotaoTelefone onClick={() => dispatch(updateTelephone({ id: id, tel: telephones[index] }))} color='#35aa47'><i className="fas fa-check"></i></BotaoTelefone>
                                        <BotaoTelefone onClick={() => dispatch(deleteTelephone({ id, codigo: value.CODIGO }))} color='#d84a38'><i className="fas fa-times"></i></BotaoTelefone>
                                    </CampoBotoes>}
                                </TelefoneCampo>
                            ))}
                            {details.data.cad_filho && <TelefoneCampo>
                                <TelefoneInput
                                    type="text"
                                    width={10}
                                    placeholder="DDD"
                                    name="DDD"
                                    onChange={(e) => setNewTel({ ...newTel, [e.target.name]: e.target.value })}
                                />
                                <TelefoneInput
                                    type="text"
                                    width={60}
                                    placeholder="TELEFONE"
                                    name="TEL"
                                    onChange={(e) => setNewTel({ ...newTel, [e.target.name]: e.target.value })}

                                />
                                <CampoBotoes>
                                    <BotaoTelefone color='#35aa47' onClick={() => dispatch(addTelephone({ id, tel: newTel }))}><i className="fas fa-check"></i></BotaoTelefone>
                                </CampoBotoes>
                            </TelefoneCampo>}
                        </Card>
                    </React.Fragment>}
                    {details.error && <Redirect to="/vendedores" />}
                </Container >
            </div >
        </React.Fragment >
    );
};

export default Vendedor;