import React from "react";

const HeadCol = ({ label }) => {
  return <div>{label}</div>;
};

const TitleCol = () => {
  return (
    <div>
      Technical Training VRF Trane + A.Dias Rio de Janeiro - 11/23/2021{" "}
    </div>
  );
};

const Actions = () => {
  return (
    <div>
      <i className="fa fa-edit me-2"></i>
      <i className="fa fa-trash"></i>
    </div>
  );
};

export { TitleCol, HeadCol, Actions };
