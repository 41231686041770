import { apiClient } from "./apiCore";
import { apiClient2 } from "./apiCore2";

export const getConsultor = consultor => {
  const baseUrl = `/api/autocomplete/consultor/36/${consultor}`;
  return apiClient2.get(`${baseUrl}`);
};

export const getRegistrationDetails = filters => {
  const baseUrl = "api/cadastro";
  return apiClient.get(`${baseUrl}`, filters);
};

export const getRegistration = id => {
  const baseUrl = `api/cadastro/revenda/${id}`;
  return apiClient.get(baseUrl);
};

export const getSuperiorRevenda = () => {
  const baseUrl = `api/superior`;
  return apiClient.get(baseUrl);
};

export const createOrUpdateRegistration = revendaData => {
  const baseUrl = revendaData.idRevenda
    ? `api/cadastro/revenda/${revendaData.idRevenda}`
    : `api/cadastro/revenda`;
  return apiClient.create(baseUrl, revendaData);
};
