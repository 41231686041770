import { WarrantyActionTypes } from "./actionTypes";

export const warrantyApiSuccess = (actionType, data) => ({
  type: WarrantyActionTypes.API_RESPONSE_SUCCESS,
  payload: { actionType, data },
});

export const warrantyApiFail = (actionType, error) => ({
  type: WarrantyActionTypes.API_RESPONSE_ERROR,
  payload: { actionType, error },
});

// recenet users
export const getWarrantyList = filters => ({
  type: WarrantyActionTypes.GET_WARRANTY_DETAILS,
  payload: filters,
});

export const getInvoice = data => ({
  type: WarrantyActionTypes.GET_INVOICE,
  payload: data,
});

export const createWarranty = data => ({
  type: WarrantyActionTypes.CREATE_WARRANTY,
  payload: data,
});

export const getManufacturerWarranty = data => ({
  type: WarrantyActionTypes.GET_MANUFACTURER,
  payload: data,
});