import React from "react";
import { Col, Container, Row } from "reactstrap";
import Category from "./Category";
import MyRating from "./MyRating";
import PointsCard from "./PointsCard";
import YouWin from "./YouWin";
import Sales from "./Sales";
import AvailableCoupons from "./AvailableCoupons";
import Coupons from "./Coupons";
import RedemptionBenefits from "./RedemptionBenefits";
import Loyalty from "./Loyalty";

//import Common Data
import { purchaseChartData, benefitsProducts } from "../../common/data";

const Rewards = () => {
  return (
    <React.Fragment>
      <div className="page-content">
        <Container>
          <Row>
            <Col md={12}>
              <h4 className="fw-bold">Minha Classificação</h4>
              <MyRating />
            </Col>
          </Row>
          <hr className="mb-3" />

          <Row>
            <Col xs={12}>
              <h4 className="fw-bold mb-4">
                Classificações alcançadas durante os últimos meses
              </h4>
            </Col>
            <Col xs={12} lg={4}>
              <Category />
            </Col>
            <Col md={8}>
              <PointsCard />
            </Col>
          </Row>

          <hr className="mb-4" />

          <Row>
            <Col xs={12} lg={4}>
              <YouWin />
            </Col>
            <Col xs={12} lg={8}>
              <Sales data={purchaseChartData} />
            </Col>
          </Row>
          <hr className="" />
          <Row>
            <Col xs={12} lg={4}>
              <AvailableCoupons />
            </Col>
            <Col xs={12} lg={8}>
              <Coupons />
            </Col>
          </Row>
          <hr className="" />
          <RedemptionBenefits benefitsProducts={benefitsProducts} />
          <hr className="" />
          <Row>
            <Col xs={12}>
              <Loyalty />
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Rewards;
