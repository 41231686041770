import { SellerActionTypes } from "./actionTypes";

export const sellerApiSuccess = (actionType, data) => ({
    type: SellerActionTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, data },
});

export const sellerApiFail = (actionType, error) => ({
    type: SellerActionTypes.API_RESPONSE_ERROR,
    payload: { actionType, error },
});

export const listSellers = (filter = {
    cnpj : "",
    codigo: "",
    nome: ""
}) => ({
    type: SellerActionTypes.LIST_SELLERS,
    payload: filter,
});

export const getSeller = id => ({
    type: SellerActionTypes.GET_SELLER,
    payload: id,
});

export const updateSeller = data => ({
    type: SellerActionTypes.UPDATE_SELLER,
    payload: data
});

export const deleteTelephone = data => ({
    type: SellerActionTypes.DELETE_TELEPHONE,
    payload: data
});

export const addTelephone = data => ({
    type: SellerActionTypes.ADD_TELEPHONE,
    payload: data
});

export const updateTelephone = data => ({
    type: SellerActionTypes.UPDATE_TELEPHONE,
    payload: data
});