import { call, put, takeEvery, all, fork } from "redux-saga/effects";
// Crypto Redux States
import { ManagementActionTypes } from "./actionTypes";
import { managementApiSuccess, managementApiFail } from "./action";

//Include Both Helper File with needed methods
import { getTableaus as getTableausApi } from "../../api/index";

function* getTableaus() {
  try {
    const response = yield call(getTableausApi);
    yield put(
      managementApiSuccess(ManagementActionTypes.GET_TABLEAUS, response)
    );
  } catch (error) {
    yield put(managementApiFail(ManagementActionTypes.GET_TABLEAUS, error));
  }
}
export function* watchGetTableaus() {
  yield takeEvery(ManagementActionTypes.GET_TABLEAUS, getTableaus);
}

function* managementSaga() {
  yield all([fork(watchGetTableaus)]);
}

export default managementSaga;
