import { X } from "phosphor-react";
import React, { useEffect, useRef, useState } from "react";
import { useHistory, withRouter } from "react-router-dom";
import { TituloModal, BotaoEntrar, DivLoading } from "./style";
import { Col, Row, Modal, ModalBody, Spinner, Alert } from "reactstrap";
import { Formik, Form, useFormik } from "formik";
import { initialValuesToFormik, RegisterSchema } from "./mockAndValidation";
import { toast } from "react-toastify";
import { DadosDoCliente } from "./Sections/DadosDoCliente";
import { Contato } from "./Sections/Contato";
import { EnderecoFaturamento } from "./Sections/EnderecoFaturamento";
import { EnderecoEntrega } from "./Sections/EnderecoEntrega";

import { useSolar } from "../../../../hooks";
import { apiClient } from "../../../../api/apiCore";
import { useSelector } from "react-redux";
import { useDataLayer } from "../../../../hooks/DataLayerHooks";

const ModalRegister = ({
  openModalRegisterClient,
  setOpenModalRegisterClient,
  id,
  data,
}) => {
  const { customer } = useSelector(state => ({
    customer: state.Order.newOrder.newOrder.customer,
  }));
  const formRef = useRef();
  const { pushToDataLayer } = useDataLayer();
  const [isOpenEnderecoEntrega, setIsOpenEnderecoEntrega] = useState(true);
  const isSolar = useSolar();
  const history = useHistory();
  const [errorEmail, setErrorEmail] = useState(false);
  const handleSubmit = async (props, { setSubmitting, resetForm }) => {
    if (errorEmail) {
      return;
    }

    const obj = {
      client: {
        fullname: props.N_COMPLETO,
        email: props.EMAIL,

        customer_address: [
          {
            zipcode: props.CEP,
            state: props.UF,
            city: props.MUNICIPIO,
            district: props.BAIRRO,
            address: props.ENDERECO,
            number: props.NumEndCob,
            complement: props.COMPL,
          },
          {
            zipcode: props.CEP_ENT,
            state: props.UF_ENT,
            city: props.MunicipEnt,
            district: props.BAI_ENT,
            address: props.END_ENT,
            number: isOpenEnderecoEntrega ? props.NumEndEnt : props.NumEndCob,
            complement: props.COMPL_ENT,
          },
        ],
        customer_phones: [
          {
            ddd: props.DDD,
            phone: props.TEL,
          },
          {
            ddd: props.DDD_CEL,
            phone: props.CELULAR,
          },
        ],
        cpfcnpj: props.CPFCNPJ,
        pessoa: props.TP_PESSOA == "J" ? "J" : "F",
        customer_type: props.TP_PESSOA == "J" ? "J" : "F",
        social_number: props.TP_PESSOA == "J" ? props.Inscr : props.Rg,
        name_reduced: props.N_REDZ,
        contribution: props.Contrib,
        contact: props.CONTATO,
        job_title: props.CARGO,
        dt_nasc: props.DT_NASC || "",
        loja: "01",
        customer_ficha: {
          AGENCIA: "",
          CONTA: "",
          DTADMISS: "",
          CNPJTRAB: "",
          EMPTRAB: "",
          CAPSOC: "",
          SOCIO: "",
          FATMEN: "",
          FUNCEMP: "",
          CPFREF: "",
          CODIBGEC: "",
          CODIBGEE: "",
        },
      },

      /*  CODEMPRESA: isSolar ? "02" : "01",
      CODFILIAL: "02", */
    };

    try {
      const response = await apiClient.create(`/api/create-client`, obj);

      if (response.erro) {
        return toast.error(
          `Ocorreu um erro tentar cadastrar um cliente ${response.msg}`,
          {
            theme: "colored",
          }
        );
      }

      const eventDataLayerName = 'cadastro_cliente'; 
      pushToDataLayer(eventDataLayerName);

      toast.success("Cliente criado com sucesso!", {
        theme: "colored",
      });

      location.reload();
    } catch (err) {
      toast.error("Ocorreu um erro ao tentar cadastrar um cliente", {
        theme: "colored",
      });
    }

    resetForm();
    setOpenModalRegisterClient(false);
    setSubmitting(false);
  };

  const closeModal = () => {
    setOpenModalRegisterClient(!openModalRegisterClient);
  };

  return (
    <Modal isOpen={openModalRegisterClient} size="lg" toggle={closeModal}>
      <Row style={{ marginBottom: 10 }}>
        <Col md={12} style={{ display: "flex", justifyContent: "flex-end" }}>
          <X
            style={{ marginRight: 10, marginTop: 10, cursor: "pointer" }}
            size={20}
            color="#000"
            weight="duotone"
            onClick={closeModal}
          />
        </Col>
      </Row>
      {customer?.loading && (
        <DivLoading>
          <Spinner size="sm" />
        </DivLoading>
      )}
      <ModalBody>
        <Row>
          <Col md={12}>
            <TituloModal>Cadastro de Cliente</TituloModal>
          </Col>
        </Row>

        <Formik
          initialValues={initialValuesToFormik}
          validationSchema={RegisterSchema}
          onSubmit={handleSubmit}
          innerRef={formRef}
        >
          {({
            errors,
            touched,
            isSubmitting,
            setFieldValue,
            values,
            handleChange,
            handleBlur,
            setErrors,
            setTouched,
            resetForm,
          }) => (
            <Form style={{ maxWidth: 710, margin: "0 auto" }}>
              <DadosDoCliente
                errors={errors}
                touched={touched}
                values={values}
                data={data}
                handleChange={handleChange}
                handleBlur={handleBlur}
                setFieldValue={setFieldValue}
                setErrors={setErrors}
                setTouched={setTouched}
                errorEmail={errorEmail}
                setErrorEmail={setErrorEmail}
              />
              <Contato
                errors={errors}
                touched={touched}
                data={data}
                setFieldValue={setFieldValue}
              />
              <EnderecoFaturamento
                errors={errors}
                touched={touched}
                values={values}
                isOpenEnderecoEntrega={isOpenEnderecoEntrega}
                setFieldValue={setFieldValue}
                setIsOpenEnderecoEntrega={setIsOpenEnderecoEntrega}
              />
              <EnderecoEntrega
                errors={errors}
                touched={touched}
                values={values}
                setFieldValue={setFieldValue}
                isOpenEnderecoEntrega={isOpenEnderecoEntrega}
                setIsOpenEnderecoEntrega={setIsOpenEnderecoEntrega}
              />
              {/*      {values.TP_PESSOA == "F" ? (
                <AnaliseDeCreditoF errors={errors} touched={touched} />
              ) : (
                <AnaliseDeCreditoJ errors={errors} touched={touched} />
              )} */}
              <Row>
                <Col
                  md={12}
                  style={{ display: "flex", justifyContent: "flex-end" }}
                >
                  <BotaoEntrar
                    isSolar={isSolar}
                    type="submit"
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? <Spinner size="sm" /> : "Enviar"}
                  </BotaoEntrar>
                </Col>
              </Row>
            </Form>
          )}
        </Formik>
      </ModalBody>
    </Modal>
  );
};

export default withRouter(ModalRegister);
