import { apiClient } from "./apiCore";

const getSharingHistory = sellerId => {
  const baseUrl = `/api/seller/sharingHistory/${sellerId}`;
  return apiClient.get(`${baseUrl}`);
};

const insertSharing = data => {
  const baseUrl = `/api/seller/share`;
  return apiClient.create(`${baseUrl}`, data);
};

const deleteSharing = sharingId => {
  const baseUrl = `api/seller/share/${sharingId}`;
  return apiClient.delete(`${baseUrl}`);
};

export { getSharingHistory, insertSharing, deleteSharing };
