import React from "react";
export default function IconTree(props) {
  const colorFill = props?.colorFill?.replaceAll("#", "") || "none";
  const colorStroke = props?.colorStroke || "#000";
  const width = props?.width || 21;
  const height = props?.height || 21;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 21 21"
      fill={colorFill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.5 19.0312V7.21875"
        stroke={colorStroke}
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.5 12.4688L6.5625 10.5"
        stroke={colorStroke}
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.5 10.5L14.4375 8.53125"
        stroke={colorStroke}
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.8656 14.8641C11.4677 15.2741 12.1485 15.5544 12.8648 15.6871C13.5811 15.8198 14.3171 15.802 15.0262 15.6347C15.7352 15.4675 16.4016 15.1545 16.9832 14.7158C17.5647 14.277 18.0485 13.722 18.4039 13.0861C18.7593 12.4502 18.9784 11.7473 19.0474 11.0221C19.1164 10.2969 19.0337 9.56523 18.8045 8.87372C18.5754 8.18222 18.2049 7.54596 17.7166 7.00542C17.2282 6.46488 16.6327 6.03188 15.9679 5.73398C15.8219 5.6606 15.706 5.53882 15.6398 5.38945C15.2145 4.37633 14.4995 3.51138 13.5845 2.9031C12.6695 2.29483 11.5952 1.97034 10.4964 1.97034C9.39767 1.97034 8.32338 2.29483 7.40836 2.9031C6.49334 3.51138 5.77834 4.37633 5.35306 5.38945C5.28688 5.53882 5.17091 5.6606 5.02494 5.73398C4.36016 6.03188 3.76465 6.46488 3.2763 7.00542C2.78794 7.54596 2.41742 8.18222 2.1883 8.87372C1.95918 9.56523 1.87649 10.2969 1.94547 11.0221C2.01445 11.7473 2.23359 12.4502 2.58897 13.0861C2.94435 13.722 3.42818 14.277 4.00969 14.7158C4.5912 15.1545 5.25766 15.4675 5.96668 15.6347C6.67571 15.802 7.41177 15.8198 8.12806 15.6871C8.84436 15.5544 9.5252 15.2741 10.1273 14.8641C10.2369 14.7921 10.3653 14.7537 10.4964 14.7537C10.6276 14.7537 10.7559 14.7921 10.8656 14.8641Z"
        stroke={colorStroke}
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
