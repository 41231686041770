import React, { useState, useEffect, useCallback } from "react";
import { Card, CardBody, Col, Collapse, Container, Row } from "reactstrap";
import classnames from "classnames";
import { useSelector, useDispatch } from "react-redux";

// actions
import { getEventDetails, deleteEvents } from "../../../store/actions";

//import Breadcrumb
import Breadcrumb from "../../../components/Breadcrumb";
import EventList from "./EventList";
import Calender from "./Calendar";
import DeleteModal from "./DeleteModal";

const Index = () => {
  const dispatch = useDispatch();
  const { eventDetails, getEventDetailsLoading, isEventDeleted } = useSelector(
    state => ({
      eventDetails: state.Events.eventDetails,
      getEventDetailsLoading: state.Events.getEventDetailsLoading,
      isEventDeleted: state.Events.isEventDeleted,
    })
  );

  /*
  get dashboard details
  */
  useEffect(() => {
    dispatch(getEventDetails());
  }, [dispatch]);

  /*
    collapse handeling
    */
  const [isOpen, setIsOpen] = useState(true);
  const toggleCollapse = () => {
    setIsOpen(!isOpen);
  };

  /***
   * Delete events
   */
  const [modalshow, setModalshow] = useState(false);

  const [event, setEvent] = useState(null);
  const onClickDelete = eventId => {
    setEvent(eventId);
    setModalshow(true);
  };

  const closeDeleteModal = useCallback(() => {
    setModalshow(false);
  }, []);

  const onDeleteEvent = () => {
    dispatch(deleteEvents(event));
  };

  useEffect(() => {
    if (isEventDeleted) {
      closeDeleteModal();
    }
  }, [isEventDeleted]);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container>
          {/* Render Breadcrumb */}
          <Breadcrumb title="Eventos" breadcrumbItem="Ver Eventos" />
          <Row>
            <Col md={12}>
              <Card>
                <CardBody>
                  <div>
                    <i className="fa fa-globe me-2"></i>
                    <span className="h5">Próximos Eventos Ativos</span>
                    <span className="float-end">
                      <i
                        className={classnames(
                          "fas",
                          { "fa-chevron-up": isOpen },
                          { "fa-chevron-down": !isOpen }
                        )}
                        onClick={toggleCollapse}
                      ></i>
                    </span>
                  </div>
                  <hr />
                  <Collapse isOpen={isOpen}>
                    <div>
                      {/* events list start */}
                      <Row>
                        <Col md={12}>
                          <EventList
                            data={eventDetails["list"] || []}
                            getEventDetailsLoading={getEventDetailsLoading}
                            onClickDelete={onClickDelete}
                          />
                        </Col>
                      </Row>
                      {/* events list end */}
                    </div>
                  </Collapse>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col md={12}>
              <Card>
                <CardBody>
                  <Row>
                    <Col md={12} className="mb-4">
                      <i className="bx bx-calendar text-dark-blue font-size-14 me-2"></i>
                      <span className="h5 text-dark-blue">CALENDÁRIO</span>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12}>
                      <Calender events={eventDetails["calendar"] || []} />
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>

      {/* <DeleteModal /> */}
      <DeleteModal
        show={modalshow}
        onClose={closeDeleteModal}
        onDelete={onDeleteEvent}
      />
    </React.Fragment>
  );
};

export default Index;
