import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Container, Card, CardBody, Spinner, Button } from "reactstrap";
import Breadcrumb from "../../../../components/Breadcrumb";
import "../../../../pages/ConsultarOrcamento/consulta.css";

import DataTable from "react-data-table-component";
import { Link, useHistory } from "react-router-dom";
import OrcamentoFilter from "./orcamento-filter";

import { formatInvertDate } from "../../../../utils";
import {
  initialValuesToFormik,
  initialValuesToFormikParts,
  inversor,
  modulo,
} from "../register/mockAndValidation";
import axios from "axios";
import { DotsThreeVertical, Trash } from "phosphor-react";
import { apiClient } from "../../../../api/apiCore";
import ConfirmationModal from "../../../../components/ConfirmationModal";
import { useCallback } from "react";
import { toast } from "react-toastify";

export default function List() {
  const history = useHistory();
  const [isOpen, setisOpen] = useState(false);
  const [optionsData, setOptionsData] = useState(inversor);
  const [loadingTable, setLoadingTable] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [toggleCleared, setToggleCleared] = useState(false);
  const [isOpenDelete, setIsOpenDelete] = useState(false);

  const [columns, setColumns] = useState([
    {
      name: "Carregando...",
      selector: row => "",
    },
  ]);

  const [data, setData] = useState([]);

  const getData = async (
    optionsData,
    nome = "",
    fabricante = "",
    potenciaOverload = "",
    grid = "",
    categoria = ""
  ) => {
    setLoadingTable(true);
    const obj = {
      tipo: optionsData,
      nome: nome,
      fabricante: fabricante,
      potenciaOverload: potenciaOverload,
      grid: grid,
      categoria: categoria,
    };
    const resp = await apiClient.create("/api/solar-products/list", obj);
    setData(resp);
    setLoadingTable(false);
  };

  useEffect(() => {
    if (optionsData) {
      getData(optionsData);
    }
  }, [optionsData]);

  const getColumns = () => {
    const initialValues = initialValuesToFormikParts(optionsData);

    let arrayColumns = [];
    Object.keys(initialValues).map((item, k) =>
      arrayColumns.push({
        name: item,
        width: "200px",
        selector: row => row[item],
      })
    );

    setColumns(arrayColumns);
  };

  useEffect(() => {
    getColumns();
  }, [optionsData]);

  const paginationComponentOptions = {
    rowsPerPageText: "Resultados por página",
    rangeSeparatorText: "de",
    selectAllRowsItem: true,
    selectAllRowsItemText: "Todos",
  };

  const PendingComponent = () => {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: 200,
        }}
      >
        <Spinner />
      </div>
    );
  };

  const NoDataComponent = () => {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: 200,
        }}
      >
        Nenhum dado econtrado...
      </div>
    );
  };

  const handleRowSelected = React.useCallback(state => {
    setSelectedRows(state.selectedRows);
  }, []);

  const handleDelete = async () => {
    var dataArray = [...data];
    var arrayIds = [];
    selectedRows.map(r => {
      const found = dataArray.findIndex(element => element.id == r.id);

      if (found >= 0) {
        arrayIds.push(`${r.id}`);
        dataArray.splice(found, 1);
      }
    });

    const obj = {
      data: {
        tipo: optionsData,
        ids: arrayIds,
      },
    };

    apiClient.delete("/api/solar-products/delete", obj);
    setToggleCleared(state => !state);
    setData(dataArray);
    toast("Removido com sucesso!", { theme: "colored" });
    onCloseDelete();
  };

  const ContextActions = () => {
    return (
      <Button
        key="delete"
        onClick={() => setIsOpenDelete(true)}
        style={{ backgroundColor: "red", color: "#fff", width: 120 }}
        icon
      >
        Remover
      </Button>
    );
  };

  const onCloseDelete = useCallback(() => {
    setIsOpenDelete(false);
  }, []);
  return (
    <React.Fragment>
      <div className="page-content consultar-orcamento">
        <Container>
          <Breadcrumb
            title="Página Inicial"
            breadcrumbItem="Consultar Produto"
          />
          <Card className="cardprincipal">
            <CardBody className="card-body">
              <div className="engloba-pesquisa-chev">
                <h5 className="titulo-card">
                  <i className="ph-magnifying-glass lupa"></i>Pesquisa de
                  Produto
                </h5>
                <a href="#" onClick={() => setisOpen(!isOpen)}>
                  {isOpen ? (
                    <i className="ph-caret-down"></i>
                  ) : (
                    <i className="ph-caret-up"></i>
                  )}
                </a>
              </div>
              <hr className="linha-pesquisa" />

              <OrcamentoFilter
                isOpen={isOpen}
                optionsData={optionsData}
                setOptionsData={setOptionsData}
                getData={getData}
              />

              <div style={{ marginTop: 60 }}>
                <DataTable
                  title="Lista de produtos"
                  columns={columns}
                  data={data}
                  progressPending={loadingTable}
                  progressComponent={<PendingComponent />}
                  noDataComponent={<NoDataComponent />}
                  onRowClicked={e =>
                    history.push(
                      `/solar/product-crud/update?tipoForm=${optionsData}&id=${e.id}`
                    )
                  }
                  pagination
                  paginationComponentOptions={paginationComponentOptions}
                  selectableRows
                  contextActions={<ContextActions />}
                  onSelectedRowsChange={handleRowSelected}
                  clearSelectedRows={toggleCleared}
                />
              </div>
            </CardBody>
          </Card>
        </Container>
      </div>{" "}
      <ConfirmationModal
        message={`Confirma remoção ${
          selectedRows.length > 0 ? "destes" : "deste"
        } item?`}
        isOpen={isOpenDelete}
        onClose={onCloseDelete}
        onConfirm={handleDelete}
        loading={false}
      />
    </React.Fragment>
  );
}
