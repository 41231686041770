import styled from "styled-components";

export const TelaToda = styled.div`
  width: 100%;
  min-height: 100vh;
  padding: 40px 120px;

  @media (max-width: 768px) {
    padding: 20px;
  }
`;

export const Card = styled.div`
  width: 100%;

  border-radius: 7.5px;
  padding: 70px;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 100%;
    height: 100%;
    max-width: 576px;
  }
`;
export const Icone = styled.p`
  font-size: 200px;
  display: flex;
  justify-content: center;
`;

export const Texto = styled.p`
  display: flex;
  font-size: 105px;
  font-weight: normal;
  justify-content: center;
`;
export const TextoPagina = styled.p`
  font-size: 40px;
  display: flex;
  justify-content: center;
`;
