import { EventsActionTypes } from "./actionTypes";

export const eventApiSuccess = (actionType, data) => ({
  type: EventsActionTypes.API_RESPONSE_SUCCESS,
  payload: { actionType, data },
});

export const eventApiFail = (actionType, error) => ({
  type: EventsActionTypes.API_RESPONSE_ERROR,
  payload: { actionType, error },
});

// get events
export const getEventDetails = () => ({
  type: EventsActionTypes.GET_EVENTS_DETAILS,
});

//update events
export const updateEvents = (data, eventId) => ({
  type: EventsActionTypes.UPDATE_EVENTS,
  payload: { data, eventId },
});

//delete events
export const deleteEvents = (eventId) => ({
  type : EventsActionTypes.DELETE_EVENT,
  payload : eventId
})
 