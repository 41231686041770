import React, { useState } from 'react';
import { CardBody, Container, Card } from 'reactstrap';
import "./insercao-manual.css";
import Breadcrumb from "../../components/Breadcrumb";
import DataTable from 'react-data-table-component';


const CartaoInsercaoManual = () => {
    const [isHidden, setIsHidden] = useState(false)
    const [temConsulta, setTemConsulta] = useState(true)
    const data = [
        {
            id: 1,
            codigoconsultor: '360141 - MATHEUS GUSTAVO S OLIVEIRA',
            codigobeneficiariocartao: '384388 - Graziela',
            registradoem: '24/01/2020',
            liberadoem: '04/02/2020 10:30:30',
            rtinserida: '',
        },
        {
            id: 2,
            codigoconsultor: '360141 - MATHEUS GUSTAVO S OLIVEIRA',
            codigobeneficiariocartao: '384388 - Graziela',
            registradoem: '24/01/2020',
            liberadoem: '04/02/2020 10:30:38',
            rtinserida: '',
        },
        {
            id: 3,
            codigoconsultor: '360141 - MATHEUS GUSTAVO S OLIVEIRA',
            codigobeneficiariocartao: '384388 - Graziela',
            registradoem: '24/01/2020',
            liberadoem: '04/02/2020 10:30:38',
            rtinserida: '',

        },
        {
            id: 4,
            codigoconsultor: '360141 - MATHEUS GUSTAVO S OLIVEIRA',
            codigobeneficiariocartao: '384388 - Graziela',
            registradoem: '24/01/2020',
            liberadoem: '04/02/2020 10:30:38',
            rtinserida: '',
        }

    ]
    const columns = [
        {
            name: 'Código - Consultor',
            selector: row => row.codigoconsultor,
        },
        {
            name: 'Código - Beneficiário Cartão',
            selector: row => row.codigobeneficiariocartao,
        },
        {
            name: ' Registrado Em',
            selector: row => row.registradoem,
        },

        {
            name: 'Liberado Em',
            selector: row => row.liberadoem,
        },
        {
            name: 'RT Inserida',
            selector: row => row.rtinserida,
            cell: () => (
                <input type="checkbox" />
            ),
        },
    ]
    const paginationComponentOptions = {
        rowsPerPageText: 'Resultados por página',
        rangeSeparatorText: 'de',
        selectAllRowsItem: true,
        selectAllRowsItemText: 'Todos',
    };
    return (
        <React.Fragment>
            <div className="page-content insercao-manual">
                <Container>
                    <Breadcrumb
                        title="Página Inicial"
                        breadcrumbItem="Solicitações de cartão." />
                    {temConsulta &&
                        <Card>
                            <CardBody>
                                <div className="engloba-titulo-icones">
                                    <h2 className='titulo-insercao'><i className="ph-user-plus user-plus"></i>Inserção manual de RT</h2>
                                    <div className="engloba-icones">
                                        <a href="#" onClick={(e) => {
                                            e.preventDefault();
                                            setIsHidden(!isHidden)
                                        }}>
                                            {isHidden ?
                                                <i className="ph-caret-down"></i>
                                                : <i className="ph-caret-up"></i>}
                                        </a>

                                        <a href="#"><i className="ph-wrench"> </i></a>
                                        <a href="#"><i className="ph-arrows-clockwise"> </i></a>

                                        <a href="#" onClick={(e) => {
                                            e.preventDefault();
                                            setTemConsulta(!temConsulta)
                                        }}>
                                            <i className="ph-x"></i>
                                        </a>
                                    </div>
                                </div>
                                <hr className="linha-insercao" />
                                <div hidden={isHidden}>
                                    <DataTable
                                        columns={columns}
                                        data={data}
                                        paginationComponentOptions={paginationComponentOptions}
                                        pagination />
                                </div>
                            </CardBody>
                        </Card>
                    }
                </Container>
            </div>
        </React.Fragment >
    );
};

export default CartaoInsercaoManual;