import { apiClient2 } from "./apiCore2";

export const getClosures = id => {
  const baseUrl = `/fechamentos/zcm/${id}`;
  return apiClient2.get(baseUrl);
};

export const deleteClosures = id => {
  const baseUrl = `/fechamentos/destroy/${id}`;
  return apiClient2.get(baseUrl);
};

export const submitUpload = data => {
  const baseUrl = `/fechamentos/upload`;
  const formData = new FormData();

  formData.append("fechamento", data.fechamento);
  formData.append("favorecido", data.favorecido);
  formData.append("cnpj", data.cnpj);
  formData.append("file1", data.file1);
  formData.append("euli", data.euli);
  formData.append("euconcordobanc", data.euconcordobanc);
  return apiClient2.create(baseUrl, formData, {
    headers: {
      "content-type": "multipart/form-data",
    },
  });
};
