import React from 'react';
import { Container, Form, FormGroup, Label, Input, Row, Col, Button } from 'reactstrap';
import "./DfaValidate.css";
const DfaValidate = () => {
    return (
        <React.Fragment>
            <div className="page-content DfaValidate">
                <Container>
                    <h1 className='titulo-validacao'>Autenticação em 2 Passos</h1>
                    <h4 className='subtitulo-validacao'>Ultimo passo para ativar a autenticação em 2 Passos!</h4>
                    <p className='texto-validacao'>Nesta ultima etapa, você precisa apenas digitar o código que aparece no seu celular.</p>
                    <hr />
                    <div className='formulario-validacao'>
                        <h4 className='titulo-form'>Preencha o formulário abaixo</h4>
                        <Form>
                            <Row form>
                                <Col md={6}>
                                    <FormGroup>
                                        <Label for="number">
                                            Insira os 6 digitos presentes no seu celular
                                        </Label>
                                        <Input
                                            id="number"
                                            name="number"
                                            placeholder="000000"
                                            maxLength="6"
                                            type="text" />
                                    </FormGroup>
                                </Col>
                            </Row>
                        </Form>
                        <Button className='btn-ativar-validacao'>Ativar</Button>
                    </div>
                </Container>
            </div>
        </React.Fragment >
    );
};
export default DfaValidate;