import { toast } from "react-toastify";

import { apiClient } from "../../api/apiCore";

export const request = async (type, endPoint, data) => {
  var resp = null;
  var MsgSuccess = "";
  var MsgError = "";
  try {
    switch (type) {
      case "post":
        MsgSuccess = "Dados enviados com sucesso!";
        MsgError = "Ocorreu um erro ao enviar os dados!";
        resp = await apiClient.create(endPoint, data);
        break;
      case "put":
        MsgSuccess = "Dados alterados com sucesso!";
        MsgError = "Ocorreu um erro ao alterar os dados!";
        resp = await apiClient.update(endPoint, data);
        break;
      case "get":
        MsgSuccess = "";
        MsgError = "";
        resp = await apiClient.get(endPoint, data);
        break;
      case "delete":
        MsgSuccess = "Dados deletados com sucesso!";
        MsgError = "Ocorreu um erro ao deletar os dados!";
        resp = await apiClient.delete(endPoint);
        break;
      default:
        console.log(`Sorry, we are out of ${type}.`);
    }

    if ((MsgSuccess != "" && resp?.status == 201) || resp?.status == 200) {
      toast.success(MsgSuccess, {
        theme: "colored",
      });
    }
    return resp;
  } catch (error) {
    toast.error(MsgError, {
      theme: "colored",
    });
  }
};
