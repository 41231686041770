import { call, put, takeEvery, all, fork } from "redux-saga/effects";

// Crypto Redux States
import { EventsActionTypes } from "./actionTypes";
import { eventApiSuccess, eventApiFail } from "./action";

//Include Both Helper File with needed methods
import {
  getEventDetails as getEventDetailsApi,
  updateEvents as updateEventsApi,
  deleteEvent as deleteEventApi,
} from "../../api/index";

// helper
import {
  showErrorNotification,
  showSuccessNotification,
} from "../../helpers/notifications";
import { toast } from "react-toastify";

function* getEventDetails() {
  try {
    const response = yield call(getEventDetailsApi);
    yield put(eventApiSuccess(EventsActionTypes.GET_EVENTS_DETAILS, response));
  } catch (error) {
    yield put(eventApiFail(EventsActionTypes.GET_EVENTS_DETAILS, error));
  }
}

function* updateEvents({ payload: data, eventId }) {
  try {
    const response = yield call(updateEventsApi, data, eventId);
    yield put(
      eventApiSuccess(EventsActionTypes.UPDATE_EVENTS, { ...response })
    );
  } catch (error) {
    yield put(eventApiFail(EventsActionTypes.UPDATE_EVENTS, error));
  }
}

function* deleteEvent({ payload: eventId }) {
  try {
    const response = yield call(deleteEventApi, eventId);
    if (response?.messages) {
      response?.messages.map(item =>
        toast.error(item.error, { theme: "colored" })
      );
    } else {
      yield put(eventApiSuccess(EventsActionTypes.DELETE_EVENT, eventId));
      yield call(showSuccessNotification, "Evento excluído com sucesso");
    }
  } catch (error) {
    yield call(showErrorNotification, error);
    yield put(eventApiFail(EventsActionTypes.DELETE_EVENT, error));
    yield call(showErrorNotification, error);
  }
}

export function* watchGetEventDetails() {
  yield takeEvery(EventsActionTypes.GET_EVENTS_DETAILS, getEventDetails);
}

export function* watchUpdateEvent() {
  yield takeEvery(EventsActionTypes.UPDATE_EVENTS, updateEvents);
}

export function* watchDeleteEvent() {
  yield takeEvery(EventsActionTypes.DELETE_EVENT, deleteEvent);
}

function* eventSaga() {
  yield all([
    fork(watchGetEventDetails),
    fork(watchUpdateEvent),
    fork(watchDeleteEvent),
  ]);
}

export default eventSaga;
