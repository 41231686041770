import React, { useState } from "react";
import { Alert, Card, CardBody, Col, Collapse, Form, Row } from "reactstrap";
import { Link } from "react-router-dom";

// Import Editor
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

//import Components
import ClassesList from "./ClassesList";

const ReactEditor = () => {
  const [col1, setcol1] = useState(true);
  const [value, setValue] = useState("select");

  const t_col1 = () => {
    setcol1(!col1);
  };

  const onChangeData = value => {
    setValue(value);
  };
  return (
    <React.Fragment>
      <div className="mt-2">
        <Row>
          <Col md={12}>
            <Card>
              <CardBody>
                <div>
                  <i className="fas fa-edit me-2"></i>
                  <span>HTML5 Editor</span>
                  <span className="float-end">
                    <i
                      className={
                        col1 ? "fas fa-chevron-up" : "fas fa-chevron-down"
                      }
                      onClick={t_col1}
                    ></i>
                  </span>
                </div>
                <hr />
                <Collapse isOpen={col1}>
                  <Row>
                    <Col md={12}>
                      <Form>
                        <Row className="mb-3">
                          <label
                            htmlFor="example-text-input"
                            className="col-md-2 col-form-label text-end"
                          >
                            Title :
                          </label>
                          <div className="col-md-6">
                            <input className="form-control" type="text" />
                          </div>
                        </Row>
                      </Form>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12}>
                      <Row className="mb-3">
                        <label
                          htmlFor="example-text-input"
                          className="col-md-2 col-form-label text-end"
                        >
                          Title :
                        </label>
                        <Col md={4}>
                          <select
                            value={value}
                            className="form-select"
                            onChange={e => {
                              onChangeData(e.target.value);
                            }}
                          >
                            <option value="2">Isento</option>
                            <option value="1">Estadual</option>
                          </select>
                        </Col>
                        <Col md={4}>
                          <select
                            value={value}
                            className="form-select"
                            onChange={e => {
                              onChangeData(e.target.value);
                            }}
                          >
                            <option value="2">Isento</option>
                            <option value="1">Estadual</option>
                          </select>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12}>
                      <Form>
                        <Row className="mb-3">
                          <label
                            htmlFor="example-text-input"
                            className="col-md-2 col-form-label text-end"
                          >
                            Endereço: :
                          </label>
                          <div className="col-md-8">
                            <input
                              className="form-control"
                              type="text"
                              disabled
                            />
                          </div>
                        </Row>
                      </Form>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12}>
                      <Form>
                        <Row className="mb-3">
                          <label
                            htmlFor="example-text-input"
                            className="col-md-2 col-form-label text-end"
                          >
                            Endereço: :
                          </label>
                          <div className="col-md-8">
                            <Editor
                              toolbarClassName="toolbarClassName"
                              wrapperClassName="wrapperClassName"
                              editorClassName="editorClassName"
                            />
                          </div>
                        </Row>
                      </Form>
                    </Col>
                  </Row>
                  <p>
                    <b>
                      Este Email será enviado 2 dias antes para todos os
                      inscritos.
                    </b>
                  </p>
                  <Row>
                    <Col md={12}>
                      <Row className="mb-3">
                        <label
                          htmlFor="example-text-input"
                          className="col-md-2 col-form-label text-end"
                        >
                          Template envio atencipado: :
                        </label>
                        <Col md={8}>
                          <select
                            value={value}
                            className="form-select"
                            onChange={e => {
                              onChangeData(e.target.value);
                            }}
                          >
                            <option value="2">Isento</option>
                            <option value="1">Estadual</option>
                          </select>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <label
                      htmlFor="example-number-input"
                      className="col-md-2 col-form-label text-end"
                    >
                      Pequeno Texto:
                    </label>
                    <div className="col-md-8">
                      <textarea
                        rows="3"
                        name="address"
                        required=""
                        id="address"
                        className="mb-1 form-control"
                      ></textarea>
                    </div>
                  </Row>
                  <p>
                    <b>
                      Este Email será enviado 2 dias depois para todos os
                      presentes.
                    </b>
                  </p>
                  <Row>
                    <Col md={12}>
                      <Row className="mb-3">
                        <label
                          htmlFor="example-text-input"
                          className="col-md-2 col-form-label text-end"
                        >
                          Template Email para Presentes: :
                        </label>
                        <Col md={8}>
                          <select
                            value={value}
                            className="form-select"
                            onChange={e => {
                              onChangeData(e.target.value);
                            }}
                          >
                            <option value="2">Isento</option>
                            <option value="1">Estadual</option>
                          </select>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <label
                      htmlFor="example-number-input"
                      className="col-md-2 col-form-label text-end"
                    >
                      Pequeno Texto:
                    </label>
                    <div className="col-md-8">
                      <textarea
                        rows="3"
                        name="address"
                        required=""
                        id="address"
                        className="mb-1 form-control"
                      ></textarea>
                    </div>
                  </Row>
                  <Alert color="info">
                    <h6 className="alert-heading">
                      O Clube A.Dias Envia os Seguintes parâmetros e você pode
                      usa-los como quiser no Mailer:
                    </h6>
                    <ul>
                      <li>Nome do Participante = #nome#</li>
                      <li>Nome da Turma = #turma#</li>
                      <li>Nome do Evento = #evento#</li>
                      <li>Data e Hora de Início = #inicio#</li>
                      <li>Data e Hora do Fim = #fim#</li>
                      <li>Endereço = #endereco#</li>
                      <li>
                        Pequeno Texto = #texto# (Exceto template
                        &quot;Cadastrado no Evento&quot;)
                      </li>
                    </ul>
                    <p className="mb-0">
                      Tais parâmetros devem ser inseridos no template inserido
                      no Mailer
                    </p>
                  </Alert>

                  <hr className="text-gray-dark my-4" />
                  <Row>
                    <Col md={12}>
                      <Link to="/events" className="btn btn-primary me-2">
                        <i className="fa fa-arrow-left me-2"></i>
                        Voltar
                      </Link>
                      <button className="btn btn-success">
                        <i className="fa fa-edit me-2"></i>
                        Editar
                      </button>
                    </Col>
                  </Row>
                </Collapse>
              </CardBody>
            </Card>
          </Col>
        </Row>

        <Row>
          <Col>
            <Card>
              <CardBody>
                <ClassesList />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
};

export default ReactEditor;
