import { call, put, takeEvery, all, fork } from "redux-saga/effects";

// Download Redux States
import { DownloadActionTypes } from "./actionTypes";
import { downloadApiSuccess, downloadApiFail } from "./action";

//Include Both Helper File with needed methods
import {
  getDownloadDetails as getDownloadDetailsApi,
  getCategoriesOptions as getCategoriesOptionsApi,
} from "../../api/index";

function* getDownloadDetails({ payload: filters }) {
  try {
    const response = yield call(getDownloadDetailsApi, filters);
    yield put(
      downloadApiSuccess(DownloadActionTypes.GET_DOWNLOAD_DETAILS, response)
    );
  } catch (error) {
    yield put(downloadApiFail(DownloadActionTypes.GET_DOWNLOAD_DETAILS, error));
  }
}

function* getCategoriesOptions({ payload: categoryId }) {
  try {
    const params = {
      id: categoryId,
    };
    const response = yield call(getCategoriesOptionsApi, params);
    yield put(
      downloadApiSuccess(DownloadActionTypes.GET_CATEGORIES_OPTIONS_DETAILS, {
        id: categoryId,
        data: response,
      })
    );
  } catch (error) {
    yield put(
      downloadApiFail(DownloadActionTypes.GET_CATEGORIES_OPTIONS_DETAILS, error)
    );
  }
}

export function* watchGetDownloadDetails() {
  yield takeEvery(DownloadActionTypes.GET_DOWNLOAD_DETAILS, getDownloadDetails);
}

export function* watchGetCategoriesOptions() {
  yield takeEvery(
    DownloadActionTypes.GET_CATEGORIES_OPTIONS_DETAILS,
    getCategoriesOptions
  );
}

function* downloadSaga() {
  yield all([fork(watchGetDownloadDetails), fork(watchGetCategoriesOptions)]);
}

export default downloadSaga;
