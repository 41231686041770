const radioLabels = ["Residencial", "Comercial", "Industrial", "Rural"];

const potentialEnvironmentalImpact = [
  {
    title: "Dióxido de carbono",
    description: "(toneladas métricas)",
  },
  {
    title: "Automóveis",
    description: "(toneladas métricas)",
  },
  {
    title: "Mudas de árvores",
    description: "(cresceram em 10 anos)",
  },
];

const dataPotentialEnvironmentalImpact = {
  descriptions: [
    "Pague à vista, maior economia ao longo da vida. Você paga o custo total antecipadamente e possui o sistema solar sem pagamentos adicionais ao longo do tempo. Como proprietário direto, você pode reivindicar quaisquer incentivos locais, estaduais ou federais.",
    "Pague à vista, maior economia ao longo da vida. Você paga o custo total antecipadamente e possui o sistema solar sem pagamentos adicionais ao longo do tempo. Como proprietário direto, você pode reivindicar quaisquer incentivos locais, estaduais ou federais.",
    "Pague à vista, maior economia ao longo da vida. Você paga o custo total antecipadamente e possui o sistema solar sem pagamentos adicionais ao longo do tempo. Como proprietário direto, você pode reivindicar quaisquer incentivos locais, estaduais ou federais.",
  ],
  comments: [
    "Custo inicial após os incentios",
    "Benefício de 25 anos",
    "Economia total em 25 anos",
    "Anos até o retorno",
  ],
};

const readyToStart = [
  "Solicitar Orçamento Aproximado",
  "Solicitar Orçamento Detalhado",
  "Solicitar Visita Técnica",
];

export {
  radioLabels,
  potentialEnvironmentalImpact,
  dataPotentialEnvironmentalImpact,
  readyToStart,
};
