import React from "react";
import { Button, Card, CardBody, Col, Row } from "reactstrap";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";

//utils
import { loadRecentData } from "../../../store/actions";

const Event = ({ record }) => {
  const dispatch = useDispatch();
  const youtubeData = record.json_youtube
    ? JSON.parse(record.json_youtube)
    : null;
  const id = record.id;
  const defaultThumbnail = `https://img.youtube.com/vi/${id}/hqdefault.jpg`;
  const thumbnail = youtubeData?.items[0]?.snippet?.thumbnails?.standard?.url
    ? youtubeData.items[0].snippet.thumbnails.standard.url
    : defaultThumbnail;

  const title = youtubeData?.items[0]?.snippet?.title
    ? youtubeData.items[0].snippet.title
    : record.name;

  /*
    load recent data
    */
  const onLoadData = () => {
    dispatch(loadRecentData(id));
  };

  return (
    <div className="mb-5 text-center">
      <Link to={`/tvadias/live/config/${id}`}>
        <div>
          <img
            className="img-thumbnail mb-2"
            width={250}
            src={thumbnail}
            alt="title"
          />
        </div>
        <h5 className="mb-3">{title}</h5>
      </Link>
      <Button className="btn text-white" style={{backgroundColor: "#2D567B"}} size="small" onClick={onLoadData}>
        <i className="mdi mdi-reload" /> Buscar dados no YouTube
      </Button>
    </div>
  );
};

const LatestEvents = ({ videos }) => {
  return (
    <Card>
      <CardBody>
        <h5 className="mb-3">Últimos Eventos</h5>
        <Row>
          {(videos || []).map((video, key) => (
            <Col lg={4} key={key}>
              <Event record={video} />
            </Col>
          ))}
        </Row>
      </CardBody>
    </Card>
  );
};

export default LatestEvents;
