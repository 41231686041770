import React, { useEffect, useState } from "react";
import {
  Spinner,
} from "reactstrap";

import { Modal } from "react-bootstrap";
import InputMask from "react-input-mask";
import { toast } from "react-toastify";
import { useSolar } from "../../hooks";
import { apiClient } from "../../api/apiCore";
import { updateDefaultZipcode } from "../../store/actions";
import { useDispatch, useSelector } from "react-redux";

const ModalUpdateZipcode = ({
  handleClose,
}) => {
  const isSolar = useSolar();
  const dispatch = useDispatch();

  const { zipcodeModalIsOpen } = useSelector(
    state => {
      return {
        zipcodeModalIsOpen: state.Layout.zipcodeModalIsOpen
      };
    }
  );

  const [deliveryZipCodeLoading, setDeliveryZipCodeLoading] = useState(false);
  const [zipcodeError, setZipcodeError] = useState(false);
  const [zipcode, setZipcode] = useState();

  
  const getAddressByZipcode = async zipcode => {
    const baseUrl = `/api/consulta/cep`;
    return await apiClient.create(`${baseUrl}`, { cep: zipcode });
  };

  const handleUpdateZipcode = async () => {
      setDeliveryZipCodeLoading(true);
      const date = new Date();
      try {
        if (zipcode?.replace(/[^0-9]+/g, "").length > 7) {
          const resp = await getAddressByZipcode(zipcode);

          var obj = {
            cep: resp.address.zipcode,
            logradouro: resp.address.city,
            uf: resp.address.state,
            date,
          };
          setZipcodeError(false);
          dispatch(updateDefaultZipcode(obj));
          handleClose();
        } else {
          setZipcodeError("Digite um CEP com 8 dígitos");
        }
      } catch (error) {
        toast.error("Digite um cep válido", {
          theme: "colored",
        });
      }
      setDeliveryZipCodeLoading(false);
    
  }

  return (
    <Modal show={zipcodeModalIsOpen} onHide={handleClose}>
      <Modal.Header closeButton style={{ display: "grid", border: "none" }}>
        <Modal.Title>Selecione onde quer receber suas compras</Modal.Title>
        <span style={{ fontSize: "12px" }}>
          Você poderá ver custos e prazos de entrega precisos em tudo que
          procurar.
        </span>
      </Modal.Header>
      <Modal.Body style={{ display: "grid" }}>
        <h6>Código de endereço postal</h6>
        <div style={{ display: "flex", marginBottom: "15px" }}>
          <div>
            <InputMask
              style={{
                width: "200px",
                height: "40px",
                borderColor: zipcodeError && "red",
              }}
              className="form-control"
              mask="99999-999"
              id="cep"
              onChange={e => setZipcode(e.target.value)}
            />
            <span style={{ color: zipcodeError && "red" }}>{zipcodeError}</span>
          </div>

          <a
            style={{
              marginLeft: "15px",
              fontSize: "12px",
              padding: "10px 10px 10px 10px",
            }}
            href={
              "https://buscacepinter.correios.com.br/app/endereco/index.php"
            }
            target="_blank"
            rel="noreferrer"
          >
            Não sei o meu CEP
          </a>
        </div>

        <button
          disabled={deliveryZipCodeLoading}
          onClick={handleUpdateZipcode}
          style={{
            backgroundColor: isSolar ? "rgba(255, 93, 0, 1)" : "#2D567B",
            color: "white",
            borderRadius: "5px",
            width: "100px",
            height: "40px",
            border: "none",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {deliveryZipCodeLoading ? <Spinner /> : "Usar"}
        </button>
      </Modal.Body>
      {/* <Modal.Footer>
  <Button variant="secondary" onClick={handleClose}>
    Close
  </Button>
  <Button variant="primary" onClick={handleClose}>
    Save Changes
  </Button>
</Modal.Footer> */}
    </Modal>
  );

}

export default ModalUpdateZipcode;