import React from "react";
export default function MagnifyingGlassComponent(props) {
  const colorFill = props?.colorFill?.replaceAll("#", "") || "none";
  const colorStroke = props?.colorStroke || "#696969";

  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill={colorFill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.5 16C13.0899 16 16 13.0899 16 9.5C16 5.91015 13.0899 3 9.5 3C5.91015 3 3 5.91015 3 9.5C3 13.0899 5.91015 16 9.5 16Z"
        stroke={colorStroke}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14 14L18 18"
        stroke={colorStroke}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
