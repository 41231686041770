import React from "react";
import { useSelector } from "react-redux";

import { Container, Spinner } from "reactstrap";

// components
import Breadcrumb from "../../components/Breadcrumb";
import OrderFilters from "./OrderFilters";
import OrdersList from "./OrdersList";
import { useState } from "react";

const Orders = () => {
  const { loading, success } = useSelector(state => ({
    loading: state.OrderList.loading,
    success: state.OrderList.success,
  }));
  const [idType, setIdType] = useState(1);
  const [clienteId, setClienteId] = useState("");
  const [parceiros, setParceiros] = useState([]);
  const [numPedido, setNumPedido] = useState("");
  return (
    <React.Fragment>
      <div className="page-content orders">
        <Container>
          <Breadcrumb
            title="Página Inicial"
            breadcrumbItem="Consultar Pedidos"
          />

          <OrderFilters
            idType={idType}
            setIdType={setIdType}
            clienteId={clienteId}
            setClienteId={setClienteId}
            parceiros={parceiros}
            setParceiros={setParceiros}
            numPedido={numPedido}
            setNumPedido={setNumPedido}
          />

          {loading ? (
            <Spinner style={{ margin: "0 50%" }} className="mt-4" />
          ) : (
            <div />
          )}

          {success ? (
            <OrdersList
              idType={idType}
              setIdType={setIdType}
              clienteId={clienteId}
              setClienteId={setClienteId}
              parceiros={parceiros}
              setParceiros={setParceiros}
              setNumPedido={setNumPedido}
            />
          ) : (
            <div />
          )}
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Orders;
