import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { connect, useDispatch, useSelector } from "react-redux";

import Routes from "./routes/index";

// Import scss
import "./assets/scss/theme.scss";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// Import Firebase Configuration file
import { initFirebaseBackend } from "./helpers/firebase_helper";
import { getProfileDetailsList } from "./store/auth/profile/actions";
import TagManager from "react-gtm-module";
import { GET_FIREBASE_REMOTE_CONFIG_USER_RESTRICT_PAGE } from "./store/firebaseRemoteConfig/actionTypes";
import { getFireBaseRemoteConfig } from "./store/actions";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_APIKEY,
  authDomain: process.env.REACT_APP_AUTHDOMAIN,
  databaseURL: process.env.REACT_APP_DATABASEURL,
  projectId: process.env.REACT_APP_PROJECTID,
  storageBucket: process.env.REACT_APP_STORAGEBUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGINGSENDERID,
  appId: process.env.REACT_APP_APPID,
};
// init firebase backend
initFirebaseBackend(firebaseConfig);
const App = () => {
  const { profileDetails } = useSelector(state => ({
    profileDetails: state.Profile.profileDetails,
  }));
  const dispatch = useDispatch();

  const { user, sidebarMenuData } = useSelector(state => ({
    user: state.Login.user,
    sidebarMenuData: state.SidebarMenu.menuItems,
  }));

  const tagManagerArgs = {
    gtmId: process.env.REACT_APP_GTM,
  };

  useEffect(() => {
    TagManager.initialize(tagManagerArgs);
  }, []);

  //scroll up em todas as telas
  useEffect(() => {
    window?.scrollTo(0, 0);
  }, [location?.pathname]);

  useEffect(() => {
    dispatch(getProfileDetailsList());
    dispatch(
      getFireBaseRemoteConfig(
        "USER_PAGE_RESTRICT_CREATE_PROMOTION",
        "",
        GET_FIREBASE_REMOTE_CONFIG_USER_RESTRICT_PAGE
      )
    );
  }, [user]);

  /* const checkHasCep = () => {
    const logradouro = localStorage.getItem("cep");
    const lograFormat = JSON.parse(logradouro);

    if (!lograFormat?.logradouro) {
      var obj = {
        cep: profileDetails.userAddress.zipcode,
        logradouro: profileDetails.userAddress.city,
        uf: profileDetails.userAddress.state,
      };
      localStorage.setItem("cep", JSON.stringify(obj));
    }
  };

  useEffect(() => {
    checkHasCep();
  }, [profileDetails]); */

  /*  const checkAccessToUserRoutes = () => {
    var array = [];
    sidebarMenuData.map(item => {
      if (item.subItems) {
        item.subItems.map(subItems => {
          console.log(window.location.pathname, ", ", subItems.link);
          return array.push(window.location.pathname.includes(subItems.link));
        });
      } else {
        if (item.link == "/solar/") {
          return array.push("home");
        }
        return array.push(window.location.pathname.includes(item.link));
      }
    });
    console.log("array", array);
    const verifyRoute = array.some(item => item == true);
    console.log("verifyRoute", verifyRoute);

    if (!verifyRoute) {
      if (window.location.pathname != "/solar/") {
        window.location.href = "/solar/";
      }
    }
  };

  useEffect(() => {
    if (sidebarMenuData.length > 0) {
      checkAccessToUserRoutes();
    }
  }, [window.location.pathname, sidebarMenuData]); */

  return (
    <React.Fragment>
      <Routes />
      <ToastContainer 
        autoClose={15000} 
        position="top-right" 
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover 
        style={{top: "58px"}}
         />
    </React.Fragment>
  );
};

App.propTypes = {
  layout: PropTypes.any,
};

const mapStateToProps = state => {
  return {
    layout: state.Layout,
  };
};

export default connect(mapStateToProps, null)(App, firebaseConfig);
