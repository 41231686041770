import { call, put, takeEvery, all, fork } from "redux-saga/effects";
import { LeadsActionTypes } from "./actionTypes";
import {
  getLeadsList as getLeadsListApi,
  getLeadDetails as getLeadDetailsApi,
  updateLeadDetails as updateLeadDetailsApi,
} from "../../api";
import { leadsApiFail, leadsApiSuccess } from "./action";

function* getLeadsList({ payload: filters }) {
  try {
    const response = yield call(getLeadsListApi, filters);
    yield put(leadsApiSuccess(LeadsActionTypes.GET_LEADS_LIST, response));
  } catch (error) {
    yield put(leadsApiFail(LeadsActionTypes.GET_LEADS_LIST, error));
  }
}

function* getLeadDetails({ payload: id }) {
  try {
    const response = yield call(getLeadDetailsApi, id);
    yield put(leadsApiSuccess(LeadsActionTypes.GET_LEAD_DETAILS, response));
  } catch (error) {
    console.log(error);
    yield put(leadsApiFail(LeadsActionTypes.GET_LEAD_DETAILS, error));
  }
}

function* updateLeadDetails({ payload: data }) {
  try {
    const response = yield call(updateLeadDetailsApi, data);
    yield put(leadsApiSuccess(LeadsActionTypes.UPDATE_LEAD_DETAILS, response));
  } catch (error) {
    console.log(error);
    yield put(leadsApiFail(LeadsActionTypes.UPDATE_LEAD_DETAILS, error));
  }
}

export function* watchGetLeadsList() {
  yield takeEvery(LeadsActionTypes.GET_LEADS_LIST, getLeadsList);
}

export function* watchGetLeadDetails() {
  yield takeEvery(LeadsActionTypes.GET_LEAD_DETAILS, getLeadDetails);
}

export function* watchUpdateLeadDetails() {
  yield takeEvery(LeadsActionTypes.UPDATE_LEAD_DETAILS, updateLeadDetails);
}

function* leadsSaga() {
  yield all([
    fork(watchGetLeadsList),
    fork(watchGetLeadDetails),
    fork(watchUpdateLeadDetails),
  ]);
}

export default leadsSaga;
