import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { formato } from "../../utils";
import {
  Col,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
  Table,
} from "reactstrap";
import { getItemTable } from "../../store/order/action";
import ModalAdias from "../../components/Modal";
import { useSolar } from "../../hooks";
import { useHistory } from "react-router-dom";

const ItemTable = ({ item, serviceVal }) => {
  const dispatch = useDispatch();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const history = useHistory();
  const isSolar = useSolar();
  const { itensTabela, findedItems, order } = useSelector(state => ({
    order: state.Order.orderActive.order,
    itensTabela: state.Order.itensTabela,
    findedItems: state.Order.newOrder.newOrder.findedItems,
  }));

  const itemDetails = findedItems[order.sku_base]
    ? findedItems[order.sku_base]
    : {};

  function getCodFab() {
    if (itensTabela[item.Produto]) {
      if (itensTabela[item.Produto].atributosEspeciais) {
        let val = Object.values(itensTabela[item.Produto].atributosEspeciais);
        let val2 = Object.values(itensTabela[item.Produto].atributos);

        let selo1 = val.find(i => i.Codattr == "304");
        let selo2 = val.find(i => i.Codattr == "305");
        let selo3 = val2.find(i => i.Codattr == "346");

        if (selo1 || selo2 || selo3) {
          return (
            <>
              {selo2?.Valor && <strong>Cód. Evaporadora: </strong>}
              {selo1?.Valor || ""}
              <br />
              {selo2?.Valor && <strong>Cód. Condensadora: </strong>}
              {selo2?.Valor || ""}
              <br />
              {selo3?.Valor && <strong>Cód. Fabricante: </strong>}
              {selo3?.Valor || ""}
            </>
          );
        } else {
          return false;
        }
      } else {
        return false;
      }
    }
  }

  useEffect(() => {
    dispatch(getItemTable(order?.sku_base || item.Produto));
  }, [location.pathname]);

  return (
    <React.Fragment>
      <tr>
        {item.foto ? (
          <td style={{ width: "15%" }}>
            <img
              src={item.foto}
              style={{ width: "100%" }}
              alt="Imagem do item"
            />
          </td>
        ) : (
          <td />
        )}

        <td style={{ width: "55%" }}>
          <Row className="mt-4">
            <Col>
              <h5>{item.DescProd}</h5>
            </Col>
          </Row>{" "}
          <>
            <Row>
              <Col className="d-flex">
                <p style={{ marginBottom: 0 }} className="text-muted sku">
                  <strong>SKU: </strong>
                  {item?.Produto}
                </p>

                {!isSolar && (
                  <p style={{ marginBottom: 0 }} className="text-muted">
                    <span style={{ textAlign: "right" }}>
                      {getCodFab() || ""}
                    </span>
                  </p>
                )}
              </Col>
            </Row>
            <Row>
              <Col className="d-flex">
                <p className="text-muted sku">
                  <strong>TES: </strong>
                  {item?.TES}
                </p>
              </Col>
            </Row>
            {/*  <Row>
                <Col>
                  <a
                    onClick={e => {
                      e.preventDefault();
                      setIsModalOpen(!isModalOpen);
                    }}
                    className="titulo-items"
                    style={{ cursor: "pointer" }}
                  >
                    VER DETALHES
                  </a>
                </Col>
              </Row> */}
          </>
        </td>
        <td style={{ width: "15%" }}>
          <Row className="mt-4">
            <Col>
              <p
                className="text-muted"
                style={{ textAlign: "center", fontSize: 18, color: "#7E7E7A" }}
              >
                Quantidade
              </p>
              <p style={{ textAlign: "center" }}>
                <strong>{item.Qtdven}</strong>
              </p>
            </Col>
          </Row>
        </td>
        <td style={{ width: "15%" }}>
          <Row className="mt-4">
            <Col>
              <p
                className="text-muted"
                style={{ textAlign: "center", fontSize: 18, color: "#7E7E7A" }}
              >
                Valor
              </p>
              <p style={{ textAlign: "center" }}>
                <strong>
                  {(Number(item.Valor - serviceVal)).toLocaleString("pt-br", formato)}
                </strong>
              </p>
            </Col>
          </Row>
        </td>
      </tr>
      {/*   <ModalAdias
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        produto={item}
        produtoDetails={itemDetails}
      /> */}
    </React.Fragment>
  );
};

export default ItemTable;
