import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getBudget } from "../../../store/actions";
import { apiClient2 } from "../../../api/apiCore2";
import { useState } from "react";
import { useSolar } from "../../../hooks";
import { filiais } from "../../../utils/filiais";

const usePdf = () => {
  const { active, findedItems, order } = useSelector(state => {
    return {
      findedItems: state.Order.newOrder.newOrder.findedItems,
      active: state.Budget.active,
      order: state.Order.orderActive.order,
    };
  });
  const [detailsProductItem, setDetailsProductItem] = useState(null);
  const [filiaisArray, setFiliaisArray] = useState("");
  const [codPag, setCodPag] = useState([]);
  const [outros, setOutros] = useState(0);
  const [totalValue, setTotalValue] = useState("");
  const detailsProductVisible = [
    "_modulo",
    "_nomeEstrutura4",
    "_nomeEstrutura2",
    "_nomeEstrutura3",
    "_nomeEstrutura",
    "_nomeInversor",
  ];
  const isSolar = useSolar();
  var id = order.Orcamento;
  const dispatch = useDispatch();
  const doNotDisplayChildBudget = "744";
  const numberTES =
    active.data?.orcamentos_filhos?.length > 0 &&
    String(active.data?.orcamentos_filhos[0]?.items[0]?.TES);
  var dataActive = active?.data;

  const [item, setItem] = useState({
    atributos: [],
    atributosEspeciais: [],
    fotos: [],
  });

  useEffect(() => {
    dispatch(getBudget(order?.Orcamento, order?.CodFilial));
  }, [dispatch, order?.Orcamento, order?.CodFilial]);

  useEffect(() => {
    if (findedItems[active?.data?.sku_base]) {
      setItem(findedItems[active.data.sku_base]);
    }
  }, [findedItems, active]);

  useEffect(() => {
    getProductDetailsPDF(item);
  }, [item.atributos, item.atributosEspeciais, item.fotos]);

  const getSubtotalFilhos = orcamento => {
    if (orcamento?.new_items) {
      let val = 0;

      orcamento?.new_items.map(value => {
        val =
          Number(val) + Number(value?.PRCRT ? value?.PRCRT : value?.subTotal);
      });

      return val;
    } else {
      let val = 0;

      orcamento.items.map(value => {
        val = Number(val) + Number(value?.PRCRT ? value?.PRCRT : value?.Valor);
      });

      return val;
    }
  };

  const getSubtotal = () => {
    if (active?.data?.orcamento?.items || active?.data?.orcamento?.new_items) {
      if (active?.data?.orcamento?.new_items) {
        let val = 0;

        active?.data?.orcamento?.new_items.map(value => {
          val =
            Number(val) + Number(value?.PRCRT ? value?.PRCRT : value?.subTotal);
        });

        return val;
      } else {
        let val = 0;

        active.data.orcamento.items.map(value => {
          val =
            Number(val) +
            Number(
              value?.PRCRT
                ? value?.PRCRT
                : value?.Valor
                ? value?.Valor
                : value.total - value.CJ_FRETE
            );
        });

        return val;
      }
    }
  };

  var totalToShow =
    active.data?.orcamentos_filhos?.length > 0 &&
    numberTES != doNotDisplayChildBudget
      ? totalValue +
        Number(
          Number(active?.data.orcamento?.CJ_FRETE) +
            Number(outros) +
            Number(getSubtotal())
        )
      : Number(
          Number(active?.data.orcamento?.CJ_FRETE) +
            Number(outros) +
            Number(getSubtotal())
        );

  function getProductDetailsPDF(item) {
    let array = [];

    item?.atributos
      ?.sort((a, b) => a.Codattr.localeCompare(b.Codattr))
      .map(value => {
        if (!value.Nome) {
          return null;
        } else if (detailsProductVisible.indexOf(value.Codattr) != -1) {
          return array.push([
            {
              text: value.Nome,
              bold: true,
              style: "normalFont",
            },

            {
              text: value.Valor,
              bold: true,
              style: "normalFont",
            },
          ]);
        }
      });

    setDetailsProductItem(array);
  }

  const getPagto = async tabela => {
    tabela = { tabela: isSolar ? "043" : "002" };
    const resp = await apiClient2.create(`/api/order/condicaopagto`, tabela);
    const newCod = resp.filter(item => {
      return item?.CODPAG == active?.data?.orcamento?.CondPag;
    });

    setCodPag(newCod[0]?.DESCRPAG);
  };

  useEffect(() => {
    if (active?.data?.orcamento?.CondPag) {
      getPagto();
    }
  }, [active?.data?.orcamento?.CondPag]);

  var AllFiliais = [];
  useEffect(() => {
    if (active.data?.orcamentos_filhos?.length > 0) {
      getAllFiliais();
    } else {
      setFiliaisArray([]);
    }
  }, [active.data.orcamentos_filhos]);

  function getAllFiliais() {
    active.data.orcamentos_filhos?.map(orcamento =>
      AllFiliais.push(filiais[Number(orcamento.CodFilial)])
    );
    setFiliaisArray(AllFiliais.join());
  }

  const getValTotSeguro = () => {
    const items = active.data.orcamento.items;
    const valTot = items.reduce(
      (acumulador, valorAtual) => acumulador + parseFloat(valorAtual.ValServ),
      0
    );
    setOutros(valTot);
  };

  useEffect(() => {
    if (active?.data?.orcamento) {
      getValTotSeguro();
    }
  }, [active]);

  useEffect(() => {
    if (active.data?.orcamentos_filhos?.length > 0) {
      sumToValorTotal();
    }
  }, [active.data.orcamentos_filhos]);

  function sumToValorTotal() {
    var ValorTotal = 0;
    active.data.orcamentos_filhos?.map(
      orcamento =>
        (ValorTotal +=
          Number(orcamento.CJ_FRETE) + Number(getSubtotalFilhos(orcamento)))
    );
    setTotalValue(ValorTotal);
  }

  return {
    dataActive,
    codPag,
    id,
    buttonType: "download",
    detailsProductItem,
    totalToShow,
    filiaisArray,
    downloadSemValores: isSolar ? "download-sem-valores" : "",
    numberTES,
    doNotDisplayChildBudget,
  };
};

export default usePdf;
