import {
  AvField,
  AvGroup,
  AvInput,
  AvFeedback,
} from "availity-reactstrap-validation";

import React from "react";
import { useState } from "react";
import { Card, CardBody, Col, Row, Input, Label, Spinner } from "reactstrap";
import { consultaCep } from "../../../api";
import { validCep } from "../../../utils";
import { toast } from "react-toastify";

const Endereco = ({ formData, setFormData }) => {
  const [isLoadingCep, setIsLoadingCep] = useState(false);

  const searchCep = async cep => {
    if (cep.length > 0) {
      if (cep.length === 9) {
        setIsLoadingCep(true);

        try {
          const response = await consultaCep(cep);

          setFormData({
            ...formData,
            endereco: response.address.address,
            cidade: response.address.city,
            bairro: response.address.district,
            estado: response.address.state,
          });
        } catch (err) {
          toast.error(`Erro ao consultar CEP`, { theme: "colored" });
        }

        setIsLoadingCep(false);
      } else {
        toast.error(`Digite um CEP válido`);
      }
    }
  };

  const estados = [
    { uf: "AC", nome: "Acre" },
    { uf: "AL", nome: "Alagoas" },
    { uf: "AP", nome: "Amapá" },
    { uf: "AM", nome: "Amazonas" },
    { uf: "BA", nome: "Bahia" },
    { uf: "CE", nome: "Ceará" },
    { uf: "DF", nome: "Distrito Federal" },
    { uf: "ES", nome: "Espírito Santo" },
    { uf: "GO", nome: "Goiás" },
    { uf: "MA", nome: "Maranhão" },
    { uf: "MT", nome: "Mato Grosso" },
    { uf: "MS", nome: "Mato Grosso do Sul" },
    { uf: "MG", nome: "Minas Gerais" },
    { uf: "PA", nome: "Pará" },
    { uf: "PB", nome: "Paraíba" },
    { uf: "PR", nome: "Paraná" },
    { uf: "PE", nome: "Pernambuco" },
    { uf: "PI", nome: "Piauí" },
    { uf: "RJ", nome: "Rio de Janeiro" },
    { uf: "RN", nome: "Rio Grande do Norte" },
    { uf: "RS", nome: "Rio Grande do Sul" },
    { uf: "RO", nome: "Rondônia" },
    { uf: "RR", nome: "Roraima" },
    { uf: "SC", nome: "Santa Catarina" },
    { uf: "SP", nome: "São Paulo" },
    { uf: "SE", nome: "Sergipe" },
    { uf: "TO", nome: "Tocantins" },
  ];

  return (
    <>
      <Card className="p-2">
        <CardBody className="card-body">
          <h4 className="titulo-card">Endereço</h4>
          <div className="mx-4">
            <Row className="justify-content-center">
              {isLoadingCep ? (
                <div className="loading">
                  <Spinner />
                </div>
              ) : (
                ""
              )}
              <Col md={3} className="mt-3">
                <Label>CEP</Label>
                <AvGroup>
                  <AvInput
                    name="cep"
                    placeholder="Digite aqui..."
                    className="input-dados"
                    onBlur={e => {
                      searchCep(e.target.value);
                    }}
                    value={validCep(formData.cep) ?? ""}
                    onChange={e =>
                      setFormData({ ...formData, cep: e.target.value })
                    }
                    required
                  />
                  <AvFeedback>Campo obrigatório!</AvFeedback>
                </AvGroup>
              </Col>
              <Col md={3} className="mt-3">
                <Label>Cidade</Label>
                <AvGroup>
                  <AvInput
                    name="cidade"
                    id="cidade"
                    value={formData.cidade ?? ""}
                    placeholder="ex.: São Paulo"
                    className="input-dados"
                    onChange={e =>
                      setFormData({ ...formData, cidade: e.target.value })
                    }
                    required
                  />
                  <AvFeedback>Campo obrigatório!</AvFeedback>
                </AvGroup>
              </Col>
              <Col md={3} className="mt-3">
                <Label>Estado</Label>
                <AvGroup>
                  <AvField
                    type={"select"}
                    className="form-select form-control input-dados"
                    id="estado"
                    name="estado"
                    value={formData.estado ?? ""}
                    onChange={e =>
                      setFormData({ ...formData, estado: e.target.value })
                    }
                    required
                  >
                    <option disabled defaultValue value="">
                      Selecione uma opção
                    </option>
                    {estados.map(estado => (
                      <option key={estado.uf} value={estado.uf}>
                        {estado.nome}
                      </option>
                    ))}
                  </AvField>
                </AvGroup>
              </Col>
              <Col md={3} className="mt-3">
                <Label>Número</Label>
                <AvGroup>
                  <AvInput
                    name="numero"
                    id="numero"
                    placeholder="ex.: 220"
                    value={formData.numero ?? ""}
                    required
                    onChange={e =>
                      setFormData({ ...formData, numero: e.target.value })
                    }
                    className="input-dados"
                  />
                  <AvFeedback>Campo obrigatório!</AvFeedback>
                </AvGroup>
              </Col>
            </Row>
            <Row className="justify-content-center">
              <Col md={12} className="mt-3">
                <Label>Endereço</Label>
                <AvGroup>
                  <AvInput
                    name="endereco"
                    id="endereco"
                    value={formData.endereco ?? ""}
                    placeholder="ex.: Av. Paulista"
                    className="input-dados"
                    onChange={e =>
                      setFormData({ ...formData, endereco: e.target.value })
                    }
                    required
                  />
                  <AvFeedback>Campo obrigatório!</AvFeedback>
                </AvGroup>
              </Col>
            </Row>
            <Row className="justify-content-center">
              <Col md={6} className="mt-3">
                <Label>Bairro</Label>
                <AvGroup>
                  <AvInput
                    name="bairro"
                    id="bairro"
                    required
                    value={formData.bairro ?? ""}
                    placeholder="ex.: Bela Vista"
                    className="input-dados"
                    onChange={e =>
                      setFormData({ ...formData, bairro: e.target.value })
                    }
                  />
                  <AvFeedback>Campo obrigatório!</AvFeedback>
                </AvGroup>
              </Col>
              <Col md={6} className="mt-3">
                <Label>Complemento</Label>
                <Input
                  name="complemento"
                  id="complemento"
                  placeholder="ex.: apto. 20"
                  value={formData.complemento ?? ""}
                  onChange={e =>
                    setFormData({ ...formData, complemento: e.target.value })
                  }
                  className="input-dados"
                />
              </Col>
            </Row>
          </div>
        </CardBody>
      </Card>
    </>
  );
};
export default Endereco;
