import PropTypes from "prop-types";
import React, { useCallback, useEffect, useRef } from "react";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
//Import Scrollbar
import SimpleBar from "simplebar-react";

// MetisMenu
import MetisMenu from "metismenujs";
import { Link, withRouter, useHistory } from "react-router-dom";
import { getMenuItems, removeAllItems } from "../../store/actions";
import { useSelector, useDispatch } from "react-redux";

//i18n
import { withTranslation } from "react-i18next";
import classNames from "classnames";

import Loader from "../../components/Loader";
import { useSolar } from "../../hooks";

// const NewRouteAdd = () => {
//   return (
//     <span className="badge rounded-pill bg-danger float-end">novidade</span>
//   )
// }

const MenuItem = ({ item, solar }) => {
  const dispatch = useDispatch();

  const hasChildren = item["subItems"] && item["subItems"].length;
  const url = item["link"] ? item["link"] : "/#";
  // const hasExtra = item["novidade"]

  return (
    <li>
      <Link
        to={hasChildren ? "/#" : url}
        className={`content-label-arrow ${classNames(
          // { "has-arrow": hasChildren && !hasExtra },
          { "has-arrow": hasChildren },
          { "waves-effect": hasChildren }
        )} ${solar && "solar-link"} `}
        onClick={() => dispatch(removeAllItems())}
      >
        <div className="icon-div">
          {item["icon"] && (
            <i className={`${item["icon"]} ${solar && "solar-icon"}`}></i>
          )}
          {/* {hasExtra && <NewRouteAdd />} */}
          <span>{item["label"]}</span>
        </div>
        {hasChildren ? (
          <div className="content-has-arrow-down">
            <div className="has-arrow-down">
              <KeyboardArrowDownOutlinedIcon fontSize="small" />
            </div>
          </div>
        ) : null}
      </Link>
      {hasChildren ? <Menu item={item} solar={solar} /> : null}
    </li>
  );
};

const Menu = ({ item, solar }) => {
  const menuItems = item["subItems"] && item["subItems"];
  const isSolar = useSolar();
  const arOrSolar = isSolar ? "solar" : "ar";

  return (
    <ul className="sub-menu">
      {(menuItems || []).map((item, key) =>
        item.system == "both" ? (
          <MenuItem item={item} key={key} solar={solar} />
        ) : item.system == arOrSolar ? (
          <MenuItem item={item} key={key} solar={solar} />
        ) : null
      )}
    </ul>
  );
};

const SidebarNav = props => {
  const dispatch = useDispatch();
  const history = useHistory();
  const isSolar = useSolar();

  const { sidebarMenuData, getMenusLoading, isMenuItemFetched } = useSelector(
    state => ({
      sidebarMenuData: state.SidebarMenu.menuItems,
      getMenusLoading: state.SidebarMenu.getMenusLoading,
      isMenuItemFetched: state.SidebarMenu.isMenuItemFetched,
    })
  );

  useEffect(() => {
    if (!getMenusLoading) {
      if (isSolar) {
        dispatch(getMenuItems({ empresaid: "solar" }));
      } else {
        dispatch(getMenuItems({ empresaid: "ar" }));
      }
    }
  }, [isSolar]);

  const ref = useRef();

  const activateParentDropdown = useCallback(item => {
    item.classList.add("active");
    const parent = item.parentElement;
    const parent2El = parent.childNodes[1];

    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show");
    }

    if (parent) {
      parent.classList.add("mm-active");
      const parent2 = parent.parentElement;

      if (parent2) {
        parent2.classList.add("mm-show"); // ul tag

        const parent3 = parent2.parentElement; // li tag

        if (parent3) {
          parent3.classList.add("mm-active"); // li
          parent3.childNodes[0].classList.add("mm-active"); //a
          const parent4 = parent3.parentElement; // ul
          if (parent4) {
            parent4.classList.add("mm-show"); // ul
            const parent5 = parent4.parentElement;
            if (parent5) {
              parent5.classList.add("mm-show"); // li
              parent5.childNodes[0].classList.add("mm-active"); // a tag
            }
          }
        }
      }
      scrollElement(item);
      return false;
    }
    scrollElement(item);
    return false;
  }, []);

  useEffect(() => {
    if (!getMenusLoading && isMenuItemFetched) {
      new MetisMenu("#side-menu-item");
      activeMenu();
    }
  }, [getMenusLoading, isMenuItemFetched]);

  const logoutErrorRequestMenu = () => {
    if (sidebarMenuData.lenght == 0) {
      history.push("/logout");
    }
  };

  useEffect(() => {
    setTimeout(() => {
      logoutErrorRequestMenu();
    }, 5000);
  }, []);

  const removeActivation = items => {
    for (var i = 0; i < items.length; ++i) {
      var item = items[i];
      const parent = items[i].parentElement;

      if (item && item.classList.contains("active")) {
        item.classList.remove("active");
      }
      if (parent) {
        const parent2El =
          parent.childNodes && parent.childNodes.lenght && parent.childNodes[1]
            ? parent.childNodes[1]
            : null;
        if (parent2El && parent2El.id !== "side-menu") {
          parent2El.classList.remove("mm-show");
        }

        parent.classList.remove("mm-active");
        const parent2 = parent.parentElement;

        if (parent2) {
          parent2.classList.remove("mm-show");

          const parent3 = parent2.parentElement;
          if (parent3) {
            parent3.classList.remove("mm-active"); // li
            parent3.childNodes[0].classList.remove("mm-active");

            const parent4 = parent3.parentElement; // ul
            if (parent4) {
              parent4.classList.remove("mm-show"); // ul
              const parent5 = parent4.parentElement;
              if (parent5) {
                parent5.classList.remove("mm-show"); // li
                parent5.childNodes[0].classList.remove("mm-active"); // a tag
              }
            }
          }
        }
      }
    }
  };

  const activeMenu = useCallback(() => {
    const pathName = props.location.pathname;
    const pathSearch = props.location.search;
    const fullPath = pathName + pathSearch;
    if (isMenuItemFetched) {
      let matchingMenuItem = null;
      const ul = document.getElementById("side-menu-item");
      const items = ul.getElementsByTagName("a");
      removeActivation(items);
      for (let i = 0; i < items.length; ++i) {
        if (fullPath === items[i].pathname + items[i].search) {
          matchingMenuItem = items[i];
          break;
        }
      }
      if (matchingMenuItem) {
        activateParentDropdown(matchingMenuItem);
      }
    }
  }, [
    props.location.pathname,
    props.location.search,
    activateParentDropdown,
    isMenuItemFetched,
  ]);

  useEffect(() => {
    ref.current.recalculate();
  }, []);

  useEffect(() => {
    activeMenu();
  }, [location, activeMenu]);

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop;
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300;
      }
    }
  }

  return (
    <React.Fragment>
      <>
        <SimpleBar
          style={{ maxHeight: "100%" }}
          ref={ref}
          className="sidebar-menu-scroll"
        >
          <div id="sidebar-menu">
            <div
              className={classNames({ "position-relative": getMenusLoading })}
            >
              {getMenusLoading && <Loader isWhite={true} />}
            </div>
            <ul className="metismenu list-unstyled" id="side-menu-item">
              {(sidebarMenuData || [])
                .sort((a, b) => (a.ordem > b.ordem ? 1 : -1))
                .map((item, key) => {
                  return (
                    <React.Fragment key={key}>
                      <MenuItem item={item} solar={props.solar} />
                    </React.Fragment>
                  );
                })}
            </ul>
          </div>
        </SimpleBar>
      </>
      {/* } */}
    </React.Fragment>
  );
};

SidebarNav.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withRouter(withTranslation()(SidebarNav));
