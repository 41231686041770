import React from "react";
import { Card, CardBody } from "reactstrap";

// conmponents
import BenefitsChat from "./BenefitsChat";
import SvgIcon from "../../components/SvgIcon";
import { useColors } from "../../hooks/useColors";

const Benefits = ({ data }) => {
  const { colorDefault } = useColors();
  const benefits = (data?.benefits && data?.benefits.values) || {};
  const total_used_benefits =
    benefits && benefits.hasOwnProperty("total_used_benefits")
      ? benefits.total_used_benefits
      : 0;

  const maxValue =
    benefits && benefits.hasOwnProperty("total_benefits")
      ? benefits.total_benefits
      : 0;
  const free_shipping = benefits && benefits.free_shipping;
  const order_discount = benefits && benefits.order_discount;
  const shipping_discount = benefits && benefits.shipping_discount;
  return (
    <Card>
      <CardBody>
        <h5 className="titulo-beneficios">Meus Benefícios</h5>
        <div>
          <div className="text-center">
            <div className="mb-4">
              <BenefitsChat data={total_used_benefits} max={maxValue} />
              <p className="text-muted1 mb-1">Seu resumo</p>
              <p className="text-muted1 mb-0">
                {data?.benefits?.updated_at || ""}
              </p>
            </div>

            {/*   <div className="d-flex align-items-center campo-benefits mb-3">
              <div className="avatar-sm">
                <span className=" avatar-title bg-transparent icone">
                  <SvgIcon name="truck" color={colorDefault} />
                </span>
              </div>
              <div className="flex-1 align-self-center">
                <div>
                  <h5
                    className="mb-1 beneficios"
                    style={{ color: colorDefault }}
                  >
                    {free_shipping && free_shipping.used
                      ? free_shipping.used
                      : 0}{" "}
                    de{" "}
                    {free_shipping && free_shipping.used
                      ? free_shipping.total
                      : 0}
                  </h5>
                  <p className="texto-beneficio mb-0 ">Frete Grátis</p>
                </div>
              </div>
            </div> */}
            <div className="d-flex align-items-center campo-benefits mb-3">
              <div className="avatar-sm">
                <span className="avatar-title bg-transparent icone">
                  <SvgIcon name="percent" color={colorDefault} />
                </span>
              </div>
              <div className="flex-1 align-self-center">
                <div>
                  <h5
                    className="mb-1 beneficios"
                    style={{ color: colorDefault }}
                  >
                    {order_discount && order_discount.used
                      ? order_discount.used
                      : 0}{" "}
                    de{" "}
                    {order_discount && order_discount.used
                      ? order_discount.total
                      : 0}
                  </h5>
                  <p className="texto-beneficio mb-0">Desconto no Pedido</p>
                </div>
              </div>
            </div>
            <div className="d-flex align-items-center campo-benefits mb-3">
              <div className="avatar-sm">
                <span className=" avatar-title bg-transparent icone">
                  <SvgIcon name="truck-percent" color={colorDefault} />
                </span>
              </div>
              <div className="flex-1 align-self-center">
                <div>
                  <h5
                    className="mb-1 beneficios"
                    style={{ color: colorDefault }}
                  >
                    {" "}
                    {shipping_discount && shipping_discount.used
                      ? shipping_discount.used
                      : 0}{" "}
                    de{" "}
                    {shipping_discount && shipping_discount.used
                      ? shipping_discount.total
                      : 0}
                  </h5>
                  <p className="mb-0 texto-beneficio mb-0">Desconto no Frete</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </CardBody>
    </Card>
  );
};

export default Benefits;
