import React from "react";

// components
import DataTable from "../../../components/DataTable";
import Loader from "../../../components/Loader";
import {
  WarrantyIdCol,
  NFE,
  SolicitanteCol,
  ConsultorCol,
  ClienteCol,
  StatusCol,
  DataCol,
} from "./WarrantyListTableColumns";
import { strToUpperCase } from "../../../utils";

const WarrantyRequest = ({ data, getWarrantyLoading }) => {
  //pagination customization
  const total = data.length || 0;

  const columns = [
    {
      dataField: "id",
      text: "#",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => <WarrantyIdCol row={row} />,
      csvFormatter: (cellContent, row) => {
        return row.id ? row.id : "-";
      },
    },
    {
      dataField: "data",
      text: "Data",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => <DataCol row={row} />,
      csvFormatter: (cellContent, row) => {
        return row["created_at"] ? row["created_at"] : "-";
      },
    },
    {
      dataField: "invoice_number",
      text: "NF-e",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => <NFE row={row} />,
      csvFormatter: (cellContent, row) => {
        return row.invoice["invoice_number"]
          ? row.invoice["invoice_number"]
          : "-";
      },
    },
    {
      dataField: "firstname",
      text: "Solicitante",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => <SolicitanteCol row={row} />,
      csvFormatter: (cellContent, row) => {
        return row.user["code"] && row.user["firstname"]
          ? `${row.user["code"]} - ${strToUpperCase(row.user["firstname"])}`
          : "-";
      },
    },
    {
      dataField: "name_sup",
      text: "Consultor",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => <ConsultorCol row={row} />,
      csvFormatter: (cellContent, row) => {
        return row.user["name_sup"] ? row.user["name_sup"] : "-";
      },
    },
    {
      dataField: "fullname",
      isDummyField: true,
      text: "Cliente",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => <ClienteCol row={row} />,
      csvFormatter: (cellContent, row) => {
        return row["fullname"] ? row["fullname"] : "-";
      },
    },
    {
      dataField: "status",
      text: "Status",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => <StatusCol row={row} />,
      csvFormatter: (cellContent, row) => {
        return row.status && row.status[0] && row.status[0]["name"]
          ? row.status[0]["name"]
          : "-";
      },
    },
  ];

  /**
   * data sorting
   */
  const defaultSorted = [
    {
      dataField: "id",
      order: "desc",
    },
  ];

  return (
    <React.Fragment>
      <div className="custom-datatable px-3">
        {getWarrantyLoading && <Loader />}
        <DataTable
          totalRecords={total}
          defaultSorted={defaultSorted}
          data={data}
          columns={columns}
          keyField="id"
          isExcel={true}
        />
      </div>
    </React.Fragment>
  );
};

export default WarrantyRequest;
