import { useCallback } from 'react';
import { useProfile } from './UserHooks';
import { useSolar } from './SolarHooks';

const getUserData = (userProfile) => ({
  id: userProfile?.claims?.code,
  email: userProfile?.claims?.email,
  phone: userProfile?.claims?.phone_number,
  name: userProfile?.claims?.name
});

export const useDataLayer = () => {
  const { userProfile } = useProfile();
  const isSolar = useSolar();

  const pushToDataLayer = useCallback((event, additionalData = {}, isSendStore = true) => {
    const dataLayerEvent = {
      event,
      ...additionalData // Spread additional data to overwrite defaults
    };

    if (isSendStore) {
      dataLayerEvent['store'] = isSolar ? "solar" : "ar_condicionado";
    }

    if (userProfile) {
      const userData = getUserData(userProfile);
      dataLayerEvent['user_data'] = userData;
    }

    if (window && window.dataLayer) {
      window.dataLayer.push(dataLayerEvent);
    } else {
      console.warn("dataLayer is not defined");
    }
  }, []);

  return { pushToDataLayer };
};