import React from "react";
import { Link } from "react-router-dom";

// utils
import { formateDate, strToUpperCase } from "../../../utils";

import classnames from "classnames";

const WarrantyIdCol = ({ row }) => {
  return (
    <div className="table-text-break">
      <Link to={`/warranty/${row.id}`} className="text-body fw-bold">
        {row.id ? row.id : "-"}
      </Link>
    </div>
  );
};

const DataCol = ({ row }) => {
  const date = row["created_at"] ? formateDate(row["created_at"]) : "-";
  return <div className="table-text-break">{date}</div>;
};

const NFE = ({ row }) => {
  return (
    <div className="table-text-break">
      <span>
        {row.invoice && row.invoice["invoice_number"]
          ? row.invoice["invoice_number"]
          : "-"}
      </span>
    </div>
  );
};

const SolicitanteCol = ({ row }) => {
  return (
    <div className="table-text-break">
      <span>
        {row.user["code"]} - {strToUpperCase(row.user["firstname"])}
      </span>
    </div>
  );
};

const ConsultorCol = ({ row }) => {
  return (
    <div className="table-text-break">
      <span>{row.user && row.user["name_sup"] ? row.user["name_sup"] : "-"}</span>
    </div>
  );
};

const ClienteCol = ({ row }) => {
  // TODO - check the icon when api is ready
  return (
    <div className="table-text-break">
      <span>{row["fullname"] ? row["fullname"] : "-"}</span>
    </div>
  )
};

const StatusCol = ({ row }) => {
  const statusClass =
    row.status &&
    row.status[0] &&
    row.status[0]["cssClass"] &&
    row.status[0]["cssClass"] !== "default"
      ? row.status[0]["cssClass"]
      : "secondary";

  return (
    <div className="table-text-break">
      <span
        className={classnames("badge", "font-size-12", `bg-${statusClass}`)}
      >
        {row.status && row.status[0] && row.status[0]["name"]
          ? row.status[0]["name"]
          : "-"}
      </span>
    </div>
  );
};

export {
  WarrantyIdCol,
  NFE,
  SolicitanteCol,
  ConsultorCol,
  ClienteCol,
  StatusCol,
  DataCol,
};
