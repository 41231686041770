import React from "react";
import classnames from "classnames";
import { Col, Row } from "reactstrap";

const SectionTitle = ({ title, className, action }) => {
  return (
    <div className="mb-4 px-4">
      <Row noGutters>
        <Col>
          <h4 className={classnames("mb-0", "mt-1", className)}>{title}</h4>
        </Col>
        {action && (
          <Col className="text-end">
            <div>{action}</div>
          </Col>
        )}
      </Row>
    </div>
  );
};

export default SectionTitle;
