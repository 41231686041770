import React, { useState, useEffect, createRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  CardBody,
  Col,
  Row,
  Card,
  FormGroup,
  Label,
  Input,
  Button,
  Spinner,
  InputGroupText,
} from "reactstrap";
import { apiClient, instance } from "../../../api/apiCore";

import { AsyncTypeahead, Typeahead } from "react-bootstrap-typeahead";
import {
  getNewOrder,
  changeGroup,
  changePagto,
  getOrderProducts,
  atualizaCarrinho,
} from "../../../store/actions";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

const OrderFilter = ({
  changePagtoSelected,
  addFilterPedido,
  filterPedido,
  dataLeed,
  parceiros,
  setParceiros,
}) => {
  const { id } = useParams();
  /*  const catSelector = createRef(); */
  /*   const gruSelector = createRef();
   */ const dispatch = useDispatch();
  const { newOrder, order, loading } = useSelector(state => ({
    newOrder: state.Order.newOrder.newOrder,
    order: state.Order.orderActive.order,
    loading: state.Order.newOrder.loading,
  }));

  const [form, setForm] = useState({
    CODEMPRESA: "02",
    Nomecondpagto: "A VISTA - BOLETO JA COM 5% OFF",
    Nometabela_preco: "SOLAR",
    bitolaliq: "",
    bitolasuc: "",
    capacidade: "",
    categoria: "",
    ciclo: "",
    codfabric: "",
    condpagto: "500",
    condpagto_limit: 2880,
    descricao: "",
    estoque: -10000000,
    fabricante: "",
    grupos: "INO ",
    kwpde: "",
    product_type: "abr",
    tabela_preco: "043",
    voltagem: "",
    fabInversor: "TODOS",
  });
  const [filters, setFilters] = useState({
    vendedores: [{ codigo: "", label: "" }],
    tabela: [],
    condPagto: [],
    grupos: [],
    categorias: [],
    capacidade: [],
    voltagem: [],
    fabricantes: [],
    inversores: [],
    inversoresFilter: [],
    modules: [],
  });
  const [initial, setInitial] = useState([]);
  const [, setCapSelected] = useState([]);
  const [, setFabSelected] = useState([]);
  const [, setCicSelected] = useState([]);
  const [, setVolSelected] = useState([]);
  const [marcaSelect, setMarceSelect] = useState([]);
  const [tabela, setTabela] = useState([]);
  const [pagamento, setPagamento] = useState([]);
  const [grupo, setGrupo] = useState([]);
  const [categoria, setCategoria] = useState([]);
  const [numModulos, setNumModulos] = useState([]);
  const [count, setCount] = useState(0);
  const [varEscolha, setVarEscolha] = useState([]);
  const [lockPower] = useState(false);
  const [, setLoadingSelectModulePower] = useState(false);

  const [roofs, setRoofs] = useState([]);
  const [inversores, setInversores] = useState([]);
  const [modules, setModules] = useState("");
  const [loadingSelect, setLoadingSelect] = useState(false);
  const myDiv = document.getElementById("sentinel");
  const [Coordinates, setCoordinates] = useState(0);

  useEffect(() => {
    if (document) {
      setCoordinates(myDiv?.getBoundingClientRect());
    }
  }, [document, myDiv]);

  useEffect(() => {
    instance.defaults.headers.common["ufEntrega"] = "SP";
  }, []);

  function getProducts(e) {
    if (form.modulo == "TODOS") {
      form.modulo = "";
    }
    /*  if (form.tabela_preco === "" || form.condpagto === "") {
      toast.error("Favor preencher Tabela/Pagamento", { theme: "colored" });
    } else if (
      form.grupos === "" &&
      form.categoria === "" &&
      form.capacidade === ""
    ) {
      toast.error(
        "Favor selecionar ao menos um dos seguintes filtros: Grupo, Categora ou Capacidade", { theme: "colored" }
      );
    } else { */
    e.preventDefault();
    if (form.kwpde !== "") {
      const start = Coordinates.top;
      const end = Coordinates.bottom;

      dispatch(getOrderProducts(form));
      if (start >= 0 && end <= window.innerHeight) {
      } else {
        window.scrollTo(0, Coordinates.y - 200);
      }
    } else {
      toast.warning("É necessário preencher o KWP para pesquisar produtos", {
        theme: "colored",
      });
    }
  }

  function clearCheckboxes() {
    let capacidade = document.getElementsByName("capacidade");
    for (let cap of capacidade) {
      cap.checked = false;
    }
    let ciclo = document.getElementsByName("ciclo");
    for (let cic of ciclo) {
      cic.checked = false;
    }
    let voltagem = document.getElementsByName("voltagem");
    for (let vol of voltagem) {
      vol.checked = false;
    }
    let fabricante = document.getElementsByName("fabricante");
    for (let fab of fabricante) {
      fab.checked = false;
    }

    setCapSelected([]);
    setCicSelected([]);
    setFabSelected([]);
    setVolSelected([]);
  }

  useEffect(() => {
    if (
      !(
        newOrder.tabela &&
        newOrder.condPagto &&
        newOrder.grupos &&
        newOrder.categorias
      )
    )
      dispatch(getNewOrder());
  }, []);

  useEffect(() => {
    if (newOrder) {
      let tab = [];
      Object.keys(newOrder.tabela || {}).map(function (key) {
        tab.push({ codigo: key, label: newOrder.tabela[key].trim() });
      });

      let pag = [];
      let pagto = Object.values(newOrder.condPagto || []);
      let pag_keys = Object.keys(newOrder.condPagto || []);
      for (let i = pagto.length; i >= 0; i--) {
        Object.keys(pagto[i] || {}).map(function (key) {
          pag.push({
            codigo: key,
            label: pagto[i][key].name.trim(),
            type: pag_keys[i],
          });
        });
      }

      let gru = [];
      Object.values(newOrder.grupoSolar || {}).map(value => {
        gru.push({ codigo: value.CODIGO.trim(), label: value.DESCR.trim() });
      });

      let cat = [];
      Object.values(newOrder.categorias || []).map(value => {
        cat.push({ codigo: value.CODIGO.trim(), label: value.DESCR.trim() });
      });

      let cap = [{ codigo: "Todos", label: "Todos" }];
      Object.values(newOrder.capacidade || {}).map(value => {
        cap.push({ codigo: value.CODIGO.trim(), label: value.DESCR.trim() });
      });
      let fronius_quality = "FQ";
      let fab = [{ codigo: "Todos", label: "Todos" }];
      Object.values(newOrder.fabricantes || {}).map(value => {
        if (value.CODIGO != fronius_quality) {
          fab.push({ codigo: value.CODIGO.trim(), label: value.DESCR.trim() });
        }
      });

      let volt = Object.values(newOrder.voltagem || []);

      setFilters({
        ...filters,
        tabela: tab,
        condPagto: pag,
        grupos: gru,
        categorias: cat,
        capacidade: cap,
        voltagem: volt,
        fabricantes: fab,
      });

      if (count <= 5) {
        setInitial({
          tabela: tab,
          condPagto: pag,
          grupos: gru,
          categorias: cat,
          capacidade: cap,
          voltagem: volt,
          fabricantes: fab,
        });

        if (id && order) {
          if (tabela.length < 1 && tab[0]?.codigo) {
            let result = tab.find(i => i.codigo == order.TabelaPreco);
            if (result) setTabela([result]);
          }

          if (pagamento.length < 1 && pag[0]?.codigo) {
            let result = pag.find(i => i.codigo == order.CondicaoPagamento);
            if (result) setPagamento([result]);
          }
        } else {
          if (tabela.length < 1 && tab[0]?.codigo) {
            let result = tab.find(i => i.codigo == newOrder.tabelaInicial);
            if (result) setTabela([result]);
          }

          if (pagamento.length < 1 && pag[0]?.codigo) {
            let result = pag.find(i => i.codigo == "500");
            if (result) setPagamento([result]);
          }
        }

        if (grupo.length < 1 && gru[0]?.codigo) {
          let result = gru.find(i => i.codigo == "INV");
          if (result) setGrupo([result]);
        }

        if (categoria.length < 1 && cat[0]?.codigo) {
          let result = cat.find(i => i.codigo == "HIW");
          if (result) setCategoria([result]);
        }
        setForm({
          ...form,
          tabela_preco: newOrder.tabelaInicial,
          condpagto: "500",
          grupos: "",
          categoria: "",
        });
      }

      setCount(count + 1);
    }
  }, [newOrder]);

  useEffect(() => {
    if (filters?.condPagto.length > 0) {
      setPagamento([filters?.condPagto[0]]);
    }
  }, [filters?.condPagto.length > 0]);

  useEffect(() => {
    let table = "";
    if (tabela.length > 0) {
      table = tabela[0].codigo;
      dispatch(changePagto(table));
    }
    setForm({ ...form, tabela_preco: table });
  }, [tabela]);

  useEffect(() => {
    let pagto = "";
    if (pagamento.length > 0) {
      pagto = pagamento[0]?.codigo || "";
      addFilterPedido({
        ...filterPedido,
        Nomecondpagto: pagamento[0]?.label || "",
        condpagto: pagamento[0]?.codigo || "",
        Nometabela_preco: tabela[0]?.label || "",
        tabela_preco: tabela[0]?.codigo || "",
        vendedor2: parceiros[0]?.codigo || "",
      });
    }
    setForm({ ...form, condpagto: pagto });
    changePagtoSelected(pagamento);
  }, [pagamento]);

  useEffect(() => {
    addFilterPedido({
      ...filterPedido,
      vendedor2: parceiros[0]?.codigo || "",
    });
  }, [parceiros]);

  useEffect(() => {
    let gru = "";
    setLoadingSelectModulePower(true);
    if (grupo.length > 0) {
      gru = grupo[0].codigo;
      if (count > 6) {
        setCategoria([]);
      }
      setFilters({
        ...filters,
        capacidade: initial.capacidade,
      });
      dispatch(changeGroup(gru));
    } else {
      if (filters.length > 0) {
        setFilters({
          ...filters,
          capacidade: initial.capacidade,
          categorias: initial.categorias,
        });
      }
    }
    // if(catSelector.current)
    /*  catSelector.current.clear(); */
    clearCheckboxes();
    /*   setForm({
      ...form,
      grupos: gru,
      categoria: "",
    }); */
    setLoadingSelectModulePower(false);
  }, [grupo]);

  /*  useEffect(() => {
    let cat = "";
    if (categoria.length > 0) {
      cat = categoria[0].codigo;
      if (grupo[0] && grupo[0].codigo) {
        let paramns = {
          group: grupo[0].codigo,
          category: cat,
        };

        dispatch(changeCategory(paramns));
      } else {
        setCategoria([]);
      }
    } else {
      if (filters.length > 0) {
        setFilters({
          ...filters,
          capacidade: initial.capacidade,
        });
      }
    }
    clearCheckboxes();
    setForm({ ...form, categoria: cat });
  }, [categoria]); */

  const getRoofs = () => {
    setLoadingSelect(true);
    apiClient.get(`/api/solar/roofs`).then(res => {
      setRoofs(res.data);
      setForm({
        ...form,
        ciclo: dataLeed?.lead?.roof_type ? dataLeed?.lead?.roof_type : "1",
        kwpde: dataLeed?.calculations?.kwp ? dataLeed?.calculations?.kwp : "",
      });
    });
    setLoadingSelect(false);
  };
  useEffect(() => {
    getRoofs();
  }, [dataLeed?.lead?.roof_type, dataLeed?.lead?.kwh_price]);

  useEffect(() => {
    if (inversores.length > 0) {
      setForm(state => {
        return { ...state, inversor: inversores[0].label };
      });
    }
  }, [inversores]);

  const getInversores = () => {
    apiClient.create(`/api/inverters`).then(res => {
      let inv = [];

      res.data.map((value, index) => {
        inv.push({ codigo: index, label: value.nome });
      });
      setFilters(state => {
        return {
          ...state,
          inversores: inv,
          inversoresFilter: inv,
        };
      });
    });
  };
  useEffect(() => {
    getInversores();
  }, []);

  useEffect(() => {
    if (modules.length > 0) {
      setForm(state => {
        return { ...state, modulo: modules[0].label };
      });
    }
  }, [modules]);

  const getModules = () => {
    apiClient.get(`/api/person-kits/modules/02/02?type=INVN`).then(res => {
      let mod = [];
      mod.push({ codigo: "", label: "TODOS" });
      res.map((value, index) => {
        mod.push({ codigo: index, label: value.nome });
      });

      setFilters(state => {
        return {
          ...state,
          modules: mod,
        };
      });
    });
  };
  useEffect(() => {
    getModules();
  }, []);

  useEffect(() => {
    setForm({
      ...form,
      categoria: categoria[0]?.codigo,
    });
  }, [categoria]);

  const mockFabricanteInversor = [
    { id: 1, name: "TODOS" },
    { id: 2, name: "FRONIUS" },
    { id: 3, name: "SOFAR" },
    { id: 4, name: "SOLIS" },
  ];

  const filterInventerFab = e => {
    var value = e.target.value;
    var validValue = value == "TODOS" ? "" : value;
    var filter = filters.inversores.filter(item =>
      item.label.includes(validValue)
    );

    setForm({
      ...form,
      fabInversor: value,
    });
    setFilters(state => {
      return {
        ...state,
        inversoresFilter: filter,
      };
    });
  };

  const filterModules = e => {
    var value = e.target.value;
    var validValue = value == "TODOS" ? "" : value;
    setModules(value);
    setForm(state => {
      return {
        ...state,
        module: validValue,
      };
    });
  };

  const format = e => {
    var valor = e.target.value;

    valor = valor + "";
    valor = parseInt(valor.replace(/[\D]+/g, ""));
    valor = valor + "";
    valor = valor.replace(/([0-9]{2})$/g, ".$1");

    if (valor == "NaN") {
      setForm(state => {
        return {
          ...state,
          kwpde: "",
        };
      });
    } else {
      setForm(state => {
        return {
          ...state,
          kwpde: valor,
        };
      });
    }
  };

  return (
    <Row id="filter">
      <Col>
        <Card>
          <CardBody className="filter-order">
            <h5 className="h5">Busca de Kits</h5>
            <Row style={{ marginTop: 20, marginBottom: 20 }}>
              <Label style={{ color: "#696969", fontWeight: "normal" }}>
                Preencha os campos abaixo para buscar o kit que melhor atende as
                suas necessidades:
              </Label>
            </Row>
            <Row>
              <Col md={4}>
                <Label>Tipo de Telhado</Label>{" "}
                <div className="input-group">
                  <label className="input-group-text" htmlFor="">
                    <i className="ph-house-bold"></i>
                  </label>

                  <select
                    className="form-select"
                    aria-label="Filter select"
                    onChange={e =>
                      setForm({
                        ...form,
                        ciclo: e.target.value,
                      })
                    }
                    value={form.ciclo}
                  >
                    {roofs?.map(item => {
                      return (
                        <option key={item.index} value={item.index}>
                          {loadingSelect ? "..." : item?.roof_name}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </Col>
              <Col md={4}>
                <Label>Potência</Label>

                <div className="input-group" style={{ flexWrap: "nowrap" }}>
                  <InputGroupText>kWp</InputGroupText>
                  <input
                    disabled={lockPower}
                    value={form.kwpde}
                    className="input-icon"
                    placeholder="0.00"
                    onChange={format}
                  />
                </div>
              </Col>{" "}
              <Col md={4}>
                <Label>Tensão da Rede</Label>{" "}
                <div className="input-group">
                  <label className="input-group-text" htmlFor="">
                    <i className="ph-lightning-bold"></i>
                  </label>
                  <select
                    className="form-select"
                    aria-label="Filter select"
                    onChange={e =>
                      setForm({
                        ...form,
                        voltagem: e.target.value,
                      })
                    }
                    value={form.voltagem}
                  >
                    <option selected value="">
                      TODOS
                    </option>
                    <option value="1">MONOFÁSICO 220V</option>
                    <option value="2">TRIFÁSICO 220V</option>
                    <option value="3">TRIFÁSICO 380V</option>
                  </select>
                </div>
              </Col>
            </Row>
            <Row className="mt-4">
              <Col md={4}>
                <Label>Fabricante do inversor:</Label>
                <select
                  className="form-select"
                  aria-label="Filter select"
                  onChange={filterInventerFab}
                  value={form.fabInversor}
                >
                  {mockFabricanteInversor?.map(item => {
                    return (
                      <option key={item.name} name={item.name}>
                        {item?.name}
                      </option>
                    );
                  })}
                </select>
              </Col>
              {/*      <Col md={4}>
                <Label>Inversores:</Label>
                <Typeahead
                  clearButton
                  id="select-grupo"
                  labelKey="label"
                  onChange={setInversores}
                  options={filters.inversoresFilter}
                  placeholder="Escolha o inversor:"
                  selected={inversores}
                />
              </Col> */}
              <Col md={4}>
                <Label>Módulos:</Label>
                <select
                  className="form-select"
                  aria-label="Filter select"
                  onChange={e => filterModules(e)}
                  value={modules}
                >
                  {filters.modules?.map(item => {
                    return (
                      <option
                        key={item.codigo}
                        name={item.label}
                        value={item.label}
                      >
                        {item?.label}
                      </option>
                    );
                  })}
                </select>

                {/*    <Typeahead
                  clearButton
                  id="select-grupo"
                  labelKey="label"
                  onChange={setModules}
                  options={filters.modules}
                  placeholder="Escolha o módulo:"
                  selected={modules}
                /> */}
              </Col>{" "}
              <Col md={4}>
                <FormGroup>
                  <Label>Formas de Pagamento</Label>
                  <Typeahead
                    clearButton
                    id="select-condpagto"
                    labelKey="label"
                    onFocus={() => {
                      setPagamento([]);
                      setVarEscolha(pagamento);
                    }}
                    onBlur={() => {
                      if (pagamento.length < 1) setPagamento(varEscolha);
                    }}
                    onChange={e => {
                      if (confirm) {
                        setPagamento(e);
                        toast.info(
                          `Ao atualizar a condição de pagamento, os valores dos produtos são atualizados e podem sofrer alterações.`,
                          { theme: "colored" }
                        );
                        dispatch(
                          atualizaCarrinho({
                            pgto: e,
                            tabela,
                          })
                        );
                        setForm({
                          ...form,
                          Nomecondpagto: e[0]?.label ? e[0].label : "",
                        });
                      } else {
                        setPagamento(pagamento);
                      }
                    }}
                    options={
                      filters?.condPagto?.sort((a, b) =>
                        a.label > b.label ? 1 : -1
                      ) || []
                    }
                    placeholder="Escolha a condição de pagamento:"
                    selected={pagamento}
                    renderMenuItemChildren={option => (
                      <div>
                        {option.label}
                        <div>
                          <small>{option.type}</small>
                        </div>
                      </div>
                    )}
                  />
                </FormGroup>
                {/*         <Label>Tecnologia:</Label>
                <Typeahead
                  clearButton
                  id="select-grupo"
                  labelKey="label"
                  onChange={setGrupo}
                  options={filters.grupos}
                  placeholder="Escolha o grupo de produtos:"
                  ref={gruSelector}
                  selected={grupo}
                  onFocus={() => {
                    setGrupo([]);
                    setVarEscolha(grupo);
                  }}
                  onBlur={() => {
                    if (grupo.length < 1) setGrupo(varEscolha);
                  }}
                /> */}
              </Col>
            </Row>
            <Row
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginTop: 30,
              }}
            >
              <Col
                md={2}
                style={{ display: "flex", justifyContent: "flex-end" }}
              >
                <Button
                  className="button-search d-flex align-items-center"
                  onClick={e => getProducts(e)}
                >
                  Buscar Produto
                  <i
                    style={{ marginLeft: 10 }}
                    className="ph-magnifying-glass-bold"
                  ></i>
                </Button>
              </Col>
            </Row>
          </CardBody>
        </Card>

        {/*    <Card>
          <CardBody className="filter-order">
            <Row>
              <FormGroup>
                <Row>
                  {" "}
                  <Col md={6}>
                    {" "}
                    <h5 className="h5">Busca Avançada</h5>
                  </Col>
                  <Col
                    md={6}
                    style={{ display: "flex", justifyContent: "flex-end" }}
                  >
                    <Button
                      color="light"
                      style={{
                        color: "#0067B4",
                        backgroundColor: "#FFF",
                        borderColor: "#F5F5F5",
                        borderWidth: "2px",
                        display: "flex",
                        width: "fit-content",
                        marginLeft: "auto",
                      }}
                      onClick={() => {
                        gruSelector.current.clear();
                        catSelector.current.clear();
                        clearCheckboxes();
                        setForm({
                          ...form,
                          Nomecondpagto: "A VISTA - BOLETO JA COM 5% OFF",
                          Nometabela_preco: "SOLAR",
                          bitolaliq: "",
                          bitolasuc: "",
                          capacidade: "",
                          categoria: "",
                          ciclo: "",
                          codfabric: "",
                          condpagto: "500",
                          condpagto_limit: 2880,
                          descricao: "",
                          estoque: -10000000,
                          fabricante: "",
                          grupos: "INO ",
                          kwpde: "",
                          product_type: "abr",
                          tabela_preco: "043",
                          voltagem: "",
                        });
                        setFilters({
                          ...filters,
                          capacidade: initial.capacidade,
                          categorias: initial.categorias,
                        });
                        setNumModulos([]);
                        setCategoria([]);
                      }}
                    >
                      Limpar Filtros <i className="fa fa-times ocultar"></i>
                    </Button>
                  </Col>
                </Row>
                <Label
                  style={{
                    marginTop: 10,
                    marginBottom: 30,
                    color: "#696969",
                    fontWeight: "normal",
                  }}
                >
                  Selecione filtros avançados para uma busca + específica:
                </Label>
              </FormGroup>
            </Row>

            <hr className="linha" />
            <Row className="mt-4">
              <Col md={6}>
                <FormGroup>
                  <Label>Tabela</Label>
                  <Typeahead
                    clearButton
                    id="select-tabela"
                    labelKey="label"
                    onFocus={() => {
                      setTabela([]);
                      setVarEscolha(tabela);
                    }}
                    onBlur={() => {
                      if (tabela.length < 1) setTabela(varEscolha);
                    }}
                    onChange={e => {
                      setTabela(e),
                        setForm({ ...form, Nometabela_preco: e[0].label });
                    }}
                    options={filters.tabela}
                    placeholder="Escolha a tabela de preço:"
                    selected={tabela}
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label>Formas de Pagamento</Label>
                  <Typeahead
                    clearButton
                    id="select-condpagto"
                    labelKey="label"
                    onFocus={() => {
                      setPagamento([]);
                      setVarEscolha(pagamento);
                    }}
                    onBlur={() => {
                      if (pagamento.length < 1) setPagamento(varEscolha);
                    }}
                    onChange={e => {
                      if (confirm) {
                        setPagamento(e);
                        toast.info(
                          `Ao atualizar a condição de pagamento, os valores dos produtos são atualizados e podem sofrer alterações.`,
                          { theme: "colored" }
                        );
                        dispatch(
                          atualizaCarrinho({
                            pgto: e,
                            tabela,
                          })
                        );
                        setForm({
                          ...form,
                          Nomecondpagto: e[0]?.label ? e[0].label : "",
                        });
                      } else {
                        setPagamento(pagamento);
                      }
                    }}
                    options={
                      filters?.condPagto?.sort((a, b) =>
                        a.label > b.label ? 1 : -1
                      ) || []
                    }
                    placeholder="Escolha a condição de pagamento:"
                    selected={pagamento}
                    renderMenuItemChildren={option => (
                      <div>
                        {option.label}
                        <div>
                          <small>{option.type}</small>
                        </div>
                      </div>
                    )}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row className="mt-4">
              <Col md={4}>
                <Label>Potência do Módulo:</Label>
                <Typeahead
                  clearButton
                  id="select-categoria"
                  labelKey="label"
                  onChange={setCategoria}
                  options={filters.categorias}
                  placeholder={
                    loading
                      ? "Carregando..."
                      : "Escolha a categoria do produto:"
                  }
                  ref={catSelector}
                  selected={categoria}
                  onFocus={() => {
                    setCategoria([]);
                    setVarEscolha(categoria);
                  }}
                  onBlur={() => {
                    if (categoria.length < 1) setCategoria(varEscolha);
                  }}
                />
              </Col>
              <Col md={4}>
                <Label>Número de Módulos</Label>
                <Typeahead
                  clearButton
                  id="select-categoria"
                  labelKey="label"
                  onChange={e => {
                    setNumModulos(e);
                    setForm({
                      ...form,
                      capacidade: e[0]?.codigo == "Todos" ? "" : e[0]?.codigo,
                    });
                  }}
                  options={filters.capacidade}
                  placeholder="Escolha os módulos do produto:"
                  ref={catSelector}
                  selected={numModulos}
                />
              </Col>{" "}
              <Col md={4}>
                <Label>Descrição</Label>
                <Input
                  type="text"
                  placeholder="Digite aqui..."
                  value={form?.descricao}
                  onChange={e =>
                    setForm({
                      ...form,
                      descricao: e.target.value,
                    })
                  }
                />
              </Col>
            </Row>

            <Row>
              <Col
                md={12}
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  marginTop: 30,
                }}
              >
                <Button
                  className="button-search d-flex align-items-center"
                  onClick={e => getProducts(e)}
                >
                  Buscar Produto
                  <i
                    style={{ marginLeft: 10 }}
                    className="ph-magnifying-glass-bold"
                  ></i>
                </Button>
              </Col>
            </Row>
          </CardBody>
        </Card> */}
      </Col>
    </Row>
  );
};

export default OrderFilter;
