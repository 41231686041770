import React from "react";
import { Col, Container, Row } from "reactstrap";

//common Components
import Breadcrumb from "../../../components/Breadcrumb";
import ReactEditor from "./ReactEditor";

const EditEvent = () => {
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumb title="Eventos" breadcrumbItem="Editar Evento" />
          <Row>
            <Col md={12}>
              <ReactEditor />
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default EditEvent;
