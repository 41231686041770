import React, { useState } from 'react';
import { Container, Card, CardBody, Form, FormGroup, FormText, Label, Col, Input, Row, Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import "./postCreate.css";
//import ReactQuill from 'react-quill';
//import 'react-quill/dist/quill.snow.css';
import { Link } from "react-router-dom";

const PostCreate = () => {
    const [isHiddenPublicar, setIsHiddenPublicar] = useState(false);
    const [isHiddenCategorias, setIsHiddenCategorias] = useState(false);
    const [isHiddenSegmentar, setIsHiddenSegmentar] = useState(false);
    const [isHiddenPropriedades, setIsHiddenPropriedades] = useState(false);
    const [isHiddenImagem, setIsHiddenImagem] = useState(false);
    const [showedOpt, setShowedOpt] = useState(0);
    const [editarStatus, setEditarStatus] = useState(false);
    const [editarVisibilidade, setEditarVisibilidade] = useState(false);
    const [editarPublicar, setEditarPublicar] = useState(false);
    const [optStatus, setOptStatus] = useState("Publicar");
    const [optVisibilade, setOptVisibilidade] = useState("Público");
    const [optHoraData, setOptHoraData] = useState({ data: "", hora: "" });
    const [isHiddenModal, setIsHiddenModal] = useState(false);

    return (
        <React.Fragment>
            <div className="page-content PostCreate">
                <Container>
                    <h1 className="titulo-pag"> Adicionar nova</h1>
                    <div className="engloba-card">
                        <div className="card-add-novo">
                            <Card>
                                <CardBody>
                                    <h4 className='titulo-card-edit'>
                                        <i className="ph-note-pencil"></i>
                                        Adicionar Nova Página
                                    </h4>
                                    <hr />
                                    <Form className='form-edit'>
                                        <FormGroup row>
                                            <Label for="areatitulo" sm={3}>Titulo</Label>
                                            <Col sm={8}>
                                                <Input id="area-titulo-" name="titulo" placeholder="Digite o título" type="text" />
                                            </Col>
                                        </FormGroup>
                                    </Form>
                                    <Form className='area-edicao'>
                                        <FormGroup row>
                                            <Label for="areatitulo" sm={3}>Insira o texto   aqui:</Label>
                                            <Col sm={8}>
                                                {/* <ReactQuill theme="snow" id="texto" /> */}
                                                procurar novo Componente TEXTO react17
                                            </Col>
                                        </FormGroup>
                                    </Form>
                                </CardBody>
                            </Card>
                        </div>
                        <div className="engloba-coluna">
                            <Card className="card-publicar">
                                <CardBody>
                                    <div className='titulo-icone'>
                                        <h4 className="titulo-cards">
                                            <i className="ph-upload"></i>
                                            Publicar
                                        </h4>
                                        <div className="engloba-icones">
                                            <a href="#" onClick={(e) => {
                                                e.preventDefault();
                                                setIsHiddenPublicar(!isHiddenPublicar)
                                            }}>
                                                {isHiddenPublicar ?
                                                    <i className="ph-caret-down"></i>
                                                    : <i className="ph-caret-up"></i>}
                                            </a>
                                        </div>
                                    </div>
                                    <hr />
                                    <div hidden={isHiddenPublicar}>
                                        <div>
                                            <Label for="exampleSelect" className="label-publicar">
                                                <i className="ph-question"></i>Status: {optStatus}
                                            </Label>
                                            <Link to="#" className='Link-editar' onClick={() => setEditarStatus(!editarStatus)}>Editar</Link>
                                        </div>
                                        {editarStatus && <Row>
                                            <Col>
                                                <Input
                                                    id="exampleSelect"
                                                    name="select"
                                                    type="select"
                                                    value={optStatus}
                                                    onChange={(e) => setOptStatus(e.target.value)}>
                                                    <option>Publicar</option>
                                                    <option>Rascunho</option>
                                                </Input>
                                            </Col>
                                        </Row>}
                                        <div>
                                            <Label for="exampleSelect" className="label-publicar">
                                                <i className="ph-eye"></i>Visibilidade: {optVisibilade}
                                            </Label>
                                            <Link to="#" className='Link-editar' onClick={() => setEditarVisibilidade(!editarVisibilidade)}>Editar</Link>
                                        </div>
                                        {editarVisibilidade && <Row>
                                            <Col>
                                                <Input
                                                    id="exampleSelect"
                                                    name="select"
                                                    type="select"
                                                    value={optVisibilade}
                                                    onChange={(e) => setOptVisibilidade(e.target.value)}>
                                                    <option>Público</option>
                                                    <option>Privado</option>
                                                </Input>
                                            </Col>
                                        </Row>}
                                        <div>
                                            <Label for="exampleDate" className="label-publicar">
                                                <i className="ph-calendar-blank"></i> Publicar {optHoraData.data || optHoraData.hora ? `${optHoraData.data} - ${optHoraData.hora}` : "imediatamente"}
                                            </Label>
                                            <Link to="#" className='Link-editar' onClick={() => setEditarPublicar(!editarPublicar)}>Editar</Link>
                                        </div>
                                        {editarPublicar && <Row>
                                            <Col className="engloba-data-hora">
                                                <Input
                                                    id="exampleDate"
                                                    name="date"
                                                    placeholder="date placeholder"
                                                    value={optHoraData.data}
                                                    onChange={(e) => setOptHoraData({ ...optHoraData, data: e.target.value })}
                                                    type="date" />
                                                <Input
                                                    id="exampleTime"
                                                    name="time"
                                                    placeholder="time placeholder"
                                                    type="time"
                                                    value={optHoraData.hora}
                                                    onChange={(e) => setOptHoraData({ ...optHoraData, hora: e.target.value })}
                                                />
                                            </Col>
                                        </Row>}
                                        <hr />
                                        <Button className="botao-salvar">Salvar</Button>
                                    </div>
                                </CardBody>
                            </Card>
                            <Row>
                                <Col>
                                    <Card>
                                        <CardBody>
                                            <div className='titulo-icone'>
                                                <h4 className="titulo-cards">
                                                    <i className="ph-tag"></i>
                                                    Categorias
                                                </h4>
                                                <div className="engloba-icones">
                                                    <a href="#" onClick={(e) => {
                                                        e.preventDefault();
                                                        setIsHiddenCategorias(!isHiddenCategorias)
                                                    }}>
                                                        {isHiddenCategorias ?
                                                            <i className="ph-caret-down"></i>
                                                            : <i className="ph-caret-up"></i>}
                                                    </a>
                                                </div>
                                            </div>
                                            <hr />
                                            <div hidden={isHiddenCategorias}>
                                                <div className="navegacao">
                                                    <ul className="nav nav-pills nav-fill back-nav">
                                                        <li className="nav-item">
                                                            <a className={showedOpt == 0 ? "nav-link active" : "nav-link"} onClick={() => setShowedOpt(0)}>Todas as Categorias</a>
                                                        </li>
                                                        <li className="nav-item">
                                                            <a className={showedOpt == 1 ? "nav-link active" : "nav-link"} onClick={() => setShowedOpt(1)}>Mais Usadas</a>
                                                        </li>
                                                    </ul>
                                                </div>
                                                {showedOpt == 0 &&
                                                    <div>
                                                        <ul>
                                                            <li><input type="radio" /> Blog</li>
                                                            <li><input type="radio" /> Notícias</li>
                                                            <li><input type="radio" /> Pop up</li>
                                                            <li><input type="radio" /> Mini banner</li>
                                                            <li><input type="radio" /> Mural de notícias</li>
                                                            <li><input type="radio" /> Marketing</li>
                                                            <li><input type="radio" /> Midias parceiras</li>
                                                            <li><input type="radio" /> Layouts web</li>
                                                            <li><input type="radio" /> E-books</li>
                                                            <li><input type="radio" /> Dicas de marketing</li>
                                                            <li><input type="radio" /> Vídeos</li>
                                                            <li><input type="radio" /> Layouts de redes sociais</li>
                                                            <li><input type="radio" /> Banner venda</li>
                                                            <li><input type="radio" /> Banner garantia</li>
                                                            <li><input type="radio" /> A DIAS PRO</li>
                                                            <li><input type="radio" /> A.books</li>
                                                            <li><input type="radio" /> A.Books</li>
                                                            <li><input type="radio" /> A.books</li>
                                                            <li><input type="radio" /> Changelog</li>
                                                            <li><input type="radio" /> Live ADias</li>
                                                            <li><input type="radio" /> Gravados</li>
                                                        </ul>
                                                    </div>
                                                }
                                                {showedOpt == 1 &&
                                                    <div>
                                                        <ul>
                                                            <li><input type="radio" /> Blog</li>
                                                            <li><input type="radio" /> Notícias</li>
                                                            <li><input type="radio" /> Pop up</li>
                                                            <li><input type="radio" /> Mini banner</li>
                                                            <li><input type="radio" /> Mural de notícias</li>
                                                            <li><input type="radio" /> Marketing</li>
                                                            <li><input type="radio" /> Midias parceiras</li>
                                                            <li><input type="radio" /> Layouts web</li>
                                                            <li><input type="radio" /> E-books</li>
                                                            <li><input type="radio" /> Dicas de marketing</li>
                                                            <li><input type="radio" /> Vídeos</li>
                                                            <li><input type="radio" /> Layouts de redes sociais</li>
                                                            <li><input type="radio" /> Banner venda</li>
                                                            <li><input type="radio" /> Banner garantia</li>
                                                            <li><input type="radio" /> A DIAS PRO</li>
                                                            <li><input type="radio" /> A.books</li>
                                                            <li><input type="radio" /> A.Books</li>
                                                            <li><input type="radio" /> A.books</li>
                                                            <li><input type="radio" /> Changelog</li>
                                                            <li><input type="radio" /> Live ADias</li>
                                                            <li><input type="radio" /> Gravados</li>
                                                        </ul>
                                                    </div>
                                                }
                                                <Link to="#" className="link-add-categoria" onClick={() => setIsHiddenModal(!isHiddenModal)}>
                                                    <i className="ph-plus"></i>
                                                    Adicionar nova categoria
                                                </Link>
                                            </div>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                            <Card>
                                <CardBody>
                                    <div className='titulo-icone'>
                                        <h4 className="titulo-cards">
                                            <i className="ph-users-three"></i>
                                            Segmentar
                                        </h4>
                                        <div className="engloba-icones">
                                            <a href="#" onClick={(e) => {
                                                e.preventDefault();
                                                setIsHiddenSegmentar(!isHiddenSegmentar)
                                            }}>
                                                {isHiddenSegmentar ?
                                                    <i className="ph-caret-down"></i>
                                                    : <i className="ph-caret-up"></i>}
                                            </a>
                                        </div>
                                    </div>
                                    <hr />
                                    <div hidden={isHiddenSegmentar}>
                                        <ul>
                                            <li><input type="checkbox" /> Administradores</li>
                                            <li><input type="checkbox" /> Diretorias</li>
                                            <li><input type="checkbox" /> Gerente</li>
                                            <li><input type="checkbox" /> Supervisor</li>
                                            <li><input type="checkbox" /> Vendedor revenda</li>
                                            <li><input type="checkbox" /> Assistente revenda</li>
                                            <li><input type="checkbox" /> Funcionário clube</li>
                                            <li><input type="checkbox" /> Dono revenda</li>
                                            <li><input type="checkbox" /> Funcionário revenda</li>
                                            <li><input type="checkbox" /> Usuarios</li>
                                            <li><input type="checkbox" /> Vendedor consumidor</li>
                                            <li><input type="checkbox" /> Arquitetos</li>
                                            <li><input type="checkbox" /> Testes multi usuario</li>
                                            <li><input type="checkbox" /> Parceiro diamante</li>
                                            <li><input type="checkbox" /> Parceiro ouro</li>
                                            <li><input type="checkbox" /> Parceiro prata</li>
                                            <li><input type="checkbox" /> Parceiro bronze</li>
                                            <li><input type="checkbox" /> Parceiro clube</li>
                                            <li><input type="checkbox" /> Parceiro esmeralda</li>
                                            <li><input type="checkbox" /> Parceiro solar</li>
                                        </ul>
                                    </div>
                                </CardBody>
                            </Card>
                            <Card>
                                <CardBody>
                                    <div className='titulo-icone'>
                                        <h4 className="titulo-cards">
                                            <i className="ph-upload"></i>
                                            Propriedades da Página
                                        </h4>
                                        <div className="engloba-icones">
                                            <a href="#" onClick={(e) => {
                                                e.preventDefault();
                                                setIsHiddenPropriedades(!isHiddenPropriedades)
                                            }}>
                                                {isHiddenPropriedades ?
                                                    <i className="ph-caret-down"></i>
                                                    : <i className="ph-caret-up"></i>}
                                            </a>
                                        </div>
                                    </div>
                                    <hr />
                                    <div hidden={isHiddenPropriedades}>
                                        <Form>
                                            <FormGroup>
                                                <Label for="propriedades-pag">
                                                    Ordenação do Post:
                                                </Label>
                                                <Input
                                                    id="propriedades-pag"
                                                    name="number"
                                                    type="number" />
                                            </FormGroup>
                                        </Form>
                                    </div>
                                </CardBody>
                            </Card>
                            <Card>
                                <CardBody>
                                    <div className='titulo-icone'>
                                        <h4 className="titulo-cards">
                                            <i className="ph-upload"></i>
                                            Imagem Destacada
                                        </h4>
                                        <div className="engloba-icones">
                                            <a href="#" onClick={(e) => {
                                                e.preventDefault();
                                                setIsHiddenImagem(!isHiddenImagem)
                                            }}>
                                                {isHiddenImagem ?
                                                    <i className="ph-caret-down"></i>
                                                    : <i className="ph-caret-up"></i>}
                                            </a>
                                        </div>
                                    </div>
                                    <hr />
                                    <div hidden={isHiddenImagem}>
                                        <input type="file" />
                                        <Form>
                                            <FormGroup>
                                                <Label for="propriedades-pag">
                                                    URL Da imagem de destaque:
                                                </Label>
                                                <Input
                                                    id="propriedades-pag"
                                                    name="number"
                                                    type="number" />
                                            </FormGroup>
                                        </Form>
                                    </div>
                                </CardBody>
                            </Card>
                        </div>
                    </div>
                </Container >
            </div >
            <div>
                <Modal
                    toggle={() => setIsHiddenModal(!isHiddenModal)} isOpen={isHiddenModal} className="ModalOrder">
                    <ModalHeader className="Titulo-Modal" toggle={function noRefCheck() { }}>
                        Adicionar nova categoria
                    </ModalHeader>
                    <ModalBody>
                        <Form>
                            <FormGroup row className='Formulario-modal'>
                                <Label
                                    for="exampleEmail"
                                    sm={2}>
                                    Nome
                                </Label>
                                <Col sm={10}>
                                    <Input
                                        id="NomeModal"
                                        name="nomeModal"
                                        placeholder="Digite o nome"
                                        type="text" />
                                    <FormText>
                                        O nome é como aparece em seu site.
                                    </FormText>
                                </Col>
                            </FormGroup>
                            <FormGroup row className='Formulario-modal'>
                                <Label for="exampleSelect" sm={2}>
                                    Principal
                                </Label>
                                <Col sm={10}>
                                    <Input
                                        id="Principal"
                                        name="select"
                                        type="select">
                                        <option>Blog</option>
                                        <option>Notícias</option>
                                        <option>Pop up</option>
                                        <option>Mini Banner</option>
                                        <option>Mural de notícias</option>
                                        <option>Marketing</option>
                                        <option>Mídias parceiras</option>
                                        <option>Dicas de marketing</option>
                                        <option>Vídeos</option>
                                        <option>Layouts web</option>
                                        <option>Layouts redes sociais</option>
                                        <option>E-books</option>
                                        <option>A.books</option>
                                        <option>A.books</option>
                                        <option>A.books</option>
                                        <option>Banner venda</option>
                                        <option>Banner garantia</option>
                                        <option>A.DIAS PRO</option>
                                        <option>Changelog</option>
                                        <option>Live Adias</option>
                                        <option>Gravados</option>
                                        <option>Banner venda solar</option>
                                        <option>Pop up solar</option>
                                        <option>Mini banner solar</option>
                                    </Input>
                                    <FormText>Ao contrário das tags, as categorias, podem ter uma hierarquia. Você pode ter uma categoria chamada MPB e nela haver categorias para Bossa Nova e Baião. Totalmente opcional.</FormText>
                                </Col>
                            </FormGroup>
                            <FormGroup row className='Formulario-modal'>
                                <Label for="exampleText" sm={2}>
                                    Descrição
                                </Label>
                                <Col sm={10}>
                                    <Input
                                        id="AreaModal"
                                        name="text"
                                        type="textarea" />
                                    <FormText>A descrição não está em destaque por padrão, no entanto alguns temas podem mostrá-la.</FormText>
                                </Col>
                            </FormGroup>
                            <FormGroup row className='Formulario-modal'>
                                <Label
                                    for="exampleFile"
                                    sm={3}>
                                    Foto de capa
                                </Label>
                                <Col sm={9}>
                                    <Input
                                        id="fileModal"
                                        name="file"
                                        type="file"
                                    />
                                    <FormText>
                                        A foto de capa não aparece padrão em nenhum post, apenas na nova página do Marketing.
                                    </FormText>
                                    <Button className='btnAdicionar'>Adicionar nova categoria</Button>
                                </Col>
                            </FormGroup>
                        </Form>
                    </ModalBody>
                    <ModalFooter>
                        <Button onClick={() => setIsHiddenModal(!isHiddenModal)} isOpen={isHiddenModal}>
                            Fechar
                        </Button>
                    </ModalFooter>
                </Modal>
            </div>
        </React.Fragment >
    );
};

export default PostCreate;