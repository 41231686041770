import React from 'react';
import { Container, Card, CardBody, Row, Col, Button, Input, Form, FormGroup, Label, Table } from 'reactstrap';
import "./buscarRegras.css";
const BuscarRegras = () => {
    return (
        <React.Fragment>
            <div className="page-content buscar-regras">
                <Container>
                    <h1>Buscar Regras</h1>
                    <Card className="engloba-tabela">
                        <CardBody className="engloba-limpar-filtros">
                            <Row>
                                <Col md={4} className='engloba-input'>
                                    <Button className='botao-left'>
                                        <i className="ph-caret-left icone-esquerda"></i>
                                    </Button>
                                    <Input value={1} type="number" className='input-number' />
                                    <p className='mb-0 qnt-pag'>de 70</p>
                                    <Button className='botao-right'>
                                        <i className="ph-caret-right icone-direita"></i>
                                    </Button>
                                </Col>
                                <Col md={4} className='engloba-qnt-total'>
                                    <p className='qnt-resultados mb-0'> 1001 Resultados</p>
                                </Col>
                                <Col md={4} className='engloba-botoes'>
                                    <Button className='limpar-filtros'>Limpar Filtro
                                        <i className="ph-x icone-x"></i>
                                    </Button>
                                    <Button className='pesquisar'>Pesquisar</Button>
                                </Col>
                            </Row>
                        </CardBody>
                        <hr className='linha-pesquisa' />
                        <CardBody className="engloba-pesquisa">
                            <Row className='engloba-linha-pesquisa'>
                                <Col className='engloba-id'>
                                    <Form>
                                        <FormGroup>
                                            <Label for="idPequisa">
                                                ID
                                            </Label>
                                            <Input className='pesquisa'
                                                id="id-pesquisa"
                                                name="id-pesquisa"
                                                type="text" />
                                        </FormGroup>
                                    </Form>
                                </Col>
                                <Col className='engloba-regra'>
                                    <Form>
                                        <FormGroup>
                                            <Label for="regraPesquisa">
                                                Regra
                                            </Label>
                                            <Input
                                                className='pesquisa'
                                                id="regraPesquisa"
                                                name="regraPesquisa"
                                                type="text" />
                                        </FormGroup>
                                    </Form>
                                </Col>
                                <Col className='engloba-codigo'>
                                    <Form>
                                        <FormGroup>
                                            <Label for="codigoCupom">
                                                Código Cupom
                                            </Label>
                                            <Input
                                                className='pesquisa'
                                                id="codigoCupom"
                                                name="codigoCupom"
                                                type="text" />
                                        </FormGroup>
                                    </Form>
                                </Col>
                                <Col className='engloba-inicio'>
                                    <Form>
                                        <FormGroup>
                                            <Label for="inicioPesquisa">
                                                Início
                                            </Label>
                                            <Input
                                                className='iniciodapesquisa pesquisa'
                                                id="inicioPesquisa"
                                                name="inicioPesquisa"
                                                type="text"
                                                placeholder='De' />
                                            <Input
                                                className='pesquisa'
                                                id="inicioPesquisa"
                                                name="inicioPesquisa"
                                                type="text"
                                                placeholder='Para' />
                                        </FormGroup>
                                    </Form>
                                </Col>
                                <Col className='engloba-fim'>
                                    <Form>
                                        <FormGroup>
                                            <Label for="fimPesquisa">
                                                Fim
                                            </Label>
                                            <Input

                                                className='fimdapesquisa pesquisa'
                                                id="fimPesquisa"
                                                name="fimPesquisa"
                                                type="text"
                                                placeholder='De' />
                                            <Input
                                                className='pesquisa'
                                                id="fimPesquisa"
                                                name="fimPesquisa"
                                                type="text"
                                                placeholder='Para' />
                                        </FormGroup>
                                    </Form>
                                </Col>
                                <Col className='engloba-status'>
                                    <Form>
                                        <FormGroup>
                                            <Label for="statusPesquisa">
                                                Status
                                            </Label>
                                            <Input
                                                className='pesquisa'
                                                id="statusPesquisa"
                                                name="statusPesquisa"
                                                type="select">
                                                <option>1</option>
                                                <option>2</option>
                                                <option>3</option>
                                            </Input>
                                        </FormGroup>
                                    </Form>
                                </Col>
                                <Col className='engloba-empresa'>
                                    <Form>
                                        <FormGroup>
                                            <Label for="engloba-empresa">
                                                Empresa/Filial
                                            </Label>
                                            <Input
                                                className='pesquisa'
                                                id="engloba-empresa"
                                                name="engloba-empresa"
                                                type="select">
                                                <option>1</option>
                                                <option>2</option>
                                                <option>3</option>
                                            </Input>
                                        </FormGroup>
                                    </Form>
                                </Col>
                            </Row>
                        </CardBody>
                        <hr className='linha-tabela' />
                        <CardBody className="engloba-tabela">
                            <Table
                                borderless
                                hover>
                                <tbody>
                                    <tr>
                                        <th scope="row">01</th>
                                        <td>Frete Grátis</td>
                                        <td>FRETEGRATIS10</td>
                                        <td>01/01/01</td>
                                        <td>-</td>
                                        <td>Ativo</td>
                                        <td>Empresa</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">02</th>
                                        <td>15% Desconto</td>
                                        <td>DESCONTO15</td>
                                        <td>01/01/01</td>
                                        <td>01/01/01</td>
                                        <td>Inativo</td>
                                        <td>Filial</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">03</th>
                                        <td>Frete Grátis</td>
                                        <td>FRETEGRATIS10</td>
                                        <td>01/01/01</td>
                                        <td>-</td>
                                        <td>Ativo</td>
                                        <td>Empresa</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">04</th>
                                        <td>15% Desconto</td>
                                        <td>DESCONTO15</td>
                                        <td>01/01/01</td>
                                        <td>01/01/01</td>
                                        <td>Inativo</td>
                                        <td>Filial</td>
                                    </tr>

                                </tbody>
                            </Table>
                        </CardBody>
                    </Card>
                </Container>
            </div>
        </React.Fragment >
    );
};

export default BuscarRegras;