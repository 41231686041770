import React from "react";
import "./styles.css";

import {
  GroupRectangles,
  GroupRectanglesBottom,
} from "../../helpers/importer.helper";

import ContainerTop from "./ContainerTop";
import ContainerBottom from "./ContainerBottom";

export default function PageTwo({ dataPdf, dataProductAttributes }) {
  return (
    <>
      <div id="pageTwo" className="scroll" />
      <div className="content-pdf-6">
        <img className="img-background-rectangle" src={GroupRectangles} />
        <img
          className="img-background-rectangle-right"
          src={GroupRectanglesBottom}
        />
        <ContainerTop
          dataPdf={dataPdf}
          dataProductAttributes={dataProductAttributes}
        />
        <ContainerBottom dataPdf={dataPdf} />
      </div>
    </>
  );
}
