import React from "react";
export default function IconConsumption(props) {
  const colorFill = props?.colorFill?.replaceAll("#", "") || "none";

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="19"
      height="33"
      viewBox="0 0 19 33"
      fill={colorFill}
    >
      <path
        d="M9.39227 22.109C8.11321 22.109 6.84316 22.109 5.5641 22.109C5.00564 22.109 4.91556 22.0189 4.91556 21.4514C4.91556 20.5957 4.92457 19.74 4.90656 18.8843C4.89755 18.461 5.08671 18.2988 5.50105 18.2988C8.11321 18.3078 10.7254 18.3078 13.3285 18.2988C13.7519 18.2988 13.923 18.47 13.914 18.8933C13.896 19.7761 13.905 20.6678 13.914 21.5505C13.923 21.9559 13.7429 22.118 13.3465 22.118C12.0315 22.109 10.7164 22.109 9.39227 22.109ZM13.0853 19.1455C10.6173 19.1455 8.19428 19.1455 5.76227 19.1455C5.76227 19.8841 5.76227 20.5777 5.76227 21.2713C8.2213 21.2713 10.6443 21.2713 13.0853 21.2713C13.0853 20.5597 13.0853 19.8751 13.0853 19.1455Z"
        fill="#E9AF00"
      />
      <path
        d="M5.72639 23.9631C5.72639 24.6657 5.72639 25.3233 5.72639 25.9808C5.72639 26.1159 5.77143 26.278 5.70837 26.3771C5.60929 26.5393 5.44716 26.6474 5.31204 26.7825C5.18594 26.6474 4.94274 26.5032 4.94274 26.3681C4.90671 25.4223 4.91572 24.4766 4.93373 23.5308C4.94274 23.1525 5.23999 23.1074 5.54624 23.1074C6.83431 23.1074 8.12237 23.1074 9.41945 23.1074C10.7075 23.1074 11.9956 23.1074 13.2927 23.1074C13.8151 23.1074 13.9232 23.2245 13.9322 23.747C13.9322 24.5756 13.9232 25.3953 13.9322 26.224C13.9412 26.6203 13.77 26.8005 13.3737 26.8005C11.5992 26.7915 9.83379 26.7915 8.05932 26.8005C7.77108 26.8005 7.52788 26.7194 7.52788 26.3951C7.52788 26.0619 7.78009 25.9898 8.05932 25.9898C9.54555 25.9898 11.0318 25.9898 12.518 25.9898C12.6982 25.9898 12.8783 25.9898 13.0855 25.9898C13.0855 25.3052 13.0855 24.6567 13.0855 23.9631C10.6535 23.9631 8.22145 23.9631 5.72639 23.9631Z"
        fill="#E9AF00"
      />
      <path
        d="M10.7158 12.6407C11.1211 12.6407 11.4995 12.6407 11.8868 12.6407C12.157 12.6407 12.3822 12.7127 12.3822 13.028C12.3912 13.3433 12.166 13.4243 11.8958 13.4333C11.4454 13.4423 10.995 13.4694 10.5447 13.4694C9.9772 13.4694 9.8511 13.3433 9.8511 12.7848C9.8511 12.0101 9.8511 11.2265 9.8511 10.2897C9.1305 11.0914 8.49097 11.803 7.75236 12.6227C8.06762 12.6227 8.25678 12.6227 8.44593 12.6227C8.7702 12.6227 8.95936 12.7848 8.95936 13.1091C8.95936 14.3521 8.95936 15.5951 8.92333 16.8381C8.91432 16.9823 8.66211 17.1264 8.51799 17.2705C8.39189 17.1174 8.1667 16.9642 8.15769 16.8021C8.12166 15.8293 8.12166 14.8565 8.14869 13.8747C8.15769 13.5054 8.03159 13.3973 7.6803 13.4243C7.4461 13.4423 7.1939 13.4513 6.9597 13.4153C6.78856 13.3883 6.51834 13.3072 6.48231 13.1901C6.43727 13.037 6.50933 12.7758 6.62643 12.6407C7.48213 11.6589 8.36486 10.6951 9.22958 9.72225C9.41874 9.50607 9.58988 9.27188 9.80606 9.09173C9.96819 8.95662 10.2024 8.90257 10.4006 8.8125C10.4816 9.00166 10.6257 9.19982 10.6347 9.38898C10.6618 10.3438 10.6438 11.3076 10.6438 12.2714C10.6798 12.3795 10.6978 12.4785 10.7158 12.6407Z"
        fill="#E9AF00"
      />
      <path
        d="M10.8716 14.613C10.8716 15.0094 10.3222 15.6129 10.079 15.5408C9.91685 15.4958 9.68265 15.1895 9.69166 15.1715C9.93486 14.8743 10.1961 14.586 10.4933 14.3428C10.5294 14.3248 10.7816 14.55 10.8716 14.613Z"
        fill="#E9AF00"
      />
      <path
        d="M5.21407 4.01733C5.21407 2.93643 5.21407 1.91859 5.21407 0.900746C5.21407 0.0360298 5.2501 0 6.12383 0C8.36668 0 10.6095 0 12.8524 0C13.4469 0 13.555 0.0990817 13.555 0.702581C13.564 1.78348 13.555 2.87338 13.555 4.01733C14.3116 4.01733 15.0052 4.01733 15.7078 4.01733C17.4192 4.01733 18.419 5.01715 18.419 6.72857C18.419 14.2948 18.419 21.8701 18.419 29.4364C18.419 31.1478 17.4192 32.1476 15.7078 32.1476C11.4833 32.1476 7.26778 32.1476 3.04328 32.1476C1.33186 32.1476 0.332031 31.1478 0.332031 29.4274C0.332031 21.8611 0.332031 14.2858 0.332031 6.71956C0.332031 5.00815 1.33186 4.00832 3.04328 4.00832C3.75487 4.01733 4.45745 4.01733 5.21407 4.01733ZM17.5363 18.114C17.5363 14.3309 17.5363 10.5477 17.5363 6.7646C17.5363 5.48554 16.9688 4.90906 15.7168 4.90906C11.5013 4.90906 7.27678 4.90906 3.06129 4.90906C1.82727 4.90906 1.24178 5.48554 1.24178 6.71056C1.24178 14.2948 1.24178 21.8791 1.24178 29.4544C1.24178 30.6794 1.82727 31.2559 3.06129 31.2559C7.25877 31.2559 11.4653 31.2559 15.6627 31.2559C16.9688 31.2559 17.5363 30.6974 17.5363 29.4003C17.5363 25.6442 17.5363 21.8791 17.5363 18.114ZM12.6542 0.927768C10.4474 0.927768 8.29462 0.927768 6.13283 0.927768C6.13283 1.97263 6.13283 2.98147 6.13283 3.9903C8.32165 3.9903 10.4744 3.9903 12.6542 3.9903C12.6542 2.95445 12.6542 1.95462 12.6542 0.927768Z"
        fill="#E9AF00"
      />
      <path
        d="M16.4239 18.1409C16.4239 21.87 16.4239 25.6081 16.4239 29.3372C16.4239 30.0578 16.3429 30.1479 15.6223 30.1479C11.4698 30.1479 7.31738 30.1479 3.16494 30.1479C2.44435 30.1479 2.36328 30.0668 2.36328 29.3372C2.36328 21.834 2.36328 14.3398 2.36328 6.83655C2.36328 6.10695 2.44435 6.02588 3.16494 6.02588C7.31738 6.02588 11.4698 6.02588 15.6223 6.02588C16.3429 6.02588 16.4239 6.10695 16.4239 6.83655C16.4239 10.6017 16.4239 14.3758 16.4239 18.1409ZM15.5142 6.95365C11.4068 6.95365 7.34441 6.95365 3.29105 6.95365C3.29105 14.3938 3.29105 21.8069 3.29105 29.2111C7.38044 29.2111 11.4428 29.2111 15.5142 29.2111C15.5142 21.7889 15.5142 14.3938 15.5142 6.95365Z"
        fill="#E9AF00"
      />
    </svg>
  );
}
