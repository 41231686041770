import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../components/Loader";

import { Container, Card, CardBody, Row, Col, Button, Toast, Spinner } from "reactstrap";

import "./createColaboradores.css";
import Breadcrumb from "../../components/Breadcrumb";
import { mask } from "../../utils";
import {
  validaCpfCnpj,
  emailValidation,
  validTel,
  validCel,
  validCep,
} from "./functions";
import { consultaCep } from "../../api/order";
import { apiClient2 } from "../../api/apiCore2";
import { useParams } from "react-router-dom";
import { InputValidation } from "./input";
import { toast } from "react-toastify";
import { createCollaborator, updateCollaborator } from "../../store/actions";
import { useColors } from "../../hooks/useColors";

const CreateColaboradores = ({ history }) => {

  const dispatch = useDispatch();

  const { colorDefault } = useColors();

  const { loading, creatingOrUpdateingError } = useSelector(state => ({
    loading: state.Collaborator.loading,
    creatingOrUpdateingError: state.Collaborator.creatingOrUpdateingError
  }));

  const [erros, setErros] = useState({
    cpfcnpj: "",
    nome: "",
    email: "",
    telefone_ddd: "",
    telefone: "",
    celular_ddd: "",
    celular: "",
    cep: "",
    endereco: "",
    endereco_numero: "",
    bairro: "",
    cidade: "",
    estado: "",
  });
  const [data, setData] = useState({
    cpfcnpj: "",
    nome: "",
    email: "",
    telefone_ddd: "",
    telefone: "",
    celular_ddd: "",
    celular: "",
    cep: "",
    endereco: "",
    endereco_numero: "",
    bairro: "",
    cidade: "",
    estado: "",
    tipo: "F"
  });
  const [loadingCep, setLoadingCep] = useState(false);
  const { id } = useParams();

  const [loadingDataCollaborator, setLoadingDataCollaborator] = useState(false);

  const getCollaborators = async () => {
    const baseUrl = `/vendedores/${id}`;

    setLoadingDataCollaborator(true);

    const resp = await apiClient2.get(baseUrl);

    const data = resp.user;
    setData({
      cpfcnpj: mask(data?.CPFCNPJ?.replace(/( )+/g, " ")),
      nome: data.NOME.replace(/( )+/g, " "),
      email: data.EMAIL.trim(),
      telefone_ddd: data.DDD_TEL.replace(/( )+/g, " "),
      telefone: validTel(data.TELEFONE.replace(/( )+/g, " ")),
      celular_ddd: data.DDD_CEL.replace(/( )+/g, " "),
      celular: validTel(data.CELULAR.replace(/( )+/g, " ")),
      cep: validCep(data.CEP.replace(/( )+/g, " ")),
      endereco: data.ENDERECO.replace(/( )+/g, " "),
      endereco_numero: data.ENDNUM.replace(/( )+/g, " "),
      bairro: data.BAIRRO.replace(/( )+/g, " "),
      cidade: data.CIDADE.replace(/( )+/g, " "),
      estado: data.ESTADO.replace(/( )+/g, " "),
      supervisor_codigo: data.CODIGO_SUP.replace(/( )+/g, " "),
      BLQCLUBE: data.A3_XBLQCLU.replace(/( )+/g, " "),
      BLQPEDIDO: data.A3_XBLQPED.replace(/( )+/g, " "),
      BLQEVENTOS: data.A3_XBLQEVE.replace(/( )+/g, " "),
      tipo: "F"
    });

    setLoadingDataCollaborator(false);
  };

  useEffect(() => {
    if (id) {
      getCollaborators();
    }
  }, [id]);

  const searchCep = value => {
    var formatCep = validCep(value);

    if (formatCep.length == 9) {
      setLoadingCep(true);
      getDataCep(formatCep).then(resp => {
        setData({
          ...data,
          cep: formatCep,
          endereco: resp?.address.address,
          bairro: resp?.address.district,
          cidade: resp?.address.city,
          estado: resp?.address.state,
        });
        setErros({
          ...erros,
          cep: formatCep && "",
          endereco: resp?.address.address && "",
          bairro: resp?.address.district && "",
          cidade: resp?.address.city && "",
          estado: resp?.address.state && "",
        });
        setLoadingCep(false);
      });
    } else {
      setErros({
        ...erros,
        cep: "Digite um cep válido",
      });
      setData({
        ...data,
        cep: formatCep,
      });
      setLoadingCep(false);
    }
  };

  const getDataCep = async formatCep => {
    var resultCep = await consultaCep(formatCep);
    return resultCep;
  };

  const submit = async () => {
    for (var erro in erros) {
      if (erros[erro] != "") {
        return toast.error("Preencha todos os campos corretamente!", {
          theme: "colored",
        });
      }
    }

    for (var datas in data) {
      if (data[datas] == "") {
        setErros({
          ...erros,
          [datas]: "Campo obrigatório",
        });
        document.getElementById(datas).focus();
        return toast.error("Preencha todos os campos corretamente!", {
          theme: "colored",
        });
      }
    }

    if (id) {
      dispatch(updateCollaborator(id, data, history));
    } else {
      dispatch(createCollaborator(data, history));
    }
  };

  useEffect(() => {
    if (creatingOrUpdateingError) {
      toast.error(creatingOrUpdateingError);
    }
  }, [creatingOrUpdateingError]);

  return (
    <React.Fragment>
      <div className="page-content createColaboradores">
        <Container>
          <h3 className="titulo-colaboradores">Colaborador</h3>
          <Breadcrumb
            title="Página Inicial"
            breadcrumbItem={id ? "Editar colaborador" : "Cadastrar colaborador"}
          />

          <Card>
            {(loadingDataCollaborator || loading) && (<Loader />)}
            <CardBody>
              <div className="englob-h4-icone">
                <h4 className="titulo-card">
                  <i className="ph-identification-card icone-funcionario"></i>
                  Funcionário
                </h4>
              </div>

              <div className="engloba-card-formulario">
                <p className="subtitulos-tabela">CPF</p>
                <InputValidation
                  icon={<i className="ph-bank"> </i>}
                  placeholder="CPF"
                  setErros={setErros}
                  erros={erros}
                  name="cpfcnpj"
                  blurError={e => validaCpfCnpj(e.target.value)}
                  setData={setData}
                  data={data}
                  onChangeData={e => mask(e.target.value)}
                  maxLength={14}
                />
                <br />
                <p className="subtitulos-tabela">Nome completo</p>
                <InputValidation
                  icon={<i className="ph-user"> </i>}
                  placeholder="Nome"
                  setErros={setErros}
                  erros={erros}
                  name="nome"
                  blurError={e => e.target.value == "" && "Campo obrigatório"}
                  setData={setData}
                  data={data}
                  onChangeData={e => e.target.value}
                />
                <br />
                <p className="subtitulos-tabela">E-mail</p>
                <InputValidation
                  icon={<i className="ph-envelope-simple"> </i>}
                  placeholder="E-mail"
                  setErros={setErros}
                  erros={erros}
                  name="email"
                  blurError={e => emailValidation(e.target.value)}
                  setData={setData}
                  data={data}
                  onChangeData={e => e.target.value}
                />
                <br />
                <p className="subtitulos-tabela">Telefone</p>
                <Row className="engloba-ddd-telefone">
                  <Col className="engloba-ddd" md={2}>
                    <InputValidation
                      icon={<i className="far fa-dot-circle"></i>}
                      placeholder="DDD"
                      setErros={setErros}
                      erros={erros}
                      name="telefone_ddd"
                      maxLength={3}
                      blurError={e =>
                        e.target.value == "" && "Campo obrigatório"
                      }
                      setData={setData}
                      data={data}
                      onChangeData={e => e.target.value}
                    />
                  </Col>
                  <Col className="engloba-telefone" md={10}>
                    <InputValidation
                      icon={<i className="ph-phone"> </i>}
                      placeholder="Telefone"
                      setErros={setErros}
                      erros={erros}
                      name="telefone"
                      blurError={e =>
                        e.target.value == "" && "Campo obrigatório"
                      }
                      setData={setData}
                      data={data}
                      onChangeData={e => validCel(e.target.value)}
                      maxLength={10}
                    />
                  </Col>
                </Row>
                <br />
                <p className="subtitulos-tabela">Celular</p>
                <Row className="engloba-ddd-celular">
                  <Col className="engloba-ddd" md={2}>
                    {" "}
                    <InputValidation
                      icon={<i className="far fa-dot-circle"></i>}
                      placeholder="DDD"
                      setErros={setErros}
                      erros={erros}
                      name="celular_ddd"
                      blurError={e =>
                        e.target.value == "" && "Campo obrigatório"
                      }
                      setData={setData}
                      data={data}
                      onChangeData={e => e.target.value}
                      maxLength={3}
                    />
                  </Col>
                  <Col className="engloba-celular" md={10}>
                    <InputValidation
                      icon={<i className="ph-phone"> </i>}
                      placeholder="Celular"
                      setErros={setErros}
                      erros={erros}
                      name="celular"
                      blurError={e =>
                        e.target.value == "" && "Campo obrigatório"
                      }
                      setData={setData}
                      data={data}
                      onChangeData={e => validCel(e.target.value)}
                      maxLength={10}
                    />
                  </Col>
                </Row>

                {id && (
                  <>
                    <p className="subtitulos-tabela">Supervisor</p>
                    <InputValidation
                      icon={<i className="ph-identification-card-bold"></i>}
                      placeholder="Supervisor"
                      disabled
                      setErros={setErros}
                      erros={erros}
                      name="supervisor_codigo"
                      blurError={e =>
                        e.target.value == "" && "Campo obrigatório"
                      }
                      setData={setData}
                      data={data}
                      onChangeData={e => e.target.value}
                    />
                  </>
                )}
                <br />
                <h4 className="titulo-local">Localização</h4>
                <p className="subtitulos-tabela">CEP</p>
                <InputValidation
                  icon={<i className="far fa-dot-circle"></i>}
                  placeholder="CEP"
                  setErros={setErros}
                  erros={erros}
                  name="cep"
                  blurError={e => e.target.value == "" && "Campo obrigatório"}
                  setData={setData}
                  data={data}
                  onChangeData={e => searchCep(e.target.value)}
                  disabled={loadingCep}
                />
                <br />
                <p className="subtitulos-tabela">Endereço</p>
                <InputValidation
                  icon={<i className="ph-map-pin"> </i>}
                  placeholder="Endereço"
                  setErros={setErros}
                  erros={erros}
                  name="endereco"
                  blurError={e => e.target.value == "" && "Campo obrigatório"}
                  setData={setData}
                  data={data}
                  onChangeData={e => e.target.value}
                  valueData={loadingCep ? "..." : data.endereco}
                  disabled={loadingCep}
                />
                <br />
                <p className="subtitulos-tabela">Número</p>
                <InputValidation
                  icon={<i className="fas fa-sort-numeric-down-alt"></i>}
                  placeholder="Número"
                  setErros={setErros}
                  erros={erros}
                  name="endereco_numero"
                  blurError={e => e.target.value == "" && "Campo obrigatório"}
                  setData={setData}
                  data={data}
                  onChangeData={e => e.target.value}
                  disabled={loadingCep}
                />
                <br />
                <p className="subtitulos-tabela">Bairro</p>
                <InputValidation
                  icon={<i className="fas fa-map-signs"></i>}
                  placeholder="Bairro"
                  setErros={setErros}
                  erros={erros}
                  name="bairro"
                  blurError={e => e.target.value == "" && "Campo obrigatório"}
                  setData={setData}
                  data={data}
                  onChangeData={e => e.target.value}
                  valueData={loadingCep ? "..." : data.bairro}
                  disabled={loadingCep}
                />
                <br />
                <p className="subtitulos-tabela">Cidade</p>
                <InputValidation
                  icon={<i className="ph-map-trifold"> </i>}
                  placeholder="Cidade"
                  setErros={setErros}
                  erros={erros}
                  name="cidade"
                  blurError={e => e.target.value == "" && "Campo obrigatório"}
                  setData={setData}
                  data={data}
                  onChangeData={e => e.target.value}
                  valueData={loadingCep ? "..." : data.cidade}
                  disabled
                />
                <br />
                <p className="subtitulos-tabela">Estado</p>
                <InputValidation
                  icon={<i className="ph-map-trifold"> </i>}
                  placeholder="Estado"
                  setErros={setErros}
                  erros={erros}
                  name="estado"
                  blurError={e => e.target.value == "" && "Campo obrigatório"}
                  setData={setData}
                  data={data}
                  onChangeData={e => e.target.value}
                  valueData={loadingCep ? "..." : data.estado}
                  disabled
                />
                {id && (
                  <>
                    <p className="subtitulos-tabela">Bloqueio no clube</p>
                    <select
                      onChange={e =>
                        setData({ ...data, BLQCLUBE: e.target.value })
                      }
                      style={{ maxWidth: 150 }}
                      className="form-select"
                      value={data.BLQCLUBE}
                    >
                      <option selected value="N">
                        Não
                      </option>
                      <option value="S">Sim</option>
                    </select>
                    <br />
                    <p className="subtitulos-tabela">Bloqueio pedidos</p>
                    <select
                      onChange={e =>
                        setData({ ...data, BLQPEDIDO: e.target.value })
                      }
                      style={{ maxWidth: 150 }}
                      className="form-select"
                      value={data.BLQPEDIDO}
                    >
                      <option selected value="N">
                        Não
                      </option>
                      <option value="S">Sim</option>
                    </select>
                    <br />
                    <p className="subtitulos-tabela">Bloqueio eventos</p>
                    <select
                      onChange={e =>
                        setData({ ...data, BLQEVENTOS: e.target.value })
                      }
                      style={{ maxWidth: 150 }}
                      className="form-select"
                      value={data.BLQEVENTOS}
                    >
                      <option selected value="N">
                        Não
                      </option>
                      <option value="S">Sim</option>
                    </select>
                    <br />
                  </>
                )}
              </div>
              <Button
                onClick={submit}
                className="botao-salvar"
                style={{ backgroundColor: colorDefault }}
              >
                {loading ? (
                  <i className="bx bx-loader bx-spin font-size-16 align-middle" />
                ) : (
                  "Salvar"
                )}
              </Button>
              <hr />
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default CreateColaboradores;
