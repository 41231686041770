import React from "react";
import {
  Alert,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Row,
} from "reactstrap";
import Breadcrumb from "../../components/Breadcrumb";
import {
  arrayDatasToContact,
  arrayDatasToContactSolar,
} from "./ContactDatas/ContactDatas";
import { FormDefault } from "../../components/forms/FormDefault";
import { useSolar } from "../../hooks";
import { useDataLayer } from "../../hooks/DataLayerHooks";

//import images
// import whatsappText from "../../assets/images/brands/whatsappText.jpg";
// import whatsappNumber from "../../assets/images/brands/whatsapp-number.jpg";

const ContactUs = () => {
  const isSolar = useSolar();
  const { pushToDataLayer } = useDataLayer();
  const componentPropsToForm = () => {
    return (
      <Alert color="info">
        Parceiro, este formulário foi criado pensando em você!
        <hr />
        Caso tiver alguma dúvida, ideia ou sugestão, por favor preencha os dados abaixo e retornaremos em breve.
      </Alert>
    );
  };

  const onValidSubmit = () => {
    pushToDataLayer(
      'formulario_contato'
    );
  }

  return (
    <>
      <div className="page-content">
        <Container>
          {/* Render Breadcrumb */}
          <Breadcrumb title="Página Inicial" breadcrumbItem="Canal Direto" />
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <CardTitle className="mb-4">Formulário de contato</CardTitle>
                  <hr />
                  <Row>
                    <Col xl={8}>
                      <FormDefault 
                      componentProps={componentPropsToForm()} 
                      onValidSubmit={onValidSubmit}
                      />
                    </Col>
                    <Col md={4}>
                      <Row
                        className="justify-content-center"
                        style={{ position: "stick", top: 0 }}
                      >
                        <Col md={7}>
                          <div className="mb-0 card-title text-center">
                            <p
                              style={{ fontSize: 18, lineHeight: 1, color: "#0F172A" }}
                              className="f-w-900"
                            >
                              Caso prefira falar por WhatsApp, clique abaixo:
                            </p>
                            <div>
                              {isSolar
                                ? arrayDatasToContactSolar.map(
                                    (item, index) => (
                                      <div key={index}>
                                        <h6
                                          style={{ fontSize: 16, color: "#BBCAD2" }}
                                          className="f-w-900 mt-4"
                                        >
                                          {item.name}
                                        </h6>
                                        <a
                                          className="color-0167af"
                                          href={`tel:+${item.tel.replace(
                                            /[^\d.]/g,
                                            ""
                                          )}`}
                                        >
                                          <i
                                            className="fab fa-whatsapp"
                                            style={{
                                              color: isSolar
                                                ? "#FF601A"
                                                : "#2D567B",
                                              fontSize: 20,
                                            }}
                                          ></i>{" "}
                                          {item.tel}
                                        </a>
                                      </div>
                                    )
                                  )
                                : arrayDatasToContact.map((item, index) => (
                                    <div key={index}>
                                      <h6
                                        style={{ fontSize: 16, color: "#2D567B" }}
                                        className="f-w-900 mt-4"
                                      >
                                        {item.name}
                                      </h6>
                                      <a
                                        className="btn"
                                        style={{backgroundColor: "#BBCAD2", color: "#2D567B"}}
                                        href={`tel:+${item.tel.replace(
                                          /[^\d.]/g,
                                          ""
                                        )}`}
                                      >
                                        <i
                                          className="fab fa-whatsapp"
                                          style={{
                                            color: isSolar
                                              ? "#FF601A"
                                              : "#2D567B",
                                            fontSize: 20,
                                          }}
                                        ></i>{" "}
                                        {item.tel}
                                      </a>
                                    </div>
                                  ))}
                            </div>
                          </div>

                          {/* <img
                            src={whatsappText}
                            className="img-fluid card-img"
                            alt=""
                          />
                          <img
                            src={whatsappNumber}
                            className="img-fluid card-img"
                            alt=""
                          /> */}
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default ContactUs;
