import { call, put, takeEvery, all, fork } from "redux-saga/effects";
// Crypto Redux States
import { OrderListActionTypes } from "./actionTypes";
import { orderListApiSuccess, orderListApiFail } from "./action";
import { getOrdersList as getOrdersListApi } from "../../api/index";

function* getOrders({ payload: filters }) {
    try {
        const response = yield call(getOrdersListApi, filters);

        if (response) {
            yield put(
                orderListApiSuccess(OrderListActionTypes.GET_ORDER_LIST, response)
            );
        }


    } catch (error) {
        yield put(orderListApiFail(OrderListActionTypes.GET_ORDER_LIST, error));
    }
}


export function* watchGetOrders() {
    yield takeEvery(OrderListActionTypes.GET_ORDER_LIST, getOrders);
}

function* orderListSaga() {
    yield all([
        fork(watchGetOrders)
    ]);
}

export default orderListSaga;