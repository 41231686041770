// import { apiClient } from "./apiCore";
import { apiClient2 } from "./apiCore2";

const getDownloadDetails = filters => {
  const baseUrl = "/downloads";
  return apiClient2.get(`${baseUrl}`, filters);
};

const getCategoriesOptions = param => {
  const baseUrl = "/categories/get";
  return apiClient2.create(`${baseUrl}`, param);
};

export { getDownloadDetails, getCategoriesOptions };
