import {
  CardDefault,
  React,
  IconTree,
  DataWithPictures,
  IconCo2,
  IconCar,
  IconTrees,
} from "../../helpers/importer.helper";

export default function SectionFour({ userData }) {
  return (
    <section id="section-4" className="mt-4 section">
      <CardDefault
        checkbox={false}
        icon={<IconTree />}
        title="Potencial Impacto Ambiental"
      >
        <div className="w-100">
          <p>
            Impacto ambiental anual estimado do tamanho recomendado da
            instalação solar.
          </p>
          <div className="d-flex j-c-center">
            <div className="w-75">
              <div className="d-flex flex-wrap father-data-with-pictures">
                <DataWithPictures
                  stringSize={24}
                  stringColor="#696969"
                  icon={<IconCo2 />}
                  value={userData?.calculations?.carbon || "00"}
                  title="Dióxido de carbono"
                  description="(toneladas métricas)"
                />
                <DataWithPictures
                  stringSize={28}
                  stringWeight={600}
                  stringColor="#009FB4"
                  icon={<IconCar colorStroke="#009FB4" />}
                  value={userData?.calculations?.automobiles || "00"}
                  title="Automóveis"
                  description="(1 por ano retirado da estrada)"
                />
                <DataWithPictures
                  stringSize={28}
                  stringColor="#02BB41"
                  stringWeight={600}
                  icon={<IconTrees />}
                  value={userData?.calculations?.seedlings_planted || "00"}
                  title="Mudas de árvores"
                  description="(cresceram em 10 anos)"
                />
              </div>
            </div>
          </div>
        </div>
      </CardDefault>
    </section>
  );
}
