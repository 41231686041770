import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Alert, Col } from "reactstrap";
import { apiClient2 } from "../../../api/apiCore2";

import ModalClosures from "./ModalClosures";

//import Components
import Breadcrumb from "../../../components/Breadcrumb";
import Closing from "./Closing";
import Filter from "./Filter";
import { useQuery } from "react-query";

const RTClosing = () => {
  const [noticemodal, setNoticemodalmodal] = useState(false);
  const [closures, setClosures] = useState([]);
  const [loadingFilter, setLoadingFilter] = useState(false);
  const [currentPageIndex, setCurrentPageIndex] = useState(1);

  //FILTER STATES
  const [code, setCode] = useState("");
  const [date, setDate] = useState("");
  const [codeVend, setCodeVend] = useState("");
  const [codeFornec, setCodeFornec] = useState("");

  const getClosures = async () => {
    const validationUrl = code || date || codeVend || codeFornec;

    const baseUrl = !validationUrl
      ? `/fechamentos?page=${currentPageIndex}`
      : `/fechamentos?code=${code}&date=${
          date != "NaN/NaN/NaN" ? date : ""
        }&code_vend=${codeVend}&code_fornec=${codeFornec}`;

    const resp = await apiClient2.get(baseUrl);

    setLoadingFilter(false);
    return resp;
  };

  const { data, isLoading, refetch } = useQuery(
    ["fechamentos", currentPageIndex],
    () => getClosures(),
    {
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    if (data) {
      setClosures(data);
    }
  }, [data]);

  return (
    <>
      <div className="page-content">
        <Container>
          {/* Render Breadcrumb */}
          <Breadcrumb
            title="Página Inicial"
            breadcrumbItem="Fechamentos de RT"
          />
          <Row>
            <Col md={12}>
              <Alert color="warning">
                Temos um aviso muito importante sobre o envio de Nota Fiscal da
                sua RT
                <Link
                  to="#"
                  className="ms-2"
                  onClick={() => setNoticemodalmodal(!noticemodal)}
                >
                  Clique para visualizar.
                </Link>
              </Alert>
            </Col>
            <Col md={6}>
              {/* import Filter Card page */}
              <Filter
                setCode={setCode}
                setCodeVend={setCodeVend}
                setCodeFornec={setCodeFornec}
                setDate={setDate}
                refetch={refetch}
                setLoadingFilter={setLoadingFilter}
              />
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              {/* Closing List page */}
              <Closing
                closures={closures}
                loading={isLoading}
                setCurrentPageIndex={setCurrentPageIndex}
                currentPageIndex={currentPageIndex}
                loadingFilter={loadingFilter}
              />
            </Col>
          </Row>
        </Container>
      </div>

      {/*  <ModalClosures
        noticemodal={noticemodal}
        setNoticemodalmodal={setNoticemodalmodal}
      /> */}
    </>
  );
};

export default RTClosing;
