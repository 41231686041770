import React, { useEffect, useState } from "react";
import { Col, Label, Row } from "reactstrap";

const LeadShow = ({ leadInfo }) => {
  const [leadData, setLeadData] = useState({});

  useEffect(() => {
    if (leadInfo?.data) {
      setLeadData(leadInfo.data.lead);
    }
  }, [leadInfo.data]);

  return (
    <>
      <Row>
        <Col md={6}>
          <Label>Nome</Label>
          <div>
            <p>{leadData.name}</p>
          </div>
        </Col>
        <Col md={6}>
          <Label>E-mail</Label>
          <div>
            <p>{leadData.email}</p>
          </div>
        </Col>
      </Row>
      <Row className="mt-4">
        <Col md={6}>
          <Label>Telefone</Label>
          <div>
            <p>{leadData.phone}</p>
          </div>
        </Col>
        <Col md={6}>
          <Label>Endereço</Label>
          <div>
            <p>{leadData.address}</p>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default LeadShow;
