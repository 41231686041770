import { BudgetActionTypes } from "./actionTypes";

export const budgetApiSuccess = (actionType, data) => ({
  type: BudgetActionTypes.API_RESPONSE_SUCCESS,
  payload: { actionType, data },
});

export const budgetApiFail = (actionType, error) => ({
  type: BudgetActionTypes.API_RESPONSE_ERROR,
  payload: { actionType, error },
});

export const listBudgets = filters => ({
  type: BudgetActionTypes.LIST_BUDGETS,
  payload: filters,
});

export const getBudget = (id, codFilial) => ({
  type: BudgetActionTypes.GET_BUDGET,
  payload: { id, codFilial },
});

export const getBudgetClear = (
  actionType = BudgetActionTypes.CLEAR_DATA_ACTIVE
) => ({
  type: BudgetActionTypes.API_RESPONSE_SUCCESS,
  payload: { actionType },
});

export const getDataClientOrderChildren = (actionType, data) => (
  console.log("actionType, data", actionType, data),
  {
    type: BudgetActionTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, data },
  }
);
