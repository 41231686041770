export const GET_FIREBASE_REMOTE_CONFIG = 
  "GET_FIREBASE_REMOTE_CONFIG";

export const GET_FIREBASE_REMOTE_CONFIG_METHOD_LOGIN =
  "GET_FIREBASE_REMOTE_CONFIG_METHOD_LOGIN";

export const GET_FIREBASE_REMOTE_CONFIG_USER_RESTRICT_PAGE =
  "GET_FIREBASE_REMOTE_CONFIG_USER_RESTRICT_PAGE";

export const FIREBASE_REMOTE_CONFIG_SUCCESS =
  "GET_FIREBASE_REMOTE_CONFIG_SUCCESS";

export const GET_FIREBASE_REMOTE_CONFIG_FABRICANTES_INVERSORES =
  "GET_FIREBASE_REMOTE_CONFIG_FABRICANTES_INVERSORES";

  export const GET_FIREBASE_REMOTE_CONFIG_SHARED_RESALES_PERMISSION =
  "GET_FIREBASE_REMOTE_CONFIG_SHARED_RESALES_PERMISSION";