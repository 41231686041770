import React, { useState, useEffect } from "react";
export function Countdown({ dateHours }) {
  function calculateTimeLeft() {
    const difference = +new Date(dateHours) - +new Date();

    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        [Math.floor(difference / (1000 * 60 * 60 * 24)) > 1 ? "dias" : "dia"]:
          Math.floor(difference / (1000 * 60 * 60 * 24)),
        h: Math.floor((difference / (1000 * 60 * 60)) % 24),
        m: Math.floor((difference / 1000 / 60) % 60),
        s: Math.floor((difference / 1000) % 60) || "00",
      };
    }

    return timeLeft;
  }

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const id = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => {
      clearTimeout(id);
    };
  });

  const timerComponents = Object.keys(timeLeft).map((interval, k) => {
    if (!timeLeft[interval]) {
      return <div key={k}></div>;
    }

    return (
      <span
        style={{ background: "#f3565d", padding: "3px", color: "#fff" }}
        key={k}
      >
        {timeLeft[interval]}
        {interval}
      </span>
    );
  });

  return (
    <div>
      {timerComponents.length
        ? "O pagamento desse pedido deve ser efetuado em até  "
        : ""}
      {timerComponents.length ? (
        timerComponents
      ) : (
        <span>Esse pedido expirou e não pode mais ser pago.</span>
      )}
      {timerComponents.length
        ? " ou será cancelado automaticamente pelo sistema"
        : ""}
    </div>
  );
}
