import { AvGroup, AvInput, AvFeedback } from "availity-reactstrap-validation";
import React from "react";
import { Card, CardBody, Col, Row, Label } from "reactstrap";
import "./cadastrarrevenda.css";
import { toast } from "react-toastify";
import { mask, validaCpfCnpj } from "../../../utils/otherUtils";

const DadosPessoais = ({ formData, setFormData }) => {
  const handleBlur = () => {
    if (formData.cpfcnpj) {
      if (validaCpfCnpj(formData.cpfcnpj)) {
        toast.success("CPF/CNPJ válido");
      } else {
        toast.error("CPF/CNPJ inválido");
      }
    }
  };

  return (
    <>
      <Card className="p-2">
        <CardBody className="card-body">
          <h4 className="titulo-card">Dados Pessoais</h4>
          <div className="mx-4">
            <Row className="justify-content-center">
              <Col md={6} className="mt-3">
                <Label>CNPJ / CPF</Label>
                <AvGroup>
                  <AvInput
                    required
                    placeholder="Digite aqui..."
                    maxLength={18}
                    name="cpfcnpj"
                    id="cpfcnpj"
                    onBlur={handleBlur}
                    value={mask(formData.cpfcnpj) ?? ""}
                    className="input-dados"
                    onChange={e =>
                      setFormData({
                        ...formData,
                        cpfcnpj: e.target.value.replace(/[^0-9]+/g, ""),
                      })
                    }
                  />
                  <AvFeedback>Campo obrigatório!</AvFeedback>
                </AvGroup>
                <input
                  id="id_cad"
                  value={formData.codFornecedor ?? ""}
                  name="id_cad"
                  type="hidden"
                />
              </Col>
              <Col md={6} className="mt-3">
                <Label>Nome Fantasia / Nome Reduzido</Label>
                <AvGroup>
                  <AvInput
                    required
                    id="nome_reduz"
                    name="nomereduz"
                    placeholder="Digite aqui..."
                    value={formData.nomeFantasia ?? ""}
                    className="input-dados"
                    onChange={e =>
                      setFormData({ ...formData, nomeFantasia: e.target.value })
                    }
                  />
                  <AvFeedback>Campo obrigatório!</AvFeedback>
                </AvGroup>
              </Col>
            </Row>
            <Row className="justify-content-center">
              <Col md={12} className="mt-3">
                <Label>Razão Social / Nome Completo</Label>
                <AvGroup>
                  <AvInput
                    required
                    placeholder="Digite aqui..."
                    id="nome"
                    name="nomeRevenda"
                    value={formData.nomeRevenda ?? ""}
                    className="input-dados"
                    onChange={e =>
                      setFormData({ ...formData, nomeRevenda: e.target.value })
                    }
                  />
                  <AvFeedback>Campo obrigatório!</AvFeedback>
                </AvGroup>
              </Col>
            </Row>
          </div>
        </CardBody>
      </Card>
    </>
  );
};
export default DadosPessoais;
