import React from "react";
export default function IconTabs(props) {
  const colorFill = props?.colorFill?.replaceAll("#", "") || "none";
  const colorStroke = props?.colorStroke || "#000";

  return (
    <svg
      width="22"
      height="21"
      viewBox="0 0 22 21"
      fill={colorFill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 14L2.85833 7.49876C2.8997 7.35405 2.98455 7.22727 3.1002 7.13738C3.21586 7.04749 3.35611 6.9993 3.5 7.00001H10.5C10.6439 6.9993 10.7841 7.04749 10.8998 7.13738C11.0155 7.22727 11.1003 7.35405 11.1417 7.49876L13 14"
        stroke={colorStroke}
        strokeWidth="1.28351"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13 7.00001H13.75C13.8795 6.9993 14.0057 7.04749 14.1098 7.13738C14.2139 7.22727 14.2903 7.35405 14.3275 7.49876L16 14"
        stroke={colorStroke}
        strokeWidth="1.28351"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17 7.00001H17.75C17.8795 6.9993 18.0057 7.04749 18.1098 7.13738C18.2139 7.22727 18.2903 7.35405 18.3275 7.49876L20 14"
        stroke={colorStroke}
        strokeWidth="1.28351"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1 14H21"
        stroke={colorStroke}
        strokeWidth="1.28351"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
