import { Field } from "formik";
import React from "react";
import "./index.scss";

export const SolarInput = ({
  title,
  name,
  mask,
  errors,
  touched,
  onchange,
  style,
  icon,
}) => {
  const cnpjMask = value => {
    return value
      .replace(/\D+/g, "") // não deixa ser digitado nenhuma letra
      .replace(/(\d{2})(\d)/, "$1.$2") // captura 2 grupos de número o primeiro com 2 digitos e o segundo de com 3 digitos, apos capturar o primeiro grupo ele adiciona um ponto antes do segundo grupo de número
      .replace(/(\d{3})(\d)/, "$1.$2")
      .replace(/(\d{3})(\d)/, "$1/$2") // captura 2 grupos de número o primeiro e o segundo com 3 digitos, separados por /
      .replace(/(\d{4})(\d)/, "$1-$2")
      .replace(/(-\d{2})\d+?$/, "$1"); // captura os dois últimos 2 números, com um - antes dos dois números
  };
  const formatValue = (fieldProps, value) => {
    onchange && onchange(fieldProps, value);

    if (!!mask) {
      switch (mask) {
        case "cpf":
          return value.replace(/[^\d]/g, "").replace(/(\d{5})(\d{3})/, "$1-$2");
          break;
        case "cnpj":
          return cnpjMask(value);
          break;
        case "money":
          var v = value.replace(/\D/g, "");
          v = (v / 100).toFixed(2) + "";
          v = v.replace(".", ",");
          v = v.replace(/(\d)(\d{3})(\d{3}),/g, "$1.$2.$3,");
          v = v.replace(/(\d)(\d{3}),/g, "$1.$2,");
          return (value = v);
          break;
        case "phone":
          var valor = value;
          var retorno = valor.replace(/\D/g, "");
          retorno = retorno.replace(/^0/, "");
          if (retorno.length > 10) {
            retorno = retorno.replace(
              /^(\d\d)(\d{1})(\d{4})(\d{4}).*/,
              "($1) $2 $3-$4"
            );
          } else if (retorno.length > 5) {
            retorno = retorno.replace(
              /^(\d\d)(\d{4})(\d{0,4}).*/,
              "($1) $2-$3"
            );
          } else if (retorno.length > 2) {
            retorno = retorno.replace(/^(\d\d)(\d{0,5})/, "($1) $2");
          } else {
            if (retorno.length != 0) {
              retorno = retorno.replace(/^(\d*)/, "($1");
            }
          }
          return retorno;
          break;
        default:
          console.log("nenhuma mascara econtrada!");
      }
    } else {
      return value;
    }
  };

  return (
    <div>
      <div className="d-flex">
        <label
          htmlFor={title?.replaceAll(" ", "-")}
          className="solar-input-title"
        >
          {title}
        </label>
        <i>{icon}</i>
      </div>
      <Field name={name}>
        {fieldProps => (
          <input
            style={style}
            className={`input ${errors[name] && touched[name] && "erros"}`}
            {...fieldProps.field}
            onChange={event => {
              fieldProps.field.onChange(event.target.name)(
                formatValue(fieldProps, event.target.value)
              );
            }}
          />
        )}
      </Field>
      {errors[name] && touched[name] ? (
        <div className="erros">{errors[name]}</div>
      ) : null}
    </div>
  );
};
