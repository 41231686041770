import React from 'react';
import { Container } from 'reactstrap';
import "./googleAds.css";


const GoogleAds = () => {
    return (
        <React.Fragment>
            <div className="page-content google-ads">
                <Container>
                <iframe src="https://data.resultz.io/html/index.html" title="Flamingo Marketing" className='iframe-google' />    
                </Container>
            </div>
        </React.Fragment >
    );
};

export default GoogleAds;