//import images
import products1 from "../../assets/images/product/caneca-1.png";
import products2 from "../../assets/images/product/camiseta-escura-1.png";
import products3 from "../../assets/images/product/caneca-com Tampa-1.png";

const benefitsProducts = [
  {
    id: 1,
    name: "Canecas",
    image: products1,
    isDisabled: false,
  },
  {
    id: 2,
    name: "Camisetas",
    image: products2,
    isDisabled: true,
  },
  {
    id: 3,
    name: "Copo Térmico",
    image: products3,
    isDisabled: true,
  },
];

export { benefitsProducts };
