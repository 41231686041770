import React from "react";
import { Card, CardBody, Container, Button, Table } from "reactstrap";
// import { useSelector, useDispatch } from "react-redux";

//import Components
// import FilterResults from "./FilterResults";
import Breadcrumb from "../../components/Breadcrumb";
// import Downloads from "./Downloads";

// actions
// import { getDownloadDetails } from "../../store/actions";
// import { Car } from "phosphor-react";

const Download = () => {
  // const dispatch = useDispatch();
  // const { downloadDetails, getDownloadDetailsLoading, categoriesOptions } =
  //   useSelector(state => ({
  //     downloadDetails: state.Download.downloadDetails,
  //     getDownloadDetailsLoading: state.Download.getDownloadDetailsLoading,
  //     categoriesOptions: state.Download.categoriesOptions,
  //   }));

  // /*
  //   get download details
  //   */
  // useEffect(() => {
  //   dispatch(getDownloadDetails());
  // }, [dispatch]);

  /*
    filters
    */
  // const onApplyFilters = filters => {
  //   dispatch(getDownloadDetails({ ...filters }));
  // };
  return (
    <React.Fragment>
      <div className="page-content">
        <Container>
          {/* Render Breadcrumb */}
          <Breadcrumb title="Transferências" breadcrumbItem="Download" />

          <div>
            <Card>
              <CardBody>
                <h6>Filtrar Resultados</h6>
                <select name="select">
                  <option value="Selecione"> Selecione</option>
                  <option value="valor3">Valor 3</option>
                </select>


                <select name="select">
                  <option value="Selecione">Selecione</option>
                  <option value="valor2">Valor 2</option>
                  <option value="valor3">Valor 3</option>
                </select>
                <select name="select">
                  <option value="Selecione">Selecione</option>
                  <option value="valor2">Valor 2</option>
                  <option value="valor3">Valor 3</option>
                </select>
                <Button>
                  Button
                  <i className="ph-funnel"></i>
                </Button>
              </CardBody>
            </Card>
            <h6>Downloads de Catálogo de Vendas</h6>

            <Table
              borderless
              hover
            >
              <thead>
                <tr>
                  <th>Descrição</th>
                  <th></th>
                  <th></th>
                  <th>Data Alteração</th>
                  <th>Download</th>
                </tr>
              </thead>
            </Table>




            <Card>
              <CardBody>
              </CardBody>
            </Card>
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Download;
