import React, { useState } from "react";
import {
  Card,
  CardBody,
  Col,
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import "./UserProfile.css";
import classnames from "classnames";
import Breadcrumb from "../../components/Breadcrumb";
import RegisterProfile from "./register-profile/RegisterProfile";
const UserProfile = () => {
  const [activeTab, setactiveTab] = useState("1");
  const [verticalActiveTabWithIcon, setverticalActiveTabWithIcon] =
    useState("1");

  const toggleVerticalIcon = tab => {
    if (verticalActiveTabWithIcon !== tab) {
      setverticalActiveTabWithIcon(tab);
    }
  };
  const toggle = tab => {
    if (activeTab !== tab) {
      setactiveTab(tab);
    }
  };
  return (
    <React.Fragment>
      <div className="page-content userProfile">
        <Container>
          {/* Render Breadcrumb */}
          <Breadcrumb title="Minible" breadcrumbItem="Perfil do Usuário" />
          <Row>
            <Col xl={12}>
              <Card>
                <CardBody
                  style={{
                    paddingInline: "1.25rem",
                    paddingBottom: "4.25rem",
                    paddingTop: "1.25rem",
                  }}
                >
                  <Nav tabs>
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          active: activeTab === "1",
                        })}
                        onClick={() => {
                          toggle("1");
                        }}
                      >
                        Cadastro
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          active: activeTab === "2",
                        })}
                        onClick={() => {
                          toggle("2");
                        }}
                      >
                        Ajuda
                      </NavLink>
                    </NavItem>
                  </Nav>
                  <TabContent activeTab={activeTab} className="p-3 text-muted">
                    <TabPane tabId="1">
                      <Row>
                        <Col sm="12">
                          <RegisterProfile />
                        </Col>
                      </Row>
                    </TabPane>
                    <TabPane tabId="2">
                      <Row>
                        <Col lg={12}>
                          <Row>
                            <Col md="3">
                              <Nav className="flex-column vertical-icon">
                                <NavItem>
                                  <NavLink
                                    style={{ cursor: "pointer" }}
                                    className={classnames({
                                      "mb-2": true,
                                      active:
                                        verticalActiveTabWithIcon === "01",
                                    })}
                                    onClick={() => {
                                      toggleVerticalIcon("01");
                                    }}
                                  >
                                    <i className="ph-notebook"></i> Consumidor
                                    3.0
                                  </NavLink>
                                </NavItem>
                                <NavItem>
                                  <NavLink
                                    style={{ cursor: "pointer" }}
                                    className={classnames({
                                      "mb-2": true,
                                      active:
                                        verticalActiveTabWithIcon === "02",
                                    })}
                                    onClick={() => {
                                      toggleVerticalIcon("02");
                                    }}
                                  >
                                    <i className="ph-notebook"></i> Eventos
                                  </NavLink>
                                </NavItem>
                                <NavItem>
                                  <NavLink
                                    style={{ cursor: "pointer" }}
                                    className={classnames({
                                      "mb-2": true,
                                      active:
                                        verticalActiveTabWithIcon === "03",
                                    })}
                                    onClick={() => {
                                      toggleVerticalIcon("03");
                                    }}
                                  >
                                    <i className="ph-notebook"></i>Garantia
                                  </NavLink>
                                </NavItem>
                                <NavItem>
                                  <NavLink
                                    style={{ cursor: "pointer" }}
                                    className={classnames({
                                      "mb-2": true,
                                      active:
                                        verticalActiveTabWithIcon === "04",
                                    })}
                                    onClick={() => {
                                      toggleVerticalIcon("04");
                                    }}
                                  >
                                    <i className="ph-notebook"></i> Pagamento de
                                    RT
                                  </NavLink>
                                </NavItem>
                                <NavItem>
                                  <NavLink
                                    style={{ cursor: "pointer" }}
                                    className={classnames({
                                      "mb-2": true,
                                      active:
                                        verticalActiveTabWithIcon === "05",
                                    })}
                                    onClick={() => {
                                      toggleVerticalIcon("05");
                                    }}
                                  >
                                    <i className="ph-notebook"></i> Pedido
                                    Online
                                  </NavLink>
                                </NavItem>
                                <NavItem>
                                  <NavLink
                                    style={{ cursor: "pointer" }}
                                    className={classnames({
                                      active:
                                        verticalActiveTabWithIcon === "06",
                                    })}
                                    onClick={() => {
                                      toggleVerticalIcon("06");
                                    }}
                                  >
                                    <i className="ph-notebook"></i> Pesquisas
                                  </NavLink>
                                </NavItem>
                                <NavItem>
                                  <NavLink
                                    style={{ cursor: "pointer" }}
                                    className={classnames({
                                      active:
                                        verticalActiveTabWithIcon === "07",
                                    })}
                                    onClick={() => {
                                      toggleVerticalIcon("07");
                                    }}
                                  >
                                    <i className="ph-notebook"></i> Procedimento
                                    A.dias
                                  </NavLink>
                                </NavItem>
                                <NavItem>
                                  <NavLink
                                    style={{ cursor: "pointer" }}
                                    className={classnames({
                                      active:
                                        verticalActiveTabWithIcon === "08",
                                    })}
                                    onClick={() => {
                                      toggleVerticalIcon("08");
                                    }}
                                  >
                                    <i className="ph-notebook"></i> Suporte
                                    Técnico
                                  </NavLink>
                                </NavItem>
                              </Nav>
                            </Col>
                            <Col md="9">
                              <TabContent
                                activeTab={verticalActiveTabWithIcon}
                                className="text-muted mt-4 mt-md-0"
                              >
                                <TabPane tabId="01"></TabPane>
                                <TabPane tabId="02"></TabPane>
                                <TabPane tabId="03"></TabPane>
                                <TabPane tabId="04"></TabPane>
                                <TabPane tabId="05"></TabPane>
                                <TabPane tabId="06"></TabPane>
                              </TabContent>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </TabPane>
                  </TabContent>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};
export default UserProfile;
