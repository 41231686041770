import redesSociais from "../../../assets/images/vendedores/redesSociais.png";
import arteWeb from "../../../assets/images/vendedores/artesWeb.png";
import lg from "../../../assets/images/vendedores/lg.png";
import samsung from "../../../assets/images/vendedores/samsung.png";
import gree from "../../../assets/images/vendedores/gree.png";
import daikin from "../../../assets/images/vendedores/daikin.png";
import fujitsu from "../../../assets/images/vendedores/fujitsu.png";
import trane from "../../../assets/images/vendedores/trane.png";
import elgin from "../../../assets/images/vendedores/elgin.png";
import panasonic from "../../../assets/images/vendedores/panasonic.png";
import electrolux from "../../../assets/images/vendedores/electrolux.png";

const imageGalleryData = [
  { img: redesSociais, label: "REDES SOCIAIS", link: "/" },
  { img: arteWeb, label: "ARTE WEB", link: "/" },
  { img: lg, label: "LG", link: "/" },
  { img: samsung, label: "SAMSUNG", link: "/" },
  { img: gree, label: "GREE", link: "/" },
  { img: daikin, label: "DAIKIN", link: "/" },
  { img: fujitsu, label: "FUJITSU", link: "/" },
  { img: trane, label: "TRANE", link: "/" },
  { img: elgin, label: "ELGIN", link: "/" },
  { img: panasonic, label: "PANASONIC", link: "/" },
  { img: electrolux, label: "ELECTROLUX", link: "/" },
];

//alterar dados para solar
const imageGalleryDataSolar = [
  { img: redesSociais, label: "REDES SOCIAIS", link: "/" },
  { img: arteWeb, label: "ARTE WEB", link: "/" },
  { img: lg, label: "LG", link: "/" },
  { img: samsung, label: "SAMSUNG", link: "/" },
  { img: gree, label: "GREE", link: "/" },
  { img: daikin, label: "DAIKIN", link: "/" },
  { img: fujitsu, label: "FUJITSU", link: "/" },
  { img: trane, label: "TRANE", link: "/" },
  { img: elgin, label: "ELGIN", link: "/" },
  { img: panasonic, label: "PANASONIC", link: "/" },
  { img: electrolux, label: "ELECTROLUX", link: "/" },
];

export { imageGalleryData, imageGalleryDataSolar };
