import React from "react";
import { Input, InputGroup, InputGroupText } from "reactstrap";

export const InputValidation = ({
  icon,
  placeholder,
  setErros,
  erros,
  name,
  blurError,
  setData,
  data,
  onChangeData,
  maxLength,
  valueData,
  disabled,
}) => {
  return (
    <>
      <InputGroup>
        <InputGroupText className="icones-form">{icon}</InputGroupText>
        <Input
          id={name}
          placeholder={placeholder}
          maxLength={maxLength}
          disabled={disabled}
          onBlur={e =>
            setErros({
              ...erros,
              [name]: blurError(e),
            })
          }
          onChange={e => {
            setErros({
              ...erros,
              [name]: blurError(e),
            });
            setData({
              ...data,
              [name]: onChangeData(e),
            });
          }}
          value={valueData ? valueData : data[name]}
        />
      </InputGroup>{" "}
      <p className="erros">{erros[name]}</p>
    </>
  );
};
