import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { Card, CardBody, Col, Row, Button, Container, ToastBody } from 'reactstrap';
import Breadcrumb from '../../../components/Breadcrumb';
import CustomFields from './customFields/CustomFields';
import { getManufacturerDetails } from '../../../store/actions';
import { toast } from 'react-toastify';
import Loader from '../../../components/Loader';
import Documents from './documents/Documents';
import './style.css'

const ManufacturerDetails = () => {

  const { manufacturerId } = useParams();

  const dispatch = useDispatch();

  const {
    manufacturerDetails,
    error,
    loading
  } = useSelector(state => ({
    manufacturerDetails: state.ManufacturerDetails.manufacturerDetails,
    loading: state.ManufacturerDetails.loading,
    error: state.ManufacturerDetails.error
  }));

  useEffect(() => {
    if (manufacturerId) {
      dispatch(getManufacturerDetails(manufacturerId));
    }
  }, [manufacturerId]);

  useEffect(() => {
    if (error) {
      toast.error("Erro ao buscar detalhes do fabricante");
    }
  }, [error]);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container>
          <Breadcrumb
            title="Fabricantes"
            breadcrumbItem="Detalhes"
          />
          <Row>
            <Col md={12}>
              <Card>
                <CardBody>
                  <div className="mb-4">
                    <h5>
                      <i className="fa fa-cubes h3 align-middle me-2"></i>
                      Fabricante selecionado: {
                        manufacturerDetails.name
                      }
                    </h5>
                    <hr />
                  </div>
                  <div id='manufacturerDetails'>
                    <CustomFields
                      manufacturerId={manufacturerId}
                    />
                    <Documents
                      manufacturerId={manufacturerId}
                    />
                    {loading && (<Loader />)}
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ManufacturerDetails;