import { FloppyDisk } from "phosphor-react";
import React, { useEffect, useState } from "react";
import { Button, Col, Input, Label, Row } from "reactstrap";

const LeadForm = ({ leadInfo }) => {
  const [leadData, setLeadData] = useState({});

  useEffect(() => {
    if (leadInfo?.data) {
      setLeadData(leadInfo.data.lead);
    }
  }, [leadInfo.data]);

  const updateLead = () => {};

  return (
    <>
      <Row>
        <Col md={6}>
          <Label>Nome</Label>
          <Input
            value={leadData.name}
            onChange={e =>
              setLeadData(prev => ({
                ...prev,
                name: e.target.value,
              }))
            }
          />
        </Col>
        <Col md={6}>
          <Label>E-mail</Label>
          <Input
            value={leadData.email}
            onChange={e =>
              setLeadData(prev => ({
                ...prev,
                email: e.target.value,
              }))
            }
          />
        </Col>
      </Row>
      <Row className="mt-4">
        <Col md={6}>
          <Label>Telefone</Label>
          <Input
            value={leadData.phone}
            onChange={e =>
              setLeadData(prev => ({
                ...prev,
                phone: e.target.value,
              }))
            }
          />
        </Col>
        <Col md={6}>
          <Label>Endereço</Label>
          <Input
            value={leadData.address}
            onChange={e =>
              setLeadData(prev => ({
                ...prev,
                address: e.target.value,
              }))
            }
          />
        </Col>
      </Row>
      <Row className="mt-4">
        <Col style={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
            style={{
              width: "fit-content",
              backgroundColor: "#4ec44e",
              color: "white",
              displat: "flex",
              alignItems: "center",
            }}
            onClick={updateLead}
          >
            <FloppyDisk className="me-2" />
            Salvar
          </Button>
        </Col>
      </Row>
    </>
  );
};

export default LeadForm;
