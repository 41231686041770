import { CustomFieldActionTypes } from "./actionTypes";

export const customFieldApiSuccess = (actionType, data) => ({
  type: CustomFieldActionTypes.API_RESPONSE_SUCCESS,
  payload: { actionType, data },
});

export const customFieldApiFail = (actionType, error) => ({
  type: CustomFieldActionTypes.API_RESPONSE_FAIL,
  payload: { actionType, error },
});

export const getCustomFields = manufacturerid => ({
  type: CustomFieldActionTypes.GET_CUSTOM_FIELDS,
  payload: manufacturerid
});

export const createCustomField = ( data ) => ({
  type: CustomFieldActionTypes.CREATE_CUSTOM_FIELD,
  payload: {
    customField: data.data.customField,
    manufacturer_id: data.data.manufacturer_id
  }
})