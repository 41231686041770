import React, { useEffect } from "react";
import { Card, CardBody, Spinner, Table } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { getProducts } from "../../store/order/action";
import { Link } from "@mui/material";
import { useHistory } from "react-router-dom";

const ProductsPurchased = () => {
  const dispatch = useDispatch();
  const { produtos } = useSelector(state => ({
    produtos: state.Order.produtos,
  }));
  const history = useHistory();
  useEffect(() => {
    dispatch(getProducts());
  }, [dispatch, location.pathname]);

  return (
    <div className="mt-5">
      <Card className="card-produtos">
        <CardBody className="p-3">
          <div className="produtos-comprados">
            <h6>Todos os pedidos realizados por este cliente.</h6>
            <p>
              Outros pedidos para o cadastro de cliente utilizado nesse pedido.
              Caso possua outro cadastro não irá listar abaixo
            </p>
          </div>
          {produtos.loading ? (
            <Spinner />
          ) : produtos.success && produtos?.produtos?.length > 0 ? (
            <Table bordered hover responsive striped>
              <thead>
                <tr>
                  <th>N° do Pedido</th>
                  <th>Data</th>
                  <th>Situação</th>
                  <th>Produtos</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {produtos?.produtos.map(value => {
                  return (
                    <tr key={value.NumPedido}>
                      <td>
                        <Link
                          href={`/pedidos/${value.NumPedido}/${value.CodFilial}/order`}
                        >
                          {value.CodFilial}-{value.NumPedido}
                        </Link>
                      </td>
                      <td>{value.Data}</td>
                      <td>{value.fila?.Descricao}</td>
                      <td style={{ padding: 0 }}>
                        <Table striped style={{ marginBottom: 0 }}>
                          <tbody>
                            {value.itemsFilial.map(item => {
                              return (
                                <tr className="tr-table" key={item.Produto}>
                                  <td>{item.DescProd}</td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </Table>
                      </td>{" "}
                      <td>
                        {" "}
                        <Link
                          href={`/pedidos/${value.NumPedido}/${value.CodFilial}/order`}
                        >
                          <i className="ph-eye"></i>{" "}
                        </Link>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          ) : (
            <p className="mb-0">
              <span className="fw-bold">Busca Finalizada</span>
              <span className="text-muted ms-2">
                Nenhum pagamento a ser exibido.
              </span>
            </p>
          )}
        </CardBody>
      </Card>
    </div>
  );
};

export default ProductsPurchased;
