import { apiClient2 } from "./apiCore2";

const getEventDetails = () => {
  const baseUrl = "/events_list";
  return apiClient2.get(`${baseUrl}`);
};

const updateEvents = (data, eventId) => {
  const baseUrl = `/events/${eventId}/edit`;
  return apiClient2.update(`${baseUrl}`, data);
};

const deleteEvent = eventId => {
  const baseUrl = `/events/${eventId}/destroy`;
  return apiClient2.delete(`${baseUrl}`, eventId);
};

export { getEventDetails, updateEvents, deleteEvent };
