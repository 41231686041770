import React, { useState } from "react";
import {
  Row,
  Card,
  CardBody,
  CardHeader,
  Col,
  Media,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import SimpleBar from "simplebar-react";
import { Link } from "react-router-dom";
import classnames from "classnames";

//dummy data
import { chatFields } from "./data";

// utils
import { formateDate } from "../../../utils/index";

const CommentBox = ({ comment, index, total }) => {
  const commentDate = formateDate(comment.date, "Do MMM YYYY, h:mm:ss a");
  return (
    <Media
      className={classnames("d-flex", "py-4", {
        "border-bottom": total !== index + 1,
      })}
    >
      <img
        src={comment["user"]["image"]}
        className="me-2 rounded-circle avatar-xs"
        alt="img"
      />
      <Media body className="flex-1">
        <h5 className="font-size-15 mt-0 mb-1">
          {comment["user"]["name"]}
          <small className="text-muted float-end">{commentDate} </small>
        </h5>
        <p className="text-muted">{comment["comment"]}</p>

        <Link to="#" className="text-muted font-13 d-inline-block">
          <i className="mdi mdi-reply"></i> Reply
        </Link>
      </Media>
    </Media>
  );
};

const InputArea = ({ value, onChange }) => {
  return (
    <>
      <div className="border rounded mt-4">
        <form action="#">
          <textarea
            rows="3"
            value={value}
            onChange={e => {
              onChange(e.target.value);
            }}
            className="form-control border-0 resize-none"
            placeholder="Escrever comentario..."
          ></textarea>
        </form>
      </div>
      <div className="mt-3 text-end">
        <Button color="success">Enviar</Button>
      </div>
    </>
  );
};

const Chat = ({ chatFields }) => {
  const [text, setText] = useState("");
  const onChageText = value => {
    setText(value);
  };
  const total = (chatFields || []).length;

  return (
    <div className="p-lg-2 p-0">
      <SimpleBar style={{ maxHeight: "430px" }}>
        <div>
          {(chatFields || []).map((comment, key) => (
            <CommentBox comment={comment} key={key} index={key} total={total} />
          ))}
        </div>
      </SimpleBar>
      <InputArea value={text} onChange={onChageText} />
    </div>
  );
};
const Fields = ({}) => {
  const situationOptions = [
    { value: 9, label: "Requested part" },
    { value: 10, label: "Incorrect data" },
    { value: 2, label: "Piece Sent" },
    { value: 5, label: "Request canceled" },
    { value: 6, label: "In Analysis" },
    { value: 3, label: "Available Part" },
    { value: 1, label: "Exchange Requested" },
    { value: 4, label: "Authorized Exchange" },
    { value: 10, label: "Waiting for defective part" },
    { value: 8, label: "Request closed" },
    { value: 7, label: "Canceled - Incorrect filling" },
    { value: 12, label: "Visit Requested to the Manufacturer" },
  ];

  return (
    <div className="p-lg-2 p-0">
      <Form>
        <div>
          <FormGroup row className="mb-3">
            <Label md={12} htmlFor="field-status">
              Status
            </Label>
            <Col md={12}>
              <Row>
                <Col>
                  <select
                    className="form-control"
                    name="field-status"
                    id="field-status"
                  >
                    {(situationOptions || []).map((option, key) => (
                      <option value={option["value"]} key={key}>
                        {option["label"]}
                      </option>
                    ))}
                  </select>
                </Col>
                <Col className="col-auto">
                  <Button color="secondary">
                    <i className="bx bx-save" />
                  </Button>
                </Col>
              </Row>
            </Col>
          </FormGroup>

          <FormGroup row>
            <Label md={12} htmlFor="field-supplier-invoice">
              Supplier Invoice
            </Label>
            <Col md={12}>
              <Row>
                <Col>
                  <Input
                    type="text"
                    name="field-supplier-invoice"
                    id="field-supplier-invoice"
                    placeholder="with a placeholder"
                  />
                </Col>
                <Col className="col-auto">
                  <Button color="secondary">
                    <i className="bx bx-save" />
                  </Button>
                </Col>
              </Row>
            </Col>
          </FormGroup>
        </div>
      </Form>
    </div>
  );
};
const ChatAndFields = () => {
  return (
    <Card>
      <CardHeader tag="h5" className="font-size-18 bg-primary text-white">
        Chat and Fields A.Dias
      </CardHeader>
      <CardBody className="border border-primary">
        <div>
          <Row>
            <Col xs={12} lg={6}>
              <Chat chatFields={chatFields} />
            </Col>
            <Col xs={12} lg={6}>
              <Fields />
            </Col>
          </Row>
        </div>
      </CardBody>
    </Card>
  );
};

export default ChatAndFields;
