import { ManagementActionTypes } from "./actionTypes";

export const managementApiSuccess = (actionType, data) => ({
  type: ManagementActionTypes.API_RESPONSE_SUCCESS,
  payload: { actionType, data },
});

export const managementApiFail = (actionType, error) => ({
  type: ManagementActionTypes.API_RESPONSE_ERROR,
  payload: { actionType, error },
});

export const getTableaus = () => ({
  type: ManagementActionTypes.GET_TABLEAUS,
});
