import React from "react";
import { Link } from "react-router-dom";
import { Row, Col, Card, CardBody, CardFooter } from "reactstrap";

//components
import SvgIcon from "../../components/SvgIcon";
import { useColors } from "../../hooks/useColors";
import { useSelector } from "react-redux";
import Loading from "./Loading";
const Guarantee = ({ data }) => {
  const { loadingDashboard } = useSelector(state => ({
    loadingDashboard: state.Dashboard.getDashboardDetailsLoading,
  }));
  var status_garantias =
    (data?.status_garantias && data?.status_garantias.values) || {};
  const { colorDefault, colorLight } = useColors();
  return (
    <Card className="gurantee-widgets">
      <CardBody>
        <h5 className="titulo-garantia">
          <SvgIcon name="medal" /> Garantia
          <i className="mdi mdi-information-outline float-end cursor-pointer text-muted font-size-20" />
        </h5>
        <div className="gurantee-content">
          <Row>
            {/* Em análise */}
            <Col xs={12} lg={6}>
              <div>
                <Card className="overflow-hidden">
                  <CardBody className="pb-0 position-relative">
                    <h5 className="mb-3 font-size-15">Em análise</h5>
                    <h4 className="fw-bold mb-4">
                      {loadingDashboard ? (
                        <Loading />
                      ) : status_garantias &&
                        status_garantias.hasOwnProperty("em_analise") ? (
                        status_garantias.em_analise
                      ) : (
                        "-"
                      )}
                    </h4>
                    <div className="widget-icon icone-garantia">
                      <SvgIcon name="clipboard-check" />
                    </div>
                  </CardBody>
                  <CardFooter
                    className="py-2 ver-mais"
                    style={{ backgroundColor: colorLight }}
                  >
                    <Link to="/warranty?idSituacao=5">
                      <h5
                        className=" font-size-14 mb-0"
                        style={{ color: colorDefault }}
                      >
                        Ver mais{" "}
                        <i className="mdi mdi-chevron-right float-end font-size-15"></i>
                      </h5>
                    </Link>
                  </CardFooter>
                </Card>
              </div>
            </Col>

            {/* Peça Solicitada */}
            <Col xs={12} lg={6}>
              <div>
                <Card className="overflow-hidden">
                  <CardBody className="pb-0 position-relative">
                    <h5 className="mb-3 font-size-15">Peça Solicitada</h5>
                    <h4 className="fw-bold mb-4">
                      {loadingDashboard ? (
                        <Loading />
                      ) : status_garantias &&
                        status_garantias.hasOwnProperty("peca_solicitada") ? (
                        status_garantias.peca_solicitada
                      ) : (
                        "-"
                      )}
                    </h4>
                    <div className="widget-icon icone-garantia">
                      <SvgIcon name="page-check" />
                    </div>
                  </CardBody>
                  <CardFooter
                    className="py-2 ver-mais"
                    style={{ backgroundColor: colorLight }}
                  >
                    <Link to="/warranty?idSituacao=1">
                      <h5
                        className=" font-size-14 mb-0"
                        style={{ color: colorDefault }}
                      >
                        Ver mais{" "}
                        <i className="mdi mdi-chevron-right float-end font-size-15"></i>
                      </h5>
                    </Link>
                  </CardFooter>
                </Card>
              </div>
            </Col>

            {/* Peça Disponível */}

            <Col xs={12} lg={6}>
              <div>
                <Card className="overflow-hidden">
                  <CardBody className="pb-0 position-relative">
                    <h5 className="mb-3 font-size-15">Peça Disponível</h5>
                    <h4 className="fw-bold mb-4">
                      {loadingDashboard ? (
                        <Loading />
                      ) : status_garantias &&
                        status_garantias.hasOwnProperty("peca_disponivel") ? (
                        status_garantias.peca_disponivel
                      ) : (
                        "-"
                      )}
                    </h4>
                    <div className="widget-icon icone-garantia">
                      <SvgIcon name="check-circle" />
                    </div>
                  </CardBody>
                  <CardFooter
                    className="py-2 ver-mais"
                    style={{ backgroundColor: colorLight }}
                  >
                    <Link to="/warranty?idSituacao=6">
                      <h5
                        className=" font-size-14 mb-0"
                        style={{ color: colorDefault }}
                      >
                        Ver mais{" "}
                        <i className="mdi mdi-chevron-right float-end font-size-15"></i>
                      </h5>
                    </Link>
                  </CardFooter>
                </Card>
              </div>
            </Col>

            {/* Aguardando Peça Defeituosa */}

            <Col xs={12} lg={6}>
              <div>
                <Card className="overflow-hidden">
                  <CardBody className="pb-0 position-relative">
                    <h5 className="mb-3 font-size-15">
                      Aguardando Peça Defeituosa
                    </h5>
                    <h4 className="fw-bold mb-4">
                      {loadingDashboard ? (
                        <Loading />
                      ) : status_garantias &&
                        status_garantias.hasOwnProperty(
                          "aguardando_peca_defeituosa"
                        ) ? (
                        status_garantias.aguardando_peca_defeituosa
                      ) : (
                        "-"
                      )}
                    </h4>
                    <div className="widget-icon icone-garantia">
                      <SvgIcon name="clock" />
                    </div>
                  </CardBody>
                  <CardFooter
                    className="py-2 ver-mais"
                    style={{ backgroundColor: colorLight }}
                  >
                    <Link to="/warranty?idSituacao=9">
                      <h5
                        className=" font-size-14 mb-0"
                        style={{ color: colorDefault }}
                      >
                        Ver mais{" "}
                        <i className="mdi mdi-chevron-right float-end font-size-15"></i>
                      </h5>
                    </Link>
                  </CardFooter>
                </Card>
              </div>
            </Col>
          </Row>
        </div>
      </CardBody>
    </Card>
  );
};

export default Guarantee;
