import React, { useState, useEffect } from "react";
import { CardBody, Card, Input, FormGroup, Label, Spinner, Button, Col, Row } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { clearNewOrder, getFreteValue } from "../../store/actions";
import { filiais } from "../../utils/filiais";

const Frete = ({
  valores,
  valTot,
  addFrete,
  newAddItemsSeparadoPorFilial,
  awaitModfyCart,
  setAwaitModfyCart
}) => {
  const dispatch = useDispatch();
  const { frete, newOrder } = useSelector(state => ({
    frete: state.Order.newOrder.newOrder.frete,
    newOrder: state.Order.newOrder.newOrder,
  }));

  const address = frete.address;
  const [valor, setValor] = useState(0);
  const [, setBtnFreteActive] = useState(false);
  const [, setCep] = useState("");
  const [totalOrderValueToCalculateShipping, setTotalOrderValueToCalculateShipping] = useState(0);
  const [zipcodeToCalculateShipping, setZipcodeTocalculateShipping] = useState();
  const [isNeedShippingUpdate, setIsNeedShippingUpdate] = useState(true);
  const formato = {
    minimumFractionDigits: 2,
    style: "currency",
    currency: "BRL",
  };

  useEffect(() => {
    dispatch(clearNewOrder());
    setValor(0);
    setBtnFreteActive(false);
    setCep("");
  }, []);

  const shippingUpdate = () => {
    if (address?.zipcode && valTot > 0) {
      if (awaitModfyCart == "close") {
        return;
      }

      setTotalOrderValueToCalculateShipping(valTot);
      setZipcodeTocalculateShipping(address?.zipcode);

      dispatch(
        getFreteValue(
          valTot,
          "01",
          "01",
          "",
          newAddItemsSeparadoPorFilial,
          false
        )
      );
      resetShippingValues();
    }
  };

  const updateSelectedShipping = shippingSelected => {
    const newShippingSelected = {...shippingSelected};
    addFrete(newShippingSelected);
  };

  useEffect(() => {
    setIsNeedShippingUpdate(valTot != totalOrderValueToCalculateShipping);
  }, [valTot, totalOrderValueToCalculateShipping]);

  useEffect(() => {
    setIsNeedShippingUpdate(address?.zipcode != zipcodeToCalculateShipping);
  }, [address?.zipcode, zipcodeToCalculateShipping]);

  useEffect(() => {
    if (isNeedShippingUpdate) {
      resetShippingValues();
    }
  }, [isNeedShippingUpdate]);

  const resetShippingValues = () => {
    addFrete({});
    setValor(0);
    let frete = document.getElementsByName("frete");
    for (let fre of frete) {
      fre.checked = false;
    }
  }

  return (
    <Card>
      <CardBody>
        <h4
          style={{
            fontSize: 23,
            fontWeight: "bold",
            color: "#202833",
            marginBottom: 35,
          }}
        >
          Calcular frete e prazo de entrega
        </h4>
        <p className="text-muted">
          <small>
            Partindo de:{" "}
            {newAddItemsSeparadoPorFilial.map((item, k) =>
              newAddItemsSeparadoPorFilial.length == k + 1
                ? filiais[Number(item.CODFILIAL)]
                : filiais[Number(item.CODFILIAL)] + ", "
            )}
          </small>
        </p>
        <p style={{ margin: "9px 0 0 0" }}>
          CEP:{" "}
          {frete?.address?.zipcode ? (
            <span>{frete?.address?.zipcode}</span>
          ) : (
            <small className="text-muted">Nenhum cep informado</small>
          )}
        </p>
        <p style={{ margin: "9px 0 0 0" }}>
          {frete.address ? (
            <span>
              {frete.address.address} - {frete.address.city}/
              {frete.address.state}
            </span>
          ) : (
            <span />
          )}
        </p>


        <div>
          <hr style={{ margin: "25px 0" }} />
          <h4
            style={{
              fontSize: 23,
              fontWeight: "bold",
              color: "#202833",
              marginBottom: 35,
              display: "flex",
              flexDirection: "column",
            }}
          >
            Selecione a modalidade{" "}
            <span
              style={{ fontSize: 12, fontWeight: "normal", color: "#495057" }}
            >
              *Dias Úteis Após Emissão da NF-e
            </span>
          </h4>
          {frete.loading ? (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                height: 121,
              }}
            >
              <Spinner size="sm" />
              <span style={{ color: "#555b62" }}>Buscando fretes</span>
            </div>
          ) : (
            <>
              {isNeedShippingUpdate || !frete.success || frete.entrega.length == 0 ? (
                <>
                  <Button
                    style={{
                      backgroundColor: "#005696",
                      color: "#FFFFFF",
                      width: "100%",
                      display: "block",
                      fontSize: 17,
                      border: "none",
                      padding: 20,
                      borderRadius: 6
                    }}
                    onClick={shippingUpdate}
                  >
                    Calcular frete
                  </Button>
                </>
              ) : (
                <div
                  onChange={e => {
                    setValor(e.target.value);
                  }}
                >
                  {frete.entrega.map((value, index) => (
                    <FormGroup check className="d-flex mt-3" key={index}>
                      <Input
                        type="radio"
                        value={value.price}
                        name="frete"
                        className="modalidade"
                        onChange={() => updateSelectedShipping(value)}
                      />
                      <Label
                        className={valor == value.price ? "label-prazo-active" : ""}
                        style={{ marginLeft: 10, fontWeight: 400 }}
                      >
                        {value.description}{" "}
                        {value.usado_percentual > 0 ? (
                          <span style={{ margin: "0 5px" }}>%</span>
                        ) : (
                          ""
                        )}{" "}
                        - *Prazo até {value.delivery_delay} dia(s) úteis -{" "}
                        {!value.promotionDiscount ? (
                          <>
                            {Number(value?.priceSomado ? value?.priceSomado : value.price).toLocaleString("pt-br", formato)}
                          </>
                        ) : (
                          <>
                            <del>
                              {Number(value?.priceSomado ? value?.priceSomado : value.price).toLocaleString("pt-br", formato)}
                            </del>
                            {" "}
                            {Number(Number(value?.priceSomado ? value?.priceSomado : value.price) - Number(value?.promotionDiscount)).toLocaleString("pt-br", formato)}
                          </>
                        )}
                      </Label>
                    </FormGroup>
                  ))}
                </div>
              )}
            </>
          )}
        </div>
      </CardBody >
    </Card >
  );
};

export default Frete;
