function numberOfModules(
  kwpPower,
  manufacturerModulePower,
  consumptionPpowerToBeMet
) {
  let result =
    (kwpPower / manufacturerModulePower) * (consumptionPpowerToBeMet / 100);

  let roundResult = Math.ceil(result);

  return roundResult;
}

export { numberOfModules };
