import React from "react";
import { Col, Container, Row } from "reactstrap";
import Breadcrumb from "../../components/Breadcrumb";
import ClubLoyaltyRating from "./ClubLoyaltyRating";

import MyRating from "./MyRating";
import RankingAchieved from "./RankingAchieved";

const Loyalty = () => {
  return (
    <React.Fragment>
      <div className="page-content">
        <Container>
          {/* Render Breadcrumb */}
          <Breadcrumb
            title="Página Inicial"
            breadcrumbItem="Fidelidade clube A.Dias"
          />
          <Row>
            <Col md={12}>
              <MyRating />
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <RankingAchieved />
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <ClubLoyaltyRating />
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Loyalty;
