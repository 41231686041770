import React from "react";
export default function IconStoreFront(props) {
  const colorFill = props?.colorFill?.replaceAll("#", "") || "none";
  const colorStroke = props?.colorStroke || "#000";
  const width = props?.width || 21;
  const height = props?.height || 21;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill={colorFill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.89062 8.67969V12.6875C2.89062 12.8118 2.94001 12.931 3.02792 13.019C3.11583 13.1069 3.23505 13.1562 3.35938 13.1562H11.7969C11.9212 13.1562 12.0404 13.1069 12.1283 13.019C12.2162 12.931 12.2656 12.8118 12.2656 12.6875V8.67969"
        stroke={colorStroke}
        strokeWidth="0.984375"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.24219 2.84375H11.9141C12.0158 2.84459 12.1145 2.87806 12.1957 2.93925C12.2769 3.00043 12.3364 3.08609 12.3652 3.18359L13.2031 6.125H1.95312L2.79102 3.18359C2.81989 3.08609 2.87931 3.00043 2.96054 2.93925C3.04177 2.87806 3.1405 2.84459 3.24219 2.84375V2.84375Z"
        stroke={colorStroke}
        strokeWidth="0.984375"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.70312 6.125V7.0625C5.70312 7.55978 5.50558 8.03669 5.15395 8.38832C4.80232 8.73996 4.32541 8.9375 3.82812 8.9375C3.33084 8.9375 2.85393 8.73996 2.5023 8.38832C2.15067 8.03669 1.95313 7.55978 1.95312 7.0625V6.125"
        stroke={colorStroke}
        strokeWidth="0.984375"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.45312 6.125V7.0625C9.45312 7.55978 9.25558 8.03669 8.90395 8.38832C8.55232 8.73996 8.07541 8.9375 7.57812 8.9375C7.08084 8.9375 6.60393 8.73996 6.2523 8.38832C5.90067 8.03669 5.70313 7.55978 5.70312 7.0625V6.125"
        stroke={colorStroke}
        strokeWidth="0.984375"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.2031 6.125V7.0625C13.2031 7.55978 13.0056 8.03669 12.6539 8.38832C12.3023 8.73996 11.8254 8.9375 11.3281 8.9375C10.8308 8.9375 10.3539 8.73996 10.0023 8.38832C9.65067 8.03669 9.45312 7.55978 9.45312 7.0625V6.125"
        stroke={colorStroke}
        strokeWidth="0.984375"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
