import React, { useEffect } from "react";
import axios from "axios";
import { apiClient } from "../../api/apiCore";
import { apiClient2 } from "../../api/apiCore2";
const PoliticaDePrivacidade = () => {
  return (
    <div className="page-content">
      <p>
        <strong>POLÍTICA DE PRIVACIDADE</strong>
      </p>

      <p>
        Para nós da ADias o respeito e o cuidado com seus dados pessoais são
        prioridades. Por isso, não medimos esforços na hora de cumprir todas as
        medidas de segurança necessárias para que seus dados estejam protegidos
        e para que você, titular dos dados, tenha plena consciência sobre como
        são utilizados por nós.
      </p>

      <p>
        Asseguramos a nossos Clientes que qualquer tratamento de dados pessoais
        que a ADias realize estará em conformidade com a Lei Geral de Proteção
        de Dados e com o disposto na presente Política.
      </p>

      <p>
        Ler, compreender e aceitar esta Política de Privacidade é um requisito
        essencial para poder contratar e/ou estabelecer qualquer tipo de
        relacionamento com a ADias.
      </p>

      <p>
        <strong>RESPONSÁVEL PELO TRATAMENTO</strong>
      </p>

      <p>
        Os controladores de seus dados são as empresas MPS Distribuidora
        Mercantil Ltda., inscrita no CNPJ sob o n° 09.570.732/0001-91, a Gedear
        Refrigeração Ltda., inscrita no CNPJ sob o n° 09.294.735/0001-40 e a
        A.Dias Solar Distribuidora Mercantil Ltda, inscrita no CNPJ sob o nº
        05.951.301/0001-14 (“<strong>ADias</strong>”).
      </p>

      <p>
        Para mais informações sobre a coleta e utilização seus dados pessoais,
        pedimos que entre em contato com nosso Encarregado pelo tratamento dos
        dados pessoais (DPO), Datablock Proteção de Dados Ltda., a través do
        seguinte e-mail: lgpd@adias.com.br
      </p>

      <p>
        <strong>QUAIS SÃO OS DADOS COLETADOS PELA ADIAS?</strong>
      </p>

      <p>
        Um dado pessoal é qualquer informação relacionada à uma pessoa física
        que seja capaz identificá-la. Para que possamos oferecer nossos produtos
        e prestar nossos serviços da melhor forma possível, precisamos coletar e
        utilizar alguns dados pessoais de nossos Clientes. Nos comprometemos a
        coletar somente aqueles dados que sejam imprescindíveis para realizar as
        finalidades indicadas nesta Política.
      </p>

      <p>
        Em alguns casos, as informações serão fornecidas por você mesmo, ao
        registrar-se, ao comprar um produto ou ao utilizar algum de nossos
        serviços. Em outros, nós as coletamos automaticamente, como quando
        navega em nossa plataforma. Também podemos coletar suas informações por
        meio de outras fontes confiáveis.
      </p>

      <p>
        Queremos que saiba que não é obrigado a nos fornecer as informações
        pessoais detalhadas abaixo; no entanto, trata-se de um requisito
        essencial para que possa contratar e/ou ter qualquer tipo de
        relacionamento com a ADias e, caso não forneça essas informações, não
        seremos capazes de lhe atender ou nossa capacidade de fazê-lo poderá ser
        significativamente prejudicada.
      </p>

      <p>Veja abaixo os tipos de dados que podemos coletar:</p>

      <ul>
        <li>
          <p>
            Informações que nos fornece diretamente ao registrar-se, ao comprar
            um produto ou ao utilizar nossos serviços:
          </p>
        </li>
      </ul>

      <ul>
        <li>
          <p>
            Dados de identificação, como nome completo, números de documentos,
            data de nascimento e gênero.
          </p>
        </li>
        <li>
          <p>Dados de contato, como e-mail, telefone e endereço.</p>
        </li>
        <li>
          <p>Dados bancários, como número do seu cartão de crédito.</p>
        </li>
      </ul>

      <p>
        <br />
        &nbsp;
      </p>

      <ul>
        <li>
          <p>
            Informações que coletamos automaticamente, através de cookies,
            independentemente se está registrado ou não:
          </p>
        </li>
      </ul>

      <ul>
        <li>
          <p>
            Informações dos dispositivos ou computadores a partir dos quais
            acessa o site da ADias e outros dados capturados automaticamente
            (como o tipo ou versão do navegador ou o sistema operacional,
            configurações e parâmetros).
          </p>
        </li>
        <li>
          <p>
            Endereço IP da internet que utiliza ao se conectar aos nossos
            serviços ou ao navegar em nossos sites.
          </p>
        </li>
        <li>
          <p>
            Informações sobre transações e movimentos no site da ADias (produtos
            de interesse, compras, pagamentos, perguntas).
          </p>
        </li>
      </ul>

      <p>
        Você pode permitir ou bloquear os cookies, assim como eliminar seus
        dados de navegação (incluindo os cookies) em seu navegador. Consulte as
        opções e instruções que seu navegador oferece. Para mais informações
        acesse nossa Política de Uso de Cookies.
      </p>

      <p>
        <br />
        &nbsp;
      </p>

      <ul>
        <li>
          <p>Informações que coletamos de outras fontes:</p>
        </li>
      </ul>

      <ul>
        <li>
          <p>
            Informações de crédito, positivas ou negativas, obtidas a partir de
            bases de risco de crédito e/ou fontes de acesso público, de acordo
            com a lei aplicável.
          </p>
        </li>
        <li>
          <p>
            Dados utilizados para validação ou autenticação de identidade ou
            para completar ou corrigir informação, obtidos a partir de fontes
            seguras e confiáveis, como órgãos públicos, prestadores de serviços
            ou parceiros de negócios com os quais trabalhamos.
          </p>
        </li>
      </ul>

      <p>
        A coleta de seus dados baseia-se não apenas em seu consentimento
        expresso, mas também: (i) no cumprimento das obrigações legais ou
        regulatórias da ADias; (ii) no exercício regular dos direitos em
        processos judiciais, administrativos ou arbitrais; (iii) na execução da
        relação comercial e contratual entre a ADias e o Cliente; (iv) no
        interesse legítimo da ADias ou de terceiros; e (v) na proteção ao
        crédito.
      </p>

      <p>
        <strong>PARA QUE OS DADOS SERÃO UTILIZADOS?</strong>
      </p>

      <p>
        Seus dados pessoais somente serão utilizados para finalidades
        relacionadas a venda de nossos produtos e/ou de nossos serviços, ou para
        estabelecer um relacionamento com você, até o limite necessário para
        tanto. Em nenhum caso utilizaremos os dados para outras finalidades sem
        antes solicitar sua autorização.<strong> </strong>A seguir, te
        explicamos para que vamos utilizar seus dados:
      </p>

      <p>
        <strong>Clientes:</strong>
      </p>

      <ul>
        <li>
          <p>Identificá-lo e contatá-lo e registrá-lo em nossos sistemas.</p>
        </li>
        <li>
          <p>Validar, atualizar e corrigir sua informação.</p>
        </li>
        <li>
          <p>
            Fornecer os produtos, serviços e/ou benefícios que solicitar ou
            contratar conosco, assim como os respectivos serviços pós-venda.
          </p>
        </li>
        <li>
          <p>
            Oferecer e cumprir a garantia, ou fazer a intermediação com o
            fabricante para esta finalidade.
          </p>
        </li>
        <li>
          <p>
            Realizar a entrega do produto adquirido através de transportadoras
            parceiras.
          </p>
        </li>
        <li>
          <p>
            Atender seus comentários, reclamações e sugestões, além de fornecer
            suporte e realizar pesquisas de satisfação, por qualquer meio de
            comunicação.
          </p>
        </li>
        <li>
          <p>
            Consultar e reportar dados nas centrais de risco creditício,
            realizar processos de verificação de risco comercial e/ou de
            crédito, analisar a viabilidade de celebrar ou manter uma relação
            comercial.
          </p>
        </li>
        <li>
          <p>Cobrança simples, judicial e/ou extrajudicial.</p>
        </li>
        <li>
          <p>
            Oferecer os serviços e funcionalidades que melhor atendam às suas
            necessidades e personalizar nossos serviços para tornar suas
            experiências em nosso site o mais confortável possível.
          </p>
        </li>
        <li>
          <p>
            Permitir a sua participação em concursos, promoções ou sorteios,
            caso sejam realizados e aplicáveis a você, além de notificá-lo caso
            seja o vencedor, sempre em conformidade com os regulamentos
            aplicáveis a sorteios e concursos.
          </p>
        </li>
        <li>
          <p>
            Permitir o envio de informação comercial sobre nossas empresas,
            produtos e serviços.
          </p>
        </li>
        <li>
          <p>Conquistar sua fidelidade através de programas de benefícios.</p>
        </li>
        <li>
          <p>
            Cumprir as obrigações e requisitos legais das autoridades
            competentes.
          </p>
        </li>
      </ul>

      <p>
        <br />
        &nbsp;
      </p>

      <p>
        <strong>Revendedores, instaladores e outros parceiros:</strong>
      </p>

      <ul>
        <li>
          <p>Identificá-lo, contatá-lo e registrá-lo em nossos sistemas.</p>
        </li>
        <li>
          <p>Validar, atualizar e corrigir sua informação.</p>
        </li>
        <li>
          <p>Emitir orçamentos e executar a venda realizada;</p>
        </li>
        <li>
          <p>Realizar o repasse de comissões;</p>
        </li>
        <li>
          <p>
            Atender seus comentários, reclamações e sugestões, além de fornecer
            suporte e realizar pesquisas de satisfação, por qualquer meio de
            comunicação.
          </p>
        </li>
        <li>
          <p>
            Permitir o gerenciamento das vendas de produtos e serviços através
            do Clube Adias;
          </p>
        </li>
        <li>
          <p>
            Permitir sua participação em treinamentos realizados pela ADias e/ou
            por fabricantes;
          </p>
        </li>
        <li>
          <p>
            Permitir a sua participação em concursos, promoções ou sorteios,
            caso sejam realizados e aplicáveis a você, além de notificá-lo caso
            seja o vencedor, sempre em conformidade com os regulamentos
            aplicáveis a sorteios e concursos.
          </p>
        </li>
        <li>
          <p>
            Participar em campanhas de marketing realizadas através de nosso
            site, redes sociais e plataformas de podcasts;
          </p>
        </li>
        <li>
          <p>
            Permitir o envio de informação comercial sobre nossas empresas,
            produtos e serviços.
          </p>
        </li>
        <li>
          <p>Conquistar sua fidelidade através de programas de benefícios.</p>
        </li>
        <li>
          <p>
            Divulgação da marca ADias, de nossos produtos e serviços, assim como
            de eventos realizados pela ADias através da publicação de fotos e
            vídeos.
          </p>
        </li>
        <li>
          <p>
            Cumprir as obrigações e requisitos legais das autoridades
            competentes.
          </p>
        </li>
      </ul>

      <p>
        <strong>Candidatos</strong>
      </p>

      <ul>
        <li>
          <p>Realizar recrutamento e seleção de candidatos.</p>
        </li>
      </ul>

      <p>
        Para participar de processos de seleção diretamente através de nosso
        site, você será direcionado à plataforma da Catho, quem será responsável
        por gerenciar o recrutamento de candidatos.
      </p>

      <p>
        Caso você nos envie seu currículo em formato físico, ou por e-mail, seus
        dados serão utilizados unicamente para possibilitar sua participação nos
        processos seletivos das empresas do Grupo ADias e poderão ser
        compartilhados com empresas especializadas em recrutamento e seleção de
        candidatos. Caso você não seja selecionado, os dados serão eliminados
        após o término do processo seletivo.
      </p>

      <p>
        <br />
        &nbsp;
      </p>

      <p>
        Algumas das finalidades descritas podem não ser aplicáveis enquanto não
        utilizar o serviço associado a tais finalidades.
      </p>

      <p>
        O uso de seus dados para qualquer outra finalidade que não seja
        compatível com as descritas será comunicado antes de prosseguirmos com o
        processamento.
      </p>

      <p>
        <strong>PARA QUEM COMPARTILHAMOS SEUS DADOS PESSOAIS?</strong>
      </p>

      <p>
        Em nenhum caso seus dados serão objeto de qualquer tipo de
        comercialização. Somente compartilharemos seus dados quando exista
        necessidade para a manutenção de nossa infraestrutura de sistemas, para
        permitir a venda de nossos produtos, para a prestação de serviços ou
        para atender uma obrigação legal, judicial ou regulatória, e até o
        limite necessário para tanto.
      </p>

      <p>
        Tomaremos todas as medidas necessárias para garantir que nossos
        funcionários, parceiros ou terceiros que possuam necessidade de ter
        acesso aos dados pessoais para cumprir com suas funções profissionais,
        estejam sujeitos a compromissos de confidencialidade.
      </p>

      <p>
        Neste sentido, seus dados pessoais podem ser transferidos para os
        seguintes destinatários:
      </p>

      <ol>
        <li>
          <p>
            “Prestadores de Serviços”: prestadores de serviços ou empresas são
            terceiros contratados para atuar em nome da ADias para prestar
            serviços seguindo nossas instruções e de acordo com o estabelecido
            nesta Política de Privacidade, a fim de ajudar a melhorar ou
            facilitar nossas operações, como: (a) empresas de transporte,
            logística, courier e de entrega para o envio dos produtos que
            comprou; (b) meios de pagamento ou intermediários na gestão de
            pagamentos, (c) provedores de sistemas de computador, provedores de
            serviços em nuvem, provedores de bancos de dados e provedores de
            serviços tecnológicos em geral, (d) agências de publicidade ou
            marketing, (e) análise de dados, (f) agências de cobrança, (g)
            entidades governamentais ou empresas especializadas em riscos, com o
            objetivo de verificar suas informações. Esses Prestadores de
            Serviços acessam apenas as informações estritamente necessárias para
            a prestação dos serviços acordados e não podem utilizá-los para
            outros fins que não lhes tenham sido confiados pela ADias.
          </p>
        </li>
        <li>
          <p>
            “Grupo ADias”: empresas pertencentes ao nosso grupo econômico, que
            operam sob nossos mesmos processos e políticas internas, sejam elas
            controladas, controladoras ou afiliadas, para facilitar a gestão dos
            serviços e produtos da ADias.
          </p>
        </li>
        <li>
          <p>
            “Instaladores credenciados”: parceiros que nos auxiliam na prestação
            de serviços de manutenção e instalação de nossos produtos. São
            também os responsáveis por solicitar à ADias a garantia dos produtos
            revendidos e/ou instalados por eles.
          </p>
        </li>
        <li>
          <p>
            “Clientes”: caso você seja instalador, podemos compartilhar seus
            dados com nossos Clientes para possibilitar que você execute o
            serviço.
          </p>
        </li>
        <li>
          <p>
            “Fabricantes”: os fabricantes dos produtos adquiridos por nossos
            Clientes poderão ter acesso aos dados pessoais de nossos Clientes
            para facilitar o cumprimento da garantia. Caso você seja revendedor
            e/ou instalador, seus dados poderão ser fornecidos ao fabricante
            para permitir sua participação em treinamentos.
          </p>
        </li>
        <li>
          <p>
            “Autoridades públicas”: autoridades administrativas e judiciais que,
            no exercício de sua competência, exijam informações, mesmo que não
            haja ordem ou citação executiva ou judicial para esse efeito, para
            os seguintes fins: (a) garantir a aplicação ou administração da
            justiça, o reconhecimento, exercício ou defesa de um direito em um
            processo judicial ou administrativo e/ou a resolução de disputas; e
            (b) cumprir com qualquer lei, regulamento ou disposição legal
            aplicável, ou algum mandato de autoridade competente devidamente
            fundamentado e motivado.
          </p>
        </li>
        <li>
          <p>
            “Entidades públicas e/ou privadas que prestam serviços de informação
            creditícia”: a ADias poderá, sempre que a lei aplicável permitir,
            compartilhar, com entidades públicas ou privadas que fornecem
            serviços de informação ou histórico de crédito, informações
            vinculadas ao seu comportamento creditício ou ao cumprimento ou
            descumprimento de obrigações de conteúdo patrimonial.
          </p>
        </li>
      </ol>

      <p>
        Caso a ADias decida compartilhar suas informações pessoais com terceiros
        que não os mencionados, solicitaremos seu consentimento prévio e
        expresso, desde que não haja autorização ou obrigação legal que permita
        que seja realizado sem tal consentimento.
      </p>

      <p>
        Da mesma forma, você dá o seu consentimento expresso e informado para
        que a ADias ceda, transmita ou transfira suas informações pessoais aos
        destinatários descritos nesta Política de Privacidade.
      </p>

      <p>
        Por fim, a ADias não será responsável pelo uso indevido de suas
        informações pessoais por parte de terceiros se tais terceiros tiverem
        coletado e/ou tratado diretamente suas informações pessoais.
      </p>

      <p>
        Os serviços prestados pela ADias requerem o suporte de uma
        infraestrutura tecnológica, como servidores e serviços em nuvem, que
        podem ser próprios ou fornecidos por terceiros. Parte dessa
        infraestrutura pode ser estabelecida fora do território nacional. Os
        países receptores dos dados que transferimos podem não oferecer os
        níveis adequados de proteção de dados pessoais conforme os regulamentos
        aplicáveis. Nesses casos, a ADias adota medidas para proteger seus
        dados, por meio de cláusulas contratuais ou regras corporativas
        vinculativas que impõem as mesmas medidas de proteção descritas nesta
        Política de Privacidade.
      </p>

      <p>
        <strong>ATENÇÃO:</strong> se você é revendedor ou instalador
        credenciado, deve informar aos Clientes que os dados serão
        compartilhados com a ADias. Nestes casos, você nos garante ter coletado
        os dados do Cliente de forma legal, tê-lo informado previamente sobre o
        compartilhamento dos dados à ADias, a finalidade do compartilhamento,
        assim como sobre todo o conteúdo desta Política.
      </p>

      <p>
        <strong>POR QUANTO TEMPO VAMOS MANTER OS DADOS?</strong>
      </p>

      <p>
        Armazenaremos as informações pessoais ao menos pelo tempo que for
        necessário para cumprir o objetivo para o qual foram coletadas, atender
        requisitos legais ou regulamentares ou durante o prazo de prescrição
        legal de possíveis responsabilidades legais ou contratuais. Findos estes
        prazos, poderemos seguir guardando alguns dados para poder atendê-lo com
        mais eficiência e qualidade em um futuro.
      </p>

      <p>
        Os dados serão armazenados com todas as garantias necessárias para
        evitar acessos não autorizados ou situações de perda, alteração ou
        divulgação ilegais ou acidentais. Entretanto, se preferir, pode
        solicitar sua exclusão de nossas bases de dados enviando um requerimento
        sem nenhum custo adicional a través do e-mail lgpd@adias.com.br.
      </p>

      <p>
        <strong>
          QUAIS SÃO OS SEUS DIREITOS COM RELAÇÃO AOS DADOS FORNECIDOS?
        </strong>
      </p>

      <p>A qualquer momento, você poderá exercer seus direitos de:</p>

      <ul>
        <li>
          <p>Confirmar se estamos tratando seus dados;</p>
        </li>
        <li>
          <p>Ter acesso a eles;</p>
        </li>
        <li>
          <p>
            Solicitar a correção de dados incompletos, inexatos ou
            desatualizados;
          </p>
        </li>
        <li>
          <p>Em alguns casos, solicitar o bloqueio ou a eliminação de dados;</p>
        </li>
        <li>
          <p>
            Solicitar a eliminação dos dados pessoais tratados com o seu
            consentimento, exceto quando sejam necessários para cumprimento de
            obrigação legal ou regulatória por parte da ADias, ou quando sejam
            mantidos de forma anônima;
          </p>
        </li>
        <li>
          <p>
            Solicitar informação sobre as entidades públicas e privadas com as
            quais compartilhamos seus dados.
          </p>
        </li>
      </ul>

      <p>
        Para obter estas informações, solicitamos que você entre em contato com
        nosso Encarregado pelo tratamento dos dados pessoais (DPO) través do
        e-mail lgpd@adias.com.br.
      </p>

      <p>
        <strong>DIREITOS DE IMAGEM</strong>
      </p>

      <p>
        As imagens publicadas em nossos materiais publicitários, site e redes
        sociais são de titularidade exclusiva da ADias, não podendo ser
        reproduzidas e/ou retransmitidas por terceiros. A reprodução ou
        divulgação, total ou parcial, das imagens poderá gerar ao infrator o
        dever de indenizar a ADias e as pessoas nelas identificadas.
      </p>

      <p>
        <strong>CÂMERAS DE SEGURANÇA e WI-FI</strong>
      </p>

      <p>
        Para garantir a segurança de nossos Clientes, funcionários,
        fornecedores, parceiros e visitantes, utilizamos sistemas de gravação em
        nossas instalações com base em nosso legítimo interesse de controlar o
        acesso à nossas instalações, assim como para proteger a vida e a
        integridade física das pessoas.
      </p>

      <p>
        As imagens captadas pelas câmeras serão armazenadas pelo prazo de 1 (um)
        mês. Transcorrido este prazo, serão definitivamente eliminadas.
      </p>

      <p>
        As imagens poderão ser compartilhadas com prestadores de serviços de
        segurança, assim como com autoridades administrativas e judiciais que,
        no exercício de sua competência, exijam informações, mesmo que não haja
        ordem ou citação executiva ou judicial para esse efeito, para os
        seguintes fins: (a) garantir a aplicação ou administração da justiça, o
        reconhecimento, exercício ou defesa de um direito em um processo
        judicial ou administrativo e/ou a resolução de disputas; e (b) cumprir
        com qualquer lei, regulamento ou disposição legal aplicável, ou algum
        mandato de autoridade competente devidamente fundamentado e motivado.
      </p>

      <p>
        Caso você visite nosso showroom, informamos que oferecemos acesso à
        nossa rede wi-fi de forma gratuita. Para poder prestar este serviço,
        devemos coletar o número IP de seu dispositivo. Este dado será
        armazenado somente para permitir que você utilize o serviço e será
        eliminado após a expiração da sessão, em 24 horas.
      </p>

      <p>
        <strong>MODIFICAÇÕES DE NOSSA POLÍTICA DE PRIVACIDADE</strong>
      </p>

      <p>
        Buscando a contínua melhoria dos nossos processos e serviços, podemos
        alterar, a qualquer momento, a Política de Privacidade. Sempre que
        necessário, divulgaremos as alterações em nosso site em lugar visível e
        de fácil acesso. Solicitamos que revise nossa Política de Privacidade a
        cada acesso ao site.
      </p>
    </div>
  );
};

export default PoliticaDePrivacidade;
