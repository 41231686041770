import React, { useEffect, useState } from "react";
import { Alert, Card, CardBody, Row, Spinner } from "reactstrap";
/* import { orders } from "../../common/data"; */
import { Link } from "react-router-dom";
import * as moment from "moment";
import DataTable from "../../../components/DataTable";
import Paginations from "../../../components/Paginations";

const Closing = ({
  closures,
  loading,
  setCurrentPageIndex,
  currentPageIndex,
  loadingFilter,
}) => {
  const [orders, setOrders] = useState([]);
  //pagination customization
  const total = closures?.total_fechamentos;

  const columns = [
    {
      dataField: "orderId",
      text: "#",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => (
        <Link to="#" className="text-body fw-bold">
          {row.orderId}
        </Link>
      ),
    },
    {
      dataField: "iten",
      text: "Itens",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => <span>{row.item}</span>,
    },
    {
      dataField: "fornecedor",
      text: "Fornecedor",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => <span>{row.billingName}</span>,
    },
    {
      dataField: "total",
      text: "Total",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => <span>{row.badgeclass}</span>,
    },
    {
      dataField: "emissão",
      isDummyField: true,
      text: "Emissão",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => handleValidDate(row.paymentMethod),
    },
    {
      dataField: "NFenviada",
      text: "NF enviada",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => <span>{row.nfEnviada}</span>,
    },
    {
      dataField: "dummy",
      text: "",
      isDummyField: true,
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => <span>{row.dummy}</span>,
    },
  ];

  /** set Date formate */
  const handleValidDate = date => {
    const date1 = moment(new Date(date)).format("DD MMM Y");
    return date1;
  };
  /**
   * data sorting
   */

  const formatDataOrders = () => {
    const ordersFormat = closures?.fechamentos.map((item, k) => {
      return {
        id: k,
        orderId: item.NUM,
        billingName: item.fornecedor.CODIGO_FORN + "-" + item.fornecedor.NREDUZ,
        item: item.ITEM,
        badgeclass:
          item.fornecedor.CODIGO_FORN == "010055"
            ? "-"
            : parseFloat(item.SUM_TOTAL).toLocaleString("pt-BR", {
                style: "currency",
                currency: "BRL",
              }),
        paymentMethod: moment(item?.EMISSAO, "YYYY/MM/DD"),
        nfEnviada:
          item.fornecedor.CODIGO_FORN == "010055" ? (
            "-"
          ) : item.temNF ? (
            <div
              style={{
                width: 60,
                height: 30,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: 5,
              }}
              className="bg-success"
            >
              <i
                className="ph-thumbs-up-fill"
                style={{ color: "#fff", marginTop: -1, marginRight: 2 }}
              ></i>
              <p className="m-0 text-white">Sim</p>
            </div>
          ) : (
            <div
              style={{
                width: 60,
                height: 30,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: 5,
              }}
              className="bg-danger"
            >
              <i
                style={{ color: "#fff", marginTop: -1, marginRight: 2 }}
                className="ph-thumbs-down-fill"
              ></i>
              <p className="m-0 text-white">Não</p>
            </div>
          ),
        dummy: item.fornecedor.CODIGO_FORN != "010055" && (
          <Link to={`/fechamentos/zcm/${item.NUM}`} className="btn btn-light">
            Mais Detalhes
          </Link>
        ),
      };
    });

    setOrders(ordersFormat);
  };

  useEffect(() => {
    if (closures && closures?.length != 0) {
      formatDataOrders();
    }
  }, [closures]);

  const onPageChange = e => {
    setCurrentPageIndex(e.currentPage);
  };

  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <Row>
            <div className="p-1 mb-3">
              <div className="d-flex align-items-center">
                <div className="me-2">
                  <i className="bx bx-list-ul text-primary h4"></i>
                </div>
                <div className="flex-1 overflow-hidden">
                  <h5 className="font-size-20 text-primary">FECHAMENTOS</h5>
                </div>
              </div>
            </div>
          </Row>
          {loading || loadingFilter ? (
            <Spinner style={{ margin: "0 50%" }} />
          ) : orders?.length != 0 ? (
            <>
              <DataTable
                data={orders}
                columns={columns}
                keyField="id"
                sizePerPage={30}
                isPaginite={false}
              />
              {total > 30 && (
                <Paginations
                  total={total}
                  pageSize={30}
                  pageNeighbours={1}
                  onPageChange={onPageChange}
                  defaultSelected={currentPageIndex}
                />
              )}
            </>
          ) : (
            <Alert color="info">Nenhum fechamento de RT encontrado.</Alert>
          )}
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default Closing;
