import { SettlementsActionTypes } from "./actionTypes";

const INIT_STATE = {
  success: false,
  loading: false,
  settlementsList: [],
  filters: {},
};

const Settlements = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SettlementsActionTypes.API_RESPONSE_SUCCESS:
      return {
        loading: false,
        success: true,
        settlementsList: action.payload.data,
      };

    case SettlementsActionTypes.API_RESPONSE_ERROR:
      return {
        ...state,
        loading: false,
        success: false,
      };

    case SettlementsActionTypes.GET_SETTLEMENTS_LIST:
      return {
        ...state,
        loading: true,
        success: false,
        filters: action.payload.filters,
      };
    default:
      return state;
  }
};

export default Settlements;
