import { call, put, takeEvery, all, fork } from "redux-saga/effects";

// Crypto Redux States
import { DashboardActionTypes } from "./actionTypes";
import { dashboardApiSuccess, dashboardApiFail } from "./action";

//Include Both Helper File with needed methods
import { getDashboardDetails as getDashboardDetailsApi } from "../../api/index";

function* getDashboardDetails({ payload: company }) {
  try {
    const response = yield call(getDashboardDetailsApi, company.company);
    yield put(
      dashboardApiSuccess(DashboardActionTypes.GET_DASHBOARD_DETAILS, response)
    );
  } catch (error) {
    yield put(
      dashboardApiFail(DashboardActionTypes.GET_DASHBOARD_DETAILS, error)
    );
  }
}
export function* watchGetDashboardDetails() {
  yield takeEvery(
    DashboardActionTypes.GET_DASHBOARD_DETAILS,
    getDashboardDetails
  );
}

function* dashboardSaga() {
  yield all([fork(watchGetDashboardDetails)]);
}

export default dashboardSaga;
