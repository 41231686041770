import React from "react";
import { Card, CardBody, Row, Col, Table } from "reactstrap";
import { useSelector } from "react-redux";
import SectionTitle from "./SectionTitle";
import ItemTable from "./ItemTable";
import BudgetItemTable from "./BudgetItemTable";
import { formato } from "../../utils";
import { useSolar } from "../../hooks";

const isSolar = useSolar();

const OrderItems = () => {
  const { budget, order } = useSelector(state => ({
    budget: state.Budget.active.data.orcamento,
    order: state.Order.orderActive.order,
  }));

  const cashbackVal =
    ((parseFloat(order.total) - parseFloat(order.C5_FRETE)) / 100) *
      Number(order?.PercentualRT) || 0;

  const serviceVal = order?.items_orcamento.reduce((accumulator, order) => {
    return parseFloat(accumulator) + parseFloat(order.ValServ);
  }, 0);

  const total = Number(order?.total || "").toLocaleString(
    "pt-br",
    formato
  );

  return (
    <Row>
      <Col>
        <SectionTitle title="Itens do pedido" />
        <Card>
          <CardBody>
            <Row>
              <Col>
                <Table borderless>
                  <tbody>
                    {(order?.items_orcamento || []).map((value, index) => {
                      return <ItemTable item={value} serviceVal={serviceVal} key={index} />;
                    })}

                    {isSolar && (
                      <tr style={{ textAlign: "center"}}>
                        <td>
                          <h6 style={{ fontSize: 16 }}>SKU</h6>
                        </td>
                        <td>
                          <h6 style={{ fontSize: 16 }}>Componente</h6>
                        </td>
                        <td>
                          <h6 style={{ fontSize: 16 }}>QTD</h6>
                        </td>
                        <td>
                          <h6 style={{ fontSize: 16 }}>Previsão</h6>
                        </td>
                      </tr>
                    )}

                    {isSolar &&
                      (budget?.new_items || []).map((value, index) => {
                        return <BudgetItemTable item={value} key={index} />;
                      })}

                    <tr style={{ borderTopWidth: 1, borderColor: "#D2D2D2" }}>
                      <td></td>
                      <td></td>
                      <td className="texto-td-itens texto-itens-azul">
                        CashBack
                      </td>
                      <td className="texto-itens-azul texto-td-bold">
                        <strong>
                          {"R$" +
                            cashbackVal.toFixed(2) +
                            " (" +
                            Number(order?.PercentualRT) +
                            "%)" || ""}
                        </strong>
                      </td>
                    </tr>
                    <tr style={{ borderTopWidth: 0.1, borderColor: "#F5F5F5" }}>
                      <td></td>
                      <td></td>
                      <td className="texto-td-itens">Desconto Médio</td>
                      <td className="texto-td-bold">
                        <strong>
                          {Number(order?.CJ_XDESVM || "").toLocaleString(
                            "pt-br",
                            formato
                          )}{" "}
                          ({parseFloat(order?.CJ_XDESPM).toFixed(1) || ""}%)
                        </strong>
                      </td>
                    </tr>
                    <tr style={{ borderTopWidth: 0.1, borderColor: "#F5F5F5" }}>
                      <td></td>
                      <td></td>
                      <td className="texto-td-itens">Frete</td>
                      <td className="texto-td-bold">
                        <strong>
                          {Number(order?.C5_FRETE || "").toLocaleString(
                            "pt-br",
                            formato
                          )}
                        </strong>
                      </td>
                    </tr>
                    <tr style={{ borderTopWidth: 0.1, borderColor: "#F5F5F5" }}>
                      <td></td>
                      <td></td>
                      <td className="texto-td-itens">Outros</td>
                      <td className="texto-td-bold">
                        <strong>
                          {Number(serviceVal || "").toLocaleString(
                            "pt-br",
                            formato
                          )}
                        </strong>
                      </td>
                    </tr>
                    <tr style={{ borderTopWidth: 0.1, borderColor: "#F5F5F5" }}>
                      <td></td>
                      <td></td>
                      <td className="texto-td-itens">Crédito</td>
                      <td className="texto-td-bold">
                        <strong>R$ 0000</strong>
                      </td>
                    </tr>
                    <tr style={{ borderTopWidth: 0.1, borderColor: "#F5F5F5" }}>
                      <td></td>
                      <td></td>
                      <td className="texto-td-itens texto-itens-verde">
                        Valor Total
                      </td>
                      <td className="texto-td-bold texto-itens-verde">
                        <strong>{total}</strong>
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

export default OrderItems;
