import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { warrantyDownloadApiSuccess, warrantyDownloadApiFail  } from "./action";
import { WarrantyDownloadActionTypes } from "./actionTypes";
import { 
  getWarrantyDownloads as getWarrantyDownloadsApi,
  sendWarrantyDownload as sendWarrantyDownloadApi, 
  deleteWarrantyDownload as deleteWarrantyDownloadApi 
} from "../../../api/warranty";

function* getWarrantyDownloads({ payload: manufacturerId }) {
  try {
    const response = yield call(getWarrantyDownloadsApi, manufacturerId);
    yield put(warrantyDownloadApiSuccess(WarrantyDownloadActionTypes.GET_WARRANTY_DOWNLOADS, response))
  } catch (error) {
    yield put(warrantyDownloadApiFail(WarrantyDownloadActionTypes.GET_WARRANTY_DOWNLOADS, error));
  }
}

function* sendWarrantyDownload({ payload: data }) {
  try {
    const response = yield call(sendWarrantyDownloadApi, data)
    yield put(warrantyDownloadApiSuccess(WarrantyDownloadActionTypes.SEND_WARRANTY_DOWNLOAD, response))
  } catch (error) {
    yield put(warrantyDownloadApiFail(WarrantyDownloadActionTypes.SEND_WARRANTY_DOWNLOAD, error));
  }
}

function* deleteWarrantyDownload({ payload: id }) {
  try {
    const response = yield call(deleteWarrantyDownloadApi, id);
    yield put(warrantyDownloadApiSuccess(WarrantyDownloadActionTypes.DELETE_WARRANTY_DOWNLOAD, response));
  } catch (error) {
    yield put(warrantyDownloadApiFail(WarrantyDownloadActionTypes.DELETE_WARRANTY_DOWNLOAD, error));
  }
}

export function* watchGetWarrantyDownloads() {
  yield takeEvery(
    WarrantyDownloadActionTypes.GET_WARRANTY_DOWNLOADS,
    getWarrantyDownloads
  )
}

export function* watchSendWarrantyDownload() {
  yield takeEvery(
    WarrantyDownloadActionTypes.SEND_WARRANTY_DOWNLOAD,
    sendWarrantyDownload
  )
}

export function* watchDeleteWarrantyDownload() {
  yield takeEvery(
    WarrantyDownloadActionTypes.DELETE_WARRANTY_DOWNLOAD,
    deleteWarrantyDownload
  )
}

function* warrantyDownloadSaga() {
  yield all([
    fork(watchGetWarrantyDownloads),
    fork(watchSendWarrantyDownload),
    fork(watchDeleteWarrantyDownload)
  ]);
}

export default warrantyDownloadSaga;