import styled from "styled-components";
import wave from '../../assets/images/elements/waves/wave_allcolors.svg';

export const TelaToda = styled.div`
  background-image: url(${wave});
  width: 100%;
  min-height: 100vh;
    background-size: 70px 47px;
  padding: 40px 120px;
  background-repeat: repeat;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  @media (max-width: 768px) {
    padding: 20px;
  }
`;


export const LogoDiv = styled.div`
  img {
    min-width: 130px;
  }
`;
export const Card = styled.div`
  width: 470px;
  background-color: #fff;
  border-radius: 7.5px;
  box-shadow: 0px 3.69417px 9.78589px #e0e5eb;
  padding: 70px;

  @media (max-width: 768px) {
    width: 100%;
    padding: 20px;
  }
`;

export const LinhaCard = styled.hr`
  margin-top: 30px;
`;

export const TituloCard = styled.h1`
  font-size: 24px;
  font-weight: 600;
  margin-left: -1px;
  margin-top: 30px;
  color: #0F172A;
`;

export const SubtituloCard = styled.p`
  margin-left: -1px;
  font-size: 16px;
  color: #0F172A;
  margin-bottom: 35px;
`;

export const InputLabel = styled.label`
  font-weight: 600;
  margin-bottom: 3px;
  color: #0F172A;
`;

export const Formulario = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  border: 1px solid #d2d2d2;
  width: fit-content;
  padding: 4px 24px;
  border-radius: 5px;
  width: 330px;
`;

export const Icone = styled.p`
  font-size: 22px;
  padding-right: 10px;
  border-right: 1px solid #d2d2d2;
  display: flex;
  margin-bottom: 0;
  color: #a8a8a8;
`;

export const IconEye = styled.div`
  position: absolute;
  right: 0;
  padding: 6px;
  font-size: 22px;
  display: flex;
  margin-bottom: 0;
  color: #a8a8a8;
  cursor: pointer;

  :hover {
    .ph-eye::before {
      color: #2D567B !important;
    }
    .ph-eye-slash::before {
      color: #2D567B !important;
    }
  }
`;

export const InputUser = styled.input`
  border: none;
  font-size: 16px;
  outline: none;
  margin-left: 20px;
  width: 200px;
`;

export const InputSenha = styled.label`
  font-weight: 600;
  margin-bottom: 3px;
  margin-top: 30px;
  font-size: 16px;
  color: #0F172A;
`;
export const CheckboxSenha = styled.div`
  margin-top: 15px;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
`;
export const Input = styled.input`
  margin-right: 4px;
`;

export const Memorizar = styled.p`
  color: #6d7989;
  margin-bottom: 0;
  margin-right: 27px;
`;

export const LinkRecuperar = styled.a`
  display: flex;
  align-items: center;
  color: #0F172A;
  margin-right: 4px;
  width: 165px;
  font-weight: bold;
`;
export const BotaoEntrar = styled.button`
  padding: 10px 132px;
  background-color: #2D567B;
  color: #fff;
  border: none;
  margin-top: 30px;
  font-size: 16px;
  border-radius: 5px;
  margin-bottom: 20px;
  width: 100%;
  height: 43px;
  transition: all 0.5 linear;
  &:hover {
    background-color: #7EACC4;
  }
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const BotaoCriar = styled.button`
  padding: 10px 47px;
  background-color: #FF601A;
  color: #fff;
  border: none;
  margin-top: 30px;
  font-size: 16px;
  border-radius: 5px;
  margin-bottom: 20px;
  width: 100%;
  text-transform: uppercase;
  height: 43px;
  transition: all 0.5 linear;
  &:hover {
    background-color: #FF9E73;
  }

  display: flex;
  align-items: center;
  justify-content: center;
`;

export const CriarConta = styled.p`
  display: flex;
  align-items: center;
  margin-bottom: 20;
  margin-left: 53px;
  font-size: 13px;
  color: #6d7989;
`;

export const LinkCriar = styled.a`
  margin-left: 5px;
  color: #24bdff;
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const LinhaRedes = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`;
export const TextoRedes = styled.p`
  margin-bottom: 0;
  color: #bdbdbd;
`;

export const TextoConta = styled.p`
  font-size: 24px;
  width: 200px;
  display: flex;
  text-align: start;
  font-weight: 600;
  color: #2D567B;
`;

export const ColCriarConta = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 0;
    padding: 5px; 
    background-color: rgb(255, 224, 211);
    border-radius: 15px 0 0 15px;
`;

export const TextoParceiro = styled.p`
  font-size: 13px;
  padding: 6px;
  font-weight: 500;
  color: #2D567B;
  border-radius: 4px;
  background-color: #BBCAD2;
`;

export const Hr = styled.hr`
  width: 106px;
  color: #bdbdbd;
`;
export const RedesSociais = styled.div`
  display: flex;
  justify-content: space-around;
`;

export const BtnSocial = styled.button`
  border-radius: 50%;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  width: 50px;
  margin: 5px;
  min-height: 50px;
  margin-top: 15px;
  color: ${props => props.color};
  border: 2px solid ${props => props.color};
`;

export const BarraLateral = styled.div`
  position: fixed;
  max-width: 316px;
  min-width: 22vw;
  min-height: 100vh;
  right: 0;
  top: 0;
  background-color: #7EACC4;
  display: flex;
  align-items: center;

  @media (max-width: 768px) {
    display: none;
  }
`;

export const ImgSofa = styled.img`
  width: 100%;
  transform: scale(1.5) translateX(-100px);
`;

export const DivBotoes = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const BotaoVoltar = styled.button`
  border: 1px solid #2D567B;
  background-color: #fff;
  color: #2D567B;
  margin-top: 40px;
  padding: 13px 43px;
  border-radius: 7px;
  color: #2D567B;
  font-weight: bold;
`;

export const BotaoEnviar = styled.button`
  border: 1px solid #2D567B;
  background-color: #2D567B;
  color: #ffff;
  margin-top: 40px;
  padding: 13px 43px;
  border-radius: 7px;
  font-weight: bold;
`;

export const BotaoEnviarLink = styled.a`
  border: 1px solid #2D567B;
  background-color: #2D567B;
  color: #ffff;
  margin-top: 40px;
  padding: 13px 43px;
  border-radius: 7px;
  font-weight: bold;
`;

export const AgrupaModal = styled.div`
  padding: 40px 70px;

  @media (max-width: 768px) {
    padding: 20px;
  }
`;

export const TituloModal = styled.h1`
  font-weight: bold;
  font-size: 24px;
  margin-top: 17px;
  margin-bottom: 24px;
  color: #404b5a;
`;

export const HrModal = styled.hr`
  width: 330px;
`;

export const BtnModal = styled.button`
  border: 1px solid #2D567B;
  background-color: #2D567B;
  color: #ffff;
  margin-top: 40px;
  padding: 13px 43px;
  border-radius: 7px;
  font-weight: bold;
  width: fit-content;
  margin-left: auto;
  display: block;
`;
export const AgrupaLinha = styled.div`
  display: flex;
  align-items: 100%;
  align-items: flex-start;
  justify-content: space-between;
`;

export const AgrupaColuna = styled.div`
  display: flex;
  flex-direction: column;
  width: ${props => props.size}%;
`;

export const Etiqueta = styled.label`
  font-weight: 500;
  font-size: 16px;
  color: #696969;
  margin-top: 20px;
`;

export const InputModal = styled.input`
  width: 100%;
  padding: 4px;
  border: 1px solid #d2d2d2;
  border-radius: 6px;
`;

export const SelectModal = styled.select`
  width: 100%;
  padding: 4px;
  border: 1px solid #d2d2d2;
  border-radius: 6px;
`;
