import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import faker from "faker";
import { Card, CardBody } from "reactstrap";
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);
const Performance = () => {
  const options = {
    plugins: {
      title: {
        display: true,
        text: "Histórico de Filas e Status e todos os Pedidos ",
        align: "start",
        font: {
          size: 20,
        },
        padding: {
          bottom: 47,
        },
      },
      legend: {
        display: false,
      },
    },
    responsive: true,
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
      },
    },
  };
  const labels = [
    "Análise do Pedido",
    "Análise de crédito",
    "Faturamento",
    "Expedição",
    "Em Trânsito",
    "Entregue",
  ];
  const data = {
    labels,
    datasets: [
      {
        label: "Exemplo 1",
        data: labels.map(() => faker.datatype.number({ min: 0, max: 100 })),
        backgroundColor: "#9de08a",
      },
      {
        label: "Exemplo 2",
        data: labels.map(() => faker.datatype.number({ min: 0, max: 100 })),
        backgroundColor: "#4fc12e",
      },
      {
        label: "Exemplo 3",
        data: labels.map(() => faker.datatype.number({ min: 0, max: 100 })),
        backgroundColor: "#229600",
      },
      {
        label: "Exemplo 4",
        data: labels.map(() => faker.datatype.number({ min: 0, max: 100 })),
        backgroundColor: "#145800",
      },
    ],
  };

  return (
    <div>
      <h4 className="performance-titulo">Performance A.Dias</h4>
      <Card>
        <CardBody>
          <Bar options={options} data={data} />,
        </CardBody>
      </Card>
    </div>
  );
};

export default Performance;
