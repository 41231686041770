import React from "react";
export default function IconSun(props) {
  const colorFill = props?.colorFill?.replaceAll("#", "") || "none";
  const colorStroke = props?.colorStroke?.replaceAll("#", "") || "#F4B200";

  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill={colorFill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.5 17C14.5376 17 17 14.5376 17 11.5C17 8.46243 14.5376 6 11.5 6C8.46243 6 6 8.46243 6 11.5C6 14.5376 8.46243 17 11.5 17Z"
        stroke={colorStroke}
        strokeWidth="1.38227"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11 4V2"
        stroke={colorStroke}
        strokeWidth="1.38227"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5 5L4 4"
        stroke={colorStroke}
        strokeWidth="1.38227"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4 11H2"
        stroke={colorStroke}
        strokeWidth="1.38227"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5 16L4 17"
        stroke={colorStroke}
        strokeWidth="1.38227"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11 19V21"
        stroke={colorStroke}
        strokeWidth="1.38227"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16 16L17 17"
        stroke={colorStroke}
        strokeWidth="1.38227"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19 11H21"
        stroke={colorStroke}
        strokeWidth="1.38227"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16 5L17 4"
        stroke={colorStroke}
        strokeWidth="1.38227"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
