import styled from "styled-components";
import { useSolar } from "../../../hooks";
const isSolar = useSolar();


export const SideSubMenu = styled.div`
  z-index: auto;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 260px;
  background-color: ${isSolar ? props => props.theme.colors.lightOrange : props => props.theme.colors.lightBlue};
  border-top-right-radius: 5px;
  .sub-container {
    position: absolute;
    top: 6px;
    width: 200px;
    height: auto;
    border-bottom-right-radius: 5px;
    background-color: ${isSolar ? props => props.theme.colors.white : props => props.theme.colors.white};
    overflow: hidden;
    box-shadow: 3px 5px 4px rgb(15 34 58 / 12%);
    z-index: 1;
    .title {
      width: 100%;
      position: relative;
      display: flex;
      align-items: center;
      width: 100%;
      height: 39px;
      padding-bottom: 5px;
      padding-left: 5px;
      background-color: ${isSolar ? props => props.theme.colors.lightOrange : props => props.theme.colors.lightBlue};
      color: ${props => props.theme.colors.white};
      font-family: "WorkSans", sans-serif;
      font-size: 15px;
    }
    ul {
      width: 100%;
      height: 100%;
      padding: ${props =>
        props.dataSideSubMenuLenght > 0 ? "8px 10px" : "0px"};
      padding-top: ${props =>
        props.dataSideSubMenuLenght > 0 ? "10px" : "0px"};
      li > a {
        margin: 0;
        display: flex;
        justify-content: flex-start;
        font-family: "WorkSans", sans-serif;
        font-size: 14px;
        font-weight: normal;
        padding: 10px 5px;
        color: ${props => props.theme.colors.grey};
        height: auto !important;
      }
    }
  }
`;
