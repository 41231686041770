import React from "react";
export default function IconHouse(props) {
  const colorFill = props?.colorFill?.replaceAll("#", "") || "none";
  const colorStroke = props?.colorStroke || "#000";
  const width = props?.width || 21;
  const height = props?.height || 21;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 21 21"
      fill={colorFill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.9091 16.3377V12.3642C11.9091 12.1885 11.842 12.0201 11.7227 11.8959C11.6034 11.7717 11.4415 11.7019 11.2727 11.7019H8.72727C8.5585 11.7019 8.39664 11.7717 8.2773 11.8959C8.15795 12.0201 8.09091 12.1885 8.09091 12.3642V16.3377C8.09091 16.5134 8.02386 16.6818 7.90452 16.806C7.78518 16.9302 7.62332 17 7.45455 17H3.63636C3.46759 17 3.30573 16.9302 3.18639 16.806C3.06705 16.6818 3 16.5134 3 16.3377V8.68035C3.00142 8.5887 3.02046 8.49827 3.05598 8.41439C3.09149 8.3305 3.14278 8.25487 3.20682 8.19193L9.57045 2.17363C9.68777 2.06194 9.841 2 10 2C10.159 2 10.3122 2.06194 10.4295 2.17363L16.7932 8.19193C16.8572 8.25487 16.9085 8.3305 16.944 8.41439C16.9795 8.49827 16.9986 8.5887 17 8.68035V16.3377C17 16.5134 16.933 16.6818 16.8136 16.806C16.6943 16.9302 16.5324 17 16.3636 17H12.5455C12.3767 17 12.2148 16.9302 12.0955 16.806C11.9761 16.6818 11.9091 16.5134 11.9091 16.3377Z"
        stroke={colorStroke}
        strokeWidth="1.25799"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
