import * as Yup from "yup";

export const inversor = "inversor";
export const modulo = "modulo";
export const peca = "peca";

export const grids = [
  { name: "MONOFÁSICO", value: "monofasico" },
  { name: "TRIFÁSICO", value: "trifasico" },
];

export const initialValuesToFormik = {
  nome: "",
  modelo: "",
  tipoForm: inversor,
  fabricante: "",
  potencia: "",
  potenciaOverload: "",
  overloadPorcentagem: "",
  voltagemMaxPorEntrada: "",
  voltagemMinPorEntrada: "",
  correnteMaxPorEntrada: "",
  correnteDeCurto: "",
  numeroDeEntradas: "",
  altura: "",
  comprimento: "",
  largura: "",
  peso: "",
  tensaoCa: "",
  grid: "",
  numeroDeMPPT: "",
  stringbox1: "",
  stringbox2: "",
  custo: "",
  preco: "",
  fusivel: "",
  qtdFusivel: "",
  //DADOS ESPECIFICOS DO MODULO
  descricao: "",
  voltagemDeCircuitoAberto: "",
  dimensoes: "",
  garantia: "",
  fornecedor: "",
  celulas: "",
  //DADOS ESPECIFICOS DA PECA
  categoria: "",
  tamanho: "",
  codigoFornecedor: "",
  estoque: "",
  codigo: "",
};

export const initialValuesToFormikParts = type => {
  if (type == inversor) {
    return {
      nome: "",
      modelo: "",
      fabricante: "",
      potencia: "",
      potenciaOverload: "",
      overloadPorcentagem: "",
      voltagemMaxPorEntrada: "",
      voltagemMinPorEntrada: "",
      correnteMaxPorEntrada: "",
      correnteDeCurto: "",
      numeroDeEntradas: "",
      altura: "",
      comprimento: "",
      largura: "",
      peso: "",
      tensaoCa: "",
      grid: "",
      numeroDeMPPT: "",
      stringbox1: "",
      stringbox2: "",
      custo: "",
      preco: "",
      fusivel: "",
      qtdFusivel: "",
    };
  } else if (type == modulo) {
    return {
      nome: "",
      fabricante: "",
      descricao: "",
      potencia: "",
      voltagemDeCircuitoAberto: "",
      correnteDeCurto: "",
      altura: "",
      comprimento: "",
      largura: "",
      peso: "",
      custo: "",
      preco: "",
      dimensoes: "",
      garantia: "",
      fornecedor: "",
      celulas: "",
    };
  } else {
    return {
      nome: "",
      categoria: "",
      tamanho: "",
      custo: "",
      preco: "",
      codigoFornecedor: "",
      estoque: "",
      codigo: "",
    };
  }
};

export const RegisterSchema = Yup.object().shape({
  nome: Yup.string().required("Campo obrigatório"),
  tipoForm: Yup.string().required("Campo obrigatório"),
  modelo: Yup.string().when("tipoForm", {
    is: value => value != peca,
    then: Yup.string().required("Campo obrigatório"),
    otherwise: Yup.string(),
  }),
  fabricante: Yup.string().when("tipoForm", {
    is: value => value != peca,
    then: Yup.string().required("Campo obrigatório"),
    otherwise: Yup.string(),
  }),
  potencia: Yup.string().when("tipoForm", {
    is: value => value != peca,
    then: Yup.string().required("Campo obrigatório"),
    otherwise: Yup.string(),
  }),
  potenciaOverload: Yup.string().when("tipoForm", {
    is: value => value == inversor,
    then: Yup.string().required("Campo obrigatório"),
    otherwise: Yup.string(),
  }),
  overloadPorcentagem: Yup.string().when("tipoForm", {
    is: value => value == inversor,
    then: Yup.string().required("Campo obrigatório"),
    otherwise: Yup.string(),
  }),
  voltagemMaxPorEntrada: Yup.string().when("tipoForm", {
    is: value => value == inversor,
    then: Yup.string().required("Campo obrigatório"),
    otherwise: Yup.string(),
  }),
  voltagemMinPorEntrada: Yup.string().when("tipoForm", {
    is: value => value == inversor,
    then: Yup.string().required("Campo obrigatório"),
    otherwise: Yup.string(),
  }),
  correnteMaxPorEntrada: Yup.string().when("tipoForm", {
    is: value => value == inversor,
    then: Yup.string().required("Campo obrigatório"),
    otherwise: Yup.string(),
  }),
  correnteDeCurto: Yup.string().when("tipoForm", {
    is: value => value != peca,
    then: Yup.string().required("Campo obrigatório"),
    otherwise: Yup.string(),
  }),
  numeroDeEntradas: Yup.string().when("tipoForm", {
    is: value => value == inversor,
    then: Yup.string().required("Campo obrigatório"),
    otherwise: Yup.string(),
  }),
  altura: Yup.string().when("tipoForm", {
    is: value => value != peca,
    then: Yup.string().required("Campo obrigatório"),
    otherwise: Yup.string(),
  }),
  comprimento: Yup.string().when("tipoForm", {
    is: value => value != peca,
    then: Yup.string().required("Campo obrigatório"),
    otherwise: Yup.string(),
  }),
  largura: Yup.string().when("tipoForm", {
    is: value => value != peca,
    then: Yup.string().required("Campo obrigatório"),
    otherwise: Yup.string(),
  }),
  peso: Yup.string().when("tipoForm", {
    is: value => value != peca,
    then: Yup.string().required("Campo obrigatório"),
    otherwise: Yup.string(),
  }),
  tensaoCa: Yup.string().when("tipoForm", {
    is: value => value == inversor,
    then: Yup.string().required("Campo obrigatório"),
    otherwise: Yup.string(),
  }),
  grid: Yup.string().when("tipoForm", {
    is: value => value == inversor,
    then: Yup.string().required("Campo obrigatório"),
    otherwise: Yup.string(),
  }),
  numeroDeMPPT: Yup.string().when("tipoForm", {
    is: value => value == inversor,
    then: Yup.string().required("Campo obrigatório"),
    otherwise: Yup.string(),
  }),
  stringbox1: Yup.string().when("tipoForm", {
    is: value => value == inversor,
    then: Yup.string().required("Campo obrigatório"),
    otherwise: Yup.string(),
  }),
  stringbox2: Yup.string().when("tipoForm", {
    is: value => value == inversor,
    then: Yup.string().required("Campo obrigatório"),
    otherwise: Yup.string(),
  }),

  custo: Yup.number("Campo aceita somente números").required(
    "Campo obrigatório"
  ),
  preco: Yup.number("Campo aceita somente números").required(
    "Campo obrigatório"
  ),
  fusivel: Yup.string().when("tipoForm", {
    is: value => value == inversor,
    then: Yup.string().required("Campo obrigatório"),
    otherwise: Yup.string(),
  }),
  qtdFusivel: Yup.string().when("tipoForm", {
    is: value => value == inversor,
    then: Yup.string().required("Campo obrigatório"),
    otherwise: Yup.string(),
  }),
  descricao: Yup.string().when("tipoForm", {
    is: value => value == modulo,
    then: Yup.string().required("Campo obrigatório"),
    otherwise: Yup.string(),
  }),
  voltagemDeCircuitoAberto: Yup.string().when("tipoForm", {
    is: value => value == modulo,
    then: Yup.string().required("Campo obrigatório"),
    otherwise: Yup.string(),
  }),
  dimensoes: Yup.string().when("tipoForm", {
    is: value => value == modulo,
    then: Yup.string().required("Campo obrigatório"),
    otherwise: Yup.string(),
  }),
  garantia: Yup.string().when("tipoForm", {
    is: value => value == modulo,
    then: Yup.string().required("Campo obrigatório"),
    otherwise: Yup.string(),
  }),
  fornecedor: Yup.string().when("tipoForm", {
    is: value => value == modulo,
    then: Yup.string().required("Campo obrigatório"),
    otherwise: Yup.string(),
  }),
  celulas: Yup.string().when("tipoForm", {
    is: value => value == modulo,
    then: Yup.string().required("Campo obrigatório"),
    otherwise: Yup.string(),
  }),
  categoria: Yup.string().when("tipoForm", {
    is: value => value == peca,
    then: Yup.string().required("Campo obrigatório"),
    otherwise: Yup.string(),
  }),
  tamanho: Yup.string().when("tipoForm", {
    is: value => value == peca,
    then: Yup.string().required("Campo obrigatório"),
    otherwise: Yup.string(),
  }),
  codigoFornecedor: Yup.string().when("tipoForm", {
    is: value => value == peca,
    then: Yup.string().required("Campo obrigatório"),
    otherwise: Yup.string(),
  }),
  estoque: Yup.string().when("tipoForm", {
    is: value => value == peca,
    then: Yup.string().required("Campo obrigatório"),
    otherwise: Yup.string(),
  }),
  codigo: Yup.string().when("tipoForm", {
    is: value => value == peca,
    then: Yup.string().required("Campo obrigatório"),
    otherwise: Yup.string(),
  }),
});
