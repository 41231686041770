import React from "react";
import { Card, CardBody, Row, Col } from "reactstrap";
import { Link } from "react-router-dom";

// components
import SvgIcon from "../../components/SvgIcon";
import RoundAlert from "../../components/RoundAlert";
import { useColors } from "../../hooks/useColors";
import { useSolar } from "../../hooks/SolarHooks";
import { useSelector } from "react-redux";
import Loading from "./Loading";

const OrderStatus = ({ data }) => {
  const isSolar = useSolar();
  const status_pedidos = [];
  let ped = Object.values(data?.status_pedidos?.values || []);
  let ped_keys = Object.keys(data?.status_pedidos?.values || []);
  for (let i = 0; i < ped.length; i++) {
    status_pedidos.push({ value: ped[i], type: ped_keys[i] });
  }
  const { colorDefault } = useColors();
  const { loadingDashboard } = useSelector(state => ({
    loadingDashboard: state.Dashboard.getDashboardDetailsLoading,
  }));
  const fases = {
    aguardando_pagto: {
      name: " Pagamento",
      icon: "coins",
      link: isSolar ? "/solar/pedidos/fase/A,B,C,D" : "/pedidos/fase/A,B,C,D",
    },
    aguardando_faturamento: {
      name: " Faturamento",
      icon: "database",
      link: isSolar ? "/solar/pedidos/fase/F" : "/pedidos/fase/F",
    },
    aguardando_expedicao: {
      name: " Expedição",
      icon: "cart",
      link: isSolar ? "/solar/pedidos/fase/G" : "/pedidos/fase/G",
    },
    analise_credito: {
      name: " Ánalise",
      icon: "credit-card",
      link: isSolar ? "/solar/pedidos/fase/C" : "/pedidos/fase/C",
    },
    expedidos: {
      name: " Rota",
      icon: "truck-percent",
      link: isSolar ? "/solar/pedidos/fase/H" : "/pedidos/fase/H",
    },
    entregue: {
      name: "Entregue",
      icon: "clipboard",
      link: isSolar ? "/solar/pedidos/fase/J" : "/pedidos/fase/J",
    },
    orcamentos: {
      name: "Orçamentos",
      icon: "file-text",
      link: isSolar ? "/solar/orcamentos" : "/orcamentos",
    },
  };

  return (
    <div className="">
      <Card>
        <CardBody>
          <h5 className="mb-5 titulos-gerais">Status</h5>
          <div className="mb-4">
            <Row>
              {(status_pedidos || []).map((value, index) => (
                <Col key={index}>
                  <div className="text-center">
                    <h4 className="mb-3">
                      {loadingDashboard ? <Loading /> : value.value}
                    </h4>
                    <div className="h-100 w-100 d-flex align-items-center justify-content-center mb-4">
                      <div
                        className="h-100 d-flex align-items-center icone-status"
                        /* style={{ stroke: colorDefault }} */
                      >
                        <SvgIcon
                          name={fases[value.type].icon}
                          className="me-1 h-100 d-flex align-items-center"
                          color={colorDefault}
                        />
                        <p className="mb-0 fw-bold font-size-13">
                          {fases[value.type].name}
                        </p>
                      </div>
                    </div>
                    <Link
                      to={`${fases[value.type].link}`}
                      className="btn botao-ver"
                      style={{ border: `1px solid ${colorDefault}` }}
                    >
                      VER
                    </Link>
                  </div>
                </Col>
              ))}
            </Row>
          </div>
          <div className="pt-2 d-none">
            <RoundAlert color="warning">
              <i className="mdi mdi-information-outline font-size-18 me-2" />{" "}
              Pedidos Fora do Prazo
              <i className="mdi mdi-arrow-right ms-2" />
            </RoundAlert>
            <RoundAlert color="danger" className="mb-0">
              <i className="mdi mdi-information-outline font-size-18 me-2" />{" "}
              SLA atrasado - clique para ver
              <i className="mdi mdi-arrow-right ms-2" />
            </RoundAlert>
          </div>
        </CardBody>
      </Card>
    </div>
  );
};

export default OrderStatus;
