import { WarrantyDownloadActionTypes } from "./actionTypes"

const INIT_STATE = {
  list: {
    error: null,
    loading: false,
    downloads: []
  },
  delete: {
    error: null,
    loading: false,
    successDeleted: false
  },
  send: {
    error: null,
    loading: false,
    downloadSent: null
  }
}

const WarrantyDownload = (state = INIT_STATE, action) => {
  switch (action.type) {
    case WarrantyDownloadActionTypes.API_RESPONSE_SUCCESS:
      switch (action.payload.actionType) {
        case WarrantyDownloadActionTypes.GET_WARRANTY_DOWNLOADS:
          return {
            ...state,
            list: {
              error: null,
              loading: false,
              downloads: action.payload.data
            }
          };
        case WarrantyDownloadActionTypes.SEND_WARRANTY_DOWNLOAD:
          return {
            ...state,
            send: {
              error: null,
              loading: false,
              downloadSent: action.payload.data
            }
          };

        case WarrantyDownloadActionTypes.DELETE_WARRANTY_DOWNLOAD:
          return {
            ...state,
            delete: {
              error: null,
              loading: false,
              successDeleted: true
            }
          };

        default:
          return state;
      }

    case WarrantyDownloadActionTypes.API_RESPONSE_FAIL:
      switch (action.payload.actionType) {
        case WarrantyDownloadActionTypes.SEND_WARRANTY_DOWNLOAD:
          return {
            ...state,
            send: {
              error: action.payload.error,
              loading: false,
              downloadSent: null
            }
          };

        case WarrantyDownloadActionTypes.DELETE_WARRANTY_DOWNLOAD:
          return {
            ...state,
            delete: {
              error: action.payload.error,
              loading: false,
              successDeleted: false
            }
          };

        default:
          return state;
      }

    case WarrantyDownloadActionTypes.SEND_WARRANTY_DOWNLOAD:
      return {
        ...state,
        send: {
          error: null,
          downloadSent: null,
          loading: true
        }
      };

    case WarrantyDownloadActionTypes.DELETE_WARRANTY_DOWNLOAD:
      return {
        ...state,
        delete: {
          error: null,
          loading: true,
          successDeleted: false
        }
      };

    case WarrantyDownloadActionTypes.GET_WARRANTY_DOWNLOADS:
      return {
        list: {
          downloads: [...state.list.downloads],
          error: null,
          loading: true,
        },
        delete: {
          error: null,
          loading: false,
          successDeleted: false
        },
        send: {
          error: null,
          loading: false,
          downloadSent: null
        }
      };


    default:
      return state;
  }
}

export default WarrantyDownload;