import React from "react";
import { Card, CardBody, Col, Row } from "reactstrap";

// utils
import { nl2br } from "../../../utils/index";

// components
import Loader from "../../../components/Loader";
import Comments from "./Comments";
import Files from "./Files";

const VideoDetails = ({
  videoId,
  video,
  comments,
  files,
  getTvvideoDetailsLoading,
}) => {
  const title = video && video.name ? `TV A.Dias - ${video.name}` : "TV A.Dias";
  const youtube_id = video && video.youtube_id ? video.youtube_id : "";
  const url = `https://www.youtube.com/embed/${youtube_id}`;
  const youtubeData = video.json_youtube
    ? JSON.parse(video.json_youtube)
    : null;
  const description = youtubeData?.items[0]?.snippet?.localized?.description
    ? youtubeData.items[0].snippet.localized.description
    : null;
  const formattedDescription = nl2br(description);

  const hasFiles = files && (files || []).length;
  return (
    <Card>
      <CardBody className="position-relative">
        <h5 className="mb-4 fw-bold">{title}</h5>
        {getTvvideoDetailsLoading && <Loader />}
        <Row className="justify-content-center">
          <Col xs={12} lg={8}>
            <div className="mb-4 embed-responsive embed-responsive-21by9 ratio ratio-21x9">
              <iframe
                title={title}
                className="embed-responsive-item"
                src={url}
                frameBorder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
            </div>
          </Col>

          {description ? (
            <Col lg={12}>
              <h5 className="font-size-16">Mais Informações</h5>
              <div
                className="mb-4"
                dangerouslySetInnerHTML={{ __html: formattedDescription }}
              />
            </Col>
          ) : null}
        </Row>
        <Row>
          <Col md={hasFiles ? 8 : 12}>
            <Comments comments={comments} videoId={videoId} />
          </Col>
          {hasFiles ? (
            <Col md={4}>
              <Files files={files} />
            </Col>
          ) : null}
        </Row>
      </CardBody>
    </Card>
  );
};

export default VideoDetails;
