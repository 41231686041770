import React, { useState, useEffect } from "react";
import { Redirect, Route } from "react-router-dom";

// api core
import { setAuthorization } from "../api/apiCore";
import { setAuthorization as setAuthorization2 } from "../api/apiCore2";
import { useProfile } from "../hooks/UserHooks";

//loader
import Loader from "../components/Loader";

const AuthProtected = props => {
  const { userProfile, loading, token } = useProfile();
  const [tokenLoading, setTokenLoading] = useState(true);

  if (!userProfile && loading && !token) {
    return (
      <Redirect to={{ pathname: "/login", state: { from: props.location } }} />
    );
  }

  useEffect(() => {
    if (userProfile && !loading && token) {
      setAuthorization(token);
      setAuthorization2(token);
      setTokenLoading(false);
    } 
  }, [token, userProfile, loading]);

  return <>{!tokenLoading ? props.children : <Loader />}</>;
};

const AccessRoute = ({ component: Component, ...rest }) => {
  // const { token } = useProfile();

  // useEffect(() => {
  //     setAuthorization("");
  //     setAuthorization2("");
  // }, [token]);

  return (
    <Route
      {...rest}
      render={props => {
        return (
          <>
            <Component {...props} />
          </>
        );
      }}
    />
  );
};

export { AuthProtected, AccessRoute };
