import React, { useState } from "react";
import { Card, CardBody, Row, Col, Alert } from "reactstrap";
import { Link } from "react-router-dom";

// components
import CustomTable from "../../../components/CustomTable";
import Loader from "../../../components/Loader";
import Paginations from "../../../components/Paginations";
import { HeadCol } from "../../../components/Columns";
import { CustomerNo, Name, CPFCNPJ, Vendedor, Action } from "./Columns";
import "../../../components/styles.css"

import { useSolar } from "../../../hooks";

const isSolar = useSolar();
import ModalRegisterClient from "./ModalRegisterClient";

const ListTable = ({
  data,
  getCustomersLoading,
  selectedPageSize,
  defaultPage,
  onPageChange,
}) => {
  const total = data.total || 0;
  const [openModalRegisterClient, setOpenModalRegisterClient] = useState(false);

  const columns = [
    {
      Header: <HeadCol className="w-50" label="Código" />,
      accessor: "CODCLIENTE",
      Cell: function colRenderer(props) {
        return <CustomerNo row={props} />;
      },
    },
    {
      Header: <HeadCol label="Nome" />,
      accessor: "N_COMPLETO",
      Cell: function colRenderer(props) {
        return <Name className="text-ellipsis-columns" row={props} />;
      },
      minWidth: 300,  
    },
    {
      Header: <HeadCol label="CPF / CNPJ" />,
      accessor: "CPFCNPJ",
      Cell: function colRenderer(props) {
        return <CPFCNPJ row={props} />;
      },
      minWidth: 180,  
    },
    {
      Header: <HeadCol label="Vendedor" />,
      accessor: "VENDEDOR",
      Cell: function colRenderer(props) {
        return <Vendedor row={props} />;
      },
      minWidth: 290,  
    },
    {
      Header: <HeadCol label="" />,
      accessor: "action",
      Cell: function colRenderer(props) {
        return <Action row={props} />;
      },
    },
  ];

  const openModal = () => {
    setOpenModalRegisterClient(true);
  };
  return (
    <>
      <Card>
        <CardBody>
          <Row>
            <Col md={6}>
              <h5>
                <i className="fa fa-list"></i> Clientes
              </h5>
            </Col>
            <Col md={6}>
              <Link onClick={openModal} className="btn float-end" style={{backgroundColor: isSolar ? '#FF9E73' : "#7EACC4", color: isSolar ?  "#FFF" : "#2D567B" }}>
                Criar Cliente
              </Link>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col xs={12}>
              <div className="mt-3 position-relative">
                {getCustomersLoading && <Loader />}
                {data["data"]?.length > 0 ? (
                  <CustomTable
                    columns={columns}
                    data={data["data"]}
                    classNames="align-middle custom-table table-nowrap table-borderless table-check"
                    selectable={false}
                    idField={"CODCLIENTE"}
                  />
                ) : !getCustomersLoading ? (
                  <Alert color="info">Nenhum dado foi encontrado</Alert>
                ) : (
                  <div style={{ height: 60 }} />
                )}
                {total > 0 ? (
                  <Paginations
                    total={total}
                    pageSize={selectedPageSize}
                    pageNeighbours={1}
                    onPageChange={onPageChange}
                    defaultSelected={defaultPage}
                    color={"#BBCAD2"}
                  />
                ) : null}
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>
      <ModalRegisterClient
        openModalRegisterClient={openModalRegisterClient}
        setOpenModalRegisterClient={setOpenModalRegisterClient}
      />{" "}
    </>
  );
};

export default ListTable;
