import React, { useEffect, useState } from "react";
import { Container, Row, Card, CardBody, Col } from "reactstrap";
import { useSelector, useDispatch } from "react-redux";

//import Breadcrumb
import Breadcrumb from "../../../components/Breadcrumb";
import "./ConsultWarranty.css";

// actions
import { getWarrantyList } from "../../../store/actions";

//import component
import FilterResults from "./FilterResults";
import WarrantyRequest from "./WarrantyRequest";
import Widgets from "./Widgets";
import { useLocation } from "react-router-dom";

const Warranty = () => {
  const dispatch = useDispatch();
  const { warrantyDetails, getWarrantyLoading } = useSelector(state => ({
    warrantyDetails: state.Warranty.warrantyDetails,
    getWarrantyLoading: state.Warranty.getWarrantyLoading,
  }));
  const [isHidden, setIsHidden] = useState(false);
  const [temConsulta, setTemConsulta] = useState(true);
  const { search } = useLocation();
  var query = useQuery();
  const idSituacao = query.get("idSituacao");
  function useQuery() {
    return React.useMemo(() => new URLSearchParams(search), [search]);
  }
  /*
  get warranty details
  */
  useEffect(() => {
    if (!idSituacao) {
      dispatch(getWarrantyList());
    }
  }, [dispatch]);

  /*
  filters
  */
  const onApplyFilters = filters => {
    dispatch(getWarrantyList({ ...filters }));
  };

  return (
    <React.Fragment>
      <div className="page-content ConsultWarranty">
        <Container>
          {/* Render Breadcrumb */}
          <Breadcrumb
            title="Solicitações On-line"
            breadcrumbItem="Consultar Garantia"
          />
          <Widgets widgetData={warrantyDetails["statsResume"] || []} />

          <Row>
            <Col>
              <Card>
                <CardBody>
                  <div className="p-1">
                    <div className="d-flex align-items-center">
                      <div className="me-2">
                        <i className="bx bxs-filter-alt h4"></i>
                      </div>
                      <div className="flex-1 overflow-hidden">
                        <h5 className="font-size-20">Filtrar Resultados</h5>
                      </div>
                    </div>
                  </div>
                  <Row>
                    <FilterResults
                      onApplyFilters={onApplyFilters}
                      manufacturer={warrantyDetails["manufacturer"] || []}
                      statusOptions={warrantyDetails["status"] || []}
                      query={query}
                    />
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
          {temConsulta && (
            <Row>
              <Col>
                <Card>
                  <CardBody className="p-0">
                    <div className="p-1">
                      <div className="d-flex align-items-center">
                        <div className="mx-3 my-3">
                          <i className="bx bxs-lock-alt h4"></i>
                        </div>
                        <div className="flex-1 overflow-hidden">
                          <h5 className="font-size-20">
                            Solicitação de Garantia
                          </h5>
                        </div>
                        <div className="engloba-icones">
                          <a
                            href="#"
                            onClick={e => {
                              e.preventDefault();
                              setIsHidden(!isHidden);
                            }}
                          >
                            {isHidden ? (
                              <i className="ph-caret-down"></i>
                            ) : (
                              <i className="ph-caret-up"></i>
                            )}
                          </a>

                          <a href="#">
                            <i className="ph-wrench"> </i>
                          </a>
                          <a href="#">
                            <i className="ph-arrows-clockwise"> </i>
                          </a>

                          <a
                            href="#"
                            onClick={e => {
                              e.preventDefault();
                              setTemConsulta(!temConsulta);
                            }}
                          >
                            <i className="ph-x"></i>
                          </a>
                        </div>
                      </div>
                      <hr className="linha-solicitacao" />
                    </div>
                    <Row hidden={isHidden}>
                      <Col md={12}>
                        <WarrantyRequest
                          data={warrantyDetails["warranty"] || []}
                          getWarrantyLoading={getWarrantyLoading}
                        />
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Warranty;
