import React, { useState, useEffect } from "react";
import { CardBody, Col, Row, Card, Input, Button, Spinner } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { removeFromCart } from "../../store/actions";
import { formato } from "../../utils";
import { toast } from "react-toastify";
import { filiais } from "../../utils/filiais";
import { Minus, Plus, Trash } from "phosphor-react";
import InputMask from "react-input-mask";
import { useDataLayer } from "../../hooks/DataLayerHooks";

const AddedItem = ({
  item,
  addValTot,
  valores,
  setItensCarrinho,
  itensCarrinho,
  addValArqu,
  valArquiteto,
  addValorRt,
  valorRt,
  setValServ,
  setSendValServ,
  setPercentResumo,
  setQtdAdd,
  addValTotTabela,
  valoresTabela,
  totalQuantity,
  addTotalQuantity,
  removeValTot
}) => {
  const dispatch = useDispatch();
  const { vendedor, findedItems, addedItems, frete, ocultarRt, comRt } = useSelector(
    state => ({
      vendedor: state.Order.newOrder.newOrder.vendedor,
      findedItems: state.Order.newOrder.newOrder.findedItems,
      addedItems: state.Order.newOrder.newOrder.addedItems,
      frete: state.Order.newOrder.newOrder.frete,
      ocultarRt: state.Order.newOrder.newOrder.filters.ocultarRt,
      comRt: state.Order.newOrder.newOrder.comRt
    })
  );
  const [promotionDiscount, setPromotionDiscount] = useState(
    parseFloat(item.promotionDiscount).toFixed(2)
  );

  const [valorTabela, setValorTabela] = useState(
    parseFloat(item.precoVenda).toFixed(2)
  );

  const [valorTabelaComDesconto, setValorTabelaComDesconto] = useState(
    parseFloat(item.precoVenda).toFixed(2) - parseFloat(item.promotionDiscount).toFixed(2)
  );

  const [precoVenda, setPrecoVenda] = useState(
    parseFloat(valorTabelaComDesconto).toFixed(2)
  );

  const { pushToDataLayer } = useDataLayer();

  const vendedorComRt = comRt == '1';

  //variaveis input
  const [precoItem, setPrecoItem] = useState(
    parseFloat(item.precoVenda).toFixed(2)
  );
  const [valMin, setValMin] = useState(
    parseFloat(item.precoVenda).toFixed(2) -
    parseFloat(item.precoVenda).toFixed(2) * 0.05
  );
  const [valMax, setValMax] = useState(
    parseFloat(item.precoVenda).toFixed(2) * 1.5
  );

  const [valorTotal, setValorTotal] = useState(
    parseInt(item.quant || 1) * parseFloat(valorTabelaComDesconto).toFixed(2)
  );

  //formatado
  const [formattedServico, setFormattedServico] = useState("0,00");
  const [formattedPercent, setFormattedPercent] = useState("");
  const [formattedValCashback, setFormattedValCashback] = useState("0");
  const [formattedValorTotal, setFormattedValorTotal] = useState(
    (
      parseInt(item.quant || 1) * parseFloat(item.precoVenda).toFixed(2)
    ).toLocaleString("pt-br", {
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
    })
  );
  const [formattedPrecoVenda, setFormattedPrecoVenda] = useState(
    precoItem.toLocaleString("pt-br", {
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
    })
  );
  const percentCashback = 50;
  const [addServico, setAddServico] = useState(false);
  const [servico, setServico] = useState();
  setQtdAdd(item.quant);
  const [quant, setQuant] = useState(Number(item.quant) || 1);
  const [percent, setPercent] = useState(0);
  const [valCashback, setValCashback] = useState(0);
  const [rtArquiteto] = useState(0);
  const [, setDiff] = useState();

  const validStock = qtd => {
    var codFilial = item.produto.CODFILIAL;
    var findFilialStock = item.produto.PRDDISPS.STRETPRDFILADIAS.find(
      item => item.CODFILPRD == codFilial
    );
    var indexFilialStock =
      item.produto.PRDDISPS.STRETPRDFILADIAS.indexOf(findFilialStock);
    var stock = Number(
      item.produto?.PRDDISPS?.STRETPRDFILADIAS[indexFilialStock]?.ESTOQUE
    );

    if (qtd > stock) {
      return stock;
    } else {
      return null;
    }
  };

  const checkValues = (precoVenda, percent) => {
    if (precoVenda < valMin || precoVenda > valMax) {
      resetValues();
      toast.warning(
        `O preço de venda deve estar entre ${valMin.toLocaleString(
          "pt-br",
          formato
        )} e ${valMax.toLocaleString("pt-br", formato)}`,
        { theme: "colored" }
      );
    }

    if (percent < 0 || percent > percentCashback) {
      resetValues();
      toast.warning(`A % deve estar entre 0% e ${percentCashback}%`, {
        theme: "colored",
      });
    }
  };

  const handlePrecoVendaUpdate = (newPrecoVenda) => {
    const newPrecoVendaFormatted = parseFloat(newPrecoVenda.toString().replace(",", "."));

    if (vendedorComRt) {
      if (newPrecoVendaFormatted >= valorTabelaComDesconto) {
        let cashback =
          quant *
          (valorTabelaComDesconto * 0.05 + (newPrecoVendaFormatted - valorTabelaComDesconto) * retained);
  
        let percent = ((cashback / newPrecoVendaFormatted) * 100) / quant;
  
        setValCashback(cashback);
        setPercent(percent);
      } else if (newPrecoVendaFormatted < valorTabelaComDesconto) {
        var percentInital = 5;
        var percent = (
          parseFloat(percentInital) +
          ((newPrecoVendaFormatted - valorTabelaComDesconto) / valorTabelaComDesconto) * 100
        ).toFixed(2);
        var cashback = (newPrecoVendaFormatted / 100) * percent;
        setValCashback(cashback);
        setPercent(percent);
      }
    }

    setPrecoVenda(newPrecoVendaFormatted);
    checkValues(precoVenda, percent);
  };

  const handlePercentUpdate = (newPercent, valorTabelaComDesconto) => {
    if (vendedorComRt) {
      if (newPercent >= 5) {
        let d = valorTabelaComDesconto * 0.05;
  
        let cashback =
          (retained * newPercent * valorTabelaComDesconto - newPercent * d) / (70 - newPercent);
        setValCashback(cashback * quant);
        setPrecoVenda((cashback * 100) / newPercent);
      } else {
        var percentInitial = 0.05;
        var cashbackInitial = valorTabelaComDesconto * percentInitial;
        let cashback = valorTabelaComDesconto * (newPercent / 100);
        var cashbackValue = cashbackInitial - cashback;
        var precoVenda = valorTabelaComDesconto - cashbackValue;
        var cashbackFinal = precoVenda * (newPercent / 100);
  
        setValCashback(quant * cashbackFinal);
        setPrecoVenda(precoVenda);
      }
    }
    checkValues(precoVenda, newPercent);
  }

  const handleQuantUpdate = newQuant => {
    var newQuantNumber = Number(newQuant);
    var hasStock = validStock(newQuantNumber);

    if (hasStock) {
      return toast.info(
        `Não foi possivel adicionar quantidade. Limite em estoque: ${hasStock} `,
        { theme: "colored" }
      );
    }

    let newTotalQuantity = {
      ...totalQuantity,
      [item.produto.CODPRODUTO]: newQuantNumber,
    };

    setQuant(newQuantNumber);
    setQtdAdd(newQuantNumber);
    if (vendedorComRt) {
      let newValCashBack = ((newQuantNumber * precoVenda) / 100) * percent;
      setValCashback(Number(newValCashBack));  
    }
  }

  const resetValues = () => {
    setPrecoVenda(valorTabelaComDesconto);
    setPercent(5);
    setValCashback(valorTabelaComDesconto * 0.05);
  };

  useEffect(() => {
    const valorTabela = parseFloat(item.produto.valorTabela).toFixed(2);
    setValorTabela(valorTabela);

    const valorTabelaComDesconto = parseFloat(valorTabela - promotionDiscount).toFixed(2);
    setValorTabelaComDesconto(valorTabelaComDesconto);

    const quantity = item.quant || 1;

    let newTotalQuantity = {
      ...totalQuantity,
      [item.produto.CODPRODUTO]: quantity,
    };

    addTotalQuantity(newTotalQuantity);


    setQtdAdd(quantity);
    setPrecoItem(valorTabelaComDesconto);
    setPrecoVenda(valorTabelaComDesconto);
    setValorTotal(
      quantity * valorTabelaComDesconto
    );
    setDiff(0);

    let valTotTabela = {
      ...valoresTabela,
      [item.produto.CODPRODUTO]: Number(valorTabelaComDesconto),
    };
    addValTotTabela(valTotTabela);

    let valTotal = {
      ...valores,
      [item.produto.CODPRODUTO]: Number(valorTotal),
    };
    addValTot(item.produto.CODPRODUTO, Number(valorTotal));

    resetValues();
  }, [item.produto.valorTabela]);

  useEffect(() => {
    const promotionDiscount = item?.promotionDiscount ? item.promotionDiscount.toFixed(2) : 0;
    setPromotionDiscount(promotionDiscount);
  }, [item?.promotionDiscount]);

  const ratio = 30 / 70; // quantos % aumentar o valor levando em conta a diminuição de 30% -> ex: diminuiu o produto em 30%, para voltar ao valor normal, aumentar em ratio%
  const retained = 0.7;
  /////////////////////////////////////////////////////////////

  const detalhes = findedItems[item.cod] || {};

  //formatando os valores 
  useEffect(() => {
    setDiff(precoVenda - valorTabelaComDesconto);
    let indexOfComma = String(percent).indexOf(".");
    let percentFormatado = "";
    if (indexOfComma !== -1) {
      percentFormatado = String(percent).slice(0, indexOfComma + 3);
    } else {
      percentFormatado = String(percent);
    }

    setFormattedPercent(percentFormatado);
    setFormattedPrecoVenda(
      precoVenda.toLocaleString("pt-br", {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      })
    );

    setFormattedValCashback(
      valCashback.toLocaleString("pt-br", {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      })
    );
    let replacedPrecoVenda = parseFloat(
      precoVenda.toString().replace(",", ".")
    );
    let total = replacedPrecoVenda * quant;
    setFormattedValorTotal(
      total.toLocaleString("pt-br", {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      })
    );

    let valTotal = {
      ...valores,
      [item.produto.CODPRODUTO]: Number(valorTotal),
    };
    addValTot(item.produto.CODPRODUTO, Number(valorTotal));
  }, [precoVenda, promotionDiscount, valorTotal, valorTabelaComDesconto, percent, valCashback]);

  //alterações componente pai
  useEffect(() => {
    let valTotal = {
      ...valores,
      [item.produto.CODPRODUTO]: Number(valorTotal),
    };

    let itemsCar = {
      [item.produto.CODPRODUTO]: {
        AMOUNT: Number(quant),
        VALORRT: Number(valCashback) / Number(quant),
        PRCRT: Number(precoVenda.toString().replace(",", ".")),
        promotionDiscount: promotionDiscount,
        subtotal: valTotal,
        maskPrcVenda: precoVenda,
        PERCENT: percent,
      },
    };

    setItensCarrinho(state => {
      return { ...state, ...itemsCar };
    });

    let arqu = {
      ...valArquiteto,
      [item.produto.CODPRODUTO]: Number(rtArquiteto),
    };
    addValArqu(arqu);

    if (vendedorComRt) {
      let rtVal = {
        ...valorRt,
        [item.produto.CODPRODUTO]: Number(valCashback),
      };
      addValorRt(rtVal);  
    }

    
    let newTotalQuantity = {
      ...totalQuantity,
      [item.produto.CODPRODUTO]: quant,
    };

    addTotalQuantity(newTotalQuantity);

  }, [valorTotal, quant, valCashback, precoVenda, percent, rtArquiteto]);

  useEffect(() => {
    let replacedPrecoVenda = parseFloat(
      precoVenda.toString().replace(",", ".")
    );
    setValorTotal(replacedPrecoVenda * quant);
  }, [precoVenda, quant]);

  useEffect(() => {
    if (addServico && servico) {
      let servicoNumber = parseFloat(
        servico.replace(".", "").replace(",", ".")
      );
      setFormattedServico(servicoNumber.toLocaleString("pt-br", formato));
    }
  }, [servico]);

  useEffect(() => {
    const minimumPercentageOfSalesValue = 0.95;
    const maximumPercentageOfSalesValue = 1.5;
    setValMin(valorTabela * minimumPercentageOfSalesValue - promotionDiscount);
    setValMax(valorTabela * maximumPercentageOfSalesValue + promotionDiscount);
    const valorTabelaComDesconto = valorTabela - promotionDiscount;
    setValorTabelaComDesconto(valorTabelaComDesconto);
    handlePercentUpdate(percent, valorTabelaComDesconto);
  }, [valorTabela, promotionDiscount]);

  //remover item do carrinho
  const removeItem = () => {
    removeValTot(item.produto.CODPRODUTO);
  
    let valTotTabela = valoresTabela;
    delete valTotTabela[item.produto.valorTabela];
    addValTotTabela(valTotTabela);

    let itemCar = itensCarrinho;
    delete itemCar[item.produto.CODPRODUTO];
    setItensCarrinho(itemCar);

    let arqu = valArquiteto;
    delete arqu[item.produto.CODPRODUTO];
    addValArqu(arqu);

    if (vendedorComRt) {
      let rt = valorRt;
      delete rt[item.produto.CODPRODUTO];
      addValorRt(rt);
    }

    pushToDataLayer(
      'remove_from_cart',
      {
        ecommerce: {
          currency: "BRL",
          items: [{
            item_id: item.produto.CODPRODUTO,
            item_name: item.produto.DESCRICAO,
            item_brand: item.produto.MARCA,
            item_category: item.produto.CATEGORIA,
            price: item.produto.PRCVENDA?.STRETPRCFILADIAS[0]?.PRCVENDA,
            quantity: quant
          }]
        }
      }
    );

    toast.info(`${item.produto.DESCRICAO} removido do carrinho.`, {
      theme: "colored",
    });
    dispatch(removeFromCart(item));
  };

  const maskMoney = value => {
    let stringValue = value.toString();
    let v = new Intl.NumberFormat("pt-br", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(stringValue);
    return v;
  };

  const [isToastBeignCalled, setIsToastBeignCalled] = useState(false);

  const callWarningOnAFK = () => {
    if (history.location.pathname != "/pedidos/novo") {
      return;
    }
    if (addedItems.length > 0 && !isToastBeignCalled) {
      toast.warning(
        "Seu carrinho será limpo em 60 minutos devido à inatividade",
        { theme: "colored" }
      );
      setIsToastBeignCalled(true);
    }
  };

  const callRemoveItem = () => {
    const removeItemWhenAFK = () => {
      removeValTot(item.produto.CODPRODUTO);

      let valTotTabela = valoresTabela;
      delete valTotTabela[item.produto.valorTabela];
      addValTotTabela(valTotTabela);

      let itemCar = itensCarrinho;
      delete itemCar[item.produto.CODPRODUTO];
      setItensCarrinho(itemCar);

      let arqu = valArquiteto;
      delete arqu[item.produto.CODPRODUTO];
      addValArqu(arqu);

      if (vendedorComRt) {
        let rt = valorRt;
        delete rt[item.produto.CODPRODUTO];
        addValorRt(rt);
      }

      toast.warning(
        `${item.produto.DESCRICAO} removido do carrinho devido à inatividade.`,
        {
          autoClose: false,
        }
      );
      dispatch(removeFromCart(item));
    };
    if (history.location.pathname != "/pedidos/novo") {
      return;
    }
    if (addedItems.length > 0) {
      removeItemWhenAFK();
      setIsToastBeignCalled(false);
    }
  };

  let time;
  let timeHalf;

  document.onmousemove = () => {
    clearTimeout(time);
    clearTimeout(timeHalf);
    time = setTimeout(callRemoveItem, 1000 * 60 * 60); //roda em 1 hora
    timeHalf = setTimeout(callWarningOnAFK, 1000 * 60 * 45); //roda em 45 minutos
  };

  return (
    <Card className="mb-0 mt-4">
      <CardBody>
        <Row className="d-flex align-items-end">
          <Col md={3}>
            <p className="mb-0" style={{ fontWeight: 500 }}>
              Enviado de:{" "}
              {
                filiais[
                  Number(
                    item.produto.CODFILIAL)
                ]
              }
            </p>
          </Col>
          <Col md={9} className="d-flex justify-content-end align-items-center">
            { (!ocultarRt && vendedorComRt) && (
                <Button color="info" size="sm" outline onClick={resetValues}>
                  Zerar Cashback
                </Button>
              ) 
            }
            <a
              href=""
              onClick={e => {
                e.preventDefault();
                removeItem();
              }}
              style={{ color: "#269FEC", marginLeft: 20 }}
            >
              <Trash size={18} weight="bold" />
            </a>
          </Col>
        </Row>
        <hr className="mb-3" />
        <Row>
          {detalhes.fotos ? (
            <Col md={1}>
              <img src={detalhes.fotos[0]} style={{ width: "100%" }} />
            </Col>
          ) : (
            <div />
          )}
          <Col md={8}>
            <h5>{item.produto.DESCRICAO}</h5>
            <Row>
              <Col className="mt-2 d-flex align-items-center">
                <div>
                  <p className="text-muted mb-0" style={{ fontSize: 11 }}>
                    Cód. Fabricante: /
                  </p>
                  <p className="text-muted mb-0" style={{ fontSize: 11 }}>
                    SKU: {item.produto.CODPRODUTO}
                  </p>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
        <hr className="mb-3" />
        <div className="product-infos">
          <div className="product-info" style={{ width: "10%" }}>
            <p className="info-title">Quantidade</p>
            <Input
              className="mb-0 mt-4 pre-venda"
              type="number"
              value={quant}
              onChange={e => handleQuantUpdate(e.target.value)}
              min={1}
              disabled={frete?.loading}
            />
          </div>
          <div className="product-info">
            <p className="info-title">Preço de Venda (R$)</p>
            <div style={{ position: "relative" }}>
              <InputMask
                className="mb-0 mt-4 pre-venda"
                //style={{ paddingLeft: "25px!important" }}
                value={formattedPrecoVenda}
                onChange={e => setPrecoVenda(e.target.value.toString().replace(".", ""))}
                readOnly={vendedor?.VENDEDOR?.TEMRT != 1}
                onBlur={() => handlePrecoVendaUpdate(precoVenda)}
              />
              {/* <div
                style={{
                  position: "absolute",
                  left: "15px",
                  top: "7px",
                  fontWeight: "bold",
                  color: "#929fb1",
                }}
              >
                R$
              </div> */}
            </div>
          </div>
          <div className="product-info">
            {/* {promotionDiscount > 0 ? (
              <>
                <label className="d-flex justify-content-end info-title mb-0">Valor Total (R$)</label>
                <p className="info-result mb-0"><del>{formattedValorTotal}</del></p>
                <p className="info-result mb-0">{formattedValorTotalWithPromotionDiscount}</p>
              </>
            ) : (
              <> */}
            <label className="info-title">Valor Total (R$)</label>
            <p className="info-result mb-0">{formattedValorTotal}</p>
            {/* </>
            )} */}
          </div>
          <div className="product-info">
            {promotionDiscount > 0 ? (
              <>
                <p className="info-title">Tabela (R$)</p>
                <p className="info-result mb-0"><del>{`${maskMoney(valorTabela)}`}</del></p>
                <p className="info-result mb-0">{`${maskMoney(valorTabelaComDesconto)}`}</p>
              </>
            ) : (
              <>
                <p className="info-title">Tabela (R$)</p>
                <p className="info-result mb-0">{`${maskMoney(valorTabelaComDesconto)}`}</p>
              </>
            )}
          </div>
          <div className="product-info" hidden={ocultarRt || !vendedorComRt}>
            <p className="info-title">%</p>
            <Input
              className="mb-0 mt-4 pre-venda"
              value={formattedPercent}

              onChange={e => setPercent(e.target.value)}
              onBlur={() => handlePercentUpdate(percent, valorTabelaComDesconto)}
              readOnly={!vendedorComRt}
            />
          </div>
          <div className="product-info" hidden={ocultarRt || !vendedorComRt}>
            <p className="info-title">Valor Cashback (R$)</p>
            <p className="info-result mb-0">{formattedValCashback}</p>
            {/*  <div style={{ position: "relative" }}>
              {" "}
              <p className="info-title mt-3">{formattedValCashback}</p>
                  <Input
                className="mb-0 mt-4 p-input pre-venda"
                //style={{ paddingLeft: "25px!important" }}
                value={formattedValCashback}
                onChange={e => {
                  handleValueUpdate(e.target.value, "cashback");
                }}
                onBlur={() => compareValues("cashback")}
                readOnly={vendedor?.VENDEDOR?.TEMRT != 1}
              /> 
            </div> */}
          </div>
          {/* 
          <div className="product-info" hidden={ocultarRt}>
            <p className="info-title">Cashback do Arquiteto</p>
            <Input
              className="mb-0 mt-4 pre-venda"
              type="number"
              value={rtArquiteto}
              onChange={e => setRtArquiteto(e.target.value)}
            />
          </div> */}
        </div>{" "}
        {frete?.loading && (
          <p className="att-preco-frete">Atualizando preço do frete...</p>
        )}
        <hr className="mb-2" />
        <Row>
          <Col md={6}>
            <div
              className="cursor-pointer"
              style={{
                color: "rgb(0, 86, 150)",
                display: "flex",
                alignItems: "center",
              }}
            >
              <div
                onClick={() => {
                  setAddServico(prev => !prev);
                  setSendValServ(prev => !prev);
                }}
              >{`${addServico ? "Remover Serviço" : "Incluir Serviço"}`}</div>
              {!addServico ? (
                <Plus
                  className="ms-2"
                  size={15}
                  onClick={() => {
                    setAddServico(prev => !prev);
                    setSendValServ(prev => !prev);
                  }}
                  weight="bold"
                  style={{ marginLeft: 5 }}
                />
              ) : (
                <Minus
                  className="ms-2"
                  size={15}
                  onClick={() => {
                    setAddServico(prev => !prev);
                    setSendValServ(prev => !prev);
                  }}
                  weight="bold"
                  style={{ marginLeft: 5 }}
                />
              )}

              {addServico && (
                <div
                  className="ms-3"
                  style={{ width: "40%", position: "relative" }}
                >
                  <Input
                    value={servico}
                    style={{ paddingLeft: "30px" }}
                    onChange={e => {
                      setServico(e.target.value);
                      setValServ(parseFloat(e.target.value));
                    }}
                    onBlur={() => {
                      let temp = parseFloat(servico);
                      setServico(
                        temp.toLocaleString("pt-br", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })
                      );
                    }}
                  />
                  <div
                    style={{
                      position: "absolute",
                      top: "8px",
                      left: "8px",
                      color: "black",
                    }}
                  >
                    R$
                  </div>
                </div>
              )}
            </div>
          </Col>
          <Col md={6}>
            {addServico && (
              <a
                href="#"
                style={{
                  display: "block",
                  width: "fit-content",
                  marginLeft: "auto",
                  color: "rgb(0, 86, 150)",
                }}
              >
                Serviço de Instalação Incluso ({formattedServico})
              </a>
            )}
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

export default AddedItem;
