import React, { useEffect } from "react";
import "./index.scss";
import Breadcrumb from "../../../components/Breadcrumb";
import { Card, CardBody } from "reactstrap";

import Filters from "./Filters";
import ListTable from "./ListTable";
import { useDispatch, useSelector } from "react-redux";
import { getLeadsList } from "../../../store/actions";

export default function ListLeads() {
  const dispatch = useDispatch();
  const { getLeadsLoading } = useSelector(state => ({
    getLeadsLoading: state.Leads.getLeadsLoading,
  }));

  useEffect(() => {
    dispatch(getLeadsList());
  }, []);

  return (
    <div className="page-content">
      <div className="container">
        {" "}
        <Breadcrumb title="Página Inicial" breadcrumbItem="Listar Leads" />
        <Filters />
        <Card className="cardprincipal">
          <CardBody className="card-body">
            <div style={{ width: "100%" }}>
              <h5 className="titulo-lista">Lista de Leads</h5>
              <ListTable getLeadsLoading={getLeadsLoading} />
            </div>
          </CardBody>
        </Card>
      </div>
    </div>
  );
}
