import React, { useState } from "react";
import { useDispatch } from "react-redux";
import {
  Col,
  Card,
  CardBody,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import { useColors } from "../../../hooks/useColors";

import { getSettlementsList } from "../../../store/actions";

function SettlementsFilter() {
  const dispatch = useDispatch();
  const { colorDefault } = useColors();

  const [numFech, setNumFech] = useState("");
  const [codVend, setCodVend] = useState("");

  const handleDispatch = () => {
    let obj = {};
    obj.code = numFech;
    obj.code_vend = codVend;

    dispatch(getSettlementsList(obj));
  };

  const handleClear = () => {
    let obj = {
      code: "",
      code_vend: "",
    };
    setNumFech("");
    setCodVend("");
    dispatch(getSettlementsList(obj));
  };

  return (
    <div>
      <Card className="filtro">
        <CardBody className="card-fechamento">
          <Form>
            <FormGroup
              row
              className="d-flex align-items-center"
              style={{ marginTop: 15 }}
            >
              <Label
                for="numero-fechamento"
                style={{
                  width: 200,
                  color: "#202833",
                  fontSize: 16,
                  fontWeight: 500,
                }}
                className="mb-0"
              >
                Numero de Fechamento
              </Label>
              <Col sm={6}>
                <Input
                  id="numero-fechamento"
                  name="numero-fechamento"
                  placeholder="Digite aqui..."
                  type="text"
                  value={numFech}
                  onChange={e => setNumFech(e.target.value)}
                />
              </Col>
              <Col sm={3}>
                <Button
                  style={{
                    color: colorDefault,
                    backgroundColor: "#fff",
                    border: `${colorDefault} solid 2px`,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    fontSize: "12.5px",
                    boxShadow: "-1px 4px 14px 0px #cfcfcf",
                  }}
                  onClick={handleClear}
                >
                  LIMPAR
                </Button>
              </Col>
            </FormGroup>
            <FormGroup
              row
              style={{ marginTop: 15 }}
              className="d-flex align-items-center"
            >
              <Label
                for="numero-fechamento"
                style={{
                  width: 200,
                  color: "#202833",
                  fontSize: 16,
                  fontWeight: 500,
                }}
                className="mb-0"
              >
                Codigo do Vendedor
              </Label>
              <Col sm={6}>
                <Input
                  id="numero-fechamento"
                  name="numero-fechamento"
                  placeholder="Digite aqui..."
                  type="text"
                  value={codVend}
                  onChange={e => setCodVend(e.target.value)}
                />
              </Col>

              <Col sm={3}>
                <Button
                  style={{
                    color: "#fff",
                    backgroundColor: colorDefault,
                    border: "none",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    fontSize: "12.5px",
                    boxShadow: "-1px 4px 14px 0px #cfcfcf",
                  }}
                  onClick={handleDispatch}
                >
                  FILTRAR
                </Button>
              </Col>
            </FormGroup>
          </Form>
        </CardBody>
      </Card>
    </div>
  );
}

export default SettlementsFilter;
