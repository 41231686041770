import React, { useEffect } from "react";
import { TitleInputs } from "../../style";
import { ArInput } from "../../../../../components/adias-input";
import { Col, Row } from "reactstrap";
import formik from "formik";
import axios from "axios";
import { Select, TitleSelect } from "./styles";
import { useDispatch, useSelector } from "react-redux";
import { getCustomer } from "../../../../../store/actions";
import { validaCpfCnpj,formatDate } from "../../../../../utils";
import { getAdressesFromZipCode } from "../../../../../api";
import { toast } from "react-toastify";

export const DadosDoCliente = ({
  errors,
  touched,
  data,
  values,
  handleChange,
  handleBlur,
  setFieldValue,
  setErrors,
  setTouched,
}) => {
  const dispatch = useDispatch();
  const { customer } = useSelector(state => ({
    customer: state.Order.newOrder.newOrder.customer,
  }));

  useEffect(() => {
    setFieldValue("N_COMPLETO", data?.data?.orcamento.prospect.US_NOME);
    setFieldValue("EMAIL", data?.data?.orcamento.prospect.US_EMAIL.trim());
  }, [data]);

  useEffect(() => {
    if (values.CPFCNPJ.length <= 14) {
      setFieldValue("TP_PESSOA", "F");
    } else {
      setFieldValue("TP_PESSOA", "J");
    }
  }, [values.CPFCNPJ]);

  const getDataUser = () => {
    let cpfcnpj = values.CPFCNPJ?.replace(/[^0-9]+/g, "");
    if (validaCpfCnpj(cpfcnpj) || cpfcnpj != "") {
      dispatch(getCustomer({ cpfcnpj: cpfcnpj }));
    }
  };

  useEffect(() => {
    if (customer.data) {
      setFieldValue("N_COMPLETO", customer?.data?.N_COMPLETO.trim());
      setFieldValue("N_REDZ", customer?.data?.N_REDZ.trim());
      setFieldValue("EMAIL", customer?.data?.EMAIL.trim());
      setFieldValue("Rg", customer?.data?.Rg.trim());
      setFieldValue("Inscr", customer?.data?.Inscr.trim());
      setFieldValue("DT_NASC", formatDate(customer?.data?.DtNasc.trim(), "YYYY-MM-DD"));
      setFieldValue("CONTATO", customer?.data?.CONTATO.trim());
      setFieldValue("CARGO", customer?.data?.CARGO.trim());
      setFieldValue("DDD", customer?.data?.DDD.trim());
      setFieldValue("TEL", customer?.data?.TEL.trim());
      setFieldValue("DDD_CEL", customer?.data?.DDD_CEL.trim());
      setFieldValue("CELULAR", customer?.data?.CELULAR.trim());
      setFieldValue(
        "CEP",
        cepFormat(customer?.data?.CEP.trim().replace(/-/g, ""))
      );
      if (customer?.data?.CEP.trim()) {
        getCep(customer?.data?.CEP.trim());
      }

      setFieldValue("BAIRRO", customer?.data?.BAIRRO.trim());
      setFieldValue("ENDERECO", customer?.data?.ENDERECO.trim().split(",")[0]);
      setFieldValue("NumEndCob", customer?.data?.NumEndCob.trim());
      setFieldValue("COMPL", customer?.data?.COMPL_ENT.trim());
      setErrors({});
      setTouched({}, false);
    } else {
      setFieldValue("N_COMPLETO", "");
      setFieldValue("N_REDZ", "");
      setFieldValue("EMAIL", "");
      setFieldValue("CONTATO", "");
      setFieldValue("CARGO", "");
      setFieldValue("DDD", "");
      setFieldValue("TEL", "");
      setFieldValue("DDD_CEL", "");
      setFieldValue("CELULAR", "");
      setFieldValue("DT_NASC", "");
      setFieldValue("CEP", "");
      setFieldValue("UF", "");
      setFieldValue("MUNICIPIO", "");
      setFieldValue("BAIRRO", "");
      setFieldValue("ENDERECO", "");
      setFieldValue("NumEndCob", "");
      setFieldValue("COMPL", "");
    }
  }, [customer]);

  const cepFormat = v => {
    v = v.trim();
    if (v.length <= 9) {
      //somente numeros
      v = v.replace(/[^0-9]/g, "");
      v = v.replace(/(\d{5})(\d{3})/, "$1-$2");
      return v;
    }
  };

  const getCep = cep => {
    if (cep?.replace(/[^0-9]+/g, "").length == 8) {
      setFieldValue("MUNICIPIO", "");
      setFieldValue("UF", "");
      getAdressesFromZipCode(cep)
        .then(res => {
          if (res.data.erro) {
            return toast.error(
              "Cep inválido! Verifique se o cep foi digitado corretamente",
              { theme: "colored" }
            );
          }

          setFieldValue("MUNICIPIO", res.data.localidade);
          setFieldValue("UF", res?.data?.uf || res?.data?.UF);
        })
        .catch(err => console.log(err));
    }
  };
  return (
    <>
      <Row>
        <Col md={6}>
          <ArInput
            title="CPF/CNPJ"
            errors={errors}
            name="CPFCNPJ"
            touched={touched}
            mask={"cnpj"}
            maxLength={18}
            onBlur={e => {
              getDataUser();
              handleBlur(e);
            }}
          />
        </Col>
        <Col md={6}>
          <TitleSelect>Tipo de Pessoa</TitleSelect>
          <Select
            value={values.TP_PESSOA}
            name="TP_PESSOA"
            onChange={handleChange}
            onBlur={handleBlur}
          >
            <optgroup label="CNPJ">
              <option value="J">Jurídica</option>
            </optgroup>
            <optgroup label="CPF">
              <option value="F">Física</option>
            </optgroup>
          </Select>
        </Col>
      </Row>
      <Row>
        {" "}
        <Col md={6}>
          <TitleSelect>* Campo obrigatório.</TitleSelect>
          <Select
            value={values.NOME_REDUZ}
            name="Contrib"
            onChange={handleChange}
            onBlur={handleBlur}
          >
            <option value="2">Isento</option>
            <option value="1">Estadual</option>
          </Select>
        </Col>
        <Col md={6}>
          {values.TP_PESSOA == "J" ? (
            <ArInput
              title="Inscrição Estadual"
              errors={errors}
              name="Inscr"
              touched={touched}
            />
          ) : (
            <ArInput
              title="RG"
              errors={errors}
              name="Rg"
              touched={touched}
              /*   mask="rg" */
            />
          )}
        </Col>
      </Row>

      <TitleInputs>DADOS PESSOAIS</TitleInputs>
      <Row>
        <Col md={6}>
          <ArInput
            title="Razão social / Nome Completo"
            errors={errors}
            name="N_COMPLETO"
            touched={touched}
          />
        </Col>
        <Col md={6}>
          <ArInput
            title="Nome fantasia / Nome Reduzido"
            errors={errors}
            name="N_REDZ"
            touched={touched}
            value={values.N_REDZ}
          />
        </Col>
      </Row>
      <Row>
        {" "}
        <Col md={12}>
          <ArInput
            title="Email"
            errors={errors}
            name="EMAIL"
            touched={touched}
          />
        </Col>
      </Row>
      <Row>
        {" "}
        {values.TP_PESSOA == "F" && (
          <Col md={4}>
            <ArInput
              title="Data Nascimento"
              type="date"
              errors={errors}
              name="DT_NASC"
              touched={touched}
            />
          </Col>
        )}
        <Col md={values.TP_PESSOA == "F" ? 4 : 6}>
          <ArInput
            title="Contato"
            errors={errors}
            name="CONTATO"
            touched={touched}
          />
        </Col>
        <Col md={values.TP_PESSOA == "F" ? 4 : 6}>
          <ArInput
            title="Cargo"
            errors={errors}
            name="CARGO"
            touched={touched}
          />
        </Col>
      </Row>
    </>
  );
};
