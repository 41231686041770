import React from 'react';
import { Container, Card, CardBody, Row, Col, Form, FormGroup, Label, Input, InputGroup, InputGroupText, Button } from 'reactstrap';
import "./vendedoresHierarquia.css";
import Breadcrumb from "../../components/Breadcrumb";



const VendedoresHerarquia = () => {

    return (
        <React.Fragment>
            <div className="page-content hierarquia">
                <Container>
                    <h1>Hierarquia</h1>
                    <Breadcrumb
                        title="Página Inicial"
                        breadcrumbItem="Hierarquia"
                    />
                    <Card>
                        <CardBody>
                            <div>
                                <h3 className='titulo-hieranquia'>
                                    <i className="ph-text-align-justify"></i>
                                    Mover Hierarquia
                                </h3>
                            </div>
                            <hr />
                            <h3 className='titulos-formulario'>Dados da Revenda</h3>
                            <hr />
                            <Row className='engloba-cod-nome-categoria'>
                                <Col className='engloba-codigo-revenda' md={3}>
                                    <Form>
                                        <FormGroup>
                                            <Label for="codigoRevenda">
                                                Código da Revenda
                                            </Label>
                                            <InputGroup>
                                                <InputGroupText>
                                                    <i className="ph-magnifying-glass"> </i>
                                                </InputGroupText>
                                                <Input placeholder="" />
                                            </InputGroup>
                                        </FormGroup>
                                    </Form>
                                </Col>
                                <Col className='engloba-nome-revenda' md={6}>
                                    <Form>
                                        <FormGroup>
                                            <Label for="NomeRevenda">
                                                Nome da Revenda
                                            </Label>
                                            <Input
                                                id="nomeRevenda"
                                                name="nomeRevenda"
                                                placeholder=""
                                                type="nome" />
                                        </FormGroup>
                                    </Form>
                                </Col>
                                <Col className='categoria-atual' md={3}>
                                    <FormGroup>
                                        <Label for="categoriaAtual">
                                            Categoria Atual
                                        </Label>
                                        <Input
                                            id="categoriaAtual"
                                            name="categoriaAtual"
                                            type="select">
                                            <option>Clube</option>
                                            <option>Bronze</option>
                                            <option>Prata</option>
                                            <option>Ouro</option>
                                            <option>Diamante</option>
                                            <option>Esmeralda</option>
                                        </Input>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row className='engloba-data-consultor-cod'>
                                <Col className='engloba-data' md={3}>
                                    <Form>
                                        <FormGroup>
                                            <Label for="dataUltimoPedido">
                                                Data do último pedido
                                            </Label>
                                            <Input
                                                id="dataUltimoPedido"
                                                name="dataUltimoPedido"
                                                placeholder=""
                                                type="nome" />
                                        </FormGroup>
                                    </Form>
                                </Col>
                                <Col className='consultor' md={6}>
                                    <Form>
                                        <FormGroup>
                                            <Label for="consultorInterno">
                                                Consultor interno atual                                            </Label>
                                            <Input
                                                id="consultorInterno"
                                                name="consultorInterno"
                                                placeholder=""
                                                type="nome" />
                                        </FormGroup>
                                    </Form>
                                </Col>
                                <Col className='engloba-cod-consultor'>
                                    <Form>
                                        <FormGroup>
                                            <Label for="codigoConsultor">
                                                Cod. consultor
                                            </Label>
                                            <Input
                                                id="codigoConsultor"
                                                name="codigoConsultor"
                                                placeholder=""
                                                type="nome" />
                                        </FormGroup>
                                    </Form>
                                </Col>
                            </Row>

                            <h3 className='titulos-formulario'>Dados do consultor</h3>
                            <hr />
                            <Row className='engloba-cod-nome-consultor'>
                                <Col className='engloba-cod-consultor' md={4}>
                                    <Form>
                                        <FormGroup>
                                            <Label for="codigoConsultorInterno">
                                                Código do consultor interno
                                            </Label>
                                            <InputGroup>
                                                <InputGroupText>
                                                    <i className="ph-magnifying-glass"> </i>
                                                </InputGroupText>
                                                <Input placeholder="" />
                                            </InputGroup>
                                        </FormGroup>
                                    </Form>
                                </Col>
                                <Col className='engloba-nome-consultor' md={8}>
                                    <Form>
                                        <FormGroup>
                                            <Label for="NomeConsultor">
                                                Nome do Consultor
                                            </Label>
                                            <Input
                                                id="NomeConsultor"
                                                name="NomeConsultor"
                                                placeholder=""
                                                type="nome" />
                                        </FormGroup>
                                    </Form>
                                </Col>
                            </Row>
                            <Row className='supervisor-cod'>
                                <Col className='engloba-supervisor' md={8}>
                                    <Form>
                                        <FormGroup>
                                            <Label for="supervisor">
                                                Supervisor
                                            </Label>
                                            <Input
                                                id="supervisor"
                                                name="supervisor"
                                                placeholder=""
                                                type="nome" />
                                        </FormGroup>
                                    </Form>
                                </Col>
                                <Col className='engloba-cod-supervisor' md={4}>
                                    <Form>
                                        <FormGroup>
                                            <Label for="codSupervisor">
                                                Cod. Supervisor
                                            </Label>
                                            <Input
                                                id="codSupervisor"
                                                name="codSupervisor"
                                                placeholder=""
                                                type="nome" />
                                        </FormGroup>
                                    </Form>
                                </Col>
                            </Row>
                            <hr />

                            <Button className="salvar">SALVAR HIERARQUIA</Button>


                        </CardBody>
                    </Card>

                </Container>
            </div>
        </React.Fragment >
    );
};

export default VendedoresHerarquia;