import React, { useEffect, useState } from "react";
import axios from "axios";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup, Label, Input } from "reactstrap";
import { useColors } from "../hooks/useColors";

const StateSelector = ({ selectedStates = [], setSelectedStates }) => {
  const [states, setStates] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { colorDefault }= useColors();

  const [preSelectedStates, setPreSelectedStates] = useState([]);

  useEffect(() => {
    const fetchStates = async () => {
      try {
        const response = await axios.get("https://servicodados.ibge.gov.br/api/v1/localidades/estados");
        const sortedStates = response.data.sort((a, b) => a.nome.localeCompare(b.nome));
        setStates(sortedStates);
      } catch (error) {
        console.error("Erro ao buscar os estados:", error);
      }
    };

    fetchStates();
  }, []);

  const handleCheckboxChange = (event) => {
    const { value, checked } = event.target;
    if (checked) {
      setPreSelectedStates((prevSelectedStates) => [...prevSelectedStates, value]);
    } else {
      setPreSelectedStates((prevSelectedStates) =>
        prevSelectedStates.filter((state) => state !== value)
      );
    }
  };

  const toggleModal = () => {
    setSelectedStates(preSelectedStates);
    setIsModalOpen(!isModalOpen);
  };

  useEffect(() => {
    setPreSelectedStates(selectedStates);
  }, [selectedStates]);

  return (
    <div>
      <Button style={{ backgroundColor: colorDefault, color: 'white' }} onClick={toggleModal}>Selecionar Estados</Button>

      <Modal isOpen={isModalOpen} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>Selecione os Estados</ModalHeader>
        <ModalBody style={{ maxHeight: '300px', overflowY: 'auto' }}>
          {states.map((state) => (
            <FormGroup check key={state.id}>
              <Label check>
                <Input
                  type="checkbox"
                  value={state.sigla}
                  checked={preSelectedStates.includes(state.sigla)}
                  onChange={handleCheckboxChange}
                />
                {state.nome}
              </Label>
            </FormGroup>
          ))}
        </ModalBody>
        <ModalFooter>
          <Button style={{ backgroundColor: colorDefault, color: 'white' }} onClick={toggleModal}>Salvar</Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default StateSelector;
