import React from "react";
export default function IconPlus(props) {
  const colorFill = props?.colorFill || "none";
  const colorStroke = props?.colorStroke || "#9E8100";
  const width = props?.width || 16;
  const height = props?.height || 16;

  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 16 16`}
      fill={colorFill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.26562 8.30713H13.3687"
        stroke={colorStroke}
        strokeWidth="1.81476"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.31641 3.25562V13.3586"
        stroke={colorStroke}
        strokeWidth="1.81476"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
