import { ADD_STATUS, REMOVE_STATUS } from "./actionTypes";

export const addStatus = data => ({
  type: ADD_STATUS,
  payload: data,
});

export const removeStatus = () => ({
  type: REMOVE_STATUS,
});
