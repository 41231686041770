import PropTypes from "prop-types";
import React, { useState, useEffect, useCallback } from "react";
import "./header.css";
import SvgIcon from "../../components/SvgIcon";
import { Link, useHistory } from "react-router-dom";
import {
  Container,
  Row,
  Spinner,
  Dropdown,
  UncontrolledPopover,
  PopoverHeader,
  PopoverBody,
} from "reactstrap";

// Import menuDropdown
import NotificationDropdown from "../CommonForBoth/TopbarDropdown/NotificationDropdown";
import ProfileMenu from "../CommonForBoth/TopbarDropdown/ProfileMenu";

//i18n
import { withTranslation } from "react-i18next";
import WhatsApp from "../CommonForBoth/TopbarDropdown/WhatsApp";
import AirConditioner from "../../assets/images/icons/arcondicionado_blue.svg";
import SolarPanel from "../../assets/images/icons/painelsolar_orange.svg";
import ArrowRightAr from "../../assets/images/icons/caretRight_ar.svg";
import ArrowRightSolar from "../../assets/images/icons/caretRight_solar.svg";
import { getChooseCompany } from "../../store/chooseCompany/action";
import { useDispatch, useSelector } from "react-redux";
import { CaretDown, CaretUp, MapPinLine } from "phosphor-react";
import { useProfile, useSolar } from "../../hooks";
import { Modal, Button, Form } from "react-bootstrap";
import { apiClient2 } from "../../api/apiCore2";
import InputMask from "react-input-mask";
import { toast } from "react-toastify";
import { display } from "@mui/system";
import { useSideBar } from "../../contexts/SideBarProvider";
import { apiClient } from "../../api/apiCore";
import ModalUpdateZipcode from "../../pages/UpdateZipcode";
import { updateZipcodeModalIsOpen } from "../../store/actions";
import { useColors } from "../../hooks/useColors";

const Header = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { userProfile, setUserProfile } = useProfile();
  const isSolar = useSolar();
  const { loadingChooseCompany, zipcodeModalIsOpen, defaultZipcode } = useSelector(state => ({
    loadingChooseCompany: state.ChooseCompany.loadingChooseCompany,
    zipcodeModalIsOpen: state.Layout.zipcodeModalIsOpen,
    defaultZipcode: state.Layout.defaultZipcode
  }));
  const { openSidebar, setOpenSidebar } = useSideBar();
  const [isOpen, setIsOpen] = useState(false);
  const logradouro = localStorage.getItem("cep");
  const lograFormat = JSON.parse(logradouro);
  const { colorDefault } = useColors();

  const [popoverOpen, setPopoverOpen] = useState(false);
  const toggleCepWarnig = () => setPopoverOpen(true);
  const undoCepWarnig = () => setPopoverOpen(false);

  function tToggle() {
    var body = document.body;

    if (window.screen.width <= 768) {
      body.classList.toggle("sidebar-enable");
    } else {
      body.classList.toggle("vertical-collpsed");
      body.classList.toggle("sidebar-enable");
    }
    setOpenSidebar(state => !state);
  }
  //ATUALIZA A EMPRESA CASO O USUARIO TROQUE DE EMPRESA PELA ROTA
  useEffect(() => {
    if (isSolar) {
      dispatch(
        getChooseCompany({
          empresa: "solar",
          history: history,
          userProfile,
          setUserProfile,
          att: "attAutomatic",
        })
      );
    } else {
      dispatch(
        getChooseCompany({
          empresa: "adias",
          history: history,
          userProfile,
          setUserProfile,
          att: "attAutomatic",
        })
      );
    }
  }, [isSolar]);

  const handleClick = system => {
    dispatch(
      getChooseCompany({
        empresa: system,
        history: history,
        userProfile,
        setUserProfile,
        att: "noAutomatic",
      })
    );
  };

  const handleClose = () => {
    dispatch(updateZipcodeModalIsOpen(false));
    zipcodeModalIsOpen == null ? toggleCepWarnig() : null;
  };
  const handleShow = () => {
    dispatch(updateZipcodeModalIsOpen(true));
  };

  const verifyExpiresModalCep = defaultZipcode => {
    const dateNow = new Date();
    const dateSave = new Date(defaultZipcode?.date) || null;
    var seconds = 15;

    if (!defaultZipcode) {
      return true;
    }

    const differenceInMilliseconds = dateNow - dateSave;
    const differenceInSeconds = Math.floor(differenceInMilliseconds / 1000);

    if (differenceInSeconds > seconds) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <React.Fragment>
      <header id="page-topbar">
        <div className="navbar-header">
          <Container>
            <div className="navegacao-header d-flex justify-content-between align-items-center">
              <div className="botoes-acoes d-flex align-items-center">
                <Link
                  to="#"
                  type="button"
                  className="btn header-item noti-icon waves-effect d-flex align-items-center justify-content-center"
                  onClick={() => {
                    tToggle();
                  }}
                >
                  <i
                    className={`ph-caret-${document.body.classList.contains("sidebar-enable")
                      ? "right"
                      : "left"
                      }`}
                  ></i>
                </Link>
                {isSolar ? (
                  <div
                    className="cep-div-isSolar"
                    onClick={() => {
                      undoCepWarnig();
                      handleShow();
                    }}
                  >
                    <SvgIcon name="maps-marker" color={colorDefault}/>
                    <a
                      style={{
                        display: "grid",
                        flexDirection: "row",
                        paddingLeft: "10px",
                      }}
                    >
                      <span>
                        {defaultZipcode?.logradouro == null
                          ? "informe seu"
                          : defaultZipcode?.logradouro + ", " + defaultZipcode?.uf}
                      </span>
                      <span>
                        CEP{" "}
                        {defaultZipcode?.cep == null
                          ? ""
                          : ": " + defaultZipcode?.cep}
                      </span>
                    </a>
                  </div>
                ) : (
                  <div
                    className="cep-div"
                    onClick={() => {
                      undoCepWarnig();
                      handleShow();
                    }}
                  >
                    <SvgIcon name="maps-marker" color={colorDefault}/>
                    <a
                      style={{
                        display: "grid",
                        flexDirection: "row",
                      }}
                    >
                      <span style={{marginLeft: "10px"}}>
                        {defaultZipcode?.logradouro == null
                          ? "informe seu"
                          : defaultZipcode?.logradouro + ", " + defaultZipcode?.uf}
                      </span>
                      <span>
                        CEP{" "}
                        {defaultZipcode?.cep == null
                          ? ""
                          : ": " + defaultZipcode?.cep}
                      </span>
                    </a>
                  </div>
                )}
                <Dropdown className="d-inline-block " id="cepWarning">
                  <div className="btn header-item noti-icon waves-effect d-flex align-items-center justify-content-center"></div>
                  <UncontrolledPopover
                    placement="bottom"
                    target="cepWarning"
                    isOpen={popoverOpen}
                    toggle={toggleCepWarnig}
                  >
                    <div>
                      <PopoverHeader
                        style={{
                          cursor: "pointer",
                          backgroundColor: "white",
                          fontSize: "15px",
                        }}
                      >
                        Confira o envio para o seu endereço
                      </PopoverHeader>
                      <PopoverBody
                        style={{ textAlign: "center", cursor: "pointer" }}
                      >
                        Altere o CEP para verificar os custos e prazos de
                        entrega precisos na busca.
                        <div
                          style={{
                            display: "flex",
                            gap: "20px",
                            marginTop: "10px",
                          }}
                        >
                          <button
                            onClick={() => {
                              undoCepWarnig();
                              handleShow();
                            }}
                            style={{
                              backgroundColor: isSolar
                                ? "rgba(255, 93, 0, 1)"
                                : "#2D567B",
                              color: "white",
                              borderRadius: "5px",
                              width: "100px",
                              height: "40px",
                              border: "none",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            Alterar
                          </button>
                          <button
                            onClick={undoCepWarnig}
                            style={{
                              backgroundColor: "transparent",
                              color: isSolar
                                ? "rgba(255, 93, 0, 1)"
                                : "#2D567B",
                              borderRadius: "5px",
                              width: "100px",
                              height: "40px",
                              border: "none",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            Continuar
                          </button>
                        </div>
                      </PopoverBody>
                    </div>
                  </UncontrolledPopover>
                </Dropdown>
              </div>
              <div className="area-social d-flex align-items-center">
                <div className="d-inline-block container-buttons-choose-company">
                  {/* 0102 MPS - CAMPO GRANDE */}
                  {loadingChooseCompany ? (
                    <Spinner size="sm" />
                  ) : userProfile?.claims?.company == "01" ? (
                    <button
                      disabled={loadingChooseCompany}
                      className="button-choose-company-solar"
                      onClick={() => handleClick("solar")}
                    >
                      <img
                        className="header-button-icon-image"
                        style={{ marginRight: 10, width: '22px' }}
                        src={SolarPanel}
                      />
                      <span
                        className="header-button-desktop-text"
                        style={{ paddingTop: 2 }}
                      >
                        Ir para Solar
                      </span>
                      <span
                        className="header-button-mobile-text"
                        style={{ paddingTop: 2 }}
                      >
                        Solar
                      </span>
                      <img
                        className="header-button-right-arrow"
                        style={{ marginLeft: 10 }}
                        src={ArrowRightSolar}
                      />
                    </button>
                  ) : (
                    <button
                      disabled={loadingChooseCompany}
                      onClick={() => handleClick("adias")}
                      className="button-choose-company-adias d-flex align-items-center"
                    >
                      <img
                        className="header-button-icon-image"
                        style={{ marginRight: 10, width: "24px" }}
                        src={AirConditioner}
                      />
                      <span
                        className="header-button-desktop-text"
                        style={{ paddingTop: 2 }}
                      >
                        Ir para Ar Condicionado
                      </span>
                      <span
                        className="header-button-mobile-text"
                        style={{ paddingTop: 2 }}
                      >
                        Ar Condicionado
                      </span>
                      <img
                        className="header-button-right-arrow"
                        style={{ marginLeft: 10 }}
                        src={ArrowRightAr}
                      />
                    </button>
                  )}
                </div>
                <WhatsApp />
                <NotificationDropdown />
                <ProfileMenu />
              </div>
            </div>
          </Container>
        </div>
      </header>
      <ModalUpdateZipcode handleClose={handleClose} />
    </React.Fragment>
  );
};

Header.propTypes = {
  t: PropTypes.any,
};

export default withTranslation()(Header);
