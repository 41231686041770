import React from "react";
export default function DataWithPictures(props) {
  var size = props.size ? props.size : "";
  return (
    <div className="how-financial-your-solar-panels">
      {props.icon ? (
        <div className="d-flex j-c-center" style={{ height: "70px" }}>
          {props.icon}
        </div>
      ) : null}
      <div className="text-center">
        <div className="my-2">
          <strong
            style={{
              color: `${props.stringColor || "#000"}`,
              fontSize: `${props?.stringSize}px`,
              fontWeight: Number(props?.stringWeight) || 400,
              fontFamily: "WorkSans",
            }}
          >
            {props.value.toLocaleString("us") + " " + size}
          </strong>
        </div>
        <h6 className="my-0 font-size-14">{props.title}</h6>
        <small
          style={{
            color: "#545454",
            fontSize: `${Number(props?.stringSize) - 6}` || 10,
          }}
        >
          {props.description}
        </small>
      </div>
    </div>
  );
}
