import React from "react";
import { Link } from "react-router-dom";

const Cliente = ({ row }) => {
  const data = row.original;

  return <div>{data.client_id ? data.client_id : "-"}</div>;
};

const SubTotal = ({ row }) => {
  const data = row.original;

  return <div>{data.valTotal ? data.valTotal : "-"}</div>;
};

const Action = ({ row }) => {
  return (
    <div>
      <Link
        to={`/solar/listar-kits/${row.original.client_id}`}
        style={{ backgroundColor: "#4ec44e", color: "white" }}
        className="btn  btn-sm ms-1"
        size="sm"
      >
        <i className="mdi mdi-arrow-right me-1" />
        Ver
      </Link>
    </div>
  );
};

export { Cliente, SubTotal, Action };
