import React from "react";
import { Container } from "reactstrap";

//components
import Breadcrumb from "../../../components/Breadcrumb";
import DadosSolicitante from "./DadosSolicitante";
import Detalhes from "./Detalhes";
import Chat from "./Chat";
import ChatAndFields from "./ChatAndFields";

const Index = () => {
  return (
    <React.Fragment>
      <div className="page-content">
        <Container>
          {/* Render Breadcrumb */}
          <Breadcrumb
            title="Solicitação de Garantia"
            breadcrumbItem="Solicitações On-line"
          />
          <DadosSolicitante />
          <Detalhes />
          <Chat />
          <ChatAndFields />
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Index;
