import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import classNames from "classnames";

const LEFT_PAGE = "LEFT";
const RIGHT_PAGE = "RIGHT";

const range = (from, to, step = 1) => {
  let i = from;
  const range = [];

  while (i <= to) {
    range.push(i);
    i += step;
  }

  return range;
};

const Paginations = ({
  defaultSelected,
  onPageChange,
  total,
  pageSize,
  pageNeighbours,
  reset,
  paginationClass,
}) => {
  const [selected, setselected] = useState(defaultSelected || 1);
  useEffect(() => {
    setselected(defaultSelected);
  }, [defaultSelected]);

  const totalPages = total < pageSize ? 1 : Math.ceil(total / pageSize);

  const pageNeighbours2 = Math.max(0, Math.min(pageNeighbours, 2)) || 0;

  const gotoPage = page => {
    const currentPage = Math.max(0, Math.min(page, totalPages));

    const paginationData = {
      currentPage,
      totalPages: totalPages,
      pageLimit: pageSize,
      totalRecords: total,
    };

    if (onPageChange) {
      onPageChange(paginationData);
    }

    setselected(page);
  };

  useEffect(() => {
    if (reset) {
      setselected(defaultSelected || 1);
    }
  }, [reset, defaultSelected]);

  const handleClick = page => {
    gotoPage(page);
  };

  const handleMoveLeft = () => {
    gotoPage(selected - 1);
  };

  const handleMoveRight = () => {
    gotoPage(selected + 1);
  };

  const fetchPageNumbers = () => {
    const currentPage = selected;
    const pageNeighbours = pageNeighbours2;

    const totalNumbers = pageNeighbours2 * 2 + 3;
    const totalBlocks = totalNumbers + 2;

    if (totalPages > totalBlocks) {
      let pages = [];

      const leftBound = currentPage - pageNeighbours;
      const rightBound = currentPage + pageNeighbours;
      const beforeLastPage = totalPages - 1;

      const startPage = leftBound > 2 ? leftBound : 2;
      const endPage = rightBound < beforeLastPage ? rightBound : beforeLastPage;

      pages = range(startPage, endPage);

      const pagesCount = pages.length;
      const singleSpillOffset = totalNumbers - pagesCount - 1;

      const leftSpill = startPage > 2;
      const rightSpill = endPage < beforeLastPage;

      const leftSpillPage = LEFT_PAGE;
      const rightSpillPage = RIGHT_PAGE;

      if (leftSpill && !rightSpill) {
        const extraPages = range(startPage - singleSpillOffset, startPage - 1);
        pages = [leftSpillPage, ...extraPages, ...pages];
      } else if (!leftSpill && rightSpill) {
        const extraPages = range(endPage + 1, endPage + singleSpillOffset);
        pages = [...pages, ...extraPages, rightSpillPage];
      } else if (leftSpill && rightSpill) {
        pages = [leftSpillPage, ...pages, rightSpillPage];
      }

      return [1, ...pages, totalPages];
    }

    return range(1, totalPages);
  };

  const pages = fetchPageNumbers();

  return (
    <>
      <div className="pagination-container">
        <ul
          className={classNames(
            "pagination",
            "justify-content-end",
            paginationClass
          )}
        >
          {pages.map((page, index) => {
            if (page === LEFT_PAGE)
              return (
                <li key={index} className="page-item">
                  <Link
                    className="page-link"
                    to="#"
                    aria-label="Previous"
                    onClick={e => {
                      e.preventDefault();
                      handleMoveLeft();
                    }}
                  >
                    <span aria-hidden="true">&laquo;</span>
                    <span className="sr-only">Previous</span>
                  </Link>
                </li>
              );

            if (page === RIGHT_PAGE)
              return (
                <li key={index} className="page-item">
                  <Link
                    className="page-link"
                    to="#"
                    aria-label="Next"
                    onClick={e => {
                      e.preventDefault();
                      handleMoveRight();
                    }}
                  >
                    <span aria-hidden="true">&raquo;</span>
                    <span className="sr-only">Next</span>
                  </Link>
                </li>
              );

            return (
              <li
                key={index}
                className={`page-item${selected === page ? " active" : ""}`}
              >
                <Link
                  className="page-link"
                  to="#"
                  onClick={e => {
                    e.preventDefault();
                    handleClick(page);
                  }}
                >
                  {page}
                </Link>
              </li>
            );
          })}
        </ul>
      </div>
    </>
  );
};

export default Paginations;
