import { call, put, takeEvery, all, fork } from "redux-saga/effects";

// Crypto Redux States
import { GalleryActionTypes } from "./actionTypes";
import { galleryApiSuccess, galleryApiFail } from "./action";

//Include Both Helper File with needed methods
import {
  getTVGallary as getTVGallaryApi,
  getTVConfigGallary as getTVConfigGallaryApi,
  loadRecentData as loadRecentDataApi,
  addNewLive as addNewLiveApi,
  uploadLiveConfigFiles as uploadLiveConfigFilesApi,
  deleteLiveConfigFile as deleteLiveConfigFileApi,
  getTVvideoDetails as getTVvideoDetailsApi,
  postVideoComment as postVideoCommentApi,
  deleteMessage as deleteMessageApi,
} from "../../api/index";

// helper
import {
  showSuccessNotification,
  showErrorNotification,
} from "../../helpers/notifications";
import { toast } from "react-toastify";

function* getTVGallary() {
  try {
    const response = yield call(getTVGallaryApi);
    yield put(galleryApiSuccess(GalleryActionTypes.GET_TV_GALLERY, response));
  } catch (error) {
    yield put(galleryApiFail(GalleryActionTypes.GET_TV_GALLERY, error));
  }
}

function* getTVConfigGallary({ payload: videoId }) {
  try {
    const response = yield call(getTVConfigGallaryApi, videoId);
    yield put(
      galleryApiSuccess(GalleryActionTypes.GET_TV_CONFIG_GALLERY, response)
    );
  } catch (error) {
    console.log(error);
    yield put(galleryApiFail(GalleryActionTypes.GET_TV_CONFIG_GALLERY, error));
  }
}

function* loadRecentData({ payload: videoId }) {
  try {
    const response = yield call(loadRecentDataApi, videoId);
    yield put(galleryApiSuccess(GalleryActionTypes.LOAD_RECET_DATA, response));
    toast.success(`Vídeo atualizado com sucesso!`, { theme: "colored" });
  } catch (error) {
    yield put(galleryApiFail(GalleryActionTypes.LOAD_RECET_DATA, error));
  }
}

function* addNewLive({ payload: data }) {
  try {
    const response = yield call(addNewLiveApi, data);
    yield put(galleryApiSuccess(GalleryActionTypes.ADD_NEW_LIVE, response));
    yield call(showSuccessNotification, "Adicionado com sucesso.");
  } catch (error) {
    yield call(showErrorNotification, error);
    yield put(galleryApiFail(GalleryActionTypes.ADD_NEW_LIVE, error));
  }
}

function* uploadLiveConfigFiles({ payload: { videoId, files } }) {
  try {
    const response = yield call(uploadLiveConfigFilesApi, videoId, files);
    yield put(
      galleryApiSuccess(GalleryActionTypes.UPLOAD_LIVE_CONFIG_FILES, response)
    );
    yield call(showSuccessNotification, "Arquivo carregado com sucesso.");
  } catch (error) {
    yield call(showErrorNotification, error);
    yield put(
      galleryApiFail(GalleryActionTypes.UPLOAD_LIVE_CONFIG_FILES, error)
    );
  }
}

function* deleteLiveConfigFile({ payload: { videoId, arquivoId } }) {
  try {
    const response = yield call(deleteLiveConfigFileApi, videoId, arquivoId);
    yield put(
      galleryApiSuccess(GalleryActionTypes.DELETE_LIVE_CONFIG_FILE, response)
    );
    yield call(showSuccessNotification, "o arquivo foi deletado com sucesso");
  } catch (error) {
    yield call(showErrorNotification, error);
    yield put(
      galleryApiFail(GalleryActionTypes.DELETE_LIVE_CONFIG_FILE, error)
    );
  }
}

function* getTVvideoDetails({ payload: videoId }) {
  try {
    const response = yield call(getTVvideoDetailsApi, videoId);
    yield put(
      galleryApiSuccess(GalleryActionTypes.GET_TV_VIDEO_DETAILS, response)
    );
  } catch (error) {
    yield put(galleryApiFail(GalleryActionTypes.GET_TV_VIDEO_DETAILS, error));
  }
}

function* postVideoComment({ payload: { videoId, commentData } }) {
  try {
    const response = yield call(postVideoCommentApi, videoId, commentData);
    yield put(
      galleryApiSuccess(GalleryActionTypes.POST_VIDEO_COMMENT, response)
    );
  } catch (error) {
    yield put(galleryApiFail(GalleryActionTypes.POST_VIDEO_COMMENT, error));
  }
}

function* deleteMessage({ payload: messageId }) {
  try {
    const response = yield call(deleteMessageApi, messageId);
    yield put(galleryApiSuccess(GalleryActionTypes.DELETE_MESSAGE, response));
    yield call(showSuccessNotification, "A mensagem foi apagada com sucesso");
  } catch (error) {
    yield put(galleryApiFail(GalleryActionTypes.DELETE_MESSAGE, error));
  }
}

export function* watchGetTVGallary() {
  yield takeEvery(GalleryActionTypes.GET_TV_GALLERY, getTVGallary);
}

export function* watchGetTVConfigGallary() {
  yield takeEvery(GalleryActionTypes.GET_TV_CONFIG_GALLERY, getTVConfigGallary);
}

export function* watchLoadRecentData() {
  yield takeEvery(GalleryActionTypes.LOAD_RECET_DATA, loadRecentData);
}

export function* watchAddNewLive() {
  yield takeEvery(GalleryActionTypes.ADD_NEW_LIVE, addNewLive);
}

export function* watchuploadLiveConfigFiles() {
  yield takeEvery(
    GalleryActionTypes.UPLOAD_LIVE_CONFIG_FILES,
    uploadLiveConfigFiles
  );
}

export function* watchDeleteLiveConfigFile() {
  yield takeEvery(
    GalleryActionTypes.DELETE_LIVE_CONFIG_FILE,
    deleteLiveConfigFile
  );
}

export function* watchGetTVvideoDetails() {
  yield takeEvery(GalleryActionTypes.GET_TV_VIDEO_DETAILS, getTVvideoDetails);
}

export function* watchPostVideoComment() {
  yield takeEvery(GalleryActionTypes.POST_VIDEO_COMMENT, postVideoComment);
}

export function* watchDeleteMessage() {
  yield takeEvery(GalleryActionTypes.DELETE_MESSAGE, deleteMessage);
}

function* gallerySaga() {
  yield all([
    fork(watchGetTVGallary),
    fork(watchGetTVConfigGallary),
    fork(watchLoadRecentData),
    fork(watchAddNewLive),
    fork(watchuploadLiveConfigFiles),
    fork(watchDeleteLiveConfigFile),
    fork(watchGetTVvideoDetails),
    fork(watchPostVideoComment),
    fork(watchDeleteMessage),
  ]);
}

export default gallerySaga;
