import React from 'react';
import { Container, Card, CardBody, Form, FormGroup, Label, Row, Col, Input, InputGroup, Button } from 'reactstrap';
import "./eventTeams.css";
import Breadcrumb from "../../components/Breadcrumb";


const EventTeams = () => {
    return (
        <React.Fragment>
            <div className="page-content event-Teams">
                <Container>
                    <h3>Incluir <small className='subtitulo-incluir-turma'>nova turma</small></h3>
                    <Breadcrumb
                        title="Página Inicial"
                        breadcrumbItem="incluir nova turma" />
                    <Card>
                        <CardBody>
                            <h4>Insira os dados da turma</h4>
                            <hr />
                            <div className='engloba-form-turma'>
                                <Form>
                                    <FormGroup row>
                                        <Label
                                            for="evento"
                                            sm={3} >
                                            Evento:
                                        </Label>
                                        <Col sm={5}>
                                            <Input
                                                id="evento"
                                                name="evento"
                                                type="select">
                                                <option>selecione</option>
                                                <option>Notificação lembrete de evento</option>
                                                <option>Novo participante castrado no evento</option>
                                                <option>Comunicação,Marketing e eventos</option>
                                                <option>Notificação de inscrição em evento</option>
                                                <option>Notificação agradecimento de presença em evento</option>
                                            </Input>
                                        </Col>
                                    </FormGroup>
                                </Form>

                                <Form>
                                    <FormGroup row>
                                        <Label
                                            for="turma"
                                            sm={3} >
                                            Turma:
                                        </Label>
                                        <Col sm={5}>
                                            <Input
                                                id="turma"
                                                name="turma"
                                                placeholder="Turma"
                                                type="text"
                                            />
                                        </Col>
                                    </FormGroup>
                                </Form>
                                <Form>
                                    <FormGroup row>
                                        <Label
                                            for="vagasEmpresa"
                                            sm={3} >
                                            Vagas por empresa:
                                        </Label>
                                        <Col sm={9} className='d-flex'>
                                            <Input
                                                id="vagasEmpresa"
                                                name="vagasEmpresa"
                                                placeholder=""
                                                type="number" />
                                            <p>* Coloque - 1 para vagas ilimitadas</p>
                                        </Col>
                                    </FormGroup>
                                </Form>
                                <Form>
                                    <FormGroup row>
                                        <Label
                                            for="vagasGeral"
                                            sm={3} >
                                            Vagas geral:
                                        </Label>
                                        <Col sm={9} className='d-flex'>
                                            <Input
                                                id="vagasGeral"
                                                name="vagasGeral"
                                                placeholder=""
                                                type="number" 
                                                 />
                                            <p>* Coloque - 1 para vagas ilimitadas</p>
                                        </Col>
                                    </FormGroup>
                                </Form>
                                <Form>
                                    <FormGroup row>
                                        <Label
                                            for="dataInicio"
                                            sm={3}>
                                            Data:
                                        </Label>
                                        <Col sm={5}>
                                            <InputGroup>
                                                <Input placeholder="Data inicio" />
                                                <Button className='botao-input'>
                                                    <i className="ph-calendar-blank"> </i>
                                                </Button>
                                            </InputGroup>
                                        </Col>
                                    </FormGroup>
                                </Form>
                                <Form>
                                    <FormGroup row>
                                        <Label
                                            for="dataFinal"
                                            sm={3}>
                                            Até:
                                        </Label>
                                        <Col sm={5}>
                                            <InputGroup>
                                                <Input placeholder="Data final" />
                                                <Button>
                                                    <i className="ph-calendar-blank"> </i>
                                                </Button>
                                            </InputGroup>
                                        </Col>
                                    </FormGroup>
                                </Form>
                                <Row>
                                    <Col>
                                        <Form>
                                            <FormGroup row>
                                                <Label
                                                    for="horario"
                                                    sm={3} >
                                                    Horário:
                                                </Label>
                                                <Col sm={2}>
                                                    <Input
                                                        id="horario"
                                                        name="select"
                                                        type="number"
                                                        placeholder='Hora'>
                                                    </Input>
                                                </Col>
                                                <Col sm={2}>
                                                    <Input
                                                        id="horario"
                                                        name="horario"
                                                        type="number"
                                                        placeholder='Minutos'>
                                                    </Input>
                                                </Col>
                                            </FormGroup>
                                        </Form>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Form>
                                            <FormGroup row>
                                                <Label
                                                    for="horario"
                                                    sm={3} >
                                                    Às
                                                </Label>
                                                <Col sm={2}>
                                                    <Input
                                                        id="horario"
                                                        name="select"
                                                        type="number"
                                                        placeholder='Hora'>
                                                    </Input>
                                                </Col>
                                                <Col sm={2}>
                                                    <Input
                                                        id="horario"
                                                        name="horario"
                                                        type="number"
                                                        placeholder='Minutos'>
                                                    </Input>
                                                </Col>
                                            </FormGroup>
                                        </Form>
                                    </Col>
                                </Row>
                                <Form>
                                    <FormGroup row>
                                        <Label
                                            for="segmentos"
                                            sm={3} > Segmentos:</Label>
                                        <Col sm={4}>
                                            <Input
                                                id="exampleSelect"
                                                name="select"
                                                type="select"
                                                multiple>
                                                <option>Administradores</option>
                                                <option>Diretorias</option>
                                                <option>Gerente</option>
                                                <option>Supervisor</option>
                                                <option>Vendedor revenda</option>
                                                <option>Assistente revenda</option>
                                                <option>Funcionário clube</option>
                                                <option>Funcionário revenda</option>
                                                <option>Usuarios</option>
                                                <option>Vendedor consumidor</option>
                                                <option>Arquitetos</option>
                                                <option>Teste multi usuario</option>
                                                <option>Parceiro - Diamante</option>
                                                <option>Parceiro - Ouro</option>
                                                <option>Parceiro - Prata</option>
                                                <option>Parceiro - Bronze</option>
                                                <option>Parceiro - Clube</option>
                                                <option>Parceiro - Esmeralda</option>
                                            </Input>
                                        </Col>
                                    </FormGroup>
                                </Form>
                            </div>
                            <hr />
                            <Button className='criar-turma'>Criar</Button>
                        </CardBody>
                    </Card>
                </Container>
            </div>
        </React.Fragment >
    );
};

export default EventTeams;