import { useEffect, useState } from "react";
import { formato } from "../../../../../utils";
import { momentFormatDate } from "../../../../../utils/dateUtils";
import {
  Economy,
  User,
  Consumption,
  Tariff,
  RectanglePageOne,
  SolarPanel,
  Moneygreen,
  Moneyred,
  Trees,
  TwoCars,
  React,
} from "../../helpers/importer.helper";
import Footer from "../Footer";
import "./styles.scss";

export default function PageOne({ dataPdf, dataProductAttributes }) {
  const date = dataPdf?.lead?.updated_at?.substring(0, 10);

  const dateFormat = momentFormatDate(date);
  //Consumo ANUAL com Impostos
  const [kwhMonth, setKwhMonth] = useState(0);
  //Geração MÉDIA de Energia Solar Estimada Mensal
  const kwh_year_consumption = kwhMonth * 12;
  //Consumo MÉDIO de Energia Elétrica Mensal

  const [kwh_month_estimated_generation, setKwh_month_estimated_generation] =
    useState(0);

  //Economia MÉDIA de Energia Obtida (Fora de Ponta)

  const [kwh_middle_economy, setKwh_middle_economy] = useState(0);

  //Gastos com Energia Elétrica (Média Mensal Fora de Ponta)
  const energy_expenses =
    Number(kwhMonth) * Number(dataPdf?.lead?.lead?.kwh_price);
  //Economia de Energia Estimada (Base tarifa atual com impostos)
  const estimated_monthly_savings =
    Number(kwh_month_estimated_generation) *
    Number(dataPdf?.lead?.lead?.kwh_price);
  const [irradiacaoMedia, setIrradiacaoMedia] = useState(0);

  //GERA A IRRADIAÇÃO MÉDIA
  const getIrradiacaoMedia = () => {
    let irradiacaoMediaArr = [];
    let temp = [];
    temp.push(
      dataPdf?.lead?.calculations?.irradiation_fv_kwh.map(v => {
        return Object.values(v)[0];
      })
    );
    irradiacaoMediaArr = temp[0];

    let irradiacaoMedia = 0;

    for (let i = 0; i < irradiacaoMediaArr.length; i++) {
      irradiacaoMedia += irradiacaoMediaArr[i];
    }

    return (irradiacaoMedia =
      irradiacaoMedia / irradiacaoMediaArr.length / 1000);
  };

  useEffect(() => {
    if (dataPdf?.lead?.calculations?.irradiation_fv_kwh) {
      setIrradiacaoMedia(getIrradiacaoMedia());
    }
  }, [dataPdf]);

  //ARREDONDA NÚMEROS E DEFINE O TAMANHO DE CASAS APÓS A VÍRGULA
  const round = (num, places) => {
    if (!("" + num).includes("e")) {
      return +(Math.round(num + "e+" + places) + "e-" + places);
    } else {
      let arr = ("" + num).split("e");
      let sig = "";
      if (+arr[1] + places > 0) {
        sig = "+";
      }

      return +(
        Math.round(+arr[0] + "e" + sig + (+arr[1] + places)) +
        "e-" +
        places
      );
    }
  };

  //GERA A Geração MÉDIA de Energia Solar Estimada Mensal:
  const getKwhMonthEstimatedGeneration = () => {
    let kwp = dataPdf?.kwpPdf;
    let irradiation = irradiacaoMedia;

    let value = round(kwp * irradiation * 30, 2);

    setKwh_month_estimated_generation(value);
  };

  useEffect(() => {
    if (irradiacaoMedia != 0) {
      getKwhMonthEstimatedGeneration();
    }
  }, [irradiacaoMedia, dataPdf?.kwpPdf]);

  //GERA A Geração MÉDIA de Energia Solar Estimada Mensal:
  const getKwhMiddleEconomy = () => {
    let years = 12;
    let kwh_price = Number(dataPdf?.lead?.lead?.kwh_price);
    let value = round(kwh_month_estimated_generation * years * kwh_price, 2);

    setKwh_middle_economy(value);
  };

  useEffect(() => {
    if (kwh_month_estimated_generation != 0) {
      getKwhMiddleEconomy();
    }
  }, [kwh_month_estimated_generation]);

  const getTotalKwhMonth = () => {
    let sum = 0;
    let lenght = Object.values(dataPdf?.lead?.calculations?.med_cost[0]).length;
    Object.values(dataPdf?.lead?.calculations?.med_cost[0]).map(
      v => (sum += Number(v.replace(",", ".")))
    );

    setKwhMonth(sum / lenght);
  };

  useEffect(() => {
    if (dataPdf?.lead) {
      getTotalKwhMonth();
    }
  }, [dataPdf?.lead]);

  // ARREDONDA NÚMEROS E DEFINE O TAMANHO DE CASAS APÓS A VÍRGULA

  return (
    <>
      <div id="pageOne" className="scroll" />
      <div className="content-pdf-5">
        <img className="rectangle-pdf-5" src={RectanglePageOne} />
        <div className="container-top">
          <div className="header">
            <div className="header-user">
              <User />
              <h1>DADOS DO CLIENTE</h1>
            </div>
          </div>
          <div className="middle-container-top">
            <div className="content-info">
              <span>Nome:</span>
              <p>{dataPdf?.dataOrcamento?.cliente?.nome}</p>
            </div>
            <div className="content-info">
              <span>Endereço:</span>
              <p>{dataPdf?.dataOrcamento?.cliente?.endereco}</p>
            </div>
            <div className="content-info">
              <span>Consessionária:</span>
              <p>{dataPdf?.concessionaria}</p>
            </div>
            <div className="content-info">
              <span>Data de Solicitação:</span>
              <p>{dateFormat}</p>
            </div>
            <div className="content-info">
              <span>Tipo de Conexão:</span>
              {dataProductAttributes.map(item =>
                item.atributos.map(
                  (item, index) =>
                    item.Codattr == "_faseTensao" && (
                      <p key={index}>{item.Valor}</p>
                    )
                )
              )}
            </div>
            <div className="content-info">
              <span>Tarifa de Energia com Impostos (fora de ponta): </span>
              <p>
                {dataPdf?.lead?.lead?.kwh_price?.toString().replace(".", ",") ||
                  ""}
              </p>
            </div>
            <div className="content-info">
              <span>Demanda Contratada:</span>
              <p>Não</p>
            </div>
          </div>

          <div className="footer-container-top">
            O Dimensionamento do Sistema Fotovoltaico no empreendimento foi
            realizado conforme dados fornecidos pelo cliente.
          </div>
        </div>

        <div className="container-middle">
          <div className="card-middle">
            <div className="card-image-middle-div">
              <div className="image-middle">
                <Tariff />
              </div>
            </div>
            <div className="card-container-info">
              <strong className="card-strong-middle">
                {kwh_year_consumption.toLocaleString("pt-BR")}
              </strong>
              <span className="card-span-middle">kWh/ano</span>
            </div>
            <p className="card-p-middle">
              Consumo ANUAL com Impostos (Fora de Ponta)
            </p>
          </div>

          <div className="card-middle">
            <div className="card-image-middle-div">
              <div className="image-middle-consumo">
                <Consumption />
              </div>
            </div>
            <div className="card-container-info">
              <strong className="card-strong-middle">
                {kwhMonth.toLocaleString("pt-BR")}
              </strong>
              <span className="card-span-middle">kWh/mês</span>
            </div>
            <p className="card-p-middle">
              Consumo MÉDIO de Energia Elétrica Mensal (Fora de Ponta):
            </p>
          </div>

          <div className="card-middle">
            <div className="card-image-middle-div">
              <div className="image-middle-placa">
                <SolarPanel />
              </div>
            </div>
            <div className="card-container-info">
              <strong className="card-strong-middle">
                {kwh_month_estimated_generation.toLocaleString("pt-BR")}
              </strong>
              <span className="card-span-middle">kWh/mês</span>
            </div>
            <p className="card-p-middle">
              Geração MÉDIA de Energia Solar Estimada Mensal:
            </p>
          </div>

          <div className="card-middle">
            <div className="card-image-middle-div">
              <div className="image-middle-economia">
                <Economy />
              </div>
            </div>
            <div className="card-container-info">
              <strong className="card-strong-middle">
                {kwh_middle_economy.toLocaleString("pt-BR")}
              </strong>
              <span className="card-span-middle">kWh/ano</span>
            </div>
            <p className="card-p-middle">
              Economia MÉDIA de Energia Obtida (Fora de Ponta):
            </p>
          </div>
        </div>

        <div className="container-bottom">
          <div className="container-bottom-top">
            <div className="card-red">
              <Moneyred />
              <div className="container-bottom-content">
                <span className="container-bottom-span">
                  {energy_expenses.toLocaleString("pt-BR", formato)}
                </span>
                <p>mês</p>
              </div>
              <p>Gastos com Energia Elétrica (Média Mensal Fora de Ponta):</p>
            </div>
            <div className="card-green">
              <Moneygreen />
              <div className="container-bottom-content">
                <span className="container-bottom-span-green">
                  {estimated_monthly_savings.toLocaleString("pt-BR", formato)}
                </span>
                <p>mês</p>
              </div>
              <p>
                Economia de Energia Estimada (Base tarifa atual com impostos):
              </p>
            </div>
          </div>

          <div className="container-bottom-bottom">
            <span>As emissões de CO² evitadas equivalem à:</span>
            <div className="container-bottom-info">
              <div className="container-bottom-info-card">
                <Trees colorFill="#0090A3" />
                <span>{dataPdf?.lead?.calculations?.seedlings_planted}</span>
                <p>Árvores Plantadas</p>
              </div>
              <div className="container-bottom-info-card">
                <TwoCars />
                <span>{dataPdf?.lead?.calculations?.automobiles}</span>
                <p>Rodados de Carro</p>
              </div>
            </div>
          </div>
          <Footer dataPdf={dataPdf} />
        </div>
      </div>
    </>
  );
}
