import React from "react";
export default function IconHouseLine(props) {
  const colorFill = props?.colorFill?.replaceAll("#", "") || "none";
  const colorStroke = props?.colorStroke?.replaceAll("#", "") || "#FF601A";

  return (
    <svg
      width="23"
      height="22"
      viewBox="0 0 23 22"
      fill={colorFill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18 18V9.68035C17.9985 9.5887 17.9781 9.49827 17.94 9.41439C17.902 9.3305 17.847 9.25487 17.7784 9.19193L10.9602 3.17363C10.8345 3.06194 10.6704 3 10.5 3C10.3296 3 10.1655 3.06194 10.0398 3.17363L3.22159 9.19193C3.15298 9.25487 3.09803 9.3305 3.05997 9.41439C3.02192 9.49827 3.00153 9.5887 3 9.68035V18"
        stroke={colorStroke}
        strokeWidth="1.38227"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2 19H22"
        stroke={colorStroke}
        strokeWidth="1.38227"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13 19V13.75C13 13.5511 12.9298 13.3603 12.8047 13.2197C12.6797 13.079 12.5101 13 12.3333 13H9.66667C9.48986 13 9.32029 13.079 9.19526 13.2197C9.07024 13.3603 9 13.5511 9 13.75V19"
        stroke={colorStroke}
        strokeWidth="1.38227"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
