import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CardBody, Col, Row, Card, Input } from "reactstrap";
import { formato, arredondar } from "../../utils";
import { getSaldo } from "../../store/actions";
import { toast } from "react-toastify";

const Saldo = ({ changeSaldo }) => {
  const dispatch = useDispatch();
  const { ativo } = useSelector(state => ({
    ativo: state.Order.newOrder.newOrder.saldo.ativo,
  }));
  const [utilizado, setUtilizado] = useState("");
  const [varUti, setVarUti] = useState("");

  useEffect(() => {
    dispatch(getSaldo());
  }, [dispatch]);

  useEffect(() => {
    changeSaldo(utilizado);
  }, [utilizado]);

  return (
    <Card>
      <CardBody>
        <Row>
          <Col md={4}>
            <p className="mb-0" style={{ fontWeight: "bold" }}>
              Deseja usar seu saldo em conta?
            </p>
            <p className="text-muted mb-0">
              <small>
                {Number(ativo).toLocaleString("pt-br", formato)} Disponível
              </small>
            </p>
          </Col>
          <Col md={3}>
            <Input
              id="saldo"
              placeholder="Digite seu valor..."
              value={varUti.toLocaleString("pt-br", formato)}
              onChange={e => {
                setVarUti(e.target.value);
              }}
              onBlur={() => {
                let val =
                  String(varUti)
                    .replace(/[^0-9,]*/g, "")
                    .replace(",", ".") || 0;
                if (!(Number(val) > arredondar(ativo))) {
                  document
                    .getElementById("saldo")
                    .classList.remove("erro-field");
                  if (Number(val) > 100) {
                    setVarUti(100);
                    setUtilizado(100);
                  } else {
                    setUtilizado(Number(val));
                  }
                } else {
                  setVarUti(Number(utilizado));
                  document.getElementById("saldo").classList.add("erro-field");
                  toast.error(`O valor não autorizado. Saldo Insuficiente!`, {
                    theme: "colored",
                  });
                }
              }}
            />
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

export default Saldo;
