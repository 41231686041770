import React, { useEffect, useState } from "react";
import { Card, CardBody, Row, Col, Alert } from "reactstrap";
import { Link } from "react-router-dom";

// components
import CustomTable from "../../../components/CustomTable";
import Loader from "../../../components/Loader";
import { HeadCol } from "../../../components/Columns";
import { Cliente, SubTotal, Action } from "./Columns";
import LeadsPagination from "./Pagination";
import { useSelector } from "react-redux";
import { apiClient } from "../sunbeam-at-your-address/helpers/importer.helper";

const ListTable = () => {
  const { leads } = useSelector(state => ({
    leads: state.Leads.leads,
  }));

  const [listaPaginada, setListaPaginada] = useState([]);
  const [defaultPage, setdefaultPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [kits, setKits] = useState([]);
  const changeIndex = valor => setdefaultPage(valor);

  const getListKits = async () => {
    const resp = await apiClient.get("/api/person-kits/listPersonKits");
    setKits(resp);
  };

  useEffect(() => {
    getListKits();
  }, []);

  useEffect(() => {
    if (kits.length >= 1) {
      setListaPaginada(paginate(kits));
    }
  }, [kits]);

  function paginate(arr) {
    let kits = [];
    let data = arr;
    while (data.length) {
      kits.push(data.splice(0, 10));
    }

    return kits;
  }

  const columns = [
    {
      Header: <HeadCol label="Id Cliente" />,
      accessor: "client",
      Cell: function colRenderer(props) {
        return <Cliente row={props} />;
      },
    },
    {
      Header: <HeadCol label="Total" />,
      accessor: "subTotal",
      Cell: function colRenderer(props) {
        return <SubTotal row={props} />;
      },
    },
    {
      Header: <HeadCol label="Ações" />,
      accessor: "action",
      Cell: function colRenderer(props) {
        return <Action row={props} />;
      },
    },
  ];

  return (
    <>
      <Row>
        <Col md={6}>
          <h5>
            <i className="fa fa-list"></i> Lista de kits Personalizados
          </h5>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col xs={12}>
          <div className="mt-3 position-relative">
            {loading && <Loader />}
            {listaPaginada.length > 0 ? (
              <CustomTable
                columns={columns}
                data={
                  listaPaginada.length >= 1 ? listaPaginada[defaultPage] : []
                }
                classNames="align-middle custom-table table-nowrap table-borderless table-check"
                selectable={false}
                idField={"name"}
              />
            ) : !loading ? (
              <Alert color="info">Nenhum dado foi encontrado</Alert>
            ) : (
              <div style={{ height: 60 }} />
            )}
            {listaPaginada.length > 0 ? (
              <LeadsPagination
                totalPages={listaPaginada.length}
                index={defaultPage}
                changeIndex={changeIndex}
              />
            ) : null}
          </div>
        </Col>
      </Row>
    </>
  );
};

export default ListTable;
