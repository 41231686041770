import { API_SUCCESS, API_FAIL, GET_SHARING_HISTORY, INSERT_SHARING, DELETE_SHARING } from "./actionTypes";

export const sharedResalesApiSuccess = (actionType, data) => ({
  type: API_SUCCESS,
  payload: { actionType, data },
});

export const sharedResalesApiFail = (actionType, error) => ({
  type: API_FAIL,
  payload: { actionType, error },
});

export const getSharingHistory = sharedSellerId => ({
  type: GET_SHARING_HISTORY,
  payload: sharedSellerId,
});

export const insertSharing = data => ({
  type: INSERT_SHARING,
  payload: data,
});

export const deleteSharing = sharingId => ({
  type: DELETE_SHARING,
  payload: sharingId,
});
