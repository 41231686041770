import React from "react";

const ReceiptIcon = ({size = "22", style = {}, color = {}}) => (

    <svg 
        width={size} 
        height={size} 
        viewBox="0 0 24 24" 
        fill="none" 
        style={style}
        xmlns="http://www.w3.org/2000/svg"
    >
        <path 
            d="M7.8002 7.8H16.2002M7.8002 12.6H16.2002M5.7602 3H18.2402C19.1018 3 19.8002 3.80589 19.8002 4.8V21L17.2002 19.2L14.6002 21L12.0002 19.2L9.4002 21L6.8002 19.2L4.2002 21V4.8C4.2002 3.80589 4.89863 3 5.7602 3Z" 
            stroke={color} 
            strokeWidth="2" 
            strokeLinecap="round" 
            strokeLinejoin="round"
        />
    </svg>
);

export default ReceiptIcon;
