import { call, put, takeEvery, all, fork } from "redux-saga/effects";

// Crypto Redux States
import { GET_MENU_ITEMS } from "./actionTypes";
import { menuapiSuccess, menuapiFail } from "./action";

//Include Both Helper File with needed methods
import { getMenuItems as getMenuItemsApi } from "../../api/index";
import { useSolar } from "../../hooks";
import { system } from "faker/lib/locales/en";

function* getMenuItems({ payload: data }) {
  try {
    const isSolar = useSolar();
    const arOrSolar = isSolar ? "solar" : "ar";
    const response = yield call(getMenuItemsApi, data);
    const verifySystemIndex = response.findIndex(item => {
      const hasChildren = item["subItems"] && item["subItems"].length;
      return (
        hasChildren >= 1 &&
        item["subItems"][0].system != arOrSolar &&
        item["subItems"][0].system != "both"
      );
    });
    if (verifySystemIndex !== -1) {
      response.splice(verifySystemIndex, 1);
    }

    yield put(menuapiSuccess(GET_MENU_ITEMS, response));
  } catch (error) {
    yield put(menuapiFail(GET_MENU_ITEMS, error));
  }
}
export function* watchGetMenuItems() {
  yield takeEvery(GET_MENU_ITEMS, getMenuItems);
}

function* sidebarMenuSaga() {
  yield all([fork(watchGetMenuItems)]);
}

export default sidebarMenuSaga;
