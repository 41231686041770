import React, { useState, useEffect, useCallback } from "react";
import { CardBody, Col, Row, Card, Input, Fade } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  addStatus,
  addToCart,
  editQtdKits,
  removeFromCartKits,
} from "../../../store/actions";
import { dateNow, formato } from "../../../utils";
import { toast } from "react-toastify";
import { Trash, Plus, CurrencyCircleDollar, FilePdf } from "phosphor-react";
import ConfirmationModal from "../../../components/ConfirmationModal";
import ModalEditQtd from "./ModalEditQtd";
import { valueByQTD } from "../../../utils/formulas";
import RtAndCashBack from "./RtAndCashBack";
import { useProfile } from "../../../hooks";

const AddedItem = ({
  item,
  addValTot,
  valores,
  setValTot,
  loadingSearModule,
  setItensCarrinho,
  setPercentWattpay,
  itensCarrinho,
  valArquiteto,
  addValArqu,
  valorRt,
  addValorRt,
  ocultarRt,
  percentCashBackAll,
  setPercentCashBackAll,
  percentCashBackAllOnBlur,
  setValTotAndRt,
  acrescimo,
  changeFormOfPayment,
  ItHasForecast,
  setRealPower,
  addValorRtNoRetention,
  valoresTabela,
  addValoresTabela
}) => {
  const dispatch = useDispatch();
  const { newOrder, user } = useSelector(state => {
    return {
      newOrder: state.Order.newOrder.newOrder,
      user: state.Profile.profileDetails.user,
    };
  });
  const { userProfile } = useProfile();
  const displayName = userProfile
    ? userProfile["displayName"] !== null
      ? userProfile["displayName"].split(" ")[0]
      : userProfile["email"]
    : "";

  const [isOpenDelete, setIsOpenDelete] = useState(false);
  const [isOpeModalEditQtd, setIsOpenModalEditQtd] = useState(false);
  const [collapse, setCollapse] = useState(false);
  const [promotionDiscount, setPromotionDiscount] = useState(0.00);
  const userCode = user?.code ? user?.code : "";
  const qtd = item?.produto?.qtd ? item?.produto.qtd : 1;

  useEffect(() => {
    if (percentCashBackAll != "0") {
      setCollapse(true);
    } else {
      setCollapse(false);
    }
  }, [percentCashBackAll]);

  useEffect(() => {
    setPromotionDiscount(item.promotionDiscount ? item.promotionDiscount : 0.00);
  }, [item.promotionDiscount]);

  useEffect(() => {
    if (percentCashBackAll == "0") {
      setItensCarrinho(state => {
        return {
          ...state,
          [item.produto.nome]: {
            PRCRT: item.produto.subTotal - (promotionDiscount * qtd),
          },
        };
      });
    }
  }, [percentCashBackAll, promotionDiscount]);

  useEffect(() => {
    if (changeFormOfPayment) {
      setCollapse(false);
    }
  }, [changeFormOfPayment]);

  const onCloseDelete = useCallback(() => {
    setIsOpenDelete(false);
  }, []);

  const onDeleteMessage = () => {
    const date = dateNow();
    const hours = new Date().toLocaleTimeString();
    let isInverter = item.produto.nome.includes("INV");
    let thereIsAModuleInTeCart = item?.produto?.nome?.includes("MOD.")
      ? item?.produto?.nome?.includes("MOD.")
      : item?.produto?.nome?.includes("Modulos")
      ? item?.produto?.nome?.includes("Modulos")
      : item?.produto?.nome?.includes("MOD FOTOV")
      ? item?.produto?.nome?.includes("MOD FOTOV")
      : item?.produto?.nome?.includes("MOD. FOTOV")
      ? item?.produto?.nome?.includes("MOD. FOTOV")
      : item?.produto?.nome?.includes("GCL");

    if (thereIsAModuleInTeCart) {
      toast.warning(
        `Entre em contato com o comercial para realizar a compra de itens fora do Kit. Os valores podem sofrer alterações`,
        { theme: "colored" }
      );
    }

    if (newOrder.addedItems.length > 1) {
      let status = {
        statusName: "remove",
        name: item.produto.nome,
        date: date,
        hours: hours,
        userCode: userCode,
        displayName: displayName,
      };
      dispatch(addStatus(status));

      if (isInverter) {
        removeStringBoxAndMC4();
      } else {
        removeProductFromCart();
      }

      dispatch(removeFromCartKits({ produto: item }));

      toast.info(`${item.produto.nome} removido do carrinho.`, {
        theme: "colored",
      });
    } else {
      toast.info("Não é possivel remover todos os itens", { theme: "colored" });
    }
    setIsOpenDelete(false);
  };

  const removeStringBoxAndMC4 = () => {
    item?.pecasVinculadas?.map(peca => {
      newOrder.addedItems.filter(item => item.produto.sku == peca.sku).map(product => {
        const productItem = product.produto;
        const newQuantity = productItem.qtd - (peca.quantityPerInverter * item.qtd);
        if (newQuantity > 0) {
          editItemQuantity(productItem, newQuantity);
        } else {
          dispatch(removeFromCartKits({ produto: productItem }));
          removeLinkedPartsValTotal(productItem);
        }
      });
    });
  };

  const editItemQuantity = (product, newItemQuantity) => {
    const valTotal = {
      ...valores,
      [product.nome]: valueByQTD(
        product.precoCliente,
        newItemQuantity
      ),
    };
    addValTot(valTotal);
    dispatch(
      editQtdKits(product.nome, newItemQuantity)
    );
  }

  const removeLinkedPartsValTotal = (productItem) => {
    let valTotal = valores;
    delete valTotal[
      productItem?.id
        ? productItem?.id + " " + productItem?.nome
        : productItem.nome
    ];
    addValTot(valTotal);
  }

  const removeProductFromCart = () => {
    dispatch(removeFromCartKits({ produto: item.produto }));
    removeValTot();
    removeValorTabela();
  }

  const removeValTot = () => {
    let newValTotal = valores;

    delete newValTotal[item?.produto?.id ? item?.produto?.id + " " + item.produto.nome : item.produto.nome];
    addValTot(newValTotal);
  };

  const removeValorTabela = () => {
    let newValTabela = [valoresTabela];

    delete newValTabela[item?.produto?.id ? item?.produto?.id + " " + item.produto.nome : item.produto.nome];
    addValoresTabela(newValTabela);
  }

  const modalConfirm = () => {
    setIsOpenDelete(true);
  };

  const modalEditQtd = () => {
    setIsOpenModalEditQtd(true);
  };

  const handleCollapse = () => {
    setCollapse(state => !state);
  };
  const precoCliente = item?.produto?.precoClienteAcrescimo
    ? item?.produto.precoClienteAcrescimo
    : item?.produto.precoCliente;
  const subTotal = precoCliente * qtd

  const showForecast = ItHasForecast.some(value => value.produto.sku == item.produto.sku);

  return (
    <Card className="mb-0 mt-4" style={{ overflow: "hidden" }}>
      <CardBody>
        <Row style={{ alignItems: "center" }}>
          <Col md={6}>
            <p
              data-toggle="tooltip"
              data-placement="top"
              title={item.produto.nome}
              className="mb-0"
              style={{
                fontWeight: 500,
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
            >
              {item?.produto?.nome}
            </p>
          </Col>
          {/*    <Col md={2}>
            {parseFloat(precoCliente)?.toLocaleString("pt-br", formato)}
          </Col> */}
          <Col style={{ padding: 0, textAlign: "center" }} md={2}>
            {item?.produto.linkFichaTecnica ? (
              <a target="_blank" rel="noreferrer" href={item?.produto?.linkFichaTecnica}>
                <FilePdf
                  style={{ cursor: "pointer" }}
                  size={25}
                  color="#FF601A"
                />
              </a>
            ) : (
              ""
            )}
          </Col>
          <Col md={1}>{item?.produto?.qtd ? Math.round(item.produto.qtd) : 1}</Col>{" "}
          {showForecast
            ? <Col md={2}>{item?.produto?.previsao}</Col> 
            : <Col md={2}>Pronta entrega</Col>
          }
          {/*  <Col md={2}>{subTotal.toLocaleString("pt-br", formato)}</Col>{" "} */}
          <Col md={1}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              {/*  <button
                className="collapse-button"
                data-toggle="tooltip"
                data-placement="top"
                title="Adicionar valor de CashBack para este produto"
              >
                <CurrencyCircleDollar
                  onClick={handleCollapse}
                  size={20}
                  color="#FF601A"
                  weight="bold"
                />
              </button> */}
              <button
                onClick={modalEditQtd}
                className="button-trash-and-edit"
                data-toggle="tooltip"
                data-placement="top"
                title="Alterar a quantidade deste produto"
              >
                <Plus size={18} color="#1392c9" weight="bold" />
              </button>
              <button
                onClick={modalConfirm}
                className="button-trash-and-edit"
                data-toggle="tooltip"
                data-placement="top"
                title="Remover este produto"
              >
                <Trash size={18} color="#c91313" weight="bold" />
              </button>
            </div>
          </Col>
        </Row>
        <Row /* style={{ marginTop: collapse ? 11 : 0 }} */>
          <RtAndCashBack
            item={item}
            subTotal={subTotal}
            valores={valores}
            setItensCarrinho={setItensCarrinho}
            setPercentWattpay={setPercentWattpay}
            addValTot={addValTot}
            itensCarrinho={itensCarrinho}
            valArquiteto={valArquiteto}
            addValArqu={addValArqu}
            valorRt={valorRt}
            addValorRt={addValorRt}
            ocultarRt={ocultarRt}
            percentCashBackAll={percentCashBackAll}
            setPercentCashBackAll={setPercentCashBackAll}
            percentCashBackAllOnBlur={percentCashBackAllOnBlur}
            collapse={collapse}
            setValTotAndRt={setValTotAndRt}
            changeFormOfPayment={changeFormOfPayment}
            addValorRtNoRetention={addValorRtNoRetention}
          />
        </Row>
      </CardBody>
      <ConfirmationModal
        message="Confirma remoção deste item?"
        isOpen={isOpenDelete}
        onClose={onCloseDelete}
        onConfirm={onDeleteMessage}
        loading={false}
      />
      <ModalEditQtd
        isOpeModalEditQtd={isOpeModalEditQtd}
        setIsOpenModalEditQtd={setIsOpenModalEditQtd}
        addValTot={addValTot}
        valores={valores}
        item={item.produto}
        setRealPower={setRealPower}
        valoresTabela={valoresTabela}
        addValoresTabela={addValoresTabela}
      />
    </Card>
  );
};

export default AddedItem;
