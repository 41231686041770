import React, { useEffect } from "react";
import { Card, CardBody, Spinner, Row, Col, Table } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { getSlips } from "../../store/order/action";
import { useHistory } from "react-router-dom";
import { apiClient } from "../../api/apiCore";
import { toast } from "react-toastify";


const Slips = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { boletos } = useSelector(state => ({
    boletos: state.Order.boletos,
  }));

  useEffect(() => {
    dispatch(getSlips());
  }, [dispatch, location.pathname]);

  const downloadSlip = async (e, index, file) => {
    try {
      e.preventDefault();

      const response = await apiClient.get(`/api/orders/slips/download`, { 
        params: { file }, 
        responseType: 'blob' 
      });

      const blob = new Blob([response], { type: 'application/pdf' });
      const url = window.URL.createObjectURL(blob);

      const link = document.createElement('a');
      
      link.href = url;
      link.download = `boleto_${index + 1}.pdf`;
      link.click();
      
      window.URL.revokeObjectURL(url);
    } catch (error) {
      toast.error(error, { theme: "colored" });
    }
  }

  return (
    <div className="mt-5">
      <Card className="card-boletos">
        <CardBody className="p-3">
          <div className="slips">
            <h6>Boletos</h6>
            <p>Links de dowload de boletos</p>
          </div>
          {boletos.loading ? (
            <Spinner />
          ) : boletos.success && boletos?.boletos?.length > 0 ? (
            <Row>
              <Col>
                <Table>
                  <tbody>
                    {boletos.boletos.map((value, index) => {
                      return (
                        <tr key={value}>
                          <td>
                            <a
                              href="#"
                              rel="noreferrer"
                              onClick={(e) => downloadSlip(e, index, value)}
                            >
                              Boleto {index + 1}
                            </a>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </Col>
            </Row>
          ) : (
            <p className="mb-0">
              <span className="fw-bold">Busca Finalizada</span>
              <span className="text-muted ms-2">
                Nenhum documento a ser exibido.
              </span>
            </p>
          )}
        </CardBody>
      </Card>
    </div>
  );
};

export default Slips;
