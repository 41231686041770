import React from "react";
import { toast } from "react-toastify";
export class ErrorBoundary extends React.Component {
  state = { hasError: false };

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, info) {
    toast.error(`ERROR: ${error} INFO: ${info}`, { theme: "colored" });
    console.log(error, info);
  }

  render() {
    if (this.state.hasError) {
      return <div style={{ color: "red" }}>{this.props.fallback}</div>;
    }
    return this.props.children;
  }
}
