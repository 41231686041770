import { CaretDown, CaretUp, Notepad } from "phosphor-react";
import React from "react";
import { Card, CardBody, Col, Row } from "reactstrap";
import ReceiptIcon from "../../../components/icons/receipt";

const ObsNF = ({ nfIsOpen, setNfIsOpen, client, setClient }) => {
  return (
    <Card>
      <CardBody>
        <Row>
          <Col md={11}>
            <p
              className="mb-0 d-flex align-items-center"
              style={{ fontWeight: "bold" }}
            >
              {" "}
              <ReceiptIcon
                size={25}
                color="#7EACC4"
                weight="bold"
                style={{
                  marginRight: 20,
                }}
              />
              Adicionar Observação de Nota Fiscal:
            </p>
            <small className="text-muted" style={{ marginLeft: "45px" }}>
              Adicionar dados do recebedor
            </small>
          </Col>
          <Col md={1}>
            <a
              href="#"
              style={{
                width: "fit-content",
                marginLeft: "auto",
                display: "block",
              }}
              onClick={event => {
                event.preventDefault();
                setNfIsOpen(!nfIsOpen);
              }}
            >
              {nfIsOpen ? (
                <CaretUp size={18} weight="bold" />
              ) : (
                <CaretDown size={18} weight="bold" />
              )}
            </a>
          </Col>
        </Row>
        <Row
          hidden={!nfIsOpen}
          style={{
            marginTop: 30,
            paddingTop: 30,
            borderTop: "1px solid #d4d4d4",
          }}
        >
          <textarea
            className="form-control"
            onChange={e =>
              setClient(prev => ({
                ...prev,
                obsNot: e.target.value,
              }))
            }
            value={client.obsNot}
          />
        </Row>
      </CardBody>
    </Card>
  );
};

export default ObsNF;
