import {
  useState,
  SignupSchema,
  Form,
  useEffect,
  Formik,
  initialValuesToFormik,
  useHistory,
  React,
  Row,
  Col,
  StageOne,
  StageTwo,
  request,
} from "./helpers/importer.helper";

export default function MainBudgetView() {
  const [address, setAddress] = useState("");
  const [formStage, setFormStage] = useState(0);
  const [latLng, setMapCenter] = useState({ lat: 0, lng: 0 });
  const [roof, setRoof] = useState("1");
  const [erros, setErros] = useState({
    address: "",
  });

  const history = useHistory();

  const handleSubmit = async (props, { setSubmitting }) => {
    if (erros.address) {
      return;
    }
    let newObj = {
      ...props,
      address: address,
      latitude: latLng.lat,
      longitude: latLng.lng,
      roof_type: roof,
    };
    const resp = await request("post", "/api/solar/smart_calculation", newObj);

    localStorage.setItem("dataUser", JSON.stringify(resp.data));
    history.push(`/solar/raio-solar-no-seu-endereco/${resp.data.id}`);
    setSubmitting(false);
  };

  useEffect(() => {
    if (address != "") {
      setErros({ ...erros, address: "" });
    } else {
      setErros({ ...erros, address: "Campo endereço obrigatório" });
    }
  }, [address]);

  return (
    <>
      <Row>
        <Col md={7} className="mx-auto py-5">
          <div className="solar-styles">
            <div>
              <h1 className="big-title mt-5 mb-3 p-3">
                Olá, que tal descobrir quanto você pode <br /> economizar
                produzindo sua própria energia?
              </h1>
              <h2 className="under-big-title mb-3 p-3">
                Conta pra gente quanto você gasta em média e o seu <br />{" "}
                endereço:
              </h2>
            </div>
            <div className="d-flex j-c-center">
              <div style={{ width: "90%" }}>
                <Formik
                  initialValues={initialValuesToFormik}
                  validationSchema={SignupSchema}
                  onSubmit={handleSubmit}
                >
                  {({ errors, touched, isSubmitting }) => (
                    <Form className="form">
                      {formStage == 0 ? (
                        <StageOne
                          errors={errors}
                          touched={touched}
                          address={address}
                          setAddress={setAddress}
                          setMapCenter={setMapCenter}
                          setRoof={setRoof}
                          erros={erros}
                          setFormStage={setFormStage}
                        />
                      ) : (
                        <StageTwo
                          errors={errors}
                          touched={touched}
                          setFormStage={setFormStage}
                          isSubmitting={isSubmitting}
                        />
                      )}
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
}
