export const API_SUCCESS = "API_SUCCESS";
export const API_FAIL = "API_FAIL";

export const CREATE_PROMOTION_RULES = "CREATE_PROMOTION_RULES";
export const UPDATE_PROMOTION_RULES = "UPDATE_PROMOTION_RULES";

export const GET_RULES_COLUMNS_FIELDS = "GET_RULES_COLUMNS_FIELDS";

export const API_RESPONSE_SUCCESS = "API_RESPONSE_SUCCESS";

export const GET_RULES = "GET_RULES";

export const GET_PRODUCT_RULES = "GET_PRODUCT_RULES";

export const GET_RULES_SELECT = "GET_RULES_SELECT";

export const IS_LOADING = "IS_LOADING";
