import React from "react";
export default function IconWarningCircle(props) {
  const colorFill = props?.colorFill || "none";
  const colorStroke = props?.colorStroke || "#9E8100";

  return (
    <svg
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill={colorFill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.2"
        d="M7.5 13.125C10.6066 13.125 13.125 10.6066 13.125 7.5C13.125 4.3934 10.6066 1.875 7.5 1.875C4.3934 1.875 1.875 4.3934 1.875 7.5C1.875 10.6066 4.3934 13.125 7.5 13.125Z"
        fill="#9E8100"
      />
      <path
        d="M7.5 13.125C10.6066 13.125 13.125 10.6066 13.125 7.5C13.125 4.3934 10.6066 1.875 7.5 1.875C4.3934 1.875 1.875 4.3934 1.875 7.5C1.875 10.6066 4.3934 13.125 7.5 13.125Z"
        stroke={colorStroke}
        strokeWidth="0.9375"
        strokeMiterlimit="10"
      />
      <path
        d="M7.5 4.6875V7.96875"
        stroke={colorStroke}
        strokeWidth="0.9375"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.5 10.7812C7.88833 10.7812 8.20312 10.4665 8.20312 10.0781C8.20312 9.6898 7.88833 9.375 7.5 9.375C7.11167 9.375 6.79688 9.6898 6.79688 10.0781C6.79688 10.4665 7.11167 10.7812 7.5 10.7812Z"
        fill="#9E8100"
      />
    </svg>
  );
}
