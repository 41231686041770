import {
  React,
  SolarInput,
  Button,
  Spinner,
} from "../../helpers/importer.helper";

export default function StageTwo({
  errors,
  touched,
  setFormStage,
  isSubmitting,
}) {
  return (
    <div id="capture-lead-2">
      <SolarInput
        title="Nome Completo"
        errors={errors}
        name="name"
        touched={touched}
      />
      <SolarInput
        title="Email"
        errors={errors}
        name="email"
        touched={touched}
      />
      <SolarInput
        title="Telefone"
        errors={errors}
        name="phone"
        touched={touched}
        mask="phone"
      />{" "}
      <div className="d-flex">
        <Button
          type="button"
          onClick={() => {
            setFormStage(0);
          }}
          className="w-100 solar-bg-gradient-on-holver mt-4 me-5"
        >
          Voltar
        </Button>

        <Button
          disabled={isSubmitting}
          type="submit"
          className="w-100 solar-bg-gradient mt-4 d-flex align-items-center justify-content-center"
        >
          {isSubmitting ? <Spinner size="md" /> : "Enviar"}
        </Button>
      </div>
    </div>
  );
}
