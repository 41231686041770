import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { clearOrderError } from "../../store/actions";
import { Redirect } from "react-router-dom";
import { useSolar } from "../../hooks";

export const ErroOrder = () => {
  const dispatch = useDispatch();
  const isSolar = useSolar();

  useEffect(() => {
    dispatch(clearOrderError());
  }, []);

  return <Redirect to={`${isSolar ? "/solar/pedidos" : "/pedidos"}`} />;
};
