import React, { useEffect, useState } from "react";

import { Card, CardBody, Col, Row } from "reactstrap";
import { XCircle } from "phosphor-react";

import {
  removeCondition
} from "../functions";

import RuleSelection from "./RuleSelection";
import { useSelector } from "react-redux";
import { operatorOptions } from "../constants";
import { useSolar } from "../../../hooks";

const Rules = ({
  cartRuleData,
  selectedRulesConditions,
  setSelectedRulesConditions
}) => {

  const isSolar = useSolar();

  const {
    rulesColumnFields,
  } = useSelector(state => ({
    rulesColumnFields: state.PromotionRules.rulesColumnsFields,
  }));

  const convertToAtributeString = (operator, fieldDescription) => {
    return fieldDescription + " " + operatorOptions.find((item, k) => item.id == operator).selectedDescription;
  };

  const getSelectedCartRules = () => {
    return selectedRulesConditions ? selectedRulesConditions.filter((item, k) => item.column_field.ruletype == 'cart') : [];
  };

  const getSelectedProductsRules = () => {
    return selectedRulesConditions ? selectedRulesConditions.filter((item, k) => item.column_field.ruletype == 'product') : [];
  };

  return (
    <Card>
      <CardBody>
        <div className="d-flex justify-content-center flex-column">
          <h5>
            <i className="fas fa-book me-1"></i> Regras de promoção
          </h5>
          <hr />
          <div id="rulesPromotion">
            <RuleSelection
              cartRuleData={cartRuleData}
              rulesColumnFields={rulesColumnFields}
              selectedRulesConditions={selectedRulesConditions}
              setSelectedRulesConditions={setSelectedRulesConditions}
            />
            <Row>
              <h2 className="font-size-18 font-weight-lighter">
                Se <strong>TODOS</strong> destas condições forem
                <strong> VERDADEIRO:</strong>
              </h2>{" "}
            </Row>
            <Row
              className="mb-3"
              style={{
                overflowY: true ? "scroll" : "auto",
                maxHeight: 400,
              }}
            >
              <Col md={12}>
                <div className="row d-flex justify-content-end">
                  <div className="col-md-11">
                    <p style={{ marginTop: 15, fontWeight: "bold" }}>
                      Atributos do Carrinho:
                    </p>
                    {getSelectedCartRules().length > 0 ? (
                      getSelectedCartRules().map(
                        (item, k) => (
                          <div
                            className="d-flex justify-content-center flex-column - p-2"
                            style={{ borderLeft: "1px solid #dbdbdb" }}
                            key={k}
                          >
                            <div className="d-flex p-2">
                              <p className="mb-0">
                                {convertToAtributeString(item.operator, item.column_field.fielddescription)}
                                {" "}
                                <strong>{item.value}</strong>
                              </p>
                              <div
                                className="d-flex align-items-center justify-content-center  rounded-circle cursor-pointer"
                                style={{
                                  marginLeft: 20,
                                }}
                                onClick={() =>
                                  removeCondition(
                                    item.column_field_id,
                                    selectedRulesConditions,
                                    setSelectedRulesConditions
                                  )
                                }
                              >
                                <XCircle
                                  size={20}
                                  color="#d26060"
                                  weight="fill"
                                />
                              </div>
                            </div>
                          </div>
                        )
                      )
                    ) : (
                      <div
                        className="d-flex justify-content-center flex-column - p-2"
                        style={{
                          borderLeft: "1px solid #dbdbdb",
                          color: "#707c8b",
                        }}
                      >
                        Nenhum atributo registrado
                      </div>
                    )}
                  </div>
                </div>
              </Col>
              {!isSolar && (
                <Col md={12}>
                  <div className="row d-flex justify-content-end">
                    <div className="col-md-11">
                      <p style={{ marginTop: 15, fontWeight: "bold" }}>
                        Atributos do Produto:
                      </p>
                      {getSelectedProductsRules().length > 0 ? (
                        getSelectedProductsRules().map((item, k) => (
                          <div
                            className="d-flex justify-content-center flex-column - p-2"
                            style={{ borderLeft: "1px solid #dbdbdb" }}
                            key={k}
                          >
                            <div className="d-flex p-2">
                              <p className="mb-0">
                                {convertToAtributeString(item.operator, item.column_field.fielddescription)}
                                {" "}
                                <strong>{item.value}</strong>
                              </p>
                              <div
                                className="d-flex align-items-center justify-content-center  rounded-circle cursor-pointer"
                                style={{
                                  marginLeft: 20,
                                }}
                                onClick={() =>
                                  removeCondition(
                                    item.column_field_id,
                                    selectedRulesConditions,
                                    setSelectedRulesConditions
                                  )
                                }
                              >
                                <XCircle
                                  size={20}
                                  color="#d26060"
                                  weight="fill"
                                />
                              </div>
                            </div>
                          </div>
                        ))
                      ) : (
                        <div
                          className="d-flex justify-content-center flex-column - p-2"
                          style={{
                            borderLeft: "1px solid #dbdbdb",
                            color: "#707c8b",
                          }}
                        >
                          Nenhum atributo registrado
                        </div>
                      )}
                    </div>
                  </div>
                </Col>
              )}
            </Row>
          </div>
        </div>
      </CardBody>
    </Card>
  );
}

export default Rules;