import React from "react";
import { Col } from "reactstrap";

const BudgetItemTable = ({ item }) => {
  return (
    <React.Fragment>
      <tr
        style={{
          borderTopWidth: 1,
          borderColor: "#F5F5F5",
          textAlign: "center",
        }}
      >
        <td>
          <Col>{item?.sku}</Col>
        </td>
        <td>
          <Col>{item?.nome}</Col>
        </td>
        <td>
          <Col>
            <p>{item?.qtd > 1 ? Number(item.qtd) : Number(item?.AMOUNT)}</p>
          </Col>
        </td>
        <td>
          <Col>
            <p>{item.previsao}</p>
          </Col>
        </td>
      </tr>
    </React.Fragment>
  );
};

export default BudgetItemTable;
