import React, { useState, useEffect } from "react";
import SettlementsPagination from "./SettlementsPagination";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import SettlementItem from "./SettlementItem";
import Alert from "@mui/material/Alert";
import { useColors } from "../../../hooks/useColors";

function SettlementsList() {
  const { settlementsList } = useSelector(state => ({
    settlementsList: Object.entries(
      state.Settlements.settlementsList.fechamentos
    ).map(e => e[1]),
  }));
  const { colorDefault } = useColors();

  const [asc, setAsc] = useState(true);

  let sorted = settlementsList.sort(function (a, b) {
    if (!asc) {
      if (a.fechamento.NUM > b.fechamento.NUM) {
        return 1;
      }
      if (a.fechamento.NUM < b.fechamento.NUM) {
        return -1;
      }
    } else {
      if (a.fechamento.NUM < b.fechamento.NUM) {
        return 1;
      }
      if (a.fechamento.NUM > b.fechamento.NUM) {
        return -1;
      }
    }
  });
  const [lista, setLista] = useState(sorted);
  const [listaPaginada, setListaPaginada] = useState([]);
  const [index, setIndex] = useState(0);
  const changeIndex = valor => setIndex(valor);

  function paginate(arr) {
    let settlements = [];
    let data = arr;
    while (data.length) {
      settlements.push(data.splice(0, 10));
    }

    return settlements;
  }

  function despaginate(arr) {
    let settlements = [];

    arr.map(value => {
      value.map(item => {
        settlements.push(item);
      });
    });

    return settlements;
  }

  useEffect(() => {
    setListaPaginada(paginate(lista));
  }, [lista, asc]);

  return (
    <div>
      <div className="titulos-tabela text-center">
        <Link
          to="#"
          onClick={() => {
            let data = despaginate(listaPaginada);
            data.sort(function (a, b) {
              if (asc) {
                if (a.fechamento.NUM > b.fechamento.NUM) {
                  return 1;
                }
                if (a.fechamento.NUM < b.fechamento.NUM) {
                  return -1;
                }
              } else {
                if (a.fechamento.NUM < b.fechamento.NUM) {
                  return 1;
                }
                if (a.fechamento.NUM > b.fechamento.NUM) {
                  return -1;
                }
              }
            });
            setAsc(!asc);
            setLista(data);
          }}
        >
          <p style={{ width: "72px", color: colorDefault }}>Numero</p>
        </Link>
        <Link
          to="#"
          onClick={() => {
            let data = despaginate(listaPaginada);
            data.sort(function (a, b) {
              if (asc) {
                if (a.fechamento.ITEM > b.fechamento.ITEM) {
                  return 1;
                }
                if (a.fechamento.ITEM < b.fechamento.ITEM) {
                  return -1;
                }
                return 0;
              } else {
                if (a.fechamento.ITEM < b.fechamento.ITEM) {
                  return 1;
                }
                if (a.fechamento.ITEM > b.fechamento.ITEM) {
                  return -1;
                }
                return 0;
              }
            });
            setAsc(!asc);
            setLista(data);
          }}
        >
          <p style={{ width: "56px", color: colorDefault }}>Itens</p>
        </Link>
        <Link
          to="#"
          onClick={() => {
            let data = despaginate(listaPaginada);
            data.sort(function (a, b) {
              if (asc) {
                if (a.fechamento.FORNECE > b.fechamento.FORNECE) {
                  return 1;
                }
                if (a.fechamento.FORNECE < b.fechamento.FORNECE) {
                  return -1;
                }
              } else {
                if (a.fechamento.FORNECE < b.fechamento.FORNECE) {
                  return 1;
                }
                if (a.fechamento.FORNECE > b.fechamento.FORNECE) {
                  return -1;
                }
              }
            });
            setAsc(!asc);
            setLista(data);
          }}
        >
          <p style={{ width: "82px", color: colorDefault }}>Fornecedor</p>
        </Link>
        <Link
          to="#"
          onClick={() => {
            let data = despaginate(listaPaginada);
            data.sort(function (a, b) {
              if (asc) {
                if (
                  parseFloat(a.fechamento.SUM_TOTAL) >
                  parseFloat(b.fechamento.SUM_TOTAL)
                ) {
                  return 1;
                }
                if (
                  parseFloat(a.fechamento.SUM_TOTAL) <
                  parseFloat(b.fechamento.SUM_TOTAL)
                ) {
                  return -1;
                }
              } else {
                if (
                  parseFloat(a.fechamento.SUM_TOTAL) <
                  parseFloat(b.fechamento.SUM_TOTAL)
                ) {
                  return 1;
                }
                if (
                  parseFloat(a.fechamento.SUM_TOTAL) >
                  parseFloat(b.fechamento.SUM_TOTAL)
                ) {
                  return -1;
                }
              }
            });
            setAsc(!asc);
            setLista(data);
          }}
        >
          <p style={{ width: "115px", color: colorDefault }}>Total</p>
        </Link>
        <Link
          to="#"
          onClick={() => {
            let data = despaginate(listaPaginada);
            data.sort(function (a, b) {
              if (asc) {
                if (a.fechamento.EMISSAO > b.fechamento.EMISSAO) {
                  return 1;
                }
                if (a.fechamento.EMISSAO < b.fechamento.EMISSAO) {
                  return -1;
                }
              } else {
                if (a.fechamento.EMISSAO < b.fechamento.EMISSAO) {
                  return 1;
                }
                if (a.fechamento.EMISSAO > b.fechamento.EMISSAO) {
                  return -1;
                }
              }
            });
            setAsc(!asc);
            setLista(data);
          }}
        >
          <p style={{ width: "99px", color: colorDefault }}>Emissão</p>
        </Link>
        <Link to="#">
          <p style={{ width: "122px", color: colorDefault }}>
            Solicitar Resgate
          </p>
        </Link>
      </div>
      {listaPaginada[index]?.length > 0 ? (
        (listaPaginada[index] || []).map((value, index) => {
          return (
            <SettlementItem
              key={index}
              settlement={value}
              colorDefault={colorDefault}
            />
          );
        })
      ) : (
        <Alert severity="info">Nenhum dado econtrado</Alert>
      )}
      {listaPaginada[index]?.length > 0 && (
        <SettlementsPagination
          settlements={listaPaginada}
          index={index}
          changeIndex={changeIndex}
        />
      )}
    </div>
  );
}

export default SettlementsList;
