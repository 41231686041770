import React, { Fragment, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-scroll"
import styled from "styled-components";

const ControleBtn = styled.button`
    font-size: 16px;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #13b013;
    color: #FFF;
    position: fixed;
    right: 20px;
    bottom: 20px;
    border: none;
    border-radius: 50%;
    z-index: 1000
`;

const ControleRemoto = () => {
    const [openCtl, setOpenCtl] = useState(false);

    const { newOrder } = useSelector(state => ({
        newOrder: state.Order.newOrder.newOrder
    }));

    return (
        <Fragment>
            {openCtl &&
                <nav className="controle-remoto">
                    <Link to="filter" smooth={true} duration={300} activeClass="active"><i className="ph-funnel"></i></Link>
                    {newOrder?.orderProducts && <Link to="busca" smooth={true} duration={300} activeClass="active"><i className="ph-magnifying-glass"></i></Link>}
                    {(newOrder?.addedItems.length || 0) > 0 && <Link to="carrinho" smooth={true} duration={300} activeClass="active"><i className="ph-shopping-cart"></i></Link>}
                    <Link to="cliente" smooth={true} duration={300} activeClass="active"><i className="ph-user"></i></Link>
                    <Link to="notas" smooth={true} duration={300} activeClass="active"><i className="ph-notepad"></i></Link>
                    <Link to="frete" smooth={true} duration={300} activeClass="active"><i className="ph-truck"></i></Link>
                </nav>}
            <ControleBtn onClick={() => setOpenCtl(!openCtl)}>
                {!openCtl ?
                    <i className="fas fa-gamepad"></i>
                    : <i className="fas fa-times"></i>}
            </ControleBtn>
        </Fragment>
    );
}

export default ControleRemoto;