import React, { useEffect, useRef, useState } from "react";
import ReactEcharts from "echarts-for-react";
import { graphic } from "echarts";
import { Card, CardBody, Row, Col } from "reactstrap";

// components
import CustomSelect from "../../components/CustomSelect";

// utils
import { formateDate, formateCurrency } from "../../utils/index";

// data
import { selectOptions } from "./data";

const Sales = ({ data }) => {
  /*
  data state
  */
  const [chartData, setChartData] = useState(data);
  useEffect(() => {
    setChartData(data);
  }, [data]);

  /*
  chart data prep
  */
  const xAxisData = (chartData || []).map(item => {
    let day = null;
    if (item.date) {
      day = new Date(item.date).getDate();
    } else {
      day = null;
    }
    return day;
  });

  const yAxisData = (chartData || []).map(item => item.vendas);

  const chartRef = useRef(null);

  /*
  chart options
  */
  const options = {
    tooltip: {
      show: true,
      enterable: false,
      padding: 0,
      extraCssText:
        "box-shadow: 0px 2.37502px 6.29143px #e0e5eb; border-radius: 20px;",
      position: function (p) {
        return [p[0] - 100, 0];
      },
      trigger: "axis",
      formatter: params => {
        let record = params[0];
        let date = new Date();
        let meta = 0;
        let vendas = 0;
        if (record.dataIndex) {
          const dataIdx = record.dataIndex;
          const dataObj = chartData[dataIdx];
          date = formateDate(dataObj.date, "DD MMMM");
          meta = `Meta ${dataObj.meta ? formateCurrency(dataObj.meta) : "-"}`;
          vendas = `Vendas ${
            dataObj.vendas ? formateCurrency(dataObj.vendas) : "-"
          }`;
        }
        let body =
          '<div class="chart-tooltip">' +
          '<div class="chart-tooltip-inner">' +
          '<div class="px-3 py-2 text-muted font-size-11">' +
          date +
          "</div>" +
          '<div class="px-3 py-2 text-center font-size-13">' +
          '<div class="border-bottom border-secondary pb-2">' +
          meta +
          "</div>" +
          '<div class="pt-2">' +
          vendas +
          "</div>" +
          "</div>" +
          "</div>" +
          "</div>";
        return body;
      },
    },
    grid: {
      left: "3%",
      right: "4%",
      bottom: "3%",
      containLabel: true,
    },
    xAxis: {
      type: "category",
      data: [...xAxisData],
      axisTick: {
        alignWithLabel: true,
      },
      axisLine: {
        show: false,
      },
    },
    yAxis: [
      {
        type: "value",
        axisLine: {
          show: false,
        },
        splitLine: {
          show: true,
          onZero: false,
          lineStyle: {
            type: "dashed",
          },
        },
        axisLabel: {
          color: "#6c757d",
          formatter: value => {
            return formateCurrency(value);
          },
          fontSize: 10,
        },
      },
    ],
    series: [
      {
        name: "Direct",
        type: "bar",
        barWidth: "60%",
        itemStyle: {
          color: new graphic.LinearGradient(0, 0, 0, 1, [
            { offset: 0, color: "#404B5A" },
            { offset: 1, color: "#AEBBCD" },
          ]),
        },
        data: [...yAxisData],
        barWidth: 35,
      },
    ],
  };

  /*
  month selection
  */
  const [selected, setSelected] = useState(selectOptions[8]);
  const onChangeSelect = value => {
    setSelected(value);
  };

  return (
    <div>
      <div className="mt-5 mt-lg-0">
        <Row noGutters>
          <Col xs={6} lg={6}>
            {" "}
            <h4 className="fw-bold mb-4">Suas Vendas</h4>
          </Col>
          <Col lg={3} xs={6} className="text-end ms-auto">
            <div className="text-start">
              <CustomSelect
                value={selected}
                onChange={onChangeSelect}
                options={selectOptions}
              />
            </div>
          </Col>
        </Row>
      </div>
      <Card className="mb-0">
        <CardBody>
          <div style={{ height: 300 }}>
            <ReactEcharts
              ref={chartRef}
              option={options}
              style={{ height: "100%", width: "100%" }}
            />
          </div>
        </CardBody>
      </Card>
    </div>
  );
};

export default Sales;
