import React from "react";
import Flatpickr from "react-flatpickr";
import { Row, Col, InputGroupText, InputGroup, Input, Button, Card, CardBody, Spinner, Label } from "reactstrap";
import { Portuguese } from "flatpickr/dist/l10n/pt.js";
import "flatpickr/dist/themes/material_blue.css";
import "../CadastrarRevenda/cadastrarrevenda.css";
import { mask } from "../../../utils/otherUtils";

const CadastroFilter = ({ filters, setFilters }) => {
  return (
    <>
      <Row className="mt-4 ms-3 justify-content-evenly">
        <Col md={6} className="mt-3">
          <Label>CPF/CNPJ</Label>
          <Input
            className="rounded-lg input-dados"
            placeholder="Digite aqui..."
            maxLength={18}
            value={mask(filters.cpfcnpj)}
            onChange={e =>
              setFilters({ ...filters, [e.target.name]: e.target.value.replace(/[^0-9]+/g, "") })
            }
            name="cpfcnpj"
          />
        </Col>
        <Col md={6} className="mt-3">
          <Label>Nome da Revenda</Label>
          <Input
            onChange={e => setFilters({ ...filters, [e.target.name]: e.target.value })}
            className="rounded-lg input-dados"
            placeholder="Digite aqui..."
            name="nome_revenda"
            value={filters.nome_revenda}
          />
        </Col>
      </Row>
      <Row className="ms-3 justify-content-evenly">
        <Col md={6} className="mt-3">
          <Label>Data de Cadastro</Label>
          <InputGroup>
            <InputGroupText className="text-white border-0">
              <i className="fa fa-calendar-days h5 p-0 m-0" style={{color: "#7EACC4" }}></i>
            </InputGroupText>
            <Flatpickr
              id="data-inicio"
              className="form-control titulo-input-status"
              placeholder="dd/mm/aaaa"
              options={{
                altInput: true,
                locale: Portuguese,
                altFormat: "d F, Y",
                dateFormat: "Ymd",
              }}
              value={filters.dataCadastro}
              onChange={([date]) =>
                setFilters({
                  ...filters,
                  dataCadastro: date ? `${date.getFullYear()}${(date.getMonth() + 1)
                    .toString()
                    .padStart(2, "0")}${date
                      .getDate()
                      .toString()
                      .padStart(2, "0")}` : '',
                })
              }
            />
          </InputGroup>
        </Col>
        <Col md={6} className="mt-3">
          <Label>Situação</Label>
          <select
            className="form-select form-control input-dados"
            name="status"
            value={filters.statusSituacao}
            onChange={e => setFilters({ ...filters, [e.target.name]: e.target.value })}
          >
            <option value="">Todos</option>
            <option value="A">Novo</option>
            <option value="B">Replicado</option>
            <option value="C">Atualizado</option>
            <option value="D">Aprovado</option>
          </select>
        </Col>
      </Row>
    </>
  );
};
export default CadastroFilter;
