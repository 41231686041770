import { all, fork } from "redux-saga/effects";

//public
import AccountSaga from "./auth/register/saga";
import AuthSaga from "./auth/login/saga";
import ForgetSaga from "./auth/forgetpwd/saga";
import ProfileSaga from "./auth/profile/saga";
import LayoutSaga from "./layout/saga";

// Sidebar Menu Items
import sidebarMenuSaga from "./sidebarMenu/saga";

// dashboard
import dashboardSaga from "./dashboard/saga";

//customer
import customersSaga from "./customers/saga";

//download
import downloadSaga from "./download/saga";

//warranty
import warrantySaga from "./warranty/saga";

//Events
import eventSaga from "./events/saga";

// gallery
import gallerySaga from "./gallery/saga";

//order
import orderSaga from "./order/saga";

//rules
import rulesSaga from "./rules/saga";

import budgetSaga from "./budget/saga";

import sellerSaga from "./seller/saga";
import promotionRules from "./promotionRules/saga";
import settlements from "./settlements/saga";

import closures from "./closures/saga";

import orderListSaga from "./orderList/saga";
import managementSaga from "./management/saga";
import chooseCompany from "./chooseCompany/saga";
import leadsSaga from "./leads/saga";
import FireBaseRemoteConfigSaga from "./firebaseRemoteConfig/saga";
import registrationSaga from "./registrations/saga";
import ManufacturersSaga from "./manufacturers/list/saga";
import manufacturerDetailsSaga from "./manufacturers/detail/saga";
import customFieldSaga from "./customField/saga";
import warrantyDownloadSaga from "./warranty/download/saga";
import sharedResalesSaga from "./sharedResales/saga";
import collaboratorSaga from "./collaborator/saga";

export default function* rootSaga() {
  yield all([
    //public
    AccountSaga(),
    fork(AuthSaga),
    ProfileSaga(),
    ForgetSaga(),
    LayoutSaga(),
    sidebarMenuSaga(),
    dashboardSaga(),
    customersSaga(),
    downloadSaga(),
    warrantySaga(),
    registrationSaga(),
    eventSaga(),
    gallerySaga(),
    orderSaga(),
    budgetSaga(),
    sellerSaga(),
    rulesSaga(),
    promotionRules(),
    settlements(),
    closures(),
    orderListSaga(),
    managementSaga(),
    chooseCompany(),
    leadsSaga(),
    FireBaseRemoteConfigSaga(),
    ManufacturersSaga(),
    manufacturerDetailsSaga(),
    customFieldSaga(),
    warrantyDownloadSaga(),
    sharedResalesSaga(),
    collaboratorSaga()
  ]);
}
