import { X } from "phosphor-react";
import React, { useRef, useState } from "react";
import { useHistory, withRouter } from "react-router-dom";
import {
  ModalHeader,
  TituloModal,
  BotaoEntrar,
  Image,
  P,
  BotaoVoltar,
} from "./style";
import {
  Col,
  Row,
  Container,
  Modal,
  ModalBody,
  Spinner,
  Alert,
} from "reactstrap";
import IlustracaoConfirmada from "../../../assets/images/svg/Ilustracao_solicitacao_confirmada.svg";
import Logo from "../../../assets/images/logos/adias/solar_laranja.svg";
import { Formik, Form } from "formik";
import { initialValuesToFormik, RegisterSchema } from "./mockAndValidation";
import { DadosPessoais } from "./Sections/DadosPessoais";
import { Contato } from "./Sections/Contato";
import { Endereco } from "./Sections/Endereco";
import axios from "axios";
import { serialize } from "object-to-formdata";
import { toast } from "react-toastify";
import ReCAPTCHA from "react-google-recaptcha";
import { useDispatch } from "react-redux";
import { openModalRegister } from "../../../store/actions";
import { useDataLayer } from "../../../hooks/DataLayerHooks";

const ModalRegister = ({ cadastro }) => {
  const [erros, setErros] = useState({ cnpjExist: false });
  const { pushToDataLayer } = useDataLayer();
  const formRef = useRef();
  const [data, setData] = useState({ rmatividades: "", conheceu_adias: "" });
  const [isVerified, setIsVerified] = useState(false);
  const [successRegistered, setSuccessRegistered] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const handleSubmit = async (props, { setSubmitting, resetForm }) => {
    if (erros.cnpjExist) {
      return;
    }
    if (data.rmatividades == "" || data.conheceu_adias == "") {
      return toast.error(
        "Selecione o campo Como conheceu a A.Dias e Ramo de Atividade",
        { theme: "colored" }
      );
    }
    let obj = {
      ...props,
      rmatividade: data.rmatividades,
      conheceu_adias: data.conheceu_adias,
      pcontato: "",
    };

    const formData = serialize(obj);

    const resp = await axios.post(
      process.env.REACT_APP_API_URL_2 + "/cadastro/prospect?ajax=1",
      formData
    );

    if (resp.data.success) {
      pushToDataLayer(
        'sign_up',
        {
          user_data: {
            email: props?.EMAILCONT_PF,
            phone: '(' + props?.DDDCONT_PF + ') ' + props?.TELCONT_PF,
            name: props?.NOME_PF,
            address_street: props?.ENDERECO_PF,
            address_city: props?.MUNICIPIO_PF,
            address_region: props?.UF_PF
          }
        }, false
      );
      toast.success(resp.data.msg, { theme: "colored" });
      history.replace("/revenda-cadastro-obrigado");
      setSuccessRegistered(true);
      resetForm();
    } else {
      toast.info(resp.data.msg, { theme: "colored" });
    }

    setSubmitting(false);
  };

  const handleOnChange = () => {
    setIsVerified(true);
  };
  return (
    <Modal
      isOpen={cadastro}
      size="lg"
      toggle={() => dispatch(openModalRegister(false))}
    >
      <Row style={{ marginBottom: 10 }}>
        <Col md={12} style={{ display: "flex", justifyContent: "flex-end" }}>
          <X
            style={{ marginRight: 10, marginTop: 10, cursor: "pointer" }}
            size={20}
            color="#000"
            weight="duotone"
            onClick={() => dispatch(openModalRegister(false))}
          />
        </Col>
      </Row>

      <ModalHeader style={{ padding: "0 36px" }}>
        <Container>
          <Row>
            <Col md={12} className="d-flex justify-content-center">
              <img style={{width: '28%'}} src={Logo} />
            </Col>
          </Row>
        </Container>
      </ModalHeader>

      <ModalBody>
        <Row>
          <Col md={12}>
            <TituloModal>Pré Cadastro Clube A.Dias</TituloModal>
          </Col>
        </Row>

        <Formik
          initialValues={initialValuesToFormik}
          validationSchema={RegisterSchema}
          onSubmit={handleSubmit}
        >
          {({
            errors,
            touched,
            isSubmitting,
            setFieldValue,
            values,
            handleChange,
            handleBlur,
          }) => (
            <Form ref={formRef} style={{ maxWidth: 710, margin: "0 auto" }}>
              {erros.cnpjExist && (
                <Alert color="danger">
                  O CNPJ/CNPJ informado já está cadastrado ou em processo de
                  aprovação
                </Alert>
              )}
              <DadosPessoais
                errors={errors}
                touched={touched}
                setErros={setErros}
                handleChange={handleChange}
                handleBlur={handleBlur}
              />
              <Contato
                errors={errors}
                touched={touched}
                handleBlur={handleBlur}
              />
              <Endereco
                errors={errors}
                touched={touched}
                setFieldValue={setFieldValue}
                setData={setData}
                values={values}
                data={data}
                handleBlur={handleBlur}
              />
              <Row>
                <Col
                  md={12}
                  style={{
                    marginTop: 30,
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <ReCAPTCHA
                    sitekey={"6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI"}
                    onChange={handleOnChange}
                  />
                </Col>
              </Row>
              <Row>
                <Col
                  md={12}
                  style={{ display: "flex", justifyContent: "flex-end" }}
                >
                  <BotaoEntrar
                    type="submit"
                    disabled={isSubmitting || !isVerified}
                  >
                    {isSubmitting ? <Spinner size="sm" /> : "Enviar"}
                  </BotaoEntrar>
                </Col>
              </Row>
            </Form>
          )}
        </Formik>
      </ModalBody>
    </Modal>
  );
};

export default withRouter(ModalRegister);
