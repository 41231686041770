import {
  GET_FIREBASE_REMOTE_CONFIG,
  FIREBASE_REMOTE_CONFIG_SUCCESS,
} from "./actionTypes";

export const firebaseRemoteConfigSuccess = (data, actionType) => {
  return {
    type: FIREBASE_REMOTE_CONFIG_SUCCESS,
    payload: { data, actionType },
  };
};

export const getFireBaseRemoteConfig = (data, defaultValue, actionType) => {
  return {
    type: GET_FIREBASE_REMOTE_CONFIG,
    payload: { data, defaultValue, actionType },
  };
};
