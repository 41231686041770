import React, { useEffect } from "react";
import { ArInput } from "../../../../components/adias-input";
import { Col, Row } from "reactstrap";

export const Contato = ({ errors, touched, data, setFieldValue }) => {
  useEffect(() => {
    setFieldValue("CELULAR", data?.data?.orcamento?.prospect?.US_TEL);
    setFieldValue("DDD_CEL", data?.data?.orcamento?.prospect?.US_DDD);
  }, [data]);
  return (
    <>
      <Row>
        <Col md={1}>
          <ArInput
            title="DDD"
            errors={errors}
            name="DDD"
            touched={touched}
            maxLength={2}
          />
        </Col>
        <Col md={5}>
          <ArInput
            title="FIXO"
            errors={errors}
            name="TEL"
            touched={touched}
            mask="phoneNoDDD"
          />
        </Col>
        <Col md={1}>
          <ArInput
            title="DDD"
            errors={errors}
            name="DDD_CEL"
            touched={touched}
            maxLength={2}
          />
        </Col>
        <Col md={5}>
          <ArInput
            title="Celular"
            errors={errors}
            name="CELULAR"
            touched={touched}
            mask="phoneNoDDD"
          />
        </Col>
      </Row>
    </>
  );
};
