import React, { createContext, useState, useContext } from "react";

export const SideBarContext = createContext(null);

export function SideBarContextProvider({ children }) {
  const [openSidebar, setOpenSidebar] = useState(true);

  return (
    <SideBarContext.Provider
      value={{
        openSidebar,
        setOpenSidebar,
      }}
    >
      {children}
    </SideBarContext.Provider>
  );
}

export const useSideBar = () => {
  const context = useContext(SideBarContext);
  if (!context) throw new Error("shoulbe use with SideBarContextProvider");
  const { openSidebar, setOpenSidebar } = context;
  return {
    openSidebar,
    setOpenSidebar,
  };
};
