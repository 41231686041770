import React, { useEffect, useRef, useState } from "react";
import ReactEcharts from "echarts-for-react";
import { graphic } from "echarts";
import { formateDate, formateCurrency } from "../../utils/index";
import { useColors } from "../../hooks/useColors";

const PurchaseChart = ({ data }) => {
  const { colorLight, colorDefault } = useColors();
  /*
  data state
  */
  const [chartData, setChartData] = useState(data);
  useEffect(() => {
    setChartData(data);
  }, [data]);

  /*
  chart data prep
  */
  const xAxisData = (chartData || []).map(item => {
    let day = null;
    if (item.date) {
      day = new Date(item.date).getDate();
      day++;
    } else {
      day = null;
    }
    return day;
  });

  const yAxisData = (chartData || []).map(item => item.vendas);

  const chartRef = useRef(null);

  /*
  chart options
  */
  const options = {
    tooltip: {
      show: true,
      enterable: false,
      padding: 0,
      extraCssText:
        "box-shadow: 0px 2.37502px 6.29143px #e0e5eb; border-radius: 20px;",
      position: function (p) {
        return [p[0] - 100, 0];
      },
      trigger: "axis",
      formatter: params => {
        let record = params[0];
        let date = new Date();
        let meta = 0;
        let vendas = 0;
        let dia = new Date(chartData[record.dataIndex].date);
        if (!(record.dataIndex < 0)) {
          const dataIdx = record.dataIndex;
          const dataObj = chartData[dataIdx];
          date = formateDate(dataObj.date, "DD MMMM");
          meta = `Meta ${
            dataObj.meta
              ? formateCurrency(dataObj.meta / (dia.getDate() + 1))
              : "-"
          }`;
          vendas = `Vendas ${
            dataObj.vendas ? formateCurrency(dataObj.vendas) : "-"
          }`;
        }

        let body =
          '<div class="chart-tooltip">' +
          '<div class="chart-tooltip-inner">' +
          `<div class="px-3 py-2 font-size-11" style="color: ${colorDefault}; font-weight:bold">` +
          date +
          "</div>" +
          '<div class="px-3 py-2 text-center font-size-13">' +
          '<div class="border-bottom border-secondary pb-2">' +
          meta +
          "</div>" +
          '<div class="pt-2">' +
          vendas +
          "</div>" +
          "</div>" +
          "</div>" +
          "</div>";
        return body;
      },
    },
    grid: {
      left: "3%",
      right: "4%",
      bottom: "3%",
      containLabel: true,
    },
    xAxis: {
      type: "category",
      data: [...xAxisData],
      axisTick: {
        alignWithLabel: true,
      },
      axisLine: {
        show: false,
      },
    },
    yAxis: [
      {
        type: "value",
        axisLine: {
          show: false,
        },
        splitLine: {
          show: true,
          onZero: false,
          lineStyle: {
            type: "dashed",
          },
        },
        axisLabel: {
          color: "#6c757d",
          formatter: value => {
            return formateCurrency(value);
          },
          fontSize: 10,
        },
      },
    ],
    series: [
      {
        name: "Direct",
        type: "bar",
        barWidth: "60%",
        itemStyle: {
          color: new graphic.LinearGradient(0, 0, 0, 1, [
            { offset: 0, color: colorLight },
            { offset: 1, color: colorLight },
          ]),
        },
        data: [...yAxisData],
      },
    ],
  };

  return (
    <div style={{ height: 500 }}>
      <ReactEcharts
        ref={chartRef}
        option={options}
        style={{ height: "100%", width: "100%" }}
      />
    </div>
  );
};

export default PurchaseChart;
