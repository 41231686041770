import React, { useEffect } from "react";
import { Redirect } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

// actions
import { logoutUser } from "../../store/actions";
import { apiClient } from "../../api/apiCore";
import { apiClient2 } from "../../api/apiCore2";

const Logout = () => {
  const dispatch = useDispatch();

  const { iseUserLoggedOut } = useSelector(state => ({
    iseUserLoggedOut: state.Login.iseUserLoggedOut,
  }));

  useEffect(() => {
    logout();
    dispatch(logoutUser());
  }, [dispatch]);

  const logout = async () => {
    await apiClient2.get("/logout");
  };

  if (iseUserLoggedOut) {
    return <Redirect to="/login" />;
  }

  return <></>;
};

export default Logout;
