export default {
  colors: {
    backgroundColor: "#f0f0f0",
    solar: "#FF601A",
    grey: "#696969",
    blue: '#2D567B',
    darkGrey: "#2D2D2D",
    lightGrey: "#b4c0d1",
    black: "#0F0F0F",
    white: "#fff",
    red: "#E50000",
    lightBlue: "#7EACC4",
    lighterBlue: "#BBCAD2",
    lightOrange: "#FF9E73",
    lighterOrange: "#FFE0D3"
  },
};
