import React from 'react';
import { Container, Card, CardBody, CardGroup, CardImg, CardText, Pagination, PaginationItem, PaginationLink } from 'reactstrap';
import "./noticias.css";
import Breadcrumb from "../../components/Breadcrumb";
import { Link } from "react-router-dom";

const Noticias = () => {
    return (
        <React.Fragment>
            <div className="page-content noticias">
                <Container>
                    <h1 className='titulo-noticias'>Notícias</h1>
                    <Breadcrumb title="Página Inicial" breadcrumbItem="Notícias" />
                    <Card>
                        <CardBody>
                            <CardGroup>
                                <Link to="/Noticias/Noticia" className="Card-noticias">
                                    <Card>
                                        <CardImg
                                            alt="Card image cap"
                                            src="https://picsum.photos/318/180"
                                            top
                                            width="100%" />
                                        <CardBody>
                                            <p className="info-card-noticias"><i className="ph-calendar"></i> 15/10/2021 | <i className="ph-user"></i>Paula</p>
                                            <CardText className="card-text">
                                                FIDELIDADE CLUBE A.DIAS 2021 - Outubro
                                            </CardText>
                                        </CardBody>
                                    </Card>
                                </Link>
                                <Link to="/Noticias/Noticia" className="Card-noticias">
                                    <Card>
                                        <CardImg
                                            alt="Card image cap"
                                            src="https://picsum.photos/318/180"
                                            top
                                            width="100%" />
                                        <CardBody>
                                            <p className="info-card-noticias"><i className="ph-calendar"></i> 16/12/2020 | <i className="ph-user"></i>Gabriel</p>
                                            <CardText className="card-text">
                                                Comunicado mudanças de regra de RT
                                            </CardText>
                                        </CardBody>
                                    </Card>
                                </Link>
                                <Link to="/Noticias/Noticia" className="Card-noticias">
                                    <Card>
                                        <CardImg
                                            alt="Card image cap"
                                            src="https://picsum.photos/318/180"
                                            top
                                            width="100%" />
                                        <CardBody>
                                            <p className="info-card-noticias"><i className="ph-calendar"></i> 15/12/2020 | <i className="ph-user"></i>Paula</p>
                                            <CardText className="card-text">
                                                CATEGORIAS - FIDELIDADE CLUBE A.DIAS 2020
                                            </CardText>
                                        </CardBody>
                                    </Card>
                                </Link>
                                <Link to="/Noticias/Noticia" className="Card-noticias">
                                    <Card>
                                        <CardImg
                                            alt="Card image cap"
                                            src="https://picsum.photos/318/180"
                                            top
                                            width="100%" />
                                        <CardBody>
                                            <p className="info-card-noticias"><i className="ph-calendar"></i> 10/09/2020 | <i className="ph-user"></i>Paula</p>
                                            <CardText className="card-text">
                                                Tabela Saldão A.dias - Setembro
                                            </CardText>
                                        </CardBody>
                                    </Card>
                                </Link>
                            </CardGroup>
                            <Pagination>
                                <PaginationItem>
                                    <PaginationLink
                                        first
                                        href="#" />
                                </PaginationItem>
                                <PaginationItem>
                                    <PaginationLink
                                        href="#"
                                        previous />
                                </PaginationItem>
                                <PaginationItem>
                                    <PaginationLink href="#">
                                        1
                                    </PaginationLink>
                                </PaginationItem>
                                <PaginationItem>
                                    <PaginationLink href="#">
                                        2
                                    </PaginationLink>
                                </PaginationItem>
                                <PaginationItem>
                                    <PaginationLink href="#">
                                        3
                                    </PaginationLink>
                                </PaginationItem>
                                <PaginationItem>
                                    <PaginationLink href="#">
                                        4
                                    </PaginationLink>
                                </PaginationItem>
                                <PaginationItem>
                                    <PaginationLink href="#">
                                        5
                                    </PaginationLink>
                                </PaginationItem>
                                <PaginationItem>
                                    <PaginationLink
                                        href="#"
                                        next />
                                </PaginationItem>
                                <PaginationItem>
                                    <PaginationLink
                                        href="#"
                                        last />
                                </PaginationItem>
                            </Pagination>
                        </CardBody>
                    </Card>
                </Container>
            </div>
        </React.Fragment >
    );
};

export default Noticias;