import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { getManufacturersList } from "../../store/actions";

import { Container } from "reactstrap";
import ManufacturersList from "./ManufacturersList";
import Breadcrumb from "../../components/Breadcrumb";

const Manufacturers = () => {

  const dispatch = useDispatch();
    
  const { manufacturers, getManufacturersLoading } = useSelector(state => ({
      manufacturers: state.Manufacturers.manufacturers,
      getManufacturersLoading: state.Manufacturers.loading,
  }));

  useEffect(() => {    
      dispatch(getManufacturersList());
  }, [dispatch]);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container>

          <Breadcrumb 
            title="Página Inicial" 
            breadcrumbItem="Consultar fabricantes"
          />

          <ManufacturersList
            manufacturers={ manufacturers || [] }
            getManufacturersLoading={ getManufacturersLoading }
          />

        </Container>
      </div>
    </React.Fragment>
  )
}

export default Manufacturers;