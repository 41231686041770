import React from "react";

const CartIcon = ({size = "22", style = {}, color = {}}) => (
<svg 
    width={size} 
    style={style} 
    height={size} 
    viewBox="0 0 22 21" 
    fill="none" 
    xmlns="http://www.w3.org/2000/svg">
    <path d="M1.40039 -0.00726318C0.848106 -0.00726318 0.400391 0.440452 0.400391 0.992737C0.400391 1.54502 0.848106 1.99274 1.40039 1.99274V-0.00726318ZM3.69293 0.992737L4.65815 0.731321C4.5401 0.295419 4.14453 -0.00726318 3.69293 -0.00726318V0.992737ZM7.4183 14.748L6.45307 15.0094C6.58334 15.4904 7.04786 15.8021 7.54234 15.7402L7.4183 14.748ZM18.881 13.3151L19.005 14.3074C19.4297 14.2543 19.7735 13.9369 19.8602 13.5177L18.881 13.3151ZM20.6004 5.00468L21.5796 5.20728C21.6406 4.91277 21.5657 4.60647 21.3758 4.37325C21.1859 4.14004 20.9011 4.00468 20.6004 4.00468V5.00468ZM4.77949 5.00468L3.81427 5.26609L4.77949 5.00468ZM1.40039 1.99274H3.69293V-0.00726318H1.40039V1.99274ZM7.54234 15.7402L19.005 14.3074L18.7569 12.3228L7.29427 13.7557L7.54234 15.7402ZM19.8602 13.5177L21.5796 5.20728L19.6211 4.80207L17.9017 13.1125L19.8602 13.5177ZM2.7277 1.25415L3.81427 5.26609L5.74472 4.74326L4.65815 0.731321L2.7277 1.25415ZM3.81427 5.26609L6.45307 15.0094L8.38353 14.4865L5.74472 4.74326L3.81427 5.26609ZM20.6004 4.00468H4.77949V6.00468H20.6004V4.00468ZM10.0004 18.5C10.0004 18.7762 9.77653 19 9.50039 19V21C10.8811 21 12.0004 19.8807 12.0004 18.5H10.0004ZM9.50039 19C9.22425 19 9.00039 18.7762 9.00039 18.5H7.00039C7.00039 19.8807 8.11968 21 9.50039 21V19ZM9.00039 18.5C9.00039 18.2239 9.22425 18 9.50039 18V16C8.11968 16 7.00039 17.1193 7.00039 18.5H9.00039ZM9.50039 18C9.77653 18 10.0004 18.2239 10.0004 18.5H12.0004C12.0004 17.1193 10.8811 16 9.50039 16V18ZM18.0004 18.5C18.0004 18.7762 17.7765 19 17.5004 19V21C18.8811 21 20.0004 19.8807 20.0004 18.5H18.0004ZM17.5004 19C17.2242 19 17.0004 18.7762 17.0004 18.5H15.0004C15.0004 19.8807 16.1197 21 17.5004 21V19ZM17.0004 18.5C17.0004 18.2239 17.2242 18 17.5004 18V16C16.1197 16 15.0004 17.1193 15.0004 18.5H17.0004ZM17.5004 18C17.7765 18 18.0004 18.2239 18.0004 18.5H20.0004C20.0004 17.1193 18.8811 16 17.5004 16V18Z" fill={color}/>
</svg>

);

export default CartIcon;