import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Card, CardBody, Col, Row } from "reactstrap";
import { apiClient } from "../sunbeam-at-your-address/helpers/importer.helper";
import AddedItem from "./AddedItem";

const Cart = () => {
  const data = [
    { name: "TESTE 1", valTotal: 6000 },
    { name: "TESTE 1", valTotal: 6000 },
  ];
  const [kits, setKits] = useState([]);
  const [total, setTotal] = useState(0);
  const { id } = useParams();

  const getListKits = async () => {
    const resp = await apiClient.get(`/api/person-kits/listPersonKits/${id}`);
    setKits(resp[0].itens);
    setTotal(resp[0].valTotal);
  };

  const totalPrecoUniCliFinal = kits.reduce(
    (previousValue, currentValue) => previousValue + currentValue.precoCliente,
    0
  );

  const formato = {
    minimumFractionDigits: 2,
    style: "currency",
    currency: "BRL",
  };
  useEffect(() => {
    getListKits();
  }, []);
  return (
    <div className="page-content">
      <div className="container">
        <Col>
          <h4
            className="mb-4"
            style={{
              fontSize: 18,
              fontWeight: "bold",
              color: "#FF601A",
            }}
          >
            Seu Kit
          </h4>
          <Card>
            <CardBody>
              <Row style={{ alignItems: "center", padding: 20 }}>
                <Col md={2}>Produto</Col>
                <Col md={2}>
                  PREÇO UN
                  <br /> (CUSTO DIST)
                </Col>
                <Col md={2}>
                  PREÇO UN
                  <br /> (CUSTO)
                </Col>
                <Col md={2}>
                  PREÇO UN
                  <br /> (CLIENTE FINAL)
                </Col>
                <Col md={1}>QTDE</Col>
                <Col md={2}>
                  SUBTOTAL
                  <br /> (CLIENTE FINAL)
                </Col>{" "}
              </Row>

              {kits.map((item, k) => (
                <AddedItem key={k} item={item} />
              ))}
              <Card
                style={{
                  borderRadius: "0 0  15px 15px",
                  backgroundColor: "#FFF7F2",
                  zIndex: 100,
                  marginTop: 20,
                }}
              >
                <CardBody>
                  <Row>
                    <Col style={{ color: "#FF601A" }} md={6}>
                      TOTAL DO ORÇAMENTO
                    </Col>
                    <Col style={{ color: "#FF601A" }} md={2}>
                      {totalPrecoUniCliFinal.toLocaleString("pt-br", formato)}
                    </Col>
                    <Col style={{ color: "#FF601A" }} md={2}>
                      -
                    </Col>
                    <Col style={{ color: "#FF601A" }} md={2}>
                      {total.toLocaleString("pt-br", formato)}
                    </Col>{" "}
                  </Row>
                </CardBody>
              </Card>
            </CardBody>
          </Card>
        </Col>
      </div>
    </div>
  );
};

export default Cart;
