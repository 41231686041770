import React from "react";
import "./styles.css";
function Toggle(props) {
  const {
    text,
    size = "default",
    checked,
    disabled,
    onChange,
    offstyle = "btn-danger",
    onstyle = "btn-success",
  } = props;

  let displayStyle = checked ? onstyle : offstyle;
  return (
    <label className="label-switch">
      <span className="switch-label">{text}</span>
      <span className={`${size} switch-wrapper`}>
        <input
          type="checkbox"
          checked={checked}
          disabled={disabled}
          onChange={e => onChange(e)}
        />
        <span style={{ background: displayStyle }} className={` switch`}>
          <span className="switch-handle" />
        </span>
      </span>
    </label>
  );
}

export default Toggle;
