import React from "react";
import "./index.scss";

export default function Main() {
  return (
    <div
      style={{
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <h1 style={{ marginTop: -120 }}>ROTAS SOLAR</h1>
      <a className="button-main" href="/solar/pdf">
        pdf
      </a>
      <br />
      <br />

      <a className="button-main" href="/solar/ordenar-por-consumo">
        ordenar-por-consumo
      </a>
      <br />
      <br />
      <a className="button-main" href="/solar/calculadora-inteligente">
        calculadora-inteligente/etapa-1
      </a>
      <br />
      <br />
      <a className="button-main" href="/solar/raio-solar-no-seu-endereco/1">
        raio-solar-no-seu-endereco
      </a>
      <br />
      <br />
      <a className="button-main" href="/solar-kits-newOrder">
        kit new order
      </a>
    </div>
  );
}
