import {
  CardAnalysisCompleted,
  React,
  Row,
  Col,
} from "../../helpers/importer.helper";

export default function SectionOne({
  userData,
  setMapCenter,
  setAddress,
  address,
  setZoom,
  setAttPlacesAutoComplete,
  attPlacesAutoComplete,
  economiaLiquidaEstimada,
}) {
  let numberFormat = Intl.NumberFormat();
  return (
    <section id="section-1" className="section">
      <Row>
        <Col md={5} className="position-relative">
          <CardAnalysisCompleted
            hoursSunLight={userData?.calculations?.hours_light_per_year || "00"}
            areaInMetters={userData?.calculations?.recommended_size || "00"}
            moneySaved={
              (economiaLiquidaEstimada &&
                numberFormat.format(economiaLiquidaEstimada.toFixed(2))) ||
              "00"
            }
            setMapCenter={setMapCenter}
            setAddress={setAddress}
            address={address}
            setZoom={setZoom}
            setAttPlacesAutoComplete={setAttPlacesAutoComplete}
            attPlacesAutoComplete={attPlacesAutoComplete}
          />
        </Col>
      </Row>
    </section>
  );
}
