import { toast } from "react-toastify";

const formato = {
  minimumFractionDigits: 2,
  style: "currency",
  currency: "BRL",
};

//lidando com "onChange"
export const handleValueUpdate = (
  newValue,
  updatedState,
  setPercent,
  setValCashback,
  setPrecoVenda,
  setValCashbackNoRetention
) => {
  switch (updatedState) {
    case "percent":
      setPercent(newValue);
      break;
    case "cashback":
      setValCashback(newValue);
      setValCashbackNoRetention(newValue);
      break;
    case "precoVenda":
      newValue = newValue.toString().replace(".", "");

      let newString = newValue;

      if (newValue.charAt(newValue.length - 1) === ",") {
        if (newValue.search(",") < newValue.length - 1) {
          newString = newValue.substring(0, newValue.search(",") + 3);
        }
      }
      setPrecoVenda(newString);
      break;
  }
};

//matematica dos valores
export const compareValues = (
  changed,
  precoVenda,
  valCashback,
  valorTabelaComDesconto,
  valores,
  item,
  addValTot,
  setValCashback,
  setPercent,
  setPrecoVenda,
  valMin,
  valMax,
  percent,
  precoItem,
  setValTotAndRt,
  setValCashbackNoRetention
) => {
  const ratio = 2 / 98; // quantos % aumentar o valor levando em conta a diminuição de 30% -> ex: diminuiu o produto em 30%, para voltar ao valor normal, aumentar em ratio%
  let replacedPrecoVenda = parseFloat(precoVenda.toString().replace(",", "."));
  let replacedValCashback = parseFloat(
    valCashback.toString().replace(",", ".")
  );

  const precoVendaChanged = () => {
    //formula inputando preço de venda
    let cashback = (replacedPrecoVenda - valorTabelaComDesconto) * 0.98;
    let percent = (cashback * 100) / valorTabelaComDesconto;

    let valTotal = {
      ...valores,
      [item?.id ? item?.id + " " + item.nome : item.nome]:
        Number(replacedPrecoVenda),
    };
    setValCashback(cashback);
    setValCashbackNoRetention(replacedPrecoVenda - valorTabelaComDesconto);
    setPercent(percent);

    /* addValTot(valTotal); */
  };

  const percentChanged = () => {
    //formula inputando a porcentagem
    let cashbackValue = (((0.98) * percent * valorTabelaComDesconto) / (98 - percent));
    let cashbackWithTax = Number(cashbackValue) + (Number(cashbackValue) * 0.02);
    let precoVenda =  Number(valorTabelaComDesconto) + Number(cashbackWithTax);

    setValCashback(cashbackValue);
    setValCashbackNoRetention(cashbackWithTax);
    setPrecoVenda(precoVenda);
    setValTotAndRt(state => {
      return {
        ...state,
        [item?.id ? item?.id + " " + item.nome : item.nome]: Number(precoVenda),
      };
    });
    /* addValTot(valTotal); */
  };

  const cashbackChanged = () => {
    //formula inputando o cashback
    let percent = (replacedValCashback * 100) / valorTabelaComDesconto;
    let precovenda =
    valorTabelaComDesconto + (replacedValCashback + replacedValCashback * ratio);
    setPrecoVenda(precovenda);
    setPercent(percent);
    /* addValTot(valTotal); */
  };

  switch (changed) {
    case "precoVenda":
      precoVendaChanged();
      break;
    case "percent":
      percentChanged();
      break;
    case "cashback":
      cashbackChanged();
      break;
  }

  checkValues(
    precoVenda,
    valMin,
    valMax,
    valCashback,
    percent,
    valores,
    item,
    precoItem,
    addValTot,
    setPrecoVenda,
    setPercent,
    setValCashback,
    setValCashbackNoRetention
  );
};

//limitando os valores
export const checkValues = (
  precoVenda,
  valMin,
  valMax,
  valCashback,
  percent,
  valores,
  item,
  precoItem,
  addValTot,
  setPrecoVenda,
  setPercent,
  setValCashback,
  setValCashbackNoRetention
) => {
  let precoVendaT = parseFloat(precoVenda);
  
  if (precoVendaT < valMin || precoVendaT > valMax) {
    resetValues(
      precoItem,
      valores,
      item,
      addValTot,
      setPrecoVenda,
      setPercent,
      setValCashback,
      setValCashbackNoRetention
    );
    toast.warning(
      `O preço de venda deve estar entre ${valMin.toLocaleString(
        "pt-br",
        formato
      )} e ${valMax.toLocaleString("pt-br", formato)}`,
      { theme: "colored" }
    );
  }

  if (valCashback < 0) {
    resetValues(
      precoItem,
      valores,
      item,
      addValTot,
      setPrecoVenda,
      setPercent,
      setValCashback,
      setValCashbackNoRetention
    );
    toast.warning(`Valor de cashback inválido`, { theme: "colored" });
  }

  if (percent < 0 || percent > 50) {
    resetValues(
      precoItem,
      valores,
      item,
      addValTot,
      setPrecoVenda,
      setPercent,
      setValCashback,
      setValCashbackNoRetention
    );
    toast.warning(`A % deve estar entre 0% e 50%`, { theme: "colored" });
  }
};

//resetando valores
const resetValues = (
  precoItem,
  valores,
  item,
  addValTot,
  setPrecoVenda,
  setPercent,
  setValCashback,
  setValCashbackNoRetention
) => {
  let valTotal = {
    ...valores,
    [item?.id ? item?.id + " " + item.nome : item.nome]: Number(precoItem),
  };
  addValTot(valTotal);
  setPrecoVenda(precoItem);
  setPercent(0);
  setValCashback(0);
  setValCashbackNoRetention(0);
};
