import { OrderListActionTypes } from "./actionTypes";

export const orderListApiSuccess = (actionType, data) => ({
  type: OrderListActionTypes.API_RESPONSE_SUCCESS,
  payload: { actionType, data },
});

export const orderListApiFail = (actionType, error) => ({
  type: OrderListActionTypes.API_RESPONSE_ERROR,
  payload: { actionType, error },
});


export const getOrdersList = filters => ({
  type: OrderListActionTypes.GET_ORDER_LIST,
  payload: filters,
});
