import React, { useState } from 'react';
import { Container, Alert, Card, CardBody } from 'reactstrap';
import "./gcloudPrint.css";
import Breadcrumb from "../../components/Breadcrumb";
const GcloudPrint = () => {
    const [temConsulta, setTemConsulta] = useState(true)
    return (
        <React.Fragment>
            <div className="page-content gcloud-print">
                <Container>
                    <div>
                        {temConsulta &&
                            <Alert className='alerta-gerar-token'
                                color="danger">
                                Não conseguimos gerar um novo token
                                <a href="#" onClick={(e) => {
                                    e.preventDefault();
                                    setTemConsulta(!temConsulta)
                                }}>
                                    <i className="ph-x"></i>
                                </a>
                            </Alert>
                        }
                    </div>
                    <h1 className='titulo-pagina-impressora'>Minha Impressora</h1>
                    <Breadcrumb
                        title="Página Inicial"
                        breadcrumbItem="Configurar Impressora" />
                    <Card>
                        <CardBody>
                            <h3 className='titulo-card-config'><i className="ph-printer"></i>Configuração de impressora</h3>
                            <hr />
                            <p>Bem Vindo a configuração de Impressão. O Clube ADias liberou para você a impressão na nuvem, com este recurso é possível que a partir de qualquer computador ou dispositivo você imprima em uma única impressora, podendo ser no seu escritório ou casa.</p>
                            <p>Você precisa apenas acessar com a sua conta do Google abaixo, e selecionar qual impressora que deseja tornar padrão.</p>
                            <p><strong>Atenção:</strong> Os recursos do Clube para impressão na nuvem estão sendo liberados gradualmente.</p>
                            <a href="https://accounts.google.com/o/oauth2/auth?client_id=932560086583-6240omnbsse59kihfobavrh4hloej0d0.apps.googleusercontent.com&redirect_uri=https%3A%2F%2Fclube.adias.com.br%2Fgcloud%2Fauthredirect&response_type=code&scope=https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fcloudprint&access_type=offline" className='btn-login-google'><i className="ph-google-logo"></i>Vincular minha conta</a>
                        </CardBody>
                    </Card>
                    <Card>
                        <CardBody>
                            <h3 className='titulo-select-impressora'><i className="ph-printer"></i>Selecione uma impressora</h3>
                            <hr />
                            <p>Chegou a hora de você selecionar qual impressora deseja </p>
                            <div>
                                <Alert color="warning">
                                    Defina sua impressora favorita antes de imprimir
                                </Alert>
                            </div>
                            <div>
                                <Alert className='alert-conecte'>
                                    Conecte-se a sua conta do Google para poder imprimir
                                </Alert>
                            </div>
                        </CardBody>
                    </Card>
                </Container>
            </div>
        </React.Fragment >
    );
};
export default GcloudPrint;