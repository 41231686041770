import React from "react";
import { Card, CardBody } from "reactstrap";
import PurchaseChart from "./PurchaseChart";
const PurchasePotential = ({ data }) => {
  const pedidos_por_dia = (data?.pedidos_por_dia && data?.pedidos_por_dia.values) || [];
  return (
    <Card>
      <CardBody className="grafico">
        <h5 className="font-size-18 mb-5">Potencial de Compra</h5>
        <div>
          <PurchaseChart data={pedidos_por_dia || []} />
        </div>
      </CardBody>
    </Card>
  );
};

export default PurchasePotential;