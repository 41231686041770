import React, { useEffect } from "react";
import { TitleInputs } from "../../style";
import { ArInput } from "../../../../components/adias-input";
import { Col, Row } from "reactstrap";
import formik from "formik";
import axios from "axios";
import { Select, TitleSelect } from "./styles";
import { useDispatch, useSelector } from "react-redux";
import { getCustomer } from "../../../../store/actions";
import { validaCpfCnpj } from "../../../../utils";
import { useState } from "react";

export const DadosDoCliente = ({
  errors,
  touched,
  data,
  values,
  handleChange,
  handleBlur,
  setFieldValue,
  setErrors,
  setTouched,
  errorEmail,
  setErrorEmail,
}) => {
  const dispatch = useDispatch();
  const { customer } = useSelector(state => ({
    customer: state.Order.newOrder.newOrder.customer,
  }));

  useEffect(() => {
    setFieldValue("N_COMPLETO", data?.data?.orcamento?.prospect.US_NOME);
    setFieldValue("EMAIL", data?.data?.orcamento?.prospect?.US_EMAIL.trim());
  }, [data]);

  useEffect(() => {
    if (values.CPFCNPJ.length <= 14) {
      setFieldValue("TP_PESSOA", "F");
    } else {
      setFieldValue("TP_PESSOA", "J");
    }
  }, [values.CPFCNPJ]);

  const getDataUser = () => {
    let cpfcnpj = values.CPFCNPJ?.replace(/[^0-9]+/g, "");
    if (validaCpfCnpj(cpfcnpj) || cpfcnpj != "") {
      dispatch(getCustomer({ cpfcnpj: cpfcnpj }));
    }
  };

  useEffect(() => {
    if (customer.data) {
      setErrorEmail(true);
    } else {
      setErrorEmail(false);
    }
  }, [customer]);

  return (
    <>
      <Row>
        <Col md={6}>
          <ArInput
            title="CPF/CNPJ"
            errors={errors}
            name="CPFCNPJ"
            touched={touched}
            mask={"cnpj"}
            maxLength={18}
            onBlur={getDataUser}
          />
          {errorEmail && (
            <p className="erros">Cpf ou CNPJ já registrado em nosso sistema</p>
          )}
        </Col>
        <Col md={6}>
          <TitleSelect>Tipo de Pessoa</TitleSelect>
          <Select
            value={values.TP_PESSOA}
            name="TP_PESSOA"
            onChange={handleChange}
            onBlur={handleBlur}
          >
            <optgroup label="CNPJ">
              <option value="J">Jurídica</option>
            </optgroup>
            <optgroup label="CPF">
              <option value="F">Física</option>
            </optgroup>
          </Select>
        </Col>
      </Row>
      <Row>
        {" "}
        <Col md={6}>
          <TitleSelect>* Campo obrigatório.</TitleSelect>
          <Select
            value={values.NOME_REDUZ}
            name="Contrib"
            onChange={handleChange}
            onBlur={handleBlur}
          >
            <option value="2">Isento</option>
            <option value="1">Estadual</option>
          </Select>
        </Col>
        <Col md={6}>
          {values.TP_PESSOA == "J" ? (
            <ArInput
              title="Inscrição Estadual"
              errors={errors}
              name="Inscr"
              touched={touched}
            />
          ) : (
            <ArInput title="RG" errors={errors} name="Rg" touched={touched} />
          )}
        </Col>
      </Row>

      <TitleInputs>DADOS PESSOAIS</TitleInputs>
      <Row>
        <Col md={6}>
          <ArInput
            title="Razão social / Nome Completo"
            errors={errors}
            name="N_COMPLETO"
            touched={touched}
          />
        </Col>
        <Col md={6}>
          <ArInput
            title="Nome fantasia / Nome Reduzido"
            errors={errors}
            name="N_REDZ"
            touched={touched}
            value={values.N_REDZ}
          />
        </Col>
      </Row>
      <Row>
        {" "}
        <Col md={12}>
          <ArInput
            title="Email"
            errors={errors}
            name="EMAIL"
            touched={touched}
          />
        </Col>
      </Row>
      <Row>
        {" "}
        {values.TP_PESSOA == "F" && (
          <Col md={4}>
            <ArInput
              title="Data Nascimento"
              type="date"
              errors={errors}
              name="DT_NASC"
              touched={touched}
            />
          </Col>
        )}
        <Col md={values.TP_PESSOA == "F" ? 4 : 6}>
          <ArInput
            title="Contato"
            errors={errors}
            name="CONTATO"
            touched={touched}
          />
        </Col>
        <Col md={values.TP_PESSOA == "F" ? 4 : 6}>
          <ArInput
            title="Cargo"
            errors={errors}
            name="CARGO"
            touched={touched}
          />
        </Col>
      </Row>
    </>
  );
};
