import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { CardBody, Col, Row, Card, Modal, ModalBody, ModalHeader, Input, InputGroup, InputGroupText, Button } from "reactstrap";
import { getCuponsNotUsed, verificarCupom } from '../../store/order/action';
import cupomAzul from "../../assets/images/cupom-azul.svg"

const Cupons = () => {
    const dispatch = useDispatch();
    const [isOpen, setIsOpen] = useState(false);
    const [cupomActive, setCupomActive] = useState({});
    const [cupomSearch, setCupomSearch] = useState('');
    const { cuponsDisponiveis } = useSelector(state => ({
        cuponsDisponiveis: state.Order.newOrder.newOrder.cuponsDisponiveis,
    }));

    function formataData(data) {
        const monthNames = ["Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"];
        let dataF = new Date(data);
        return `${dataF.getDate()} de ${monthNames[dataF.getMonth()]} de ${dataF.getFullYear()}`;
    }

    useEffect(() => {
        dispatch(getCuponsNotUsed());
    }, [dispatch])

    return (
        <React.Fragment>
            <Card>
                <CardBody>
                    <Row>
                        <Col className="d-flex justify-content-between">
                            <p className="mb-0 d-flex align-items-center" style={{ fontWeight: "bold" }}><i className="ph-ticket" style={{ color: "#13b013", marginRight: 20, fontSize: 25 }}></i>Cupom Adias</p>
                            <Link to="#" onClick={() => setIsOpen(!isOpen)} className="mb-0 d-flex align-items-center" style={{ marginLeft: 20, flex: 1 }}>Selecione ou insira seu código <i className="fas fa-arrow-right" style={{ marginLeft: 15 }}></i></Link>
                            {cupomActive.coupom_used && <p className="mb-0"><strong>Selecionado: </strong>{cupomActive.coupom_used}</p>}
                        </Col>
                    </Row>
                </CardBody>
            </Card>
            <Modal
                isOpen={isOpen}
                size="lg"
            >
                <ModalHeader toggle={() => setIsOpen(!isOpen)}>
                    Selecione ou insira seu código:
                </ModalHeader>
                <ModalBody>
                    <Row className='d-flex justify-content-center'>
                        <Col md={6}>
                            <InputGroup>
                                <Input placeholder='Digite o código...' value={cupomSearch} onChange={e => setCupomSearch(e.target.value)} />
                                <InputGroupText className='d-flex align-items-center' style={{ color: "#FFF" }} onClick={() => {
                                    if (cupomSearch) {
                                        dispatch(verificarCupom(cupomSearch))
                                    } else {
                                        dispatch(getCuponsNotUsed());
                                    }
                                }}>
                                    <i className="ph-magnifying-glass"></i>
                                </InputGroupText>
                            </InputGroup>
                        </Col>
                    </Row>
                    <Row>
                        {cuponsDisponiveis && cuponsDisponiveis.length > 0 ?
                            cuponsDisponiveis.map((value, index) => (
                                <Col md={6} className='d-flex justify-content-between align-items-center mt-4' key={index}>
                                    <div
                                        style={{
                                            backgroundImage: `url(${cupomAzul})`,
                                            backgroundSize: "cover",
                                            backgroundPosition: "center",
                                            height: 140,
                                            width: "56%",
                                            padding: "0 25px",
                                        }}
                                        className='d-flex flex-column justify-content-center'
                                    >
                                        <h4 style={{ fontSize: 18, color: "#FFF" }}>{value.rule_name}</h4>
                                        <p className='mb-0' style={{ color: "#FFF" }}>{value.rule_description}</p>
                                    </div>
                                    <div
                                        className='d-flex flex-column justify-content-center'
                                        style={{
                                            padding: "0 10px",
                                            borderRadius: "3px",
                                            border: index % 2 == 0 ? "1px solid #269fec" : "1px solid #13b013",
                                            height: 140,
                                            // width: "55%"

                                        }}>
                                        <p>Disponível até:</p>
                                        <h5 style={{ fontSize: 14 }}>{formataData(value.end)}</h5>
                                        <Button className='d-block'
                                            style={{
                                                backgroundColor: index % 2 == 0 ? "#269fec" : "#13b013",
                                                color: "#FFF"
                                            }}
                                            onClick={(e) => {
                                                e.preventDefault();
                                                if (!(cupomActive == value)) {
                                                    setCupomActive(value);
                                                    setIsOpen(false)
                                                } else {
                                                    setCupomActive("");
                                                }
                                            }}
                                        >
                                            {cupomActive == value ? "Remover" : "Selecionar"}
                                        </Button>
                                    </div>
                                </Col>
                            ))
                            : <Col className='mt-4'><p>Não há cupom disponível nesse momento.</p></Col>}
                    </Row>
                </ModalBody>
            </Modal>
        </React.Fragment>
    );
}

export default Cupons;