import PlacesAutoComplete from "../../../../components/places-auto-complete";

import {
  React,
  SolarInput,
  useEffect,
  useState,
  Input,
  Button,
  apiClient,
} from "../../helpers/importer.helper";

export default function StageOne({
  errors,
  touched,
  address,
  setAddress,
  setMapCenter,
  setRoof,
  erros,
  setFormStage,
}) {
  const [loadingSelect, setLoadingSelect] = useState(false);
  const [roofs, setRoofs] = useState([{}]);

  const getRoofs = () => {
    setLoadingSelect(true);
    apiClient.get(`/api/solar/roofs`).then(res => {
      setRoofs(res.data);
    });
    setLoadingSelect(false);
  };

  useEffect(() => {
    getRoofs();
  }, []);

  const handleSecondyForm = () => {
    setFormStage(1);
  };
  return (
    <div id="capture-lead-1">
      <div className="position-relative">
        <SolarInput
          title="Gasto médio da sua conta de luz"
          errors={errors}
          name="avg_account_price"
          touched={touched}
          style={{ paddingLeft: 50 }}
          mask="money"
        />
        <div className="position-absolute icon-absolute-left">
          <span>R$ </span>
        </div>
      </div>
      <label htmlFor="select-stret" className="mt-4 mb-2">
        Seu endereço
      </label>
      <PlacesAutoComplete
        address={address}
        setAddress={setAddress}
        setMapCenter={setMapCenter}
      />

      <label htmlFor="select-stret" className="mt-4 mb-2">
        Tipo de telhado
      </label>
      <Input
        type="select"
        onChange={e => setRoof(e.target.value)}
        disabled={loadingSelect}
      >
        {roofs?.map(item => {
          return (
            <option key={item.index} value={item?.index}>
              {loadingSelect ? "..." : item?.roof_name}
            </option>
          );
        })}
      </Input>
      <Button
        type="button"
        disabled={errors.avg_account_price || erros.address ? true : false}
        onClick={() => handleSecondyForm(errors)}
        className="w-100 solar-bg-gradient mt-4"
      >
        Continuar
      </Button>
    </div>
  );
}
