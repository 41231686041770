import { ManufacturersActionTypes } from "./actionTypes";

export const manufacturersApiSuccess = (actionType, data) => ({
    type: ManufacturersActionTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, data },
  });
  
export const manufacturersApiFail = (actionType, error) => ({
    type: ManufacturersActionTypes.API_RESPONSE_ERROR,
    payload: { actionType, error },
});

export const getManufacturersList = filters => ({
    type: ManufacturersActionTypes.GET_MANUFACTURERS_LIST,
    payload: filters
})