import {
  Logo,
  FotoSolarCapa,
  Rectangle,
  RectangleClaro,
  RectangleBlack,
  React,
} from "../../helpers/importer.helper";
import "./styles.scss";

export default function Capa({ dataPdf }) {
  return (
    <div className="content-pdf-3">
      <div className="container-text">
        <div className="content-header">
          <div className="contact">
            <span>
              Telefone: ({dataPdf?.dataOrcamento?.cliente?.DDD_CEL}){" "}
              {dataPdf?.dataOrcamento?.cliente?.CEL}
            </span>
            <br />
            <span>E-mail: {dataPdf?.dataOrcamento?.cliente?.email}</span>
          </div>
          <img className="image-logo-3" src={Logo} />
        </div>
        <p>
          Estudo Simplificado de Viabilidade
          <br /> Sistema Solar Fotovoltaico
        </p>
        <p className="strong">
          {dataPdf?.dataOrcamento?.cliente?.nome}
          <br />
          {dataPdf?.dataOrcamento?.cliente?.endereco}
        </p>

        <p className="strong">{dataPdf?.lead?.lead?.kwp.toFixed(2)} kWp</p>
      </div>
      <img className="background-image" src={FotoSolarCapa} />
      <div className="middle">
        <img className="retangolo" src={Rectangle} />
        <img className="retangolo-claro" src={RectangleClaro} />{" "}
        <div className="retangolo-black">
          <img src={RectangleBlack} />{" "}
          <span>
            <p className="title">Proposta</p> <br />
            <p className="subscription">Comercial</p>
          </span>
        </div>
      </div>
    </div>
  );
}
