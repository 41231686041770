import {
  CardDefault,
  IconHouse,
  radioLabels,
  IconStoreFront,
  IconFactory,
  IconTree,
  InputGroup,
  Input,
  InputGroupText,
  React,
  Row,
  Col,
} from "../../helpers/importer.helper";
import { formatMoney } from "../../../../../utils/priceUtils";

export default function SectionTwo({
  data,
  setData,
  typeOfEstablishment,
  setTypeOfEstablishment,
  updateInfoUser,
  accountPriceType,
  setAccountPriceType,
  dataSotarageUser,
}) {
  const onBlurMoney = () => {
    updateInfoUser("attMoney");
  };
  return (
    <section id="section-2" className="section">
      <div className="my-5 pt-2">
        <h2 className="under-big-title f-w-700">
          Ajuste suas informações para descobrir quanto você pode economizar.
        </h2>
      </div>
      <CardDefault
        checkbox={true}
        icon={<IconHouse />}
        title="Tipo de estabelecimento"
        onChange={e =>
          setData({ ...data, has_contracted_demand: e.target.checked })
        }
      >
        <div>
          <Row className="me-5 position-relative px-4">
            {radioLabels.map((item, index) => (
              <Col
                md={2}
                className={`d-flex me-4 opacity-2 ${
                  typeOfEstablishment == item ? "active" : null
                }`}
                key={`radio-${index}`}
              >
                <input
                  id={`id-choice-${index}`}
                  onChange={() => {
                    setTypeOfEstablishment(item);
                  }}
                  checked={typeOfEstablishment == item ? true : false}
                  type="radio"
                  name="radio-choice"
                />
                <label
                  htmlFor={`id-choice-${index}`}
                  className="d-flex j-c-center cursor-pointer"
                >
                  <div className="d-flex">
                    <div className="mx-2 d-flex j-c-center">
                      {index == 0 ? (
                        <IconHouse />
                      ) : index == 1 ? (
                        <IconStoreFront />
                      ) : index == 2 ? (
                        <IconFactory />
                      ) : (
                        <IconTree />
                      )}
                    </div>
                    <div className="d-flex j-c-center">
                      <h3 className="font-size-12 active m-0">{item}</h3>
                    </div>
                  </div>
                </label>
              </Col>
            ))}
          </Row>
          <div className="mt-3">
            <InputGroup className="input-group">
              <Input
                type="select"
                name="select"
                value={accountPriceType}
                onChange={e => {
                  setData({
                    ...data,
                    avg_account_price: "",
                  });
                  setAccountPriceType(e.target.value);
                }}
              >
                <option value="real">Valor (R$/mês)</option>
                <option value="kwh">Valor (kWh/mês)</option>
              </Input>
              <InputGroupText>
                <input
                  style={{ width: 90, height: "100%", border: "none" }}
                  type="text"
                  value={data?.avg_account_price}
                  onChange={e =>
                    setData({
                      ...data,
                      avg_account_price_type: accountPriceType,
                      avg_account_price: formatMoney(e.target.value),
                    })
                  }
                  onBlur={onBlurMoney}
                />
              </InputGroupText>
            </InputGroup>
          </div>
        </div>
      </CardDefault>
    </section>
  );
}
