import T3M from "../assets/images/logos/3M.png";
import AG from "../assets/images/logos/AG.png";
import BR from "../assets/images/logos/BR.png";
import CA from "../assets/images/logos/CA.png";
import CO from "../assets/images/logos/CO.png";
import DA from "../assets/images/logos/DA.png";
import EL from "../assets/images/logos/EL.png";
import ET from "../assets/images/logos/ET.png";
import EX from "../assets/images/logos/EX.png";
import FO from "../assets/images/logos/FO.png";
import FU from "../assets/images/logos/FU.png";
import GR from "../assets/images/logos/GR.png";
import HI from "../assets/images/logos/HI.png";
import LG from "../assets/images/logos/LG.png";
import MI from "../assets/images/logos/MI.png";
import MO from "../assets/images/logos/MO.png";
import MT from "../assets/images/logos/MT.png";
import OK from "../assets/images/logos/OK.png";
import OL from "../assets/images/logos/OL.png";
import PA from "../assets/images/logos/PA.png";
import PH from "../assets/images/logos/PH.png";
import RH from "../assets/images/logos/RH.png";
import SA from "../assets/images/logos/SA.png";
import SM from "../assets/images/logos/SM.png";
import SP from "../assets/images/logos/SP.png";
import TO from "../assets/images/logos/TO.png";
import TR from "../assets/images/logos/TR.png";
import TT from "../assets/images/logos/TT.png";
import TC from "../assets/images/logos/TC.png";
import TS from "../assets/images/logos/TS.png";
import WE from "../assets/images/logos/WG.png";

export function returnLogo(code) {
  switch (code) {
    case "3M":
      return T3M;
    case "AG":
      return AG;
    case "BR":
      return BR;
    case "CA":
      return CA;
    case "CO":
      return CO;
    case "DA":
      return DA;
    case "EL":
      return EL;
    case "ET":
      return ET;
    case "EX":
      return EX;
    case "FO":
      return FO;
    case "FU":
      return FU;
    case "GR":
      return GR;
    case "HI":
      return HI;
    case "LG":
      return LG;
    case "MI":
      return MI;
    case "MO":
      return MO;
    case "MT":
      return MT;
    case "OK":
      return OK;
    case "OL":
      return OL;
    case "PA":
      return PA;
    case "PH":
      return PH;
    case "RH":
      return RH;
    case "SA":
      return SA;
    case "SM":
      return SM;
    case "SP":
      return SP;
    case "TO":
      return TO;
    case "TR":
      return TR;
    case "TT":
      return TT;
    case "TC":
      return TC;
    case "TS":
      return TS;
    case "WE":
      return WE;
    default:
      return null;
  }
}
