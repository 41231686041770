import React, { useState } from 'react';
import { Container, Row, Col, Card, CardBody, Form, FormGroup, Label, Input, Button,Alert } from 'reactstrap';
import "./pagamentosPendentes.css";


const CartaoPagamentosPendentes = () => {
    const [showedOpt, setShowedOpt] = useState(0)

    return (
        <React.Fragment>
            <div className="page-content pagamentos-pendentes">
                <Container>
                    <Row>
                        <Col md={6}>
                            <Card>
                                <CardBody>
                                    <div className="navegacao">
                                        <ul className="nav nav-pills nav-fill back-nav">
                                            <li className="nav-item">
                                                <a className={showedOpt == 0 ? "nav-link active" : "nav-link"} onClick={() => setShowedOpt(0)}>Filtrar status pagamento</a>
                                            </li>
                                            <li className="nav-item">
                                                <a className={showedOpt == 1 ? "nav-link active" : "nav-link"} onClick={() => setShowedOpt(1)}>Enviar planilha pagamento</a>
                                            </li>
                                        </ul>
                                    </div>
                                    <hr className='linha-status' />

                                    {showedOpt == 0 &&
                                        <div className='planilha-endereco'>
                                            <h4 style={{color: "#2D567B"}}><i className="ph-funnel icone-planilhas"></i> FILTRAR</h4>
                                            <Form>
                                                <FormGroup row>
                                                    <Label
                                                        for="Number"
                                                        sm={3}>
                                                        Número de fechamento:
                                                    </Label>
                                                    <Col sm={6}>
                                                        <Input
                                                            id="exampleNumber"
                                                            name="Number"
                                                            placeholder="Núm. Fechamento"
                                                            type="number" />
                                                    </Col>
                                                </FormGroup>
                                            </Form>
                                            <hr />
                                            <Button className='baixar-planilhas'>
                                                <i className="ph-funnel icone-planilhas"> </i>
                                                Filtrar
                                            </Button>

                                            <Alert>
                                            Necessário buscar pelo menos um fechamento.
                                            </Alert>
                                        </div>

                                    }
                                    {showedOpt == 1 &&
                                        <div className='planilha-portador'>
                                            <h4 style={{color: "#2D567B"}}><i className="ph-funnel icone-planilhas"></i>ENVIAR PLANILHA DE PAGAMENTO VALE PRESENTE</h4>
                                            <Form>
                                                <FormGroup row>
                                                    <Label
                                                        for="exampleNumber"
                                                        sm={3}>
                                                        Número de Fechamento:
                                                    </Label>
                                                    <Col sm={6}>
                                                        <Input
                                                            id="number"
                                                            name="number"
                                                            placeholder="Núm. Fechamento"
                                                            type="data" />
                                                    </Col>
                                                </FormGroup>
                                            </Form>
                                            <Form method="post" enctype="multipart/form-data">
                                                <div>
                                                    <Label for="file">Planilha:</Label>
                                                    <Input type="file" id="file" name="file" multiple />
                                                </div>
                                            </Form>
                                            <hr />
                                            <Button className='baixar-planilhas'>
                                                <i className="ph-funnel icone-planilhas"> </i>
                                                Enviar planilha
                                            </Button>
                                        </div>
                                    }

                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment >
    );
};

export default CartaoPagamentosPendentes;