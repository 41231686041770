import React from "react";
export default function IconCo2(props) {
  const colorFill = props?.colorFill || "#696969";

  return (
    <svg
      width="54"
      height="53"
      viewBox="0 0 54 53"
      fill={colorFill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.7101 15.1543C21.8032 15.0073 21.8963 14.8736 21.9894 14.7266C25.0892 9.78228 30.8896 7.2032 36.5703 8.2188C42.3973 9.26112 46.9738 13.7244 48.1179 19.524C48.2775 20.3258 48.3308 21.1543 48.4106 21.9694C48.4372 22.2367 48.517 22.3703 48.7565 22.4772C52.0824 24.0006 53.9183 26.6065 53.9981 30.2546C54.0647 33.8359 52.3485 36.4818 49.1822 38.1388C48.0248 38.7402 46.7609 38.9941 45.4439 38.9941C32.8719 38.9941 20.2999 39.0074 7.72787 38.9941C3.72345 38.9941 0.317706 35.8404 0.0250248 31.9517C-0.294264 27.729 2.47291 24.0942 6.54384 23.4394C6.87643 23.3859 7.20902 23.3325 7.54162 23.3592C7.95403 23.3859 8.03385 23.2122 8.06046 22.8381C8.2201 20.4995 9.12476 18.4817 10.7744 16.8113C13.5682 14.005 17.7988 13.2834 21.3642 14.9939C21.4706 15.0607 21.5903 15.1008 21.7101 15.1543ZM26.6324 37.4039C32.8053 37.4039 38.9783 37.4039 45.1512 37.4039C45.4838 37.4039 45.8164 37.4172 46.149 37.3771C49.3019 37.0564 51.9893 34.464 52.3352 31.4172C52.721 27.9695 50.8718 24.9227 47.7321 23.8269C46.8807 23.5329 46.8141 23.426 46.8141 22.504C46.894 16.0362 41.945 10.4103 35.5592 9.71547C30.3175 9.14085 25.3952 11.7333 22.8941 16.3837C22.4551 17.1988 22.1092 17.3057 21.3376 16.8246C17.6391 14.593 12.8631 15.8224 10.6813 19.5507C9.88307 20.9271 9.53717 22.4238 9.6436 24.014C9.69681 24.8024 9.36422 25.1231 8.59261 25.0563C8.49948 25.043 8.40636 25.043 8.32653 25.0296C6.74339 24.8425 5.3199 25.2701 4.05605 26.2189C1.90085 27.8492 1.04941 30.6288 1.88754 33.1544C2.73898 35.7201 5.08043 37.4039 7.79439 37.4039C14.0737 37.4039 20.3531 37.4039 26.6324 37.4039Z"
        fill={colorFill}
      />
      <path
        d="M16.5127 0C18.973 0.0127656 20.9872 2.01697 20.9999 4.46797C21.0127 6.97003 18.9475 9.0253 16.4362 8.99976C13.9887 8.97423 12 6.9445 12 4.48073C12.0127 2.0042 14.0396 0 16.5127 0ZM16.4617 1.54464C14.8428 1.55741 13.5425 2.88503 13.5425 4.50627C13.5425 6.15303 14.8683 7.48066 16.5127 7.46789C18.1317 7.45512 19.483 6.10197 19.4575 4.48073C19.432 2.8212 18.1062 1.53187 16.4617 1.54464Z"
        fill={colorFill}
      />
      <path
        d="M25.9999 44.028C25.986 46.23 24.1742 48.0139 21.9583 47.9999C19.7703 47.986 17.9724 46.1464 18.0003 43.9444C18.0282 41.7424 19.8399 39.9725 22.0559 40.0003C24.23 40.0282 26.0139 41.84 25.9999 44.028ZM22.0141 41.6867C20.718 41.6867 19.6727 42.7319 19.6866 44.0141C19.7006 45.2823 20.7319 46.3136 22.0001 46.3136C23.2823 46.3275 24.3275 45.2684 24.3275 43.9722C24.3136 42.718 23.2823 41.6867 22.0141 41.6867Z"
        fill={colorFill}
      />
      <path
        d="M11.9996 11.5409C11.986 13.4685 10.4001 15.027 8.45881 14.9996C6.57219 14.986 5 13.3728 5 11.4862C5.01367 9.55858 6.6132 7.98642 8.52717 8.00009C10.4685 8.01376 12.027 9.5996 11.9996 11.5409ZM10.3728 11.5272C10.3864 10.5156 9.5525 9.66795 8.55451 9.65428C7.5155 9.64061 6.66788 10.4882 6.66788 11.5135C6.66788 12.5252 7.50183 13.3591 8.51349 13.3591C9.52516 13.3591 10.3591 12.5389 10.3728 11.5272Z"
        fill={colorFill}
      />
      <path
        d="M42 44.0002C42 45.6682 40.6681 47 38.9871 47C37.332 47 35.9872 45.6553 36.0001 43.9872C36.013 42.3451 37.3449 41.0004 38.9871 41.0004C40.6293 40.9745 42 42.3451 42 44.0002ZM37.5518 43.9872C37.5389 44.8148 38.1854 45.4484 39 45.4484C39.7888 45.4484 40.4354 44.8019 40.4483 44.026C40.4483 43.2244 39.7888 42.552 38.9871 42.552C38.1983 42.552 37.5647 43.1856 37.5518 43.9872Z"
        fill={colorFill}
      />
      <path
        d="M30.9999 50.5242C30.9999 51.8768 29.8861 52.9879 28.5303 53C27.126 53 25.988 51.8889 26.0001 50.5C26.0001 49.1353 27.1381 48.0001 28.5061 48.0001C29.8983 47.988 31.012 49.1112 30.9999 50.5242ZM28.5545 49.4493C27.9855 49.4131 27.477 49.872 27.4528 50.4517C27.4165 51.0194 27.8765 51.5145 28.4576 51.5387C29.0387 51.5628 29.5351 51.1281 29.5593 50.5484C29.5714 49.9687 29.1356 49.4856 28.5545 49.4493Z"
        fill={colorFill}
      />
      <path
        d="M25 27C25 24.8121 26.7643 23.0135 28.9596 23.0001C31.1683 22.9867 32.9865 24.7719 32.9999 26.9732C33.0134 29.1879 31.2222 31 29.0134 31C26.7778 31 25.0135 29.2282 25 27ZM28.9596 29.5235C30.2255 29.5369 31.3568 28.4094 31.3434 27.1477C31.3434 25.9128 30.2525 24.839 29 24.8524C27.707 24.8524 26.6566 25.9128 26.6566 27.188C26.67 28.4631 27.707 29.5235 28.9596 29.5235Z"
        fill={colorFill}
      />
      <path
        d="M24 24.3228C23.6165 24.6833 23.2468 25.0161 22.8907 25.3351C21.1925 24.2812 20.0969 24.3228 19.2067 25.4737C18.4398 26.4582 18.522 27.8588 19.3848 28.7601C20.3434 29.7585 21.5075 29.7446 22.8633 28.6769C23.2331 29.0097 23.6028 29.3425 23.9863 29.6753C22.8222 31.2838 20.0969 31.4502 18.4398 30.0497C16.6458 28.5244 16.5088 25.9175 18.1385 24.2257C19.7408 22.5756 22.4662 22.5756 24 24.3228Z"
        fill={colorFill}
      />
      <path
        d="M33.0556 31C32.9084 30.4699 33.0556 30.1351 33.4705 29.87C33.8586 29.6189 34.2066 29.312 34.568 29.0051C34.8356 28.7819 35.0899 28.5169 34.8892 28.1123C34.7553 27.8333 34.3404 27.8194 33.9389 28.0565C33.8051 28.1402 33.6847 28.2239 33.5508 28.3216C33.4705 28.2518 33.4036 28.196 33.3367 28.1402C33.2162 28.0426 33.0824 27.9589 33.0021 27.8891C33.3635 26.9266 35.0364 26.6755 35.6788 27.4846C36.0803 28.0007 36.0535 28.754 35.5583 29.2562C35.2907 29.5352 34.9695 29.7584 34.6081 30.0793C35.0899 30.0793 35.5048 30.0793 35.9599 30.0793C35.9732 30.4001 35.9866 30.6652 36 30.9721C34.9962 31 34.0326 31 33.0556 31Z"
        fill={colorFill}
      />
    </svg>
  );
}
