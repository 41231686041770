import { ManagementActionTypes } from "./actionTypes";

const INIT_STATE = {
  success: false,
  loading: false,
  tableaus: [],
};

const Management = (state = INIT_STATE, action) => {
  switch (action.type) {
    case ManagementActionTypes.API_RESPONSE_SUCCESS:
      return {
        loading: false,
        success: true,
        tableaus: action.payload.data.dashs,
      };

    case ManagementActionTypes.API_RESPONSE_ERROR:
      return {
        ...state,
        loading: false,
        success: false,
      };

    case ManagementActionTypes.GET_TABLEAUS:
      return {
        loading: true,
        success: false,
        tableaus: [],
      };
    default:
      return state;
  }
};

export default Management;
