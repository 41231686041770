import React, { useState, useEffect } from "react";
import { CardBody, Col, Row, Card, Input, Button } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { removeFromCart } from "../../../store/actions";
import { formato } from "../../../utils";
import { toast } from "react-toastify";
import { filiais } from "../../../utils/filiais";

const AddedItem = ({
  item,
  addValTot,
  valores,
  ocultarRt,
  changeItensCarrinho,
  itensCarrinho,
  addValArqu,
  valArquiteto,
  addValorRt,
  valorRt,
  setValServ,
  setSendValServ,
  setPercentWattpay,
}) => {
  const dispatch = useDispatch();
  const { vendedor, findedItems, addedItems } = useSelector(state => ({
    vendedor: state.Order.newOrder.newOrder.vendedor,
    findedItems: state.Order.newOrder.newOrder.findedItems,
    addedItems: state.Order.newOrder.newOrder.addedItems,
  }));

  useEffect(() => {
    setPrecoItem(parseFloat(item.precoVenda.toFixed(2)));
    setValMin(parseFloat(item.precoVenda.toFixed(2)));
    setValMax(parseFloat(item.precoVenda.toFixed(2)) * 1.5);
    setPrecoVenda(parseFloat(item.precoVenda.toFixed(2)));
    setValorTotal(
      parseInt(item.quant || 1) * parseFloat(item.precoVenda.toFixed(2))
    );
    setValorTabela(parseFloat(item.precoVenda.toFixed(2)));
  }, [item]);

  const [precoItem, setPrecoItem] = useState(
    parseFloat(item?.precoVenda.toFixed(2))
  );
  const [valMin, setValMin] = useState(parseFloat(item.precoVenda.toFixed(2)));
  const [valMax, setValMax] = useState(
    parseFloat(item?.precoVenda.toFixed(2)) * 1.5
  );

  const [addServico, setAddServico] = useState(false);
  const [servico, setServico] = useState();

  //formulas e estados pertinentes
  const [quant, setQuant] = useState(item.quant || 1);
  const [precoVenda, setPrecoVenda] = useState(
    parseFloat(item.precoVenda.toFixed(2))
  );
  const [valorTotal, setValorTotal] = useState(
    parseInt(item.quant || 1) * parseFloat(item.precoVenda.toFixed(2))
  );
  const [valorTabela, setValorTabela] = useState(
    parseFloat(item.precoVenda.toFixed(2))
  );
  const [percent, setPercent] = useState(0);
  const [valCashback, setValCashback] = useState(0);
  const [rtArquiteto] = useState(0);

  const [formattedServico, setFormattedServico] = useState("0,00");

  const [formattedPrecoVenda, setFormattedPrecoVenda] = useState(
    precoItem.toLocaleString("pt-br", {
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
    })
  );
  const [formattedPercent, setFormattedPercent] = useState("");
  const [formattedValCashback, setFormattedValCashback] = useState("0");
  const [formattedValorTotal, setFormattedValorTotal] = useState(
    (
      parseInt(item.quant || 1) * parseFloat(item.precoVenda.toFixed(2))
    ).toLocaleString("pt-br", {
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
    })
  );

  const ratio = 2 / 98; // quantos % aumentar o valor levando em conta a diminuição de 30% -> ex: diminuiu o produto em 30%, para voltar ao valor normal, aumentar em ratio%

  /////////////////////////////////////////////////////////////

  const detalhes = findedItems[item.cod] || {};

  //formatando os valores
  useEffect(() => {
    let indexOfComma = String(percent).indexOf(".");
    let percentFormatado = "";
    if (indexOfComma !== -1) {
      percentFormatado = String(percent).slice(0, indexOfComma + 3);
    } else {
      percentFormatado = String(percent);
    }

    setPercentWattpay(percentFormatado);
    setFormattedPercent(percentFormatado);
    setFormattedPrecoVenda(
      precoVenda.toLocaleString("pt-br", {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      })
    );
    setFormattedValCashback(
      valCashback.toLocaleString("pt-br", {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      })
    );
    let replacedPrecoVenda = parseFloat(
      precoVenda.toString().replace(",", ".")
    );
    let total = replacedPrecoVenda * quant;
    setFormattedValorTotal(
      total.toLocaleString("pt-br", {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      })
    );
  }, [precoVenda, valorTotal, valorTabela, percent, valCashback]);

  //alterações componente pai
  useEffect(() => {
    if (Object.keys(item).length > 0) {
      let valTotal = {
        ...valores,
        [item.produto.CODPRODUTO]: Number(valorTotal),
      };
      addValTot(valTotal);
    }
  }, [item]);

  useEffect(() => {
    let valTotal = {
      ...valores,
      [item.produto.CODPRODUTO]: Number(valorTotal),
    };

    let itemsCar = {
      ...itensCarrinho,
      [item.produto.CODPRODUTO]: {
        AMOUNT: Number(quant),
        VALORRT: Number(valCashback) / Number(quant),
        /*  PRCRT: Number(precoVenda.toString().replace(",", ".")), */
        subtotal: valTotal,
        maskPrcVenda: precoVenda,
        PERCENT: percent,
      },
    };
    changeItensCarrinho(itemsCar);

    let arqu = {
      ...valArquiteto,
      [item.produto.CODPRODUTO]: Number(rtArquiteto),
    };
    addValArqu(arqu);

    let rtVal = {
      ...valorRt,
      [item.produto.CODPRODUTO]: Number(valCashback),
    };
    addValorRt(rtVal);
  }, [valorTotal, quant, valCashback, precoVenda, percent, rtArquiteto]);

  useEffect(() => {
    let replacedPrecoVenda = parseFloat(
      precoVenda.toString().replace(",", ".")
    );

    setValorTotal(replacedPrecoVenda * quant);
  }, [precoVenda, quant]);

  useEffect(() => {
    if (addServico && servico) {
      let servicoNumber = parseFloat(
        servico.replace(".", "").replace(",", ".")
      );
      setFormattedServico(servicoNumber.toLocaleString("pt-br", formato));
    }
  }, [servico]);

  //resetando valores
  const resetValues = () => {
    let valTotal = {
      ...valores,
      [item.produto.CODPRODUTO]: Number(precoItem),
    };
    addValTot(valTotal);
    setPrecoVenda(precoItem);
    setPercent(0);
    setValCashback(0);
  };

  //lidando com "onChange"
  const handleValueUpdate = (newValue, updatedState) => {
    switch (updatedState) {
      case "percent":
        setPercent(newValue);
        break;
      case "cashback":
        setValCashback(newValue);
        break;
      case "precoVenda":
        newValue = newValue.toString().replace(".", "");

        let newString = newValue;

        if (newValue.charAt(newValue.length - 1) === ",") {
          if (newValue.search(",") < newValue.length - 1) {
            newString = newValue.substring(0, newValue.search(",") + 3);
          }
        }
        setPrecoVenda(newString);
        break;
    }
  };

  //matematica dos valores
  const compareValues = changed => {
    let replacedPrecoVenda = parseFloat(
      precoVenda.toString().replace(",", ".")
    );
    let replacedValCashback = parseFloat(
      valCashback.toString().replace(",", ".")
    );

    const precoVendaChanged = () => {
      //formula inputando preço de venda
      let cashback = (replacedPrecoVenda - valorTabela) * 0.98;
      let percent = (cashback * 100) / valorTabela;
      let valTotal = {
        ...valores,
        [item.produto.CODPRODUTO]: Number(replacedPrecoVenda),
      };
      setValCashback(cashback);
      setPercent(percent);
      addValTot(valTotal);
    };

    const percentChanged = () => {
      //formula inputando a porcentagem
      let cashback = valorTabela * (percent / 100);
      let precovenda = valorTabela + (cashback + cashback * ratio);
      let valTotal = {
        ...valores,
        [item.produto.CODPRODUTO]: Number(precovenda),
      };
      setValCashback(cashback);
      setPrecoVenda(precovenda);
      addValTot(valTotal);
    };

    const cashbackChanged = () => {
      //formula inputando o cashback
      let percent = (replacedValCashback * 100) / valorTabela;
      let precovenda =
        valorTabela + (replacedValCashback + replacedValCashback * ratio);
      let valTotal = {
        ...valores,
        [item.produto.CODPRODUTO]: Number(precovenda),
      };
      setPrecoVenda(precovenda);
      setPercent(percent);
      addValTot(valTotal);
    };

    switch (changed) {
      case "precoVenda":
        precoVendaChanged();
        break;
      case "percent":
        percentChanged();
        break;
      case "cashback":
        cashbackChanged();
        break;
    }

    checkValues();
  };

  //limitando os valores
  const checkValues = () => {
    let precoVendaT = parseFloat(precoVenda.replace(",", "."));

    if (precoVendaT < valMin || precoVendaT > valMax) {
      resetValues();
      toast.warning(
        `O preço de venda deve estar entre ${valMin.toLocaleString(
          "pt-br",
          formato
        )} e ${valMax.toLocaleString("pt-br", formato)}`,
        { theme: "colored" }
      );
    }

    if (valCashback >= precoVendaT * 0.4 || valCashback < 0) {
      resetValues();
      toast.warning(`Valor de cashback inválido`, { theme: "colored" });
    }

    if (percent < 0 || percent > 50) {
      resetValues();
      toast.warning(`A % deve estar entre 0% e 50%`, { theme: "colored" });
    }
  };

  //remover item do carrinho
  const removeItem = () => {
    let valTotal = valores;
    delete valTotal[item.produto.CODPRODUTO];

    addValTot(valTotal);

    let itemCar = itensCarrinho;
    delete itemCar[item.produto.CODPRODUTO];
    changeItensCarrinho(itemCar);

    let arqu = valArquiteto;
    delete arqu[item.produto.CODPRODUTO];
    addValArqu(arqu);

    let rt = valorRt;
    delete rt[item.produto.CODPRODUTO];
    addValorRt(rt);

    toast.info(`${item.produto.DESCRICAO} removido do carrinho.`, {
      theme: "colored",
    });
    dispatch(removeFromCart(item));
  };

  const maskMoney = value => {
    let stringValue = value.toString();
    let v = new Intl.NumberFormat("pt-br", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(stringValue);
    return v;
  };

  const [isToastBeignCalled, setIsToastBeignCalled] = useState(false);

  const callWarningOnAFK = () => {
    if (history.location.pathname != "/pedidos/novo") {
      return;
    }
    if (addedItems.length > 0 && !isToastBeignCalled) {
      toast.warning(
        "Seu carrinho será limpo em 60 minutos devido à inatividade"
      );
      setIsToastBeignCalled(true);
    }
  };

  const callRemoveItem = () => {
    const removeItemWhenAFK = () => {
      let valTotal = valores;
      delete valTotal[item.produto.CODPRODUTO];
      addValTot(valTotal);

      let itemCar = itensCarrinho;
      delete itemCar[item.produto.CODPRODUTO];
      changeItensCarrinho(itemCar);

      let arqu = valArquiteto;
      delete arqu[item.produto.CODPRODUTO];
      addValArqu(arqu);

      let rt = valorRt;
      delete rt[item.produto.CODPRODUTO];
      addValorRt(rt);

      toast.warning(
        `${item.produto.DESCRICAO} removido do carrinho devido à inatividade.`,
        {
          autoClose: false,
        }
      );
      dispatch(removeFromCart(item));
    };
    if (history.location.pathname != "/pedidos/novo") {
      return;
    }
    if (addedItems.length > 0) {
      removeItemWhenAFK();
      setIsToastBeignCalled(false);
    }
  };

  let time;
  let timeHalf;

  document.onmousemove = () => {
    clearTimeout(time);
    clearTimeout(timeHalf);
    time = setTimeout(callRemoveItem, 1000 * 60 * 60); //roda em 1 hora
    timeHalf = setTimeout(callWarningOnAFK, 1000 * 60 * 45); //roda em 45 minutos
  };

  return (
    <Card className="mb-0 mt-4">
      <CardBody>
        <Row className="d-flex align-items-end">
          <Col md={3}>
            <p className="mb-0" style={{ fontWeight: 500 }}>
              Enviado de:{" "}
              {
                filiais[
                  Number(
                    item.produto.PRDDISPS?.STRETPRDFILADIAS[item.filial]
                      ?.CODFILPRD
                  ) || item.filial
                ]
              }
            </p>
          </Col>
          <Col md={9} className="d-flex justify-content-end align-items-center">
            <Button color="info" size="sm" outline onClick={resetValues}>
              Zerar Cashback
            </Button>
            <a
              href=""
              onClick={e => {
                e.preventDefault();
                removeItem();
              }}
              style={{ color: "#269FEC", marginLeft: 20 }}
            >
              <i className="far fa-trash-alt"></i>
            </a>
          </Col>
        </Row>
        <hr className="mb-3" />
        <Row>
          {detalhes.fotos ? (
            <Col md={1}>
              <img src={detalhes.fotos[0]} style={{ width: "100%" }} />
            </Col>
          ) : (
            <div />
          )}
          <Col md={8}>
            <h5>{item.produto.DESCRICAO}</h5>
            <Row>
              <Col className="mt-2 d-flex align-items-center">
                <div>
                  <p className="text-muted mb-0" style={{ fontSize: 11 }}>
                    Cód. Fabricante: /
                  </p>
                  <p className="text-muted mb-0" style={{ fontSize: 11 }}>
                    SKU: {item.produto.CODPRODUTO}
                  </p>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
        <hr className="mb-3" />
        <div className="product-infos">
          <div className="product-info" style={{ width: "10%" }}>
            <p className="info-title">Quantidade</p>
            <Input
              className="mb-0 mt-4 pre-venda"
              type="number"
              value={quant}
              onChange={e => setQuant(e.target.value)}
              min={1}
            />
          </div>

          <div className="product-info">
            <p className="info-title">Preço de Venda (R$)</p>
            <div style={{ position: "relative" }}>
              <Input
                className="mb-0 mt-4 pre-venda"
                //style={{ paddingLeft: "25px!important" }}
                value={formattedPrecoVenda.replace(/[^\d.,]/g, "")}
                onChange={e => {
                  handleValueUpdate(e.target.value, "precoVenda");
                }}
                readOnly={vendedor?.VENDEDOR?.TEMRT != 1}
                onBlur={() => {
                  compareValues("precoVenda");
                }}
              />
              {/* <div
                style={{
                  position: "absolute",
                  left: "15px",
                  top: "7px",
                  fontWeight: "bold",
                  color: "#929fb1",
                }}
              >
                R$
              </div> */}
            </div>
          </div>
          <div className="product-info">
            <p className="info-title">Valor Total (R$)</p>
            <p className="info-result mb-0">{formattedValorTotal}</p>
          </div>
          <div className="product-info">
            <p className="info-title">Tabela (R$)</p>
            <p className="info-result mb-0">{`${maskMoney(valorTabela)}`}</p>
          </div>
          <div className="product-info" hidden={ocultarRt}>
            <p className="info-title">%</p>
            <Input
              className="mb-0 mt-4 pre-venda"
              value={formattedPercent.replace(/[^\d.,]/g, "")}
              onChange={e => {
                handleValueUpdate(e.target.value, "percent");
              }}
              onBlur={() => compareValues("percent")}
              readOnly={vendedor?.VENDEDOR?.TEMRT != 1}
            />
          </div>
          <div className="product-info" hidden={ocultarRt}>
            <p className="info-title">Valor Cashback (R$)</p>
            <p className="info-result mb-0">{`${maskMoney(valCashback)}`}</p>
            {/*     <div style={{ position: "relative" }}>
              <Input
                className="mb-0 mt-4 p-input pre-venda"
                //style={{ paddingLeft: "25px!important" }}
                value={formattedValCashback.replace(/[^\d.,]/g, "")}
                onChange={e => {
                  handleValueUpdate(e.target.value, "cashback");
                }}
                onBlur={() => compareValues("cashback")}
                readOnly={vendedor?.VENDEDOR?.TEMRT != 1}
              />
             <div
                style={{
                  position: "absolute",
                  left: "15px",
                  top: "7px",
                  fontWeight: "bold",
                  color: "#929fb1",
                }}
              >
                R$
              </div> 
            </div>*/}
          </div>
          {/* 
          <div className="product-info" hidden={ocultarRt}>
            <p className="info-title">Cashback do Arquiteto</p>
            <Input
              className="mb-0 mt-4 pre-venda"
              type="number"
              value={rtArquiteto}
              onChange={e => setRtArquiteto(e.target.value)}
            />
          </div> */}
        </div>
        <hr className="mb-3" />
        <Row>
          <Col md={6}>
            <div
              className="cursor-pointer"
              style={{
                color: "#FF601A",
                display: "flex",
                alignItems: "center",
              }}
            >
              <div
                onClick={() => {
                  setAddServico(prev => !prev);
                  setSendValServ(prev => !prev);
                }}
              >{`${addServico ? "Remover Serviço" : "Incluir Serviço"}`}</div>
              <i
                onClick={() => {
                  setAddServico(prev => !prev);
                  setSendValServ(prev => !prev);
                }}
                className={`ms-2 fa ${addServico ? "fa-minus" : "fa-plus"}`}
                style={{ marginLeft: 5 }}
              ></i>
              {addServico && (
                <div
                  className="ms-3"
                  style={{ width: "40%", position: "relative" }}
                >
                  <Input
                    value={servico}
                    style={{ paddingLeft: "30px" }}
                    onChange={e => {
                      setServico(e.target.value);
                      setValServ(parseFloat(e.target.value));
                    }}
                    onBlur={() => {
                      let temp = parseFloat(servico);
                      setServico(
                        temp.toLocaleString("pt-br", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })
                      );
                    }}
                  />
                  <div
                    style={{
                      position: "absolute",
                      top: "8px",
                      left: "8px",
                      color: "black",
                    }}
                  >
                    R$
                  </div>
                </div>
              )}
            </div>
          </Col>
          <Col md={6}>
            {addServico && (
              <a
                href="#"
                style={{
                  display: "block",
                  width: "fit-content",
                  marginLeft: "auto",
                  color: "#FF601A",
                }}
              >
                Serviço de Instalação Incluso ({formattedServico})
              </a>
            )}
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

export default AddedItem;
