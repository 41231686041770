import React from "react";
import { Card, CardBody, Progress } from "reactstrap";

const PointsCard = () => {
  return (
    <React.Fragment>
      <Card className="p-4 mb-0">
        <CardBody>
          <h1 className="display-5 fw-bold mb-3">75%</h1>
          <p className="font-size-18 text-gray-dark mb-2">Nível1 : 80 / 120</p>
          <p className="font-size-18 text-gray-dark mb-3">
            Pontos acumulados : 0101001
          </p>
          <Progress
            color="primary"
            value={75}
            className="ranking-progress bg-soft-primary"
          />
          <button className="btn btn-primary mt-3 float-end">
            VER DETALHES
          </button>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default PointsCard;
