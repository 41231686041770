const formato = {
  minimumFractionDigits: 2,
  currency: "BRL",
};

//ARREDONDA NÚMEROS E DEFINE O TAMANHO DE CASAS APÓS A VÍRGULA
const round = (num, places) => {
  if (!("" + num).includes("e")) {
    return +(Math.round(num + "e+" + places) + "e-" + places);
  } else {
    let arr = ("" + num).split("e");
    let sig = "";
    if (+arr[1] + places > 0) {
      sig = "+";
    }

    return +(
      Math.round(+arr[0] + "e" + sig + (+arr[1] + places)) +
      "e-" +
      places
    );
  }
};

//GERA A Geração MÉDIA de Energia Solar Estimada Mensal:
export const getKwhMonthEstimatedGeneration = dataPdf => {
  let kwp =
    Number(dataPdf?.lead?.calculations?.kwp.replace(",", ".")) ||
    Number(dataPdf?.calculations?.kwp.replace(",", "."));
  let irradiation =
    round(dataPdf?.lead?.calculations?.irradiation_kwh_day, 2) ||
    round(dataPdf?.calculations?.irradiation_kwh_day, 2);
  let value = round(kwp * irradiation * 30, 2);
  return value;
};

//GERA OS VALORES DO Geração kWh/ano
export const getValueKwhYearGenerate = (
  kwh_month_estimated_generation,
  years
) => {
  var conta = 0;
  var valor = kwh_month_estimated_generation.toFixed(2);

  var arrayValues = [];

  for (var i = 0; i < years || 24; i++) {
    for (var j = 0; j < 1; j++) {
      conta = (valor / 100) * 0.5;

      arrayValues.push(round(valor, 2));
    }
    valor = valor - conta;
  }
  return arrayValues;
};

//GERA OS VALORES DO RESULTADO ANUAL
export const getValueYearResult = (
  years,
  valueKwhYearGenerate,
  valuesTariffPercent
) => {
  var conta = 0;

  var arrayValues = [];
  for (var i = 0; i < years; i++) {
    conta = Number(valueKwhYearGenerate[i]) * Number(valuesTariffPercent[i]);

    arrayValues.push(round(conta, 2));
  }

  return arrayValues;
};

//GERA OS VALORES DO CUSTO AQUISIÇÃO
export const getValuePurchaseCost = (dataPdf, years) => {
  var conta = 0;
  var valor =
    -Number(dataPdf?.dataOrcamento?.orcamento?.total) ||
    -dataPdf?.calculations?.product_infos[14]?.Valor;

  var arrayValues = [];
  for (var i = 0; i < years; i++) {
    if (i == 0) {
      conta = valor;
    } else {
      conta = valor;
    }

    arrayValues.push(round(String(conta), 2));
    valor = conta;
  }

  return arrayValues;
};

//GERA OS VALORES DO RESULTADO ACUMULADO
export const getValueAccumulatedResult = (dataPdf, years, valueYearResult) => {
  var conta = null;
  var valor =
    -Number(dataPdf?.dataOrcamento?.orcamento?.total) ||
    -dataPdf?.calculations?.product_infos[14]?.Valor;

  var arrayValues = [];
  for (var i = 0; i < years; i++) {
    if (i == 0) {
      conta = valor;
    } else {
      conta = valueYearResult[i] + valor;
    }
    arrayValues.push(round(conta, 2));
    valor = conta;
  }

  return arrayValues;
};

//GERA OS VALORES DO CUSTO O&M
export const valuePercentCustMo = (dataPdf, years) => {
  var conta = 0;
  var valor =
    (Number(dataPdf?.dataOrcamento?.orcamento?.total) / 100) * 5 ||
    (dataPdf?.calculations?.product_infos[14]?.Valor / 100) * 5;

  var arrayValues = [];
  for (var i = 0; i < years; i++) {
    for (var j = 0; j < 1; j++) {
      conta = (valor / 100) * 5;
      arrayValues.push(
        round(valor.toFixed(3), 2).toLocaleString("pt-BR", formato)
      );
    }
    valor = valor + conta;
  }
  return arrayValues;
};

//GERA OS VALORES DA TARIFA KWH
export const valueTariffPercent = (dataPdf, years) => {
  var conta = 0;
  var valor =
    Number(dataPdf?.lead?.lead?.kwh_price) || Number(dataPdf?.lead?.kwh_price);
  var arrayValues = [];
  for (var i = 0; i < years; i++) {
    for (var j = 0; j < 1; j++) {
      conta = valor * 0.1;

      arrayValues.push(round(valor.toFixed(3), 2));
    }
    valor = valor + conta;
  }
  return arrayValues;
};

export const valueByQTD = (value, qtd) => {
  let result;
  result = value * qtd;
  return parseFloat(result);
};

export const getAcrescimoCount = (acrescimo, valTot) => {
  var valAcrescimo =
    acrescimo > 0 ? valTot / acrescimo : valTot * acrescimo * -1;

  return parseFloat(arredondar(valAcrescimo));
};

function arredondar(n) {
  return (Math.round(n * 100) / 100).toFixed(2);
}
