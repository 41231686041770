import React from "react";

// data table
import BootstrapTable from "react-bootstrap-table-next";

const BasicTable = ({
  tableRef,
  keyField,
  defaultSorted,
  selectRow,
  ...other
}) => {
  return (
    <BootstrapTable
      ref={tableRef}
      keyField={keyField}
      responsive
      bordered={false}
      striped={false}
      defaultSorted={defaultSorted}
      selectRow={selectRow}
      classes={
        "align-middle custom-table table-nowrap table-borderless table-check table"
      }
      {...other}
    />
  );
};

export default BasicTable;
