import React from "react";

import { Link, useLocation } from "react-router-dom";
import { SidebarSubMenu } from "./styles";
import { useSolar } from "../../../hooks";
import { useDispatch } from "react-redux";
import { getBudgetClear, removeAllItems } from "../../../store/actions";

export default function SideBar({ submenuData }) {
  const isSolar = useSolar();
  const location = useLocation();
  const dispatch = useDispatch();

  const handleClick = () => {
    dispatch(removeAllItems());
    dispatch(getBudgetClear());
  };
  return (
    <SidebarSubMenu location={location} data={submenuData} isSolar={isSolar}>
      <Link to={submenuData.link} onClick={handleClick}>
        <div>
          <div>
            {" "}
            {submenuData["icon"] && (
              <i
                className={`icon ${submenuData["icon"]} ${
                  isSolar && "solar-icon"
                }`}
              ></i>
            )}
          </div>
          <span>{submenuData.label}</span>
        </div>
      </Link>
    </SidebarSubMenu>
  );
}
