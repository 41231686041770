import React from "react";
export default function IconCheck(props) {
  const colorFill = props?.colorFill?.replaceAll("#", "") || "none";
  const colorStroke = props?.colorStroke || "#000";

  return (
    <svg
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill={colorFill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13 4L6.33333 11L3 7.5"
        stroke={colorStroke}
        strokeWidth="0.933035"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
