import React, { useEffect } from "react";
import { Card, CardBody, Spinner, Table, Row, Col } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { getDocuments } from "../../store/order/action";
import { useSolar } from "../../hooks";
import { formatDate, formatInvertDate } from "../../utils";
import { useHistory } from "react-router-dom";

const SubmittedDocuments = () => {
  const dispatch = useDispatch();
  const { documentos } = useSelector(state => ({
    documentos: state.Order.documentos,
  }));
  const history = useHistory();
  useEffect(() => {
    dispatch(getDocuments());
  }, [dispatch, location.pathname]);

  const isSolar = useSolar();

  return (
    <div>
      <Card className="box-envio-docs">
        <CardBody className="p-3">
          <div className="doc-analise">
            <h6>Documentos para análise de crédito</h6>
          </div>
          {documentos.loading ? (
            <Spinner />
          ) : documentos.success && documentos?.documentos?.length > 0 ? (
            <Row>
              <Col>
                <Table>
                  <tbody>
                    {documentos.documentos.map(value => {
                      return (
                        <tr key={value.id}>
                          <td>
                            <i className="fas fa-file"></i>
                          </td>
                          <td>
                            <a
                              target="_blank"
                              rel="noreferrer"
                              href={isSolar ? `${value.url}` : `${value.url}`}
                            >
                              {value.nome}
                            </a>
                          </td>
                          <td>{value.tipo_desc}</td>
                          <td>{value.uploaddata}</td>
                          <td>{value.status_desc}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </Col>
            </Row>
          ) : (
            <p className="mb-0">
              <span className="fw-bold">Busca Finalizada</span>
              <span className="text-muted ms-2">
                Nenhum documento a ser exibido.
              </span>
            </p>
          )}
        </CardBody>
      </Card>
    </div>
  );
};

export default SubmittedDocuments;
