import {
  CardDefault,
  React,
  DataWithPictures,
  IconCurrencyDollar,
  Row,
  Col,
  dataPotentialEnvironmentalImpact,
  useEffect,
  useState,
} from "../../helpers/importer.helper";
import { jurosCompostos } from "../../../../../utils";

export default function SectionFive({
  userData,
  data,
  setEconomiaLiquidaEstimada,
}) {
  const [stateValue, setStateValue] = useState({
    description: dataPotentialEnvironmentalImpact.descriptions[0],
    values: [0, 0, 0, 0],
    comments: dataPotentialEnvironmentalImpact.comments,
  });
  const [currentIndexTab, setCurrentIndextab] = useState(0);

  const [beneficioDe25Anos, setBeneficioDe25Anos] = useState(0);
  const [beneficioDe25AnosEmp, setBeneficioDe25AnosEmp] = useState(0);
  const [economiaTotalDe25Anos, setEconomiaTotalDe25Anos] = useState(0);
  const [economiaTotalDe25AnosEmp, setEconomiaTotalDe25AnosEmp] = useState(0);
  const [anosAteRetorno, setAnosAteRetorno] = useState(0);
  const [anosAteRetornoEmp, setAnosAteRetornoEmp] = useState(0);
  const [firstParcel, setFirstParcel] = useState(0);

  useEffect(() => {
    if (Object.keys(userData).length >= 1) {
      if (Object.keys(userData?.calculations).length >= 1) {
        const irradiacaoMedia = userData?.calculations?.irradiation_kwh_day;
        const kwp = Number((userData?.calculations?.kwp).replace(",", "."));
        const geracaoMediaAnualKwh = kwp * irradiacaoMedia * 30 * 12;
        const productPrice = parseFloat(
          userData?.calculations?.product_price
            .replace(".", "")
            .replace(",", ".")
        );

        ////////////////////////////////////////////////////ambos
        const getTarifa = ano =>
          jurosCompostos(
            userData?.calculations
              ? Number(userData?.calculations["energy tariff"])
              : "",
            ano,
            0.1,
            true
          );

        const getCustoOM = ano =>
          jurosCompostos(productPrice * 0.05, ano, 0.05, true);
        const getGeracaoKwhAno = ano =>
          jurosCompostos(geracaoMediaAnualKwh, ano, 0.005, false);

        const getResultadoAnual = ano => {
          return getGeracaoKwhAno(ano) * getTarifa(ano) - getCustoOM(ano);
        };

        ///////////////////////////////////////////////////compra

        const getResultadoAcumulado = ano => {
          let sum = -productPrice;
          for (let i = 0; i <= ano; i++) {
            sum += getResultadoAnual(i);
          }
          return sum;
        };

        ///////////////////////////////////////////////emprestimo
        const getProductPriceEmp = meses => {
          let x = (1 - 1 / Math.pow(1 + 0.02, meses)) / 0.02;
          let final = productPrice / x;
          return final;
        };

        const getResultadoAcumuladoEmp = ano => {
          let sum = -getProductPriceEmp(60) * 60;
          for (let i = 0; i <= ano; i++) {
            sum += getResultadoAnual(i);
          }
          return sum;
        };

        let custoEnergia = 0;
        for (let i = 0; i <= 25; i++) {
          custoEnergia += jurosCompostos(
            data.avg_account_price * 12,
            i,
            0.1,
            true
          );
        }

        setFirstParcel(getProductPriceEmp(12));

        //////////////////////////////////////////////////////fim
        let tempBeneficio = getResultadoAcumulado(25);
        let tempEconomia = tempBeneficio - productPrice;
        let tempEconomiaEmp = tempBeneficio - getProductPriceEmp(60) * 60;

        setBeneficioDe25Anos(getResultadoAcumulado(25));
        setEconomiaLiquidaEstimada(tempEconomia);
        setEconomiaTotalDe25Anos(tempEconomia);
        for (let i = 0; i < 25; i++) {
          if (getResultadoAcumulado(i) > 0) {
            setAnosAteRetorno(i);
            break;
          }
        }
        for (let i = 0; i < 25; i++) {
          if (getResultadoAcumuladoEmp(i) > 0) {
            setAnosAteRetornoEmp(i);
            break;
          }
        }

        setBeneficioDe25AnosEmp(getResultadoAcumuladoEmp(25));
        setEconomiaTotalDe25AnosEmp(tempEconomiaEmp);
      }
    }
  }, [userData]);

  useEffect(() => {
    let val = currentIndexTab;
    let numberFormat = Intl.NumberFormat();
    if (val == 0)
      setStateValue({
        description: dataPotentialEnvironmentalImpact.descriptions[0],
        values: [
          userData?.calculations?.product_price,
          numberFormat.format(beneficioDe25Anos.toFixed(2)),
          numberFormat.format(economiaTotalDe25Anos.toFixed(2)),
          anosAteRetorno,
        ],
        comments: dataPotentialEnvironmentalImpact.comments,
      });

    if (val == 2)
      setStateValue({
        description: dataPotentialEnvironmentalImpact.descriptions[2],
        values: [
          numberFormat.format(firstParcel.toFixed(2)),
          numberFormat.format(beneficioDe25AnosEmp.toFixed(2)),
          numberFormat.format(economiaTotalDe25AnosEmp.toFixed(2)),
          anosAteRetornoEmp,
        ],
        comments: dataPotentialEnvironmentalImpact.comments,
      });
  }, [
    beneficioDe25Anos,
    economiaTotalDe25Anos,
    beneficioDe25AnosEmp,
    economiaTotalDe25AnosEmp,
    currentIndexTab,
  ]);

  const handleChangeIndexTab = val => {
    setCurrentIndextab(val);
  };

  return (
    <section id="section-5" className="mt-4 section">
      <CardDefault
        checkbox={false}
        icon={<IconCurrencyDollar />}
        title="Como financiar seus painéis solares"
      >
        <div className="w-100">
          <div>
            <div className="w-100">
              <Row>
                <Col
                  md={5}
                  className="d-flex justify-content-between mx-auto my-4"
                >
                  <button
                    onClick={() => {
                      handleChangeIndexTab(0);
                    }}
                    className={`button-tabs cursor-pointer button ${
                      currentIndexTab == 0 ? "active" : "none"
                    }`}
                  >
                    Compra
                  </button>
                  {/* <button
                            onClick={() => {
                              handleChangeIndexTab(1);
                            }}
                            className={`button-tabs cursor-pointer ${
                              currentIndexTab == 1 ? "active" : "none"
                            }`}
                          >
                            Arrendamento / PPA
                          </button> */}
                  <button
                    onClick={() => {
                      handleChangeIndexTab(2);
                    }}
                    className={`button-tabs cursor-pointer button ${
                      currentIndexTab == 2 ? "active" : "none"
                    }`}
                  >
                    Empréstimo
                  </button>
                </Col>
              </Row>
              {
                <div id="tab-buy">
                  <p className="mt-3 mb-5">{stateValue.description}</p>
                  <Row>
                    <Col md={10} className="mx-auto" style={{ width: "100%" }}>
                      <Row className="d-flex justify-content-between">
                        <Col md={3}>
                          <DataWithPictures
                            stringSize={28}
                            stringWeight={600}
                            stringColor={
                              currentIndexTab == 0 ? "#25cf84" : "#3F3F3"
                            }
                            value={`R$ ` + stateValue.values[0]}
                            description={stateValue.comments[0]}
                          />
                        </Col>
                        <Col md={3}>
                          <DataWithPictures
                            stringSize={28}
                            stringColor="#3F3F3F"
                            stringWeight={600}
                            value={`R$ ` + stateValue?.values[1]}
                            description={stateValue.comments[1]}
                          />
                        </Col>
                        <Col md={3}>
                          <DataWithPictures
                            stringSize={28}
                            stringColor="#3F3F3F"
                            stringWeight={600}
                            value={`R$ ` + stateValue?.values[2]}
                            description={stateValue.comments[2]}
                          />
                        </Col>
                        <Col md={3}>
                          <DataWithPictures
                            stringSize={28}
                            stringColor="#3F3F3F"
                            stringWeight={600}
                            value={stateValue?.values[3]}
                            description={stateValue.comments[3]}
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </div>
              }
            </div>
          </div>
        </div>
      </CardDefault>
    </section>
  );
}
