import {
  Logo,
  GroupRectangles,
  GroupRectanglesBottom,
  Absolar,
  Inmetro,
  Procel,
  LogoAdias,
  React,
} from "../../helpers/importer.helper";
import Footer from "../Footer";
import "./styles.scss";

export default function ContraCapa({ dataPdf }) {
  return (
    <div className="content-pdf-4">
      <img className="rectangles-top" src={GroupRectangles} />
      <img className="rectangles-bottom" src={GroupRectanglesBottom} />
      <p>
        Eco-eficiência, é a união entre o fornecimento de bens e serviços
        sustentáveis a preços competitivos que satisfaçam as necessidades
        humanas, promovendo assim, a redução dos impactos ambientais e de
        consumo de recursos naturais. Eco-eficiência é uma das grandes atitudes
        que podem nos levar ao desenvolvimento sustentável.
        <br />
        <br />
        Desenvolvimento Sustentável é o que atende as necessidades das gerações
        do presente sem comprometer a capacidade das gerações futuras de atender
        as suas próprias necessidades.
        <br />
        <br /> A Energia Solar Fotovoltaica é a tecnologia que permite
        transformar a luz do sol em energia elétrica através de módulos solares
        feitos de material semicondutor. Para que se gere energia não é
        necessário calor, apenas luminosidade.
        <br />
        <br /> Investir em energia solar, hoje, é antecipar uma consciência
        sustentável que, em breve, será uma tendência irreversível no mundo,
        dado a necessidade de diversificarmos nossa matriz energética e o
        tamanho dos benefícios envolvidos, tais como:
        <br />
        <br /> Economia Financeira; Pioneirismo Tecnológico; Valorização
        Imobiliária; Redução da Emissão de gases poluentes no Planeta.
        <br />
        <br />
        Garantimos todo o funcionamento e durabilidade dos equipamentos conforme
        as especificações técnicas dos fabricantes. Também oferecemos o
        diferencial de empenho e dedicação em todo serviço prestado para que
        nossos clientes sempre estejam satisfeitos e realizados com suas compras
        e consultas.
        <br />
        <br />
        Venha fazer parte desse time, juntos somos mais fortes!
      </p>
      <div className="container-logos">
        <img className="logoOne" src={Logo} />
        <img className="absolar" src={Absolar} />
        <img className="inmetro" src={Inmetro} />
        <img className="procel" src={Procel} />
        <img className="logoTwo" src={LogoAdias} />
      </div>
      <Footer dataPdf={dataPdf} />
    </div>
  );
}
