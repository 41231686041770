import React, { useEffect } from "react";

const TermoDeUso = () => {
  return (
    <div className="page-content">
      <p>
        <strong>TERMOS E CONDIÇÕES DE USO DO SITE</strong>
      </p>

      <p>&nbsp;</p>

      <ol>
        <li>
          <p>
            <strong>Introdução</strong>
          </p>
        </li>
      </ol>

      <p>
        O aplicativo Clube ADias pertence à MPS Distribuidora Mercantil Ltda.,
        inscrita no CNPJ sob o n° 09.570.732/0001-91, a Gedear Refrigeração
        Ltda., inscrita no CNPJ sob o n° 09.294.735/0001-40, e a A.Dias Solar
        Distribuidora Mercantil Ltda, inscrita no CNPJ sob o nº
        05.951.301/0001-14, ambas localizadas na Rua Tenente Alberto Spicciati,
        120 – Sala 1 – Várzea da Barra Funda – São Paulo - CEP 01140-130, São
        Paulo – SP – CEP 01140-130, (“<strong>ADias”</strong>).
      </p>

      <p>
        <a name="_Hlk100064043"></a> Por favor, leia atentamente estes Termos e
        Condições de Uso. Ao acessar ou utilizar este aplicativo, você
        automaticamente concorda com as presentes Cláusulas. Caso você não
        concorde, por favor não acesse nem utilize o aplicativo.
      </p>

      <p>
        A ADias se reserva o direito de limitar e/ou cancelar o acesso à
        Plataforma aos Usuários sem necessidade de pré-aviso ou de qualquer
        justificativa.
      </p>

      <p>
        A ADias<strong> </strong>poderá modificar os presentes Termos e
        Condições de Uso a qualquer momento, por isso recomendamos sua leitura a
        cada acesso.
      </p>

      <ol start="2">
        <li>
          <p>
            <strong>
              Direitos de Propriedade Industrial e Direitos de Autor
            </strong>
          </p>
        </li>
      </ol>

      <p>
        Todo o conteúdo do aplicativo, incluindo sua programação, edição,
        compilação, desenhos, logotipos, imagens, textos e gráficos são de
        propriedade exclusiva da ADias<strong> </strong>e encontram-se
        protegidos pela legislação nacional e internacional sobre propriedade
        industrial e intelectual. O mero acesso por parte do Usuário não lhe
        outorga nenhum direito sobre eles.
      </p>

      <p>
        Desta forma, o Usuário não deverá realizar qualquer ação para capturar,
        imprimir, divulgar e/ou armazenar o conteúdo e os materiais
        disponibilizados na Plataforma. A gravação de imagens e sons, prints da
        tela, cópia e/ou download de materiais estão estritamente proibidos, a
        menos que exista autorização prévia e por escrito da{" "}
        <strong>ADias</strong>.
      </p>

      <p>
        <br />
        &nbsp;
      </p>

      <ol start="3">
        <li>
          <p>
            <strong>Responsabilidade</strong>
          </p>
        </li>
      </ol>

      <p>
        A ADias<strong> </strong>cumpre com a legislação vigente e atua com a
        necessária diligência na publicação de seu conteúdo, e, portanto, não
        poderá ser responsabilizado por qualquer tipo de prejuízo que o Usuário
        possa sofrer em decorrência da utilização do aplicativo.
      </p>

      <p>
        A ADias<strong> </strong>poderá publicar links que direcionem o Usuário
        a sites externos. Neste caso, o Usuário manifesta estar ciente de que
        são plataformas de terceiros sem nenhuma relação com a ADias
        <strong> </strong>e de que a ADias<strong> </strong>não possui nenhuma
        responsabilidade sobre estes conteúdos, assim como não garante que estes
        sejam verídicos, adequados ou corretos.
      </p>

      <p>
        A ADias<strong> </strong>não controla a maneira com que o Usuário
        utiliza o aplicativo e não pode garantir que estes realizem uma
        utilização legal, diligente e prudente desta ferramenta. É dever do
        Usuário utilizar a Plataforma dentro dos limites legais, respeitando a
        moral e a ética do ambiente de trabalho.
      </p>

      <p>
        Em caso de não atendimento das normas previstas neste Termo por parte do
        Usuário, a ADias poderá cancelar seu acesso à Plataforma, sem
        necessidade de pré-aviso ou de justificativa, e adotar as medidas
        administrativas e/ou judiciais pertinentes.
      </p>

      <ol start="4">
        <li>
          <p>
            <strong>Exclusão de Garantias</strong>
          </p>
        </li>
      </ol>

      <p>
        A ADias<strong> </strong>adota todas as medidas técnicas necessárias
        para que o aplicativo seja seguro, entretanto, não garante o tempo de
        execução do aplicativo, nem seu funcionamento ininterrupto, livre de
        erros ou de softwares maliciosos. Não nos responsabilizamos por
        eventuais perdas ou danos que o Usuário possa sofrer em decorrência de
        interferências, omissões, interrupções, softwares maliciosos ou
        desconexões no sistema operativo causadas por fatores externos a nossos
        sistemas.
      </p>

      <p>
        Por isso, recomendamos que o Usuário adote medidas técnicas adequadas
        para controlar de forma razoável estes riscos e evitar a ocorrência de
        danos em seus equipamentos, assim como a perda de dados e extravio de
        informação. Para tanto, deverá ter sistemas atualizados de detecção de
        softwares maliciosos, como vírus, troyanos, ramsomwares, spywares etc. e
        atualizar de forma periódica os patches de segurança de seus
        navegadores.
      </p>

      <ol start="5">
        <li>
          <p>
            <strong>Dever dos Usuários</strong>
          </p>
        </li>
      </ol>

      <p>O Usuário se compromete a:</p>

      <ol>
        <li>
          <p>
            Fornecer dados verídicos e atualizados em todos os formulários do
            aplicativo.
          </p>
        </li>
        <li>
          <p>
            Não utilizar identidades falsas, nem suplantar a identidade de
            terceiros na utilização do aplicativo.
          </p>
        </li>
        <li>
          <p>
            Manter a confidencialidade das credenciais de acesso à Plataforma,
            impedindo que seja acessada por terceiros.
          </p>
        </li>
        <li>
          <p>
            Não utilizar o aplicativo para realizar atividades publicitarias ou
            comerciais;
          </p>
        </li>
        <li>
          <p>
            Não introduzir, armazenar, difundir nenhum software, dados, vírus,
            código ou qualquer outro instrumento que seja suscetível de causar
            danos no aplicativo ou de impedir que este funcione com normalidade.
          </p>
        </li>
        <li>
          <p>
            Não difundir conteúdo que infrinja a propriedade industrial ou
            intelectual da ADias ou de um terceiro.
          </p>
        </li>
        <li>
          <p>
            Não difundir conteúdo ou realizar comentários que possam ferir a
            honra de terceiros, que tenham cunho desrespeitoso, pornográfico, ou
            que incitem a discriminação, o ódio ou a violência contra uma pessoa
            ou grupo de pessoas por causa da sua raça, cor, origem étnica ou
            nacional, religião, sexo ou orientação sexual.
          </p>
        </li>
      </ol>

      <p>
        <br />
        &nbsp;
      </p>

      <ol start="6">
        <li>
          <p>
            <strong>Menores de Idade</strong>
          </p>
        </li>
      </ol>

      <p>
        A informação publicada pela ADias no aplicativo está direcionada a
        pessoas maiores de idade. A utilização deste por menores de idade requer
        a autorização previa por parte de seus pais ou representantes legais,
        que serão responsáveis por todos os atos realizados por aqueles através
        do aplicativo.
      </p>
    </div>
  );
};

export default TermoDeUso;
