import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, useParams } from "react-router-dom";
import {
  Alert,
  Card,
  CardBody,
  Col,
  Container,
  FormGroup,
  Input,
  Label,
  Row,
  Spinner,
} from "reactstrap";
import { apiClient } from "../../api/apiCore";
import ProductsPagination from "./ProductsPagination";

import Breadcrumb from "../../components/Breadcrumb";
import {
  addProductBySku,
  atualizaCarrinho,
  checkCartRuleOnChange,
  clearNewOrder,
  getNewOrder,
  getOrderActive,
  getProductDetails,
  removeOrderActive,
  resetAllDataOrder,
} from "../../store/actions";
//import Component
import AddedItem from "./AddedItem";
import Banner from "../../pages/NewOrders/Banner";
import FindedItem from "./FindedItem";
import "./newOrder.css";
import OrderFilter from "./OrderFilter";

import { toast } from "react-toastify";
import { formato } from "../../utils";
import Arquiteto from "./Arquiteto";
import ControleRemoto from "./ControleRemoto";
import Cupons from "./Cupons";
import Frete from "./Frete";
import PedidoEmitido from "./PedidoEmitido";
import RegisterUser from "./RegisterUser";
import Resumo from "./Resumo";
import Saldo from "./Saldo";
import PartnerCompany from "./PartnerCompany";
import { useProfile, useSolar } from "../../hooks";
import { CaretDown, CaretUp, Notepad } from "phosphor-react";
import ObsNF from "./ObsNF";
import ObsInterna from "./ObsInterna";
import CartProducts from "./CartProducts";
import ListProducts from "./ListProducts";
import { ErrorBoundary } from "../../components/ErrorBoundary";
import useLogic from "./useLogic";

const NewOrder = ({ history }) => {
  const {
    newOrder,
    loading,
    order,
    addedItems,
    emissaoPedido,
    addFrete,
    addValArqu,
    addValTot,
    removeValTot,
    addValorRt,
    arrayShippingFilialSelect,
    awaitModfyCart,
    cartBranchSameCustomerUfTable,
    cepCliente,
    changeCepCliente,
    changeClient,
    changePedidoEmitido,
    changeSaldo,
    changeType,
    client,
    currentPage,
    dataProducts,
    fullDiscount,
    errosDataProspectAndPartner,
    formVisible,
    frete,
    id,
    isRevenda,
    itensCarrinho,
    loadingProspect,
    newAddItemsSeparadoPorFilial,
    nfIsOpen,
    obsIsOpen,
    ocultarSemEstoque,
    pagamento,
    paginatedProducts,
    pedido,
    percentResumo,
    qtdAdd,
    saldo,
    setAwaitModfyCart,
    setClient,
    setCurrentPage,
    setDiscountValue,
    setErrosDataProspectAndPartner,
    setFormVisible,
    setItensCarrinho,
    setLoadingProspect,
    setNfIsOpen,
    setObsIsOpen,
    setOcultarSemEstoque,
    setPagamento,
    setPercentResumo,
    setQtdAdd,
    setSendValServ,
    setValServ,
    token,
    type,
    ufValidate,
    valArquiteto,
    valRt,
    valTot,
    valorRt,
    valores,
    discounts,
    activePromotionsAndCoupons,
    setActivePromotionsAndCoupons,
    addValTotTabela,
    valTotTabela,
    valoresTabela,
    freteRedux,
    totalQuantity,
    addTotalQuantity,
    vendedorComRt
  } = useLogic();

  const renderView = () => {
    if ("fabricantes" in newOrder) {
      return (
        <React.Fragment>
          <Breadcrumb title="Página Inicial" breadcrumbItem="Iniciar Venda" />
          <Row>
            <Col md={12}>
              <div className="mb-4">
                {/* import Banner */}
                <Banner />
              </div>
            </Col>
          </Row>
          {loadingProspect && (
            <div className="carregando-emissao">
              <Spinner />
            </div>
          )}

          {loading && (
            <div className="carregando-emissao">
              <Spinner />
            </div>
          )}
          {/* VERIFICA SE O PEDIDO FOI EMITIDO  */}
          {!emissaoPedido?.data?.success ? (
            <React.Fragment>
              <ErrorBoundary fallback="Ocorreu um erro ao exibir o componente OrderFilter">
                <OrderFilter
                  pagamento={pagamento}
                  setPagamento={setPagamento}
                  setAwaitModfyCart={setAwaitModfyCart}
                  pedido={pedido}
                  cartBranchSameCustomerUfTable={cartBranchSameCustomerUfTable}
                  ufValidate={ufValidate}
                  history={history}
                />
              </ErrorBoundary>
              <hr style={{ borderTop: "1.5px solid #D5DCE5" }} />
              <FormGroup check className="d-flex justify-content-end">
                <Input
                  type="checkbox"
                  checked={ocultarSemEstoque}
                  className="sem-estoque"
                  onChange={() => setOcultarSemEstoque(prev => !prev)}
                />
                <Label check className="text-indigo ocultar">
                  Ocultar produtos sem estoque
                </Label>
              </FormGroup>
              {newOrder?.orderProducts ? (
                <Row className="mt-4" id="busca">
                  <ErrorBoundary fallback="Ocorreu um erro ao exibir o componente ListProducts">
                    <ListProducts
                      paginatedProducts={paginatedProducts}
                      ocultarSemEstoque={ocultarSemEstoque}
                      setOcultarSemEstoque={setOcultarSemEstoque}
                      currentPage={currentPage}
                      isRevenda={isRevenda}
                      dataProducts={dataProducts}
                      setCurrentPage={setCurrentPage}
                      pedido={pedido}
                    />
                  </ErrorBoundary>
                </Row>
              ) : (
                <div />
              )}
              <hr id="sentinel" style={{ borderTop: "1.5px solid #D5DCE5" }} />
              {(newOrder?.addedItems.length || 0) > 0 ? (
                <ErrorBoundary fallback="Ocorreu um erro ao exibir o componente CartProducts">
                  <CartProducts
                    setValServ={setValServ}
                    setSendValServ={setSendValServ}
                    addValTot={addValTot}
                    removeValTot={removeValTot}
                    valores={valores}
                    setItensCarrinho={setItensCarrinho}
                    itensCarrinho={itensCarrinho}
                    valArquiteto={valArquiteto}
                    addValArqu={addValArqu}
                    valorRt={valorRt}
                    addValorRt={addValorRt}
                    setPercentResumo={setPercentResumo}
                    setQtdAdd={setQtdAdd}
                    valTot={valTot}
                    addValTotTabela={addValTotTabela}
                    valTotTabela={valTotTabela}
                    valoresTabela={valoresTabela}
                    totalQuantity={totalQuantity}
                    addTotalQuantity={addTotalQuantity}
                  />
                </ErrorBoundary>
              ) : (
                <div />
              )}
              <Row className="mt-4">
                <ErrorBoundary fallback="Ocorreu um erro ao exibir o componente RegisterUser">
                  <RegisterUser
                    valTot={valTot}
                    changeCepCliente={changeCepCliente}
                    changeClient={changeClient}
                    client={client}
                    formVisible={formVisible}
                    setFormVisible={setFormVisible}
                    errosDataProspectAndPartner={errosDataProspectAndPartner}
                    setErrosDataProspectAndPartner={
                      setErrosDataProspectAndPartner
                    }
                  />
                </ErrorBoundary>
              </Row>
              <Row className="mt-3">
                <ErrorBoundary fallback="Ocorreu um erro ao exibir o componente Saldo">
                  <Saldo changeSaldo={changeSaldo} />{" "}
                </ErrorBoundary>
              </Row>
              <Row className="mt-3">
                <ErrorBoundary fallback="Ocorreu um erro ao exibir o componente ObsInterna">
                  <ObsInterna
                    obsIsOpen={obsIsOpen}
                    setObsIsOpen={setObsIsOpen}
                    client={client}
                    setClient={setClient}
                  />
                </ErrorBoundary>
              </Row>
              <Row className="mt-3">
                <ErrorBoundary fallback="Ocorreu um erro ao exibir o componente ObsNF">
                  <ObsNF
                    nfIsOpen={nfIsOpen}
                    setNfIsOpen={setNfIsOpen}
                    client={client}
                    setClient={setClient}
                  />
                </ErrorBoundary>
              </Row>
              <Row className="mt-3" id="notas">
                <ErrorBoundary fallback="Ocorreu um erro ao exibir o componente Cupons">
                  <Cupons
                    enabled={formVisible.client}
                    disabledText="Cupom desabilitado em orçamentos com Prospect"
                    discounts={discounts}
                    activePromotionsAndCoupons={activePromotionsAndCoupons}
                    setActivePromotionsAndCoupons={setActivePromotionsAndCoupons}
                  />
                </ErrorBoundary>
              </Row>
              <Row className="d-flex justify-content-between" id="frete">
                <Col md={7}>
                  <ErrorBoundary fallback="Ocorreu um erro ao exibir o componente Frete">
                    <Frete
                      valores={valores}
                      valTot={valTot}
                      addFrete={addFrete}
                      cepCliente={cepCliente}
                      newAddItemsSeparadoPorFilial={
                        newAddItemsSeparadoPorFilial
                      }
                      awaitModfyCart={awaitModfyCart}
                      setAwaitModfyCart={setAwaitModfyCart}
                    />{" "}
                  </ErrorBoundary>
                  {/*  <Arquiteto valArq={valArq} /> */}
                  {vendedorComRt && (
                    <Card style={{ padding: 20 }}>
                      {" "}
                      <ErrorBoundary fallback="Ocorreu um erro ao exibir o componente PartnerCompany">
                        <PartnerCompany
                          pedido={pedido}
                        />{" "}
                      </ErrorBoundary>
                    </Card>
                  )}
                </Col>
                <Col md={5}>
                  {" "}
                  <ErrorBoundary fallback="Ocorreu um erro ao exibir o componente Resumo">
                    <Resumo
                      valTot={valTot}
                      valRt={valRt}
                      frete={frete}
                      saldo={saldo}
                      pedido={pedido}
                      changePedidoEmitido={changePedidoEmitido}
                      changeType={changeType}
                      formVisible={formVisible}
                      token={token}
                      setLoadingProspect={setLoadingProspect}
                      percent={percentResumo}
                      fullDiscount={fullDiscount}
                      arrayShippingFilialSelect={arrayShippingFilialSelect}
                      newAddItemsSeparadoPorFilial={
                        newAddItemsSeparadoPorFilial
                      }
                    />{" "}
                  </ErrorBoundary>
                </Col>
              </Row>
              <ControleRemoto />
            </React.Fragment>
          ) : (
            <ErrorBoundary fallback="Ocorreu um erro ao exibir o componente PedidoEmitido">
              <PedidoEmitido
                changePedidoEmitido={changePedidoEmitido}
                type={type}
              />
            </ErrorBoundary>
          )}
        </React.Fragment>
      );
    } else {
      return (
        <div
          style={{
            marginInline: "auto",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Spinner />
        </div>
      );
    }
  };

  return (
    <React.Fragment>
      <div className="page-content neworder">
        <Container>
          {id ? (
            order ? (
              order?.Situacao < "F" ? (
                addedItems.length > 0 ? (
                  <React.Fragment>{renderView()}</React.Fragment>
                ) : (
                  <Spinner />
                )
              ) : (
                <Redirect to={`/pedidos/${order?.NumPedido}/order`} />
              )
            ) : (
              <Spinner />
            )
          ) : (
            renderView()
          )}
        </Container>
      </div>
    </React.Fragment>
  );
};

export default NewOrder;
