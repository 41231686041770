import { apiClient2 } from "./apiCore2";

// utils
import { objectToUrlParams } from "../utils/index";
const getTVGallary = () => {
  const baseUrl = "/tvadias/live";
  return apiClient2.get(`${baseUrl}`);
};

const getTVConfigGallary = videoId => {
  var baseUrl = `/tvadias/live/config`;
  if (videoId) {
    baseUrl = `/tvadias/live/config/${videoId}`;
  }
  return apiClient2.get(`${baseUrl}`);
};

const loadRecentData = videoId => {
  const baseUrl = "/tvadias/live/config/youtube";
  return apiClient2.create(`${baseUrl}/${videoId}`);
};

const addNewLive = data => {
  const baseUrl = "/tvadias/live/config";
  const config = {
    headers: {
      "content-type": "application/x-www-form-urlencoded",
    },
  };
  const params = objectToUrlParams(data);
  return apiClient2.create(`${baseUrl}`, params, config);
};

const uploadLiveConfigFiles = (videoId, files) => {
  const baseUrl = "/tvadias/live/config/upload";
  var urls = [];
  for (const file of files) {
    const params = {
      id: videoId,
      file: file,
    };
    urls.push({
      url: baseUrl,
      params: params,
    });
  }
  return apiClient2.createWithMultipleFiles(urls);
};

const deleteLiveConfigFile = (videoId, arquivoId) => {
  const baseUrl = "/tvadias/live/config";
  return apiClient2.delete(`${baseUrl}/${videoId}/${arquivoId}/deletar`);
};

const getTVvideoDetails = videoId => {
  const baseUrl = `/tvadias/live`;
  return apiClient2.get(`${baseUrl}/${videoId}`);
};

const postVideoComment = (videoId, commentData) => {
  const baseUrl = `/tvadias/live/comments`;
  return apiClient2.create(`${baseUrl}/${videoId}`, commentData);
};

const deleteMessage = messageId => {
  const baseUrl = `/tvadias/live/comments`;
  return apiClient2.delete(`${baseUrl}/${messageId}`);
};

export {
  getTVGallary,
  getTVConfigGallary,
  loadRecentData,
  addNewLive,
  uploadLiveConfigFiles,
  deleteLiveConfigFile,
  getTVvideoDetails,
  postVideoComment,
  deleteMessage,
};
