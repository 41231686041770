const tabelaDatasToSelect = [
    "Padrão ADIAS",
    "REVENDA/PARCEIRO",
    "NORTE",
    "SITE A.DIAS",
    "TELEVENDAS/CONSUMIDOR FINAL",
    "SITE TRAY",
    "VRF",
    "BLACK FRIDAY 2021",
    "TABELA DE SERVIÇOS",
    "TABELA DE PEÇAS",
    "MADEIRA MADEIRA",
    "MAGAZINE LUIZA",
    "CNOVA",
    "GARANTIA ESTENDIDA",
    "MLIVRE CLASSICO",
    "B2W",
    "MLIVRE PREMIUM",
    "CARREFOUR",
    "NET SUPRIMENTOS",
    "AMAZON",
    "ZOOM",
    "TABELA SANTANDER",
    "SOLAR",
];

const fidelidade = [
    "Diamante",
    "Ouro",
    "Prata",
    "Bronze",
    "Clube",
    "Esmeralda",
    "todas as fidelidade",
]

const TiposUnidadesDeMedida = [
    "Porcentagem (%)",
    "Valor (R$)"
]

export {tabelaDatasToSelect, fidelidade, TiposUnidadesDeMedida}