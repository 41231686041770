import React from "react";
import { Col, Row, Card, CardBody } from "reactstrap";

import { Link } from "react-router-dom";

import "./rules.css";

const RulesItem = ({ rule }) => {
  function formatDate(date) {
    let data = date;
    let dataFormatada = `${data.getDate().toString().padStart(2, "0")}/${(
      data.getMonth() + 1
    )
      .toString()
      .padStart(2, "0")}/${data.getFullYear()}`;
    return dataFormatada;
  }

  const isActiveRule = (active, startDate, endDate) => {
    const start = new Date(startDate);
    const end = new Date(endDate);
    const currentDateWithHours = new Date();
    const currentDate = new Date(currentDateWithHours.getFullYear(), currentDateWithHours.getMonth(), currentDateWithHours.getDate(), 0, 0, 0);
    return active && (currentDate >= start && currentDate <= end);
  }

  return (
    <React.Fragment>
      <Row className="mt-2">
        <Col>
          <Card
            style={{
              borderLeft: `10px solid ${
                isActiveRule(rule.active, rule.start, rule.end) ? "#97E655" : "#DB0000"
              }`,
            }}
          >
            <CardBody className="d-flex flex-column flex-md-row align-items-center justify-content-between card-list text-center">
              <label className="rulesLabel" style={{ width: "29px" }}>{rule.id}</label>
              <label className="rulesLabel" style={{ width: "200px" }}>
                {rule.rule_name != null
                  ? rule.rule_name.length > 940
                    ? `${rule.rule_name.slice(0, 40)}...`
                    : rule.rule_name
                  : null}
              </label>
              <label className="rulesLabel">{formatDate(new Date(rule.created_at))}</label>
              <label className="rulesLabel" style={{ width: "63px" }}>
                {rule.need_coupom === 1 ? "Sim" : "Não"}
              </label>
              <label className="rulesLabel" style={{ width: "66px" }}>
                {rule.applytoshipping === 1 ? "Sim" : "Não"}
              </label>
              <Link style={{}} to={`regra-promocao/${rule.id}`}>
                <i className="fa fa-search fa-md"></i>
              </Link>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default RulesItem;
