import React from "react";

import { SideSubMenu } from "./styles";

import { Link, useLocation } from "react-router-dom";
import { useSolar } from "../../../hooks";
import { getBudgetClear, removeAllItems } from "../../../store/actions";
import { useDispatch } from "react-redux";
import SvgIcon from "../../SvgIcon";

export default function SideSubMenuComponent({
  item,
  setMouseHover,
  openSubMenu,
}) {
  const location = useLocation();
  const isSolar = useSolar();
  const dispatch = useDispatch();

  const handleClick = () => {
    dispatch(removeAllItems());
    dispatch(getBudgetClear());
  };

  return (
    <SideSubMenu
      onMouseEnter={() => setMouseHover(item.id)}
      onMouseLeave={() => setMouseHover(0)}
      dataSideSubMenuLenght={item?.subItems?.length}
      openSubMenu={openSubMenu}
    >
      <div className="sub-container">
        <div className="title">{item.label}</div>
        <ul>
          {item?.subItems &&
            item.subItems.map((item, k) => (
              <li key={k}>
                <Link
                  style={{
                    color:
                      item.link == location.pathname
                        ? isSolar
                          ? "#FF601A"
                          : "#2D567B"
                        : "#696969",
                    zIndex: 99,
                  }}
                  to={item.link}
                  onClick={handleClick}
                >
                  {item["icon"] && (
                    <SvgIcon
                      style={{ marginRight: 10 }}
                      name={item["icon"]}
                    />
                  )}
                  {item.label}
                </Link>
              </li>
            ))}
        </ul>
      </div>
    </SideSubMenu>
  );
}
