import { call, put, takeEvery, all, fork } from "redux-saga/effects";

// Crypto Redux States
import {
  GET_CONDITIONS,
  GET_PRODUCT_RULES,
  CREATE_PROMOTION_RULES,
  GET_RULES,
  GET_RULES_SELECT,
  UPDATE_PROMOTION_RULES,
  GET_RULES_COLUMNS_FIELDS,
} from "./actionTypes";
import {
  promotionRulesapiSuccess,
  promotionRulesapiFail,
  isLoadingProductRules,
} from "./action";

//Include Both Helper File with needed methods
import {
  getRulesColumnsFields as getRulesColumnsFieldsApi,
  submitForm as submitFormApi,
  getRules as getRulesApi,
  updateForm as updateFormApi,
} from "../../api/index";
import { toast } from "react-toastify";

function* createPromotionRules(data) {
  try {
    yield put(isLoadingProductRules((CREATE_PROMOTION_RULES)));

    const response = yield call(submitFormApi, data.payload.data);

    yield put(promotionRulesapiSuccess(CREATE_PROMOTION_RULES, response));
    toast.success(`Cadastrado com sucesso!`, { theme: "colored" });

    const historyDestination = `regra-promocao/${response.data.id}`;
    data.payload.history.push(historyDestination);
  } catch (error) {
    yield put(promotionRulesapiFail(CREATE_PROMOTION_RULES, error));
    toast.error(`Não foi possivel efetuar o cadastro! erro: ${error}`, {
      theme: "colored",
    });
  }
}

function* updatePromotionRules(data) {
  try {
    yield put(isLoadingProductRules((UPDATE_PROMOTION_RULES)));

    const response = yield call(updateFormApi, data.payload.data);

    yield put(promotionRulesapiSuccess(UPDATE_PROMOTION_RULES, response));
    toast.success(`Atualizado com sucesso!`, { theme: "colored" });
    const historyDestination = `${response.data.id}`;
    data.payload.history.push(historyDestination);
  } catch (error) {
    yield put(promotionRulesapiFail(UPDATE_PROMOTION_RULES, error));
    toast.error(`Não foi possivel efetuar a atualização! erro: ${error}`, {
      theme: "colored",
    });
  }
}

function* getRulesColumnsFields() {
  try {
    yield put(isLoadingProductRules((GET_RULES_COLUMNS_FIELDS)));
    const response = yield call(getRulesColumnsFieldsApi);
    yield put(promotionRulesapiSuccess(GET_RULES_COLUMNS_FIELDS, response));
  } catch (error) {
    yield put(promotionRulesapiFail(GET_RULES_COLUMNS_FIELDS, error));
  }
}

function* geRules({ payload: id }) {
  try {
    yield put(isLoadingProductRules(GET_RULES));
    const resp = yield call(getRulesApi, id);
    yield put(promotionRulesapiSuccess(GET_RULES, resp.rules[0]));
  } catch (error) {
    toast.info(`Ocorreu um erro ao buscar os dados`, { theme: "colored" });
    yield put(promotionRulesapiFail(GET_RULES, error));
  }
}

export function* watchCreatePromotionRules() {
  yield takeEvery(CREATE_PROMOTION_RULES, createPromotionRules);
}

export function* watchUpdatePromotionRules() {
  yield takeEvery(UPDATE_PROMOTION_RULES, updatePromotionRules);
}

export function* watchGetRulesColumnsFields() {
  yield takeEvery(GET_RULES_COLUMNS_FIELDS, getRulesColumnsFields);
}

export function* watchGetRules() {
  yield takeEvery(GET_RULES, geRules);
}

function* conditionsSaga() {
  yield all([
    fork(watchGetRulesColumnsFields),
    fork(watchCreatePromotionRules),
    fork(watchUpdatePromotionRules),
    fork(watchGetRules),
  ]);
}

export default conditionsSaga;
