import {
  API_SUCCESS,
  API_FAIL,
  CREATE_PROMOTION_RULES,
  UPDATE_PROMOTION_RULES,
  GET_RULES_COLUMNS_FIELDS,
  GET_RULES,
  IS_LOADING,
  GET_PRODUCT_RULES,
} from "./actionTypes";

export const promotionRulesapiSuccess = (actionType, data) => ({
  type: API_SUCCESS,
  payload: { actionType, data },
});

export const promotionRulesapiFail = (actionType, error) => ({
  type: API_FAIL,
  payload: { actionType, error },
});

export const createPromotionRules = (data, history) => ({
  type: CREATE_PROMOTION_RULES,
  payload: { data, history },
});

export const updatePromotionRules = (data, history) => ({
  type: UPDATE_PROMOTION_RULES,
  payload: { data, history },
});

export const getRulesColumnsFields = () => ({
  type: GET_RULES_COLUMNS_FIELDS,
});

export const getRules = id => ({
  type: GET_RULES,
  payload: id,
});

export const isLoadingProductRules = actionType => ({
  type: IS_LOADING,
  payload: { actionType },
});
