import React from "react";
export default function IconCar(props) {
  const colorFill = props?.colorFill?.replaceAll("#", "") || "none";

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="26"
      height="33"
      viewBox="0 0 26 33"
      fill={colorFill}
    >
      <path
        d="M25.2105 13.1785C24.8714 13.1785 24.578 13.1785 24.2205 13.1785C24.2205 12.9585 24.2205 12.7568 24.2205 12.5643C24.2205 9.31935 24.2205 6.06518 24.2205 2.82019C24.2205 1.60102 23.643 1.02352 22.4147 1.02352C17.1164 1.02352 11.8272 1.02352 6.52888 1.02352C6.36388 1.02352 6.19888 1.02352 5.95138 1.02352C5.95138 1.77519 5.95138 2.49019 5.95138 3.21435C5.95138 4.53435 5.40138 5.08435 4.06305 5.08435C3.35721 5.08435 2.65138 5.08435 1.89055 5.08435C1.89055 5.31352 1.89055 5.49685 1.89055 5.68018C1.89055 13.6643 1.89055 21.6485 1.89055 29.6327C1.89055 30.9252 2.44971 31.4752 3.75138 31.4752C9.95721 31.4752 16.163 31.4752 22.378 31.4752C23.6705 31.4752 24.2205 30.916 24.2205 29.6143C24.2205 25.3885 24.2205 21.1535 24.2205 16.9277C24.2205 16.726 24.2205 16.5243 24.2205 16.286C24.5597 16.286 24.8714 16.286 25.238 16.286C25.238 16.4693 25.238 16.6527 25.238 16.836C25.238 21.1168 25.238 25.3977 25.238 29.6785C25.238 31.4752 24.2022 32.4927 22.3964 32.4927C16.1722 32.4927 9.94804 32.4927 3.72388 32.4927C1.89055 32.4927 0.882213 31.4752 0.882213 29.6327C0.882213 21.3918 0.882214 13.1602 0.873047 4.91935C0.873047 4.51602 0.992213 4.22269 1.28555 3.93852C2.50471 2.74685 3.69638 1.51852 4.92471 0.326854C5.10805 0.152688 5.41971 0.0151877 5.66721 0.0151877C11.3139 -0.00314559 16.9697 -0.00314545 22.6164 0.00602121C24.1105 0.00602121 25.2289 1.06935 25.238 2.52685C25.2564 5.99185 25.2472 9.46602 25.238 12.931C25.238 13.0043 25.2197 13.0685 25.2105 13.1785ZM4.93388 1.91269C4.20971 2.64602 3.51305 3.34269 2.80721 4.05769C3.34805 4.05769 3.91638 4.09435 4.47555 4.03019C4.64055 4.01185 4.88804 3.71852 4.91554 3.52602C4.97054 3.00352 4.93388 2.46269 4.93388 1.91269Z"
        fill="#E9AF00"
      />
      <path
        d="M12.6482 21.3283C9.8432 21.3283 7.02903 21.3283 4.22404 21.3283C3.5457 21.3283 3.4082 21.2 3.4082 20.54C3.4082 18.6608 3.4082 16.7817 3.4082 14.9117C3.4082 13.8208 4.0132 13.2158 5.10404 13.2158C10.3932 13.2158 15.6915 13.2158 20.9807 13.2158C22.0807 13.2158 22.6857 13.8208 22.6857 14.9025C22.6949 16.4883 22.6949 18.0833 22.6857 19.6692C22.6857 20.7233 22.0624 21.3375 21.0082 21.3375C18.2307 21.3283 15.4349 21.3283 12.6482 21.3283ZM11.0165 18.12C11.869 17.2492 12.639 16.4608 13.409 15.6817C14.014 15.0675 14.124 15.0675 14.7199 15.6725C15.499 16.4608 16.2782 17.2583 16.9657 17.955C17.5982 17.3408 18.1574 16.7908 18.6982 16.2683C18.5699 16.2592 18.3774 16.25 18.1757 16.2317C18.1757 15.8925 18.1757 15.5808 18.1757 15.2417C18.854 15.2417 19.4957 15.205 20.1282 15.26C20.3207 15.2783 20.6324 15.5258 20.6415 15.6817C20.6965 16.36 20.669 17.0567 20.669 17.735C20.3024 17.735 19.9907 17.735 19.6882 17.735C19.6699 17.4967 19.6515 17.3133 19.624 16.9833C18.8999 17.7167 18.2674 18.3675 17.6257 19.0091C17.2315 19.4033 17.0299 19.4033 16.6449 19.0183C16.2599 18.6425 15.8749 18.2575 15.499 17.8725C15.0315 17.4142 14.5732 16.9558 14.069 16.4517C13.2349 17.295 12.4557 18.0925 11.6765 18.8808C11.1174 19.44 10.989 19.4492 10.439 18.8992C9.8157 18.2667 9.19237 17.625 8.64237 17.0567C7.51487 18.1933 6.45153 19.2566 5.4157 20.2925C5.3882 20.2833 5.43403 20.3016 5.47987 20.3016C10.6682 20.3016 15.8657 20.3016 21.054 20.3016C21.5307 20.3016 21.714 20.0541 21.7049 19.605C21.6957 18.0466 21.7049 16.4975 21.7049 14.9392C21.7049 14.3708 21.549 14.215 20.9807 14.215C15.719 14.215 10.4574 14.215 5.20487 14.215C4.60904 14.215 4.46237 14.3617 4.46237 14.9667C4.46237 16.3325 4.46237 17.7075 4.46237 19.0733C4.46237 19.1925 4.48987 19.3208 4.51737 19.5591C5.68154 18.3858 6.75403 17.2858 7.8357 16.195C8.47737 15.5442 8.55987 15.5442 9.2107 16.2133C9.77903 16.8367 10.3565 17.4417 11.0165 18.12Z"
        fill="#E9AF00"
      />
      <path
        d="M3.40824 26.4146C3.39907 24.1871 5.21407 22.3447 7.41407 22.3355C9.70573 22.3263 11.5299 24.1321 11.5299 26.4055C11.5299 28.6238 9.7149 30.448 7.49657 30.4571C5.25074 30.4663 3.4174 28.6605 3.40824 26.4146ZM6.9649 23.4721C5.91074 23.4996 4.8474 24.453 4.5449 25.5896C4.19657 26.9005 4.7374 28.2571 5.85574 28.9813C6.87324 29.6321 8.40407 29.5863 9.20157 28.8255C8.57824 28.2113 7.9274 27.6155 7.34074 26.9738C7.13907 26.7538 6.9924 26.3963 6.97407 26.103C6.92824 25.223 6.9649 24.3521 6.9649 23.4721ZM8.00073 25.8555C8.8349 25.8555 9.62323 25.8555 10.4024 25.8555C10.3107 24.6546 9.0549 23.4355 8.00073 23.4996C8.00073 24.2696 8.00073 25.0488 8.00073 25.8555ZM8.9724 26.928C9.28407 27.2946 9.61407 27.698 10.0174 28.1746C10.1916 27.7255 10.3291 27.3496 10.4941 26.928C9.86157 26.928 9.36657 26.928 8.9724 26.928Z"
        fill="#E9AF00"
      />
      <path
        d="M18.1436 7.14853C18.1344 9.92603 15.8428 12.2085 13.0561 12.1994C10.2603 12.1902 7.99609 9.91686 7.99609 7.12103C7.99609 4.30687 10.2878 2.03353 13.1111 2.05187C15.8886 2.06103 18.1436 4.34353 18.1436 7.14853ZM9.01359 7.12103C9.01359 9.3577 10.8378 11.1819 13.0836 11.1727C15.3203 11.1635 17.1353 9.33936 17.1261 7.09353C17.1169 4.88437 15.2928 3.0602 13.0836 3.0602C10.8286 3.0602 9.01359 4.8752 9.01359 7.12103Z"
        fill="#E9AF00"
      />
      <path
        d="M12.5664 25.8557C12.5664 25.5257 12.5664 25.2323 12.5664 24.9023C15.9397 24.9023 19.2856 24.9023 22.6681 24.9023C22.6681 25.2232 22.6681 25.5165 22.6681 25.8557C19.3222 25.8557 15.9672 25.8557 12.5664 25.8557Z"
        fill="#E9AF00"
      />
      <path
        d="M12.5625 27.8905C12.5625 27.5605 12.5625 27.2763 12.5625 26.9463C15.9175 26.9463 19.2633 26.9463 22.655 26.9463C22.655 27.2305 22.655 27.5421 22.655 27.8905C19.3183 27.8905 15.9633 27.8905 12.5625 27.8905Z"
        fill="#E9AF00"
      />
      <path
        d="M12.5664 29.9152C12.5664 29.5852 12.5664 29.2919 12.5664 28.9619C15.6006 28.9619 18.6164 28.9619 21.6506 28.9619C21.6506 29.2827 21.6506 29.5761 21.6506 29.9152C18.6439 29.9152 15.6281 29.9152 12.5664 29.9152Z"
        fill="#E9AF00"
      />
      <path
        d="M22.6878 22.8755C22.6878 23.2147 22.6878 23.4988 22.6878 23.8197C20.0019 23.8197 17.3253 23.8197 14.6211 23.8197C14.6211 23.5172 14.6211 23.2147 14.6211 22.8755C17.2978 22.8755 19.9653 22.8755 22.6878 22.8755Z"
        fill="#E9AF00"
      />
      <path
        d="M24.2305 15.1957C24.2305 14.8657 24.2305 14.5907 24.2305 14.2607C24.5513 14.2607 24.8538 14.2607 25.2021 14.2607C25.2021 14.5632 25.2021 14.8657 25.2021 15.1957C24.8813 15.1957 24.5605 15.1957 24.2305 15.1957Z"
        fill="#E9AF00"
      />
      <path
        d="M13.5262 23.8584C13.187 23.8584 12.9029 23.8584 12.582 23.8584C12.582 23.5376 12.582 23.2351 12.582 22.8867C12.8845 22.8867 13.187 22.8867 13.5262 22.8867C13.5262 23.1984 13.5262 23.5009 13.5262 23.8584Z"
        fill="#E9AF00"
      />
      <path
        d="M11.0451 8.14579C11.5035 8.14579 11.8518 8.11829 12.2826 8.45746C12.6126 8.72329 13.3185 8.53079 13.8593 8.49413C13.9326 8.48496 14.0793 8.15496 14.0335 8.03579C13.9601 7.87079 13.7585 7.68746 13.5843 7.64163C13.3093 7.57746 13.016 7.62329 12.7226 7.61413C11.7876 7.60496 11.1276 7.08246 11.036 6.27579C10.9351 5.45996 11.4485 4.79996 12.356 4.57996C12.4018 4.57079 12.4476 4.55246 12.521 4.53413C12.5393 4.38746 12.5576 4.24996 12.576 4.08496C12.8968 4.08496 13.1993 4.08496 13.5385 4.08496C13.5568 4.24079 13.5751 4.38746 13.5935 4.51579C14.6293 4.79996 14.9868 5.16663 15.0785 6.06496C14.6568 6.04663 14.226 6.30329 13.9051 5.75329C13.7493 5.48746 12.4293 5.49663 12.2276 5.74413C12.1268 5.87246 12.0443 6.11079 12.0993 6.23913C12.1635 6.39496 12.3651 6.55079 12.5393 6.58746C12.8143 6.64246 13.1076 6.60579 13.401 6.60579C14.336 6.61496 14.996 7.13746 15.0876 7.94413C15.1885 8.75996 14.6751 9.41996 13.7676 9.63996C13.7218 9.64913 13.676 9.66746 13.6026 9.68579C13.5843 9.83246 13.566 9.96996 13.5476 10.135C13.2268 10.135 12.9243 10.135 12.5851 10.135C12.5668 9.97913 12.5485 9.83246 12.5301 9.70413C11.4851 9.41996 11.1276 9.05329 11.0451 8.14579Z"
        fill="#E9AF00"
      />
    </svg>
  );
}
