import React from "react";
import { Card, CardBody } from "reactstrap";
import clube from "../../assets/images/selos/fidelidade/adias.png";
import ouro from "../../assets/images/selos/fidelidade/ouro.png";
import prata from "../../assets/images/selos/fidelidade/prata.png";
import bronze from "../../assets/images/selos/fidelidade/bronze.png";
import diamante from "../../assets/images/selos/fidelidade/diamante.png";


const Category = ({ data }) => {
  const categories = {
    "@" : "Esmeralda",
    "A" : "Diamante",
    "B" : "Ouro",
    "C" : "Prata",
    "D" : "Bronze",
    "E" : "Clube"
  }

  let fidelidadeImg = "";

  switch (data.fidelidade) {
    case 'B':
      fidelidadeImg = ouro;
      break;
    case 'C':
      fidelidadeImg = prata;
      break;
    case 'D':
      fidelidadeImg = bronze;
      break;
    case 'E':
      fidelidadeImg = clube;
      break;
    case 'A':
      fidelidadeImg = diamante;
      break;
    default:
      fidelidadeImg = '';
      break;
  }

  return (
    <div className="category">
      <Card>
        <CardBody className="text-center position-relative">
          <i className="mdi mdi-information-outline info-icon font-size-16 text-muted" />
          <h6 className="fw-bold mb-5">Sua categoria atual é: {categories[data.fidelidade]}</h6>
          <img
            src={fidelidadeImg}
            height={105}
            className="mb-4"
          />
        </CardBody>
      </Card>
    </div>
  );
};

export default Category;
