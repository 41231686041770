import React from "react";
import { ArInput } from "../../../../../../components/adias-input";
import { Col, Row } from "reactstrap";
import { useEffect } from "react";
import { initialValuesToFormik, modulo } from "../../mockAndValidation";

export const Modulos = ({ errors, touched, resetForm, values }) => {
  useEffect(() => {
    if (values.options == modulo) {
      resetForm({
        values: { ...initialValuesToFormik, options: values.options },
      });
    }
  }, [values.options]);
  return (
    <>
      <Row>
        <Col md={4}>
          <ArInput title="Nome" errors={errors} name="nome" touched={touched} />
        </Col>
        <Col md={4}>
          <ArInput
            title="Modelo"
            errors={errors}
            name="modelo"
            touched={touched}
          />
        </Col>
        <Col md={4}>
          <ArInput
            title="Fabricante"
            errors={errors}
            name="fabricante"
            touched={touched}
          />
        </Col>
      </Row>

      <Row>
        <Col md={4}>
          <ArInput
            title="Descricao"
            errors={errors}
            name="descricao"
            touched={touched}
          />
        </Col>
        <Col md={4}>
          <ArInput
            title="Potência (kw)"
            errors={errors}
            name="potencia"
            type="number"
            touched={touched}
          />
        </Col>
        <Col md={4}>
          <ArInput
            title="Voltagem De Circuito Aberto"
            errors={errors}
            name="voltagemDeCircuitoAberto"
            type="number"
            touched={touched}
          />
        </Col>
      </Row>

      <Row>
        <Col md={4}>
          <ArInput
            title="Corrente De Curto"
            errors={errors}
            name="correnteDeCurto"
            type="number"
            touched={touched}
          />
        </Col>
        <Col md={4}>
          <ArInput
            title="Altura (mm)"
            errors={errors}
            name="altura"
            type="number"
            touched={touched}
          />
        </Col>
        <Col md={4}>
          <ArInput
            title="Comprimento (mm)"
            errors={errors}
            name="comprimento"
            type="number"
            touched={touched}
          />
        </Col>
      </Row>

      <Row>
        <Col md={4}>
          <ArInput
            title="Largura (mm)"
            errors={errors}
            name="largura"
            type="number"
            touched={touched}
          />
        </Col>
        <Col md={4}>
          <ArInput
            title="Peso (kg)"
            errors={errors}
            name="peso"
            type="number"
            touched={touched}
          />
        </Col>
        <Col md={4}>
          <ArInput
            title="Custo Distribuidor"
            errors={errors}
            name="custo"
            type="number"
            touched={touched}
          />
        </Col>
      </Row>

      <Row>
        <Col md={4}>
          <ArInput
            title="Preço Cliente Final"
            errors={errors}
            name="preco"
            type="number"
            touched={touched}
          />
        </Col>
        <Col md={4}>
          <ArInput
            title="Dimensoes"
            errors={errors}
            name="dimensoes"
            touched={touched}
          />
        </Col>
        <Col md={4}>
          <ArInput
            title="Garantia"
            errors={errors}
            name="garantia"
            touched={touched}
          />
        </Col>
      </Row>

      <Row>
        <Col md={4}>
          <ArInput
            title="Fornecedor"
            errors={errors}
            name="fornecedor"
            touched={touched}
          />
        </Col>
        <Col md={4}>
          <ArInput
            title="Celulas"
            errors={errors}
            name="celulas"
            touched={touched}
          />
        </Col>
      </Row>
    </>
  );
};
