import { API_FAIL, API_SUCCESS, CREATE_COLLABORATOR, DELETE_COLLABORATOR, GET_COLLABORATORS, UPDATE_COLLABORATOR } from "./actionTypes"

const INIT_STATE = {
  collaborators: [],
  error: false,
  creatingOrUpdateingError: false,
  loading: false
};

const Collaborator = (state = INIT_STATE, action) => {
  switch (action.type) {
    case API_SUCCESS:
      switch (action.payload.actionType) {
        case GET_COLLABORATORS:
          return {
            ...state,
            collaborators: action.payload.data,
            error: false,
            loading: false
          };
        case CREATE_COLLABORATOR:
        case UPDATE_COLLABORATOR:
          return {
            ...state,
            error: false,
            loading: false
          };
        default:
          return state;
      }
    case API_FAIL:
      switch (action.payload.actionType) {
        case GET_COLLABORATORS:
          return {
            ...state,
            collaborators: [],
            error: action.payload.error,
            loading: false
          };
        case CREATE_COLLABORATOR:
        case UPDATE_COLLABORATOR:
          return {
            ...state,
            loading: false,
            creatingOrUpdateingError: action.payload.error
          };

        default:
          return state;
      }

    case GET_COLLABORATORS:
    case CREATE_COLLABORATOR:
    case UPDATE_COLLABORATOR:
      return {
        ...state,
        error: false,
        loading: true
      };

    default:
      return state;
  }
};

export default Collaborator;
