import {
  CHOOSE_COMPANY,
  API_SUCCESS,
  API_FAIL,
  IS_LOADING,
} from "./actionTypes";

const INIT_STATE = {
  data: {},
  loadingChooseCompany: false,
};

const PromotionRules = (state = INIT_STATE, action) => {
  switch (action.type) {
    case API_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loadingChooseCompany: false,
      };

    case CHOOSE_COMPANY:
      return {
        ...state,
        data: action.payload,
        loadingChooseCompany: true,
      };

    case API_FAIL:
      return {
        ...state,
        data: {},
        loadingChooseCompany: false,
      };

    case IS_LOADING:
      return {
        ...state,
        loadingChooseCompany: true,
      };

    default:
      return state;
  }
};

export default PromotionRules;
