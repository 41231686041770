import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Card, CardBody, Row, Col, Form, Button } from "reactstrap";
import { getLeadsList } from "../../../store/actions";

const Filters = () => {
  const dispatch = useDispatch();
  const [filters, setFilters] = useState({});

  const onChangeFilters = (field, value) => {
    const modifiedFilters = { ...filters };
    modifiedFilters[field] = value;
    setFilters(modifiedFilters);
  };

  const onApply = () => {
    dispatch(getLeadsList(filters));
  };

  const onClear = () => {
    setFilters({});
  };

  return (
    <Card>
      <CardBody>
        <h5>
          <i className="fas fa-search"></i> Pesquisar leads
        </h5>
        <hr />
        <Form>
          <Row>
            <Col md={6}>
              <div className="mb-3">
                <input
                  type="text"
                  className="form-control"
                  id="name"
                  name="name"
                  placeholder="Nome do lead"
                  value={filters["name"] || ""}
                  onChange={e => {
                    onChangeFilters("name", e.target.value);
                  }}
                />
              </div>
            </Col>
            <Col md={6}>
              <div className="mb-3">
                <input
                  type="email"
                  className="form-control"
                  name="email"
                  id="email"
                  placeholder="E-mail do lead"
                  value={filters["email"] || ""}
                  onChange={e => {
                    onChangeFilters("email", e.target.value);
                  }}
                />
              </div>
            </Col>
            <Col md={6}>
              <div className="mb-3">
                <input
                  type="text"
                  className="form-control"
                  id="phone"
                  name="phone"
                  placeholder="Telefone do lead"
                  value={filters["phone"] || ""}
                  onChange={e => {
                    onChangeFilters("phone", e.target.value);
                  }}
                />
              </div>
            </Col>
            <Col md={6}>
              <div className="mb-3">
                <input
                  type="text"
                  className="form-control"
                  id="address"
                  name="address"
                  placeholder="Endereço do lead"
                  value={filters["address"] || ""}
                  onChange={e => {
                    onChangeFilters("address", e.target.value);
                  }}
                />
              </div>
            </Col>
          </Row>
          <hr className="mt-2" />
          <div className="d-flex justify-content-end">
            <Button className="me-2 btn btn-warning text-white" style={{background: '#FF601A' }} onClick={onApply}>
              Pesquisar
            </Button>
            <Button className="btn text-white" style={{background: '#BBCAD2'}} onClick={onClear}>
              Limpar Filtros
            </Button>
          </div>
        </Form>
      </CardBody>
    </Card>
  );
};

export default Filters;
