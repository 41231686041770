import React from "react";
import { Loader } from "react-bootstrap-typeahead";
import { Table, Alert, Button } from "reactstrap";
import { HeadCol } from "../../../../components/Columns";
import { Action, File, Description, Name } from "./Columns";
import CustomTable from "../../../../components/CustomTable";

const DocumentsTable = ({ documents, loadingList, loadingDelete, handleClickDelete }) => {

  const columns = [
    {
      Header: <HeadCol label="Nome" />,
      Cell: function colRenderer(props) {
        return <Name document={props.original} />
      }
    },
    {
      Header: <HeadCol label="Arquivo" />,
      Cell: function colRenderer(props) {
        return <File document={props.original} />
      }
    },
    {
      Header: <HeadCol label="Descrição" />,
      Cell: function colRenderer(props) {
        return <Description document={props.original} />
      }
    },
    {
      Header: <HeadCol label="Ações" />,
      Cell: function colRenderer(props) {
        return <Action 
          document={props.original}
          loadingDelete={loadingDelete} 
          handleClickDelete={handleClickDelete}
        />
      }
    },
  ];

  return (
    <div>
      { (!loadingList && !loadingDelete) ? (
        documents && documents.length > 0 ? (
          <CustomTable
            columns={columns}
            data={documents}
            classNames="align-middle custom-table table-check"
            selectable={false}
            idField={"id"}
          />
        ) : (
          <Alert color="info">Nenhum documento encontrado</Alert>
        )
      ): (
        <Loader />
      )}
  
    </div>
  );

};

export default DocumentsTable;
