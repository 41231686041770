import { Form, Formik } from "formik";
import React from "react";
import {
  Card,
  CardBody,
  Col,
  Container,
  Label,
  Row,
  Spinner,
} from "reactstrap";
import Breadcrumb from "../../../../components/Breadcrumb";
import { useSolar } from "../../../../hooks";
import { BotaoEntrar } from "../../../../pages/Orcamento/ModalRegisterClient/style";
import {
  inversor,
  modulo,
  peca,
  RegisterSchema,
  initialValuesToFormik,
} from "./mockAndValidation";

import "./index.scss";
import { useRef } from "react";
import { useState } from "react";
import { Inversores } from "./type-products/Inversores";
import { Pecas } from "./type-products/pecas";
import { Modulos } from "./type-products/modulos";
import { useEffect } from "react";
import { CaretLeft } from "phosphor-react";
import { Link, useHistory, useLocation } from "react-router-dom";
import axios from "axios";
import { apiClient } from "../../../../api/apiCore";
import { toast } from "react-toastify";

export default function Register() {
  const isSolar = useSolar();
  const { search, pathname } = useLocation();
  const history = useHistory();
  const [categoriaSelect, setCategoriaSelect] = useState([]);
  const [categoriaSelectLoading, setCategoriaSelectLoading] = useState(false);
  const formRef = useRef(null);
  const [optionsSelect] = useState([
    { id: 1, name: inversor },
    { id: 2, name: modulo },
    { id: 3, name: peca },
  ]);

  var query = useQuery();
  var isUpdate = pathname.includes("update");

  function useQuery() {
    return React.useMemo(() => new URLSearchParams(search), [search]);
  }

  const getData = async () => {
    const resp = await apiClient.get(
      `/api/solar-products/list/${query.get("tipoForm")}/${query.get("id")}`
    );
    let data = resp;

    Object.keys(data).map(item => {
      if (item == "tipoForm") {
        return;
      }
      formRef.current.setFieldValue(item, data[item]);
    });
  };

  useEffect(() => {
    if (formRef.current) {
      if (isUpdate) {
        formRef.current.setFieldValue("tipoForm", query.get("tipoForm"));
        getData();
      }
    }
  }, [query, formRef, pathname]);

  const handleSubmit = async (props, { setSubmitting, resetForm }) => {
    if (isUpdate) {
      const resp = await apiClient.update(
        `/api/solar-products/update/${query.get("id")}`,
        props
      );
      toast.success("Atualização efetuada com sucesso!", { theme: "colored" });
      history.push("/solar/product-crud/list");
    } else {
      const resp = await apiClient.create("/api/solar-products/add", props);
      toast.success("Cadastro efetuado com sucesso!", { theme: "colored" });
    }

    resetForm();
    setSubmitting(false);
  };

  const getProducts = async () => {
    setCategoriaSelectLoading(true);
    const resp = await apiClient.get(`/api/person-kits/listProducts`);
    let newObj = [];

    Object.keys(resp).map((item, index) =>
      newObj.push({ id: index, name: item, value: item.toLowerCase() })
    );
    setCategoriaSelect(newObj);
    setCategoriaSelectLoading(false);
  };

  useEffect(() => {
    getProducts();
  }, []);

  return (
    <React.Fragment>
      <div className="page-content consultar-orcamento">
        <Container>
          <Breadcrumb
            title="Página Inicial"
            breadcrumbItem={isUpdate ? "Alterar Produto" : "Registrar Produto"}
          />
          <Card className="cardprincipal">
            <CardBody className="card-body">
              {" "}
              <Row style={{ marginBottom: 40 }}>
                <Col md={12}>
                  <h1>
                    {isUpdate ? "Alterar Produto" : "Cadastro de Produto"}
                  </h1>
                </Col>
              </Row>
              <Formik
                initialValues={initialValuesToFormik}
                validationSchema={RegisterSchema}
                onSubmit={handleSubmit}
                innerRef={formRef}
              >
                {({
                  errors,
                  touched,
                  isSubmitting,
                  setFieldValue,
                  values,
                  handleChange,
                  handleBlur,
                  setErrors,
                  setTouched,
                  resetForm,
                }) => (
                  <Form>
                    <Row>
                      <Col md={4}>
                        <label
                          style={{ marginTop: "0.5rem", marginBottom: "5px" }}
                        >
                          Tipo de produto
                        </label>
                        <select
                          className="form-select"
                          aria-label="Filter select"
                          name="tipoForm"
                          value={values.tipoForm}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          style={{ height: 35 }}
                          disabled={isUpdate}
                        >
                          {optionsSelect?.map(item => {
                            return (
                              <option key={item.id} value={item.name}>
                                {item?.name}
                              </option>
                            );
                          })}
                        </select>
                      </Col>
                    </Row>
                    {values.tipoForm == "inversor" && (
                      <Inversores
                        errors={errors}
                        touched={touched}
                        resetForm={resetForm}
                        values={values}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                      />
                    )}

                    {values.tipoForm == "peca" && (
                      <Pecas
                        errors={errors}
                        touched={touched}
                        resetForm={resetForm}
                        values={values}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        categoriaSelect={categoriaSelect}
                        categoriaSelectLoading={categoriaSelectLoading}
                      />
                    )}
                    {values.tipoForm == "modulo" && (
                      <Modulos
                        errors={errors}
                        touched={touched}
                        resetForm={resetForm}
                        values={values}
                      />
                    )}

                    <Row>
                      <Col
                        md={12}
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                          gap: 30,
                        }}
                      >
                        <Link to="/solar/product-crud/list">
                          <BotaoEntrar
                            isSolar={isSolar}
                            type="button"
                            disabled={isSubmitting}
                            style={{
                              background: "transparent",
                              border: "1px solid #FF601A",
                              color: "#FF601A",
                            }}
                          >
                            <CaretLeft
                              style={{ marginRight: 5 }}
                              size={17}
                              weight="bold"
                              color="#FF601A"
                            />{" "}
                            Voltar
                          </BotaoEntrar>
                        </Link>
                        <BotaoEntrar
                          isSolar={isSolar}
                          type="submit"
                          disabled={isSubmitting}
                        >
                          {isSubmitting ? (
                            <Spinner size="sm" />
                          ) : isUpdate ? (
                            "Atualizar"
                          ) : (
                            "Cadastrar"
                          )}
                        </BotaoEntrar>
                      </Col>
                    </Row>
                  </Form>
                )}
              </Formik>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  );
}
