import { apiClient } from "./apiCore";

export const getLeadsList = filters => {
  const baseUrl = `/api/solar/lead_all`;
  return apiClient.get(baseUrl, filters);
};

export const getLeadDetails = id => {
  const baseUrl = `/api/solar/lead/${id}`;
  return apiClient.get(baseUrl, { type: "order" });
};

export const updateLeadDetails = data => {
  const baseUrl = ``;
  return apiClient.create(baseUrl, data);
};
