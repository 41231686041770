import React from "react";
import { ArInput } from "../../../../../../components/adias-input";
import { Col, Row, Label } from "reactstrap";
import { useEffect } from "react";
import { initialValuesToFormik, peca } from "../../mockAndValidation";
import { apiClient } from "../../../../sunbeam-at-your-address/helpers/importer.helper";
import { useState } from "react";

export const Pecas = ({
  errors,
  touched,
  resetForm,
  values,
  handleChange,
  handleBlur,
  categoriaSelect,
  categoriaSelectLoading,
}) => {
  useEffect(() => {
    if (values.options == peca) {
      resetForm({
        values: { ...initialValuesToFormik, options: values.options },
      });
    }
  }, [values.options]);

  return (
    <>
      <Row>
        <Col md={4}>
          <ArInput title="Nome" errors={errors} name="nome" touched={touched} />
        </Col>
        <Col md={4}>
          <Label style={{ marginTop: 8, marginBottom: 5 }}>Categoria</Label>
          <select
            className="form-select"
            aria-label="Filter select"
            name="categoria"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.categoria}
            style={{ height: 35 }}
            disabled={categoriaSelectLoading}
          >
            {categoriaSelect?.map(item => {
              return (
                <option key={item.value} value={item.value}>
                  {categoriaSelectLoading ? "Carregando..." : item?.name}
                </option>
              );
            })}
          </select>
        </Col>
        <Col md={4}>
          <ArInput
            title="Tamanho"
            errors={errors}
            name="tamanho"
            type="number"
            touched={touched}
          />
        </Col>
      </Row>

      <Row>
        <Col md={4}>
          <ArInput
            title="Custo Distribuidor"
            errors={errors}
            name="custo"
            type="number"
            touched={touched}
          />
        </Col>
        <Col md={4}>
          <ArInput
            title="Preco"
            errors={errors}
            name="preco"
            type="number"
            touched={touched}
          />
        </Col>
        <Col md={4}>
          <ArInput
            title="Codigo Fornecedor"
            errors={errors}
            name="codigoFornecedor"
            touched={touched}
          />
        </Col>
      </Row>

      <Row>
        <Col md={4}>
          <ArInput
            title="Estoque"
            errors={errors}
            name="estoque"
            type="number"
            touched={touched}
          />
        </Col>
        <Col md={4}>
          <ArInput
            title="Código"
            errors={errors}
            name="codigo"
            touched={touched}
          />
        </Col>
      </Row>
    </>
  );
};
