import { DownloadActionTypes } from "./actionTypes";

const INIT_STATE = {
  downloadDetails: {},
  categories: {},
};

const Download = (state = INIT_STATE, action) => {
  switch (action.type) {
    case DownloadActionTypes.API_RESPONSE_SUCCESS:
      switch (action.payload.actionType) {
        case DownloadActionTypes.GET_DOWNLOAD_DETAILS:
          return {
            ...state,
            downloadDetails: action.payload.data,
            getDownloadDetailsLoading: false,
            isDownloadDetailsFetched: true,
          };

        case DownloadActionTypes.GET_CATEGORIES_OPTIONS_DETAILS:
          const existingCategories = { ...state.categories };
          var newCategory = {};
          newCategory[action.payload.data.id] = action.payload.data.data;
          const totalCategories = { ...existingCategories, ...newCategory };
          return {
            ...state,
            categories: totalCategories,
            isCategoriesOptionFetched: true,
          };

        default:
          return { ...state };
      }
    case DownloadActionTypes.API_RESPONSE_ERROR:
      switch (action.payload.actionType) {
        case DownloadActionTypes.GET_DOWNLOAD_DETAILS:
          return {
            ...state,
            downloadDetailsError: action.payload.error,
            getDownloadDetailsLoading: false,
            isDownloadDetailsFetched: true,
          };

        case DownloadActionTypes.GET_CATEGORIES_OPTIONS_DETAILS:
          return {
            ...state,
            categoriesOptionsError: action.payload.error,
            isCategoriesOptionFetched: true,
          };

        default:
          return { ...state };
      }

    case DownloadActionTypes.GET_DOWNLOAD_DETAILS:
      return {
        ...state,
        getDownloadDetailsLoading: true,
        isDownloadDetailsFetched: false,
      };

    case DownloadActionTypes.GET_CATEGORIES_OPTIONS_DETAILS:
      return {
        ...state,
        isCategoriesOptionFetched: false,
      };

    default:
      return state;
  }
};

export default Download;
