import { call, put, takeEvery, all, fork } from "redux-saga/effects";

// Crypto Redux States
import { CHOOSE_COMPANY } from "./actionTypes";
import { companyapiFail, companyapiSuccess } from "./action";

//Include Both Helper File with needed methods
import { getChooseCompany as getChooseCompanyApi } from "../../api/index";
import { setLoggeedInUser } from "../../helpers/firebase_helper";

function* ChooseCompany({ payload: data }) {
  let idEmpresa = "0102";
  var CompanyChanging = {};

  if (data.empresa == "solar") {
    idEmpresa = "0202";
    CompanyChanging = {
      ...data.userProfile,
      claims: { ...data.userProfile.claims, company: "02" },
    };

    data.setUserProfile(CompanyChanging);
    setLoggeedInUser(CompanyChanging);

    if (data.att == "noAutomatic") {
      data.history.push("/solar/?open_modal=true");
      location.reload();
    }
  } else {
    idEmpresa = "0102";
    CompanyChanging = {
      ...data.userProfile,
      claims: { ...data.userProfile.claims, company: "01" },
    };

    data.setUserProfile(CompanyChanging);
    setLoggeedInUser(CompanyChanging);

    if (data.att == "noAutomatic") {
      data.history.push("/dashboard");
      location.reload();
    }
  }
  try {
    const resp = yield call(getChooseCompanyApi, idEmpresa);

    yield put(companyapiSuccess(CHOOSE_COMPANY, resp));
  } catch (error) {
    yield put(companyapiFail(CHOOSE_COMPANY, error));
  }
}

export function* watchChooseCompany() {
  yield takeEvery(CHOOSE_COMPANY, ChooseCompany);
}

function* ChooseCompanyWatch() {
  yield all([fork(watchChooseCompany)]);
}

export default ChooseCompanyWatch;
