import React, { useEffect } from "react";
import { Col, Container, Row, Spinner, Alert } from "reactstrap";
import { Link, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getOrderActive } from "../../store/actions";
import "./OrderDatails.css";
import moment from "moment";
// components
import Status from "./Status";
import ClientData from "./ClientData";
import OrderData from "./OrderData";
import Addresses from "./Addresses";
import OrderItems from "./OrderItems";
import StatusCar from "../../components/StatusCar";

// import OrderItems2 from "./OrderItems2";
// import DeliveryOccurrence from "./DeliveryOccurrence";
import SubmittedDocuments from "./SubmittedDocuments";
import PaymentReceived from "./PaymentReceived";
import GFEData from "./GFEData";
import Slips from "./Slips";
import ProductsPurchased from "./ProductsPurchased";

// dummy data
import { orderItems } from "../../common/data";
import { CancelCard, CancelTitle } from "./styled";
import { formatInvertDate } from "../../utils";
import { ErroOrder } from "./ErroOrder";
import { Countdown } from "./CountDown";
import SideCard from "../../components/SideCard";
import { useSolar } from "../../hooks";
import { toast } from "react-toastify";
import { apiClient } from "../../api/apiCore";
import { useState } from "react";
import ChildrenBudgetUrl from "./ChildrenBudgetUrl";

const Orders = () => {
  const { id, codFilial } = useParams();
  const dispatch = useDispatch();
  const [orderChildren, setOrderChildren] = useState([]);

  const { loading, success, order, orderActiveError, fila_historico, active } =
    useSelector(state => {
      return {
        loading: state.Order.loading,
        order: state.Order.orderActive.order,
        fila_historico: state.Order.orderActive.filaHistorico,
        orderActiveError: state.Order.orderActiveError,
        success: state.Order.success,
        active: state.Budget.active,
      };
    });

  const bloqueio = fila_historico
    ? fila_historico[(fila_historico?.length || 1) - 1]
    : {};
  var dateAndHour = order?.CJ_XLIMITE;
  var date = moment(dateAndHour?.substring(0, 8)).format("YYYY-MM-DD");
  var hour = dateAndHour?.substring(8, 10);
  var minutes = dateAndHour?.substring(10);
  var hourAndMinutes = `${hour}:${minutes}`;

  const isSolar = useSolar();

  useEffect(() => {
    dispatch(getOrderActive(id, isSolar ? "02" : "01", codFilial));
  }, [dispatch, location.pathname]);

  const clientData = {
    client: {
      N_COMPLETO: order?.cliente?.N_COMPLETO,
      N_REDZ: order?.cliente?.N_REDZ,
      EMAIL: order?.cliente?.EMAIL,
      CONTATO: order?.cliente?.CONTATO,
      job_title: order?.cliente?.CARGO,
      DT_NASC: order?.cliente?.DT_NASC || "",
      CPFCNPJ: order?.cliente?.CPFCNPJ,
      social_number:
        order?.cliente?.TP_PESSOA == "J"
          ? order?.cliente?.Inscr
          : order?.cliente?.Rg,
      prospect_id: order?.filho?.length > 0 ? order.filho[0].Cliente : "",
    },
    telephone: {
      DDD: order?.cliente?.DDD,
      TEL: order?.cliente?.TEL,
      DDD_CEL: order?.cliente?.DDD_CEL,
      CELULAR: order?.cliente?.CELULAR,
    },
    client_address: {
      CEP: order?.cliente?.CEP?.replace(/[^0-9]+/g, ""),
      UF: order?.cliente?.UF,
      MUNICIPIO: order?.cliente?.MUNICIPIO,
      BAIRRO: order?.cliente?.BAIRRO,
      ENDERECO: order?.cliente?.ENDERECO,
      NumEndCob: order?.cliente?.NumEndCob,
      COMPL: order?.cliente?.COMPL,
      CEP_ENT: order?.cliente?.CEP_ENT?.replace(/[^0-9]+/g, ""),
      UF_ENT: order?.cliente?.UF_ENT,
      MunicipEnt: order?.cliente?.MunicipEnt,
      BAI_ENT: order?.cliente?.BAI_ENT,
      END_ENT: order?.cliente?.END_ENT,
      NumEndEnt: order?.cliente?.NumEndEnt,
      COMPL_ENT: order?.cliente?.COMPL_ENT,
    },
    AGENCIA: "",
    CAPSOC: "",
    CNPJTRAB: "",
    CODIBGE: "",
    CODIBGEC: "",
    CODIBGEE: "",
    CPFREF: "",
    DTADMISS: "",
    EMPTRAB: "",
    FATMEN: "",
    FUNCEMP: "",
    HOMEPAGE: "",
    INSCRRUR: "",
    PROCESSO: "",
    SOCIO: "",
    TELREFBANC: "",
    vendedor2: "",
    contribution: order?.cliente?.Contrib,
  };
  const makeOrderChildren = () => {
    var lengthOrder = order.filho.length;

    toast.info(
      lengthOrder > 1
        ? `Os ${lengthOrder} orcamentos atrelados a este estão sendo efetivados para pedido.`
        : `O ${lengthOrder} orcamento atrelado a este esta sendo efetivado para pedido.`,
      { theme: "colored" }
    );

    order.filho.map(async (item, k) => {
      var obj = null;

      obj = {
        ...clientData,
        CODEMPRESA: item.CodEmpresa,
        CODFILIAL: item.CodFilial,
      };

      const res = await apiClient.create(
        `api/orcamentos/efetiva/${item.Orcamento}`,
        obj
      );

      if (!res.erro) {
        toast.success(
          `Orçamento atrelado ${k + 1} efetivado em pedido com sucesso!`,
          {
            theme: "colored",
          }
        );
        setOrderChildren(state => [
          ...state,
          {
            Orcamento: item.Orcamento,
            numPedido: res.pedido,
            CODEMPRESA: obj.CODEMPRESA,
            CODFILIAL: obj.CODFILIAL,
            erro: false,
          },
        ]);
      } else {
        toast.error(
          `Ocorreu um erro ao tentar efetivar o pedido atrelado ${k + 1} ${
            res.msg ? ":" + res.msg : ""
          }`,
          {
            theme: "colored",
          }
        );
        setOrderChildren(state => [
          ...state,
          {
            Orcamento: item.Orcamento,
            numPedido: "",
            ...obj,
            erro: true,
          },
        ]);
      }
    });
  };

  useEffect(() => {
    if (order?.filho) {
      makeOrderChildren();
    }
  }, [order?.filho]);

  return (
    <React.Fragment>
      <div className="page-content order-details">
        <Container>
          {loading ? (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "100vh",
              }}
            >
              <Spinner className="mt-4" />{" "}
            </div>
          ) : (
            <div />
          )}
          {success ? (
            <React.Fragment>
              <Row>
                <Col md={12}>
                  {order?.Situacao == "I" ? (
                    <React.Fragment>
                      <h2 className="numero-pedido">
                        Pedido {order?.NumPedido || 0}
                      </h2>
                      <CancelCard>
                        <CancelTitle>
                          Pedido Cancelado: {bloqueio?.desc_mot_bloq || ""}
                        </CancelTitle>
                        <p>
                          Esse pedido foi cancelado em:{" "}
                          {bloqueio?.dtent
                            ? formatInvertDate(bloqueio?.dtent)
                            : ""}{" "}
                          {bloqueio?.hrent || ""} por: {bloqueio?.respon || ""}
                        </p>
                        <p>
                          Motivo do cancelamento: {bloqueio?.descricao || ""}
                        </p>
                      </CancelCard>
                    </React.Fragment>
                  ) : (
                    <Status />
                  )}
                </Col>
              </Row>

              <hr />
              {/* order details start */}

              {order?.filho && (
                <ChildrenBudgetUrl
                  orderChildren={orderChildren}
                  setOrderChildren={setOrderChildren}
                  clientData={clientData}
                />
              )}

              {order?.Situacao != "I" && order?.Situacao <= "E" && (
                <Row>
                  <Col xs={12}>
                    <Alert style={{ display: "flex" }} color="danger">
                      <Countdown dateHours={`${date} ${hourAndMinutes}`} />
                    </Alert>
                  </Col>
                </Row>
              )}
                {(active?.data?.orcamento?.ObservacaoNota && active.data.orcamento.ObservacaoNota.trim() !== "") && (
                  <div className="mb-2">
                    <SideCard type="solar" title="Observações da Nota Fiscal">
                      {order?.ObservacaoNota.split('|').map((linha, index) => (
                        <div key={index}>
                          {linha}
                          <br />
                        </div>
                      ))}
                    </SideCard>
                  </div>
                )}

              {(order?.ObservacaoInterna && order.ObservacaoInterna.trim() !== "") && (
                <div className="mb-2">
                  <SideCard className="mt-2" type="solar" title="Observações Internas">
                    {order?.ObservacaoInterna.split('|').map((linha, index) => (
                      <div key={index}>
                        {linha}
                        <br />
                      </div>
                    ))}
                  </SideCard>
                </div>
              )}
              <Row>
                <Col xs={12}>
                  <ClientData />
                  <OrderData />
                  <Addresses />
                </Col>
              </Row>
              {/* order details end */}
              <hr />
              <Row>
                <Col xs={12}>
                  <OrderItems orderItems={orderItems} />
                  {/* <OrderItems2 orderItems2={orderItems2} />
                <DeliveryOccurrence orderItems2={orderItems2} /> */}
                </Col>
              </Row>
              <hr />
              {isSolar && (
                <>
                  {" "}
                  <Row>
                    {" "}
                    <Col xs={12}>
                      <StatusCar
                        height="100%"
                        maxHeight={300}
                        status={order?.status?.status}
                        loggedInUsername={false}
                      />{" "}
                    </Col>{" "}
                  </Row>
                  <hr />
                </>
              )}
              <Row>
                <Col xs={12}>
                  <SubmittedDocuments />
                  <PaymentReceived date={date} />
                  {/* <GFEData /> */}
                  <Slips />
                  <ProductsPurchased />
                </Col>
              </Row>
            </React.Fragment>
          ) : (
            <div />
          )}
          {!loading && orderActiveError && <ErroOrder />}
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Orders;
