import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  Col,
  Row,
  NavLink,
  NavItem,
  Nav,
  TabContent,
  TabPane,
} from "reactstrap";
import ReactApexChart from "react-apexcharts";
import { useDispatch, useSelector } from 'react-redux';
import { Link } from "react-router-dom";

import classnames from "classnames";

//import images
import clubImg from "../../assets/images/brands/Clube.jpg";
import clube from "../../assets/images/selos/fidelidade/adias.png";
import ouro from "../../assets/images/selos/fidelidade/ouro.png";
import prata from "../../assets/images/selos/fidelidade/prata.png";
import bronze from "../../assets/images/selos/fidelidade/bronze.png";
import diamante from "../../assets/images/selos/fidelidade/diamante.png";
import { getCategoryLoyalty } from '../../api';

const MyRating = () => {
  const [activeTab, setactiveTab] = useState("1");

  const dispatch = useDispatch();

  const [fidelidade, setFidelidade] = useState('');
  const [error, setError] = useState(null);

  useEffect(() => {
      const fetchData = async () => {
          try {
              const data = await getCategoryLoyalty();
              setFidelidade(data.category);
          } catch (error) {
              setError(error.message);
          }
      };

      fetchData();
  }, []);

  
  let fidelidadeImg = "";

  switch (fidelidade) {
    case 'Ouro':
      fidelidadeImg = ouro;
      break;
    case 'Prata':
      fidelidadeImg = prata;
      break;
    case 'Bronze':
      fidelidadeImg = bronze;
      break;
    case 'Clube':
      fidelidadeImg = clube;
      break;
    case 'Diamante':
      fidelidadeImg = diamante;
      break;
    default:
      fidelidadeImg = '';
      break;
  }
  function toggle(tab) {
    if (activeTab !== tab) {
      setactiveTab(tab);
    }
  }

  const series = [0];
  const options = {
    chart: {
      height: 200,
      type: "radialBar",
      sparkline: {
        enabled: true,
      },
    },
    plotOptions: {
      radialBar: {
        hollow: {
          size: "70%",
        },
      },
    },
    legend: {
      show: false,
    },
    labels: [""],
  };
  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <h5 className="text-success">MINHA CLASSIFICAÇÃO</h5>
          <hr />
          <Row>
            <Col md={3}>
              <div className="text-center">
                <img className="mb-2 mt-4" src={fidelidadeImg} width="150px" />
                <div>
                  <button className="btn btn-outline-light border-secondary btn-rounded mb-2">
                    {fidelidade}
                  </button>
                  {/* <p className="mt-3 mb-2 text-muted">
                    01/09/2016 - 31/08/2017
                  </p> */}
                  <p className="text-muted mb-2">
                    Minha categoria atual é {fidelidade}.
                  </p>
                  <Link to="#">Entenda as categorias</Link>
                </div>
              </div>
            </Col>
            <Col md={9}>
              <Nav tabs>
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      active: activeTab === "1",
                    })}
                    onClick={() => {
                      toggle("1");
                    }}
                  >
                    <span className="d-block d-sm-none">
                      <i className="fas fa-home"></i>
                    </span>
                    <span className="d-none d-sm-block">Atual</span>
                  </NavLink>
                </NavItem>
              </Nav>
              <TabContent activeTab={activeTab} className="p-3 text-muted">
                <TabPane tabId="1">
                  <Row>
                    <Col md={3}>
                      <div className="text-center">
                        <ReactApexChart
                          options={options}
                          series={series}
                          type="radialBar"
                          height={150}
                        />
                        <a href="#" className="h6">
                          Pedidos
                        </a>
                      </div>
                    </Col>
                    <Col md={3}>
                      <div className="text-center">
                        <ReactApexChart
                          options={options}
                          series={series}
                          type="radialBar"
                          height={150}
                        />
                        <a href="#" className="h6">
                          Faturamento
                        </a>
                      </div>
                    </Col>
                    <Col md={3}>
                      <div className="text-center">
                        <ReactApexChart
                          options={options}
                          series={series}
                          type="radialBar"
                          height={150}
                        />
                        <a href="#" className="h6">
                          Frequência
                        </a>
                      </div>
                    </Col>
                    <Col md={3}>
                      <div className="text-center">
                        <ReactApexChart
                          options={options}
                          series={series}
                          type="radialBar"
                          height={150}
                        />
                        <a href="#" className="h6">
                          Para Bronze
                        </a>
                      </div>
                    </Col>
                  </Row>
                  <Row className="mt-4">
                    <Col md={3}>
                      <p className="mb-1">Média de Pedidos</p>
                      <h6>3.17</h6>
                    </Col>
                    <Col md={3}>
                      <p className="mb-1">Média Faturamento</p>
                      <h6>R$ 30.245,03</h6>
                    </Col>
                    <Col md={3}>
                      <p className="mb-1">Pedidos no mês</p>
                      <h6>3</h6>
                    </Col>
                    <Col md={3}>
                      <p className="mb-1">Faturado no mês</p>
                      <h6>R$ 15.871,37</h6>
                    </Col>
                  </Row>
                </TabPane>
              </TabContent>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default MyRating;
