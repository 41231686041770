import { useEffect, useState } from "react";
import { getAuthenticatedUser } from "../helpers/firebase_helper";
// api core
import { setAuthorization } from "../api/apiCore";
import { setAuthorization as setAuthorization2 } from "../api/apiCore2";

const useProfile = () => {
  const userProfileSession = getAuthenticatedUser();

  const [token, setToken] = useState(userProfileSession?.stsTokenManager?.accessToken);
  const [loading, setLoading] = useState(!token);
  const [userProfile, setUserProfile] = useState(
    userProfileSession ? userProfileSession : null
  );
  
  setAuthorization(userProfileSession?.stsTokenManager?.accessToken);
  setAuthorization2(userProfileSession?.stsTokenManager?.accessToken);
  
  useEffect(() => {
    const userProfileSession = getAuthenticatedUser();
    setAuthorization(userProfileSession?.stsTokenManager?.accessToken);
    setAuthorization2(userProfileSession?.stsTokenManager?.accessToken);
    setToken(userProfileSession?.stsTokenManager?.accessToken);
    setUserProfile(userProfileSession ? userProfileSession : null);
    setLoading(!token);
  }, []);

  return { userProfile, loading, token, setUserProfile };
};

export { useProfile };
