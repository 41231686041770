import {
  GET_CLOSURES,
  API_SUCCESS,
  API_FAIL,
  CREATE_UPLOAD,
  IS_LOADING,
  DELETE_CLOSURE,
} from "./actionTypes";

export const closuresapiSuccess = (actionType, data) => ({
  type: API_SUCCESS,
  payload: { actionType, data },
});

export const closuresapiFail = (actionType, error) => ({
  type: API_FAIL,
  payload: { actionType, error },
});

export const getClosures = id => ({
  type: GET_CLOSURES,
  payload: id,
});

export const createUpload = (data, setModal) => ({
  type: CREATE_UPLOAD,
  payload: { data, setModal },
});

export const deleteClosures = data => ({
  type: DELETE_CLOSURE,
  payload: data,
});

export const isLoading = actionType => ({
  type: IS_LOADING,
  payload: actionType,
});
