import AvForm from "availity-reactstrap-validation/lib/AvForm";
import React, { useState, useEffect } from "react";
import { Col, Container, Row, Card, CardBody, CardText } from "reactstrap";
import Breadcrumb from "../../../components/Breadcrumb";
import DadosPessoais from "./DadosPessoais";
import Endereco from "./Endereco";
import Contato from "./Contato";
import { useParams, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import DadosAdicionais from "./DadosAdicionais";
import { useSelector, useDispatch } from "react-redux";
import {
  getRegistration,
  createOrUpdateRegistration,
} from "../../../store/actions";

const CadastrarRevenda = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const history = useHistory();


  const { registrationDetails } = useSelector(state => ({
    registrationDetails: state.RegistrationsReducer.registrationDetails,
  }));

  const title = id ? `Alterar Revenda` : "Cadastro de Revenda";
  const buttonClassName = `btn btn-lg h6 py-3 text-white w-100 shadow ${
    id ? "bg-dark-blue" : "bg-dark-blue"
  }`;

  const [formData, setFormData] = useState({
    cpfcnpj: "",
    nomeRevenda: "",
    rmAtividade: "",
    comoConheceu: "",
    nomeConsultorAdias: "",
    nomeConsultor: "",
    codConsultor: "",
    nomeFantasia: "",
    telefone: "",
    idRevenda: "",
    dddTel: "",
    celular: "",
    dddCel: "",
    email: "",
    complemento: "",
    cep: "",
    bairro: "",
    numero: "",
    cidade: "",
    estado: "",
    statusSituacao: "",
    nomeContato: "",
    nomeIndicou: "",
    comoConheceu: "",
    codFornecedor: "",
  });

  useEffect(() => {
    if (id) {
      dispatch(getRegistration(id));
    }
  }, [dispatch]);

  useEffect(() => {
    if (id) {
      setFormData({
        cpfcnpj: registrationDetails["cpfcnpj"],
        nomeRevenda: registrationDetails["nome_revenda"],
        nomeFantasia: registrationDetails["nome_reduz"],
        nomeContato: registrationDetails["nome_contato"],
        dddTel: registrationDetails["ddd_tel"],
        telefone: registrationDetails["telefone"],
        celular: registrationDetails["celular"],
        dddCel: registrationDetails["ddd_cel"],
        email: registrationDetails["email"],
        cep: registrationDetails["cep"],
        bairro: registrationDetails["bairro"],
        cidade: registrationDetails["municipio"],
        estado: registrationDetails["uf"],
        complemento: registrationDetails["complemento"],
        numero: registrationDetails["numero"],
        endereco: registrationDetails["endereco"],
        statusSituacao: registrationDetails["status"],
        rmAtividade: registrationDetails["ramo_atividade"],
        nomeConsultorAdias: registrationDetails["nome_consultor"],
        nomeConsultor: registrationDetails["supervisor"],
        codConsultor: registrationDetails["cod_sup"],
        comoConheceu: registrationDetails["como_conheceu"],
        nomeIndicou: registrationDetails["nome_indicou"],
        codFornecedor: registrationDetails["cod_fornecedor"],
        idRevenda: registrationDetails["id"],
      });
    }
  }, [registrationDetails]);

  const titlerevenda = id
    ? registrationDetails["nome_revenda"] +
      "... - #" +
      registrationDetails["id"]
    : "";

  const avisoText =
    id && registrationDetails["status"] == "D" && registrationDetails["id"] ? (
      <Card style={{ backgroundColor: "#1a981a1f" }} inverse>
        <CardBody>
          <CardText style={{ color: "#0c600ca3" }}>
            Cadastro já foi processado pelo Protheus e não pode mais ser
            alterado nesta tela. Para alterar, utilize a tela "Vendedores" ou
            "Hierarquia" no Clube ou Protheus.
          </CardText>
        </CardBody>
      </Card>
    ) : (
      ""
    );

  const buttonTitle = id ? "Alterar solicitação" : "Cadastrar";

  const handleCreateCadastro = (e, err) => {
    if (err.length) {
      toast.warning(`Preencha todos os campos corretamente.`, {
        theme: "colored",
      });
    } else {
      dispatch(createOrUpdateRegistration(formData, history));
    }
  };

  return (
    <>
      <div className="page-content">
        <Container>
          <Breadcrumb title="Página Inicial" breadcrumbItem={title} />
          {avisoText}
          <h3 className="fw-bold mb-4 me-3">
            {title} <small className="opacity-75 h5">{titlerevenda}</small>
          </h3>
          <AvForm onSubmit={handleCreateCadastro}>
            <input
              id="id_cad"
              value={registrationDetails["id"] ?? ""}
              name="id_cad"
              type="hidden"
            />
            <DadosPessoais formData={formData} setFormData={setFormData} />
            <Contato formData={formData} setFormData={setFormData} />
            <Endereco formData={formData} setFormData={setFormData} />
            <DadosAdicionais formData={formData} setFormData={setFormData} />
            <Row className="justify-content-end mb-4">
              <Col md={3}>
                <input
                  className={buttonClassName}
                  type="submit"
                  disabled={registrationDetails["status"] == "D" ? true : false}
                  name="btn-enviar"
                  value={buttonTitle}
                />
              </Col>
            </Row>
          </AvForm>
        </Container>
      </div>
    </>
  );
};
export default CadastrarRevenda;
