import { useEffect, useState } from "react";
import { request } from "../../utils";
import {
  useRef,
  Capa,
  ContraCapa,
  PageOne,
  PageTwo,
  PageThree,
  PageFour,
  PageSix,
  ReactToPrint,
  React,
} from "./helpers/importer.helper";
import "./index.scss";
import { getBudget } from "../../../store/budget/action";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getProductDetails } from "../../../api/order";
import { Spinner } from "reactstrap";
import { getNewOrder } from "../../../store/actions";
export default function PdfComponent() {
  const componentRef = useRef(null);
  const [dataPdf, setDataPdf] = useState(null);
  const [dataProductAttributes, setProductsAttributes] = useState([]);
  const { id } = useParams();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [formOfPayment, setFormOfPayment] = useState("");
  const { active, loadingBudget, newOrder } = useSelector(state => ({
    active: state.Budget.active,
    loadingBudget: state.Budget.active.loading,
    newOrder: state.Order.newOrder.newOrder,
  }));

  const getKwp = () => {
    const newKwp = dataProductAttributes[0].atributos.filter(
      item => item.Codattr == "_kwp"
    );
    setDataPdf(state => {
      return { ...state, kwpPdf: newKwp[0]?.Valor };
    });
  };

  useEffect(() => {
    if (dataProductAttributes.length > 0) {
      getKwp();
    }
  }, [dataProductAttributes]);

  const getFormOfPayment = () => {
    var objOne = newOrder.condPagto["Pagamento Online"];
    var objTwo =
      newOrder.condPagto["Pagamento a prazo - Precisa de De Acordo assinado"];

    var objFull = Object.assign(objOne, objTwo);

    Object.keys(objFull).forEach(item => {
      item == active?.data?.orcamento?.CondPag &&
        setFormOfPayment(objFull[item].name);
    });
  };

  useEffect(() => {
    if (newOrder?.condPagto) {
      getFormOfPayment();
    }
  }, [newOrder, active]);

  const getInfoPdf = async () => {
    setLoading(true);
    const resp = await request("get", `/api/solar/lead/${active.data.lead}`, {
      type: "order",
    });
    dispatch(getNewOrder("CODEMPRESA=02&CODFILIAL=02"));

    setDataPdf(state => {
      return { ...state, lead: resp, dataOrcamento: active.data };
    });
  };

  const getProductAttributes = async () => {
    var products = dataPdf?.dataOrcamento?.orcamento?.items[0]?.Produto;

    const resp = await getProductDetails(products);
    setProductsAttributes(state => [...state, resp]);
  };

  useEffect(() => {
    if (dataPdf?.dataOrcamento) {
      getProductAttributes();
    }
  }, [dataPdf?.dataOrcamento]);

  useEffect(() => {
    if (active.fetch) {
      getInfoPdf();
    }
  }, [active]);

  useEffect(() => {
    dispatch(getBudget(id));
  }, [dispatch, id]);

  const getTariff = async () => {
    let obj = {
      latitude: dataPdf.lead.lead.latitude,
      longitude: dataPdf.lead.lead.longitude,
    };
    const resp = await request("post", "/api/solar/tariff", obj);
    setDataPdf(state => {
      return {
        ...state,
        concessionaria: resp.data[0].concessionaria,
      };
    });

    setLoading(false);
  };
  useEffect(() => {
    if (dataPdf?.lead?.lead?.latitude) {
      getTariff();
    }
  }, [dataPdf?.lead?.lead?.latitude]);

  return (
    <div className="pdf-container sol page-content">
      {loading || loadingBudget ? (
        <Spinner />
      ) : (
        <>
          <ReactToPrint
            trigger={() => (
              <a className="button-generate-pdf" href="#">
                Baixar PDF
              </a>
            )}
            content={() => componentRef.current}
          />
          <div ref={componentRef} style={{ width: "100%" }}>
            {/*    <PropostaOne />
        <PropostaTwo /> */}
            <Capa dataPdf={dataPdf} />
            <ContraCapa dataPdf={dataPdf} />
            <PageOne
              dataPdf={dataPdf}
              dataProductAttributes={dataProductAttributes}
            />
            <PageTwo
              dataPdf={dataPdf}
              dataProductAttributes={dataProductAttributes}
            />
            <PageThree
              dataPdf={dataPdf}
              dataProductAttributes={dataProductAttributes}
            />
            <PageFour dataPdf={dataPdf} />
            {/* <PageFive dataPdf={dataPdf}/> */}
            <PageSix dataPdf={dataPdf} formOfPayment={formOfPayment} />
            {/*  <PageSeven dataPdf={dataPdf}/> */}
          </div>
        </>
      )}
    </div>
  );
}
