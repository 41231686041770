import React, { useEffect, useState } from "react";
import "./index.scss";
import Breadcrumb from "../../../components/Breadcrumb";
import { Card, CardBody } from "reactstrap";

import Filters from "./Filters";
import ListTable from "./ListTable";
import { useDispatch, useSelector } from "react-redux";
import { getLeadsList } from "../../../store/actions";

export default function ListLeads() {
  return (
    <div className="page-content">
      <div className="container">
        {" "}
        <Breadcrumb title="Página Inicial" breadcrumbItem="Listar Leads" />
        {/* <Filters /> */}
        <Card className="cardprincipal">
          <CardBody className="card-body">
            <div style={{ width: "100%" }}>
              <ListTable />
            </div>
          </CardBody>
        </Card>
      </div>
    </div>
  );
}
