import { apiClient2 } from "./apiCore2";

const postNewCardData = data => {
  // eslint-disable-next-line no-undef
  const baseUrl = `/cartao/cadastro`;
  return apiClient2.create(`${baseUrl}`, data);
};

const getExistingCardData = () => {
  const baseUrl = `/cartao/cadastro?jsonrequest=1`;
  return apiClient2.get(`${baseUrl}`);
};

export { postNewCardData, getExistingCardData };
