import React from "react";
export default function IconCaretUp(props) {
  const colorFill = props?.colorFill || "none";
  const colorStroke = props?.colorStroke || "#9E8100";

  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill={colorFill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.3789 13.6895L13.3789 0.310547L0 0.310546L-5.84811e-07 13.6895L13.3789 13.6895Z"
        fill="transparent"
      />
      <path
        d="M5.01709 2.81918L9.198 7.00009L5.01709 11.181"
        fill="transparent"
      />
      <path
        d="M5.01709 2.81918L9.198 7.00009L5.01709 11.181"
        stroke={colorStroke}
        strokeWidth="0.856009"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
