import React from "react";
import { Button } from "reactstrap";
import { Link } from "react-router-dom";
import { useSolar } from "../../../hooks";

const isSolar = useSolar();

const CustomerNo = ({ row }) => {
  const data = row.original;
  return <div>{data.CODCLIENTE ? data.CODCLIENTE : "-"}</div>;
};

const Name = ({ row }) => {
  const data = row.original;
  return <div className="text-ellipsis-columns">{data.N_COMPLETO ? data.N_COMPLETO : "-"}</div>;
};

const CPFCNPJ = ({ row }) => {
  const data = row.original;
  return <div>{data.CPFCNPJ ? data.CPFCNPJ : "-"}</div>;
};

const Vendedor = ({ row }) => {
  const data = row.original;
  return (
    <div className="text-ellipsis-columns">
      {data.VENDEDOR ? data.VENDEDOR : "-"}
      {data.vendedor && data.vendedor.NOME ? ` - ${data.vendedor.NOME}` : "-"}
    </div>
  );
};

const Action = ({ row }) => {
  const data = row.original;
  const url = (isSolar ? '/solar' : '') + `/cliente/${data.CODCLIENTE}/editar`;
  const urlVer = (isSolar ? '/solar' : '') + `/cliente/${data.CODCLIENTE}`;
  return (
    <div className="d-flex justify-content-evenly">
      <Link to={urlVer} className="btn bg-opacity-50 btn-sm ms-1" style={{color: isSolar ? '#FF601A' : "#2D567B", backgroundColor: isSolar ? '#FFE0D3' : "#BBCAD2"}}>
        <i className="mdi mdi-eye me-1" />
        Ver
      </Link>
      <Link to={url} style={{backgroundColor: isSolar ? "#FF601A" : "#2D567B", color: isSolar ? "#FFE0D3" : '#FFF'}} className="btn btn-sm ms-1">
        <i className="bx bx-edit-alt me-1" />
        Editar
      </Link>
    </div>
  );
};
export { CustomerNo, Name, CPFCNPJ, Vendedor, Action };
