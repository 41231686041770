import React from "react";
import { Alert } from "reactstrap";
import classnames from "classnames";

const RoundAlert = ({ color, children, className }) => {
  return (
    <Alert
      color={color}
      className={classnames(
        "border-radius-30",
        "border-0",
        "d-flex",
        "align-items-center",
        className
      )}
    >
      {children}
    </Alert>
  );
};

export default RoundAlert;
