import React from "react";
import { Action, Active, CreatedBy, EndDate, ResponsibleSeller, SharedId, SharedSeller, StartDate } from "./sharedHistoryColumns";
import { Alert, Label } from "reactstrap";
import Loader from "../../components/Loader";
import SimpleTable from "../../components/CustomTable";
import { HeadCol } from "../../components/Columns";
import './style.css'

const SharedHistoryTable = ({ sharedHistory, handleClickDelete, loading }) => {

  const columns = [
    {
      Header: <HeadCol label="Ativo" />,
      Cell: function colRenderer(props) {
        return <Active sharedResale={props.original} />
      }
    },
    {
      Header: <HeadCol label="id" />,
      Cell: function colRenderer(props) {
        return <SharedId sharedResale={props.original} />
      }
    },
    {
      Header: <HeadCol label="Vendedor compartilhado" />,
      Cell: function colRenderer(props) {
        return <SharedSeller sharedResale={props.original} />
      }
    },
    {
      Header: <HeadCol label="Vendedor responsável" />,
      Cell: function colRenderer(props) {
        return <ResponsibleSeller sharedResale={props.original} />
      }
    },
    {
      Header: <HeadCol label="Ínicio" />,
      Cell: function colRenderer(props) {
        return <StartDate sharedResale={props.original} />
      }
    },
    {
      Header: <HeadCol label="Final" />,
      Cell: function colRenderer(props) {
        return <EndDate sharedResale={props.original} />
      }
    },
    // {
    //   Header: <HeadCol label="Criado" />,
    //   Cell: function colRenderer(props) {
    //     return <CreatedBy sharedResale={props.original} />
    //   }
    // },
    {
      Header: <HeadCol label="Ações" />,
      Cell: function colRenderer(props) {
        return <Action
          sharedResale={props.original}
          loadingDelete={loading}
          handleClickDelete={handleClickDelete}
        />
      }
    },
  ];

  return (
    <div className="mt-2" id="modalSharedHistoryTable">
      <Label for="select-partner">Historico:</Label>
      {loading && (<Loader />)}
      {sharedHistory && sharedHistory.length > 0 ? (
        <SimpleTable
          columns={columns}
          data={sharedHistory}
          classNames="align-middle custom-table table-check"
          selectable={false}
          idField={"id"}
        />
      ) : (<Alert color="info">Nenhum registro encontrado</Alert>)}
    </div>
  );
}

export default SharedHistoryTable