import { borderBottom } from "@mui/system";
import React, { useState } from "react";
import { useEffect } from "react";
import { Input } from "reactstrap";
import "./styles.scss";
const SelectSearch = ({
  options,
  onChange,
  selected,
  renderMenuItem,
  placeholder = "Filtrar",
  ...props
}) => {
  const [name, setName] = useState("");
  const [dataOptions, setDataOptions] = useState(options);
  const [click, setClick] = useState(false);
  const filter = e => {
    const keyword = e.target.value;

    if (keyword !== "") {
      const results = options.filter(data => {
        return (data.name || data.label)
          .toLowerCase()
          .startsWith(keyword.toLowerCase());
        // Use the toLowerCase() method to make it case-insensitive
      });
      setDataOptions(results);
    } else {
      setDataOptions(options);
      // If the text field is empty, show all users
    }

    setName(keyword);
  };
  
  useEffect(() => {
    setDataOptions(options);
  }, [options]);

  useEffect(() => {
    window.addEventListener("mouseup", function (event) {
      var pol = document.getElementById("pol");
      var options = document.getElementById("clickOptions");
      var search = document.getElementById("search");
      if (event.target != pol && event.target.parentNode != pol) {
        if (event.target != options && event.target.parentNode != options) {
          if (event.target != search && event.target.parentNode != search) {
            setClick(false);
          }
        }
      } else {
        setClick(state => !state);
      }
    });
  }, []);
  return (
    <div className="select-search">
      <button
        style={{
          borderBottomLeftRadius: click ? 0 : 5,
          borderBottomRightRadius: click ? 0 : 5,
        }}
        id="pol"
        className="form-select"
        {...props}
      >
        {selected?.map(select => select?.name || select?.label)}
      </button>
      <div className="select-options-container">
        {" "}
        {click && (
          <Input
            className="select-input"
            type="search"
            id="search"
            value={name}
            onChange={filter}
            placeholder={placeholder}
          />
        )}
        <div
          className="select-options"
          style={{
            height: click ? "auto" : "0",
            borderBottom: click ? "1px solid #ced4da" : "none",
          }}
        >
          {renderMenuItem
            ? dataOptions.map((item, k) => (
                <div
                  onClick={e => {
                    onChange(item);
                    setClick(false);
                  }}
                  key={k}
                  className="options"
                >
                  {renderMenuItem(item)}
                </div>
              ))
            : dataOptions.map((item, k) => (
                <div
                  id="clickOptions"
                  onClick={e => {
                    onChange(item);
                    setClick(false);
                  }}
                  key={k}
                  className="options"
                >
                  {item.name.toUpperCase()}
                </div>
              ))}
        </div>{" "}
      </div>
    </div>
  );
};

export default SelectSearch;
