import React, { useEffect, useState } from "react";
import { Row, Col, Container } from "reactstrap";
import Breadcrumb from "../../../components/Breadcrumb";

//redux
import { useSelector, useDispatch } from "react-redux";

//actions
import { getCustomersList } from "../../../store/actions";

// components
import Filters from "./Filters";
import ListTable from "./ListTable";

// // dummy data
// import { customers } from "./data";

const Index = () => {
  const dispatch = useDispatch();
  const { customers, getCustomersLoading } = useSelector(state => ({
    customers: state.Customers.customers,
    getCustomersLoading: state.Customers.getCustomersLoading,
  }));

  /*
  get dashboard details
  */
  useEffect(() => {
    dispatch(getCustomersList());
  }, [dispatch]);

  /*
  pagination
  */
  const [selectedPageSize] = useState(30);
  const [defaultPage, setdefaultPage] = useState(1);
  const onPageChange = page => {
    setdefaultPage(page["currentPage"]);
    dispatch(getCustomersList({ page: page["currentPage"] }));
  };

  /*
  filters
  */
  const onApplyFilters = filters => {
    setdefaultPage(1);
    dispatch(getCustomersList({ ...filters }));
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container>
          {/* Render Breadcrumb */}
          <Breadcrumb title="Clientes" breadcrumbItem="Listar Clientes" />
          <Row>
            <Col xs={12}>
              <Filters onApplyFilters={onApplyFilters} />
            </Col>
          </Row>
          <Row>
            <Col>
              <ListTable
                data={customers || []}
                getCustomersLoading={getCustomersLoading}
                selectedPageSize={selectedPageSize}
                defaultPage={defaultPage}
                onPageChange={onPageChange}
              />
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Index;
