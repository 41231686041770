import { Typeahead } from "react-bootstrap-typeahead";
import {
  CardDefault,
  React,
  Row,
  Col,
  IconFolder,
  IconWarningCircle,
  IconTabs,
  apiClient,
  useEffect,
  useState,
} from "../../helpers/importer.helper";
import { Spinner } from "reactstrap";

export default function SectionThree({
  data,
  setData,
  mapCenter,
  userData,
  updateInfoUser,
}) {
  const [tariff, setTariff] = useState([]);
  const getTariff = async () => {
    let obj = { latitude: mapCenter.lat, longitude: mapCenter.lng };
    const resp = await apiClient.create(`/api/solar/tariff`, obj);

    setTariff(resp.data);
  };

  useEffect(() => {
    getTariff();
  }, [mapCenter]);

  const handleSelect = async e => {
    if (e.length >= 1) {
      setData({
        ...data,
        energy_tariff: e[0].tarifa,
      });
    }
  };

  const handleBlur = () => {
    updateInfoUser("attTarrifEnergy", data.energy_tariff);
  };
  return (
    <section id="section-3" className="mt-4 section">
      <Row>
        <Col md={6}>
          <CardDefault
            checkbox={false}
            icon={<IconFolder colorStroke="#000" />}
            title="Tarifa de energia em sua região"
          >
            <div>
              <p>
                Com base na ANEEL Tarifas, usamos sua conta para estimar quanta
                eletricidade você usa com base nas tarifas típicas de serviços
                públicos em sua área.
              </p>
              <div className="d-flex">
                <i className="me-3">
                  <IconWarningCircle />
                </i>
                <small className="warning-color">
                  Aviso: essa tarifa é apenas uma sugestão
                </small>
              </div>
              <div className="w-100 d-flex j-c-center mt-5">
                {tariff.length >= 1 ? (
                  <Typeahead
                    name="kwh_price"
                    id="kwh_price"
                    onChange={handleSelect}
                    allowNew
                    options={tariff}
                    defaultInputValue={data.energy_tariff}
                    labelKey="tarifa"
                    newSelectionPrefix="Adicionar nova tarifa: "
                    onBlur={handleBlur}
                    renderMenuItemChildren={item => (
                      <option
                        style={{
                          paddingInline: "20px",
                          paddingBlock: "5px",
                        }}
                        value={item.tarifa}
                      >
                        {item?.tarifa +
                          " - " +
                          item?.concessionaria.substring(0, 15) +
                          "..."}
                      </option>
                    )}
                  />
                ) : (
                  <Spinner />
                )}

                {/*<InputGroup>
                  <Input
                    className="f-w-700"
                    type="select"
                    name="select"
                    value={data.energy_tariff}
                    onChange={e => handleSelect(e)}
                    onBlur={handleBlur}
                  >
                    {" "}
                    <option value="0,00">R$ 0,00</option>
                    {tariff.map((item, k) => (
                      <option key={k} value={item.tarifa}>
                        R${" "}
                        {item.tarifa +
                          " - " +
                          item?.concessionaria.substring(0, 10) +
                          "..."}
                      </option>
                    ))}
                  </Input>
                </InputGroup>*/}
              </div>
            </div>
          </CardDefault>
        </Col>
        <Col md={6} id="card-recommended-size-for-installation">
          <CardDefault
            checkbox={false}
            icon={<IconTabs />}
            title="Tamanho recomendado para sua instalação"
          >
            <div>
              <p>
                Este tamanho cobrirá cerca de 97% do seu uso de eletricidade. As
                instalações solares são dimensionadas em quilowatts (kW).
              </p>
              <div className="d-flex j-c-center h-75">
                <div>
                  <strong className="font-size-50">
                    {userData?.calculations?.kwp || "00"} Kwp
                  </strong>
                  <div className="d-flex j-c-center">
                    <p className="font-size-20">
                      ({userData?.calculations?.recommended_size || "00"} m²)
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </CardDefault>
        </Col>
      </Row>
    </section>
  );
}
