class APIClient {
  _axios = null;

  constructor(axios) {
    this._axios = axios;
  }

  getMultiple = (urls, params) => {
    const reqs = [];
    let queryString = "";
    if (params) {
      queryString = params
        ? Object.keys(params)
            .map(key => key + "=" + params[key])
            .join("&")
        : "";
    }

    for (const url of urls) {
      reqs.push(this._axios.get(`${url}?${queryString}`));
    }
    return this._axios.all(reqs);
  };

  /**
   * Fetches data from given url
   */
  get = (url, params) => {
    var response;
    if (params) {
      var paramKeys = [];
      Object.keys(params).map(key => {
        // when param is an array and will need to pass it with same key with diff values
        if (Array.isArray(params[key])) {
          params[key].map(pk => {
            return paramKeys.push(key + "=" + pk);
          });
        } else {
          paramKeys.push(key + "=" + params[key]);
        }
        return paramKeys;
      });

      var queryString =
        paramKeys && paramKeys.length ? paramKeys.join("&") : "";
      response = this._axios.get(`${url}?${queryString}`, params);
    } else {
      response = this._axios.get(`${url}`, params);
    }
    return response;
  };

  /**
   * post given data to url
   */
  create = (url, data, config) => {
    return this._axios.post(url, data, config);
  };
  /**
   * Updates data
   */
  update = (url, data) => {
    return this._axios.put(url, data);
  };
  patch = (url, data) => {
    return this._axios.patch(url, data);
  };

  /**
   * Delete
   */
  delete = (url, config) => {
    return this._axios.delete(url, { ...config });
  };

  createWithFile = (url, data) => {
    const formData = new FormData();
    for (const k in data) {
      formData.append(k, data[k]);
    }
    const config = {
      headers: {
        ...this._axios.defaults.headers,
        "content-type": "multipart/form-data",
      },
    };
    return this._axios.post(url, formData, config);
  };

  createWithMultipleFiles = urls => {
    const reqs = [];
    for (const url of urls) {
      if (url.params) {
        var formData = new FormData();
        for (const key in url.params) {
          formData.append(key, url.params[key]);
        }
        const config = {
          headers: {
            "content-type": "multipart/form-data",
          },
        };
        reqs.push(this._axios.post(url.url, formData, config));
      }
    }
    return this._axios.all(reqs);
  };
}
export { APIClient };
