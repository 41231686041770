import React from "react";
export default function IconPackage(props) {
  const colorStroke = props?.colorStroke || "#FF601A";
  const width = props?.width || 22;
  const height = props?.height || 21;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 22 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.7523 14.1772V6.29097C18.7523 6.17748 18.7221 6.06604 18.6648 5.96808C18.6075 5.87012 18.5252 5.78916 18.4263 5.73353L11.3909 1.77611C11.2952 1.72226 11.1872 1.69397 11.0773 1.69397C10.9675 1.69397 10.8595 1.72226 10.7638 1.77611L3.72836 5.73353C3.62945 5.78916 3.54713 5.87012 3.48983 5.96808C3.43254 6.06604 3.40234 6.17748 3.40234 6.29097V14.1772C3.40234 14.2907 3.43254 14.4021 3.48983 14.5001C3.54713 14.5981 3.62945 14.679 3.72836 14.7347L10.7638 18.6921C10.8595 18.7459 10.9675 18.7742 11.0773 18.7742C11.1872 18.7742 11.2952 18.7459 11.3909 18.6921L18.4263 14.7347C18.5252 14.679 18.6075 14.5981 18.6648 14.5001C18.7221 14.4021 18.7523 14.2907 18.7523 14.1772Z"
        stroke={colorStroke}
        strokeWidth="1.82857"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.995 12.1937V8.03639L7.23828 3.7583"
        stroke={colorStroke}
        strokeWidth="1.82857"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.6615 5.96686L11.1506 10.2339L3.48828 5.96606"
        stroke={colorStroke}
        strokeWidth="1.82857"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.1493 10.234L11.0742 18.7741"
        stroke={colorStroke}
        strokeWidth="1.82857"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
