import React from "react";
import { Card, CardBody, Col, Row } from "reactstrap";
import Loader from "../../components/Loader";

const Live = ({ getTvGalleryLoading, live }) => {
  const title = live && live.name ? `TV A.Dias - ${live.name}` : "TV A.Dias";
  const youtube_id = live && live.youtube_id ? live.youtube_id : "";
  const url = `https://www.youtube.com/embed/${youtube_id}`;
  const hostName = window.location.hostname; // "clube.adias.com.br";
  const liveUrl = `https://www.youtube.com/live_chat?v=${youtube_id}&embed_domain=${hostName}`;
  return (
    <Card>
      <CardBody className="position-relative">
        <h5 className="mb-4 fw-bold">{title}</h5>
        {getTvGalleryLoading && <Loader />}
        <Row>
          {live ? (
            <>
              <Col lg={8}>
                <div className="embed-responsive embed-responsive-21by9 ratio ratio-21x9">
                  <iframe
                    title={title}
                    className="embed-responsive-item"
                    src={url}
                    frameBorder="0"
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  />
                </div>
              </Col>
              <Col lg={4}>
                <div className="embed-responsive">
                  <iframe
                    width="100%"
                    height="445"
                    src={liveUrl}
                    frameBorder="0"
                    allowFullScreen
                  />
                </div>
              </Col>
            </>
          ) : (
            <Col md={12}>
              <div className="alert alert-info">
                Estamos fora do ar no momento...
              </div>
            </Col>
          )}
        </Row>
      </CardBody>
    </Card>
  );
};

export default Live;
