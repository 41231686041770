import React, { useState } from "react";

import { AvForm, AvField } from "availity-reactstrap-validation";
import { Col, FormGroup, Button, Spinner, Row } from "reactstrap";
import { useSolar } from "../../hooks";
import { toast } from "react-toastify";

export const FormDefault = ({ componentProps, onValidSubmit = () => {} }) => {
  const [partnerName, setPartnerName] = useState();
  const [partnerEmail, setPartnerEmail] = useState();
  const [partnerPhoneNumber, setPartnerPhoneNumber] = useState();
  const [subject, setSubject] = useState();
  const [showLoading, setShowLoading] = useState(false);
  const [partnerMessage, setPartnerMessage] = useState();

  const isSolar = useSolar();

  const handleValidSubmit = () => {
    onValidSubmit();
    toast.success("Seu formulário foi enviado com sucesso!");
    setPartnerName("");
    setPartnerEmail("");
    setPartnerPhoneNumber("");
    setSubject("");
    setShowLoading("");
    setPartnerMessage("");
  };

  const maskCellphone = v => {
    return v
      .replace(/\D/g, "")
      .replace(/(\d{2})(\d)/, "($1) $2")
      .replace(/(\d{5})(\d)/, "$1-$2")
      .replace(/(-\d{4})\d+?$/, "$1");
  };

  return (
    <>
      {componentProps}
      <AvForm
        id="form-budget"
        className="form-horizontal mt-5"
        onValidSubmit={(e, v) => {
          handleValidSubmit(e, v);
        }}
      >
        <Row className="mb-3 justify-content-center">
          <Col md={8}>
            <FormGroup>
              <AvField
                label="Nome"
                type="text"
                className="form-control mb-3"
                id="formrow-partnername-Input"
                value={partnerName}
                validate={{
                  required: {
                    value: true,
                    errorMessage: "Este campo é obrigatório",
                  },
                  minLength: {
                    value: 5,
                    errorMessage:
                      "O campo 'nome' deve possuir no mínimo 5 caracters",
                  },
                }}
                name="partnername"
              />
              <AvField
                label="E-mail"
                type="email"
                className="form-control mb-3"
                id="formrow-partneremail-Input"
                value={partnerEmail}
                validate={{
                  required: {
                    value: true,
                    errorMessage: "Este campo é obrigatório",
                  },
                }}
                name="partneremail"
              />
              <AvField
                label="Telefone"
                type="text"
                className="form-control mb-3"
                id="formrow-partnertelphone-Input"
                value={partnerPhoneNumber}
                onChange={e => {
                  setPartnerPhoneNumber(maskCellphone(e.target.value));
                }}
                maxLength={15}
                validate={{
                  required: {
                    value: true,
                    errorMessage: "Este campo é obrigatório",
                  },
                  minLength: {
                    value: 5,
                    errorMessage:
                      "Insira a quantidade certa do seu número telefone",
                  },
                }}
                name="partnertelphone"
              />
              <AvField
                label="Assunto"
                type="text"
                className="form-control mb-3"
                id="formrow-subject-Input"
                value={subject}
                validate={{
                  required: {
                    value: true,
                    errorMessage: "Este campo é obrigatório",
                  },
                  minLength: {
                    value: 10,
                    errorMessage:
                      "O campo 'assunto' deve possuir no mínimo 10 caracters",
                  },
                }}
                name="subject"
              />
              <AvField
                type="textarea"
                label="Mensagem"
                rows="6"
                name="address"
                value={partnerMessage}
                validate={{
                  required: {
                    value: true,
                    errorMessage: "Este campo é obrigatório",
                  },
                  minLength: {
                    value: 20,
                    errorMessage:
                      "Digite uma mensagem com no minimo 20 caracteres.",
                  },
                }}
                id="address"
                className="mb-3 form-control"
              />
              <div className="w-100 d-flex justify-content-end">
                <Button
                  variant="contained"
                  color="success"
                  className="btn cursor-pointer hover-img-upload j-c-center mt-auto"
                  style={{
                    minWidth: 135,
                    backgroundColor: isSolar ? "#FF601A" : "#2D567B",
                    borderColor: isSolar ? "#FF601A" : "#2D567B",
                  }}
                  disabled={showLoading}
                  type="submit"
                >
                  {showLoading ? <Spinner /> : "Salvar"}
                </Button>
              </div>
            </FormGroup>
          </Col>
        </Row>
      </AvForm>
    </>
  );
};
