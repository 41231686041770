const date = new Date();
const d = date.getDate();
const m = date.getMonth();
const y = date.getFullYear();

const events = [
  {
    id: 1,
    title: "CANCELADO - Treinamento Técnico Fujitsu - Presidente Prudente",
    start: new Date(y, m, 1),
    state: "Alagoas",
    createdDate: "27/06/2018",
    className: "bg-warning text-white",
  },
  {
    id: 2,
    title: "CANCELADO - Treinamento Técnico Fujitsu - Presidente Prudente",
    start: new Date(y, m, d - 5),
    state: "Alagoas",
    createdDate: "19/06/2018",
    className: "bg-info text-white",
  },
  {
    id: 3,
    title: "CANCELADO - Treinamento Técnico Fujitsu - Presidente Prudente",
    start: new Date(y, m, 1),
    state: "Alagoas",
    createdDate: "27/06/2018",
    className: "bg-warning text-white",
  },
  {
    id: 4,
    title: "CANCELADO - Treinamento Técnico Fujitsu - Presidente Prudente",
    start: new Date(y, m, 18),
    state: "Alagoas",
    createdDate: "27/06/2018",
    className: "bg-info text-white",
  },
  {
    id: 5,
    title: "CANCELADO - Treinamento Técnico Fujitsu - Presidente Prudente",
    start: new Date(y, m, 11),
    state: "Alagoas",
    createdDate: "27/06/2018",
    className: "bg-info text-white",
  },
  {
    id: 6,
    title: "CANCELADO - Treinamento Técnico Fujitsu - Presidente Prudente",
    start: new Date(y, m, 28),
    state: "Alagodas",
    createdDate: "27/06/2018",
    className: "bg-warning text-white",
  },
  {
    id: 7,
    title: "CANCELADO - Treinamento Técnico Fujitsu - Presidente Prudente",
    start: new Date(y, m, d, 10, 30),
    state: "Alagoas",
    createdDate: "27/06/2018",
    className: "bg-info text-white",
  },
];

export { events };
