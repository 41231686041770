import React, { useState, } from "react";
import {
  CardBody,
  Col,
  Row,
  Card,
} from "reactstrap";
import { useSolar } from "../../hooks";
import { CaretDown, CaretUp, Ticket } from "phosphor-react";
import Switch from "../../components/Switch";
import { Alert } from "@mui/material";
import TicketIcon from "../../components/icons/ticket.js"

const Cupons = ({
  discounts,
  activePromotionsAndCoupons,
  setActivePromotionsAndCoupons,
  enabled = true,
  disabledText = ""
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const isSolar = useSolar();

  return (
    <React.Fragment>
      <Card>
        {!enabled && (
          <div style={{
            position: 'absolute',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            backgroundColor: 'rgba(68, 68, 68, 0.452)',
            zIndex: 55,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}>
            <Alert style={{ width: "fit-content" }} severity="error">
              {disabledText}
            </Alert>
          </div>
        )}
        <CardBody>
          <Row>
            <Col md={11}>
              <p
                className="mb-0 d-flex align-items-center"
                style={{ fontWeight: "bold" }}
              >
                <TicketIcon
                  size={20}
                  color={isSolar ? "#FF9E73" : "#7EACC4"}
                  weight="bold"
                  style={{
                    marginRight: 20,
                    fontSize: 25,
                  }}
                />
                Cupons e promoções Adias{""}
                <Switch
                  setChecked={setActivePromotionsAndCoupons}
                  checked={activePromotionsAndCoupons}
                />
              </p>
            </Col>
            <Col md={1}>
              <a
                href="#"
                style={{
                  width: "fit-content",
                  marginLeft: "auto",
                  display: "block",
                }}
                onClick={event => {
                  event.preventDefault();
                  setIsOpen(!isOpen);
                }}
              >
                {isOpen ? (
                  <CaretUp size={18} weight="bold" />
                ) : (
                  <CaretDown size={18} weight="bold" />
                )}
              </a>
            </Col>
          </Row>
          <Row
            hidden={!isOpen}
            style={{
              marginTop: 15,
              paddingTop: 30,
              borderTop: "1px solid #d4d4d4",
            }}
          >
            <div>
              <ul>
                {discounts && discounts.map(item => (
                  <li key={item.id}>
                    <span>{item.name}</span>
                  </li>
                ))}
              </ul>
            </div>
          </Row>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default Cupons;
